import { Sentry } from '@client/assets/js/sentry';
import { isMobileDevice } from '@client/modules/app-integration/checks';
import { openBankId } from '@client/modules/bank-id';
import get from 'lodash/get';
import URI from 'urijs';

let webSocket: WebSocket | undefined;

export function closeWebSocketConnection(): void {
  if (webSocket) {
    webSocket.close();
    webSocket = undefined;
  }
}

export interface BankIdSigningStatus {
  type: string;
  finished: boolean;
  resourceSignedId?: string;
  autoStartToken?: string;
}

export function sign(
  contractId: string,
  signingId: string,
  resourceToSignId: string,
  onUpdateStatus: (status: BankIdSigningStatus) => void,
  onSigningComplete: () => void
) {
  const uri = new URI({
    /* eslint-disable  */
    protocol: window.location.protocol === 'http:' ? 'ws' : 'wss',
    hostname: window.location.host,
    path: `/api/user/contracts/${contractId}/bank-id/sign/${signingId}`,
  });

  closeWebSocketConnection();
  webSocket = new WebSocket(uri.toString());

  webSocket.onopen = () => {
    if (!webSocket) {
      throw new Error('Web socket is undefined');
    }

    webSocket.send(
      JSON.stringify({
        signingId: signingId,
        resourceToSignId: resourceToSignId,
      })
    );
  };

  webSocket.onmessage = (event) => {
    const status = JSON.parse(event.data) as BankIdSigningStatus;
    if (status.type === 'BankIdSignResourceRequestStarted') {
      onUpdateStatus(status);

      const isMobile = isMobileDevice();
      if (isMobile && !get(status, 'replay', false)) {
        openBankId(status.autoStartToken);
      }
    } else if (status.type === 'BankIdSignResourceCompleted') {
      onSigningComplete();
      closeWebSocketConnection();
    } else {
      onUpdateStatus(status);
    }
  };

  webSocket.onclose = () => {
    closeWebSocketConnection();
  };

  webSocket.onerror = (error) => {
    Sentry.captureMessage('WebSocket error in sign resource with BankID', { extra: { error } });
    closeWebSocketConnection();
  };
}
