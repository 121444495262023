import { useLocalization } from '@client/internationalization';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { SigningButton } from '@client/modules/shared-components/buttons/SigningButton';
import { useBackOrDefault } from '@client/utils/hooks/history';
import { useZendesk } from '@client/utils/hooks/use-zendesk';
import { LABEL_CANCELLATIONS } from '@client/zendesk';
import { makeStyles } from '@material-ui/core';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  pagePadding: {
    height: 80,
    '@supports (height: calc(80px + env(safe-area-inset-bottom)))': {
      height: 'calc(80px + env(safe-area-inset-bottom))',
    },
  },
});

interface Props {
  hideHelp?: boolean;
  backButton?: boolean;
  customGoBack?(): void;
  signButton?: boolean;
  isCancelButtonClicked?: boolean;
  onSubmitCancellationForm?: any;
}

export const ContractDetailsFloatingButtons: FC<Props> = ({
  hideHelp,
  backButton,
  customGoBack,
  isCancelButtonClicked,
  signButton,
  onSubmitCancellationForm,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { localizeMessage } = useLocalization('termination/TerminationFormPage');

  const historyAvailable = history.length > 1;
  const openZendesk = useZendesk(LABEL_CANCELLATIONS);
  const features = useSelector(selectorFeatures);
  const floatingBarVisible =
    (backButton && historyAvailable) || (!hideHelp && features.helpCenter.enabled) || signButton;
  const backOrDefaultHook = useBackOrDefault();
  const goBack = customGoBack ?? backOrDefaultHook;
  const onBackClick = backButton ? goBack : undefined;

  return floatingBarVisible ? (
    <>
      <div className={classes.pagePadding} />
      <FooterNavigation
        onBackClick={onBackClick}
        onHelpClick={!hideHelp && features.helpCenter.enabled ? openZendesk : undefined}
      >
        {signButton && (
          <SigningButton
            label={localizeMessage('terminateButton')}
            fullWidth
            loading={isCancelButtonClicked}
            onClick={onSubmitCancellationForm}
          />
        )}
      </FooterNavigation>
    </>
  ) : null;
};
