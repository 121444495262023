import { LocalizedMessage } from '@client/internationalization';
import { AppType } from '@client/modules/app-context/constants';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import { HistoryCard } from '../../components/cards/HistoryCard';
import { UserCard } from '../../components/cards/UserCard';
import { ConsentPageWrapper } from '../../ConsentPageWrapper';
import styles from './styles.scss';
import { viewedOnboardingMixpanelEvent } from '@client/tracking/mixpanel-events';
import { TrackingPageName } from '@client/tracking/mixpanel-constants';
interface ConsentPageContentsOpAndIngProps {
  onContinueToConsentClick(): void;
  appType: AppType;
}

const ConsentPageContentsOpAndIng: FunctionComponent<ConsentPageContentsOpAndIngProps> = ({
  onContinueToConsentClick,
  appType,
}: ConsentPageContentsOpAndIngProps) => {
  const [open, setOpen] = useState(false);
  const { colors } = useTheme();

  return (
    <>
      <div>
        <Headline2 style={{ margin: '0 0 8px' }} data-test={'headline-consent-expired'}>
          <LocalizedMessage id="headline" />
        </Headline2>
        <Body color={colors.textOn.surfaceSubdued} style={{ margin: '16px 0' }}>
          <LocalizedMessage id="descriptionOne" />
        </Body>
        <Body color={colors.textOn.surfaceSubdued} style={{ margin: '16px 0' }}>
          <LocalizedMessage id="descriptionTwo" />
        </Body>
        <Caption className={styles.link} data-test="dialog-trigger" onClick={() => setOpen(!open)}>
          <LocalizedMessage id="dialogTriggerButtonV2" />
        </Caption>
      </div>
      <div className={styles.button}>
        <PrimaryButton
          label={<LocalizedMessage id="buttonLabelV2" />}
          onClick={onContinueToConsentClick}
          data-test={'continue-button'}
        />
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        title={<LocalizedMessage id="modalTitleV2" />}
        button1={
          <PrimaryButton
            label={<LocalizedMessage id="modalCloseButtonLabel" />}
            onClick={() => setOpen(!open)}
            data-test="dialog-primary-button"
          />
        }
      >
        <Body align="center">
          <LocalizedMessage id="modalDescriptionV2" />
        </Body>
        {appType === AppType.OP ? (
          <div className={styles.card}>
            <UserCard />
          </div>
        ) : null}
        <div className={styles.card}>
          <HistoryCard />
        </div>
      </Modal>
    </>
  );
};

interface ConsentPageContentsProps {
  onContinueToConsentClick(): void;
}

const ConsentPageContents: FunctionComponent<ConsentPageContentsProps> = ({
  onContinueToConsentClick,
}: ConsentPageContentsProps) => {
  const { colors } = useTheme();

  return (
    <>
      <div>
        <Headline2 style={{ margin: '0 0 8px' }} data-test={'headline-consent-expired'}>
          <LocalizedMessage id="headline" />
        </Headline2>
        <Body color={colors.textOn.surfaceSubdued} style={{ margin: '16px 0', textAlign: 'left' }}>
          <LocalizedMessage id="descriptionOne" />
        </Body>
        <Body color={colors.textOn.surfaceSubdued} style={{ margin: '16px 0', textAlign: 'left' }}>
          <LocalizedMessage id="descriptionTwo" />
        </Body>
      </div>
      <div className={styles.button}>
        <PrimaryButton
          label={<LocalizedMessage id="buttonLabelV3" />}
          onClick={onContinueToConsentClick}
          data-test={'continue-button'}
          fullWidth
        />
      </div>
    </>
  );
};

interface RenewConsentPageProps {
  onContinueToConsentClick(): void;
  appType: AppType;
}

export const RenewConsentPage: FunctionComponent<RenewConsentPageProps> = ({
  onContinueToConsentClick,
  appType,
}: RenewConsentPageProps) => {
  return (
    <ConsentPageWrapper
      appType={appType}
      trackViewedPage={() => viewedOnboardingMixpanelEvent(TrackingPageName.NEW_CONSENT)}
    >
      {appType == AppType.OP || appType == AppType.ING ? (
        <ConsentPageContentsOpAndIng onContinueToConsentClick={onContinueToConsentClick} appType={appType} />
      ) : (
        <ConsentPageContents onContinueToConsentClick={onContinueToConsentClick} />
      )}
    </ConsentPageWrapper>
  );
};
