import { Sentry } from '@client/assets/js/sentry';
import { setUser } from '@client/ducks/user';
import { saveUserInformation } from '@client/models/user';
import type { User } from '@client/models/user-models';
import type { AppContext } from '@client/modules/app-context';
import { SigningFeature } from '@client/modules/app-context';
import { saveCancellationAnswers } from '@client/modules/cancellation/api';
import {
  CancellationAction,
  selectorDesiredTerminationAt,
  selectorTerminationAnswers,
  selectorTerminationQuestions,
} from '@client/modules/cancellation/duck';
import { sentCancellationOfServiceFailedMixpanelEvent } from '@client/modules/cancellation/mixpanel-events';
import { cancelServiceSignCompleteCallback } from '@client/modules/cancellation/signing/common';
import { contractCancellationAnswers, userInfoFromUserInformationForm } from '@client/modules/cancellation/utils';
import { signCancelServiceLoa } from '@client/modules/cancellation/websocketHelpers';
import { selectorUserInformationForm } from '@client/modules/subscription/duck';
import * as urls from '@client/routes';
import type { History } from 'history';
import type { Dispatch } from 'redux';

export function onSendCancelServiceRequest(
  dispatch: Dispatch,
  appContext: AppContext,
  history: History,
  merchantId: string,
  serviceId: string
) {
  dispatch<any>((dispatchInner: Dispatch<any>, getState: () => any) => {
    const state = getState();

    const terminationAnswers = contractCancellationAnswers(
      selectorTerminationAnswers(state),
      selectorTerminationQuestions(state)
    );
    dispatchInner(CancellationAction.setCancelButtonClicked(true));

    if (terminationAnswers) {
      const updatedUserInformation = userInfoFromUserInformationForm(selectorUserInformationForm(state));

      const answersPromise = saveCancellationAnswers(
        merchantId,
        serviceId,
        terminationAnswers,
        selectorDesiredTerminationAt(state)
      );
      answersPromise.catch((error: Error) => {
        Sentry.captureExceptionWithMessage(error, 'Failed to save answers from cancellation form page');
      });

      const userPromise = saveUserInformation(updatedUserInformation);
      userPromise
        .then((user: User) => {
          dispatchInner(setUser(user));
        })
        .catch((error: Error) => {
          Sentry.captureExceptionWithMessage(error, 'Failed to update user from cancellation form page');
        });

      Promise.all([userPromise, answersPromise])
        .then(() => {
          if (appContext.features.signing === SigningFeature.BANKID) {
            const failedSigningCallback = () => sentCancellationOfServiceFailedMixpanelEvent(merchantId, serviceId);
            const onTerminationSignCompleted = () =>
              cancelServiceSignCompleteCallback(dispatch, history)(merchantId, serviceId);
            dispatchInner(
              signCancelServiceLoa(merchantId, serviceId, onTerminationSignCompleted, failedSigningCallback)
            );
          }
          dispatch(CancellationAction.setCancelButtonClicked(false));
          history.push(urls.signCancelServiceRouteFun(merchantId, serviceId));
        })
        .catch((error) => {
          Sentry.captureExceptionWithMessage(error, 'Failed to sign loa');
        });
    }
  });
}
