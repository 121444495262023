import Sentry from '@client/assets/js/sentry';
import type { Question, RecipientQuestion } from '@client/models/cancellation-questions';
import type { Locale } from '@client/modules/app-context/constants';
import { CancellationAction } from '@client/modules/cancellation/duck';
import { collectAllQuestions, questionInfoMap } from '@client/modules/cancellation/utils';

export interface AddressInformation {
  street?: string;
  city?: string;
  careOf?: string;
  postalCode?: string;
}

export interface CancellationAnswersFormValues {
  firstName?: string;
  lastName?: string;
  personalIdentityNumber?: string;
}

export interface RecipientQuestionAnswer {
  alternativeId: string;
  requiresUserInput: boolean;
  userInput?: string;
  userInputLabel?: string;
}

export const getAnswerObject = (type: string, questionAnswers: any, cancellationQuestion: Question, locale: Locale) => {
  switch (type) {
    case 'TextAnswer':
      return { text: questionAnswers[cancellationQuestion.id] };
    case 'EmailAnswer':
      return { emailAddress: questionAnswers[cancellationQuestion.id] };
    case 'DateAnswer':
      return { date: questionAnswers[cancellationQuestion.id] };
    case 'TelephoneAnswer':
      return { number: questionAnswers[cancellationQuestion.id] };
    case 'RecipientAnswer': {
      const recipientQuestion = cancellationQuestion as RecipientQuestion;
      const alternativeId = questionAnswers['recipientQuestion' + cancellationQuestion.id];
      const userInput = questionAnswers['recipientQuestionUserInput' + alternativeId];
      const alternative = recipientQuestion.alternatives.find((alt) => alt.id === alternativeId);
      return {
        alternativeId: alternativeId,
        requiresUserInput: alternative?.requiresUserInput,
        userInput: userInput,
        userInputLabel: alternative?.details[locale]?.userInputLabel,
      };
    }
    case 'NestedAnswer':
      return { nestedQuestionAlternativeId: questionAnswers['nestedQuestion' + cancellationQuestion.id] };
    case 'AddressAnswer': {
      const street = questionAnswers['street' + cancellationQuestion.id];
      const postCode = questionAnswers['postCode' + cancellationQuestion.id];
      const city = questionAnswers['city' + cancellationQuestion.id];
      const careOf = questionAnswers['careOf' + cancellationQuestion.id];
      return { city: city, postCode: postCode, careOf: careOf, street: street };
    }
    default:
      return Sentry.captureMessage('Was not able to create a Cancellation answer');
  }
};

export const updateCancellationAnswers = (
  cancellationQuestions: Question[],
  cancellationFormAnswer: any,
  dispatch: any,
  locale: Locale
) => {
  const allQuestions = collectAllQuestions(cancellationQuestions, cancellationFormAnswer);
  allQuestions.map((cancellationQuestion: Question) => {
    const questionInfo: { answerType: string } = questionInfoMap(cancellationQuestion);
    const questionId = cancellationQuestion.id;
    const answerObject = getAnswerObject(questionInfo.answerType, cancellationFormAnswer, cancellationQuestion, locale);
    dispatch(CancellationAction.setTerminationAnswer(questionId, answerObject));
  });
};
