import { setUser } from '@client/ducks/user';
import { changeUserEmail, saveUserInformation } from '@client/models/user';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import type { Dispatch } from 'redux';
import { handle } from 'redux-pack';
import {
  CHANGE_EMAIL_CLEAR_ERROR,
  CHANGE_EMAIL_FAILURE,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  PERSONAL_INFORMATION_CLEAR,
  PERSONAL_INFORMATION_SAVE,
} from './actions';

export const selectorPersonalInformationSaving = (state: any) => state.userSettings.personalInformationSaving;
export const selectorPersonalInformationError = (state: any) => state.userSettings.personalInformationError;
export const selectorPersonalInformationSuccessfulSave = (state: any) =>
  state.userSettings.personalInformationSuccessfulSave;

export const selectorEmail = (state: any) => state.userSettings.email;
export const selectorEmailLoading = (state: any) => state.userSettings.emailLoading;
export const selectorEmailErrorReason = (state: any) => state.userSettings.emailErrorReason;
export const selectorEmailChangeSuccess = (state: any) => state.userSettings.emailChangeSuccess;

export const EMAIL_ERROR_NOT_UNIQUE = 'EmailNotUnique';
export const EMAIL_ERROR_UNKNOWN = 'Unknown';
export const emailErrorReasonPropType = PropTypes.oneOf([EMAIL_ERROR_UNKNOWN, EMAIL_ERROR_NOT_UNIQUE]);
export enum EmailErrorReason {
  EMAIL_ERROR_UNKNOWN = 'Unknown',
  EMAIL_ERROR_NOT_UNIQUE = 'EmailNotUnique',
}

const initialState = {
  personalInformationSaving: false,
  personalInformationError: false,
  personalInformationSuccessfulSave: false,
  email: undefined,
  emailLoading: false,
  emailErrorReason: undefined,
  emailChangeSuccess: false,
};

export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case PERSONAL_INFORMATION_SAVE: {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          personalInformationSaving: true,
          personalInformationError: false,
          personalInformationSuccessfulSave: false,
        }),
        // the updated user from action.payload is taken care of in ducks/user.js
        success: (s) => ({ ...s, personalInformationSaving: false, personalInformationSuccessfulSave: true }),
        failure: (s) => ({
          ...s,
          personalInformationSaving: false,
          personalInformationError: true,
          personalInformationSuccessfulSave: false,
        }),
      });
    }
    case PERSONAL_INFORMATION_CLEAR: {
      return {
        ...state,
        personalInformationSaving: false,
        personalInformationError: false,
        personalInformationSuccessfulSave: false,
      };
    }
    case CHANGE_EMAIL_REQUEST: {
      return {
        ...state,
        emailLoading: true,
        email: undefined,
        emailErrorReason: undefined,
        emailChangeSuccess: false,
      };
    }
    case CHANGE_EMAIL_SUCCESS: {
      return {
        ...state,
        emailChangeSuccess: true,
        email: undefined,
        emailLoading: false,
        emailErrorReason: undefined,
      };
    }
    case CHANGE_EMAIL_FAILURE: {
      return {
        emailErrorReason: action.reason,
        email: action.email,
        emailLoading: false,
        emailChangeSuccess: false,
      };
    }
    case CHANGE_EMAIL_CLEAR_ERROR: {
      return {
        ...state,
        email: undefined,
        emailLoading: false,
        emailErrorReason: undefined,
        emailChangeSuccess: false,
      };
    }
    default:
      return state;
  }
}

export const saveUserPersonalInformation = (userInformation: any) => ({
  type: PERSONAL_INFORMATION_SAVE,
  promise: saveUserInformation(userInformation),
});

export const clearUserPersonalInformationProgressStates = () => ({
  type: PERSONAL_INFORMATION_CLEAR,
});

export const changeEmail = (email: string) => async (dispatch: Dispatch, getState: any) => {
  if (selectorEmailLoading(getState())) return;

  dispatch({ type: CHANGE_EMAIL_REQUEST, email });
  await changeEmailPromise(dispatch, email);
};

export const changeEmailPromise = async (dispatch: Dispatch, email: string) =>
  changeUserEmail(email).then(
    (user) => {
      dispatch({ type: CHANGE_EMAIL_SUCCESS, email });
      dispatch(setUser(user));

      return true;
    },
    (error) => {
      dispatch({
        type: CHANGE_EMAIL_FAILURE,
        email,
        reason: get(error, 'response.data.error.reason', EmailErrorReason.EMAIL_ERROR_UNKNOWN),
      });

      return false;
    }
  );

export const clearErrorForChangeEmail = () => ({
  type: CHANGE_EMAIL_CLEAR_ERROR,
});
