import CircularProgress from '@material-ui/core/CircularProgress';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import type { ReactNode } from 'react';
import React from 'react';
import styles from './styles.scss';

interface LoadingPageProps {
  caption?: ReactNode;
}

// (for some reason 'any' is needed here. Otherwise this can't be passed to react-loadable)
export const LoadingPage: React.FunctionComponent<any | LoadingPageProps> = ({ caption }) => {
  return (
    <div className={styles['progress-container']} data-test="loading-page-div">
      <CircularProgress />
      {caption && (
        <div className={styles.caption}>
          <Body>{caption}</Body>
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default LoadingPage;
