// eslint-disable-next-line import/no-unassigned-import
import 'intl';
// eslint-disable-next-line import/no-unassigned-import
import 'intl/locale-data/jsonp/sv.js';

export function formatPriceWithOptionalDecimals(number: number) {
  const decimals = Math.abs(Math.round((number * 100) % 100));
  if (decimals !== 0 && decimals !== 100) {
    return new Intl.NumberFormat('sv', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
      number
    );
  } else {
    return new Intl.NumberFormat('sv', { style: 'decimal', maximumFractionDigits: 0 }).format(number);
  }
}

export function formatPersonalNumber(personalNumber?: string) {
  if (personalNumber) {
    return `${personalNumber.substring(0, 8)}-${personalNumber.substring(8)}`;
  } else {
    return personalNumber;
  }
}
