import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { Currency } from '@client/models/subscription';
import type { SupplierWithServices } from '@client/models/supplier';
import { PaymentIntervalSelect } from '@client/modules/shared-components/PaymentIntervalSelect';
import type { PaymentInterval } from '@client/modules/subscription/types';
import grid from '@client/styles/grid.css';
import { useZendesk } from '@client/utils/hooks/use-zendesk';
import { LABEL_FAQ_GENERAL } from '@client/zendesk';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { RHTextInput } from '@minna-technologies/minna-ui/components/Form/RHTextInput';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import classNames from 'classnames/bind';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import PropTypes from 'prop-types';
import React from 'react';

const useStyle = makeStyles({
  container: {
    padding: '0 8px',
  },

  cardLayout: {
    display: 'flex',
    flexDirection: 'column',
  },

  supplierLogo: {
    alignSelf: 'center',
    background: 'white',
    marginBottom: '21px',
    marginRight: '5px',
    maxHeight: '50px',
    maxWidth: '150px',
    padding: '3px',
  },

  supplierName: {
    alignSelf: 'center',
    margin: '8px 0 24px',
  },

  description: {
    marginBottom: '30px',
  },
});

const cx = classNames.bind(grid);

export interface ManualContractPricingPageInnerProps {
  currency: Currency;
  isHelpCenterEnabled: boolean;
  selectedPaymentInterval: PaymentInterval;
  supplier: SupplierWithServices;
  onBackClick(): void;
  onChangeCost(amount: number): void;
  onChangePaymentInterval(interval: PaymentInterval): void;
  onSubmit(): void;
}

const ManualContractPricingPageInner: React.FunctionComponent<ManualContractPricingPageInnerProps> = (
  {
    currency,
    isHelpCenterEnabled,
    selectedPaymentInterval,
    supplier,
    onBackClick,
    onChangeCost,
    onChangePaymentInterval,
    onSubmit,
  },
  { localizeMessage, localizeCurrency }
) => {
  const { colors } = useTheme();
  const classes = useStyle();
  const onHelpClick = useZendesk(LABEL_FAQ_GENERAL);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      // Todo: Not to sure what to do in this case
      // @ts-ignore
      onChangeCost(Number(e?.target?.value ?? 0));
    }
  };

  const methods = useMinnaForm<{ pricing: number }>();

  const hasLogo = (logo?: string): boolean => !isNil(logo) && !isEmpty(logo);

  return (
    <MultiDeviceLayout
      title={<LocalizedMessage id="fillInDetails" />}
      footer={
        <FooterNavigation onBackClick={onBackClick} onHelpClick={isHelpCenterEnabled ? onHelpClick : undefined} />
      }
      hasTopNavigation={false}
    >
      <div className={classes.container}>
        <Body color={colors.textOn.surfaceSubdued} className={classes.description}>
          <LocalizedMessage id="fillInDetailsDescription" />
        </Body>
        <Card className={classes.cardLayout}>
          {hasLogo(supplier.logoUrl) ? (
            <img className={classes.supplierLogo} src={supplier.logoUrl} alt={supplier.name} />
          ) : (
            <Subheading1 color={colors.textOn.surfaceSubdued} className={classes.supplierName}>
              {supplier.name}
            </Subheading1>
          )}
          <Form onSubmit={onSubmit} formMethods={methods}>
            <div className={cx('row')}>
              <div className={cx('col-xs-6')}>
                <RHTextInput
                  type="number"
                  name="pricing"
                  data-test={'manual-subscription-price'}
                  validation={{
                    required: localizeMessage('pricingRequired'),
                  }}
                  label={localizeMessage('cost')}
                  fullWidth
                  suffix={localizeCurrency(currency)}
                  inputProps={{
                    onBlur: (e) => onChangeCost(Number(e.target.value)),
                    /* @ts-ignore */
                    onKeyPress: (e) => handleKeyPress(e),
                    /* @ts-ignore */
                    step: '0.01',
                  }}
                />
              </div>
              <div className={cx('col-xs-6')}>
                <PaymentIntervalSelect
                  defaultValue={selectedPaymentInterval}
                  name="interval"
                  data-test={'manual-subscription-interval'}
                  label={localizeMessage('paymentIntervalInputLabel')}
                  fullWidth
                  onChange={(interval) => {
                    onChangePaymentInterval(interval);
                  }}
                />
              </div>
            </div>
            <PrimaryButton
              type="submit"
              data-test={'manual-subscription-next'}
              label={<LocalizedMessage id="add" />}
              fullWidth
            />
          </Form>
        </Card>
      </div>
    </MultiDeviceLayout>
  );
};

ManualContractPricingPageInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
  localizeCurrency: PropTypes.func.isRequired,
};

export const ManualContractPricingPage = withLocalization('ManualContractPricingPage')(ManualContractPricingPageInner);
