import { disableSentry } from '@client/assets/js/sentry';
import type { Locale } from '@client/modules/app-context/constants';
import { getItemFromLocalStorage, setItemInLocalStorage } from '@client/utils/local-storage';
import get from 'lodash/get';
import URI from 'urijs';

let hasReadUrlParameter: boolean = false;

export function isDeveloperToolsEnabled(): boolean {
  if (!hasReadUrlParameter) {
    const currentUrl = new URI(window.location.href);
    const developerTools = get(currentUrl.query(true), 'developerTools');
    if (developerTools !== undefined) {
      setDeveloperToolsEnabled(developerTools === 'true');
    }
    hasReadUrlParameter = true;
  }

  const enabled = getItemFromLocalStorage('developerTools.enabled') === 'true';
  if (enabled) {
    disableSentry();
  }

  return enabled;
}

export function setDeveloperToolsEnabled(enabled: boolean): void {
  disableSentry();
  setItemInLocalStorage('developerTools.enabled', String(enabled));
}

export function getDeveloperToolsCurrentDebugLocale(): Locale | null {
  const maybeLocale = getItemFromLocalStorage('developerTools.currentDebugLocale');
  if (maybeLocale) {
    return maybeLocale as Locale;
  } else {
    return null;
  }
}

export function setDeveloperToolsCurrentDebugLocale(currentDebugLocale: Locale): void {
  setItemInLocalStorage('developerTools.currentDebugLocale', currentDebugLocale);
}
