import type { Market } from '../modules/app-context/constants';

export interface Order {
  id: string;
  userId: string;
  productCommission?: number;
  productName: string;
  note?: string;
  createdAt: string;
  state: any;
  supplierName: string;
  email: string;
  market: Market;
  personalIdentifier: string;
  userFullName: string;
}

export enum OrderState {
  RejectedBySupplier = 'RejectedBySupplier',
  CancelledByUser = 'CancelledByUser',
  TerminationFailed = 'TerminationFailed',
  ChangeDataPlanRejected = 'ChangeDataPlanRejected',
  New = 'New',
  WaitForTerminationInformation = 'WaitForTerminationInformation',
  SentToSupplier = 'SentToSupplier',
  PendingSendToSupplier = 'PendingSendToSupplier',
  PendingWebhookSendToSupplier = 'PendingWebhookSendToSupplier',
  PendingWithSupplier = 'PendingWithSupplier',
  TechnicalError = 'TechnicalError',
  AcceptedBySupplier = 'AcceptedBySupplier',
  PaidBySupplier = 'PaidBySupplier',
  ChangeDataPlanAccepted = 'ChangeDataPlanAccepted',
  InvoiceSentToSupplier = 'InvoiceSentToSupplier',
}

export const successfulOrderStates = [
  OrderState.AcceptedBySupplier,
  OrderState.PaidBySupplier,
  OrderState.ChangeDataPlanAccepted,
  OrderState.InvoiceSentToSupplier,
];

export const failedOrderStates = [
  OrderState.RejectedBySupplier,
  OrderState.CancelledByUser,
  OrderState.TerminationFailed,
  OrderState.ChangeDataPlanRejected,
];

export enum RejectedReason {
  AlreadyCustomer = 'AlreadyCustomer',
  CreditCheckFailed = 'CreditCheckFailed',
  UnknownAddress = 'UnknownAddress',
  HasBindingPeriod = 'HasBindingPeriod',
  Unknown = 'Unknown',
}

export enum PortingDateType {
  SpecificPortingDate = 'SpecificPortingDate',
  AsSoonAsPossible = 'AsSoonAsPossible',
}

export interface PortingDate {
  type: PortingDateType;
  date?: string;
}
