import { selectorUser } from '@client/ducks/user';
import get from 'lodash/get';
import includes from 'lodash/includes';
import last from 'lodash/last';

export const selectorZChatIsDoneInitializing = (state: any) => state.zendeskChat.isDoneInitializing;
export const selectorShouldInitialize = (state: any) =>
  !state.zendeskChat.isInitializing && !state.zendeskChat.isDoneInitializing;
export const selectorIsChatting = (state: any) => state.zendeskChat.isChatting;
export const selectorIsChatConnected = (state: any) => state.zendeskChat.connectionStatus === 'connected';
export const selectorAccountStatus = (state: any) => state.zendeskChat.agentStatus;
export const selectorIsAgentAvailable = (state: any) => includes(['away', 'online'], selectorAccountStatus(state));
export const selectorChatEvents = (state: any) => state.zendeskChat.events;
export const selectorFirstMessageForm = (state: any) => state.zendeskChat.firstMessageForm;
export const selectorMessageForm = (state: any) => state.zendeskChat.messageForm;
export const selectorAgentIsTyping = (state: any) => state.zendeskChat.agentIsTyping;
export const selectorVisitorIsTyping = (state: any) => state.zendeskChat.visitorIsTyping;
export const selectorAgentData = (state: any) => state.zendeskChat.agentData;
export const selectorAdditionalUserInfoWhenSetVisitorInfo = (state: any) => {
  const user = selectorUser(state);
  return user?.id;
};
export const selectorUnreadMessagesCount = (state: any) => state.zendeskChat.unreadMessages.length;

export const selectorRecentMessageText = (state: any) => get(last(state.zendeskChat.unreadMessages), 'msg');
export const selectorShowRecentMessage = (state: any) => state.zendeskChat.showRecentMessage;
