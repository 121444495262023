import { BasicPaper } from '@client/modules/subscription/components/BasicPaper';
import type { ServiceCategory } from '@client/modules/subscription/types';
import { CategoryIcon } from '@client/shared-components/CategoryIcon/CategoryIcon';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import React from 'react';
import styles from './styles.scss';

export interface ContractCardInnerProps {
  category: ServiceCategory;
  categoryName: string;
  bottomMessage?: React.ReactNode;
  onContractCardClicked(): void;
}
export const ContractCard: React.FunctionComponent<ContractCardInnerProps> = ({
  category,
  categoryName,
  bottomMessage,
  onContractCardClicked,
}: ContractCardInnerProps) => {
  const { colors } = useTheme();

  return (
    <BasicPaper className={styles.paper} onClick={onContractCardClicked} data-test="card-service">
      <div className={styles.content}>
        <div>
          <div className={styles.firstRow}>
            <CategoryIcon categoryName={category.name} nativeColor={colors.textOn.surfaceSubdued} />
            <div className={styles.categoryText}>
              <Body style={{ textTransform: 'capitalize' }}>{categoryName}</Body>
            </div>
          </div>
          {bottomMessage && <div className={styles.bottomMessage}>{bottomMessage}</div>}
        </div>
        <div>
          <div className={styles.chevronContainer}>
            <ChevronRightIcon nativeColor={colors.textOn.surfaceSubdued} />
          </div>
        </div>
      </div>
    </BasicPaper>
  );
};
