import { useLocalization } from '@client/internationalization';
import { ContractExpiringSelect } from '@client/modules/subscription/ContractDetailsPage/components/ReminderDialog/ContractExpiringSelect';
import { isBindingPeriodBound } from '@client/modules/subscription/ContractDetailsPage/components/SubscriptionReminder/component';
import type { Contract, ContractBindingPeriod } from '@client/modules/subscription/types';
import { BindingPeriodType } from '@client/modules/subscription/types';
import { TrackingButtonLocation } from '@client/tracking/mixpanel-constants';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { DangerButton } from '@minna-technologies/minna-ui/components/Buttons/DangerButton';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { ReminderIcon } from '@minna-technologies/minna-ui/icons/Reminder';
import type { FC } from 'react';
import React, { useState } from 'react';
import { ReminderTypeSelect } from '../ReminderTypeSelect';

const useStyles = makeStyles({
  reminderIcon: {
    top: '14px',
    left: '16px',
    position: 'absolute',
  },
});

const NoContractBindingPeriod: ContractBindingPeriod = {
  type: BindingPeriodType.NoContractBindingPeriod,
};

export enum ReminderDialogView {
  Default = 'Default',
  UpcomingPayment = 'UpcomingPayment',
  ContractExpiring = 'ContractExpiring',
  Cancel = 'Cancel',
  Switch = 'Switch',
}

interface Props {
  contract: Contract;
  open: boolean;
  onSaveContractBinding(contractBindingPeriod: ContractBindingPeriod): void;
  onClose(buttonLocation: TrackingButtonLocation): void;
}

export const ReminderDialog: FC<Props> = ({ contract, open, onSaveContractBinding, onClose }) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const { localizeMessage } = useLocalization('contract/subscriptionReminderDialog');

  const isBound = isBindingPeriodBound(contract.contractBindingPeriod);

  const [contractPeriod, setContractPeriod] = useState(contract.contractBindingPeriod);

  const [currentView, setCurrentView] = useState(() => {
    if (contract.contractBindingPeriod.type === BindingPeriodType.FixedContractBindingPeriod) {
      return ReminderDialogView.ContractExpiring;
    }
    // TODO: Add cases for Payment/Cancel/Switch in follow up stories

    return ReminderDialogView.Default;
  });

  const [isPending, setIsPending] = useState(!isBound);

  const isSaveDisabled = isBound && !isPending;

  const resetDialog = () => {
    setCurrentView(ReminderDialogView.Default);
    setIsPending(true);
    setContractPeriod(NoContractBindingPeriod);
  };

  const getCurrentView = (currentView: ReminderDialogView) => {
    if (currentView === ReminderDialogView.ContractExpiring) {
      return (
        <ContractExpiringSelect
          defaultValue={contractPeriod}
          onChange={(contractBindingPeriod) => {
            setIsPending(true);
            setContractPeriod(contractBindingPeriod);
          }}
        />
      );
    }
    // TODO: Add cases for Payment/Cancel/Switch in follow up stories
    return null;
  };

  const removeContractBinding = (): void => {
    onSaveContractBinding(NoContractBindingPeriod);
  };

  const onRemove = (): void => {
    if (currentView === ReminderDialogView.ContractExpiring) {
      removeContractBinding();
    }
    // TODO: Add cases for Payment/Cancel/Switch in follow up stories

    resetDialog();

    onClose(TrackingButtonLocation.BOTTOM_CENTER);
  };

  const onSave = (): void => {
    if (currentView === ReminderDialogView.ContractExpiring) {
      onSaveContractBinding(contractPeriod);
    } else {
      removeContractBinding();
    }
    // TODO: Add cases for Payment/Cancel/Switch in follow up stories

    setIsPending(false);
    onClose(TrackingButtonLocation.BOTTOM_CENTER);
  };

  const titleText = (view: ReminderDialogView) => {
    return view === ReminderDialogView.ContractExpiring && isBound
      ? localizeMessage('reminderTitleContractEnding')
      : localizeMessage('reminderTitle');
  };

  const bodyText = (view: ReminderDialogView) => {
    return isBound && view !== ReminderDialogView.Default
      ? localizeMessage('reminderContractEndingBody')
      : localizeMessage('reminderDefaultBody');
  };

  return (
    <Modal
      buttonLayout="horizontal"
      onClose={() => onClose(TrackingButtonLocation.TOP_RIGHT)}
      open={open}
      title={<Title align="left">{titleText(currentView)}</Title>}
      button1={<PrimaryButton label={localizeMessage('reminderSave')} onClick={onSave} disabled={isSaveDisabled} />}
      button2={isSaveDisabled && <DangerButton label={localizeMessage('reminderRemove')} onClick={onRemove} />}
    >
      <ReminderIcon nativeColor={colors.action.secondary} className={classes.reminderIcon} />
      <Body color={colors.textOn.surfaceSubdued} style={{ marginTop: '-16px', marginBottom: '24px' }}>
        {bodyText(currentView)}
      </Body>
      <ReminderTypeSelect
        defaultValue={currentView}
        onChange={(view: ReminderDialogView) => {
          if (view !== ReminderDialogView.ContractExpiring) {
            removeContractBinding();
            setContractPeriod(NoContractBindingPeriod);
          }
          setCurrentView(view);
        }}
      />
      {getCurrentView(currentView)}
    </Modal>
  );
};
