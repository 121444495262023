import type { Market } from '@client/modules/app-context/constants';
import { saveRating } from '@client/modules/rating/api';
import type { FeedbackType, RatingRequest } from '@client/modules/rating/model';
import { RatingType } from '@client/modules/rating/model';

export function ratedOrderRequestEvent(
  ratingNumber: number,
  feedbackType: FeedbackType,
  category: string,
  market: Market,
  orderId?: string,
  ratingText?: string
) {
  ratedIndependentOrderRequestEvent(ratingNumber, feedbackType, category, market, orderId, ratingText);
}

export function ratedIndependentOrderRequestEvent(
  ratingNumber: number,
  feedbackType: FeedbackType,
  category: string,
  market: Market,
  orderId?: string,
  ratingText?: string
) {
  const ratingRequest: RatingRequest = {
    rating: ratingNumber,
    maxRating: 5,
    value: ratingText,
    ratingType: RatingType.IndependentOrderRating,
    ratingTypeUUID: orderId,
    feedbackType: feedbackType,
    market: market,
    categoryName: { name: category },
  };
  saveRating(ratingRequest);
}
