import { LocalizedMessage } from '@client/internationalization';
import { blueTheme, white } from '@client/styles/colors';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { RaisedButton } from 'material-ui';
import Paper from 'material-ui/Paper';
import React from 'react';
import { ZendeskSubHeader } from '../../../../ZendeskSubHeader';
import styles from '../styles.scss';

export const ZendeskCallBackFailure = ({ onNavigateToCallBack }: any) => {
  return (
    <Paper className={styles.paper}>
      <div className={styles.container} data-test="zendesk-callback-failure-page">
        <ZendeskSubHeader title={<LocalizedMessage id="callBackRequestFailedTitle" />} />
        <Body className={styles['force-margin-top']}>
          <LocalizedMessage id="callBackRequestFailedDescription1" />
        </Body>
        <Body className={styles['force-margin-top']}>
          <LocalizedMessage id="callBackRequestFailedDescription2" />
        </Body>
        <RaisedButton
          data-test="try-again-button"
          className={styles['right-button']}
          label={<LocalizedMessage id="callBackRequestFailedButton" />}
          backgroundColor={blueTheme}
          labelColor={white}
          onClick={onNavigateToCallBack}
        />
      </div>
    </Paper>
  );
};
