import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { TextFieldProps } from '@client/modules/shared-components/inputs/TextField';
import { TextField } from '@client/modules/shared-components/inputs/TextField';
import { isValidEmail } from '@client/utils/email';
import omit from 'lodash/omit';
import * as React from 'react';

const valueToErrorText = (value: any, errorMessage?: React.ReactNode | string) =>
  !value || isValidEmail(value) ? false : errorMessage;

const EmailFieldInner: React.FunctionComponent<TextFieldProps> = (props) => {
  const { value } = props;
  const cleanProps = omit(props, 'validators');

  return (
    <TextField
      type="email"
      errorText={valueToErrorText(value)}
      requiredErrorText={props.required ? <LocalizedMessage id="missingRequiredEmailError" /> : undefined}
      validators={{
        email(val: string) {
          return valueToErrorText(val, <LocalizedMessage id="emailFormatError" />);
        },
        ...props.validators,
      }}
      label={<LocalizedMessage id="emailInputLabel" />}
      {...cleanProps}
    />
  );
};

export const EmailField: React.ComponentType<TextFieldProps> = withLocalization('standard-components/EmailField')(
  EmailFieldInner
);
