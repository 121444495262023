import { Sentry } from '@client/assets/js/sentry';
import { withLocalization } from '@client/internationalization';
import { SetCancellationReason, SetOffer, SetSentFeedback } from '@client/modules/saveDesk/actions';
import type { CancellationReason, SaveDeskOfferForUser } from '@client/modules/saveDesk/api';
import { getCancellationReasonsForSaveDesk, getSaveDeskOffers } from '@client/modules/saveDesk/api';
import { CancellationReasonDialogComponent } from '@client/modules/saveDesk/CancellationReasonDialog/component';
import {
  clickedCancellationReasonButton,
  viewedCancellationReasonDialog,
} from '@client/modules/saveDesk/mixpanel-events';
import { selectorCancellationReason } from '@client/modules/saveDesk/state';
import { fireMixpanelEvent } from '@client/modules/saveDesk/utils';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import { TrackingButtonName } from '@client/tracking/mixpanel-constants';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import { useDispatch } from '@client/utils/redux';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

interface CancellationReasonDialogInnerProps {
  subscription: Subscription;
  open: boolean;
  onClose(): void;
  goToCancelForm(): void;
  contract: Contract;
}

const CancellationReasonDialogInner: React.FunctionComponent<CancellationReasonDialogInnerProps> = ({
  subscription,
  open,
  onClose,
  goToCancelForm,
  contract,
}) => {
  const dispatch = useDispatch();
  const [cancellationReasons, setCancellationReasons] = useState<CancellationReason[]>([]);
  const selectedCancellationReason = useSelector(selectorCancellationReason);
  const [loading, setLoading] = useState<boolean>(false);
  const onSendFeedback = () => {
    setLoading(true);
    fireMixpanelEvent(
      subscription,
      contract,
      clickedCancellationReasonButton(TrackingButtonName.SEND_FEEDBACK, selectedCancellationReason)
    );
    getSaveDeskOffers(contract.id, subscription.supplier.id)
      .then((offers: SaveDeskOfferForUser[]) => {
        const selectedOffer = offers.find((offer: SaveDeskOfferForUser) =>
          offer.cancellationReasons.includes(selectedCancellationReason)
        );
        if (selectedOffer !== undefined) {
          dispatch(SetOffer(selectedOffer));
          setLoading(false);
          dispatch(SetSentFeedback(true));
        } else {
          goToCancelForm();
          onClose();
        }
      })
      .catch((error: Error) => {
        Sentry.captureExceptionWithMessage(error, 'Unable to fetch saveDesk offers');
        onClose();
      });
  };
  const onGoToCancellationFlow = () => {
    fireMixpanelEvent(
      subscription,
      contract,
      clickedCancellationReasonButton('I dont want to answer', selectedCancellationReason)
    );
    goToCancelForm();
  };

  useMountEffect(() => {
    fireMixpanelEvent(subscription, contract, viewedCancellationReasonDialog);
    getCancellationReasonsForSaveDesk(contract.id, contract.service.category.name)
      .then((cancellationReasons: CancellationReason[]) => {
        setCancellationReasons(cancellationReasons);
      })
      .catch((error: Error) => {
        onGoToCancellationFlow();
        Sentry.captureExceptionWithMessage(error, 'Unable to fetch cancellation reasons');
      });
  });

  return (
    <CancellationReasonDialogComponent
      dialogOpen={open}
      onClose={onClose}
      cancellationReasons={cancellationReasons}
      onSendFeedback={onSendFeedback}
      selectedCancellationReason={selectedCancellationReason}
      onSelectCancellationReason={(cancellationReason: CancellationReason) =>
        dispatch(SetCancellationReason(cancellationReason))
      }
      onGoToCancellationFlow={onGoToCancellationFlow}
      loading={loading}
      providerName={subscription.supplier.name}
    />
  );
};

export const CancellationReasonDialog = withLocalization('save-desk')(CancellationReasonDialogInner);
