import { BROADBAND, MOBILE } from '@client/constants/categories';
import { withLocalization } from '@client/internationalization';
import { OrderState } from '@client/models/order';
import type { Category } from '@client/models/service';
import type { Currency } from '@client/models/subscription';
import type { AppFeatureToggles } from '@client/modules/app-context';
import { Market } from '@client/modules/app-context/constants';
import { ORDER_ACCEPTED } from '@client/modules/overview/OverviewPage/components/SubscriptionList/SubscriptionListItem/SubscriptionChanged/subscriptionChangeUtils';
import { AmountPerInterval } from '@client/modules/subscription/components/AmountPerInterval';
import { ContractDetailsFloatingButtons } from '@client/modules/subscription/ContractDetailsPage/components/ContractDetailsFloatingButtons';
import { ContractOrderOutcome } from '@client/modules/subscription/ContractDetailsPage/components/ContractOrderOutcome';
import type { OptimizationInfo } from '@client/modules/subscription/types';
import type {
  OrderedBroadbandProduct,
  OrderedGenericProduct,
  OrderedMobileProduct,
  OrderedMobileProductLegacy,
  OrderedProduct,
  OrderedSubscription,
} from '@client/modules/switch/ordered-subscription/models';
import { OngoingOrderCard } from '@client/modules/switch/ordered-subscription/OngoingOrderCard';
import { isShallowCategory } from '@client/modules/switch/ordered-subscription/utils';
import { overviewPage } from '@client/routes';
import { TrackingPageName } from '@client/tracking/mixpanel-constants';
import { ContractHeaderDeprecated } from '@minna-technologies/minna-ui/components/ContractHeaderDeprecated';
import { CardLayout } from '@minna-technologies/minna-ui/components/Layouts/CardLayout';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import type { History } from 'history';
import React from 'react';
import { OrderedSubscriptionDetails } from '../OrderedSubscriptionDetails';

interface OrderedSubscriptionPageProps {
  orderedSubscription: OrderedSubscription;
  currency: Currency;
  goToTermsPage(): void;
  onBackClick(): void;
  history: History;
  features: AppFeatureToggles;
  market: Market;
  sentRating?: boolean;
}

const OrderedSubscriptionPageInner: React.FunctionComponent<OrderedSubscriptionPageProps> = ({
  orderedSubscription,
  features,
  history,
  currency,
  market,
  goToTermsPage,
  sentRating,
}) => {
  const orderSucceeded = orderedSubscription.userMessage.message.type === ORDER_ACCEPTED;
  const isIndependentOrder = !orderedSubscription.oldSupplierName;
  const maybeMonthlyCost = getMonthlyCost(orderedSubscription.product, market);
  const optimizationInfo: OptimizationInfo = {
    orderState: OrderState.AcceptedBySupplier,
    orderSucceeded: orderSucceeded,
    newSupplierName: orderedSubscription.product.supplierInformation.name,
    portingDate: orderedSubscription.portingOrStartDate,
    newProductName: orderedSubscription.product.information.name,
    waitingForTerminationInformation: false,
    orderId: orderedSubscription.orderId,
    orderRequestDate: orderedSubscription.actionRequestedAt,
    userRating: orderedSubscription.userRating,
  };

  const content = maybeMonthlyCost && (
    <AmountPerInterval
      cost={{ amount: maybeMonthlyCost, currency: currency }}
      paymentInterval={{ amount: 1, unit: 'month' }}
    />
  );

  return (
    <MultiDeviceLayout
      hasTopNavigation={false}
      fullWidthContent={
        <ContractHeaderDeprecated
          providerLogo={orderedSubscription.product.supplierInformation.logoUrl ?? ''}
          providerName={orderedSubscription.product.supplierInformation.name}
          subHeading={orderedSubscription.product.supplierInformation.name}
          title={getOrderedSubscriptionTitle(orderedSubscription.product.category)}
          content={content}
        />
      }
      footer={
        <ContractDetailsFloatingButtons
          backButton={!!features.identify?.overview}
          customGoBack={() => {
            history.push(overviewPage);
          }}
        />
      }
    >
      <CardLayout>
        {orderSucceeded && (
          <ContractOrderOutcome
            oldServiceProviderName={orderedSubscription.oldSupplierName}
            optimizationInfo={optimizationInfo}
            pageName={TrackingPageName.ORDERED_SUBSCRIPTION}
            category={orderedSubscription.product.category[0].name}
            sentRating={sentRating}
          />
        )}
        {!orderSucceeded && (
          <OngoingOrderCard
            isIndependentOrder={isIndependentOrder}
            oldServiceProviderName={orderedSubscription.oldSupplierName}
            waitingForCancellationInfo={orderedSubscription.waitingForTerminationInformation}
            newServiceProviderName={orderedSubscription.product.supplierInformation.name}
            categoryName={orderedSubscription.product.category[0].name}
            orderIsAccepted={orderSucceeded}
            actionRequestDate={orderedSubscription.actionRequestedAt}
            portingOrStartDate={orderedSubscription.portingOrStartDate}
            hasCancellation={Boolean(orderedSubscription.actionRequestedAt)}
          />
        )}
        <OrderedSubscriptionDetails goToTermsPage={goToTermsPage} orderedSubscription={orderedSubscription} />
      </CardLayout>
    </MultiDeviceLayout>
  );
};

export const OrderedSubscriptionPage = withLocalization('orderedSubscription/OrderedSubscriptionPage')(
  OrderedSubscriptionPageInner
);

const getOrderedSubscriptionTitle = (category: Category[]) => {
  if (category.length === 1) {
    return category[0].text;
  } else {
    return category.map((c) => c.text).join(' + ');
  }
};

const getMonthlyCost = (orderedProduct: OrderedProduct, market: Market) => {
  // Here we assume that the only case we can have multiple categories is for energy products
  const category = orderedProduct.category[0].name;

  switch (category) {
    case MOBILE:
      if (market === Market.Sweden) {
        const mobileProduct = orderedProduct as OrderedMobileProductLegacy;

        return mobileProduct.pricing.monthlyCost;
      } else {
        const mobileProduct = orderedProduct as OrderedMobileProduct;

        return mobileProduct.information.monthlyCost.amount;
      }
    case BROADBAND: {
      const broadbandProduct = orderedProduct as OrderedBroadbandProduct;

      return broadbandProduct.price.fullMonthlyCost;
    }
    default:
      if (isShallowCategory(category)) {
        const genericProduct = orderedProduct as OrderedGenericProduct;

        return genericProduct.monthlyCost.amount;
      } else {
        // We don't want to show cost for estimated prices for like electricity and gas contracts
        return undefined;
      }
  }
};
