import { useLocalization } from '@client/internationalization';
import type { Contract, ContractBindingPeriod, Subscription } from '@client/modules/subscription/types';
import { BindingPeriodType } from '@client/modules/subscription/types';
import type { TrackingButtonLocation } from '@client/tracking/mixpanel-constants';
import { TrackingPageName } from '@client/tracking/mixpanel-constants';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CardLink } from '@minna-technologies/minna-ui/components/CardLink';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { ReminderIcon } from '@minna-technologies/minna-ui/icons/Reminder';
import moment from 'moment';
import type { FC } from 'react';
import React, { useState } from 'react';
import { clickedContractActionMixpanelEventDeprecated, viewPageMixpanelEvent } from '../../../mixpanel';
import { ReminderDialog } from '../ReminderDialog';

const useStyles = makeStyles({
  actionButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '10px 0',
  },

  iconContainer: {
    marginRight: '16px',

    img: {
      height: '82px',
      marginTop: '12px',
    },
  },

  textContainer: {
    flexGrow: 1,
  },
});

interface Props {
  contract: Contract;
  subscription: Subscription;
  onSaveContractBinding(contractBindingPeriod: ContractBindingPeriod): void;
}

export const isBindingPeriodBound = (contractBindingPeriod: ContractBindingPeriod): boolean =>
  contractBindingPeriod.type === BindingPeriodType.FixedContractBindingPeriod && !!contractBindingPeriod.endsAt;

export const SubscriptionReminder: FC<Props> = ({ contract, subscription, onSaveContractBinding }) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const { localizeMessage } = useLocalization('contract/SubscriptionReminder');

  const [openBindingTimeDialog, setOpenBindingTimeDialog] = useState(false);

  const isBound = isBindingPeriodBound(contract.contractBindingPeriod);
  const formattedDate = moment(contract.contractBindingPeriod.endsAt).format('YYYY-MM-DD');

  function onClickBindingPeriodCard() {
    clickedContractActionMixpanelEventDeprecated(contract, subscription, 'Manage binding period');
    setOpenBindingTimeDialog(true);
    onViewBindingPeriodDialog();
  }

  function onCloseBindingPeriodDialog(buttonLocation: TrackingButtonLocation) {
    clickedContractActionMixpanelEventDeprecated(
      contract,
      subscription,
      'Close',
      TrackingPageName.MANAGE_BINDING_PERIOD,
      buttonLocation
    );
    setOpenBindingTimeDialog(false);
  }

  function onViewBindingPeriodDialog() {
    const isBindingTimeSet = contract.contractBindingPeriod.type === BindingPeriodType.FixedContractBindingPeriod;
    viewPageMixpanelEvent(subscription, TrackingPageName.MANAGE_BINDING_PERIOD, {
      Category: contract.service.category.name,
      'Binding time set': isBindingTimeSet,
      'Monthly Cost': subscription?.cost.amount,
    });
  }

  return (
    <>
      <CardLink onClick={onClickBindingPeriodCard} data-test="subscription-reminder-link">
        <div className={classes.actionButtonContainer}>
          <div className={classes.iconContainer}>
            <ReminderIcon nativeColor={colors.action.secondary} />
          </div>

          <div className={classes.textContainer}>
            <Title>
              {isBound
                ? localizeMessage('subscriptionReminderContractExpiringTitle')
                : localizeMessage('subscriptionReminderDefaultTitle')}
            </Title>
            <Body color={colors.base.border}>
              {isBound ? formattedDate : localizeMessage('subscriptionReminderDefaultBody')}
            </Body>
          </div>
        </div>
      </CardLink>

      <ReminderDialog
        onClose={onCloseBindingPeriodDialog}
        open={openBindingTimeDialog}
        contract={contract}
        onSaveContractBinding={onSaveContractBinding}
      />
    </>
  );
};
