import type { Subscription } from '@client/modules/subscription/types';
import { makeStyles } from '@material-ui/core';
import type { FC } from 'react';
import React from 'react';
import { NoSubscriptionsSection } from './NoSubscriptionsSection';
import { SubscriptionList } from './SubscriptionList';

const useStyles = makeStyles({
  content: {
    width: '100%',
  },
});

interface OverviewContentComponentProps {
  subscriptions: Subscription[];
  orderedSubscriptions: any;
  onAddManualSubscription(): void;
}

export const OverviewContentComponent: FC<OverviewContentComponentProps> = ({
  subscriptions,
  orderedSubscriptions,
  onAddManualSubscription,
}) => {
  const classes = useStyles();

  return (
    <>
      <div data-test="overview-content-container">
        {subscriptions.length === 0 && orderedSubscriptions.length === 0 ? (
          <NoSubscriptionsSection goToAddManualSubscription={onAddManualSubscription} />
        ) : (
          <div className={classes.content}>
            <SubscriptionList />
          </div>
        )}
      </div>
    </>
  );
};
