import { Sentry } from '@client/assets/js/sentry';
import type { SelectableService, ServiceToDisplay } from '@client/models/service';
import { fetchSupplier } from '@client/models/supplier';
import { setUpdatedSubscription, subscriptionLoadingFailure } from '@client/modules/subscription';
import {
  selectorServicesToDisplay,
  supplierLoadingSuccess,
  updateSelectedServices,
} from '@client/modules/subscription/duck';
import {
  buttonClickedSubscriptionPageMixpanelEvent,
  viewSubscriptionPageMixpanelEvent,
} from '@client/modules/subscription/mixpanel';
import { SelectServicesPageComponent } from '@client/modules/subscription/SelectServicesPage/component';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import { ContractState } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { updateContractsOnSubscription } from '../model';

export interface SelectServicesPageProps {
  subscription: Subscription;
}

const contractToSelectableService = (contract: Contract): SelectableService => ({
  serviceId: contract.service.id,
  contractId: contract.id,
  selected: true,
  displayId: 0,
});

export const SelectServicesPage: React.FunctionComponent<SelectServicesPageProps> = ({
  subscription,
}: SelectServicesPageProps) => {
  const servicesToDisplay: any[] = useSelector(selectorServicesToDisplay);
  const [isLoading, setIsLoading] = useState(true);
  const pageName = 'Select services';
  const dispatch = useDispatch();
  const history = useHistory();
  async function onContinue(services: ServiceToDisplay[]) {
    buttonClickedSubscriptionPageMixpanelEvent(subscription, 'Save', pageName);

    const serviceIdsOfContractsToBeCreated = services
      .filter((service) => service.selected && !service.contractId && service.serviceId)
      .map((service) => service.serviceId as string);

    const contractIdsOfContractsToBeDeleted = services
      .filter((service) => !service.selected && service.contractId && service.serviceId)
      .map((service) => service.contractId);

    return updateContractsOnSubscription(
      subscription.id,
      serviceIdsOfContractsToBeCreated,
      contractIdsOfContractsToBeDeleted
    )
      .then((resultSubscription: Subscription) => {
        dispatch(setUpdatedSubscription(resultSubscription));

        if (resultSubscription.singleContractId) {
          history.push(urls.contractDetailsPage(resultSubscription.id, resultSubscription.singleContractId));
        } else {
          history.push(urls.subscriptionPage(resultSubscription.id));
        }
      })
      .catch((error: Error) => {
        dispatch(subscriptionLoadingFailure());
        Sentry.captureExceptionWithMessage(error, 'Failed to update contracts on subscription in select services', {
          extra: { subscriptionId: subscription.id },
        });
      });
  }
  useEffect(() => {
    if (isLoading) {
      fetchSupplier(subscription.supplier.id)
        .then((supplier) => {
          const currentlySelectedServices = subscription.contracts
            .filter((contract: Contract) => contract.state !== ContractState.Terminated)
            .map(contractToSelectableService);

          supplier.services.forEach((service) => {
            if (!currentlySelectedServices.find((tempService) => tempService.serviceId === service.id)) {
              currentlySelectedServices.push({ serviceId: service.id, contractId: '', selected: false, displayId: 0 });
            }
          });
          currentlySelectedServices.forEach((service: SelectableService, index: number) => {
            service.displayId = index;
          });
          dispatch(updateSelectedServices(currentlySelectedServices));
          dispatch(supplierLoadingSuccess(supplier));
          setIsLoading(false);
        })
        .catch((error: any) => {
          Sentry.captureExceptionWithMessage(error, 'Failed to load supplier in select services', {
            extra: { supplierId: subscription.supplier.id },
          });
        });
    } else {
      if (subscription) {
        viewSubscriptionPageMixpanelEvent(subscription, pageName);
      }
    }
  }, [dispatch, isLoading, subscription]);

  return (
    <>
      {isLoading ? null : (
        <SelectServicesPageComponent
          subscription={subscription}
          servicesToDisplay={servicesToDisplay}
          onContinue={onContinue}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
