import type { SupplierWithServices } from '@client/models/supplier';
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/fp/isEmpty';
import includes from 'lodash/includes';
import take from 'lodash/take';
import union from 'lodash/union';
import words from 'lodash/words';

export function searchSuppliers(rawQuery: string, suppliers: SupplierWithServices[]): SupplierWithServices[] {
  const suppliersMatchingWithoutSpaces = searchSuppliersWithStringMatch(rawQuery, suppliers);

  const suppliersMatchingOnAbbreviations = searchSuppliersByAbbreviation(rawQuery, suppliers);

  const suppliersWithSpecialCases = searchSuppliersWithSpecialCases(rawQuery, suppliers);

  const suppliersMatchingOnMisspelling = searchSuppliersWithoutDoubleLetters(rawQuery, suppliers);

  return union(
    concat(
      suppliersMatchingOnAbbreviations,
      suppliersWithSpecialCases,
      suppliersMatchingWithoutSpaces,
      suppliersMatchingOnMisspelling
    )
  );
}

export function searchSuppliersWithStringMatch(
  rawQuery: string,
  suppliers: SupplierWithServices[]
): SupplierWithServices[] {
  const query = cleanQuery(rawQuery);
  return isEmpty(query)
    ? []
    : _(suppliers)
        .filter((supplier) => includes(stringWithoutWhitespaces(supplier.name), stringWithoutWhitespaces(query)))
        .sortBy((supplier) => supplier.name.indexOf(query))
        .value();
}

const abbreviate = (s: string): string =>
  _(s.toLowerCase())
    .words()
    // @ts-ignore
    .flatMap((word: string) => take(word))
    .join('');

export function searchSuppliersByAbbreviation(
  rawQuery: string,
  suppliers: SupplierWithServices[]
): SupplierWithServices[] {
  const query = cleanQuery(rawQuery);
  return isEmpty(query)
    ? []
    : filter(suppliers, (supplier) => (words(supplier.name).length > 1 ? abbreviate(supplier.name) === query : false));
}

export function searchSuppliersWithoutDoubleLetters(
  rawQuery: string,
  suppliers: SupplierWithServices[]
): SupplierWithServices[] {
  const query = cleanQuery(rawQuery);
  return isEmpty(query)
    ? []
    : filter(suppliers, (supplier) =>
        includes(stringWithoutDoubleLetters(supplier.name.toLowerCase()), stringWithoutDoubleLetters(query))
      );
}

function searchSuppliersWithSpecialCases(rawQuery: string, suppliers: SupplierWithServices[]): SupplierWithServices[] {
  const query = cleanQuery(rawQuery);
  const suppliersWithSpecialCases = [];
  if (query === 'tre') {
    const supplierNamed3 = find(suppliers, { name: '3 (Hi3G)' });
    if (supplierNamed3) {
      suppliersWithSpecialCases.push(supplierNamed3);
    }
  }

  return suppliersWithSpecialCases;
}

function cleanQuery(query: string): string {
  return (query || '').toLowerCase().trim();
}

function stringWithoutWhitespaces(input: string): string {
  return input.toLowerCase().replace(/\s/g, '');
}

function stringWithoutDoubleLetters(input: string): string {
  return (
    _(input)
      .words()
      // @ts-ignore
      .flatMap((word: string) => union(word.split('')))
      .join('')
  );
}
