import HeaderBackground from '@client/assets/images/header-background.svg';
import Stars from '@client/assets/images/stars.svg';
import { makeStyles } from '@material-ui/core';
import type { ClassNameMap } from '@material-ui/styles';
import React from 'react';

export enum ConsentPageHeaderType {
  GenericHeader,
  OldHeader,
  TransparentHeader,
}

interface ConsentPageHeaderProps {
  illustration?: string;
  altText: any;
  headerType: ConsentPageHeaderType;
  icon?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '30px',
    borderBottomLeftRadius: '150% 30%',
    borderBottomRightRadius: '150% 30%',
    backgroundColor: theme.colors.action.primary,
  },
  headerOld: {
    backgroundImage: `url(${Stars}), url(${HeaderBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain, cover',
    backgroundOrigin: 'borderBox',
    backgroundPosition: 'center center, center bottom',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '57px 68px',
  },
  headerTransparent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    padding: '32px 56px 32px 56px',
  },
}));

export const ConsentPageHeader = ({ illustration, altText, headerType, icon }: ConsentPageHeaderProps) => {
  const classes = useStyles();
  const className = getClassName(headerType, classes);
  return (
    <div className={className} data-test={'consent-page-header'}>
      {illustration && <img src={illustration} alt={altText} data-test={'consent-header-illustration'} />}
      {icon}
    </div>
  );
};

function getClassName(headerType: ConsentPageHeaderType, classes: ClassNameMap) {
  switch (headerType) {
    case ConsentPageHeaderType.OldHeader:
      return classes.headerOld;
    case ConsentPageHeaderType.GenericHeader:
      return classes.header;
    case ConsentPageHeaderType.TransparentHeader:
      return classes.headerTransparent;
    default:
      return classes.header;
  }
}
