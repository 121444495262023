import { Sentry } from '@client/assets/js/sentry';
import { LocalizedMessage } from '@client/internationalization';
import { ConfirmedByUserContent } from '@client/modules/cancellation/CancellationOutcome/components/ConfirmedByUserContent';
import { NoAnswerFromSupplierContent } from '@client/modules/cancellation/CancellationOutcome/components/NoAnswerFromSupplierContent';
import { NoAnswerFromUserContent } from '@client/modules/cancellation/CancellationOutcome/components/NoAnswerFromUserContent';
import { NotACancellableSubscriptionContent } from '@client/modules/cancellation/CancellationOutcome/components/NotACancellableSubscriptionContent';
import { PayingThroughThirdPartyContent } from '@client/modules/cancellation/CancellationOutcome/components/PayingThroughThirdPartyContent';
import { ServiceProviderContactedUserContent } from '@client/modules/cancellation/CancellationOutcome/components/ServiceProviderContactedUser';
import { CancelledWithUpcomingPaymentsContent } from '@client/modules/cancellation/CancellationOutcome/components/SuccessfulCancellationWithUpcomingPaymentsContent';
import { UserNotTheOwnerContent } from '@client/modules/cancellation/CancellationOutcome/components/UserNotTheOwnerContent';
import { UserRegrettedCancellationContent } from '@client/modules/cancellation/CancellationOutcome/components/UserRegrettedCancellationContent';
import { WrongUserInputContent } from '@client/modules/cancellation/CancellationOutcome/components/WrongUserInputContent';
import { InsightCancellationOutcome } from '@client/modules/InsightsPage/models';
import type { CancellationInfo, ContractId, SubscriptionId } from '@client/modules/subscription/types';
import React from 'react';
import { BindingTimeContent } from './components/BindingTimeContent';
import { NoSuchAccountContent } from './components/NoSuchAccountContent';
import { SuccessfulCancellationContent } from './components/SuccessfulCancellationContent';
import type { TrackingPageName } from '@client/tracking/mixpanel-constants';

export interface CancellationOutcomeCardContent {
  headline: React.ReactNode;
  content?: React.ReactNode;
  askForRating: boolean;
}

export const createCancellationOutcomeInfo = (
  colors: any,
  outcome: InsightCancellationOutcome,
  serviceProviderName: string,
  serviceProviderId: string,
  pageName: TrackingPageName,
  cancellationInfo: CancellationInfo,
  contractId?: ContractId,
  subscriptionId?: SubscriptionId,
  closeInsight?: () => void
): CancellationOutcomeCardContent | undefined => {
  switch (outcome) {
    case InsightCancellationOutcome.AlreadyCancelled:
      return {
        headline: <LocalizedMessage id="alreadyCancellationSubtitle" />,
        content: (
          <SuccessfulCancellationContent
            serviceProviderName={serviceProviderName}
            outcome={outcome}
            messageFromProvider={cancellationInfo.messageToUser}
            yearlySavings={cancellationInfo.yearlySavings}
          />
        ),
        askForRating: true,
      };
    case InsightCancellationOutcome.Cancelled:
      return {
        headline: <LocalizedMessage id="successfulCancellationSubtitle" />,
        content: (
          <SuccessfulCancellationContent
            serviceProviderName={serviceProviderName}
            outcome={outcome}
            messageFromProvider={cancellationInfo.messageToUser}
            yearlySavings={cancellationInfo.yearlySavings}
          />
        ),
        askForRating: true,
      };
    case InsightCancellationOutcome.ConfirmedByUser:
      return {
        headline: <LocalizedMessage id="ConfirmedByUserTitle" values={{ merchantName: serviceProviderName }} />,
        // For IntelligentGuide cancellations cancellationRequestDate is the date the user confirmed they cancelled the service
        // The vast majority of ConfirmedByUser cancellations are from IG, but this date might look weird for a MO cancellation
        content: <ConfirmedByUserContent cancelledAt={cancellationInfo.cancellationRequestDate} />,
        askForRating: false,
      };
    case InsightCancellationOutcome.UserWithdrawn:
      return {
        headline: <LocalizedMessage id="userRegretCancellationSubtitle" />,
        content: <UserRegrettedCancellationContent messageFromSupport={cancellationInfo.messageToUser} />,
        askForRating: true,
      };
    case InsightCancellationOutcome.NoResponseFromServiceProvider:
      return {
        headline: (
          <LocalizedMessage
            id="noAnswerFromSupplierCancellationSubtitle"
            values={{ supplierName: serviceProviderName }}
          />
        ),
        content: <NoAnswerFromSupplierContent pageName={pageName} serviceProviderName={serviceProviderName} />,
        askForRating: true,
      };
    case InsightCancellationOutcome.NoSuchAccount:
      return {
        headline: (
          <LocalizedMessage id="noSuchAccountCancellationSubtitle" values={{ supplierName: serviceProviderName }} />
        ),
        content: (
          <NoSuchAccountContent
            subscriptionId={subscriptionId}
            contractId={contractId}
            pageName={pageName}
            serviceProviderName={serviceProviderName}
            messageFromProvider={cancellationInfo.messageToUser}
            onTryAgainClick={closeInsight}
          />
        ),
        askForRating: true,
      };
    case InsightCancellationOutcome.HasBindingPeriod:
      return {
        headline: (
          <LocalizedMessage id="genericFailedCancellationSubtitle" values={{ supplierName: serviceProviderName }} />
        ),
        content: (
          <BindingTimeContent
            serviceProviderName={serviceProviderName}
            bindingTimeEndsAt={cancellationInfo.bindingPeriodEndsAt}
            messageFromProvider={cancellationInfo.messageToUser}
          />
        ),
        askForRating: true,
      };
    case InsightCancellationOutcome.NotACancellableSubscription:
      return {
        headline: (
          <LocalizedMessage
            id="notACancellableSubscriptionCancellationSubtitle"
            values={{ supplierName: serviceProviderName }}
          />
        ),
        content: <NotACancellableSubscriptionContent pageName={pageName} serviceProviderName={serviceProviderName} />,
        askForRating: true,
      };
    case InsightCancellationOutcome.PayingThroughThirdParty:
      return {
        headline: (
          <LocalizedMessage id="genericFailedCancellationSubtitle" values={{ supplierName: serviceProviderName }} />
        ),
        content: <PayingThroughThirdPartyContent />,
        askForRating: true,
      };
    case InsightCancellationOutcome.NoAnswerFromUser:
      return {
        headline: <LocalizedMessage id="noAnswerFromUserCancellationSubtitle" />,
        content: <NoAnswerFromUserContent pageName={pageName} serviceProviderName={serviceProviderName} />,
        askForRating: true,
      };
    case InsightCancellationOutcome.CancelledWithUpcomingPayments:
      return {
        headline: <LocalizedMessage id="cancelledWithUpcomingPaymentsTitle" />,
        content: (
          <CancelledWithUpcomingPaymentsContent
            serviceProviderName={serviceProviderName}
            bindingTimeEndsAt={cancellationInfo.bindingPeriodEndsAt}
            lastPaymentAt={cancellationInfo.lastPaymentAt}
          />
        ),
        askForRating: true,
      };
    case InsightCancellationOutcome.UserNotTheOwner:
      return {
        headline: <LocalizedMessage id="UserNotTheOwnerTitle" />,
        content: <UserNotTheOwnerContent serviceProviderName={serviceProviderName} />,
        askForRating: true,
      };
    case InsightCancellationOutcome.WrongUserInput:
      return {
        headline: <LocalizedMessage id="WrongUserInputTitle" />,
        content: <WrongUserInputContent serviceProviderName={serviceProviderName} />,
        askForRating: true,
      };
    case InsightCancellationOutcome.ServiceProviderContactedUser:
      return {
        headline: (
          <LocalizedMessage id="ServiceProviderContactedUserTitle" values={{ providerName: serviceProviderName }} />
        ),
        content: <ServiceProviderContactedUserContent serviceProviderName={serviceProviderName} />,
        askForRating: true,
      };
    default:
      Sentry.captureMessage(`Don´t know how to handle outcome ${outcome} in CancellationOutcomeInsight`);

      return undefined;
  }
};
