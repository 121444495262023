import type { LocalizeMessage } from '@client/internationalization';
import { LocalizedMessage } from '@client/internationalization';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { RHTextArea } from '@minna-technologies/minna-ui/components/Form/RHTextArea';
import { RHTextInput } from '@minna-technologies/minna-ui/components/Form/RHTextInput';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ZendeskSubHeader } from '../../../ZendeskSubHeader';
import styles from './styles.scss';

interface FormValues {
  displayName: string;
  email: string;
  message: string;
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(2, 1),
  },
  subHeader: {
    marginBottom: theme.spacing(3),
  },
  messageDescriptionHeader: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface FirstMessageProps {
  name: string;
  email: string;
  message: string;
  isAgentAvailable: boolean;
  onChange: (key: string, value: string) => void;
  onStartChat: (name: string, email: string, message: string) => void;
}

export const FirstMessage: React.FC<FirstMessageProps> = (
  { name, email, message, isAgentAvailable, onChange, onStartChat },
  { localizeMessage }: { localizeMessage: LocalizeMessage }
) => {
  const { colors } = useTheme();
  const methods = useMinnaForm<FormValues>();
  const classes = useStyles();

  const onValidSubmit = async () => {
    const messageTrimmed = message.trim();
    if (messageTrimmed) {
      await onStartChat(name, email, messageTrimmed);
      onChange('message', '');
    } else {
      onChange('message', '');
    }
  };
  const buttonMargin = { marginTop: '16px' };

  return (
    <div className={classes.formContainer}>
      <Form onSubmit={async () => onValidSubmit()} formMethods={methods}>
        <Card className={classNames(styles.paper)} variant="border">
          <div className={classes.subHeader}>
            <ZendeskSubHeader title={<LocalizedMessage id="chatFirstMessageSubtitle" />} />
          </div>
          <div className={styles['field-container']}>
            <RHTextInput
              name="displayName"
              label={<LocalizedMessage id="chatFirstMessageNameInputLabel" />}
              fullWidth
              value={name}
              validation={{
                required: localizeMessage('chatFirstMessageRequiredNameError'),
              }}
              onChange={({ target: { value } }) => onChange('name', value)}
            />
          </div>
          <div className={styles['field-container']}>
            <RHTextInput
              type="email"
              name="email"
              label={localizeMessage('chatFirstMessageEmailInputLabel')}
              fullWidth
              value={email}
              onChange={({ target: { value } }) => onChange('email', value)}
            />
          </div>
          <Body color={colors.textOn.surfaceSubdued} className={classes.messageDescriptionHeader}>
            <LocalizedMessage id="chatFirstMessageMessageDescription" />
          </Body>
          <div className={styles['field-container']}>
            <RHTextArea
              name="message"
              label={<LocalizedMessage id="chatFirstMessageMessageInputLabel" />}
              fullWidth
              validation={{
                required: localizeMessage('chatFirstMessageRequiredMessageError'),
              }}
              onChange={({ target: { value } }) => onChange('message', value)}
              disabled={!isAgentAvailable}
            />
          </div>
          <div style={buttonMargin}>
            {!isAgentAvailable && (
              <div className={styles['status-bar']}>
                <LocalizedMessage id="chatFirstMessageAgentOffline1" />
                <LocalizedMessage id="chatFirstMessageAgentOffline2" />
                <LocalizedMessage id="chatFirstMessageAgentOffline3" />
              </div>
            )}
            <div className={classes.submitButton}>
              <PrimaryButton
                type="submit"
                label={<LocalizedMessage id="chatFirstMessageStartChatButton" />}
                disabled={!isAgentAvailable}
              />
            </div>
          </div>
        </Card>
      </Form>
    </div>
  );
};

//@ts-ignore
FirstMessage.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
