import React from 'react';
import styles from './styles.scss';

export interface BasicPaperProps {
  style?: any;
  children?: React.ReactNode;
  onClick?(): void;
  className?: string;
  flat?: boolean;
  'data-test'?: string;
}

export const BasicPaper: React.ComponentType<BasicPaperProps> = (props: BasicPaperProps) => {
  const classes = [!props.flat ? styles.raisedBox : '', styles.flatBox, props.className ?? ''].join(' ');

  return (
    <div className={classes} style={props.style} onClick={props.onClick} role="button" data-test={props['data-test']}>
      {props.children}
    </div>
  );
};
