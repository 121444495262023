export const costWithDecimalsRegex = /^[0-9]\d*(?:[.|,]\d{1,2})?$/;

export const removeSpaces = (value: string): string => value.replace(/\s+/g, '');

export const formatAmountWithDecimals = (amount: string | number): string => {
  const stringAmount = removeSpaces(amount.toString());

  return costWithDecimalsRegex.test(stringAmount) ? stringAmount.replace(',', '.') : stringAmount;
};

export const parseAmountWithDecimals = (amount: string): number => parseFloat(formatAmountWithDecimals(amount));
