import PropTypes from 'prop-types';
import React from 'react';
import {wrapDisplayName} from 'recompose';
import {ReactReduxContext} from 'react-redux';
import {__RouterContext as RouterContext} from 'react-router-dom';

/**
 * @deprecated `useEffect` can be used instead
 *
 * HOC for componentWillMount
 * onEnterFunc has access to the props as well as the store and router
 */
export const onEnter = (onEnterFunc) => (WrappedComponent) => {
  class OnEnter extends React.Component {
    static displayName = wrapDisplayName(WrappedComponent, 'OnEnter');

    static propTypes = {
      __store: PropTypes.object,
      __router: PropTypes.object
    };

    UNSAFE_componentWillMount() {
      onEnterFunc(this.props, {store: this.props.__store, router: this.props.__router});
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const {__store, __router, ...rest} = this.props;
      return <WrappedComponent { ...rest } />;
    }
  }

  return function withReduxStore(props) {
    return (
      <RouterContext.Consumer>
        {router => (
          <ReactReduxContext.Consumer>
            {({store}) => <OnEnter { ...props } __store={ store } __router={ router } />}
          </ReactReduxContext.Consumer>
        )}
      </RouterContext.Consumer>
    );
  };
};
