import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { forceLoadMessagesAndLocale } from '@client/internationalization/load';
import type { Locale } from '@client/modules/app-context/constants';
import { AppType } from '@client/modules/app-context/constants';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { ErrorComponent } from '../ErrorComponent';

const GenericErrorPage: React.FunctionComponent = (_, { localizeMessage }) => {
  const hasMessages = useCallback(() => localizeMessage('genericHeaderText') !== 'MISSING', [localizeMessage]);

  /*
   * We know nothing about the user at this point. Trying to fetch messages based on the browser locale,
   * else we fall back to English.*/
  useEffect(() => {
    if (!hasMessages()) {
      try {
        const browserLocale = navigator.language as Locale;
        forceLoadMessagesAndLocale(browserLocale, AppType.Undefined);
      } catch (error) {
        return;
      }
    }
  }, [hasMessages]);

  return hasMessages() ? (
    <ErrorComponent
      headerText={<LocalizedMessage id="genericHeaderText" />}
      imageAltText={localizeMessage('imageAltText')}
      bodyText={<LocalizedMessage id="genericBodyText" />}
    />
  ) : (
    <ErrorComponent
      headerText="Something went wrong"
      imageAltText="Something went wrong"
      bodyText="We’re working on it. Please try again."
    />
  );
};

GenericErrorPage.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const GenericErrorPageContainer = withLocalization('ErrorScreen')(GenericErrorPage);
