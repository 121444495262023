import React from 'react';
import PageNavigationBar from '@client/shared-components/PageNavigationBar';
import { useSelector } from 'react-redux';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { LABEL_FAQ_GENERAL } from '@client/zendesk';
import minnaLogo from '@client/assets/images/minna-technologies-horizontal-rgb.svg';
import { ZendeskHeaderHelpButton } from '@client/shared-components/Header/ZendeskHeaderHelpButton';
import { sendClickedZendeskButton } from '@client/modules/subscription-hub/mixpanel';

export const Header: React.FC = () => {
  const features = useSelector(selectorFeatures);
  const logo = features?.navigation?.pageNavigationLogo ? minnaLogo : undefined;

  const helpIcon = <ZendeskHeaderHelpButton query={LABEL_FAQ_GENERAL} sideEffect={sendClickedZendeskButton} />;

  return <PageNavigationBar logo={logo} iconRight={helpIcon} />;
};
