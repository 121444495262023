import * as urls from '@client/routes';
import { getLogo, getLogoAltText } from '@client/utils/logo';
import createStyles from '@material-ui/core/styles/createStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import React from 'react';
import { Link } from 'react-router-dom';
import type { AppContext } from '../../../modules/app-context';

interface LogoProps {
  isLoggedIn: boolean;
  appContext: AppContext;
  type: 'text' | 'image';
  classes: any;
}

const styles = createStyles({
  logo: {
    maxHeight: '21px',
    '@media(min-width:640px)': {
      paddingRight: '16px',
    },
    userSelect: 'none',
  },
});

export const LogoInner: React.FunctionComponent<LogoProps> = ({ isLoggedIn, appContext, type, classes }) => {
  const { colors } = useTheme();
  const logo = getLogo(appContext);
  const logoAltText = getLogoAltText(appContext);

  return isLoggedIn ? (
    <Link to={urls.landingPage}>
      {type === 'text' ? (
        <Title color={colors.textOn.surfaceSubdued}>{appContext.displayName}</Title>
      ) : (
        <img className={classes.logo} src={logo} alt={logoAltText} />
      )}
    </Link>
  ) : (
    <Link to={urls.landingPage}>
      {type === 'text' ? (
        <Title color={colors.textOn.surfaceSubdued}>{appContext.displayName}</Title>
      ) : (
        <img className={classes.logo} src={logo} alt={logoAltText} />
      )}
    </Link>
  );
};

export const Logo = withStyles(styles)(LogoInner);
