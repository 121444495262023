import { getMixpanel } from '@client/tracking/mixpanel';

export enum FeedbackButtons {
  CLOSEX = 'Close X',
  CONTINUE = 'Continue',
  CLOSE = 'Close',
  SAVE = 'Save',
}

export enum FeedbackCards {
  CSAT = 'CSAT rating',
  NPS = 'NPS rating',
  FEEDBACK = 'Feedback',
}

export enum CsatFeature {
  OVERVIEW = 'Overview',
  CANCEL = 'Cancel',
  SWITCH = 'Switch',
  DISCOVER = 'Discover',
}

export const feedbackCardButtonClicked = (button: FeedbackButtons, type: string): void => {
  getMixpanel().track('Clicked button', {
    Feature: 'Overview',
    Type: type,
    Page: 'Feedback card',
    Location: button === FeedbackButtons.CLOSEX ? 'Top right' : 'Bottom',
    Button: button,
  });
};

export const feedbackCardViewed = (card: FeedbackCards): void => {
  getMixpanel().track('Viewed guide', {
    Feature: 'Overview',
    Type: card,
    Page: 'Feedback card',
  });
};

export const trackCsatScore = (Feature: string, score: number): void => {
  getMixpanel().track('Rated experience', {
    Feature,
    'Feedback type': 'CSAT rating',
    Page: 'Feedback card',
    mp_rating: score,
  });
};

export const trackNpsScore = (score: number): void => {
  getMixpanel().track('Rated experience', {
    Feature: 'NPS Rating',
    'Feedback type': 'NPS rating',
    Page: 'Feedback card',
    mp_rating: score,
  });
};

export const trackFeedbackThankYouMessage = (message: string): void => {
  getMixpanel().track('Rated experience', {
    Feature: 'Overall product',
    'Feedback type': 'Feedback',
    Page: 'Feedback card',
    Value: message,
  });
};
