import { useLocalization } from '@client/internationalization';
import type { Question } from '@client/models/cancellation-questions';
import type { Locale } from '@client/modules/app-context/constants';
import {
  inputFieldSizeClass,
  requiredQuestionLabel,
} from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/logic';
import { detailsByLocale } from '@client/utils/locale';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { RHTextInput } from '@minna-technologies/minna-ui/components/Form/RHTextInput';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import * as React from 'react';
import { useState } from 'react';
import { useStyles } from './styles';

interface TextQuestionProps {
  locale: Locale;
  question: Question;
}

export const TextQuestion: React.FunctionComponent<TextQuestionProps> = ({ locale, question }: TextQuestionProps) => {
  const { localizeMessage } = useLocalization('termination/TerminationFormPage');
  const { colors } = useTheme();
  const { text, description } = detailsByLocale(question.details, locale);
  const required = question.required;
  const label = requiredQuestionLabel(text, required);
  const classes = useStyles();
  const [hintOpen, setHintOpen] = useState(false);
  const abTestHint = (
    <Tooltip
      title={
        <Card className={classes.cardStyle}>
          <Body>{localizeMessage('cancellationQuestionsHintLong')}</Body>
        </Card>
      }
      className={classes.tooltip}
      open={hintOpen}
      onClick={() => setHintOpen(true)}
      onClose={() => setHintOpen(false)}
    >
      <div>
        <Caption className={classes.caption} color={colors.action.primary}>
          {localizeMessage('cancellationQuestionsHint')}
        </Caption>
      </div>
    </Tooltip>
  );
  const showAbTest = false;

  return (
    <>
      <Body className={classes.body}>{description}</Body>
      <div className={inputFieldSizeClass(question, classes)}>
        <RHTextInput
          label={label}
          name={question.id}
          validation={{
            required: required ? localizeMessage('requiredInputGenericError', { inputTitle: text }) : false,
          }}
          fullWidth
        />
      </div>
      {showAbTest && abTestHint}
    </>
  );
};
