import type { Question } from '@client/models/cancellation-questions';
import type { Locale } from '@client/modules/app-context/constants';
import { requiredQuestionLabel } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/logic';
import { detailsByLocale } from '@client/utils/locale';
import { RHDatePicker } from '@minna-technologies/minna-ui/components/Form/RHDatePicker';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useStyles } from './styles';

interface DateQuestionProps {
  locale: Locale;
  question: Question;
}

export const DateQuestion: React.FunctionComponent<DateQuestionProps> = (
  { locale, question }: DateQuestionProps,
  { localizeMessage }
) => {
  const { text, description } = detailsByLocale(question.details, locale);
  const required = question.required;
  const label = requiredQuestionLabel(text, required);
  const classes = useStyles();

  return (
    <>
      <Body className={classes.body}>{description}</Body>
      <div className={classes.large}>
        <RHDatePicker
          name={question.id}
          label={label}
          validation={{ required: required ? localizeMessage('requiredDateError') : false }}
          fullWidth
        />
      </div>
    </>
  );
};

DateQuestion.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
