//@ts-ignore
import { loadingPageWhileLoading } from '@client/containers/container-helpers';
import { selectorIsLoading, selectorUser } from '@client/ducks/user';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withAppContext } from '../../app-context/react';
import { navigateBack, navigateToUserSearchResult } from '../ducks';
import {
  filterArticlesByQuery,
  selectorArticlesUserSearchQuery,
  selectorCategories,
  selectorCommunicationWithZendeskApiFailed,
  selectorLoadingCategories,
  updateArticlesUserSearchQuery,
} from '../ducks/zendesk-help-center';
import { ZendeskHelpCenter } from './component';

const mapStateToProps = (state: any) => {
  return {
    user: selectorUser(state),
    userIsLoading: selectorIsLoading(state),
    categories: selectorCategories(state),
    userSearchQuery: selectorArticlesUserSearchQuery(state),
    loadingCategories: selectorLoadingCategories(state),
    communicationWithApiFailed: selectorCommunicationWithZendeskApiFailed(state),
  };
};

const mapDispatchToProps = (dispatch: any, { appContext }: any) => {
  return {
    onUpdateUserSearchQuery(userSearchQuery: any) {
      dispatch(updateArticlesUserSearchQuery(userSearchQuery));
    },
    onSearchArticles: (userSearchQuery: any) => {
      dispatch(
        filterArticlesByQuery(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, userSearchQuery)
      );
      dispatch(navigateToUserSearchResult());
    },
    onNavigateBack: () => {
      dispatch(navigateBack());
    },
  };
};

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: any) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onSearchArticles: () => {
      const userSearchQuery = stateProps.userSearchQuery && stateProps.userSearchQuery.trim();
      dispatchProps.onSearchArticles(userSearchQuery);
    },
  };
};

export const Index = compose<any, any>(
  withAppContext,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  loadingPageWhileLoading((p: any) => p.userIsLoading || p.loadingCategories)
)(ZendeskHelpCenter);

Index.propTypes = {
  currentRoute: PropTypes.object,
};
