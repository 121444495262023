import { useLocalization } from '@client/internationalization';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import type { FC } from 'react';
import * as React from 'react';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import type { AmountWithCurrency } from '@client/models/subscription';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTheme } from '@material-ui/core/styles';

export interface UpdateBankAppProps {
  amountSaved: AmountWithCurrency;
}

const useStyles = makeStyles((theme) => ({
  savingsOffer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '8px',
    background: theme.colors.base.background,
    borderRadius: theme.borderRadius,
    padding: '16px',
    margin: '0 24px',
  },
}));

export const YouSavedComponent: FC<UpdateBankAppProps> = ({ amountSaved }) => {
  const classes = useStyles();
  const { colors } = useTheme();

  const { localizeMessage, localizeCostWithCurrency } = useLocalization('shared-components/YouSavedComponent');
  const youWillSaveDescription = localizeMessage('successSavedText');
  const youWillSaveTitle = localizeMessage('donePerYear', { amount: localizeCostWithCurrency(amountSaved, 2) });

  return (
    <div className={classes.savingsOffer}>
      <Body align="center" color={colors.textOn.surface}>
        {youWillSaveDescription}
      </Body>
      <Headline3 align="center" color={colors.action.positive}>
        {youWillSaveTitle}
      </Headline3>
      <Body align="center" color={colors.textOn.surface}>
        {localizeMessage('greatToStartSaving')}
      </Body>
    </div>
  );
};
