// @see interval-to-string component for rendering
import type { IntervalUnit, PaymentInterval } from '../modules/subscription/types';

export const paymentIntervalsPer: { interval: PaymentInterval }[] = [
  { interval: { amount: 1, unit: 'day' } },
  { interval: { amount: 1, unit: 'week' } },
  { interval: { amount: 2, unit: 'week' } },
  { interval: { amount: 1, unit: 'month' } },
  { interval: { amount: 2, unit: 'month' } },
  { interval: { amount: 3, unit: 'month' } },
  { interval: { amount: 4, unit: 'month' } },
  { interval: { amount: 6, unit: 'month' } },
  { interval: { amount: 1, unit: 'year' } },
];
export const paymentIntervalUnits: IntervalUnit[] = ['day', 'week', 'month', 'year'];
