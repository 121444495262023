// TODO: this OP-specific V1 page can be removed once identifyOptIn is toggled on for all users in prod.

import { Sentry } from '@client/assets/js/sentry';
import { LocalizedMessage, LocalizedMessageWithElementsInjected, withLocalization } from '@client/internationalization';
import { acceptPrivacyPolicyAndTermsOfUse } from '@client/models/user';
import { selectorAppType, selectorMarket } from '@client/modules/app-context/duck';
import { ConsentPageWrapper } from '@client/modules/onboarding/OnboardingPsd2/consent/ConsentPageWrapper';
import { psd2AuthenticationWithClient } from '@client/modules/onboarding/OnboardingPsd2/consent/psd2AuthenticationWithClient';
import * as urls from '@client/routes';
import { TrackingPageName } from '@client/tracking/mixpanel-constants';
import { finishedOnboardingMixpanelEvent, viewedOnboardingMixpanelEvent } from '@client/tracking/mixpanel-events';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { HistoryIcon } from '@minna-technologies/minna-ui/icons/History';
import { UserIcon } from '@minna-technologies/minna-ui/icons/User';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  introductionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '16px',
    paddingBottom: '8px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '16px',
    marginTop: '16px',
    flexShrink: 0,
    width: '100%',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  iconWrapper: {
    flex: 0,
    marginRight: '16px',
    alignSelf: 'flex-start',
    boxSizing: 'content-box',
    height: '24px',
    width: '24px',
  },
  contentWrapper: {
    textAlign: 'left',
    paddingLeft: '40px',
  },
  bottomContainer: {
    padding: '8px 8px 56px 8px',
  },
  button: {
    margin: '8px 0',
  },
}));

const OpBankConsentPageInner = () => {
  const { colors } = useTheme();
  const appType = useSelector(selectorAppType);
  const market = useSelector(selectorMarket);
  const styles = useStyles();
  const maybeUrlParameter = new URLSearchParams(window.location.search);
  const termsAndConditionsUrl = `${urls.userTermsPage}?${maybeUrlParameter}`;
  const privacyPolicyUrl = `${urls.privacyPolicyPage}?${maybeUrlParameter}`;

  const onContinueToConsentClick = async () => {
    try {
      await acceptPrivacyPolicyAndTermsOfUse();
    } catch (error) {
      Sentry.captureExceptionWithMessage(
        error,
        `Failed to store use consent for market: ${market} and  appType: ${appType} `
      );
      throw error;
    }
    finishedOnboardingMixpanelEvent(TrackingPageName.NEW_CONSENT, market, appType);
    window.location = await psd2AuthenticationWithClient();
  };

  return (
    <ConsentPageWrapper
      appType={appType}
      trackViewedPage={() => viewedOnboardingMixpanelEvent(TrackingPageName.CONSENT)}
    >
      <div>
        <div className={styles.introductionContainer}>
          <Headline2 data-test={'headline'}>
            <LocalizedMessage id="headline" />
          </Headline2>
        </div>
        <div>
          <Card className={styles.card} data-test={'card-one'}>
            <div className={styles.cardHeader}>
              <div className={styles.iconWrapper}>
                <UserIcon nativeColor={colors.action.primary} />
              </div>
              <Body variant="bold">
                <LocalizedMessage id="personalDataHeadline" />
              </Body>
            </div>
            <div className={styles.contentWrapper}>
              <Caption style={{ color: colors.textOn.surfaceSubdued, margin: '8px 0 0' }}>
                <LocalizedMessage id="personalDataDescription" />
              </Caption>
            </div>
          </Card>
        </div>
        <div>
          <Card className={styles.card} data-test={'card-two'}>
            <div className={styles.cardHeader}>
              <div className={styles.iconWrapper}>
                <HistoryIcon nativeColor={colors.action.primary} />
              </div>
              <Body variant="bold">
                <LocalizedMessage id="transactionHistoryHeadline" />
              </Body>
            </div>
            <div className={styles.contentWrapper}>
              <Caption style={{ color: colors.textOn.surfaceSubdued, margin: '16px 0' }}>
                <LocalizedMessage id="transactionHistoryDescription1" />
              </Caption>
              <Caption style={{ color: colors.textOn.surfaceSubdued, margin: '16px 0 0' }}>
                <LocalizedMessage id="transactionHistoryDescription2" />
              </Caption>
            </div>
          </Card>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <Caption style={{ color: colors.textOn.surfaceSubdued, margin: '24px 8px 16px', textAlign: 'start' }}>
          <LocalizedMessageWithElementsInjected
            id="termsAndConditionsText"
            elements={{
              termsAndConditionsUrl: (
                <Link style={{ color: colors.action.primary }} to={termsAndConditionsUrl}>
                  <LocalizedMessage id="termsAndConditions" />
                </Link>
              ),
              privacyPolicyUrl: (
                <Link style={{ color: colors.action.primary }} to={privacyPolicyUrl}>
                  <LocalizedMessage id="privacyPolicy" />
                </Link>
              ),
            }}
          />
        </Caption>
        <div className={styles.button}>
          <PrimaryButton
            fullWidth
            label={<LocalizedMessage id="buttonLabel" />}
            onClick={onContinueToConsentClick}
            data-test={'continue-button'}
          />
        </div>
      </div>
    </ConsentPageWrapper>
  );
};

export const OpBankConsentPageV1 = withLocalization('onboarding-psd2/ConsentPage/OpBankConsentPage')(
  OpBankConsentPageInner
);
