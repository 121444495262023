import { Sentry } from '@client/assets/js/sentry';
import { withFeatures } from '@client/feature-toggling';
import { setUserMessageAsClicked } from '@client/models/user';
import type { AppFeatureToggles } from '@client/modules/app-context';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import { cancellationGuidePage } from '@client/routes';
import { clickedSubscriptionOnDashboardMixpanelEvent } from '@client/tracking/mixpanel-events';
import type { AxiosError } from 'axios';
import type { History } from 'history';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import { selectorFeatures, selectorMarket } from '../../../../app-context/duck';
import {
  selectorOrderedSubscriptions,
  selectorSubscriptions,
  selectorTerminatedSubscriptions,
  updateUnresolvedSubscriptions,
} from '../../../duck';
import { subscriptionPageByContracts } from '../../../utils';
import type { SubscriptionListComponentProps } from './component';
import { SubscriptionListComponent } from './component';

const mapStateToProps = (state: any) => ({
  subscriptions: selectorSubscriptions(state).filter((s) => s.cost.amount !== -1), // generic-actions: Remove the filter when all cancellations are handled. Hiding all subscriptions created as consequence of exploring the feature.
  terminatedSubscriptions: selectorTerminatedSubscriptions(state),
  orderedSubscriptions: selectorOrderedSubscriptions(state),
  features: selectorFeatures(state),
  appMarket: selectorMarket(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: { history: History }) => ({
  onSubscriptionClick: (subscription: Subscription, allSubscriptions: Subscription[], features: AppFeatureToggles) => {
    const userMessage = get(subscription, 'userMessage');
    // DidYouCancelMessage should not be removed if the user does not actively answer the question.
    if (userMessage && userMessage.message.type !== 'DidYouCancelMessage') {
      setUserMessageAsClicked(userMessage)
        .then(noop)
        .catch((error: AxiosError) =>
          Sentry.captureExceptionWithMessage(error, 'Failed to update user message as clicked')
        );
    }
    clickedSubscriptionOnDashboardMixpanelEvent(subscription);
    // TODO not the best possible place to do this
    if (subscription.contracts.length === 0) {
      const unresolvedSubscriptionsFromSameServiceProvider = allSubscriptions.filter(
        (sub) => sub.contracts.length === 0 && sub.supplier.id === subscription.supplier.id
      );
      dispatch(
        updateUnresolvedSubscriptions({
          showSuccessAfterGuide: unresolvedSubscriptionsFromSameServiceProvider.length > 1,
          unresolvedSubscriptions: unresolvedSubscriptionsFromSameServiceProvider,
        })
      );
    }
    if (userMessage.message.type === 'DidYouCancelMessage' && subscription.singleContractId) {
      history.push(cancellationGuidePage(subscription.id, subscription.singleContractId, true));
    } else if (userMessage.message.type === 'DidYouCancelMessage' && !subscription.singleContractId) {
      history.push(cancellationGuidePage(subscription.id, userMessage.contractId, true));
    } else {
      history.push(subscriptionPageByContracts(subscription, features));
    }
  },
  onOrderedSubscriptionClick: (orderId: string) => {
    history.push(urls.orderedSubscriptionDetailsPage(orderId));
  },
  navigateToContractDetailsPage: (subscription: Subscription, contract: Contract) => {
    clickedSubscriptionOnDashboardMixpanelEvent(subscription);
    history.push(urls.contractDetailsPage(subscription.id, contract.id));
  },
  onAddManualSubscription: () => {
    history.push(urls.manualAddSubscriptionPage);
  },
});

export const SubscriptionList = compose<SubscriptionListComponentProps, any>(
  withRouter,
  withFeatures,
  connect(mapStateToProps, mapDispatchToProps)
)(SubscriptionListComponent);
