import type { QuestionId, RecipientQuestion } from '@client/models/cancellation-questions';
import type { Locale } from '@client/modules/app-context/constants';
import { requiredQuestionLabel } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/logic';
import { detailsByLocale, detailsByLocaleAlternative } from '@client/utils/locale';
import { RHSelect } from '@minna-technologies/minna-ui/components/Form/RHSelect';
import { RHTextInput } from '@minna-technologies/minna-ui/components/Form/RHTextInput';
import { SelectOption } from '@minna-technologies/minna-ui/components/Inputs/Select/components/SelectOption';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import reject from 'lodash/reject';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useStyles } from './styles';

interface RecipientQuestionProps {
  locale: Locale;
  question: RecipientQuestion;
  watchRecipientQuestion(questionId: QuestionId): any;
}

export const RecipientQuestionComponent: React.FunctionComponent<RecipientQuestionProps> = (
  { locale, question, watchRecipientQuestion }: RecipientQuestionProps,
  { localizeMessage }
) => {
  const { text, description } = detailsByLocale(question.details, locale);
  const required = question.required;
  const label = requiredQuestionLabel(text, required);
  const alternativesMappedToOldFormat = question.alternatives.map((alternative) => {
    const alternativeDetails = detailsByLocaleAlternative(alternative.details, locale);
    return {
      id: alternative.id,
      label: alternativeDetails.label,
      requiresUserInput: alternative.requiresUserInput,
      userInputLabel: alternativeDetails.userInputLabel,
    };
  });

  const doesContainOther = (alternative: any) => includes(get(alternative, 'label', '').toLowerCase(), 'annan '); // ???
  const alternativesContainingOther = filter(alternativesMappedToOldFormat, doesContainOther);
  const alternativesNotContainingOther = reject(alternativesMappedToOldFormat, doesContainOther);
  const sortedAlternatives = sortBy(alternativesNotContainingOther, [
    (alternative) => get(alternative, 'label', '').toLowerCase(),
  ]);
  const allAlternatives = sortedAlternatives.concat(alternativesContainingOther);
  const alternativesMenuItems = map(allAlternatives, (alternative) => {
    return <SelectOption key={alternative.id} value={alternative.id} label={alternative.label} />;
  });

  const alternativeRequiresUserInput = (id: QuestionId) => {
    const maybeAlternativeWithUserInput = allAlternatives.find((obj) => {
      return obj.id === id;
    });
    return maybeAlternativeWithUserInput?.userInputLabel;
  };

  const selectedValue = watchRecipientQuestion(question.id);
  const classes = useStyles();

  return (
    <>
      <Body className={classes.body}>{description}</Body>
      <RHSelect
        name={'recipientQuestion' + question.id}
        label={label}
        validation={{
          required: required ? localizeMessage('requiredRecipientQuestionSelectError', { inputTitle: text }) : false,
        }}
        fullWidth
      >
        <SelectOption value={undefined} />
        {alternativesMenuItems}
      </RHSelect>
      {alternativeRequiresUserInput(selectedValue) !== undefined ? (
        <RHTextInput
          name={'recipientQuestionUserInput' + selectedValue}
          label={alternativeRequiresUserInput(selectedValue)}
          validation={{
            required: required //required?
              ? localizeMessage('requiredRecipientQuestionInputError', {
                  inputTitle: alternativeRequiresUserInput(selectedValue),
                })
              : false,
          }}
          fullWidth
        />
      ) : null}
    </>
  );
};

RecipientQuestionComponent.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
