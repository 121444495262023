import makeStyles from '@material-ui/core/styles/makeStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import PropTypes from 'prop-types';
import React from 'react';
import { ARTICLES_FILTERED_BY_SECTION } from '../../../ducks/zendesk-help-center';
import { ZendeskSubHeader } from '../../../ZendeskSubHeader';
import { Index } from '../ArticlesResult';

const useStyles = makeStyles((theme) => ({
  displaySection: {
    padding: 0,
  },
  displaySectionSubheader: {
    padding: theme.spacing(2),
  },
}));

export const DisplaySection = ({ section }: any) => {
  const classes = useStyles();
  return (
    <Card className={classes.displaySection} variant="border">
      <div className={classes.displaySectionSubheader}>
        <ZendeskSubHeader title={section.name} />
      </div>
      <Index articlesFilteredBy={ARTICLES_FILTERED_BY_SECTION} embedded />
    </Card>
  );
};

DisplaySection.propTypes = {
  section: PropTypes.object.isRequired,
};
