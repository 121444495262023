import { useLocalization } from '@client/internationalization';
import type { AmountWithCurrency } from '@client/models/subscription';
import { AmountPerInterval } from '@client/modules/subscription/components/AmountPerInterval';
import type { PaymentInterval } from '@client/modules/subscription/types';
import { parseDate } from '@client/utils/date';
import { makeStyles } from '@material-ui/core/styles';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles({
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
  },

  priceRow: {
    display: 'flex',
  },
});

interface Props {
  cost: AmountWithCurrency;
  paymentInterval?: PaymentInterval;
  estimatedPaymentDate?: string;
}

export const ContractDetailsPriceInformation: FC<Props> = ({ cost, paymentInterval, estimatedPaymentDate }) => {
  const classes = useStyles();
  const { localizeMessage, localizeDate } = useLocalization('contract/ContractDetails');

  // TODO: This was descoped, as soon as we can support it, just set it
  const descopedPaymentSource = undefined;

  const priceText = <AmountPerInterval cost={cost} paymentInterval={paymentInterval} />;

  const contentToShow = priceText || estimatedPaymentDate || descopedPaymentSource;

  return contentToShow ? (
    <div className={classes.flexRow}>
      <div className={classes.flexColumn}>
        {priceText && <div className={classes.priceRow}>{priceText}</div>}
        {estimatedPaymentDate && (
          <>
            <Caption style={{ marginTop: '8px' }}>{localizeMessage('estimatedPaymentDate')}</Caption>
            <Subheading2 style={{ marginTop: '8px' }}>
              {localizeDate(parseDate(estimatedPaymentDate), 'dayAndMonth')}
            </Subheading2>
          </>
        )}
      </div>
      {descopedPaymentSource && (
        <div className={classes.flexColumn}>
          <div>Paid from</div>
          <div>{descopedPaymentSource}</div>
        </div>
      )}
    </div>
  ) : null;
};
