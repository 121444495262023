import { useLocalization } from '@client/internationalization';
import type { BankEvent } from '@client/modules/subscription/types';
import { parseDate } from '@client/utils/date';
import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles({
  content: {
    padding: '8px',
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
  },

  firstColumn: {
    display: 'flex',
    flexDirection: 'column',
  },

  price: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
});

interface Props {
  bankEvent: BankEvent;
}

export const BankEventListItem: FC<Props> = ({ bankEvent }) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const { localizeMessage, localizeCostWithCurrency } = useLocalization('contract/PriceHistoryLolipop');

  return (
    <div className={classes.content}>
      <Divider />
      <div className={classes.row}>
        <div className={classes.firstColumn}>
          <Body style={{ marginBottom: '8px' }}>
            {localizeMessage('historicalTransactionDate', {
              // @ts-ignore
              transactionDate: parseDate(bankEvent.date),
            })}
          </Body>
          <Body style={{ marginBottom: '0', color: colors.textOn.surfaceSubdued }}>{bankEvent.description}</Body>
        </div>
        <div className={classes.price}>
          <Body variant="bold" style={{ color: colors.action.primary }}>
            {localizeCostWithCurrency(bankEvent.cost)}
          </Body>
        </div>
      </div>
    </div>
  );
};
