import Sentry from '@client/assets/js/sentry';
import { viewPageOrderedSubscriptionMixpanelEvent } from '@client/modules/switch/mixpanel-events';
import {
  OrderedSubscriptionAction,
  selectorOrderedSubscription,
} from '@client/modules/switch/ordered-subscription/duck';
import type { OrderedSubscription } from '@client/modules/switch/ordered-subscription/models';
import { fetchOrderedSubscription } from '@client/modules/switch/ordered-subscription/models';
import { OrderedSubscriptionContainer } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionPage';
import { OrderedSubscriptionTermsAndConditionsPage } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionTermsAndConditionsPage';
import * as urls from '@client/routes';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import type { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';

interface OrderedSubscriptionRouteProps {
  orderId: string;
}

export const OrderedSubscriptionRoute: React.FunctionComponent<OrderedSubscriptionRouteProps> = ({ orderId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const orderedSubscription: OrderedSubscription = useSelector(selectorOrderedSubscription);
  useEffect(() => {
    dispatch(OrderedSubscriptionAction.setIsLoading());
    fetchOrderedSubscription(orderId)
      .then((orderSubscription: OrderedSubscription | undefined) => {
        if (orderSubscription) {
          dispatch(OrderedSubscriptionAction.setOrderedSubscriptionLoaded(orderSubscription));
        }
      })
      .catch((error: AxiosError) => {
        dispatch(OrderedSubscriptionAction.setOrderedSubscriptionFailedLoading());
        Sentry.captureExceptionWithMessage(error, 'Failed to load ordered subscription');
      });
  }, [dispatch, orderId]);
  useMountEffect(() => {
    viewPageOrderedSubscriptionMixpanelEvent(
      orderedSubscription?.product.supplierInformation.name ?? '',
      orderedSubscription?.product.category,
      orderedSubscription?.oldSupplierName
    );
  });

  return (
    <Switch>
      {orderedSubscription && (
        <Route
          path={urls.orderedSubscriptionTermsPage(orderId)}
          render={() => (
            <OrderedSubscriptionTermsAndConditionsPage
              orderedSubscription={orderedSubscription}
              onBackClick={history.goBack}
            />
          )}
        />
      )}
      <Route
        exact
        path={urls.orderedSubscriptionDetailsPage(orderId)}
        render={() => <OrderedSubscriptionContainer orderId={orderId} />}
      />
    </Switch>
  );
};
