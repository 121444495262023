export const BROADBAND = 'broadband';
export const CAR_LOAN = 'car-loan';
export const CHARITY = 'charity';
export const CREDIT_CARD = 'credit-card';
export const DATING = 'dating';
export const E_WALLET = 'e-wallet';
export const ELECTRICITY = 'electricity';
export const ELECTRICITY_GRID = 'electricity-grid';
export const FITNESS = 'fitness';
export const GAS = 'gas';
export const GAME = 'game';
export const GAMBLING = 'gambling';
export const GROCERY_BAG = 'grocery-bag';
export const HOME_SECURITY = 'home-security';
export const INSURANCE = 'insurance';
export const ISK_ACCOUNT = 'ISK account';
export const LAND_LINE = 'land-line';
export const MAGAZINE = 'magazine';
export const MEMBERSHIP = 'membership';
export const MOBILE = 'mobile';
export const MOBILE_APP = 'mobile-app';
export const MOBILE_BROADBAND = 'mobile-broadband';
export const MORTGAGE_LOAN = 'mortgage-loan';
export const NEWS = 'news';
export const OTHER = 'other';
export const OTHER_TRANSACTION = 'other-transaction';
export const PRIVATE_LOAN = 'private-loan';
export const PUBLIC_TRANSPORT = 'public-transport';
export const SAVINGS = 'savings';
export const SAVINGS_ACCOUNT = 'savings-account';
export const SUBSCRIPTION_BOX = 'subscription-box';
export const SOFTWARE = 'software';
export const STREAMING_MEDIA = 'streaming-media';
export const TRADE_UNION = 'trade-union';
export const TV = 'tv';
export const UNEMPLOYMENT_INSURANCE = 'unemployment-insurance';

export const LOAN = 'loan';

export type CategoryName =
  | 'electricity'
  | 'electricity-grid'
  | 'broadband'
  | 'charity'
  | 'game'
  | 'fitness'
  | 'gambling'
  | 'grocery-bag'
  | 'insurance'
  | 'magazine'
  | 'membership'
  | 'mobile'
  | 'news'
  | 'other'
  | 'public-transport'
  | 'software'
  | 'streaming-media'
  | 'trade-union'
  | 'tv'
  | 'subscription-box'
  | 'loan'
  | 'private-loan'
  | 'mortgage-loan'
  | 'car-loan'
  | 'other-transaction'
  | 'credit-card'
  | 'savings'
  | 'e-wallet'
  | 'savings-account'
  | 'isk-account'
  | 'mobile-broadband'
  | 'land-line'
  | 'unemployment-insurance'
  | 'dating'
  | 'home-security'
  | 'mobile-app'
  | 'transport' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'entertainment' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'health' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'clothing-accessories' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'food' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'online-fitness' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'relaxation' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'weight-loss' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'music' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'audio-books' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'hygiene-products' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'house-and-home' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'drinks' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'cars' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'boats' // 2021-02-26: Part of Fake Categories experiment in Discover
  | 'scooter' // 2021-02-26: Part of Fake Categories experiment in Discover
  | typeof GAS
  | typeof CONSOLIDATABLE_LOAN;

/*
 * CONSOLIDATABLE_LOAN exists as a single representation of any loan that is consolidatable.
 * We need that in the presentation logic and when passing one single category to ManualAddContractPage.
 */
export const CONSOLIDATABLE_LOAN = 'consolidatable-loan';
export const ALL_LOAN_CATEGORIES_ORDERED: CategoryName[] = [
  PRIVATE_LOAN,
  CAR_LOAN,
  MORTGAGE_LOAN,
  SAVINGS,
  CREDIT_CARD,
  OTHER_TRANSACTION,
];
export const MAIN_LOAN_CATEGORIES: CategoryName[] = [PRIVATE_LOAN, CAR_LOAN, MORTGAGE_LOAN];
export const OTHER_LOAN_CATEGORIES: CategoryName[] = [OTHER_TRANSACTION, CREDIT_CARD, SAVINGS];

export const LOAN_CATEGORIES_CODES: { [index: string]: string } = {
  [PRIVATE_LOAN]: '1000',
  [CAR_LOAN]: '1500',
  [MORTGAGE_LOAN]: '2000',
  [LOAN]: '3000',
  [SAVINGS]: '5000',
  [CREDIT_CARD]: '8000',
  [OTHER_TRANSACTION]: 'Other transaction',
};

export enum SavingsCategories {
  E_WALLET,
  SAVINGS_ACCOUNT,
  ISK_ACCOUNT,
}
