import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { ThemeType } from '@client/models/ThemeType';
import type { AppFeatureToggles } from '@client/modules/app-context';
import { isCancellationEnabled } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { CancelInfoSlide } from '@client/modules/onboarding-overview-drawer/slides/CancelInfoSlide';
import { SyncDoneEmptySlide } from '@client/modules/onboarding-overview-drawer/slides/SyncDoneEmptyOverviewSlide';
import { SyncDoneSlide } from '@client/modules/onboarding-overview-drawer/slides/SyncDoneSlide';
import { containerDesktop } from '@client/modules/onboarding-overview-drawer/utils';
import { selectorSubscriptions } from '@client/modules/overview/duck';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_ONBOARDING_ACTION_DRAWER } from '@client/tracking/mixpanel-constants';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import withWidth from '@material-ui/core/withWidth';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Drawer } from '@minna-technologies/minna-ui/components/Drawer';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Pagination } from '@minna-technologies/minna-ui/components/Pagination';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import styles from './slides/styles.scss';

interface OnboardingDrawerProps {
  width: Breakpoint;
  onSeenNewFeatures(): void;
}

const OnboardingOverviewDrawerComponent: React.FunctionComponent<OnboardingDrawerProps> = ({
  onSeenNewFeatures,
  width,
}) => {
  enum SlideType {
    EMPTYOVERVIEW = 'No subscription',
    SUBSCRIPTIONS = 'Introduction',
    CANCEL = 'Cancel',
  }
  const [currentSlide, setCurrentSlide] = useState(SlideType.EMPTYOVERVIEW);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [slidesNumber, setSlidesNumber] = useState(0);
  const features: AppFeatureToggles = useSelector(selectorFeatures);
  const [isOnboardingOverviewDrawerOpen, setIsOnboardingOverviewDrawerOpen] = useState(true);
  const onCloseWithEvent = () => {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_ONBOARDING_ACTION_DRAWER,
      Page: currentSlide,
      Button: 'Close',
    });
    onClose();
  };
  const onClose = () => {
    setIsOnboardingOverviewDrawerOpen(false);
    onSeenNewFeatures();
  };
  const subscriptions = useSelector(selectorSubscriptions);
  function onDone() {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_ONBOARDING_ACTION_DRAWER,
      Page: currentSlide,
      Button: 'Next', //TODO This needs to be changed, probably
    });
    onClose();
  }
  function goToNextSlide() {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_ONBOARDING_ACTION_DRAWER,
      Page: currentSlide,
      Button: 'Next',
    });
    if (currentSlide === SlideType.SUBSCRIPTIONS) {
      setCurrentSlide(SlideType.CANCEL);
    }
    setCurrentSlideIndex(currentSlideIndex + 1);
  }
  function hasNextButton(): boolean {
    let result = false;
    if (currentSlide === SlideType.SUBSCRIPTIONS && slidesNumber > 1) {
      result = true;
    }
    if (currentSlide === SlideType.CANCEL && slidesNumber > 2) {
      result = true;
    }

    return result;
  }
  function generateDoneButton() {
    switch (features.theme) {
      case ThemeType.WAFFLE:
        return (
          <div className={styles.addManualButton}>
            <SecondaryButton
              label={<LocalizedMessage id="goToOverview" />}
              onClick={onDone}
              data-test="onboarding-drawer-overview-button"
            />
          </div>
        );
      default:
        return (
          <div className={styles.addManualButton}>
            <PrimaryButton
              label={<LocalizedMessage id="goToOverview" />}
              onClick={onDone}
              data-test="onboarding-drawer-overview-button"
            />
          </div>
        );
    }
  }
  useEffect(() => {
    function calculateSlidesNumber() {
      let totalSlides = 0;
      if (subscriptions.length) {
        totalSlides++;
        if (isCancellationEnabled(features.actionFeatures)) {
          totalSlides++;
        }
      }
      setSlidesNumber(totalSlides);
    }

    calculateSlidesNumber();
    if (currentSlide === SlideType.EMPTYOVERVIEW && subscriptions.length) {
      setCurrentSlide(SlideType.SUBSCRIPTIONS);
    }
  }, [SlideType.EMPTYOVERVIEW, SlideType.SUBSCRIPTIONS, currentSlide, features.actionFeatures, subscriptions]);

  return (
    <MultiDeviceLayout>
      <Drawer open={isOnboardingOverviewDrawerOpen} onClose={onCloseWithEvent}>
        <div className={width !== 'xs' ? containerDesktop(features.theme) : ''} data-test="onboarding-drawer">
          {currentSlide === SlideType.EMPTYOVERVIEW && <SyncDoneEmptySlide onClose={onClose} />}
          {currentSlide === SlideType.SUBSCRIPTIONS && <SyncDoneSlide />}
          {currentSlide === SlideType.CANCEL && <CancelInfoSlide />}
          {slidesNumber >= 2 && <Pagination steps={slidesNumber} index={currentSlideIndex} variant={'dark'} />}
          {hasNextButton() && (
            <div className={styles.addManualButton}>
              <SecondaryButton
                label={<LocalizedMessage id="next" />}
                onClick={goToNextSlide}
                data-test="onboarding-drawer-next-button"
              />
            </div>
          )}
          {!hasNextButton() && slidesNumber > 1 && generateDoneButton()}
        </div>
      </Drawer>
    </MultiDeviceLayout>
  );
};

//@ts-ignore
export const OnboardingOverviewDrawer: React.FunctionComponent<any> = compose(
  withWidth(),
  withLocalization('overview/OnboardingDrawer')
  //@ts-ignore
)(OnboardingOverviewDrawerComponent);
