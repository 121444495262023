import type { CategoryName } from '@client/constants/categories';
import { CategoryIcon } from '@client/shared-components/CategoryIcon/CategoryIcon';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import React from 'react';

const useStyles = makeStyles(() => ({
  serviceName: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
  },
}));

export const ServiceNameWithIcon = ({
  categoryName,
  serviceName,
  localizedCategoryName,
}: {
  categoryName: string;
  serviceName?: string;
  localizedCategoryName: string;
}) => {
  const { colors } = useTheme();
  const classes = useStyles();
  return (
    <Caption className={classes.serviceName} color={colors.textOn.surfaceSubdued} data-test="service-name-with-icon">
      <CategoryIcon style={{ marginRight: '4px' }} size={16} categoryName={categoryName as CategoryName} />
      {serviceName || localizedCategoryName}
    </Caption>
  );
};
