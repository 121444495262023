import { withAppContext } from '@client/modules/app-context/react';
import { landingPage, menuPage } from '@client/routes';
import { getLogo, getLogoAltText } from '@client/utils/logo';
import { LABEL_FAQ_GENERAL } from '@client/zendesk';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import useTheme from '@material-ui/core/styles/useTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import { UserIcon } from '@minna-technologies/minna-ui/icons/User';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import type { AppContext } from '../../../modules/app-context';
import { ZendeskHeaderHelpButton } from '../ZendeskHeaderHelpButton';
import styles from './styles.scss';

const HeaderLogo = withAppContext(({ appContext }: { appContext: AppContext }) => (
  <Link to={landingPage}>
    <img src={getLogo(appContext)} alt={getLogoAltText(appContext)} className={styles.logo} />
  </Link>
));

const ProfileLogo = withRouter(({ history }) => {
  const { colors } = useTheme();

  return (
    <IconButton onClick={() => history.push(menuPage)}>
      <UserIcon nativeColor={colors.action.primary} />
    </IconButton>
  );
});

const FlexToolbar = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})(Toolbar);

const WhiteAppBar = withStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.base.surface,
  },
}))(AppBar);

export const ProfileHeader = () => (
  <WhiteAppBar position="sticky">
    <FlexToolbar>
      <ProfileLogo />

      <HeaderLogo />

      <ZendeskHeaderHelpButton query={LABEL_FAQ_GENERAL} />
    </FlexToolbar>
  </WhiteAppBar>
);
