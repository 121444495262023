import type { AmountWithCurrency } from '@client/models/subscription';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { AppIntegration } from '@client/modules/app-integration';
import { IntelligentGuideDoneComponent } from '@client/modules/intelligent-guides/components/IntelligentGuideDonePage';
import {
  ratedCancellationGuideExperienceMixpanelEvent,
  trackViewedIntelligentGuidePage,
} from '@client/modules/intelligent-guides/mixpanel';
import { amountSavedPerYear, selectorContract, selectorSubscription } from '@client/modules/subscription';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import { TrackingButtonName, TrackingFeature, TrackingPageName } from '@client/tracking/mixpanel-constants';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { trackCancellationSuccessfulButtonClick } from '../../mixpanel-events';
import {
  trackClosedUpdateBankAppModal,
  trackViewedUpdateBankAppModal,
} from '@minna-components/UpdateBankAppModal/mixpanel-events';
import { CancellationMethodType } from '@client/models/service';

interface Props {
  contractId: string;
}

export const IntelligentGuideDonePageContractWrapper = ({ contractId }: Props): JSX.Element => {
  const history = useHistory();
  const appContext = useSelector(selectorAppContext);
  const subscription: Subscription = useSelector(selectorSubscription) as Subscription;
  const contract: Contract | undefined = useSelector((state: any) => selectorContract(state, contractId));

  const amountWithCurrency: AmountWithCurrency | undefined = subscription
    ? amountSavedPerYear(subscription)
    : undefined;

  const trackCloseButtonClicked = () => {
    trackCancellationSuccessfulButtonClick(
      TrackingButtonName.CLOSE,
      subscription.supplier.id ?? '',
      subscription.supplier.name ?? '',
      contract?.service.id ?? '',
      contract?.service.name ?? '',
      contract?.service.category.name ?? '',
      appContext.market,
      appContext.appType,
      contract?.cancellationMethod.type,
      amountWithCurrency?.amount
    );
  };
  const onRedirectUser = () => {
    if (!appContext.features.identify?.overview && appContext.embeddedView.webView) {
      AppIntegration.closeApplicationOrGoTo(urls.contractDetailsPage(subscription.id, contractId));
    } else if (appContext.features.identify?.overview) {
      history.push(urls.overviewPage);
    } else {
      history.push(urls.contractDetailsPage(subscription.id, contractId));
    }
  };

  const onRatedCancellationGuideExperience = (rating: number) => {
    ratedCancellationGuideExperienceMixpanelEvent(rating, subscription, amountWithCurrency?.amount, contract);
  };

  const onViewIntelligentGuideSuccessPage = () => {
    trackViewedIntelligentGuidePage(
      TrackingFeature.Cancellation,
      TrackingPageName.SUCCESS_SCREEN,
      subscription.supplier.name ?? '',
      contract?.service.category.name ?? '',
      contract?.service.name ?? '',
      appContext.market,
      appContext.appType,
      contract?.service.id ?? '',
      contract?.id ?? '',
      subscription?.supplier.id ?? '',
      subscription?.source ?? ''
    );
  };

  const onClickStartNewSavingsButton = () => {
    trackCancellationSuccessfulButtonClick(
      TrackingButtonName.START_SAVING,
      subscription.supplier.id ?? '',
      subscription.supplier.name ?? '',
      contract?.service?.id ?? '',
      contract?.service?.name ?? '',
      contract?.service?.category?.name ?? '',
      appContext.market,
      appContext.appType,
      contract?.cancellationMethod?.type,
      amountWithCurrency?.amount
    );
  };
  const onViewedUpdateBankAppModal = () => {
    trackViewedUpdateBankAppModal(
      subscription.supplier.id ?? '',
      subscription.supplier.name ?? '',
      contract?.service?.id ?? '',
      contract?.service?.name ?? '',
      contract?.service?.category?.name ?? '',
      appContext.market,
      appContext.appType,
      CancellationMethodType.IntelligentGuide,
      subscription.source ?? ''
    );
  };
  const onCloseUpdateBankAppModal = () => {
    trackClosedUpdateBankAppModal(
      subscription.supplier.id ?? '',
      subscription.supplier.name ?? '',
      contract?.service?.id ?? '',
      contract?.service?.name ?? '',
      contract?.service?.category?.name ?? '',
      appContext.market,
      appContext.appType,
      CancellationMethodType.IntelligentGuide,
      subscription?.source ?? ''
    );
  };

  return (
    <IntelligentGuideDoneComponent
      showCancelAndSaveEntryPoint
      amountWithCurrency={amountWithCurrency}
      trackCloseButtonClicked={trackCloseButtonClicked}
      onRedirectUser={onRedirectUser}
      trackRatedIntelligentGuideExperience={onRatedCancellationGuideExperience}
      trackIntelligentGuideSuccessPageViewed={onViewIntelligentGuideSuccessPage}
      trackStartNewSavingsButtonClicked={onClickStartNewSavingsButton}
      trackViewedUpdateBankAppModal={onViewedUpdateBankAppModal}
      trackClosedUpdateBankAppModal={onCloseUpdateBankAppModal}
    />
  );
};
