import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames } from '@client/tracking/mixpanel-constants';

export interface MixPanelEventDetails {
  Event: string;
  Feature: string;
}

export function sendViewedPageEvent(Feature: string, Page: string, extra: Record<string, unknown> = {}): void {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature,
    Page,
    ...extra,
  });
}

export function sendButtonClickedEvent(
  Feature: string,
  Page: string,
  Button: string, //Description of which button was pressed
  extra: Record<string, unknown> = {}
): void {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature,
    Page,
    Button,
    ...extra,
  });
}
