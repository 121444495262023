import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { OngoingOrderStepper } from '@client/modules/switch/ordered-subscription/OrderStepper';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardHeader } from '@minna-technologies/minna-ui/components/Card/CardHeader';
import React from 'react';

interface OngoingOrderCardProps {
  oldServiceProviderName: string;
  newServiceProviderName: string;
  portingOrStartDate?: string;
  categoryName: string;
  waitingForCancellationInfo: boolean;
  actionRequestDate: string;
  orderIsAccepted: boolean;
  isIndependentOrder: boolean;
  hasCancellation: boolean;
}

const OngoingOrderCardInner: React.FunctionComponent<OngoingOrderCardProps> = ({
  orderIsAccepted,
  oldServiceProviderName,
  newServiceProviderName,
  actionRequestDate,
  waitingForCancellationInfo,
  categoryName,
  portingOrStartDate,
  isIndependentOrder,
  hasCancellation,
}) => {
  return (
    <Card>
      {!isIndependentOrder && (
        <CardHeader
          title={<LocalizedMessage id={orderIsAccepted ? 'confirmedSwitchOrderTitle' : 'ongoingSwitchTitle'} />}
        />
      )}
      {isIndependentOrder && <CardHeader title={<LocalizedMessage id="ongoingIndependentOrderTitle" />} />}
      <OngoingOrderStepper
        oldServiceProviderName={oldServiceProviderName}
        newServiceProviderName={newServiceProviderName}
        actionRequestDate={actionRequestDate}
        portingOrStartDate={portingOrStartDate}
        orderIsAccepted={orderIsAccepted}
        categoryName={categoryName}
        waitingForCancellationInfo={waitingForCancellationInfo}
        hasCancellation={hasCancellation}
      />
    </Card>
  );
};

export const OngoingOrderCard = withLocalization('orderedSubscription/OngoingOrderCard')(OngoingOrderCardInner);
