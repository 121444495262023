import { withLocalization } from '@client/internationalization';
import { selectorAppType, selectorMarket } from '@client/modules/app-context/duck';
import * as urls from '@client/routes';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import { psd2AuthenticationWithClient } from '../../psd2AuthenticationWithClient';
import { ConsentPageOP } from './component';

const mapStateToProps = (state: any) => ({
  market: selectorMarket(state),
  appType: selectorAppType(state),
});

export const RenewConsentPageV2Container = compose(
  connect(mapStateToProps),
  withLocalization('onboarding-psd2/RenewConsentPageV2'),
  withProps(() => ({
    onContinueToConsentClick: async () => {
      const url = await psd2AuthenticationWithClient(urls.onboardingPsd2ConsentExpired);
      window.location = url;
    },
  }))
  // @ts-ignore
)(ConsentPageOP);
