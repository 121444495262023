import type { CancellationReason, SaveDeskOfferForUser } from '@client/modules/saveDesk/api';

export interface IState {
  sentFeedback: boolean;
  cancellationReason?: CancellationReason;
  offer?: SaveDeskOfferForUser;
}

export const initState: IState = {
  sentFeedback: false,
};

export const selectorSentFeedback = (state: any) => state.saveDesk.sentFeedback;
export const selectorCancellationReason = (state: any) => state.saveDesk.cancellationReason;
export const selectorOffer = (state: any) => state.saveDesk.offer;
