import { isReturnToPageRedirectUrlValid } from '@client/modules/overview/model';
import axios from 'axios';

export const psd2AuthenticationWithClient: any = async (returnToPage?: string) => {
  let response: any = undefined;
  if (returnToPage && isReturnToPageRedirectUrlValid(returnToPage)) {
    response = await axios.get('/api/client-oauth/consent/init', { params: { returnToPage: returnToPage } });
  } else {
    response = await axios.get('/api/client-oauth/consent/init');
  }
  return response.data;
};
