import Sentry from '@client/assets/js/sentry';
import { setUser } from '@client/ducks/user';
import { withLocalization } from '@client/internationalization';
import type { CancellationUser } from '@client/models/cancellation';
import { getLoaPreviewForContract, getLoaPreviewForService } from '@client/models/cancellation';
import type { Question } from '@client/models/cancellation-questions';
import type { ServiceId, SupplierId } from '@client/models/supplier';
import { saveUserInformation } from '@client/models/user';
import type { User } from '@client/models/user-models';
import type { AppContext } from '@client/modules/app-context';
import { saveCancellationAnswers } from '@client/modules/cancellation/api';
import { LoaDocumentPreview } from '@client/modules/cancellation/CancellationFormPage/components/LoaDocument/component';
import { selectorUserInformationForm } from '@client/modules/subscription/duck';
import {
  requestedLoaPreviewForServiceMixpanelEvent,
  requestedLoaPreviewMixpanelEvent,
} from '@client/tracking/mixpanel-events';
import clone from 'lodash/clone';
import get from 'lodash/get';
import set from 'lodash/set';
import type React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import { selectorAppContext } from '../../../../app-context/duck';
import {
  selectorContract,
  selectorSubscription,
  subscriptionLoadingFailure,
  updateContract,
  updateContractApiRequest,
} from '../../../../subscription';
import type { Contract, ContractId, Subscription } from '../../../../subscription/types';
import {
  CancellationAction,
  selectorDesiredTerminationAt,
  selectorLoaPreview,
  selectorPreviewDialogLoaOpen,
  selectorTerminationAnswers,
  selectorTerminationQuestions,
} from '../../../duck';
import { contractCancellationAnswers, userInfoFromUserInformationForm } from '../../../utils';

interface ContainerOuterProps {
  contractId?: ContractId;
  merchantId?: SupplierId;
  serviceId?: ServiceId;
  onPreviewLoa(requestPreviewLoa: () => void): void;
}

interface DispatchProps {
  requestPreviewLoaForContract(
    subscription: Subscription,
    contract: Contract,
    terminationQuestions: Question[],
    desiredTerminationAt: string
  ): void;
  requestPreviewLoaForService(
    merchantId: SupplierId,
    serviceId: ServiceId,
    terminationQuestions: Question[],
    desiredTerminationAt: string
  ): void;
  closePreviewDialogOpen(): void;
}

interface StateProps {
  text: string;
  supplierName: string;
  terminationQuestionResults: any[];
  previewDialogLoaOpen: boolean;
  subscription?: Subscription;
  contract?: Contract;
  terminationQuestions: Question[];
  terminationAnswers: Record<string, unknown>;
  desiredTerminationAt: string;
  appContext: AppContext;
  user: CancellationUser;
}

const mapStateToProps = (state: any, props: ContainerOuterProps): StateProps => {
  const loaPreview = selectorLoaPreview(state);

  return {
    text: get(loaPreview, 'loaText'),
    supplierName: get(loaPreview, 'supplierName'),
    terminationQuestionResults: get(loaPreview, 'terminationQuestionResults', {}),
    user: get(loaPreview, 'user', {}),
    previewDialogLoaOpen: selectorPreviewDialogLoaOpen(state),
    subscription: selectorSubscription(state),
    contract: selectorContract(state, props.contractId ?? ''),
    terminationQuestions: selectorTerminationQuestions(state),
    terminationAnswers: selectorTerminationAnswers(state),
    desiredTerminationAt: selectorDesiredTerminationAt(state),
    appContext: selectorAppContext(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  requestPreviewLoaForContract: (subscription, contract, terminationQuestions, desiredTerminationAt) => {
    dispatch<any>((dispatchInner: Dispatch<any>, getState: () => any) => {
      const state = getState();
      const userInformationForm = selectorUserInformationForm(state);

      const terminationAnswers = selectorTerminationAnswers(state);
      const formattedTerminationAnswers = contractCancellationAnswers(terminationAnswers, terminationQuestions);
      const contractToUpdate = set(clone(contract), 'terminationFormData.answers', formattedTerminationAnswers);
      set(contractToUpdate, 'terminationFormData.desiredTerminationAt', desiredTerminationAt);
      const contractPromise = updateContractApiRequest(contractToUpdate);
      contractPromise
        .then((updatedContract: Contract) => {
          dispatchInner(updateContract(updatedContract));
        })
        .catch((error: Error) => {
          dispatchInner(subscriptionLoadingFailure());
          Sentry.captureExceptionWithMessage(error, 'Failed to update contract in termination form', {
            extra: { contractId: contract.id },
          });
        });

      const updatedUserInfo = userInfoFromUserInformationForm(userInformationForm);
      const userPromise = saveUserInformation(updatedUserInfo).then((user: User) => {
        dispatchInner(setUser(user));
      });

      Promise.all([userPromise, contractPromise])
        .then(async () => getLoaPreviewForContract(contract.id))
        .then((loaPreview) => {
          requestedLoaPreviewMixpanelEvent(subscription, contract);
          dispatchInner(CancellationAction.setLoaPreview(loaPreview));
          dispatchInner(CancellationAction.setPreviewDialogLoaOpen(true));
        })
        .catch((error) => {
          Sentry.captureExceptionWithMessage(error, 'Failed to save the user or contract');
        });
    });
  },
  requestPreviewLoaForService: (merchantId, serviceId, terminationQuestions, desiredTerminationAt) => {
    dispatch<any>((dispatchInner: Dispatch<any>, getState: () => any) => {
      const state = getState();
      const terminationForm = selectorUserInformationForm(state);

      const terminationAnswers = selectorTerminationAnswers(state);
      const formattedTerminationAnswers = contractCancellationAnswers(terminationAnswers, terminationQuestions);

      const answersPromise = saveCancellationAnswers(
        merchantId,
        serviceId,
        formattedTerminationAnswers,
        desiredTerminationAt
      );
      answersPromise.catch((error: Error) => {
        Sentry.captureExceptionWithMessage(error, 'Failed to save answers from cancellation form page');
      });

      const updatedUserInfo = userInfoFromUserInformationForm(terminationForm);
      const userPromise = saveUserInformation(updatedUserInfo).then((user: User) => {
        dispatchInner(setUser(user));
      });

      Promise.all([userPromise, answersPromise])
        .then(async () => getLoaPreviewForService(merchantId, serviceId))
        .then((loaPreview) => {
          requestedLoaPreviewForServiceMixpanelEvent(merchantId, serviceId);
          dispatchInner(CancellationAction.setLoaPreview(loaPreview));
          dispatchInner(CancellationAction.setPreviewDialogLoaOpen(true));
        })
        .catch((error) => {
          Sentry.captureExceptionWithMessage(error, 'Failed to save the user or cancellation question answers');
        });
    });
  },
  closePreviewDialogOpen: () => {
    dispatch(CancellationAction.setPreviewDialogLoaOpen(false));
  },
});

const mergeProps = (mapProps: StateProps, dispatchProps: DispatchProps, ownProps: ContainerOuterProps) => {
  const { subscription, contract, terminationQuestions, desiredTerminationAt } = mapProps;
  const { merchantId, serviceId } = ownProps;

  return {
    ...mapProps,
    ...dispatchProps,
    ...ownProps,
    requestPreviewLoa: () => {
      if (subscription && contract) {
        dispatchProps.requestPreviewLoaForContract(subscription, contract, terminationQuestions, desiredTerminationAt);
      } else if (merchantId && serviceId) {
        dispatchProps.requestPreviewLoaForService(merchantId, serviceId, terminationQuestions, desiredTerminationAt);
      } else {
        Sentry.captureMessage('Neither contract nor service was defined when trying to request LOA preview', {
          extra: {
            merchantId: merchantId,
            serviceId: serviceId,
            subscriptionId: subscription?.id,
            contractId: contract?.id,
          },
        });
      }
    },
  };
};

type LoaDocumentContainerProps = StateProps & DispatchProps & ContainerOuterProps & { requestPreviewLoa(): void };

export const LoaDocumentContainer: React.ComponentClass<ContainerOuterProps> = compose<
  LoaDocumentContainerProps,
  ContainerOuterProps
>(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  withLocalization('termination/TerminationFormPage')
)(LoaDocumentPreview);
