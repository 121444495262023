import { LocalizedMessage } from '@client/internationalization';
import type { AppFeatureToggles } from '@client/modules/app-context';
import type { Market } from '@client/modules/app-context/constants';
import { updateUnresolvedSubscriptions } from '@client/modules/overview/duck';
import { OrderedSubscriptionsList } from '@client/modules/overview/OverviewPage/components/SubscriptionList/OrderedSubscriptionList/component';
import type { Subscription } from '@client/modules/subscription/types';
import { ResolveSubscriptionsDialog } from '@client/modules/subscription/unresolved-guide/components/ResolveSubscriptionsDialog';
import type { OrderedSubscription } from '@client/modules/switch/ordered-subscription/models';
import { TypeformButton } from '@client/modules/typeform/components/TypeformButton';
import { unresolvedSubscriptionGuidePath } from '@client/routes';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE } from '@client/tracking/mixpanel-constants';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import partition from 'lodash/partition';
import uniqBy from 'lodash/uniqBy';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FrontendSubscriptionType } from '../../../../subscription/types';
import { AddManualSubscription } from '../AddManualSubscription';
import styles from './styles.scss';
import { SubscriptionListItemWithActions } from './SubscriptionListItemWithActions';

export interface SubscriptionListComponentProps {
  subscriptions: Subscription[];
  orderedSubscriptions: OrderedSubscription[];
  terminatedSubscriptions: Subscription[];
  onSubscriptionClick(): void;
  onOrderedSubscriptionClick(): void;
  navigateToContractDetailsPage(): void;
  onAddManualSubscription(): void;
  appMarket: Market;
  features: AppFeatureToggles;
}

function createUnterminatedListItemsWithActions(
  subscriptions: Subscription[],
  onSubscriptionClick: (
    subscription: Subscription,
    allSubscriptions: Subscription[],
    features: AppFeatureToggles
  ) => void,
  navigateToContractDetailsPage: () => void,
  enableModal?: (subscription: Subscription, allSubscriptions: Subscription[]) => boolean
) {
  return subscriptions.map((subscription, index) =>
    subscriptionToSubscriptionListItemWithActions(
      subscription,
      index,
      FrontendSubscriptionType.OtherSubscription,
      navigateToContractDetailsPage,
      enableModal,
      onSubscriptionClick
    )
  );
}

function subscriptionToSubscriptionListItemWithActions(
  subscription: Subscription,
  index: number,
  subscriptionType: FrontendSubscriptionType,
  navigateToContractDetailsPage: () => void,
  enableModal?: (subscription: Subscription, allSubscriptions: Subscription[]) => boolean,
  onSubscriptionClick?: (
    subscription: Subscription,
    allSubscriptions: Subscription[],
    features: AppFeatureToggles
  ) => void
) {
  return (
    <div key={`${subscription.id}+${index}`} className={styles['list-item']}>
      <SubscriptionListItemWithActions
        subscription={subscription}
        subscriptionType={subscriptionType}
        onSubscriptionClick={(sub: Subscription, allSubscriptions: Subscription[], features: AppFeatureToggles) => {
          // if the method to trigger the modal is defined
          if (enableModal) {
            // if the method returned false, meaning the modal was not opened
            if (!enableModal(sub, allSubscriptions)) {
              // eslint-disable-next-line
              if (onSubscriptionClick) {
                // do whatever action we're supposed to do on click
                onSubscriptionClick(sub, allSubscriptions, features);
              }
            }
          } else if (onSubscriptionClick) {
            // there's no modal, so do the default action
            onSubscriptionClick(sub, allSubscriptions, features);
          }
        }}
        navigateToContractDetailsPage={navigateToContractDetailsPage}
      />
    </div>
  );
}

export const SubscriptionListComponent: FC<SubscriptionListComponentProps> = ({
  subscriptions,
  terminatedSubscriptions = [],
  orderedSubscriptions,
  onSubscriptionClick,
  onOrderedSubscriptionClick,
  onAddManualSubscription,
  features,
  navigateToContractDetailsPage,
}) => {
  const terminationListItemWithActions = terminatedSubscriptions.map((subscription, index) =>
    subscriptionToSubscriptionListItemWithActions(
      subscription,
      index,
      FrontendSubscriptionType.TerminatedSubscription,
      navigateToContractDetailsPage
    )
  );

  const terminatedSubscriptionItems = terminationListItemWithActions.length > 0 && (
    <div>
      <Divider title={<LocalizedMessage id="terminatedSubscriptions" />} fullWidth />
      {terminationListItemWithActions}
    </div>
  );

  const unresolvedSubscriptionGuidesEnabled = features.useUnresolvedSubscriptionGuide;
  const [_unresolvedSubscriptions, currentSubscriptions] = partition(
    subscriptions,
    (s) => s.contracts?.length < 1 && unresolvedSubscriptionGuidesEnabled
  );
  const unresolvedSubscriptions = uniqBy(_unresolvedSubscriptions, (s) => s.supplier.id);

  const remainingSubscriptionItems = createUnterminatedListItemsWithActions(
    currentSubscriptions,
    onSubscriptionClick,
    navigateToContractDetailsPage,
    unresolvedSubscriptionGuidesEnabled ? enableModal : undefined
  );
  const hasRemainingSubscriptions = remainingSubscriptionItems.length > 0;
  const [resolveSubscriptionsDialogOpen, setResolveSubscriptionsDialogOpen] = useState(false);
  const [unresolvedSubscriptionsNumber, setUnresolvedSubscriptionsNumber] = useState(0);
  const [modalLandingPage, setModalLandingPage] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  function enableModal(subscription: Subscription, allSubscriptions: Subscription[]): boolean {
    // TODO return true if the modal was opened, false otherwise
    const maybeGroupOfSubscriptions = allSubscriptions.filter(
      (sub) => sub.supplier.id === subscription.supplier.id && sub.contracts.length === 0
    );
    if (maybeGroupOfSubscriptions.length > 1) {
      dispatch(
        updateUnresolvedSubscriptions({
          showSuccessAfterGuide: true,
          unresolvedSubscriptions: maybeGroupOfSubscriptions,
        })
      );
      setUnresolvedSubscriptionsNumber(maybeGroupOfSubscriptions.length);
      setResolveSubscriptionsDialogOpen(true);
      setModalLandingPage(unresolvedSubscriptionGuidePath(maybeGroupOfSubscriptions[0].id));

      return true;
    }

    return false;
  }

  return (
    <>
      {unresolvedSubscriptions.length > 0 ? (
        <>
          <Divider
            title={<LocalizedMessage id="potentialSubscriptions" />}
            className={styles.verticalSpacing}
            fullWidth
          />
          {createUnterminatedListItemsWithActions(
            unresolvedSubscriptions,
            onSubscriptionClick,
            navigateToContractDetailsPage,
            enableModal
          )}
        </>
      ) : null}
      {remainingSubscriptionItems.length > 0 && (
        <Divider title={<LocalizedMessage id="yourSubscriptions" />} fullWidth />
      )}
      <div className={styles['subscriptions-list']}>
        {hasRemainingSubscriptions && remainingSubscriptionItems}
        <AddManualSubscription onAddManualSubscription={onAddManualSubscription} />
        {orderedSubscriptions.length !== 0 ? (
          <div>
            <Divider title={<LocalizedMessage id="orderedSubscriptions" />} fullWidth />
            <OrderedSubscriptionsList
              orderedSubscriptions={orderedSubscriptions}
              onOrderedSubscriptionClick={onOrderedSubscriptionClick}
            />
          </div>
        ) : null}
        {terminatedSubscriptionItems}
        {resolveSubscriptionsDialogOpen && (
          <ResolveSubscriptionsDialog
            open={resolveSubscriptionsDialogOpen}
            unresolvedSubscriptionsNumber={unresolvedSubscriptionsNumber}
            onClose={() => {
              getMixpanel().track(EventNames.CLICKED_BUTTON, {
                Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
                Type: 'Contract guide',
                Page: 'Intro',
                Button: 'Close',
                Location: 'Top right',
              });
              setResolveSubscriptionsDialogOpen(false);
            }}
            onGotoResolveSubscriptionsGuide={() => {
              getMixpanel().track(EventNames.CLICKED_BUTTON, {
                Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
                Type: 'Contract guide',
                Page: 'Intro',
                Button: 'Continue',
                Location: 'Bottom',
              });
              history.push(modalLandingPage);
            }}
          />
        )}
        {features.typeformAgent && <TypeformButton location={features.typeformAgent} />}
      </div>
    </>
  );
};
