import Sentry from '@client/assets/js/sentry';
import type { SupplierService, SupplierWithServices } from '@client/models/supplier';
import type { AppContext } from '@client/modules/app-context';
import { isChangePaymentEnabled } from '@client/modules/app-context';
import * as urls from '@client/routes';
import type { History } from 'history';

export const onChangePaymentOfService = (
  appContext: AppContext,
  merchant: SupplierWithServices,
  service: SupplierService,
  history: History
) => {
  if (!isChangePaymentEnabled(appContext.features.actionFeatures)) {
    Sentry.captureMessage(
      'Invalid feature toggling: on click change payment service, assertion failed that change payment feature is enabled',
      {
        extra: { features: appContext.features },
      }
    );

    return;
  }

  history.push(urls.changePaymentIntelligentGuidePageFun(merchant.id, service.id));
};
