import { LocalizedMessage } from '@client/internationalization';
import Fade from '@material-ui/core/Fade';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { HeartPictogram } from '@minna-technologies/minna-ui/pictograms/Heart';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.colors.base.background,
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    borderRadius: '0 0 8px 8px',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: '8px 16px',
    minHeight: '200px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  heartIcon: {
    fontSize: '80px',
  },
  pictogram: {
    height: 88,
  },
}));

export const ThankYouCard20: FC = () => {
  const { colors } = useTheme();
  const classes = useStyles();

  return (
    <Fade timeout={500} in mountOnEnter>
      <div className={classes.container}>
        <div className={classes.content}>
          <HeartPictogram className={classes.pictogram} />
          <Headline2 color={colors.textOn.surface} align="center">
            <LocalizedMessage id="thankForYourOpinion" />
          </Headline2>
          <Body color={colors.textOn.surfaceSubdued} align="center">
            <LocalizedMessage id="weUseOpinion" />
          </Body>
        </div>
      </div>
    </Fade>
  );
};
