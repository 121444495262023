import { Sentry } from '@client/assets/js/sentry';
import type { WithLocalizationContextType } from '@client/internationalization';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { SupplierWithServices } from '@client/models/supplier';
import { filterRegularSupplierServices, filterValueAddedSupplierServices } from '@client/models/supplier';
import { PerInterval } from '@client/modules/shared-components/interval-to-string';
import type { ServiceId, Subscription } from '@client/modules/subscription/types';
import { WhatsWrongDialog } from '@client/modules/subscription/unresolved-guide/SubscriptionGuidePage/components/WhatsWrongDialog';
import { CategoryIcon } from '@client/shared-components/CategoryIcon/CategoryIcon';
import { getMixpanel } from '@client/tracking/mixpanel';
import {
  EventNames,
  FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
  TrackingPageName,
} from '@client/tracking/mixpanel-constants';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import useTheme from '@material-ui/core/styles/useTheme';
import { BackButton } from '@minna-technologies/minna-ui/components/flow/BackButton';
import { FlowContent } from '@minna-technologies/minna-ui/components/flow/FlowContent';
import { FlowFooter } from '@minna-technologies/minna-ui/components/flow/FlowFooter';
import { FlowHeader } from '@minna-technologies/minna-ui/components/flow/FlowHeader';
import { HorizontalLayout } from '@minna-technologies/minna-ui/components/flow/FlowLayout';
import { NextButton } from '@minna-technologies/minna-ui/components/flow/NextButton';
import { ProviderInfo } from '@minna-technologies/minna-ui/components/flow/ProviderInfo';
import { SelectButtonCheckbox } from '@minna-technologies/minna-ui/components/flow/SelectButtonCheckbox';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import isEmpty from 'lodash/fp/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './styles.scss';

export interface FlowPageUnresolvedSubscriptionComponentProps {
  unresolvedSubscription: Subscription;
  currentSupplier: SupplierWithServices;
  partOfMultipleSubscriptions: boolean;
  selectedServices: ServiceId[];
  toggleSelectService(serviceId: ServiceId, selected: boolean): void;
  guideForwardAction(): void;
  guideBackAction(): void;
  somethingWrongDeleteAction(): void;
}

export const FlowPageUnresolvedSubscriptionComponentInner: React.FunctionComponent<
  FlowPageUnresolvedSubscriptionComponentProps
> = (
  {
    currentSupplier,
    unresolvedSubscription,
    partOfMultipleSubscriptions,
    toggleSelectService,
    somethingWrongDeleteAction,
    selectedServices,
    guideBackAction,
    guideForwardAction,
  }: FlowPageUnresolvedSubscriptionComponentProps,
  { localizeCostWithCurrency }: WithLocalizationContextType
) => {
  const { colors } = useTheme();
  const [somethingWrongDialogOpened, setSomethingWrongDialogOpened] = useState(false);
  const [localSubscriptionClone, setLocalSubscriptionClone] = useState<Subscription>(unresolvedSubscription);

  function onServiceClicked(serviceId: ServiceId, selected: boolean): void {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
      Page: TrackingPageName.SELECT_SERVICE,
      Type: 'Contract guide',
      Location: 'Center',
      Button: 'Edit',
    });
    toggleSelectService(serviceId, selected);
  }

  function getServicesList(supplier: SupplierWithServices): JSX.Element {
    const regularServices = filterRegularSupplierServices(supplier.services);
    const valueAddedServices = filterValueAddedSupplierServices(supplier.services);

    const regularServicesContent = regularServices.map((service) => (
      <SelectButtonCheckbox
        data-test={'unresolved-subscription-service'}
        key={service.id}
        value={service.id}
        label={service.name}
        selected={selectedServices.includes(service.id)}
        onClick={onServiceClicked}
        icon={<CategoryIcon categoryName={service.category.name} />}
      />
    ));

    const valueAddedServicesContent = valueAddedServices.map((service) => (
      <SelectButtonCheckbox
        key={service.id}
        value={service.id}
        label={service.name}
        onClick={toggleSelectService}
        selected={selectedServices.includes(service.id)}
        icon={<CategoryIcon categoryName={service.category.name} />}
      />
    ));

    return (
      <>
        {regularServicesContent}
        {!isEmpty(valueAddedServicesContent) && (
          <>
            <Subheading1 color={colors.base.surface}>
              <LocalizedMessage id="valueAddedServicesSectionTitle" values={{ supplierName: supplier.name }} />
            </Subheading1>
            {valueAddedServicesContent}
          </>
        )}
      </>
    );
  }

  function onOpenSomethingWrongDialog(): void {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
      Page: 'Select service',
      Type: 'Contract guide',
      Location: 'Center',
      Button: 'Something not right',
    });
    getMixpanel().track(EventNames.VIEWED_GUIDE, {
      Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
      Page: 'Edit subscription',
      Type: 'Contract guide',
    });
    setSomethingWrongDialogOpened(true);
  }

  function somethingWrongDialogClose(): void {
    setSomethingWrongDialogOpened(false);
  }

  function onSomethingWrongError(): void {
    // TODO no error indication in the UI
    setSomethingWrongDialogOpened(false);
  }

  function onSomethingWrongEdit(updatedSubscription: Subscription): void {
    setLocalSubscriptionClone(updatedSubscription);
  }

  useMountEffect(() => {
    setLocalSubscriptionClone(unresolvedSubscription);
    getMixpanel().track(EventNames.VIEWED_GUIDE, {
      Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
      Page: TrackingPageName.SELECT_SERVICE,
      Type: 'Contract guide',
    });
    if (unresolvedSubscription.supplier?.singleServiceSupplier || currentSupplier.services.length <= 1) {
      Sentry.captureMessage(
        'Suspicously showing service selector for merchant without services or single service',
        {
          extra: {
            currentSupplier,
            subscriptionId: unresolvedSubscription.id,
            subscriptionSource: unresolvedSubscription.source,
            subscriptionSupplier: unresolvedSubscription.supplier,
          },
        },
        'warning'
      );
    }
  });

  const lastPaymentDate = unresolvedSubscription.bankEvents ? unresolvedSubscription.bankEvents[0].date : '';

  return (
    <>
      <FlowHeader header={<LocalizedMessage id={'headline'} />} />
      <FlowContent>
        <ProviderInfo
          imageUrl={unresolvedSubscription.supplier.logoUrl}
          imageAlt={unresolvedSubscription.supplier.name}
          label={<LocalizedMessage id="latestInvoice" />}
          content1={
            partOfMultipleSubscriptions && lastPaymentDate ? (
              localizeCostWithCurrency(localSubscriptionClone.cost)
            ) : (
              <>
                {localizeCostWithCurrency(localSubscriptionClone.cost)}/
                <PerInterval interval={localSubscriptionClone.paymentInterval} showPrefix={false} />
              </>
            )
          }
          content2={
            lastPaymentDate && partOfMultipleSubscriptions ? (
              <>
                <LocalizedMessage id="paid" />
                &nbsp;
                {unresolvedSubscription.bankEvents ? unresolvedSubscription.bankEvents[0].date : ''}
              </>
            ) : undefined
          }
        />
        <div className={styles.actionLink}>
          <Body color={colors.textOn.surfaceSubdued} onClick={onOpenSomethingWrongDialog} variant="link">
            <LocalizedMessage id={'question'} />
          </Body>
        </div>
        {currentSupplier && <HorizontalLayout>{getServicesList(currentSupplier)}</HorizontalLayout>}
      </FlowContent>
      <FlowFooter>
        <BackButton onClick={guideBackAction} data-test="guide-back-button" />
        <NextButton
          label={<LocalizedMessage id={'next'} />}
          onClick={guideForwardAction}
          disabled={isEmpty(selectedServices)}
          data-test="guide-next-button"
        />
      </FlowFooter>
      <WhatsWrongDialog
        subscription={localSubscriptionClone}
        open={somethingWrongDialogOpened}
        onClose={somethingWrongDialogClose}
        onSubscriptionRemoved={somethingWrongDeleteAction}
        onPriceUpdated={onSomethingWrongEdit}
        onError={onSomethingWrongError}
      />
    </>
  );
};

export const FlowPageUnresolvedSubscriptionComponent = withLocalization('subscription/UnresolvedGuide')(
  FlowPageUnresolvedSubscriptionComponentInner
);

FlowPageUnresolvedSubscriptionComponentInner.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
};
