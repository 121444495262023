import type { User } from '@client/models/user-models';
import type { Contract, Subscription } from '../subscription/types';

export function subscriptionsWithOnlyImprovableContracts(subscriptions: Subscription[]) {
  const subscriptionsWithImprovableContracts: Subscription[] = subscriptions.map((subscription) => {
    const improvableContracts = subscription.contracts.filter((contract) => contract.optimizable);

    return {
      ...subscription,
      contracts: improvableContracts,
    };
  });

  return subscriptionsWithImprovableContracts.filter(hasOneOrMoreContracts);
}

export function hasOneOrMoreContracts(subscription: Subscription) {
  return subscription.contracts.length > 0;
}

export function isImprovableContractUnreviewed(contract: Contract, user: User): boolean {
  // @ts-ignore Part of Switch, will be removed soon
  const userReviewedContracts = user.contractImprovementsExplored ?? [];
  const reviewedContract = userReviewedContracts.find((c: any) => c.contractId === contract.id);

  return contract.optimizable && !reviewedContract;
}
