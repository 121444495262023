import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  body: { marginBottom: '8px' },
  tooltip: { marginBottom: '8px', marginTop: '-8px' },
  cardStyle: {
    margin: '-1rem',
  },
  caption: { textDecoration: 'underline' },
  small: {
    width: '25%',
    minWidth: '50px',
    maxWidth: '100px',
  },
  medium: {
    width: '50%',
    minWidth: '50px',
    maxWidth: '150px',
  },
  large: {
    width: '75%',
    minWidth: '50px',
    maxWidth: '250px',
  },
}));
