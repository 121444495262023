import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { selectorUnresolvedSubscriptionsForGuide } from '@client/modules/overview/duck';
import { getMixpanel } from '@client/tracking/mixpanel';
import {
  EventNames,
  FEATURE_ONBOARDING,
  FEATURE_OVERVIEW,
  FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
  TrackingPageName,
} from '@client/tracking/mixpanel-constants';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useTheme from '@material-ui/core/styles/useTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { CloseIcon } from '@minna-technologies/minna-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import chestIconUrl from '../icons/chest.svg';

export interface ResolveSubscriptionsDialogProps {
  onClose(): void;
  onGotoResolveSubscriptionsGuide(): void;
  open: boolean;
  unresolvedSubscriptionsNumber: number;
}

const ResolveSubscriptionsDialogComponent: React.FunctionComponent<ResolveSubscriptionsDialogProps> = ({
  onClose,
  onGotoResolveSubscriptionsGuide,
  open,
  unresolvedSubscriptionsNumber,
}: ResolveSubscriptionsDialogProps) => {
  const { colors } = useTheme();
  const unresolvedSubscriptions = useSelector(selectorUnresolvedSubscriptionsForGuide);
  const features = useSelector(selectorFeatures);
  const [singleServiceProvider, setSingleServiceProvider] = useState(false);
  const unresolvedGuideEnabled = features.useUnresolvedSubscriptionGuide;
  useEffect(() => {
    if (unresolvedSubscriptions && unresolvedSubscriptions.length > 0) {
      if (unresolvedSubscriptions.length === 1) {
        setSingleServiceProvider(false);
      } else {
        const firstServiceProvider = unresolvedSubscriptions[0].supplier.id;
        const notUnique = unresolvedSubscriptions.some(
          (subscription) => subscription.supplier.id !== firstServiceProvider
        );
        setSingleServiceProvider(!notUnique);
      }
    }
  }, [unresolvedSubscriptions]);
  useMountEffect(() => {
    if (unresolvedGuideEnabled) {
      getMixpanel().track(EventNames.VIEWED_GUIDE, {
        Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
        Page: 'Intro',
        Type: 'Contract guide',
      });
    } else {
      getMixpanel().track(EventNames.VIEWED_PAGE, {
        Feature: FEATURE_OVERVIEW,
        Page: TrackingPageName.INSIGHTS,
        Type: 'Contract guide',
      });
      getMixpanel().track(EventNames.ENTERED_GUIDE, {
        Feature: FEATURE_ONBOARDING,
        Type: 'Contract guide',
        Page: 'Introduction',
        'Number of unanswered subscriptions in guide': unresolvedSubscriptionsNumber,
      });
    }
  });
  const singleServiceProviderMessage = unresolvedGuideEnabled &&
    unresolvedSubscriptions &&
    unresolvedSubscriptions[0] && (
      <>
        <Body style={{ margin: '20px 16px 16px 32px' }} data-test={'unresolved-guide-entry'}>
          <LocalizedMessage
            id="singleProviderRow1"
            values={{ count: unresolvedSubscriptions.length, provider: unresolvedSubscriptions[0].supplier.name }}
          />
        </Body>
        <Body style={{ margin: '0 16px 32px 32px' }}>
          <LocalizedMessage id="singleProviderRow2" />
        </Body>
      </>
    );
  const standardMessage = (
    <Body style={{ margin: '20px 16px 32px 32px' }}>
      <LocalizedMessage id="resolveSubscriptionsMesage" />
    </Body>
  );
  function renderMessage() {
    if (unresolvedGuideEnabled) {
      return singleServiceProvider && singleServiceProviderMessage ? singleServiceProviderMessage : standardMessage;
    }

    return standardMessage;
  }
  function onMainClick() {
    onGotoResolveSubscriptionsGuide();
  }
  function onCloseClick(reason: string) {
    if (unresolvedGuideEnabled && reason === 'backdropClick') {
      getMixpanel().track(EventNames.CLICKED_BUTTON, {
        Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
        Page: 'Intro',
        Type: 'Contract guide',
        Location: 'Top right',
        Button: 'Close',
      });
    }
  }

  return (
    <StyledDialog open={open} maxWidth="sm" onClose={(event, reason) => onCloseClick(reason)}>
      <StyledDialogTitle disableTypography>
        <Title color={colors.action.primary} style={{ flexGrow: 1, textAlign: 'center' }}>
          <LocalizedMessage id="getFullPicture" />
        </Title>
        <CloseIcon onClick={onClose} />
      </StyledDialogTitle>
      <DialogContent style={{ padding: '0' }}>
        <img src={chestIconUrl} role="presentation" alt="" />
        {renderMessage()}
      </DialogContent>
      <DialogActions>
        <StyledPrimaryButton
          label={<LocalizedMessage id="letsGo" />}
          onClick={onMainClick}
          data-test={'resolve-subscription-dialog-button'}
        />
      </DialogActions>
    </StyledDialog>
  );
};

export const ResolveSubscriptionsDialog = withLocalization('overview/InsightsPage/OnboardingInsightCard')(
  ResolveSubscriptionsDialogComponent
);

const StyledDialog = withStyles({
  paperWidthSm: {
    width: '343px',
    flexShrink: 0,
  },
})(Dialog);

const StyledDialogTitle = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
})(DialogTitle);

const StyledPrimaryButton = withStyles({
  root: {
    margin: '0 16px 4px 16px',
    width: '100%',
  },
})(PrimaryButton);
