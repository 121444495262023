import type { ContractId, SubscriptionId } from '@client/modules/subscription/types';

export const landingPage = '/';

export const settingsPage = '/installningar';
export const userSettingsPage = '/installningar/konto';
export const notificationSettingsPage = '/installningar/notifikationer';
export const profilingSettingsPage = '/installningar/rekommendationer';
export const viewGdprDataPage = '/installningar/gdpr-data';

export const bankLoginPage = '/login';
export const loginPage = '/logga-in';
export const createAccountPage = '/register';
export const resetPasswordPage = '/reset-password';
export const loginStatusPage = '/loggar-in';
export const onboardingSetEmailPage = '/kom-igang/e-postaddress';
export const onboardingTermsAndConsentPage = '/kom-igang/villkor';

export const terminate = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/sag-upp`;
export const terminationFormPage = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/sag-upp`;
export const terminationSignPage = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/sag-upp/signera`;
export const terminationSentPage = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/sag-upp/klar`;
export const terminationInstructionsPage = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/sag-upp/information`;
export const whyDidYouCancelPage = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/sag-upp/anledning`;
export const saveDesk = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/save-desk`;
export const saveDeskStartPage = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${saveDesk(subscriptionId, contractId)}/start`;
export const saveDeskPainChoicesPage = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${saveDesk(subscriptionId, contractId)}/klagomal`;
export const saveDeskDetailsPage = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${saveDesk(subscriptionId, contractId)}/detaljer`;
export const saveDeskConfirmedPage = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${saveDesk(subscriptionId, contractId)}/klar`;

export const contractDetailsPage = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/detaljer`;

export const subscriptionRoutePrefix = '/tjanst';
export const subscriptionContractRoutePrefix = 'kontrakt';
export const subscriptionRoute = (subscriptionId: SubscriptionId) => `${subscriptionRoutePrefix}/${subscriptionId}`;
export const subscriptionContractRoute = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${subscriptionRoute(subscriptionId)}/${subscriptionContractRoutePrefix}/${contractId}`;
export const subscriptionPage = (subscriptionId: SubscriptionId) => `${subscriptionRoute(subscriptionId)}/detaljer`;
export const subscriptionPaymentHistoryPage = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${subscriptionRoute(subscriptionId)}/${subscriptionContractRoutePrefix}/${contractId}/history`;
export const subscriptionSettingsPage = (subscriptionId: SubscriptionId) =>
  `${subscriptionRoute(subscriptionId)}/installningar`;
export const selectServicesPage = (subscriptionId: SubscriptionId) =>
  `${subscriptionRoute(subscriptionId)}/valj-tjanster`;
export const subscriptionUpdateCostPage = (subscriptionId: SubscriptionId) =>
  `${subscriptionRoute(subscriptionId)}/uppdatera`;

export const orderedSubscriptionDetailsPage = (orderId: any) => `/bestalld-tjanst/${orderId}`;

export const overviewPage = '/tjanster';
export const syncLoadingPage = '/tjanster-synkade';
export const improvementPage = `${overviewPage}/forbattra`;
export const menuPage = '/meny';
export const morePage = '/more';
export const cancellationListPage = `${overviewPage}/sag-upp`;

export const manualAddSubscriptionPage = '/lagg-till-tjanst';
export const manualSubscriptionPricingPage = '/fyll-i-detaljer';

export const feedbackPage = '/lamna-feedback';

export const conditionsAndPoliciesMenu = '/sakerhet-och-villkor';
export const conditionsAndPoliciesOnboardingPage = '/publik-sakerhet-och-villkor';
export const userTermsPage = '/anvandarvillkor';
export const privacyPolicyPage = '/integritetspolicy';
export const securityPage = '/sakerhet';
export const cookiesPage = '/kakor';
export const userDeletePage = '/ta-bort-konto';
export const loggedOutPage = '/utloggad';
export const signoutSuccessPage = '/sign-out-success';

export const userDeleteSuccessPage = '/konto-borttaget';

export const pushNotificationOnboarding = '/push-notification-onboarding';

export const onboard = '/onboard';
export const onboardBankSync = '/onboard/bank-sync';
export const onboardConsent = '/onboard/consent';
export const onboardBankDiscoveryWorking = '/onboard/loading';

export const onboardingPsd2 = '/oauth-init';
export const onboardingPsd2Consent = '/oauth-init/consent';
export const onboardingPsd2ConsentExpired = '/oauth-init/renew-consent';
export const onboardingPsd2Callback = '/oauth-callback';

export const monthlyChecklist = '/checklist';
export const recommendation = '/recommendation';

export const quickActions = '/quick';
export const quickActionsCancel = '/quick/cancel';

export const unsubscribeEmailPath = (emailUserId: string) => `/avregistrera-epost/${emailUserId}`;
