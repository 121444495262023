import { isProductionEnvironment } from '@client/assets/js/sentry';

/**
 * @param mode can take the values of "lmt" for limited or "std" for standard. We'll use the limited mode for it
 * is compatible with mobile screens
 */
export const nemIdClientUrl = (mode: string = 'lmt') => {
  const random = new Date().getTime();

  return `${nemIdClientBaseUrl}/launcher/${mode}/${random}`;
};

export const nemIdClientBaseUrl = isProductionEnvironment() ? 'https://applet.danid.dk' : 'https://appletk.danid.dk';
