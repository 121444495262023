import { useLocalization } from '@client/internationalization';
import { buttonClickedSubscriptionPageMixpanelEvent } from '@client/modules/subscription/mixpanel';
import { subscriptionSettingsPage } from '@client/routes';
import { makeStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { EditIcon } from '@minna-technologies/minna-ui/icons/Edit';
import React from 'react';
import { useHistory } from 'react-router-dom';
import type { Subscription } from '../../types';

const useStyles = makeStyles({
  settingsContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '32px 0 8px 0',
    cursor: 'pointer',
  },
});

export interface Props {
  subscription: Subscription;
}

export const SubscriptionSettingsButton = ({ subscription }: Props) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const { localizeMessage } = useLocalization('contract/SettingsButtonLolipop');
  const history = useHistory();

  const pageName = 'Subscription details';

  function goToSettingsPage() {
    if (subscription) {
      buttonClickedSubscriptionPageMixpanelEvent(subscription, 'Settings', pageName);
      history.push(subscriptionSettingsPage(subscription.id));
    }
  }

  return (
    <div className={classes.settingsContainer}>
      <SecondaryButton
        onClick={goToSettingsPage}
        icon={<EditIcon nativeColor={colors.textOn.surfaceSubdued} />}
        iconPlacement={'left'}
        label={localizeMessage('editSubscription')}
        data-test="subscription-edit-button"
      />
    </div>
  );
};
