import { Sentry } from '@client/assets/js/sentry';
import { getPreloadedFetchOrFallback } from '@client/modules/preloaded-resources';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { AppContextIncoming } from './index';

/**
 * Gets the platform config for the current user. If the value was preloaded it is cached until browser reload.
 */
export async function getCachedOrFetchAppContext(): Promise<AppContextIncoming> {
  const responsePromise: Promise<AppContextIncoming> = getPreloadedFetchOrFallback('platformConfig', async () => {
    return fetchAppContext();
  });

  return responsePromise.catch((error) => {
    Sentry.captureExceptionWithMessage(error, 'Failed to fetch AppContext');

    throw error;
  });
}

export async function fetchAppContext(): Promise<AppContextIncoming> {
  return axios.get('/api/platform-config').then((response: AxiosResponse<AppContextIncoming>) => response.data);
}
