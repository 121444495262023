import { selectorMarket } from '@client/modules/app-context/duck';
import { connect } from 'react-redux';
import { Component } from './component';

const mapStateToProps = (state: any) => {
  return {
    market: selectorMarket(state),
  };
};

const MobilePhoneNumberFieldContainer = connect(mapStateToProps)(Component);

export { MobilePhoneNumberFieldContainer as MobilePhoneNumberField };
