import type { WithLocalizationContextType } from '@client/internationalization';
import { AppIntegration } from '@client/modules/app-integration';
import type { OrderedGenericProduct } from '@client/modules/switch/ordered-subscription/models';
import { OrderedSubscriptionInfoCard } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionDetails/components/OrderedSubscriptionInfoCard';
import compact from 'lodash/compact';
import PropTypes from 'prop-types';
import React from 'react';

interface OrderedGenericProductInfoProps {
  orderedProduct: OrderedGenericProduct;
  goToTermsPage(): void;
}

export const OrderedGenericProductInfo: React.FunctionComponent<OrderedGenericProductInfoProps> = (
  { orderedProduct, goToTermsPage },
  { localizeCostWithCurrency, localizeMessage }: WithLocalizationContextType
) => {
  const onGoToTermsAndConditionsClick = () => {
    if (orderedProduct.termsAndConditions?.length !== 0) {
      return goToTermsPage;
    } else if (orderedProduct.supplierInformation.termsUrl) {
      return () => AppIntegration.openExternalWebPage(orderedProduct.supplierInformation.termsUrl ?? '');
    } else {
      return undefined;
    }
  };

  return (
    <OrderedSubscriptionInfoCard
      onGoToTermsAndConditionsClick={onGoToTermsAndConditionsClick()}
      productInfos={[
        {
          labelValues: compact([
            {
              label: localizeMessage('priceTitle'),
              value: localizeMessage('priceText', {
                monthlyCost: localizeCostWithCurrency(orderedProduct.monthlyCost),
              }),
            },
            orderedProduct.startUpFee && {
              label: localizeMessage('startFeeTitle'),
              value: localizeCostWithCurrency(orderedProduct.startUpFee),
            },
            orderedProduct.information.bindingPeriod && {
              label: localizeMessage('bindingTimeTitle'),
              value: localizeMessage('periodInMonths', { months: orderedProduct.information.bindingPeriod }),
            },
            orderedProduct.information.terminationTime && {
              label: localizeMessage('terminationTimeTitle'),
              value: localizeMessage('periodInMonths', { months: orderedProduct.information.terminationTime }),
            },
          ]),
        },
      ]}
    />
  );
};

OrderedGenericProductInfo.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
  localizeMessage: PropTypes.func.isRequired,
};
