import { LocalizedMessage } from '@client/internationalization';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
// @ts-ignore js import, remove this when the import is typed
import Paper from 'material-ui/Paper';
import React from 'react';
import { ZendeskSubHeader } from '../../../../ZendeskSubHeader';
import styles from './styles.scss';

export interface ZendeskCallBackSuccessProps {
  callBackRequestData: {
    timeSlotInput: string;
    phoneInput: string;
    emaillInput: string;
  };
}

export const ZendeskCallBackSuccess = ({ callBackRequestData }: ZendeskCallBackSuccessProps) => {
  return (
    <Paper className={styles.paper}>
      <div className={styles.container} data-test="zendesk-callback-success-page">
        <div>
          <ZendeskSubHeader title={<LocalizedMessage id="callBackConfirmedTitle" />} />
          <Body className={styles['force-margin-top']}>
            <LocalizedMessage id="callBackConfirmedDescription1" />
          </Body>
          <Body className={styles['force-margin-top']}>
            <LocalizedMessage
              id="callBackConfirmedDescription2"
              values={{ timeSlot: callBackRequestData.timeSlotInput, phoneNumber: callBackRequestData.phoneInput }}
            />
          </Body>
          <Body className={styles['force-margin-top']}>
            <LocalizedMessage id="callBackConfirmedDescription3" />
          </Body>
        </div>
        <div>
          <Body className={styles['stick-to-buttom']}>
            <LocalizedMessage id="callBackConfirmedNote" />
          </Body>
        </div>
      </div>
    </Paper>
  );
};
