import { debouncePromise } from '@client/assets/js/utils/debounce-promise';
import { isCancellationEnabled, isChangePlanEnabled, isPauseEnabled } from '@client/modules/app-context';
import { selectorAppContext, selectorFeatures } from '@client/modules/app-context/duck';
import { onRequestCancellationOfContract } from '@client/modules/cancellation/onCancelContract';
import { onRequestPause } from '@client/modules/pause/onRequestPause';
import { onRequestResume } from '@client/modules/pause/onRequestResume';
import { ContractDetailsPageComponent } from '@client/modules/subscription/ContractDetailsPage/component';
import {
  clickedSubscriptionActionMixpanelEvent,
  updateBindingTimeContractDetailsMixpanelEvent,
  viewPageMixpanelEvent,
} from '@client/modules/subscription/mixpanel';
import type {
  Contract,
  ContractBindingPeriod,
  ContractId,
  Subscription,
  SubscriptionId,
} from '@client/modules/subscription/types';
import { BindingPeriodType, ContractState } from '@client/modules/subscription/types';
import { TrackingButtonName, TrackingFeature, TrackingPageName } from '@client/tracking/mixpanel-constants';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectorSubscription, updateContract } from '../duck';
import { updateContract as updateContractRequest } from '../model';
import { onRequestChangePlan } from '@client/modules/change-plan/onRequestChangePlan';

export interface ContractDetailsPageProps {
  subscriptionId: SubscriptionId;
  contractId: ContractId;
}

export enum BindingPeriod {
  Yes = 'Yes',
  No = 'No',
  Unknown = 'Unknown',
}

export const ContractDetailsPage: React.FunctionComponent<ContractDetailsPageProps> = ({
  contractId,
}: ContractDetailsPageProps) => {
  const subscription: Subscription | undefined = useSelector(selectorSubscription);
  const features = useSelector(selectorFeatures);
  const appContext = useSelector(selectorAppContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const contract: Contract | undefined = subscription?.contracts?.find(
    (possibleContract: Contract) => possibleContract.id === contractId
  );
  // @ts-ignore
  const onSaveContractBinding = debouncePromise(async (contractBindingPeriod: ContractBindingPeriod) => {
    if (!subscription) {
      return;
    }
    const updatedContract: Contract = {
      ...(contract as Contract),
      contractBindingPeriod,
    };
    const bindingTime: BindingPeriod =
      contractBindingPeriod.type === BindingPeriodType.FixedContractBindingPeriod
        ? BindingPeriod.Yes
        : contractBindingPeriod.type === BindingPeriodType.NoContractBindingPeriod
        ? BindingPeriod.No
        : BindingPeriod.Unknown;
    updateBindingTimeContractDetailsMixpanelEvent(
      subscription,
      TrackingPageName.CONTRACT_DETAILS,
      bindingTime,
      contract
    );

    return updateContractRequest(updatedContract).then((contract) => {
      dispatch(updateContract(contract));
    });
  });

  useMountEffect(() => {
    if (subscription && contract) {
      viewPageMixpanelEvent(subscription, TrackingPageName.CONTRACT_DETAILS, {
        'Binding time set': contract.contractBindingPeriod.type === BindingPeriodType.FixedContractBindingPeriod,
        'Monthly Cost': subscription.cost.amount,
      });
    }
  });

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const action = params.get('action');
    if (action && action == 'cancel') {
      if (contract && subscription) {
        //If contract is recently cancelled, stay on this page, otherwise directly go to the cancellation form. See BI-1471 for more information.
        if (contract.state == ContractState.Active) {
          onRequestCancellationOfContract(appContext, contract, subscription, history, dispatch);
        }
      }
    }
  }, [location, appContext, contract, dispatch, history, subscription]);

  const onCancelClick = () => {
    if (contract && subscription) {
      onRequestCancellationOfContract(appContext, contract, subscription, history, dispatch);
    }
  };

  const onPauseClick = () => {
    if (contract && subscription) {
      onRequestPause(appContext, contract, subscription, history);
    }
  };

  const onResumeClick = () => {
    if (contract && subscription) {
      onRequestResume(appContext, contract, subscription, history);
    }
  };

  const onChangePlanClick = () => {
    if (contract && subscription) {
      clickedSubscriptionActionMixpanelEvent(
        TrackingFeature.ChangePlan,
        contract,
        subscription,
        TrackingButtonName.CHANGE_PLAN,
        appContext.market,
        appContext.appType,
        TrackingPageName.CONTRACT_DETAILS
      );
      onRequestChangePlan(appContext, contract, subscription, history);
    }
  };

  const cancelEnabled = isCancellationEnabled(features.actionFeatures) && contract?.state === ContractState.Active;
  const pauseEnabled =
    isPauseEnabled(features.actionFeatures) && contract?.pausable && contract?.state === ContractState.Active;
  const resumeEnabled = isPauseEnabled(features.actionFeatures) && contract?.pauseInfo;
  const changePlanEnabled = isChangePlanEnabled(features.actionFeatures) && contract?.changePlanEnabled;

  return subscription ? (
    <ContractDetailsPageComponent
      subscription={subscription}
      contract={contract}
      onSaveContractBinding={onSaveContractBinding}
      onCancelClick={cancelEnabled ? onCancelClick : undefined}
      onPauseClick={pauseEnabled ? onPauseClick : undefined}
      onResumeClick={resumeEnabled ? onResumeClick : undefined}
      onChangePlanClick={changePlanEnabled ? onChangePlanClick : undefined}
    />
  ) : null;
};
