import { isMobileApp, isMobileBrowser } from '@client/modules/app-integration/checks';
import get from 'lodash/get';
import type { ReactNode } from 'react';
import React from 'react';

interface StableFullDeviceHeightProps {
  children: ReactNode;
  className: string;
  heightProperty: string;
}

// eslint-disable-next-line import/no-default-export, react/no-unsafe
export default class StableFullDeviceHeight extends React.Component<StableFullDeviceHeightProps, any> {
  public static defaultProps = {
    heightProperty: 'minHeight',
  };

  public UNSAFE_componentWillMount() {
    if (isMobileBrowser() && !isMobileApp()) {
      const devicePixelRatio = get(window, 'devicePixelRatio', 1);
      // eslint-disable-next-line yoda
      const heightChangeTolerance = 2 * devicePixelRatio; // Approximate height of URL bar in Chrome on tablet

      const onResize = this.handleResize.bind(this);
      const onScroll = this.handleScroll.bind(this);

      const viewportHeight = window.innerHeight;

      this.setState({
        onResize,
        onScroll,
        heightChangeTolerance,
        viewportHeight,
        style: { [this.props.heightProperty]: `${viewportHeight}px` },
      });

      window.addEventListener('scroll', onScroll);
      window.addEventListener('resize', onResize);
    } else {
      this.setState({
        style: { [this.props.heightProperty]: '100vh' },
      });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.state.onResize);
    window.removeEventListener('scroll', this.state.onScroll);
  }

  public handleResize() {
    if (Math.abs(this.state.viewportHeight - window.innerHeight) > this.state.heightChangeTolerance) {
      this.updateHeight();
    }
  }

  public handleScroll() {
    if (window.scrollY === 0) {
      this.updateHeight();
    }
  }

  public updateHeight() {
    const viewportHeight = window.innerHeight;
    this.setState({
      style: { [this.props.heightProperty]: `${viewportHeight}px` },
      viewportHeight,
    });
  }

  public render() {
    return (
      <div style={{ ...this.state.style, display: 'flex', flexDirection: 'column' }} className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}
