import PropTypes from 'prop-types';
import React from 'react';
import { withState } from 'recompose';
import isString from 'lodash/isString';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const ErrorDialog = ({ open, setOpen, title, children }) => {
  const actions = [
    <Button color="primary" onClick={() => setOpen(false)} key="action1">
      Ok
    </Button>,
  ];
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const ErrorDialogWithState = withState('open', 'setOpen', true)(ErrorDialog);
ErrorDialogWithState.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ErrorDialogWithState;

export const ResponseErrorDialog = ({ response, ...props }) => {
  const responseData = response.data;
  const preText = isString(responseData) ? responseData : JSON.stringify(responseData, null, 2);

  return (
    <ErrorDialogWithState {...props}>
      <p>Got response status: {response.status}</p>
      <pre style={{ whiteSpace: 'pre-wrap' }}>{preText}</pre>
    </ErrorDialogWithState>
  );
};

ResponseErrorDialog.propTypes = {
  response: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
