import { extractSuccess } from '@client/models/Fetchable';
import { LoadingState } from '@client/models/LoadingState';
import { conditionalRender } from '@client/modules/fetching/conditionalRender';
import type React from 'react';
import { compose, withProps } from 'recompose';
import { ErrorLoading } from './ErrorPage/ErrorLoading';
import LoadingPage from './LoadingPage';

interface HandleFetchableOptions {
  onNotStarted?: React.ComponentType;
  onLoading?: React.ComponentType;
  onFailure?: React.ComponentType;
}
/**
 * Renders different pages depending on the state of a fetchable
 * When fetch succeeds, the property is converted from a Fetchable<T> into a T
 *
 * @param fetchableName - the property name of the Fetchable<T>
 * @param onNotStarted - what to render when Fetchable is NotStarted
 * @param onFailure - what to render when Fetchable is Failure
 * @param onLoading - what to render when Fetchable is Loading
 */
export function handleFetchable<T extends string>(
  fetchableName: T,
  { onNotStarted = LoadingPage, onFailure = ErrorLoading, onLoading = LoadingPage }: HandleFetchableOptions = {}
) {
  return compose(
    conditionalRender((props: any) => props[fetchableName].type === LoadingState.NotStarted, onNotStarted),
    conditionalRender((props: any) => props[fetchableName].type === LoadingState.Loading, onLoading),
    conditionalRender((props: any) => props[fetchableName].type === LoadingState.Failure, onFailure),
    withProps((props: any) => ({
      [fetchableName]: extractSuccess(props[fetchableName]),
    }))
  );
}
