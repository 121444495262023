import { ThemeType } from '@client/models/ThemeType';
import styles from '@client/modules/onboarding-overview-drawer/slides/styles.scss';
import type React from 'react';

export const secondaryColorWaffle = '#ff6200';

export const containerDesktop = (theme: ThemeType | undefined): string => {
  switch (theme) {
    case ThemeType.WAFFLE:
      return styles.containerDesktopWaffle;
    case ThemeType.MINNA:
    default:
      return styles.containerDesktop;
  }
};

export const minHeightContainer = (theme: ThemeType | undefined): string => {
  switch (theme) {
    case ThemeType.WAFFLE:
      return styles.minHeightContainerWaffle;
    case ThemeType.MINNA:
    default:
      return styles.minHeightContainer;
  }
};

export const secondaryColor = (theme: ThemeType | undefined, secondaryColor: React.CSSProperties['color']): string => {
  switch (theme) {
    case ThemeType.WAFFLE:
      return secondaryColorWaffle;
    case ThemeType.SPANISH_OMELETTE:
    case ThemeType.MINNA:
    default:
      return secondaryColor as string;
  }
};
