import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { FEATURE_OVERVIEW } from '@client/tracking/mixpanel-constants';
import { sendButtonClickedEvent } from '@client/tracking/mixpanel-tracking-events';
import useTheme from '@material-ui/core/styles/useTheme';
import { IconButton } from '@minna-technologies/minna-ui/components/Buttons/IconButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import { AddIcon } from '@minna-technologies/minna-ui/icons/Add';
import React from 'react';
import styles from './styles.scss';

interface OnAddManualSubscriptionProps {
  onAddManualSubscription(): void;
}

const AddManualSubscriptionInner = ({ onAddManualSubscription }: OnAddManualSubscriptionProps) => {
  const { colors } = useTheme();

  function onClick() {
    sendButtonClickedEvent(FEATURE_OVERVIEW, FEATURE_OVERVIEW, 'Add Manual');
    onAddManualSubscription();
  }

  return (
    <div className={styles.container} role="button" onClick={onClick} data-test="add-manual-subscription">
      <div className={styles.text}>
        <Subheading2 style={{ marginBottom: '4px' }}>
          <LocalizedMessage id="missingSomething" />
        </Subheading2>
        <Body>
          <LocalizedMessage id="youCanAddItHere" />
        </Body>
      </div>
      <IconButton className={styles.icon}>
        <AddIcon nativeColor={colors.textOn.surfaceSubdued} />
      </IconButton>
    </div>
  );
};

export const AddManualSubscription = withLocalization('overview/addManualSubscription')(AddManualSubscriptionInner);
