import { getMixpanel } from '@client/tracking/mixpanel';
import type { TrackingFeature } from '@client/tracking/mixpanel-constants';
import { EventNames, TrackingPageName, TrackingButtonName } from '@client/tracking/mixpanel-constants';
import debounce from 'lodash/debounce';

export const trackClickedMerchantAction = (
  merchantId: string,
  merchantName: string,
  feature: TrackingFeature,
  category?: string
) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: feature,
    Page: TrackingPageName.SUBSCRIPTION_SEARCH,
    Button: 'Search result row',
    'Supplier name': merchantName,
    'Supplier ID': merchantId,
    Category: category,
  });
};

export const trackClickedServiceAction = (
  merchantId: string,
  merchantName: string,
  serviceId: string,
  feature: TrackingFeature,
  category?: string
) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: feature,
    Page: 'Multi service selection',
    Button: 'Service row',
    'Supplier name': merchantName,
    'Supplier ID': merchantId,
    Category: category,
    'Service ID': serviceId,
  });
};

export const trackViewedSubscriptionSearch = (feature: TrackingFeature) => {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: feature,
    Page: TrackingPageName.SUBSCRIPTION_SEARCH,
  });
};

export const trackViewedSelectService = (feature: TrackingFeature) => {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: feature,
    Page: 'Select service',
  });
};

export const trackSelectedTopMerchant = (
  merchantId: string,
  merchantName: string,
  feature: TrackingFeature,
  category?: string
) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: feature,
    Page: TrackingPageName.SUBSCRIPTION_SEARCH,
    Button: 'Top merchant row',
    'Supplier name': merchantName,
    'Supplier ID': merchantId,
    Category: category,
  });
};

// This method sends mixpanel events with the users search query, therefore we debounce it to not bombard mixpanel.
export const trackSubscriptionSearchQuery = debounce((query: string, feature: TrackingFeature) => {
  getMixpanel().track(EventNames.SEARCHED, {
    Feature: feature,
    Page: TrackingPageName.SUBSCRIPTION_SEARCH,
    'Search term': query,
  });
}, 500);

export const trackIntroDrawerClosed = (feature: TrackingFeature) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: feature,
    Page: TrackingPageName.SUBSCRIPTION_SEARCH,
    Button: 'Close intro drawer',
  });
};

export const trackClickedBackButton = (feature: TrackingFeature) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: feature,
    Page: TrackingPageName.SUBSCRIPTION_SEARCH,
    Button: TrackingButtonName.BACK,
  });
};
