import { LocalizedMessage } from '@client/internationalization';
import type { SupplierService } from '@client/models/supplier';
import type { Contract } from '@client/modules/subscription/types';
import { abortedElectricityTerminationFromDialogMixpanelEvent } from '@client/tracking/mixpanel-events';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';

interface CancelElectricityGridContractConfirmationDialogProps {
  contract?: Contract;
  service?: SupplierService;
  open: boolean;
  onClose(): void;
  onContinue(): void;
}

export const CancelElectricityGridContractConfirmationDialog: React.FunctionComponent<
  CancelElectricityGridContractConfirmationDialogProps
> = ({ contract, service, open, onClose, onContinue }) => {
  const handleClose = () => {
    abortedElectricityTerminationFromDialogMixpanelEvent(contract, service);
    onClose();
  };

  return (
    <Modal
      onClose={handleClose}
      open={open}
      button1={
        <PrimaryButton label={<LocalizedMessage id="cancelElectricityGridConfirmOptionYes" />} onClick={onContinue} />
      }
      button2={
        <SecondaryButton label={<LocalizedMessage id="cancelElectricityGridConfirmOptionNo" />} onClick={handleClose} />
      }
      title={<LocalizedMessage id="cancelElectricityGridConfirmTitle" />}
    >
      <Body>
        <LocalizedMessage id="cancelElectricityGridConfirmDescription" />
      </Body>
    </Modal>
  );
};
