import Sentry from '@client/assets/js/sentry';
import { persistSearchTerm } from '@client/ducks/search-entrypoint';
import { useLocalization } from '@client/internationalization';
import type { SupplierService, SupplierWithServices } from '@client/models/supplier';
import { fetchSupplier } from '@client/models/supplier';
import { selectorAppType, selectorMarket } from '@client/modules/app-context/duck';
import { cancelServiceUsingIntelligentGuide } from '@client/modules/cancellation/api';
import LoadingPage from '@client/modules/fetching/LoadingPage';
import { getServiceIntelligentGuide, saveViewedIntelligentGuideEvent } from '@client/modules/intelligent-guides/api';
import { IntelligentGuidePageComponent } from '@client/modules/intelligent-guides/components/IntelligentGuidePageComponent';
import {
  sendViewedGuideCancellationGuideMixpanelEvent,
  trackClickedButton,
} from '@client/modules/intelligent-guides/mixpanel';
import type { IntelligentGuide } from '@client/modules/intelligent-guides/models';
import * as urls from '@client/routes';
import {
  TrackingButtonLocation,
  TrackingButtonName,
  TrackingPageName,
  TrackingSubscriptionMethod,
  TrackingFeature,
} from '@client/tracking/mixpanel-constants';
import { useBackOrDefault } from '@client/utils/hooks/history';
import { ContractHeader } from '@minna-technologies/minna-ui/components/ContractHeader';
import { ContractHeaderLogo } from '@minna-technologies/minna-ui/components/ContractHeader';
import type { FC } from 'react';
import { useState } from 'react';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SubscriptionActionType } from '@client/models/subscription';

interface Props {
  merchantId: string;
  serviceId: string;
}

export const IntelligentGuideServicePage: FC<Props> = ({ merchantId, serviceId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { localizeMessage } = useLocalization('cancellation/IntelligentGuidePage');
  const market = useSelector(selectorMarket);
  const appType = useSelector(selectorAppType);

  const [intelligentGuide, setIntelligentGuide] = useState<IntelligentGuide | undefined>(undefined);
  useEffect(() => {
    getServiceIntelligentGuide(merchantId, serviceId, SubscriptionActionType.Cancel).then((data) => {
      setIntelligentGuide(data);
    });
  }, [merchantId, serviceId]);
  const [merchant, setMerchant] = React.useState<SupplierWithServices | undefined>(undefined);
  const [service, setService] = React.useState<SupplierService | undefined>(undefined);
  useEffect(() => {
    fetchSupplier(merchantId).then((supplier) => {
      setMerchant(supplier);
      const newService = supplier.services?.find((service) => service.id === serviceId);
      if (newService === undefined) {
        Sentry.captureMessage(`Failed to find service ${serviceId} to CANCEL`, {
          extra: { merchantId, serviceId },
        });
      }
      setService(newService);
    });
  }, [serviceId, merchantId]);
  const onSubmitDialog = () => {
    dispatch(persistSearchTerm(undefined));
    history.push(urls.subscriptionHub);
  };

  const onCancel = () => {
    dispatch(persistSearchTerm(undefined));
    cancelServiceUsingIntelligentGuide(merchantId, serviceId)
      .then(() => history.push(urls.cancelServiceIntelligentGuideDonePageFun(merchantId, serviceId)))
      .catch((error: Error) => {
        Sentry.captureExceptionWithMessage(error, `Failed to create cancellation for service ${service?.id}`);
      });
  };
  const backOrDefault = useBackOrDefault(urls.subscriptionHub);

  const sendClickedButtonMixpanelEventWithService = (
    buttonLocation: TrackingButtonLocation,
    buttonName: string,
    page: string = TrackingPageName.CANCEL_INTELLIGENT_GUIDE,
    reason?: string
  ) => {
    trackClickedButton(
      TrackingFeature.CancelIG,
      page,
      buttonLocation,
      buttonName,
      merchantId,
      serviceId,
      market,
      appType,
      merchant?.name,
      reason
    );
  };

  const trackIntelligentGuideClickedButton = () => {
    trackClickedButton(
      TrackingFeature.CancelIG,
      TrackingPageName.CANCEL_INTELLIGENT_GUIDE,
      TrackingButtonLocation.CENTER,
      TrackingButtonName.BUTTON,
      merchantId,
      serviceId,
      market,
      appType,
      merchant?.name ?? '',
      service?.name ?? '',
      undefined,
      TrackingSubscriptionMethod.IntelligentGuide,
      service?.category?.text ?? ''
    );
  };

  const trackViewedGuide = () => {
    if (service?.id) {
      saveViewedIntelligentGuideEvent(
        SubscriptionActionType.Cancel,
        merchantId,
        merchant?.name ?? '',
        service.id,
        service?.name ?? '',
        service?.category.name ?? ''
      ).catch((error) => {
        Sentry.captureExceptionWithMessage(
          error,
          `Failed to save viewed CANCEL MERCHANT_GUIDE event for service ${service.id}`
        );
      });
    }
    sendViewedGuideCancellationGuideMixpanelEvent(TrackingPageName.CANCEL_INTELLIGENT_GUIDE, merchant?.name ?? '');
  };

  const header = (
    <ContractHeader
      title={service?.category?.text ?? ''}
      subHeading={merchant?.name ?? ''}
      logo={<ContractHeaderLogo logoUrl={merchant?.logoUrl ?? ''} merchantName={merchant?.name ?? ''} />}
    />
  );

  return (
    (intelligentGuide && merchant && service && (
      <IntelligentGuidePageComponent
        header={header}
        intelligentGuide={intelligentGuide}
        dialogPageName={TrackingPageName.CANCEL_INTELLIGENT_GUIDE_DIALOG_REASON}
        linkName={TrackingButtonName.LINK}
        title={localizeMessage('headline')}
        feedbackSectionTitle={localizeMessage('didYouCancel', { providerName: merchant?.name })}
        modalTitle={localizeMessage('whyNotCancel')}
        onGoBack={backOrDefault}
        onConfirmAction={onCancel}
        onSubmitDeclineDialog={onSubmitDialog}
        onClickAnyButton={sendClickedButtonMixpanelEventWithService}
        trackViewedGuide={trackViewedGuide}
        trackClickedIntelligentGuideButton={trackIntelligentGuideClickedButton}
        actionType={SubscriptionActionType.Cancel}
      />
    )) || <LoadingPage />
  );
};
