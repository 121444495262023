import { LocalizedMessage } from '@client/internationalization';
// @ts-ignore js import, remove this when the import is typed
import FullscreenContainerView from '@client/shared-components/layout/FullscreenContainerView';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { ZendeskHeader } from '../../../ZendeskHeader/component';
import styles from '../../styles.scss';
import { ZendeskMinimized } from '../ZendeskMinimized/container';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    overflow: 'scroll',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.base.background,
  },
}));

interface ZendeskMobileDialogProps {
  isOpen: boolean;
  onCloseDialog(): void;
  onNavigateBack(): void;
  visibleComponent: React.ReactNode;
}

export const ZendeskMobileDialog: React.FunctionComponent<ZendeskMobileDialogProps> = ({
  isOpen,
  onCloseDialog,
  onNavigateBack,
  visibleComponent,
}: ZendeskMobileDialogProps) => {
  const classes = useStyles();

  return (
    <div>
      <FullscreenContainerView isOpen={isOpen} name="zendesk-mobile-dialog">
        <div data-test="zendesk-mobile-dialog" className={styles['header-container']}>
          <ZendeskHeader
            onNavigateBack={onNavigateBack}
            title={<LocalizedMessage id="mobileDialogHeaderTitle" />}
            onClose={onCloseDialog}
          />
        </div>
        <div className={classes.contentContainer}>{visibleComponent}</div>
      </FullscreenContainerView>
      <ZendeskMinimized />
    </div>
  );
};
