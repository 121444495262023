import { withLocalization } from '@client/internationalization';
import { selectorMarket } from '@client/modules/app-context/duck';
import { ActionType } from '@client/modules/InsightsPage/models';
import { FeedbackType } from '@client/modules/rating/model';
import { ContractActionOutcomeRatingCard } from '@client/modules/subscription/ContractDetailsPage/components/ContractActionOutcomeRatingCard';
import type { OptimizationInfo } from '@client/modules/subscription/types';
import { rateCompletedOrderMixpanelEvent } from '@client/modules/switch/mixpanel-events';
import { OrderedSubscriptionAction } from '@client/modules/switch/ordered-subscription/duck';
import { useCreateOrderOutcomeInfo } from '@client/modules/switch/ordered-subscription/OrderOutcome';
import { ratedOrderRequestEvent } from '@client/modules/switch/tracking-events';
import type { WithStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const styles = () =>
  createStyles({
    marginTop: {
      marginTop: 8,
    },
    paper: {
      margin: '8px 0 16px',
    },
    headerContainer: {
      padding: '0 16px',
    },
    container: {
      padding: '16px 0',
    },
    content: {
      margin: '16px 16px 0',
    },
  });

interface ContractOrderOutcomeProps {
  optimizationInfo: OptimizationInfo;
  pageName: string;
  category: string;
  oldServiceProviderName?: string;
  sentRating?: boolean;
}

const ContractOrderOutcomeInner: React.FunctionComponent<ContractOrderOutcomeProps & WithStyles<typeof styles>> = ({
  classes,
  optimizationInfo,
  pageName,
  category,
  oldServiceProviderName,
  sentRating,
}) => {
  const actionType = ActionType.IndependentOrder;
  const market = useSelector(selectorMarket);
  const dispatch = useDispatch();
  const onRateOrderExperience = (rating: number, message: string) => {
    rateCompletedOrderMixpanelEvent(
      pageName,
      optimizationInfo.newSupplierName,
      category,
      rating,
      message,
      oldServiceProviderName,
      optimizationInfo.orderState
    );
    ratedOrderRequestEvent(rating, FeedbackType.Completed, category, market, optimizationInfo.orderId, message);
    dispatch(OrderedSubscriptionAction.sentRating());
  };

  const outcomeInfo = useCreateOrderOutcomeInfo(
    optimizationInfo.orderState,
    actionType,
    category,
    optimizationInfo.newSupplierName,
    oldServiceProviderName,
    optimizationInfo.rejectedReason,
    optimizationInfo.bindingTimeEndsAt,
    optimizationInfo.message
  );

  const content = (
    <>
      <div className={classes.headerContainer}>
        <Headline2 style={{ color: outcomeInfo?.color }}>{outcomeInfo?.headline}</Headline2>
      </div>
      {outcomeInfo?.content && <div className={classes.content}>{outcomeInfo.content}</div>}
    </>
  );

  return (
    <Card className={classes.paper} noPadding>
      <div className={classes.container}>{content}</div>
      {!sentRating && !optimizationInfo.userRating && outcomeInfo?.askForRating && (
        <ContractActionOutcomeRatingCard onRatedExperience={onRateOrderExperience} />
      )}
    </Card>
  );
};

export const ContractOrderOutcome: React.ComponentType<ContractOrderOutcomeProps> = withStyles(styles)(
  withLocalization('switch/OrderOutcome')(ContractOrderOutcomeInner)
);
