import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_OVERVIEW } from '@client/tracking/mixpanel-constants';
import { sendButtonClickedEvent } from '@client/tracking/mixpanel-tracking-events';

export const sendViewedPSD2PrimerEvent = () => {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: FEATURE_OVERVIEW,
    Page: 'PSD2 primer',
  });
};

export const continueOnboardingPSD2PrimerEvent = () => {
  sendButtonClickedEvent(FEATURE_OVERVIEW, 'PSD2 primer', 'Continue');
};

export const cancelOnboardingPSD2PrimerEvent = () => {
  sendButtonClickedEvent(FEATURE_OVERVIEW, 'PSD2 primer', 'Back');
};
