import { LocalizedMessage, withLocalization } from '@client/internationalization';
import {
  FeedbackButtons,
  feedbackCardButtonClicked,
  trackFeedbackThankYouMessage,
} from '@client/modules/nps-csat-module/mixpanel-events';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { CarouselCard } from '@minna-technologies/minna-ui/components/CarouselCard';
import { TextArea } from '@minna-technologies/minna-ui/components/Inputs/TextArea';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { HeartIcon } from '@minna-technologies/minna-ui/icons/Heart';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

interface FeedbackThankYouProps {
  showInput?: boolean;
  onClose: () => void;
  opacity?: number;
  fullHeight?: boolean;
}

const useStyles = makeStyles(() => ({
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  paddedContainer: {
    padding: '104px 8px',
  },
  verticalSpace16: {
    marginTop: '16px',
  },
  verticalSpace32: {
    marginTop: '32px',
  },
  verticalSpace8: {
    marginTop: '8px',
  },
  verticalSpace24: {
    marginTop: '24px',
  },
  inputBox: {
    marginTop: '32px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputOptional: {
    width: '100%',
    textAlign: 'end',
    marginTop: '-16px',
  },
  buttonsContainer: {
    display: 'flex',
    marginTop: '16px',
    flexDirection: 'column',
    height: '104px',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    left: 0,
    padding: '0 16px',
    bottom: '16px',
  },
}));

export const Component: React.FunctionComponent<FeedbackThankYouProps> = (
  { onClose, showInput, opacity, fullHeight },
  { localizeMessage }
) => {
  const [inputValue, setInputValue] = useState('');
  const { colors } = useTheme();
  const styles = useStyles();
  const onChangeFeedback = (changeEvent: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(changeEvent.target.value);
  };
  const onSaveFeedback = () => {
    trackFeedbackThankYouMessage(inputValue);
    feedbackCardButtonClicked(FeedbackButtons.SAVE, 'Feedback');
    onClose();
  };
  const onCloseFeedback = () => {
    feedbackCardButtonClicked(FeedbackButtons.CLOSE, 'Feedback');
    onClose();
  };

  const contentWithInput = (
    <CarouselCard fullHeight={fullHeight} opacity={opacity}>
      <div className={styles.centeredContainer}>
        <div>
          <HeartIcon bold nativeColor={colors.action.positive} />
        </div>
        <div>
          <Headline3 className={styles.verticalSpace16}>
            <LocalizedMessage id={'thankYou'} />
          </Headline3>
        </div>
        <div className={styles.verticalSpace8}>
          <Body>
            <LocalizedMessage id={'thankYouBody1'} />
          </Body>
        </div>
        <div className={styles.verticalSpace16}>
          <Body>
            <LocalizedMessage id={'thankYouBody2'} />
          </Body>
        </div>
        <div className={styles.inputBox}>
          <TextArea
            value={inputValue}
            onChange={onChangeFeedback}
            label={''}
            placeholder={localizeMessage('feedbackPlaceholder')}
          />
          <div className={styles.inputOptional}>
            <Caption>
              <LocalizedMessage id={'optional'} />
            </Caption>
          </div>
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <PrimaryButton
          onClick={onSaveFeedback}
          disabled={inputValue === ''}
          fullWidth
          label={<LocalizedMessage id={'saveButtonLabel'} />}
        />
        <SecondaryButton onClick={onCloseFeedback} fullWidth label={<LocalizedMessage id={'closeButtonLabel'} />} />
      </div>
    </CarouselCard>
  );
  const contentWithoutInput = (
    <CarouselCard fullHeight={fullHeight} opacity={opacity}>
      <div className={[styles.centeredContainer, styles.paddedContainer].join(' ')}>
        <div>
          <HeartIcon bold nativeColor={colors.action.positive} />
        </div>
        <div className={styles.verticalSpace24}>
          <Body>
            <LocalizedMessage id={'thankYouBody1'} />
          </Body>
        </div>
      </div>
    </CarouselCard>
  );

  return showInput ? contentWithInput : contentWithoutInput;
};

Component.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const FeedbackCardThankyou = withLocalization('feedback-modal')(Component);
