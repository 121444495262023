import Sentry from '@client/assets/js/sentry';
import * as urls from '@client/routes';
import React from 'react';
import { Redirect } from 'react-router-dom';

export class PageNotFoundRedirect extends React.PureComponent {
  public componentDidMount(): void {
    Sentry.captureMessage('Rendered page not found');
  }

  public render(): React.ReactNode {
    return <Redirect to={urls.overviewPage} />;
  }
}
