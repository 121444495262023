import { useLocalization } from '@client/internationalization';
import { CancellationProgress } from '@client/modules/cancellation/CancellationStepper';
import type { CancellationInfo } from '@client/modules/subscription/types';
import { ServiceNameWithIcon } from '@client/shared-components/ServiceNameWithIcon';
import { parseDate } from '@client/utils/date';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Badge } from '@minna-technologies/minna-ui/components/Badge';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { InfoIcon } from '@minna-technologies/minna-ui/icons/Info';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles({
  card: {
    marginBottom: '1rem',
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
    lineHeight: '28px',
  },
  serviceName: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
});

interface Props {
  categoryName: string;
  cancellationInfo: CancellationInfo;
  serviceProviderName: string;
  serviceName?: string;
  localizedCategoryName: string;
}

export const OngoingCancellation: FC<Props> = ({
  cancellationInfo,
  serviceProviderName,
  categoryName,
  serviceName,
  localizedCategoryName,
}) => {
  const classes = useStyles();
  const { localizeMessage } = useLocalization('contract/ContractDetails');
  const { colors } = useTheme();
  const cancellationRequestDate = cancellationInfo?.cancellationRequestDate
    ? parseDate(cancellationInfo.cancellationRequestDate)
    : new Date();

  const cancellationReminderSentDate =
    cancellationInfo?.cancellationReminderSentDate && parseDate(cancellationInfo.cancellationReminderSentDate);

  return (
    <Card className={classes.card} variant="border" data-test="ongoing-cancellation-card">
      <div className={classes.headerRow}>
        <Badge
          color={colors.textOn.info}
          label={localizeMessage('cancellationOngoingCardTitle')}
          icon={<InfoIcon bold />}
          backgroundColor={colors.base.info}
        />
        <ServiceNameWithIcon
          categoryName={categoryName}
          serviceName={serviceName}
          localizedCategoryName={localizedCategoryName}
        />
      </div>
      <CancellationProgress
        supplierName={serviceProviderName}
        cancellationRequestDate={cancellationRequestDate.toString()}
        cancellationReminderSentDate={cancellationReminderSentDate?.toString()}
      />
    </Card>
  );
};
