import Sentry from '@client/assets/js/sentry';
import type { CategoryName } from '@client/constants/categories';
// @ts-ignore
import { errorPageOnFailure, loadingPageWhileLoading, onEnter } from '@client/containers/container-helpers';
import requireLoggedInUser from '@client/containers/requireLoggedInUser';
import {
  pageLoading,
  pageLoadingFailure,
  pageLoadingSuccess,
  selectorIsLoading,
  selectorLoadingFailed,
  selectorSuppliers,
  setGoToOptimizeOptions,
  setSelectedSupplier,
} from '@client/ducks/manual-add-supplier';
import type { Category, ServiceToDisplay } from '@client/models/service';
import type { SupplierWithServices } from '@client/models/supplier';
import { fetchSuppliers } from '@client/models/supplier';
import * as urls from '@client/routes';
import { viewedAddManualSubscriptionPageMixpanelEvent } from '@client/tracking/mixpanel-events';
import { useZendesk } from '@client/utils/hooks/use-zendesk';
import { eqIgnoreCase } from '@client/utils/utils';
import { LABEL_FAQ_GENERAL } from '@client/zendesk';
import filter from 'lodash/filter';
import curry from 'lodash/fp/curry';
import eq from 'lodash/fp/eq';
import find from 'lodash/fp/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import some from 'lodash/some';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import type { Action, Dispatch, Store } from 'redux';
import { fetchSubscriptions } from '../../subscription';
import { ManualAddContractPage } from './component';

const hasCategoryName = curry((categoryName: CategoryName, { name }: Category): boolean => eq(categoryName, name));

const hasValidCategory = curry((categoryName: CategoryName, service: ServiceToDisplay): boolean =>
  hasCategoryName(categoryName, service.category)
);

export interface ManualAddContractPageProps {
  suppliers: SupplierWithServices[];
  goToOptimize: boolean;
  category: CategoryName;
  specificCategoryText: string;
}

interface MapStateProps {
  suppliers: SupplierWithServices[];
  isLoading: boolean;
  loadingFailed: boolean;
  specificCategoryText: string;
}

const mapStateToProps = (state: any, { specificCategoryText }: ManualAddContractPageProps): MapStateProps => ({
  suppliers: selectorSuppliers(state),
  isLoading: selectorIsLoading(state),
  loadingFailed: selectorLoadingFailed(state),
  specificCategoryText: specificCategoryText,
});

const mergeProps = (stateProps: MapStateProps, dispatchProps: DispatchProps, ownProps: ManualAddContractPageProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSupplierSubmit: (submittedSupplier: string) => {
    dispatchProps.onSupplierSubmit(submittedSupplier, stateProps.suppliers);
  },
  onBackClick: () => maybeOnBackClickFunction(stateProps, ownProps),
});

const maybeOnBackClickFunction = (stateProps: MapStateProps, ownProps: any) => {
  const previousUrl = get(ownProps, 'history.location.state.previousUrl');
  if (previousUrl) {
    ownProps.history.push(previousUrl);
  } else {
    ownProps.dispatch((dispatch: any, getState: any) => {
      ownProps.history.push(getState().manualAddSupplier.goToOptimize ? urls.insightsPage : urls.overviewPage);
    });
  }
};

interface DispatchProps {
  onSupplierSubmit(submittedSupplier: string, suppliers: SupplierWithServices[]): void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<Action<string>>,
  { history, location }: ManualAddContractPageProps & RouteComponentProps
): DispatchProps => ({
  onSupplierSubmit: (submittedSupplier: string, suppliers: SupplierWithServices[]) => {
    const supplierNameMatchesQuery = (supplier: SupplierWithServices): boolean =>
      eqIgnoreCase(supplier.name, submittedSupplier);

    const supplier = find(supplierNameMatchesQuery, suppliers) || { name: submittedSupplier };

    dispatch(setSelectedSupplier(supplier));
    history.push(urls.manualSubscriptionPricingPage, { ...location.state });
  },
});

// TODO: Refactor this to use hooks where it is applicable
export const ManualAddSubscriptionContainer = compose(
  requireLoggedInUser,
  withRouter,
  withProps(({ location }: RouteComponentProps) => ({
    category: get(location, 'state.category'),
    goToOptimize: get(location, 'state.goToOptimize', false),
    onHelpClick: useZendesk(LABEL_FAQ_GENERAL),
  })),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  onEnter(({ suppliers, goToOptimize, category }: ManualAddContractPageProps, { store }: { store: Store }) => {
    const categoryName = category;

    viewedAddManualSubscriptionPageMixpanelEvent(categoryName);

    if (isEmpty(suppliers)) {
      store.dispatch(pageLoading());
    }

    store.dispatch(setGoToOptimizeOptions(goToOptimize, categoryName));

    Promise.all([fetchSuppliers(), fetchSubscriptions()])
      .then(([suppliers, subscriptions]) => {
        let filteredSuppliers = reject(suppliers, (supplier) => some(subscriptions, { supplier: { id: supplier.id } }));

        if (categoryName) {
          filteredSuppliers = filter(suppliers, (supplier) => some(supplier.services, hasValidCategory(categoryName)));
        }
        store.dispatch(pageLoadingSuccess(filteredSuppliers));
      })
      .catch((error) => {
        store.dispatch(pageLoadingFailure());
        Sentry.captureExceptionWithMessage(error, 'Failed to fetch suppliers');
      });
  }),
  loadingPageWhileLoading((p: MapStateProps) => p.isLoading),
  errorPageOnFailure((p: MapStateProps) => p.loadingFailed)
  // @ts-ignore
)(ManualAddContractPage);
