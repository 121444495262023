import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { selectorAppType, selectorFeatures } from '@client/modules/app-context/duck';
import { WidgetProvider } from '@client/modules/subscription-hub/WidgetProvider';
import makeStyles from '@material-ui/styles/makeStyles';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sendViewedSubscriptionHub } from './mixpanel';
import { SettingsButton } from '@client/modules/subscription-hub/SettingsButton';
import { AppType } from '@client/modules/app-context/constants';
import { Header } from './Header';

const useStyles = makeStyles({
  actionsHeadline: {
    marginTop: '40px',
    marginBottom: '16px',
  },
  settingsLayout: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    flexDirection: 'column',
    display: 'flex',
    marginBottom: '32px',
  },
});

const SubscriptionHubInner = () => {
  const classes = useStyles();
  const features = useSelector(selectorFeatures);
  const appType = useSelector(selectorAppType);

  useEffect(() => {
    sendViewedSubscriptionHub();
  }, []);

  const isOP = appType === AppType.OP;
  return (
    <>
      {isOP && <Header />}
      <MultiDeviceLayout
        hasTopNavigation={isOP}
        footer={
          isOP && (
            <div className={classes.settingsLayout}>
              <SettingsButton />
            </div>
          )
        }
      >
        <Headline3 className={classes.actionsHeadline}>
          <LocalizedMessage id={'actions'} />
        </Headline3>
        <WidgetProvider features={features} />
      </MultiDeviceLayout>
    </>
  );
};

export const SubscriptionHubPage = withLocalization('subscriptionHub/subscriptionHub')(SubscriptionHubInner);
