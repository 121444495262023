import { getMixpanel } from '@client/tracking/mixpanel';
import { TrackingButtonName, EventNames, TrackingFeature } from '@client/tracking/mixpanel-constants';

export function sendViewedGuideMixpanelEvent(categoryName: string, merchantName: string) {
  getMixpanel().track(EventNames.VIEWED_DIALOG, {
    Feature: TrackingFeature.Cancellation,
    Dialog: 'Cancellation reasons dialog',
    Category: categoryName,
    SupplierName: merchantName,
  });
}

export function sendCloseCancellationReasonsDialogEvent(categoryName: string, merchantName: string) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Dialog: 'Cancellation reasons dialog',
    Button: TrackingButtonName.CLOSE,
    Category: categoryName,
    SupplierName: merchantName,
  });
}

export function sendRejectedCancellationReasonsDialogEvent(categoryName: string, merchantName: string) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Dialog: 'Cancellation reasons dialog',
    Button: 'I don’t want to answer',
    Category: categoryName,
    SupplierName: merchantName,
  });
}

export function sendSubmittedCancellationReasonEvent(
  cancellationReason: string,
  anotherReasonText: string,
  categoryName: string,
  merchantName: string
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Dialog: 'Cancellation reasons dialog',
    'Reason for cancellation': cancellationReason,
    'Text field': anotherReasonText,
    Button: TrackingButtonName.SEND_FEEDBACK,
    Category: categoryName,
    'Supplier name': merchantName,
  });
}
