import type { OptionsWithoutRender } from 'react-loadable';
import Loadable from 'react-loadable';
import { withProps } from 'recompose';
import { LoadingBox } from './LoadingBox';

export interface LoadableComponentOptions extends Omit<OptionsWithoutRender<Record<string, unknown>>, 'loading'> {
  /**
   * Height of the box when loading or error, a CSS value. Such as "128px" or "auto".
   */
  height: string;
}

/**
 * Lazy load a module. In the meantime a box is displayed with a loading spinner.
 *
 * A height for the box must be provided.
 *
 * Wrapper around react-loadable.
 *
 * @example
 * LazyLoadingBox({
 *   loader: async () => import(/- webpackChunkName: "subscription-price-history" -/'./SubscriptionPriceHistory').then(module => module.SubscriptionPriceHistory),
 *   height: '126px'
 * })
 */
export function LazyLoadingBox(options: LoadableComponentOptions) {
  return Loadable({
    loading: withProps({ height: options.height })(LoadingBox),
    delay: 250,
    timeout: 10000,
    ...options,
  });
}
