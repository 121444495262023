import type { EndUserImages } from '@client/styles/themes/defaultTheme';
import { defaultThemeOptions } from '@client/styles/themes/defaultTheme';
import type { Theme } from '@material-ui/core';
import { createTheme } from '@minna-technologies/minna-ui/styles/themes/minna';

export const zlantarTheme: Theme<EndUserImages> = createTheme<EndUserImages>({
  colors: {
    action: {
      primary: '#429BA1',
      primaryHover: '#429BA1',
      primaryPressed: '#357C81',
      primaryDisabled: '#DDDDDD',
      secondary: '#E7E7E7',
      secondaryPressed: '#E7E7E7',
      secondaryHover: '#E7E7E7',
      secondaryDisabled: '#DDDDDD',
      info: '#7fa3ff',
      infoPressed: '#7fa3ff',
      infoHover: '#7fa3ff',
      positive: '#32A176',
      positivePressed: '#28815E',
      positiveHover: '#51AF8A',
      danger: '#D9365B',
      dangerPressed: '#B1233D',
      dangerHover: '#E24B67',
    },
    textOn: {
      surface: '#222222',
      surfaceSubdued: '#4a4b4b',
      primary: '#ffffff',
      secondary: '#222222',
      disabled: '#818181',
      info: '#192133',
      positive: '#1F3D1B',
      danger: '#430D17',
      badge: '#ffffff',
    },
    element: {
      rating: '#FFD467',
      badgeSurface: '#1D82A1',
    },
    base: {
      surface: '#ffffff',
      border: '#B7B7B7',
      background: '#F9F9F9',
      info: '#D8E3FF',
      positive: '#D1EFCD',
      danger: '#F5BFC9',
    },
  },
  cardShadow: '0px 16px 8px rgba(0, 0, 0, 0.08)',
  modalShadow: '0px 16px 8px rgba(0, 0, 0, 0.08)',
  selectButtonShadowSelected: '00px 3px 3px rgba(34, 34, 34, 0.1), 0px 6px 12px rgba(34, 34, 34, 0.15)',
  selectButtonShadowDefault: '0px 2px 2px rgba(34, 34, 34, 0.2)',
  nextStepShadow:
    '0px 0px 3px rgba(34, 34, 34, 0.2), 0px 2px 5px rgba(34, 34, 34, 0.1), 0px 5px 10px rgba(34, 34, 34, 0.2)',
  floatingShadow: '0px 16px 8px rgba(0, 0, 0, 0.08)',
  borderRadius: '16px',
  transitionDuration: '0.2s',
  borderRadiusPrimaryButton: '8px',
  borderRadiusSecondaryButton: '8px',
  themeButtons: {
    primaryButton: {
      backgroundColor: '#429BA1',
      color: '#FFFFFF',
    },
    positiveButton: {
      backgroundColor: '#32A176',
      color: '#FFFFFF',
    },
    secondaryButton: {
      backgroundColor: '#E7E7E7',
      color: '#222222',
      borderWidth: '0px',
    },
    iconFloatingButton: {
      backgroundColor: '#E7E7E7',
      color: '#222222',
    },
    positiveFloatingButton: {
      backgroundColor: '#32A176',
      color: '#FFFFFF',
    },
  },
  themeFonts: {
    headline1: {
      fontFamily: 'ZlantarSans',
    },
    headline2: {
      fontFamily: 'ZlantarSans',
    },
    headline3: {
      fontFamily: 'ZlantarSans',
    },
    title: {
      fontFamily: 'ZlantarSans',
    },
    buttonText: {
      fontFamily: 'ZlantarSans',
    },
  },
  themeImages: defaultThemeOptions.themeImages, // No custom images
});
