import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { ServiceToDisplay } from '@client/models/service';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { hasOneOrMoreContracts } from '@client/modules/overview/logic';
import { SelectableServicesList } from '@client/modules/subscription/components/SelectableServicesList';
import { ContractDetailsFloatingButtons } from '@client/modules/subscription/ContractDetailsPage/components/ContractDetailsFloatingButtons';
import type { Subscription } from '@client/modules/subscription/types';
import { overviewPage, subscriptionSettingsPage } from '@client/routes';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { PositiveButton } from '@minna-technologies/minna-ui/components/Buttons/PositiveButton';
import { ContractHeaderDeprecated } from '@minna-technologies/minna-ui/components/ContractHeaderDeprecated';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '24px 0',
  },

  content: {
    padding: '8px',
  },

  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export interface SelectServicesPageInnerProps {
  subscription: Subscription;
  servicesToDisplay: ServiceToDisplay[];
  isLoading: boolean;
  onContinue(services: ServiceToDisplay[]): void;
}

export const SelectServicesPageInner: React.FunctionComponent<SelectServicesPageInnerProps> = (
  { subscription, servicesToDisplay, isLoading, onContinue }: SelectServicesPageInnerProps,
  { localizeMessage }
) => {
  const classes = useStyles();

  const history = useHistory();
  const features = useSelector(selectorFeatures);
  const showBackButton = features.identify?.overview || hasOneOrMoreContracts(subscription);
  const [services, setServices] = useState<ServiceToDisplay[]>(servicesToDisplay);
  const hasSelectedServices: boolean = services.some((service) => service.selected);

  return (
    <MultiDeviceLayout
      hasTopNavigation={false}
      fullWidthContent={
        <ContractHeaderDeprecated
          providerLogo={subscription.supplier.logoUrl ?? ''}
          title={localizeMessage('selectServices')}
          subHeading={subscription.supplier.name}
          providerName={subscription.supplier.name}
        />
      }
    >
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.content}>
          <SelectableServicesList subscription={subscription} services={services} setServices={setServices} />
          <div className={classes.button}>
            <PositiveButton
              label={<LocalizedMessage id="continueButton" />}
              onClick={() => onContinue(services)}
              data-test="save-selected-service-button"
              disabled={!hasSelectedServices}
            />
          </div>
        </div>
      )}
      {showBackButton && (
        <ContractDetailsFloatingButtons
          backButton
          customGoBack={() => {
            history.push(subscription.contracts.length ? subscriptionSettingsPage(subscription.id) : overviewPage);
          }}
        />
      )}
    </MultiDeviceLayout>
  );
};

SelectServicesPageInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const SelectServicesPageComponent = withLocalization('contract/SelectServicePageLolipop')(
  SelectServicesPageInner
);
