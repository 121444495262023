import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { AppFeatureToggles } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { minHeightContainer } from '@client/modules/onboarding-overview-drawer/utils';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_ONBOARDING_ACTION_DRAWER } from '@client/tracking/mixpanel-constants';
import { useAppTheme } from '@client/utils/hooks/use-app-theme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.scss';

export const CancelInfoSlideComponent = () => {
  const { themeImages } = useAppTheme();
  const features: AppFeatureToggles = useSelector(selectorFeatures);

  useEffect(() => {
    getMixpanel().track(EventNames.VIEWED_GUIDE, {
      Feature: FEATURE_ONBOARDING_ACTION_DRAWER,
      Page: 'Cancel',
    });
  }, []);

  return (
    <div className={minHeightContainer(features.theme)}>
      <div className={[styles.centeredContainer, styles.imageSpacing].join(' ')}>
        <img alt={'Cancel icon'} src={themeImages.onboardingDrawer.cancel} />
      </div>
      <div className={[styles.centeredContainer, styles.titleSpacing].join(' ')}>
        <Headline2>
          <LocalizedMessage id={'cancelTitle'} />
        </Headline2>
      </div>
      <div className={[styles.centeredContainer, styles.monthlyCostTitleSpacing].join(' ')}>
        <Body>
          <LocalizedMessage id={'cancelFirstRow'} />
        </Body>
      </div>
      <div className={[styles.centeredContainer, styles.monthlyCostTitleSpacing].join(' ')}>
        <Body>
          <LocalizedMessage id={'cancelSecondRow'} />
        </Body>
      </div>
    </div>
  );
};

export const CancelInfoSlide = withLocalization('overview/OnboardingDrawer')(CancelInfoSlideComponent);
