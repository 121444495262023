import PropTypes from 'prop-types';
import React from 'react';
import isFunction from 'lodash/isFunction';
import identity from 'lodash/identity';
import noop from 'lodash/noop';
import {branch, renderComponent, wrapDisplayName, withState, compose} from 'recompose';
import {ErrorLoading} from '../../modules/fetching/ErrorPage/ErrorLoading';
import {Redirect, __RouterContext as RouterContext, withRouter} from 'react-router-dom';
import {ResponseErrorDialog} from './components/ErrorDialog';
import {Snackbar} from 'material-ui';
import {conditionalRender} from '../../modules/fetching/conditionalRender';
import {ReactReduxContext} from 'react-redux';

export const errorPageOnFailure = hasFailed => {
  return conditionalRender(hasFailed, ErrorLoading);
};

export const redirect = (condition, location, redirectProps) => {
  return branch(
    props => condition(props),
    renderComponent((props) => {
      function to() {
        if (isFunction(location)) {
          return location(props);
        } else {
          return location;
        }
      }

      return (<Redirect to={ to() } { ...redirectProps } />);
    }),
    identity
  );
};

/**
 * @deprecated `useEffect` and `useDispatch` can be used instead
 */
export const dispatchBeforeConnect = onDispatchBeforeFunc => WrappedComponent => {
  class OnDispatchBeforeConnect extends React.Component {
    static displayName = wrapDisplayName(WrappedComponent, 'OnDispatchBeforeConnect');

    static propTypes = {
      __store: PropTypes.object
    };

    UNSAFE_componentWillMount() {
      // eslint-disable-next-line no-unused-vars
      const {__store, ...rest} = this.props;
      onDispatchBeforeFunc(__store.getState(), __store.dispatch, rest);
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const {__store, ...rest} = this.props;
      return <WrappedComponent { ...rest } />;
    }
  }

  return function withReduxStore(props) {
    return (
      <ReactReduxContext.Consumer>
        {({store}) => <OnDispatchBeforeConnect { ...props } __store={ store } />}
      </ReactReduxContext.Consumer>
    );
  };
};

/**
 * @deprecated `useEffect` can be used instead
 *
 * HOC that calls onPropsChangedFunc if shouldExecute returns true.
 * onPropsChangedFunc has access to the props of the component as well as the store and router
 */
export const onPropsChanged = (shouldExecute, onPropsChangedFunc) => (WrappedComponent) => {
  class OnPropsChanged extends React.Component {
    static displayName = wrapDisplayName(WrappedComponent, 'OnPropsChanged');

    static propTypes = {
      __store: PropTypes.object,
      __router: PropTypes.object
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      // eslint-disable-next-line no-unused-vars
      const {__store, __router, ...rest} = this.props;
      // eslint-disable-next-line no-unused-vars
      const {__store: nextStore, __router: nextRouter, ...nextRest} = nextProps;
      const defaultShouldExecute = () => rest !== nextRest;
      if (isFunction(shouldExecute) && noop !== shouldExecute ? shouldExecute(rest, nextRest) : defaultShouldExecute()) {
        onPropsChangedFunc(nextRest, {store: __store, router: __router});
      }
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const {__store, __router, ...rest} = this.props;
      return <WrappedComponent { ...rest } />;
    }
  }

  return function withReduxStore(props) {
    return (
      <RouterContext.Consumer>
        {router => (
          <ReactReduxContext.Consumer>{({store}) =>
            <OnPropsChanged { ...props } __store={ store } __router={ router } />
          }
          </ReactReduxContext.Consumer>
        )}
      </RouterContext.Consumer>
    );
  };
};

export const wrap = (Component) => (WrappedComponent) => {
  class ComponentWithChild extends React.Component {
    static displayName = wrapDisplayName(WrappedComponent, 'wrap');

    render() {
      return (
        <Component { ...this.props }>
          <WrappedComponent { ...this.props } />
        </Component>
      );
    }
  }

  return ComponentWithChild;
};

export const withErrorDialogOnError = (errorResponseFromProps, titleOrTitleFunction) => (Component) => {
  class ComponentWithErrorDialogOnError extends React.Component {
    static displayName = wrapDisplayName(Component, 'withErrorDialogOnError');

    render() {
      const title = isFunction(titleOrTitleFunction) ? titleOrTitleFunction(this.props) : titleOrTitleFunction;
      const errorResponse = errorResponseFromProps(this.props);
      return (
        <div>
          { errorResponse ?
            (<ResponseErrorDialog response={ errorResponse } title={ title } />) :
            null
          }
          <Component { ...this.props } />
        </div>
      );
    }
  }

  return ComponentWithErrorDialogOnError;
};

const withOpenState = compose(
  withState('open', 'setOpenState', true)
);

const SnackbarWithState = withOpenState(({open, setOpenState, ...props}) => {
  return (<Snackbar open={ open } onRequestClose={ () => setOpenState(false) } { ...props } />);
});

export const withSnackbarOnSuccess = (successFromProps, messageOrMessageFunction, autoHideDuration = 3000) => (Component) => {
  class ComponentWithSnackbarOnSuccess extends React.Component {
    static displayName = wrapDisplayName(Component, 'withSnackbarOnSuccess');

    render() {
      const message = isFunction(messageOrMessageFunction) ? messageOrMessageFunction(this.props) : messageOrMessageFunction;
      const success = successFromProps(this.props);
      return (
        <div>
          { success ?
            (<SnackbarWithState message={ message || '' } autoHideDuration={ autoHideDuration } />) :
            null
          }
          <Component { ...this.props } />
        </div>
      );
    }
  }

  return ComponentWithSnackbarOnSuccess;
};

class ScrollToTopWithoutRoute extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}
export const ScrollToTop = withRouter(ScrollToTopWithoutRoute);
