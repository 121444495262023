const iPadUserAgentPattern = /(iPad).*OS\s([\d_]+)/;

export function getOs(userAgent: any) {
  const returnObj: any = {};

  const windows = userAgent.match(/(Windows\sNT)\s([\d.]+)/i);
  const mac = userAgent.match(/(os\sx)\s([\d_]+)/i);
  const linux = userAgent.match(/(linux)\s([A-z|0-9]+)/i);

  const android = userAgent.match(/(Android)\s+([\d.]+)/);
  const iphone = userAgent.match(/(iPhone\sOS)\s([\d_]+)/);
  const ipad = userAgent.match(iPadUserAgentPattern);
  const webos = userAgent.match(/(webOS)\/([\d.]+)/);
  const symbian = userAgent.match(/(Symbian|SymbianOS)\/([\d.]+)/);
  const windowsPhone = userAgent.match(/Windows\sPhone(?:\sOS)?\s([\d.]+)/);

  if (windows && !windowsPhone) {
    returnObj.windows = true;
    returnObj.version = windows[2];
  } else if (mac) {
    returnObj.osx = true;
    returnObj.version = mac[2].replace(/_/g, '.');
  } else if (linux && !android) {
    returnObj.linux = true;
    returnObj.version = linux[2];
  } else if (windowsPhone) {
    returnObj.windowsPhone = true;
    returnObj.version = windowsPhone[1];
  } else if (android) {
    returnObj.android = true;
    returnObj.version = android[2];
  } else if (iphone && !ipad) {
    returnObj.iphone = true;
    returnObj.ios = true;
    returnObj.version = iphone[2].replace(/_/g, '.');
  } else if (ipad) {
    returnObj.ipad = true;
    returnObj.ios = true;
    returnObj.version = ipad[2].replace(/_/g, '.');
  } else if (webos) {
    returnObj.webos = true;
    returnObj.version = webos[2];
  } else if (symbian) {
    returnObj.symbian = true;
    returnObj.version = symbian[2];
  }

  return returnObj;
}

export function isNative() {
  return true;
}

export function isAndroidApp() {
  return getOs(navigator.userAgent).android;
}

export function isIosApp() {
  return getOs(navigator.userAgent).ios;
}

export function isIpadApp() {
  return isIosApp() && !!navigator.userAgent.match(iPadUserAgentPattern);
}
