import { useLocalization } from '@client/internationalization';
import type { PastAction } from '@client/models/cancellation';
import type { AppContext } from '@client/modules/app-context';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';
import { PastActionCardHeader } from './PastActionCardHeader';
import { PastActionCardText } from './PastActionCardText';

interface PastActionCardProps {
  cancellation: PastAction;
  onPastActionClicked(merchantId: string, merchantName: string, category: string, cancellationId: string): void;
  appContext: AppContext;
}

export const PastActionCard = (props: PastActionCardProps) => {
  const { localizeMessage } = useLocalization('subscriptionHub/subscriptionHub');
  const { colors } = useTheme();

  const useStyles = makeStyles({
    pastActionsCard: {
      marginTop: '10px',
    },
    flexBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    readMoreText: {
      color: colors.action.primary,
      fontWeight: 'bold',
      float: 'right',
    },
  });

  const classes = useStyles();

  return (
    <Card
      className={classes.pastActionsCard}
      data-test="past-actions-card"
      onClick={() =>
        props.onPastActionClicked(
          props.cancellation.merchantId,
          props.cancellation.merchantName,
          props.cancellation.category.name.name,
          props.cancellation.id
        )
      }
    >
      <PastActionCardHeader pastAction={props.cancellation} />
      <Divider />
      <div className={classes.flexBoxContainer}>
        <PastActionCardText pastAction={props.cancellation} />
      </div>
      <Body>
        <span className={classes.readMoreText}>{localizeMessage('readMoreLabel')}</span>
      </Body>
    </Card>
  );
};
