import type { SubscriptionActionType } from '@client/models/subscription';
import type { SupplierId, SupplierWithServices } from '@client/models/supplier';
import { MerchantSearchBar } from '@client/modules/subscription-search/SubscriptionSearchPage/MerchantSearch/component';
import { trackViewedSubscriptionSearch } from '@client/modules/subscription-search/SubscriptionSearchPage/mixpanel';
import { subscriptionActionTypeToTrackingFeature as toTrackingFeature } from '@client/tracking/mixpanel-constants';
import React, { useEffect } from 'react';

export const MerchantSearchContainer = ({
  merchants,
  onMerchantSelected,
  action,
  defaultValue,
}: {
  merchants: SupplierWithServices[];
  onMerchantSelected(merchantId: SupplierId, searchTerm: string): void;
  action: SubscriptionActionType;
  defaultValue?: string;
}) => {
  useEffect(() => {
    trackViewedSubscriptionSearch(toTrackingFeature(action));
  }, [action]);

  return (
    <MerchantSearchBar
      merchants={merchants}
      onSelect={onMerchantSelected}
      action={action}
      defaultValue={defaultValue}
    />
  );
};
