import {
  androidAppVersionIsEqualOrAbove,
  iosAppVersionIsEqualOrAbove,
  isAndroidApp,
  isIosApp,
  isNative,
  isSimulatingNative,
} from './device-helper';
import { sendAndroidEvent, sendAndroidEventWithPayload, sendIosEvent } from './send-event';

export const IOS_APP_VERSION = '4.6.0';
export const ANDROID_APP_VERSION = '4.4.0';

const EVENT_ID = 'closeWebPage';
const FALLBACK_EVENT_ID = 'closeWebView';

/**
 * Closes the current web view.
 */
export function closeWebPage() {
  if (iosAppVersionIsEqualOrAbove(IOS_APP_VERSION)) {
    const iosEventPayload = {
      action: EVENT_ID,
      data: { refresh: true, numberOfViewsToClose: 2 },
    };

    sendIosEvent(iosEventPayload);
  } else if (androidAppVersionIsEqualOrAbove(ANDROID_APP_VERSION)) {
    // Agreed on the number of views to close with Sb1.
    sendAndroidEventWithPayload(EVENT_ID, { refresh: true, numberOfViewsToClose: 2 });
  } else if (isIosApp() || isAndroidApp()) {
    closeWebPageFallback();
  } else if (isSimulatingNative() || !isNative()) {
    if (window.opener) {
      // If we opened this window, simply close it
      window.close();
    } else {
      // Otherwise, go back to the beginning of history.
      const numberOfPages = window.history.length - 1;
      window.history.go(-numberOfPages);
    }
  } else {
    throw new Error('"closeWebPage" can only be called from the native Mobilbank app');
  }
}

/**
 * Fallback for closing the web page.
 */
export function closeWebPageFallback() {
  if (isIosApp()) {
    sendIosEvent({
      action: FALLBACK_EVENT_ID,
    });
  } else if (isAndroidApp()) {
    sendAndroidEvent(FALLBACK_EVENT_ID);
  } else {
    throw new Error('"closeWebPage" can only be called from the native Mobilbank app');
  }
}
