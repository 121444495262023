import PropTypes from 'prop-types';
import React from 'react';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';
import {ValidationFaCC} from '../ValidationHOC';
import {validationContextPropType} from '../Form';
import getDisplayName from 'recompose/getDisplayName';

function callIfFunction(maybeFunction, ...args) {
  if (isFunction(maybeFunction)) {
    return maybeFunction(...args);
  } else {
    return maybeFunction;
  }
}

export const WrappedProps = {
  name: PropTypes.string,
  value: PropTypes.any,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  canShowError: PropTypes.bool,
  formSubmitted: PropTypes.bool,
  errors: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  focused: PropTypes.bool,
  valid: PropTypes.bool
};

export default function (WrappedComponent) {
  class InputValidationHOC extends React.Component {
    static displayName = `InputValidationHOC(${getDisplayName(WrappedComponent)})`;

    static propTypes = {
      ...omit(ValidationFaCC.propTypes, ['children']),
      showErrorOnBlur: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func
      ]),
      showErrorOnChange: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func
      ]),
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
      value: PropTypes.any,
      defaultValue: PropTypes.any,
      clear: PropTypes.bool
    };

    static defaultProps = {
      ...ValidationFaCC.defaultProps,
      showErrorOnBlur: false,
      showErrorOnChange: false,
      clear: false
    };

    static contextTypes = {
      validation: validationContextPropType.isRequired
    };

    state = {
      value: undefined,
      canShowError: false,
      formSubmitted: false,
      focused: false
    };

    UNSAFE_componentWillMount() {
      this.context.validation.addFormSubmittedListener(this.setFormSubmitted);
      this.setState({...this.state, value: this.props.defaultValue});
    }

    componentWillUnmount() {
      this.context.validation.removeFormSubmittedListener(this.setFormSubmitted);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state.value !== undefined && nextProps.clear) {
        this.setState({...this.state, value: undefined, canShowError: false});
      }
      if (this.state.value === undefined && nextProps.defaultValue !== undefined) {
        this.setState({...this.state, value: nextProps.defaultValue});
      }
    }

    setFormSubmitted = (formSubmitted) => {
      this.setState({
        ...this.state,
        canShowError: true,
        formSubmitted
      });
    };

    handleChange = (event) => {
      const {onChange, showErrorOnChange} = this.props;

      const value = event.target.value;
      const canShowError = this.state.canShowError || callIfFunction(showErrorOnChange, value);

      this.setState({...this.state, value, canShowError});

      if (onChange) {
        onChange(event);
      }
    };

    handleFocus = () => {
      this.setState({...this.state, focused: true});
    };

    handleBlur = (event) => {
      const {onBlur, showErrorOnBlur} = this.props;

      if (callIfFunction(showErrorOnBlur, this.state.value)) {
        this.setState({...this.state, canShowError: true, focused: false});
      } else {
        this.setState({...this.state, focused: false});
      }

      if (onBlur) {
        onBlur(event);
      }
    };

    render() {
      const {name, validators, ...props} = this.props;
      const {canShowError, formSubmitted, focused} = this.state;

      const value = this.props.value != undefined ? this.props.value : this.state.value;

      return (
        <ValidationFaCC name={ name } value={ value } validators={ validators }>{ ({valid, errors}) => {
          const canBeShownErrors = (canShowError || formSubmitted) && !valid ? errors : null;

          const wrappedProps = {
            ...props,
            name,
            value,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onChange: this.handleChange,
            canShowError,
            formSubmitted,
            errors: canBeShownErrors,
            focused,
            valid
          };

          return <WrappedComponent { ...wrappedProps } />;
        }}</ValidationFaCC>
      );
    }
  }

  return InputValidationHOC;
}
