import React from 'react';
import type { InferableComponentEnhancer } from 'react-redux';
import { withHandlers, withState } from 'recompose';
import { compose } from 'redux';

// @ts-ignore
export const withHovering: InferableComponentEnhancer<{ hovering: boolean }> = (WrappedComponent) => {
  class WrappedWithHover extends React.Component<any, { hovering: boolean }> {
    public constructor(props: any) {
      super(props);
      this.state = { hovering: false };

      this.handleMouseEnter = this.handleMouseEnter.bind(this);
      this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    private handleMouseEnter() {
      this.setState({ hovering: true });
    }

    private handleMouseLeave() {
      this.setState({ hovering: false });
    }

    public render() {
      return (
        <div style={{ cursor: 'pointer' }} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
          {/*
           // @ts-ignore */}
          <WrappedComponent {...this.props} hovering={this.state.hovering} />
        </div>
      );
    }
  }

  return WrappedWithHover;
};

interface InnerWithDialogProps {
  dialogOpen: boolean;
  showDialog(update: boolean | ((current: boolean) => boolean)): void;
}

export interface WithDialogProps extends InnerWithDialogProps {
  onToggleDialog(): void;
}

export const withDialogStateHandler: InferableComponentEnhancer<WithDialogProps> = compose(
  withState('dialogOpen', 'showDialog', false),
  withHandlers({
    onToggleDialog: (props: InnerWithDialogProps) => () => props.showDialog((dialogOpen) => !dialogOpen),
  })
);
