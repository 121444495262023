import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PageNotFoundContainer from '../modules/fetching/ErrorPage';
import { ConsentPageContainer } from '../modules/onboarding/OnboardingPsd2/consent/ConsentPage';
import { RenewConsentPageContainer } from '../modules/onboarding/OnboardingPsd2/consent/RenewConsentPage';
import { WelcomePageContainer } from '../modules/onboarding/OnboardingPsd2/WelcomePage';
import * as urls from '../routes';

export const OnboardingPSD2Route = () => {
  return (
    <Switch>
      <Route path={urls.onboardingPsd2Consent} render={() => <ConsentPageContainer />} />
      <Route exact path={urls.onboardingPsd2} render={() => <WelcomePageContainer />} />
      <Route exact path={urls.onboardingPsd2ConsentExpired} render={() => <RenewConsentPageContainer />} />
      <Route component={PageNotFoundContainer} />
    </Switch>
  );
};
