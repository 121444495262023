import { promisify } from '@client/utils/promise';
import React from 'react';
//@ts-ignore
import zChat from '../../../../../../../vendor/web_sdk';
import { logErrors } from '../ducks/zendesk-chat';
import { FirstMessage } from './components/FirstMessage';
import type { MessageFormProps } from './components/Messaging';
import { Messaging } from './components/Messaging';
import { eventsArrayToNumChatEvents } from './zendesk-component-utils';

interface FirstMessageForm {
  name: string;
  email: string;
  message: string;
}

export interface ZendeskChatProps {
  isAgentAvailable: boolean;
  partitionedEvents: any[];
  messageForm: MessageFormProps;
  firstMessageForm: FirstMessageForm;
  onNavigateBack: () => void;
  onMinimizeChat: () => void;
  onStartChat: () => void;
  onFirstMessageFormChange: (key: string, value: string) => void;
  onMessageFormChange: () => void;
  agentIsTyping: boolean;
  isChatOnly: boolean;
  additionalUserInfo?: string;
  addMessageLocally: (value: string) => void;
}

export const ZendeskChat = ({
  additionalUserInfo,
  onStartChat,
  isAgentAvailable,
  partitionedEvents,
  messageForm,
  firstMessageForm,
  agentIsTyping,
  onMinimizeChat,
  onMessageFormChange,
  onFirstMessageFormChange,
  addMessageLocally,
}: ZendeskChatProps) => {
  const onStartChatInner = async (name: any, email: any, message: any) => {
    await setVisitorInfo({ name, email });
    await sendChatMsg(message);
    onStartChat();
  };

  const setVisitorInfo = async (options: any) => {
    const displayName = additionalUserInfo ? `${options.name} (${additionalUserInfo})` : options.name;
    const visitorInfo = {
      display_name: displayName,
      email: options.email,
    };
    const setVisitorInfo = zChat.setVisitorInfo.bind(zChat, visitorInfo);
    // TODO: show error message in GUI once we have a standardized component for showing errors to users

    return promisify(setVisitorInfo).catch((err: any) => logErrors(err, 'setVisitorInfo', visitorInfo));
  };

  const sendChatMsg = async (message: string) => {
    const sendChatMsg = zChat.sendChatMsg.bind(zChat, message);
    addMessageLocally(message);
    // TODO: show error message in GUI once we have a standardized component for showing errors to users
    await promisify(sendChatMsg).catch((err: any) => logErrors(err, 'sendChatMsg', message));
  };

  return eventsArrayToNumChatEvents(partitionedEvents) > 0 ? (
    <Messaging
      partitionedEvents={partitionedEvents}
      agentIsTyping={agentIsTyping}
      isAgentAvailable={isAgentAvailable}
      messageForm={messageForm}
      onMessageFormChange={onMessageFormChange}
      onMinimizeChat={onMinimizeChat}
      sendChatMsg={async (message: string) => sendChatMsg(message)}
    />
  ) : (
    <FirstMessage
      name={firstMessageForm.name}
      email={firstMessageForm.email}
      message={firstMessageForm.message}
      isAgentAvailable={isAgentAvailable}
      onStartChat={async (name: any, email: any, message: any) => onStartChatInner(name, email, message)}
      onChange={async (key: string, value: string) => onFirstMessageFormChange(key, value)}
    />
  );
};
