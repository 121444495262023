import { LocalizedMessage, LocalizedMessageWithElementsInjected } from '@client/internationalization';
import { BasicPaper } from '@client/modules/subscription/components/BasicPaper';
import * as urls from '@client/routes';
import { MultiDeviceLayout } from '@client/shared-components/layout/MultiDeviceLayout';
import Checkbox from '@material-ui/core/Checkbox';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.scss';
import { WelcomePageHeader } from './WelcomePageHeader';

export const WelcomePage = ({
  onNavigateToNextPage,
  onAgreeToPrivacyPolicyAndTermsOfUseChanged,
  agreedToTerms,
}: any) => {
  const maybeUrlParameter = new URLSearchParams(window.location.search);
  const termsAndConditionsUrl = `${urls.userTermsPage}?${maybeUrlParameter}`;
  const privacyPolicyUrl = `${urls.privacyPolicyPage}?${maybeUrlParameter}`;
  const { colors } = useTheme();

  const handleCheck = onAgreeToPrivacyPolicyAndTermsOfUseChanged;

  return (
    <MultiDeviceLayout>
      <div className={styles.container}>
        <WelcomePageHeader />
        <div className={styles['container-inner']}>
          <div>
            <Headline1 data-test={'headline'}>
              <LocalizedMessage id="headlineV2" />
            </Headline1>
            <Body style={{ color: colors.textOn.surfaceSubdued, margin: '16px 0' }}>
              <LocalizedMessage id="descriptionOne" />
            </Body>
            <Body style={{ color: colors.textOn.surfaceSubdued, margin: '16px 0' }}>
              <LocalizedMessage id="descriptionTwo" />
            </Body>
            <BasicPaper flat className={styles['flat-paper']}>
              <Checkbox
                checked={agreedToTerms}
                onChange={(_, value) => handleCheck(value)}
                data-test={'user-terms-agreement-checkbox'}
              />
              <Caption>
                <LocalizedMessageWithElementsInjected
                  id="policyConsentMessageV2"
                  elements={{
                    termsAndConditionsUrl: (
                      <Link to={termsAndConditionsUrl}>
                        <LocalizedMessage id="termsAndConditions" />
                      </Link>
                    ),
                    privacyPolicyUrl: (
                      <Link to={privacyPolicyUrl}>
                        <LocalizedMessage id="privacyPolicy" />
                      </Link>
                    ),
                  }}
                />
              </Caption>
            </BasicPaper>
          </div>
          <div>
            <PrimaryButton
              label={<LocalizedMessage id="buttonLabelV2" />}
              onClick={onNavigateToNextPage}
              disabled={!agreedToTerms}
              data-test={'continue-button'}
            />
          </div>
        </div>
      </div>
    </MultiDeviceLayout>
  );
};
