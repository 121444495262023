import useTheme from '@material-ui/core/styles/useTheme';
import { NavDiscoverIcon } from '@minna-technologies/minna-ui/icons/NavDiscover';
import { NavImproveIcon } from '@minna-technologies/minna-ui/icons/NavImprove';
import { NavMoreIcon } from '@minna-technologies/minna-ui/icons/NavMore';
import { NavOverviewIcon } from '@minna-technologies/minna-ui/icons/NavOverview';
import React from 'react';

export enum OverviewNavigationIconType {
  OVERVIEW = 'Overview',
  INSIGHTS = 'Insights',
  DISCOVER = 'Discover',
  MORE = 'More',
}

interface OverviewNavigationIconProps {
  iconType: OverviewNavigationIconType;
  isSelected: boolean;
}

export const OverviewNavigationIcon = ({ iconType, isSelected }: OverviewNavigationIconProps) => {
  const { colors } = useTheme();

  function getIcon() {
    switch (iconType) {
      case OverviewNavigationIconType.OVERVIEW: {
        return (
          <NavOverviewIcon
            nativeColor={isSelected ? colors.action.primary : colors.textOn.surfaceSubdued}
            bold={isSelected}
          />
        );
      }
      case OverviewNavigationIconType.INSIGHTS: {
        return (
          <NavImproveIcon
            nativeColor={isSelected ? colors.action.primary : colors.textOn.surfaceSubdued}
            bold={isSelected}
          />
        );
      }
      case OverviewNavigationIconType.DISCOVER: {
        return (
          <NavDiscoverIcon
            nativeColor={isSelected ? colors.action.primary : colors.textOn.surfaceSubdued}
            bold={isSelected}
          />
        );
      }
      case OverviewNavigationIconType.MORE: {
        return (
          <NavMoreIcon
            nativeColor={isSelected ? colors.action.primary : colors.textOn.surfaceSubdued}
            bold={isSelected}
          />
        );
      }
      default:
        return null;
    }
  }

  return getIcon();
};
