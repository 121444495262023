import { selectorAppContext } from '@client/modules/app-context/duck';
import { sendClickedButtonMixpanelEvent } from '@client/modules/cancellation/mixpanel-events';
import { navigateToContactUs, open } from '@client/modules/Zendesk/ducks';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { AppContext } from '../../modules/app-context';
import type { ContactUsButtonInnerProps } from './component';
import { ContactUsButtonComponent } from './component';
import type { TrackingPageName } from '@client/tracking/mixpanel-constants';
import { TrackingButtonName } from '@client/tracking/mixpanel-constants';

interface ContactUsButtonProps {
  insightCardName: string;
  supplierName: string;
  pageName: string;
}

interface StateProps {
  appContext: AppContext;
}

interface DispatchProps {
  onNavigateToContactUsButton(
    pageName: string,
    insightCardName: string,
    supplierName: string,
    appContext: AppContext
  ): void;
}

const mapStateToProps = (state: any): StateProps => ({
  appContext: selectorAppContext(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onNavigateToContactUsButton: (
    pageName: TrackingPageName,
    insightCardName: string,
    supplierName: string,
    appContext: AppContext
  ) => {
    dispatch(navigateToContactUs());
    dispatch(open(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale));
    sendClickedButtonMixpanelEvent(pageName, supplierName, TrackingButtonName.CONTACT_US, insightCardName);
  },
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: ContactUsButtonProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onNavigateToContactUs: (pageName: string, insightCardName: string, supplierName: string) => {
    dispatchProps.onNavigateToContactUsButton(pageName, insightCardName, supplierName, stateProps.appContext);
  },
});

export const ContactUsButton = compose<ContactUsButtonInnerProps, ContactUsButtonProps>(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(ContactUsButtonComponent);
