import Sentry from '@client/assets/js/sentry';
/* eslint-disable no-restricted-imports */
import _ from 'lodash';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { handle } from 'redux-pack';
import {
  CLEAR_ARTICLE_STATE,
  CLEAR_USER_SEARCH_QUERY,
  FETCH_ARTICLE,
  FETCH_ARTICLES,
  FETCH_CATEGORIES,
  INCREASE_NUMBER_OF_ADDITIONAL_ARTICLES_TO_DISPLAY,
  RESET_ADDITIONAL_NUMBER_OF_ARTICLES_TO_DISPLAY,
  SET_LABELS_FOR_SUGGESTED_ARTICLES,
  STORE_ARTICLES_FILTER,
  UPDATE_ARTICLES_USER_SEARCH_QUERY,
} from './zendesk-help-center-actions';

interface IState {
  articles: any[];
  categories: any[];
  numberOfAdditionalArticlesToDisplay: number;
  filter?: any;
}

export const defaultState: IState = {
  articles: [],
  categories: [],
  numberOfAdditionalArticlesToDisplay: 0,
};

/* eslint-disable  */
export function reducer(state: IState = defaultState, action: any) {
  switch (action.type) {
    case SET_LABELS_FOR_SUGGESTED_ARTICLES: {
      return {
        ...state,
        labels: action.labels,
      };
    }
    case FETCH_ARTICLES: {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          loadingArticles: true,
          fetchingArticlesFailed: false,
        }),
        finish: (s) => ({ ...s, loadingArticles: false }),
        failure: (s) => {
          Sentry.captureMessage('Failed to fetch articles from Zendesk', {
            extra: { error: action.payload, filter: state.filter },
          });

          return { ...s, articles: [], fetchingArticlesFailed: true };
        },
        success: (s) => {
          const articles = _(action.payload.results)
            .map(({ id, title, html_url, section_id }) => ({
              id,
              title,
              html_url,
              sectionId: section_id,
            }))
            .value();

          return { ...s, articles };
        },
      });
    }
    case FETCH_ARTICLE: {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          loadingArticle: true,
          fetchingArticleFailed: false,
        }),
        finish: (s) => ({ ...s, loadingArticle: false }),
        failure: (s) => {
          Sentry.captureMessage('Failed to fetch article from Zendesk', {
            extra: { error: action.payload },
          });

          return { ...s, article: {}, fetchingArticleFailed: true };
        },
        success: (s) => {
          const article = pick(action.payload.article, ['id', 'title', 'body']);

          return { ...s, article };
        },
      });
    }
    case STORE_ARTICLES_FILTER: {
      return {
        ...state,
        filter: action.filter,
      };
    }
    case UPDATE_ARTICLES_USER_SEARCH_QUERY: {
      return {
        ...state,
        userSearchQuery: action.userSearchQuery,
      };
    }
    case CLEAR_USER_SEARCH_QUERY: {
      return {
        ...state,
        userSearchQuery: undefined,
      };
    }
    case CLEAR_ARTICLE_STATE: {
      return {
        ...state,
        article: undefined,
      };
    }
    case FETCH_CATEGORIES: {
      return handle(state, action, {
        start: (s) => ({
          ...s,
          loadingCategories: true,
          fetchingCategoriesFailed: false,
        }),
        finish: (s) => ({ ...s, loadingCategories: false }),
        failure: (s) => {
          Sentry.captureMessage('Failed to fetch categories from Zendesk', {
            extra: { error: action.payload },
          });

          return { ...s, categories: [], fetchingCategoriesFailed: true };
        },
        success: (s) => {
          return { ...s, categories: action.payload };
        },
      });
    }
    case INCREASE_NUMBER_OF_ADDITIONAL_ARTICLES_TO_DISPLAY: {
      const currentDisplayNumber = get(
        state,
        'numberOfAdditionalArticlesToDisplay',
        defaultState.numberOfAdditionalArticlesToDisplay
      );
      const amount: number = action.amount;

      return {
        ...state,
        numberOfAdditionalArticlesToDisplay: currentDisplayNumber + amount,
      };
    }
    case RESET_ADDITIONAL_NUMBER_OF_ARTICLES_TO_DISPLAY: {
      return {
        ...state,
        numberOfAdditionalArticlesToDisplay: defaultState.numberOfAdditionalArticlesToDisplay,
      };
    }
    default:
      return state;
  }
}
