import { selectorZendeskNavigationState } from '@client/modules/Zendesk/ducks';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { IconButton } from '@minna-technologies/minna-ui/components/Buttons/IconButton';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { ArrowBackIcon } from '@minna-technologies/minna-ui/icons/ArrowBack';
import { CloseIcon } from '@minna-technologies/minna-ui/icons/Close';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

interface ZendeskHeaderProps {
  title: React.ReactNode;
  onClose(): void;
  onNavigateBack(): void;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: theme.colors.action.primary,
    borderRadius: '4px 4px 0 0',
  },
  mainContent: {
    display: 'grid',
    gridTemplateAreas: '"backButton header closeButton"',
    gridTemplateColumns: '32px 1fr 32px',
    alignItems: 'center',
  },
  navigateBackButton: {
    gridArea: 'backButton',
  },
  header: {
    gridArea: 'header',
    textAlign: 'center',
  },
  closeButton: {
    gridArea: 'closeButton',
  },
}));

export const ZendeskHeader: React.FC<ZendeskHeaderProps> = ({ title, onClose, onNavigateBack, className }) => {
  const classes = useStyles();
  const zendeskNavigationState = useSelector(selectorZendeskNavigationState);
  const { colors } = useTheme();

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.mainContent}>
        {zendeskNavigationState.length > 1 && (
          <IconButton
            data-test="zendesk-navigate-back-button"
            onClick={onNavigateBack}
            className={classes.navigateBackButton}
          >
            <ArrowBackIcon nativeColor={colors.base.surface} />
          </IconButton>
        )}
        <Headline2 color={colors.base.surface} className={classes.header}>
          {title}
        </Headline2>
        <IconButton data-test="zendesk-close-button" onClick={onClose} className={classes.closeButton}>
          <CloseIcon nativeColor={colors.base.surface} />
        </IconButton>
      </div>
    </div>
  );
};
