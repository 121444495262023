import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { FeedbackCarouselCardProps } from '@client/modules/nps-csat-module/component';
import { cardStyles } from '@client/modules/nps-csat-module/component';
import {
  CsatFeature,
  FeedbackButtons,
  feedbackCardButtonClicked,
  FeedbackCards,
  feedbackCardViewed,
  trackCsatScore,
} from '@client/modules/nps-csat-module/mixpanel-events';
import { RatingBox } from '@client/modules/rating-box/component';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { CarouselCard } from '@minna-technologies/minna-ui/components/CarouselCard';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { CloseIcon } from '@minna-technologies/minna-ui/icons/Close';
import React, { useState } from 'react';

export const Component: React.FunctionComponent<FeedbackCarouselCardProps> = ({
  onNext,
  onClose,
  fullHeight,
  opacity,
}) => {
  const globalClasses = cardStyles();
  const { colors } = useTheme();
  const [overviewScore, setOverviewScore] = useState<number | null>(null);
  const [cancelScore, setCancelScore] = useState<number | null>(null);
  const [discoverScore, setDiscoverScore] = useState<number | null>(null);
  const continueToNps = () => {
    if (overviewScore) trackCsatScore(CsatFeature.OVERVIEW, overviewScore);
    if (cancelScore) trackCsatScore(CsatFeature.CANCEL, cancelScore);
    if (discoverScore) trackCsatScore(CsatFeature.DISCOVER, discoverScore);
    feedbackCardButtonClicked(FeedbackButtons.CONTINUE, 'CSAT rating');
    feedbackCardViewed(FeedbackCards.NPS);
    onNext();
  };
  const closeFromCsat = () => {
    feedbackCardButtonClicked(FeedbackButtons.CLOSEX, 'CSAT rating');
    onClose();
  };

  return (
    <CarouselCard fullHeight={fullHeight} opacity={opacity}>
      <div className={globalClasses.centeredContainer}>
        <Title color={colors.action.primary}>
          <LocalizedMessage id={'title'} />
        </Title>
      </div>
      <div className={globalClasses.closeButton}>
        <CloseIcon onClick={closeFromCsat} data-test={'feedback-close-button'} />
      </div>
      <div className={globalClasses.mainTextContent}>
        <Body>
          <LocalizedMessage id={'overallSatisfaction'} />
        </Body>
      </div>
      <RatingBox
        overview
        cancel
        discover
        onOverviewRatingSet={(rating) => {
          setOverviewScore(rating);
        }}
        onCancelRatingSet={(rating) => {
          setCancelScore(rating);
        }}
        onDiscoverRatingSet={(rating) => {
          setDiscoverScore(rating);
        }}
      />
      <div className={globalClasses.nextButton}>
        <PrimaryButton onClick={continueToNps} fullWidth label={<LocalizedMessage id={'nextButtonLabel'} />} />
      </div>
    </CarouselCard>
  );
};

export const FeedbackCsatRatingCard = withLocalization('feedback-modal')(Component);
