import type { CategoryName } from '@client/constants/categories';
import type { CancellationId } from '@client/models/cancellation';
import type { CancellationInfo } from '@client/modules/subscription/types';
import axios from 'axios';

export interface CancellationDetailsResponse {
  merchantName: string;
  merchantId: string;
  localizedCategoryName: string; // This is a display friendly name.
  categoryName: { name: CategoryName }; // This is an enum-like name.
  serviceName?: string;
  logo?: string;
  cancellationInformation: CancellationInfo;
}

export async function getCancellationDetails(cancellationId: CancellationId): Promise<CancellationDetailsResponse> {
  return axios
    .get(`api/v1/user/cancellations/${encodeURIComponent(cancellationId)}`, { params: {} })
    .then((response) => response.data);
}
