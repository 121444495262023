import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import React from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    border: '1px dashed #008487',
    padding: '8px 16px 8px 8px',
    borderRadius: '8px',
    margin: '8px',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  text: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
  },
});
const DidYouCancelMessageInner = ({ onClick }: { onClick?(): void }) => {
  const classes = useStyles();
  const { colors } = useTheme();

  return (
    <div className={classes.container} onClick={onClick} role="button" data-test="did-you-cancel-button">
      <div className={classes.text}>
        <Subheading2 style={{ color: colors.action.primary }}>
          <LocalizedMessage id="didYouCancelTitle" />
        </Subheading2>
        <Subheading2 style={{ color: colors.textOn.surfaceSubdued }}>
          <LocalizedMessage id="didYouCancelText" />
        </Subheading2>
      </div>
      <ChevronRightIcon nativeColor={colors.textOn.surfaceSubdued} style={{ alignSelf: 'center' }} />
    </div>
  );
};

export const DidYouCancelMessage = withLocalization(
  'overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/DidYouCancelMessage'
)(DidYouCancelMessageInner);
