import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import PropTypes from 'prop-types';
import React from 'react';
import { ARTICLES_FILTERED_BY_SECTION } from '../../../ducks/zendesk-help-center';
import { Index } from '../ArticlesResult';
import { ContactUsButton } from '../ContactUsButton';
import styles from './styles.scss';

const useStyles = makeStyles((theme) => ({
  articlesDivider: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export const DisplayArticle = ({ article, onNavigateToContactUs }: any) => {
  const classes = useStyles();
  const { colors } = useTheme();

  return (
    <>
      <Card variant="border">
        <Title className={classes.title}>{article.title}</Title>
        <Body color={colors.textOn.surfaceSubdued} className={styles['margin-top']}>
          <span
            /* eslint-disable  */
            className={styles['display-article__img-container']}
            dangerouslySetInnerHTML={{ __html: article.body }}
          />
        </Body>
      </Card>
      <Divider className={classes.articlesDivider} />
      <div>
        <ContactUsButton onNavigateToContactUs={onNavigateToContactUs} />
      </div>
      <Divider className={classes.articlesDivider} />
      <Index articlesFilteredBy={ARTICLES_FILTERED_BY_SECTION} />
    </>
  );
};

DisplayArticle.propTypes = {
  article: PropTypes.object.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onNavigateToContactUs: PropTypes.func.isRequired,
};
