import { useDispatch } from '@client/utils/redux';
import type { ZendeskLabel } from '@client/zendesk';
import { useSelector } from 'react-redux';
import { selectorAppContext } from '../../modules/app-context/duck';
import { open } from '../../modules/Zendesk/ducks';

/**
 * This hook checks if the helpCenter feature flag is enabled, and if so opens up the zendesk dialog.
 * The locale is decided by what's in the appContext. Needs to have the zendeskBaseUrl set in the feature flags.
 *
 * @param label Specifies that category of questions to display in the FAQ
 */
export function useZendesk(label: ZendeskLabel): (() => void) | undefined {
  const dispatch = useDispatch();
  const appContext = useSelector(selectorAppContext);

  if (!appContext.features.helpCenter.enabled) {
    return;
  }

  return async () => {
    // eslint-disable-next-line no-void
    void dispatch(open(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, label));
  };
}
