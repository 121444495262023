import minnaMan from '@client/assets/images/minna-man.svg';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { BasicPaper } from '@client/modules/subscription/components/BasicPaper';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';
import styles from './styles.scss';

// eslint-disable-next-line
const SignoutSuccessPage: FC = ({}, { localizeMessage }) => {
  const { colors } = useTheme();

  return (
    <div className={styles.container}>
      <div className={styles['paper-container']} style={{ backgroundColor: colors.base.background }}>
        <BasicPaper>
          <div data-test="signout-success-message" className={styles['paper-content']}>
            <img src={minnaMan} alt={localizeMessage('headline')} />
            <div className={styles['header-row']}>
              <Title>{<LocalizedMessage id="headline" />}</Title>
            </div>
            <div className={styles.row}>
              <Body>{<LocalizedMessage id="body" />}</Body>
            </div>
          </div>
        </BasicPaper>
      </div>
    </div>
  );
};

SignoutSuccessPage.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const SignoutSuccessPageContainer = withLocalization('SignoutSuccessPage')(SignoutSuccessPage);
