import Sentry from '@client/assets/js/sentry';
// @ts-ignore js import, remove this when the import is typed
import { onEnter, redirect } from '@client/containers/container-helpers/index';
import { selectorUser, setUser } from '@client/ducks/user';
import type { Question } from '@client/models/cancellation-questions';
import { saveUserInformation } from '@client/models/user';
import type { User } from '@client/models/user-models';
import type { AppFeatureToggles } from '@client/modules/app-context';
import { SigningFeature } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import type { CancellationFormPageProps } from '@client/modules/cancellation/CancellationFormPage/CancelContractForm/component';
import { CancellationFormPage } from '@client/modules/cancellation/CancellationFormPage/CancelContractForm/component';
import {
  selectorUserInformationForm,
  updateUserInformationForm,
  updateUserInformationFormField,
} from '@client/modules/subscription/duck';
import * as urls from '@client/routes';
import { trackViewedCancellationFormPage } from '@client/tracking/mixpanel-events';
import { goBackOrDefault } from '@client/utils/hooks/history';
import get from 'lodash/get';
import set from 'lodash/set';
import type React from 'react';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { Dispatch, Store } from 'redux';
import type { AppContext } from '../../../app-context';
import { withAppContext } from '../../../app-context/react';
import {
  selectorContract,
  selectorSubscription,
  subscriptionLoadingFailure,
  updateContract,
  updateContractApiRequest,
} from '../../../subscription';
import type { Contract, ContractId, Subscription, SubscriptionId } from '../../../subscription/types';
import {
  CancellationAction,
  selectorDesiredTerminationAt,
  selectorFormSubmitted,
  selectorIsCancelButtonClicked,
  selectorTerminationAnswers,
  selectorTerminationQuestions,
} from '../../duck';
import type { UserInformationForm } from '../../models';
import { cancellationSignCompleteCallback, onTerminationSignFailed } from '../../signing/common';
import {
  contractCancellationAnswers,
  getPrefilledUserInformationForm,
  userInfoFromUserInformationForm,
} from '../../utils';
import { signLoa } from '../../websocketHelpers';

interface ContainerProps {
  subscriptionId: SubscriptionId;
  contractId: ContractId;
}

interface StateProps {
  user: User;
  contract?: Contract;
  form: UserInformationForm;
  terminationQuestions: Question[];
  terminationAnswers: any;
  formSubmitted: boolean;
  desiredTerminationAt: Date;
  merchantName?: string;
  categoryName?: string;
  categoryText?: string;
  features: AppFeatureToggles;
  logoUrl?: string;
  subscription?: Subscription;
  isCancelButtonClicked: boolean;
}

type ConnectOwnProps = ContainerProps & RouteComponentProps & { appContext: AppContext };

interface DispatchProps {
  onSendCancellation(): void;
  onFormPropertyUpdate(propertyName: keyof UserInformationForm, newValue: any): void;
  onSetAnswer(questionId: string, value: any): void;
  onBackClick(): void;
}

type CancellationFormContainerProps = StateProps & DispatchProps & ConnectOwnProps;

const mapStateToProps = (state: any, { contractId }: ContainerProps): StateProps => ({
  user: selectorUser(state),
  contract: selectorContract(state, contractId),
  form: selectorUserInformationForm(state),
  terminationQuestions: selectorTerminationQuestions(state),
  terminationAnswers: selectorTerminationAnswers(state),
  formSubmitted: selectorFormSubmitted(state),
  desiredTerminationAt: selectorDesiredTerminationAt(state),
  merchantName: selectorSubscription(state)?.supplier.name,
  logoUrl: selectorSubscription(state)?.supplier.logoUrl,
  categoryName: selectorContract(state, contractId)?.service.category.name,
  categoryText: selectorContract(state, contractId)?.service.category.text,
  features: selectorFeatures(state),
  subscription: selectorSubscription(state),
  isCancelButtonClicked: selectorIsCancelButtonClicked(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch,
  { subscriptionId, contractId, history, appContext }: ConnectOwnProps
): DispatchProps => ({
  onSendCancellation: () => {
    dispatch<any>((dispatchInner: Dispatch<any>, getState: () => any) => {
      const state = getState();

      const terminationAnswers = contractCancellationAnswers(
        selectorTerminationAnswers(state),
        selectorTerminationQuestions(state)
      );

      dispatchInner(CancellationAction.setCancelButtonClicked(true));

      if (terminationAnswers) {
        //FIXME: get values from updated user form?
        const updatedUserInformation = userInfoFromUserInformationForm(selectorUserInformationForm(state));

        const userPromise = saveUserInformation(updatedUserInformation);
        userPromise
          .then((user: User) => {
            dispatchInner(setUser(user));
          })
          .catch((error: Error) => {
            Sentry.captureExceptionWithMessage(error, 'Failed to update user from cancellation form page');
          });

        const currentContract = selectorContract(state, contractId);
        // @ts-ignore This should be handled more carefully
        const updatedContract = set(currentContract, 'terminationFormData.answers', terminationAnswers);
        set(updatedContract, 'terminationFormData.desiredTerminationAt', selectorDesiredTerminationAt(state));
        // @ts-ignore This should be handled more carefully
        const contractPromise = updateContractApiRequest(updatedContract);
        contractPromise
          .then((contract: Contract) => {
            dispatchInner(updateContract(contract));
          })
          .catch((error: Error) => {
            dispatchInner(subscriptionLoadingFailure());
            Sentry.captureExceptionWithMessage(error, 'Failed to update contract in termination form', {
              extra: { contractId },
            });
          });

        Promise.all([userPromise, contractPromise])
          .then(() => {
            if (appContext.features.signing === SigningFeature.BANKID) {
              const onTerminationSignCompleted = cancellationSignCompleteCallback(dispatch, history);
              dispatchInner(signLoa(contractId, onTerminationSignCompleted, onTerminationSignFailed));
            }
            history.push(urls.terminationSignPage(subscriptionId, contractId));
          })
          .catch((error) => {
            Sentry.captureExceptionWithMessage(error, 'Failed to sign loa');
          });
      }
    });
  },
  onFormPropertyUpdate: (propertyName: keyof UserInformationForm, newValue: any) => {
    dispatch(updateUserInformationFormField(propertyName, newValue));
  },
  onSetAnswer: (questionId: string, value: any) => {
    dispatch(CancellationAction.setTerminationAnswer(questionId, value));
  },
  onBackClick: () => {
    goBackOrDefault(history);
  },
});

const mergeProps = (mapProps: StateProps, dispatchProps: DispatchProps, ownProps: ConnectOwnProps) => ({
  ...mapProps,
  ...dispatchProps,
  ...ownProps,
});

export const CancellationFormContainer: React.ComponentClass<ContainerProps> = compose<
  CancellationFormPageProps,
  ContainerProps
>(
  withAppContext,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  redirect(
    (props: CancellationFormContainerProps) => get(props.contract, 'state') !== 'Active',
    ({ subscriptionId, contractId }: CancellationFormContainerProps) =>
      urls.terminationSentPage(subscriptionId, contractId)
  ),
  onEnter(({ user, contract }: CancellationFormContainerProps, { store }: { store: Store }) => {
    store.dispatch(CancellationAction.updateTerminationInformation(contract?.service.terminationQuestions ?? []));
    store.dispatch(updateUserInformationForm(getPrefilledUserInformationForm(user)));
    // @ts-ignore This should be handled more carefully
    store.dispatch(CancellationAction.setInitialFormAnswers(contract));
    store.dispatch(CancellationAction.setCancelButtonClicked(false));
    const subscription = selectorSubscription(store.getState());
    if (subscription) {
      // @ts-ignore This should be handled more carefully
      trackViewedCancellationFormPage(subscription, contract);
    }
  })
)(CancellationFormPage);
