import { ThemeType } from '@client/models/ThemeType';

export const cogsColorWaffle = '#ff6200';

export const cogsColor = (theme: ThemeType | undefined, primaryColor: string): string => {
  switch (theme) {
    case ThemeType.WAFFLE:
      return cogsColorWaffle;
    case ThemeType.MINNA:
    default:
      return primaryColor;
  }
};
