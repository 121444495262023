import type { SupplierId } from '@client/models/supplier';
import type { ContractId } from '@client/modules/subscription/types';
import axios from 'axios';

interface ISaveDeskRequest {
  cancellationReason: CancellationReason;
  saveDeskOfferId: SaveDeskOfferId;
  name: string;
  emailAddress: string;
  phoneNumber: string;
  address: string;
}

export type SaveDeskOfferId = string;
export type PartnerSupplierId = string;

export enum CancellationReason {
  NotSatisfiedWithService = 'NotSatisfiedWithService',
  SwitchedToAnotherService = 'SwitchedToAnotherService',
  TechnicalDifficulties = 'TechnicalDifficulties',
  NoTimeOrNoInterest = 'NoTimeOrNoInterest',
  TooExpensive = 'TooExpensive',
  Covid19 = 'Covid19',
  AnotherReason = 'AnotherReason',
  NotEnoughSelection = 'NotEnoughSelection',
  IDoNotNeedItAnymore = 'IDoNotNeedItAnymore',
  IHaveMoved = 'IHaveMoved',
}

export interface SaveDeskOfferForUser {
  id: SaveDeskOfferId;
  partnerSupplierId: PartnerSupplierId;
  cancellationReasons: string[];
  name: string;
  description: string;
  disclaimer?: string;
}

export async function acceptSaveDeskOffer(contractId: ContractId, request: ISaveDeskRequest): Promise<void> {
  return axios.post(`/api/user/contracts/${contractId}/offer/accept-predefined`, request);
}

export async function getSaveDeskOffers(
  contractId: ContractId,
  supplierId: SupplierId
): Promise<SaveDeskOfferForUser[]> {
  return axios
    .get(`/api/user/contracts/${contractId}/save-desk/offers/${supplierId}`)
    .then((response) => response.data);
}

export async function getCancellationReasonsForSaveDesk(
  contractId: ContractId,
  categoryName: string
): Promise<CancellationReason[]> {
  return axios
    .get(`/api/user/contracts/${contractId}/save-desk/cancellation-reasons/${categoryName}`)
    .then((response) => response.data);
}
