import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { Contract } from '@client/modules/subscription/types';
import { BindingPeriodType, ContractState, isWithinBindingPeriod } from '@client/modules/subscription/types';
import { ButtonText } from '@minna-technologies/minna-ui/components/Typography/ButtonText';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import moment from 'moment';
import React from 'react';
import styles from './styles.scss';

export interface ContractCardInfoChipProps {
  contract: Contract;
}

function generateBindingTimeMessage(contract: Contract) {
  if (contract.contractBindingPeriod.type !== BindingPeriodType.FixedContractBindingPeriod) {
    return null;
  }
  const bindingTimeExpired = isWithinBindingPeriod(contract);

  return (
    !bindingTimeExpired && (
      <div className={styles.bindingTime}>
        <ButtonText>
          <div className={styles.chip}>{<LocalizedMessage id="bindingTimeLeftChip" />}</div>
        </ButtonText>
        <Caption>
          <LocalizedMessage
            id="bindingTimeLeft"
            values={{
              duration: moment(contract.contractBindingPeriod.endsAt).fromNow(true),
            }}
          />
        </Caption>
      </div>
    )
  );
}

function generateStateChip(contractState: ContractState) {
  if ([ContractState.Terminated, ContractState.Optimized].includes(contractState)) {
    return (
      <ButtonText>
        <div className={styles.terminatedChip}>{<LocalizedMessage id={`status${contractState}`} />}</div>
      </ButtonText>
    );
  }
  if ([ContractState.Terminating, ContractState.Negotiating, ContractState.Optimizing].includes(contractState)) {
    return (
      <ButtonText>
        <div className={styles.chip}>{<LocalizedMessage id={`status${contractState}`} />}</div>
      </ButtonText>
    );
  }

  return null;
}

export const ContractCardInfoChipComponent: React.FunctionComponent<ContractCardInfoChipProps> = (
  props: ContractCardInfoChipProps
) => {
  const chip = generateStateChip(props.contract.state);

  return <>{chip ?? generateBindingTimeMessage(props.contract)}</>;
};

export const ContractCardInfoChip = withLocalization('contract/ContractCardMessage')(ContractCardInfoChipComponent);
