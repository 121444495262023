import type { CancellationReason, SaveDeskOfferForUser } from '@client/modules/saveDesk/api';
import type { ActionsUnion } from '@client/utils/redux';
import { SaveDeskEvent } from './events';

const makeAction =
  <T extends SaveDeskEvent, P>(type: T) =>
  (payload: P) => ({
    type,
    payload,
  });

export const SetSentFeedback = makeAction<SaveDeskEvent.SENT_FEEDBACK, boolean>(SaveDeskEvent.SENT_FEEDBACK);

export const SetCancellationReason = makeAction<SaveDeskEvent.SET_CANCELLATION_REASON, CancellationReason>(
  SaveDeskEvent.SET_CANCELLATION_REASON
);
export const SetOffer = makeAction<SaveDeskEvent.SET_OFFER, SaveDeskOfferForUser>(SaveDeskEvent.SET_OFFER);

const actions = {
  SetSentFeedback,
  SetCancellationReason,
  SetOffer,
};

export type SaveDeskAction = ActionsUnion<typeof actions>;
