import type { AppType, Market } from '@client/modules/app-context/constants';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import { appPlatform, getMixpanel } from '@client/tracking/mixpanel';
import type { TrackingButtonLocation } from '@client/tracking/mixpanel-constants';
import {
  EventNames,
  TrackingPageName,
  TrackingSubscriptionMethod,
  TrackingFeature,
  TrackingFeedbackType,
} from '@client/tracking/mixpanel-constants';
import { subscriptionAndContractProperties } from '@client/tracking/mixpanel-event-properties';

export function sendClickedButtonMixpanelEvent(
  page: string,
  location: TrackingButtonLocation,
  button: string,
  subscription: Subscription,
  contract?: Contract,
  reason?: string
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    ...subscriptionAndContractProperties(subscription, contract),
    Feature: TrackingFeature.CancelIG,
    Page: page,
    Button: button,
    Location: location,
    Reason: reason,
  });
}

export function trackClickedButton(
  feature: TrackingFeature,
  page: string,
  location: TrackingButtonLocation,
  button: string,
  merchantId: string,
  serviceId: string,
  market: Market,
  appType: AppType,
  merchantName?: string,
  serviceName?: string,
  reason?: string,
  method?: string,
  category?: string
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: feature,
    Page: page,
    Button: button,
    Location: location,
    Reason: reason,
    'Supplier id': merchantId,
    'Supplier name': merchantName,
    'Service id': serviceId,
    'Service name': serviceName,
    Method: method,
    Category: category,
    Market: market,
    Platform: appType,
    'App platform': appPlatform(),
  });
}

export function ratedCancellationGuideExperienceMixpanelEvent(
  rating: number,
  subscription: Subscription,
  amountSavedPerYear?: number,
  contract?: Contract
) {
  getMixpanel().track(EventNames.RATED_EXPERIENCE, {
    Page: TrackingPageName.SUCCESS_SCREEN,
    ...subscriptionAndContractProperties(subscription, contract),
    Feature: TrackingFeature.CancelIG, // In Figma it's Cancellation
    mp_rating: rating,
    'Feedback type': TrackingFeedbackType.Guide,
    'Amount saved per year': amountSavedPerYear,
  });
}

export function trackRatedIntelligentGuideExperience(
  feature: TrackingFeature,
  merchantId: string,
  serviceId: string,
  categoryName: string,
  serviceName: string,
  merchantName: string,
  rating: number,
  market: Market,
  appType: AppType
) {
  getMixpanel().track(EventNames.RATED_EXPERIENCE, {
    Feature: feature,
    Method: TrackingSubscriptionMethod.IntelligentGuide,
    Page: TrackingPageName.SUCCESS_SCREEN,
    Category: categoryName,
    'Service id': serviceId,
    'Service name': serviceName,
    'Supplier id': merchantId,
    'Supplier name': merchantName,
    mp_rating: rating,
    'Feedback type': TrackingFeedbackType.Guide,
    Market: market,
    Platform: appType,
    'App platform': appPlatform(),
  });
}

export function sendViewedGuideCancellationGuideMixpanelEvent(page: string, merchantName: string) {
  getMixpanel().track(EventNames.VIEWED_GUIDE, {
    Feature: TrackingFeature.CancelIG,
    Page: page,
    'Supplier name': merchantName,
  });
}

export function trackViewedIntelligentGuidePage(
  feature: TrackingFeature,
  page: string,
  merchantName: string,
  categoryName: string,
  serviceName: string,
  market: Market,
  appType: AppType,
  serviceId?: string,
  contractId?: string,
  merchantId?: string,
  subscriptionSource?: string
) {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: feature,
    Page: page,
    Method: TrackingSubscriptionMethod.IntelligentGuide,
    Category: categoryName,
    'Service name': serviceName,
    'Service id': serviceId,
    Market: market,
    Platform: appType,
    'Supplier name': merchantName,
    'App platform': appPlatform(),
    'Contract id': contractId,
    'Supplier id': merchantId,
    'Subscription source': subscriptionSource,
  });
}
