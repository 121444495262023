import { Sentry } from '@client/assets/js/sentry';
import type { CategoryName } from '@client/constants/categories';
import useTheme from '@material-ui/core/styles/useTheme';
import type { IconProps } from '@minna-technologies/minna-ui/icons';
import { BroadbandIcon } from '@minna-technologies/minna-ui/icons/Broadband';
import { CharityIcon } from '@minna-technologies/minna-ui/icons/Charity';
import { CreditCardIcon } from '@minna-technologies/minna-ui/icons/CreditCard';
import { DatingIcon } from '@minna-technologies/minna-ui/icons/Dating';
import { ElectricityGridIcon } from '@minna-technologies/minna-ui/icons/ElectricityGrid';
import { ElectricityNetworkIcon } from '@minna-technologies/minna-ui/icons/ElectricityNetwork';
import { FitnessIcon } from '@minna-technologies/minna-ui/icons/Fitness';
import { GamblingIcon } from '@minna-technologies/minna-ui/icons/Gambling';
import { GamingIcon } from '@minna-technologies/minna-ui/icons/Gaming';
import { GasIcon } from '@minna-technologies/minna-ui/icons/Gas';
import { GroceryBagIcon } from '@minna-technologies/minna-ui/icons/GroceryBag';
import { HeartIcon } from '@minna-technologies/minna-ui/icons/Heart';
import { HomeSecurityIcon } from '@minna-technologies/minna-ui/icons/HomeSecurity';
import { InsuranceAllIcon } from '@minna-technologies/minna-ui/icons/InsuranceAll';
import { InsuranceUnemploymentIcon } from '@minna-technologies/minna-ui/icons/InsuranceUnemployment';
import { LandlineIcon } from '@minna-technologies/minna-ui/icons/Landline';
import { LoanCarIcon } from '@minna-technologies/minna-ui/icons/LoanCar';
import { LoanHouseIcon } from '@minna-technologies/minna-ui/icons/LoanHouse';
import { LoanPrivateIcon } from '@minna-technologies/minna-ui/icons/LoanPrivate';
import { MembershipIcon } from '@minna-technologies/minna-ui/icons/Membership';
import { MobileIcon } from '@minna-technologies/minna-ui/icons/Mobile';
import { MobileAppIcon } from '@minna-technologies/minna-ui/icons/MobileApp';
import { MobileBroadbandIcon } from '@minna-technologies/minna-ui/icons/MobileBroadband';
import { NewsMagazineIcon } from '@minna-technologies/minna-ui/icons/NewsMagazine';
import { NewsNewspaperIcon } from '@minna-technologies/minna-ui/icons/NewsNewspaper';
import { OtherIcon } from '@minna-technologies/minna-ui/icons/Other';
import { PublicTransportIcon } from '@minna-technologies/minna-ui/icons/PublicTransport';
import { SavingsIcon } from '@minna-technologies/minna-ui/icons/Savings';
import { SoftwareIcon } from '@minna-technologies/minna-ui/icons/Software';
import { StreamingIcon } from '@minna-technologies/minna-ui/icons/Streaming';
import { SubscriptionBoxIcon } from '@minna-technologies/minna-ui/icons/SubscriptionBox';
import { TelevisionIcon } from '@minna-technologies/minna-ui/icons/Television';
import { TradeUnionIcon } from '@minna-technologies/minna-ui/icons/TradeUnion';
import React from 'react';

type Props = {
  categoryName: CategoryName;
  isHighlighted?: boolean;
} & IconProps;

export const CategoryIcon = ({ categoryName, isHighlighted, ...props }: Props) => {
  const Icon = icon(categoryName);
  const { colors } = useTheme();
  const nativeColor = isHighlighted ? colors.action.positive : props.nativeColor;

  return <Icon {...{ ...props, nativeColor }} />;
};

// eslint-disable-next-line complexity
const icon = (categoryName: CategoryName) => {
  // eslint-disable-next-line
  switch (categoryName) {
    case 'broadband':
      return BroadbandIcon;
    case 'grocery-bag':
      return GroceryBagIcon;
    case 'membership':
      return MembershipIcon;
    case 'public-transport':
      return PublicTransportIcon;
    case 'car-loan':
      return LoanCarIcon;
    case 'game':
      return GamingIcon;
    case 'mortgage-loan':
      return LoanHouseIcon;
    case 'mobile':
      return MobileIcon;
    case 'software':
      return SoftwareIcon;
    case 'tv':
      return TelevisionIcon;
    case 'charity':
      return CharityIcon;
    case 'gambling':
      return GamblingIcon;
    case 'mobile-broadband':
      return MobileBroadbandIcon;
    case 'streaming-media':
      return StreamingIcon;
    case 'insurance':
      return InsuranceAllIcon;
    case 'news':
      return NewsNewspaperIcon;
    case 'trade-union':
      return TradeUnionIcon;
    case 'private-loan':
      return LoanPrivateIcon;
    case 'magazine':
      return NewsMagazineIcon;
    case 'electricity':
      return ElectricityNetworkIcon;
    case 'land-line':
      return LandlineIcon;
    case 'credit-card':
      return CreditCardIcon;
    case 'dating':
      return DatingIcon;
    case 'electricity-grid':
      return ElectricityGridIcon;
    case 'fitness':
      return FitnessIcon;
    case 'home-security':
      return HomeSecurityIcon;
    case 'mobile-app':
      return MobileAppIcon;
    case 'subscription-box':
      return SubscriptionBoxIcon;
    case 'unemployment-insurance':
      return InsuranceUnemploymentIcon;
    case 'other':
      return OtherIcon;
    case 'other-transaction':
      return OtherIcon;
    case 'gas':
      return GasIcon;
    case 'savings':
      return SavingsIcon;
    case 'entertainment':
      return GamingIcon;
    case 'clothing-accessories':
      return GroceryBagIcon;
    case 'health':
      return HeartIcon;
    case 'transport':
      return PublicTransportIcon;
    default:
      Sentry.captureMessage('Icon not added for category', {
        extra: { categoryName: categoryName },
      });

      return OtherIcon;
  }
};
