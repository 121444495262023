import type { CategoryName } from '@client/constants/categories';
import { BROADBAND, FITNESS, MOBILE, STREAMING_MEDIA, TV } from '@client/constants/categories';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { AppType, Market } from '@client/modules/app-context/constants';
import {
  sendCloseCancellationReasonsDialogEvent,
  sendRejectedCancellationReasonsDialogEvent,
  sendSubmittedCancellationReasonEvent,
} from '@client/modules/cancellation/CancellationReasonsDialog/mixpanel';
import { CancellationReason } from '@client/modules/saveDesk/api';
import { RadioButton } from '@client/modules/shared-components/inputs/RadioButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { TextInput } from '@minna-technologies/minna-ui/components/Inputs/TextInput';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React, { useState } from 'react';

export interface IOption {
  text: React.ReactNode | string;
  value: CancellationReason;
}

const useStyles = makeStyles({
  cancellationReasonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '100%',
    marginTop: '8px',
  },
});

interface CancellationReasonsDialogComponentProps {
  dialogOpen: boolean;
  providerName: string;
  categoryName: CategoryName;

  onClose(): void;
}

const CancellationReasonsByCategoryName: Record<string, CancellationReason[]> = {
  [STREAMING_MEDIA]: [
    CancellationReason.TooExpensive,
    CancellationReason.NotSatisfiedWithService,
    CancellationReason.SwitchedToAnotherService,
    CancellationReason.NotEnoughSelection,
    CancellationReason.TechnicalDifficulties,
  ],
  [MOBILE]: [
    CancellationReason.TooExpensive,
    CancellationReason.NotSatisfiedWithService,
    CancellationReason.SwitchedToAnotherService,
    CancellationReason.IDoNotNeedItAnymore,
  ],
  [BROADBAND]: [
    CancellationReason.TooExpensive,
    CancellationReason.NotSatisfiedWithService,
    CancellationReason.SwitchedToAnotherService,
    CancellationReason.IHaveMoved,
  ],
  [FITNESS]: [
    CancellationReason.TooExpensive,
    CancellationReason.NotSatisfiedWithService,
    CancellationReason.SwitchedToAnotherService,
    CancellationReason.NoTimeOrNoInterest,
    CancellationReason.Covid19,
  ],
  [TV]: [
    CancellationReason.TooExpensive,
    CancellationReason.NotSatisfiedWithService,
    CancellationReason.SwitchedToAnotherService,
    CancellationReason.TechnicalDifficulties,
    CancellationReason.NoTimeOrNoInterest,
  ],
};

export function isItApplicableToShowCancellationReasonsDialog(
  market: Market,
  appType: AppType,
  categoryName: CategoryName,
  saveDeskEnabled: boolean
): boolean {
  return (
    market === Market.Sweden &&
    appType === AppType.Standalone &&
    Object.keys(CancellationReasonsByCategoryName).includes(categoryName) &&
    !saveDeskEnabled
  );
}

const CancellationReasonDialogComponentInner: React.FunctionComponent<CancellationReasonsDialogComponentProps> = ({
  providerName,
  categoryName,
  dialogOpen,
  onClose,
}) => {
  const classes = useStyles();
  const [selectedReason, setSelectedReason] = useState<CancellationReason | undefined>(undefined);
  const [anotherReasonText, setAnotherReasonText] = useState<string>('');
  const cancellationReasonOptions: IOption[] = CancellationReasonsByCategoryName[categoryName]
    .map(fromCancellationReasonToOption)
    .concat([fromCancellationReasonToOption(CancellationReason.AnotherReason)]);

  const cancellationReasonsSection = cancellationReasonOptions.map((reason, i) => {
    return (
      <RadioButton
        key={i}
        onChange={() => setSelectedReason(reason.value)}
        checked={reason.value === selectedReason}
        label={reason.text}
      />
    );
  });
  const primaryButton = (
    <PrimaryButton
      label={<LocalizedMessage id="cancellationReasonsDialogSendFeedbackButton" />}
      onClick={onSendFeedback}
      disabled={!selectedReason}
    />
  );

  return (
    <Modal
      open={dialogOpen}
      onClose={onModalClose}
      title={<LocalizedMessage id="cancellationReasonsDialogTitle" />}
      button1={primaryButton}
      button2={
        <SecondaryButton
          label={<LocalizedMessage id="cancellationReasonsDialogNoAnswerButton" />}
          onClick={onCancelClicked}
        />
      }
    >
      <Body>
        <LocalizedMessage id="cancellationReasonsDialogDescription" values={{ providerName: providerName }} />
      </Body>
      <div className={classes.cancellationReasonsWrapper}>{cancellationReasonsSection}</div>
      {selectedReason === CancellationReason.AnotherReason && (
        <TextInput
          onChange={onAnotherReasonTextChange}
          label={<LocalizedMessage id="reasonForCancellationInput" />}
          fullWidth
          value={anotherReasonText}
        />
      )}
    </Modal>
  );

  function onModalClose() {
    sendCloseCancellationReasonsDialogEvent(categoryName, providerName);
    onClose();
  }

  function onCancelClicked() {
    sendRejectedCancellationReasonsDialogEvent(categoryName, providerName);
    onClose();
  }

  function onSendFeedback() {
    if (!selectedReason) return;

    sendSubmittedCancellationReasonEvent(selectedReason, anotherReasonText, categoryName, providerName);
    onClose();
  }

  function onAnotherReasonTextChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAnotherReasonText(event.target.value);
  }

  function fromCancellationReasonToOption(cancellationReason: CancellationReason) {
    return {
      value: cancellationReason,
      text: <LocalizedMessage id={cancellationReason} />,
    };
  }
};

export const CancellationReasonsDialogComponent = withLocalization('cancellation/CancellationReasonsDialog')(
  CancellationReasonDialogComponentInner
);
