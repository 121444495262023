import Sentry from '@client/assets/js/sentry';
// @ts-ignore js import, remove this when the import is typed
import { errorPageOnFailure, loadingPageWhileLoading, onEnter } from '@client/containers/container-helpers';
import requireLoggedInUser from '@client/containers/requireLoggedInUser';
import { withFeatures } from '@client/feature-toggling';
import type { Subscription, SubscriptionId } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { AppContext, AppFeatureToggles } from '../../app-context';
import { withAppContext } from '../../app-context/react';
import PageNotFoundContainer from '../../fetching/ErrorPage';
import {
  fetchSubscription,
  selectorSubscription,
  selectorSubscriptionIsLoading,
  selectorSubscriptionLoadingFailed,
  subscriptionLoadingFailure,
  subscriptionLoadingSuccess,
} from '../index';
import { SelectServicesPage } from '../SelectServicesPage';
import { SubscriptionDetailsPage } from '../SubscriptionDetailsPage';
import { SubscriptionSettingsPage } from '../SubscriptionSettingsPage';
import { SubscriptionUpdateCostPage } from '../SubscriptionUpdateCostPage';
import { ContractRoute } from './ContractRoute';

interface SubscriptionRouteProps {
  subscriptionId: SubscriptionId;
  subscription: Subscription;
  features: AppFeatureToggles;
  appContext: AppContext;
}

const SubscriptionRouteInner: React.FC<SubscriptionRouteProps> = ({
  subscriptionId,
  subscription,
  features,
  appContext,
}) => {
  return (
    <Switch>
      <Route
        exact
        path={urls.subscriptionBasePath(subscriptionId)}
        render={() => {
          const numberOfContracts = get(subscription, 'contracts', []).length;
          if (numberOfContracts === 0) {
            return <Redirect to={urls.selectServicesPage(subscriptionId)} />;
          } else if (subscription.singleContractId) {
            return <Redirect to={urls.contractDetailsPage(subscriptionId, subscription.singleContractId)} />;
          } else {
            return <Redirect to={urls.subscriptionPage(subscriptionId)} />;
          }
        }}
      />
      <Route
        exact
        path={urls.subscriptionSettingsPage(subscriptionId)}
        render={() => {
          return <SubscriptionSettingsPage />;
        }}
      />
      <Route
        exact
        path={urls.subscriptionPage(subscriptionId)}
        render={() => {
          return <SubscriptionDetailsPage />;
        }}
      />
      <Route
        exact
        path={urls.selectServicesPage(subscriptionId)}
        render={() => {
          return <SelectServicesPage subscription={subscription} />;
        }}
      />
      {features.identify?.overview?.manuallyAddedSubscriptions && (
        <Route
          exact
          path={urls.subscriptionUpdateCostPage(subscriptionId)}
          render={() => {
            return <SubscriptionUpdateCostPage />;
          }}
        />
      )}
      <Route
        path={`${urls.subscriptionRoutePrefix}/:subscriptionId/${urls.subscriptionContractRoutePrefix}/:contractId`}
        render={({
          match: {
            params: { subscriptionId, contractId },
          },
        }) => {
          return (
            <ContractRoute
              subscriptionId={subscriptionId}
              contractId={contractId}
              appContext={appContext}
              features={appContext.features}
            />
          );
        }}
      />
      <Route component={PageNotFoundContainer} />
    </Switch>
  );
};

const mapStateToProps = (state: any, { subscriptionId }: SubscriptionRouteProps) => {
  return {
    subscription: selectorSubscription(state),
    isLoading: selectorSubscriptionIsLoading(state, subscriptionId),
    loadingFailed: selectorSubscriptionLoadingFailed(state),
  };
};

export const SubscriptionRoute: React.ComponentType<any> = compose(
  withRouter,
  withFeatures,
  withAppContext,
  requireLoggedInUser,
  connect(mapStateToProps),
  onEnter(({ subscriptionId }: SubscriptionRouteProps, { store }: any) => {
    getSubscription(subscriptionId, store.dispatch);
  }),
  errorPageOnFailure((p: any) => p.loadiloadingFailedngFailed),
  loadingPageWhileLoading((p: any) => p.isLoading)
  // @ts-ignore
)(SubscriptionRouteInner);

function getSubscription(subscriptionId: SubscriptionId, dispatch: any) {
  fetchSubscription(subscriptionId)
    .then((s: Subscription) => {
      dispatch(subscriptionLoadingSuccess(s));
    })
    .catch((error) => {
      dispatch(subscriptionLoadingFailure());
      Sentry.captureMessage('Failed to load subscription', { extra: { error, subscriptionId } });
    });
}
