import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_OVERVIEW } from '@client/tracking/mixpanel-constants';

export const mixpanelClickButtonEvent = (pageName: string, button: string, extra = {}) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_OVERVIEW,
    Page: pageName,
    Button: button,
    ...extra,
  });
};
