import Sentry from '@client/assets/js/sentry';
import { hidePotentialEmailAddresses } from '@client/utils/email';
import defer from 'lodash/defer';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

export const userObjectToUsername = (userObject: any) => {
  if (!isObject(userObject)) return '';

  const firstName = get(userObject, 'name.first', '');
  const lastName = get(userObject, 'name.last', '');

  if (!isString(firstName) || !isString(lastName)) return '';

  return `${firstName.trim()} ${lastName.trim()}`;
};

export const userObjectToEmailAddress = (userObject: any) => {
  if (!isObject(userObject)) return '';

  const emailAddress = get(userObject, 'emailAddress', '');

  if (!isString(emailAddress)) return '';

  return emailAddress.trim();
};

export const scrollToBottom = () => {
  // Need to wait until React has added the new DOM element to the bottom of the history before we scroll
  // This approach seems to do the trick, albeit with a little bit of stuttering
  defer(() => {
    const bottomEl = document.querySelector('div[data-js="the-very-last-element-in-chat-history"]');
    if (bottomEl) {
      bottomEl.scrollIntoView();
    }
  });
};

export const logErrors = (error: any, methodName: any, ...methodArgs: any) => {
  error.message = hidePotentialEmailAddresses(error.message);
  Sentry.captureMessage(`Zendesk Chat API: ${methodName}`, { extra: { error, methodName, methodArgs } });
};
