import type { Market } from '@client/modules/app-context/constants';
import { selectorMarket } from '@client/modules/app-context/duck';
import type { ESigningComponentProps } from '@client/modules/e-signing/component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { ESigningComponent } from './component';

interface ESigningProps {
  navigateToOnSuccess: string;
  contractId: string;
  signingId: string;
  resourceToSignId: string;
}

interface StateProps {
  market: Market;
}

const mapStateToProps = (state: any): StateProps => ({
  market: selectorMarket(state),
});

export const ESigning = compose<ESigningComponentProps, ESigningProps>(
  withRouter,
  connect(mapStateToProps)
)(ESigningComponent);
