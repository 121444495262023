import Sentry from '@client/assets/js/sentry';
// @ts-ignore js import, remove this when the import is typed
import { loadingPageWhileLoading, onEnter } from '@client/containers/container-helpers';
import type { ServiceId, SupplierId } from '@client/models/supplier';
import { CancellationAction, selectorIsSendingCancellation } from '@client/modules/cancellation/duck';
import { sentCancellationOfServiceFinishTrackingEvent } from '@client/modules/cancellation/mixpanel-events';
import { requestCancellationForService } from '@client/modules/cancellation/signing/NoSigningServicePage/api';
import * as urls from '@client/routes';
import type { AxiosError } from 'axios';
import type { History } from 'history';
import type * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { NoSigningServicePageProps } from './component';
import { NoSigningServicePageComponent } from './component';

interface ContainerOuterProps {
  merchantId: SupplierId;
  serviceId: ServiceId;
}

type ConnectOwnProps = {
  history: History;
} & ContainerOuterProps;

interface StateProps {
  isSendingCancellation: boolean;
}

interface DispatchProps {
  onBackClick(): void;
  onCancellationSigningComplete(): void;
  setIsSendingCancellation(isSending: boolean): void;
}

type MergeProps = ContainerOuterProps & ConnectOwnProps & StateProps & DispatchProps;

function mapStateToProps(state: any): StateProps {
  return {
    isSendingCancellation: selectorIsSendingCancellation(state),
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  { history, merchantId, serviceId }: ConnectOwnProps
): DispatchProps => ({
  onBackClick: () => {
    dispatch(CancellationAction.cancel());
    history.replace(urls.signCancelServiceRouteFun(merchantId, serviceId));
  },
  onCancellationSigningComplete: () => {
    sentCancellationOfServiceFinishTrackingEvent(merchantId, serviceId);
    history.replace(urls.sentCancelServiceRouteFun(merchantId, serviceId));
  },
  setIsSendingCancellation: (isSending: boolean) => {
    dispatch(CancellationAction.setIsSendingCancellation(isSending));
  },
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: ConnectOwnProps): MergeProps => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
  };
};

type NoSigningServicePageContainerProps = StateProps & DispatchProps & MergeProps & ContainerOuterProps;

export const NoSigningServicePage: React.ComponentClass<ContainerOuterProps> = compose<
  NoSigningServicePageProps,
  ContainerOuterProps
>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  onEnter(
    ({
      merchantId,
      serviceId,
      onCancellationSigningComplete,
      setIsSendingCancellation,
    }: NoSigningServicePageContainerProps) => {
      setIsSendingCancellation(true);
      requestCancellationForService(merchantId, serviceId)
        .then(() => {
          setIsSendingCancellation(false);
          onCancellationSigningComplete();
        })
        .catch((error: AxiosError) => {
          setIsSendingCancellation(false);
          Sentry.captureExceptionWithMessage(error, 'Failed to request cancellation for Service');
        });
    }
  ),
  loadingPageWhileLoading((props: NoSigningServicePageContainerProps) => props.isSendingCancellation)
)(NoSigningServicePageComponent);
