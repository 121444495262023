import type { AddonOption, AddonProductWithMobileProduct } from '@client/modules/addon/utils';
import type { InsightId } from '@client/modules/InsightsPage/models';
import type { ContractId, Subscription } from '@client/modules/subscription/types';
import type { MobileProduct, MobileSubscriptionFilter } from '@client/modules/switch/mobile/models';
import { MinimumAirTimeUnit } from '@client/modules/switch/mobile/models';
import type { ActionsUnion } from '@client/utils/redux';
import { createAction } from '@client/utils/redux';

export interface AddonState {
  mobileProducts: MobileProduct[];
  chosenProduct?: AddonProductWithMobileProduct;
  subscription?: Subscription;
  contractId?: ContractId;
  insightId?: InsightId;
  addonOptions: AddonOption[];
  filter: MobileSubscriptionFilter;
  placingOrder: boolean;
}

const initialState: AddonState = {
  mobileProducts: [],
  addonOptions: [],
  filter: {
    requestedDataCapacity: 4,
    currentDataCapacity: 4,
    minimumAirTime: 8,
    minimumAirTimeUnit: MinimumAirTimeUnit.HOURS,
  },
  placingOrder: false,
};

enum ActionKeys {
  SET_MOBILE_PRODUCTS = 'ADDON/SET_MOBILE_PRODUCTS',
  SET_INSIGHT_ID_AND_ADDON_OPTIONS = 'ADDON/SET_INSIGHT_ID_AND_ADDON_OPTIONS',
  SET_CHOSEN_PRODUCT_AND_ADDON_OPTIONS = 'ADDON/SET_CHOSEN_PRODUCT_AND_ADDON_OPTIONS',
  SET_PLACING_ORDER = 'MOBILE_GUIDE/SET_PLACING_ORDER',
}

export const AddonActions = {
  setMobileProducts: (mobileProducts: MobileProduct[]) => createAction(ActionKeys.SET_MOBILE_PRODUCTS, mobileProducts),
  setInsightIdAndAddonOptions: (addonOptions: AddonOption[], insightId?: InsightId) =>
    createAction(ActionKeys.SET_INSIGHT_ID_AND_ADDON_OPTIONS, { addonOptions, insightId }),
  setChosenProductWithAddonOptions: (mobileProductWithAddonOptions: AddonProductWithMobileProduct) =>
    createAction(ActionKeys.SET_CHOSEN_PRODUCT_AND_ADDON_OPTIONS, mobileProductWithAddonOptions),
  setPlacingOrder: (placingOrder: boolean) => createAction(ActionKeys.SET_PLACING_ORDER, placingOrder),
};

export function reducer(state: AddonState = initialState, action: ActionsUnion<typeof AddonActions>): AddonState {
  switch (action.type) {
    case ActionKeys.SET_INSIGHT_ID_AND_ADDON_OPTIONS:
      return {
        ...state,
        insightId: action.payload.insightId,
        addonOptions: action.payload.addonOptions,
      };
    case ActionKeys.SET_MOBILE_PRODUCTS:
      return {
        ...state,
        mobileProducts: action.payload,
      };
    case ActionKeys.SET_PLACING_ORDER: {
      return {
        ...state,
        placingOrder: action.payload,
      };
    }
    case ActionKeys.SET_CHOSEN_PRODUCT_AND_ADDON_OPTIONS: {
      return {
        ...state,
        chosenProduct: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

interface State {
  addon: AddonState;
}

export const selectorChosenProduct = (state: State) => state.addon?.chosenProduct;
export const selectorInsightId = (state: State) => state.addon?.insightId;
export const selectorMobileProducts = (state: State) => state.addon?.mobileProducts;
export const selectorAddonOptions = (state: State) => state.addon?.addonOptions;
export const selectorFilter = (state: State) => state.addon?.filter;
export const selectorPlacingOrder = (state: State) => state.addon?.placingOrder;
