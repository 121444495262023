import type { ActionsUnion } from '@client/utils/redux';
import { createAction } from '@client/utils/redux';
import type { OrderedSubscription } from './models';

export interface OrderedSubscriptionState {
  pageIsLoading: boolean;
  pageLoadingFailed: boolean;
  orderedSubscription?: OrderedSubscription;
  sentRating?: boolean;
}

const initialState: OrderedSubscriptionState = {
  pageIsLoading: true,
  pageLoadingFailed: false,
};

enum ActionKeys {
  LOADING = 'ORDERED_SUBSCRIPTION/PAGE_LOADING',
  LOADING_FAILURE = 'ORDERED_SUBSCRIPTION/PAGE_LOADING_FAILURE',
  LOADING_SUCCESS = 'ORDERED_SUBSCRIPTION/PAGE_LOADING_SUCCESS',
  SENT_RATING = 'ORDERED_SUBSCRIPTION/SENT_RATING',
}

export const OrderedSubscriptionAction = {
  setIsLoading: () => createAction(ActionKeys.LOADING),
  setOrderedSubscriptionLoaded: (orderedSubscription: OrderedSubscription) =>
    createAction(ActionKeys.LOADING_SUCCESS, orderedSubscription),
  setOrderedSubscriptionFailedLoading: () => createAction(ActionKeys.LOADING_FAILURE),
  sentRating: () => createAction(ActionKeys.SENT_RATING),
};

export type OrderedSubscriptionActions = ActionsUnion<typeof OrderedSubscriptionAction>;

export function reducer(state: OrderedSubscriptionState = initialState, action: OrderedSubscriptionActions) {
  switch (action.type) {
    case ActionKeys.LOADING:
      return { ...state, isLoading: true, loadingFailed: false };
    case ActionKeys.LOADING_FAILURE:
      return { ...state, isLoading: false, loadingFailed: true };

    case ActionKeys.LOADING_SUCCESS:
      return { ...state, isLoading: false, loadingFailed: false, orderedSubscription: action.payload };

    case ActionKeys.SENT_RATING:
      return { ...state, sentRating: true };

    default:
      return state;
  }
}

export const selectorIsLoading = (state: any) => state.orderedSubscription.isLoading;
export const selectorLoadingFailed = (state: any) => state.orderedSubscription.loadingFailed;
export const selectorOrderedSubscription = (state: any) => state.orderedSubscription.orderedSubscription;
export const selectorSentRating = (state: any) => state.orderedSubscription.sentRating;
