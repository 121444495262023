import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { Market } from '@client/modules/app-context/constants';
import {
  norwayMobilePhoneNumberFormatter,
  NorwayMobilePhoneNumberValidators,
} from '@client/modules/shared-components/inputs/MobilePhoneNumberField/norway/NorwayMobilePhoneNumberValidators';
import {
  swedishMobilePhoneNumberFormatter,
  SwedishMobilePhoneNumberValidators,
} from '@client/modules/shared-components/inputs/MobilePhoneNumberField/sweden/SwedishMobilePhoneNumberValidators';
import type { TextFieldProps } from '@client/modules/shared-components/inputs/TextField';
import { TextField } from '@client/modules/shared-components/inputs/TextField';
import React from 'react';
import {
  belgiumMobilePhoneNumberFormatter,
  BelgiumMobilePhoneNumberValidators,
} from './belgium/BelgiumMobilePhoneNumberValidators';
import {
  danishMobilePhoneNumberFormatter,
  DanishMobilePhoneNumberValidators,
} from './denmark/DanishMobilePhoneNumberValidators';
import {
  finlandMobilePhoneNumberFormatter,
  FinlandMobilePhoneNumberValidators,
} from './finland/FinlandMobilePhoneNumberValidators';
import {
  spainMobilePhoneNumberFormatter,
  SpainMobilePhoneNumberValidators,
} from './spain/SpainMobilePhoneNumberValidators';
import {
  unitedKingdomMobilePhoneNumberFormatter,
  UnitedKingdomMobilePhoneNumberValidators,
} from './unitedkingdom/UnitedKingdomMobilePhoneNumberValidators';

const marketAppropriateValidators = (market: Market) => {
  switch (market) {
    case Market.Sweden:
      return SwedishMobilePhoneNumberValidators;
    case Market.Denmark:
      return DanishMobilePhoneNumberValidators;
    case Market.Belgium:
      return BelgiumMobilePhoneNumberValidators;
    case Market.Norway:
      return NorwayMobilePhoneNumberValidators;
    case Market.UnitedKingdom:
      return UnitedKingdomMobilePhoneNumberValidators;
    case Market.Finland:
      return FinlandMobilePhoneNumberValidators;
    case Market.Spain:
      return SpainMobilePhoneNumberValidators;
    default:
      throw new TypeError('Unknown market set');
  }
};

const marketAppropriateFormatter = (market: Market) => {
  switch (market) {
    case Market.Sweden:
      return swedishMobilePhoneNumberFormatter;
    case Market.Denmark:
      return danishMobilePhoneNumberFormatter;
    case Market.Belgium:
      return belgiumMobilePhoneNumberFormatter;
    case Market.Norway:
      return norwayMobilePhoneNumberFormatter;
    case Market.UnitedKingdom:
      return unitedKingdomMobilePhoneNumberFormatter;
    case Market.Finland:
      return finlandMobilePhoneNumberFormatter;
    case Market.Spain:
      return spainMobilePhoneNumberFormatter;
    default:
      throw new TypeError('Unknown market set');
  }
};

interface MobilePhoneNumberFieldComponentProps extends TextFieldProps {
  /**
   * Handy for e2e testing.
   */
  'data-test'?: string;
  market: Market;
}

const MobilePhoneNumberFieldComponentInner: React.FunctionComponent<MobilePhoneNumberFieldComponentProps> = (props) => (
  <TextField
    data-test={props['data-test'] ? props['data-test'] : 'mobile-phone-field'}
    type="tel"
    label={<LocalizedMessage id="mobileInputLabel" />}
    requiredErrorText={props.required ? <LocalizedMessage id="missingRequiredPhoneNumberError" /> : undefined}
    validators={marketAppropriateValidators(props.market)}
    transformBlurValue={marketAppropriateFormatter(props.market)}
    {...props}
  />
);

export const Component = withLocalization('standard-components/MobilePhoneNumberField')(
  MobilePhoneNumberFieldComponentInner
);
