import type { CategoryName } from '@client/constants/categories';
import type { ServiceId, SupplierId, SupplierService, SupplierWithServices } from '@client/models/supplier';
import type { CancellationOutcome } from '@client/modules/cancellation/models';
import { successfulCancellationOutcomes } from '@client/modules/cancellation/models';
import { appPlatform, getMixpanel } from '@client/tracking/mixpanel';
import type { CancellationMethodType } from '@client/models/service';
import type { TrackingButtonName } from '@client/tracking/mixpanel-constants';
import { TrackingFeedbackType } from '@client/tracking/mixpanel-constants';
import {
  EventNames,
  OUTCOME_FAILED,
  TrackingButtonLocation,
  TrackingFeature,
  TrackingPageName,
} from '@client/tracking/mixpanel-constants';
import type { Market, AppType } from '../app-context/constants';
import { methodToTrackingSubscriptionMethod } from '@client/tracking/mixpanel-events';

export function trackRatedCompletedCancellation(
  pageName: TrackingPageName,
  merchantName: string,
  category: string,
  rating: number,
  inputValue: string,
  outcome?: CancellationOutcome
) {
  getMixpanel().track(EventNames.RATED_EXPERIENCE, {
    Feature: TrackingFeature.Cancellation,
    Category: category,
    'Supplier name': merchantName,
    Page: pageName,
    'Feedback type': 'Completion',
    Value: inputValue,
    'mp_.rating': rating,
    'Action outcome': outcome,
    'Action successful': outcome && successfulCancellationOutcomes.includes(outcome),
  });
}

export function sendClickedButtonMixpanelEvent(
  page: TrackingPageName,
  merchantName: string,
  button: TrackingButtonName,
  insightCardName?: string
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Card: insightCardName,
    Page: page,
    Button: button,
    supplierName: merchantName,
  });
}

export function trackClickedCancelContractButton(
  pageName: TrackingPageName,
  merchantId: string,
  merchantName: string,
  category: string,
  contractId: string,
  serviceName: string,
  subscriptionSource: string,
  button: TrackingButtonName,
  location: TrackingButtonLocation,
  serviceId: string,
  method: CancellationMethodType
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Method: methodToTrackingSubscriptionMethod(method),
    Page: pageName,
    'Supplier Id': merchantId,
    'Supplier name': merchantName,
    Category: category,
    'Contract id': contractId,
    'Service id': serviceId,
    'Service name': serviceName,
    'Subscription source': subscriptionSource,
    Button: button,
    Location: location,
  });
}

export function trackViewCancellationTermsDialog(
  pageName: TrackingPageName,
  merchantId: string,
  merchantName: string,
  category: string,
  contractId: string,
  serviceName: string,
  subscriptionSource: string,
  button: TrackingButtonName,
  serviceId: string
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Page: pageName,
    'Supplier Id': merchantId,
    'Supplier name': merchantName,
    Category: category,
    'Contract id': contractId,
    'Service id': serviceId,
    'Service name': serviceName,
    'Subscription source': subscriptionSource,
    Button: button,
  });
}
export function trackViewedCancelServiceTermsDialog(
  pageName: TrackingPageName,
  merchantId: string,
  merchantName: string,
  serviceId: string,
  serviceName: string,
  category: string,
  button: TrackingButtonName
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Page: pageName,
    'Supplier Id': merchantId,
    'Supplier name': merchantName,
    Category: category,
    'Service id': serviceId,
    'Service name': serviceName,
    Button: button,
  });
}

export function trackClickedCancelServiceButton(
  pageName: TrackingPageName,
  merchantId: string,
  merchantName: string,
  serviceId: string,
  serviceName: string,
  category: string,
  button: TrackingButtonName,
  location: TrackingButtonLocation,
  method?: CancellationMethodType
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Method: methodToTrackingSubscriptionMethod(method),
    Page: pageName,
    'Supplier Id': merchantId,
    'Supplier name': merchantName,
    Category: category,
    'Service id': serviceId,
    'Service name': serviceName,
    Button: button,
    Location: location,
  });
}

export function trackRatedCancelServiceExperience(
  pageName: TrackingPageName,
  rating: number,
  merchant: SupplierWithServices,
  service: SupplierService
) {
  getMixpanel().track(EventNames.RATED_EXPERIENCE, {
    Feature: TrackingFeature.Cancellation,
    Method: methodToTrackingSubscriptionMethod(service.cancellationMethod.type),
    Page: pageName,
    'mp_.rating': rating,
    'Feedback type': TrackingFeedbackType.Guide,
    'Supplier Id': merchant.id,
    'Supplier name': merchant.name,
    Category: service.category.name,
    'Service id': service.id,
    'Service name': service.category.name,
  });
}

export function sentCancellationOfServiceFailedMixpanelEvent(merchantId: string, serviceId: string) {
  getMixpanel().track(EventNames.SUBMITTED_FORM, {
    Feature: TrackingFeature.Cancellation,
    Page: 'Cancel service form',
    Outcome: OUTCOME_FAILED,
    'Supplier Id': merchantId,
    'Service id': serviceId,
  });
}

export function sentCancellationOfServiceFinishTrackingEvent(merchantId: string, serviceId: string) {
  sentCancellationOfServiceFinishMixpanelEvent(merchantId, serviceId);
}

function sentCancellationOfServiceFinishMixpanelEvent(merchantId: string, serviceId: string) {
  getMixpanel().track(EventNames.SUBMITTED_FORM, {
    Feature: TrackingFeature.Cancellation,
    // TODO: Hard to get Method in here without refactoring components
    'Supplier Id': merchantId,
    'Service id': serviceId,
  });
}

export function trackCancellationSuccessfulButtonClick(
  buttonName: TrackingButtonName,
  merchantId: SupplierId,
  merchantName: string,
  serviceId: ServiceId,
  serviceName: string,
  categoryName: CategoryName | string,
  market: Market,
  appType: AppType,
  method: CancellationMethodType | undefined,
  amountSavedPerYear?: number
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Page: TrackingPageName.SUCCESS_SCREEN,
    Location: TrackingButtonLocation.BOTTOM_CENTER,
    Method: methodToTrackingSubscriptionMethod(method),
    'App platform': appPlatform(),

    Button: buttonName,
    'Supplier id': merchantId,
    'Supplier name': merchantName,
    'Service id': serviceId,
    'Service name': serviceName,
    Category: categoryName,
    Market: market,
    Platform: appType,
    'Amount saved per year': amountSavedPerYear,
  });
}
