import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { SubscriptionActionType } from '@client/models/subscription';
import type { SupplierService } from '@client/models/supplier';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardRowLink } from '@minna-technologies/minna-ui/components/CardRowLink';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CategoryIcon } from '../CategoryIcon/CategoryIcon';

const useStyles = makeStyles({
  headline: {
    marginTop: '48px',
  },
  description: {
    margin: '10px 0 14px 0',
  },
  card: {
    padding: '0',
    marginBottom: '16px',
  },
});

export interface SelectServicesPageInnerProps {
  services: SupplierService[];
  supplierName?: string;
  action: SubscriptionActionType;
  onServiceClicked(service: SupplierService): void;
}

const SelectServicesPageInner: React.FunctionComponent<SelectServicesPageInnerProps> = ({
  services,
  supplierName,
  action,
  onServiceClicked,
}: SelectServicesPageInnerProps) => {
  const { colors } = useTheme();
  const classes = useStyles();
  const history = useHistory();

  const maybeHandleBackClick =
    history.length > 1
      ? () => {
          history.goBack();
        }
      : undefined;

  let titleMessageId = '';
  let subtitleMessageId = '';
  // Separate messages per action type
  switch (action) {
    case SubscriptionActionType.Cancel:
      titleMessageId = 'servicesSectionTitleCancel';
      subtitleMessageId = 'servicesSectionSubtitleCancel';
      break;
    case SubscriptionActionType.ChangePayment:
      titleMessageId = 'servicesSectionTitleChangePayment';
      subtitleMessageId = 'servicesSectionSubtitleChangePayment';
      break;

    default:
      break;
  }

  return (
    <MultiDeviceLayout footer={<FooterNavigation onBackClick={maybeHandleBackClick} />} hasTopNavigation={false}>
      <Headline2 className={classes.headline}>
        <LocalizedMessage id={titleMessageId} values={{ supplierName: supplierName }} />
      </Headline2>
      <Body color={colors.textOn.surfaceSubdued} className={classes.description}>
        <LocalizedMessage id={subtitleMessageId} values={{ supplierName: supplierName }} />
      </Body>
      {services
        .sort((service1, service2) => service1.id.localeCompare(service2.id))
        .map((service: SupplierService, index: number) => (
          <Card key={index} className={classes.card}>
            <CardRowLink
              data-test="select-service-card"
              icon={<CategoryIcon categoryName={service.category.name} nativeColor={colors.textOn.surface} />}
              body={service.name}
              onClick={() => onServiceClicked(service)}
            />
          </Card>
        ))}
    </MultiDeviceLayout>
  );
};

export const SelectServicesPageComponent = withLocalization('genericActions/SelectServicesPage')(
  SelectServicesPageInner
);
