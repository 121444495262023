import { AppType } from '@client/modules/app-context/constants';
import Cookies from 'js-cookie';

/**
 * This method determines if the UpdateBankAppModal should be opened.
 * If the savingsAfterCancellationSupported cookie is set then Cancel and Save
 * should be supported and we shouldn't show the modal.
 * Swedbank has stopped sending this cookie. Probably by accident. We should always support cancel and save for swedbank,
 * therefor we should NOT open the update bank modal.
 */
export function shouldOpenUpdateBankAppModal(appType: AppType) {
  if (appType == AppType.Swedbank) {
    return false;
  } else {
    return Cookies.get('savingsAfterCancellationSupported') !== 'true';
  }
}

export const DELAY_MS_BEFORE_OPEN_UPDATE_BANK_APP_MODAL = 2000;
