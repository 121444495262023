import { LocalizationProvider } from '@client/internationalization/LocalizationProvider';
import type { AppContext } from '@client/modules/app-context';
import { AppType, Market } from '@client/modules/app-context/constants';
import { LazyLoadedDeveloperToolsBar } from '@client/modules/developer-tools/LazyLoadedDeveloperToolsBar';
import { ZendeskDialogContainer } from '@client/modules/Zendesk/ZendeskDialog';
import { blueBaseTheme } from '@client/styles/themes/blueBaseTheme';
import { useAppTheme } from '@client/utils/hooks/use-app-theme';
import { MuiThemeProvider as MuiThemeProviderNext } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import type { History } from 'history';
// @ts-ignore
import { MuiThemeProvider } from 'material-ui';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { RootErrorRenderer } from './components/RootErrorRenderer';
import { EndUserRouter } from './EndUserRouter';
import { persistor, store } from './state/store';

interface AppProps {
  history: History<any>;
  appContext: AppContext;
}

export const EndUserApp: React.FC<AppProps> = ({ history, appContext }) => {
  return (
    <Sentry.ErrorBoundary fallback={RootErrorRenderer}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <EndUserAppWithTheming history={history} appContext={appContext} />
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

const EndUserAppWithTheming: React.FC<AppProps> = ({ history, appContext }) => {
  const theme = useAppTheme();

  return (
    <MuiThemeProvider muiTheme={blueBaseTheme}>
      <MuiThemeProviderNext theme={theme}>
        <>
          <LazyLoadedDeveloperToolsBar />
          <LocalizationProvider>
            <>
              <Helmet
                defaultTitle={appContext.displayName}
                titleTemplate={`%s - ${appContext.displayName}`}
                // @ts-ignore
                bodyAttributes={{ style: `background: ${theme.colors.white80}` }}
              >
                <MetaTags appContext={appContext} />
              </Helmet>

              <div id="app">
                <EndUserRouter history={history} />
              </div>
              <ZendeskDialogContainer />
            </>
          </LocalizationProvider>
        </>
      </MuiThemeProviderNext>
    </MuiThemeProvider>
  );
};

export interface MetaTagsProps {
  appContext: AppContext;
}

export const MetaTags: React.FunctionComponent<MetaTagsProps> = ({ appContext }) => {
  if (appContext.market === Market.Sweden && appContext.appType === AppType.Standalone) {
    return (
      <meta
        name="description"
        content="Mina Tjänster är en app som håller koll på dina abonnemang - snabbt, enkelt och helt gratis."
      />
    );
  }

  return null;
};
