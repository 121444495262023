import PropTypes from 'prop-types';
import React from 'react';
import {CircularProgress} from 'material-ui';
import styles from './styles.scss';
import {Body} from '@minna-technologies/minna-ui/components/Typography/Body';
import {LocalizedMessage} from '@client/internationalization';
import {PrimaryButton} from "@minna-technologies/minna-ui/components/Buttons/PrimaryButton";

const MessageWithProgress = ({children}) => (
  <div className={ styles.box }>
    <Body>{children}</Body>
    <CircularProgress />
  </div>
);

MessageWithProgress.propTypes = {
  children: PropTypes.node.isRequired
};

const MessageWithRetryButton = ({children, onRetryClick}) => (
  <div className={ styles.box }>
    <Body>{children}</Body>
    <PrimaryButton
      onClick={ onRetryClick }
      label={ <LocalizedMessage id="tryAgainOnSigningErrorButton" /> }
    />
  </div>
);

MessageWithRetryButton.propTypes = {
  children: PropTypes.node.isRequired,
  onRetryClick: PropTypes.func.isRequired
};

const BankIdStatus = ({status, onRetryClick}) => {
  const startApp = (
    <MessageWithProgress><LocalizedMessage id="startBankIdSigningMessage" /></MessageWithProgress>
  );

  switch (status.type) {
    case 'Connecting':
      return (
        <MessageWithProgress><LocalizedMessage id="connectingSigningMessage" /></MessageWithProgress>
      );

    case 'LoginStarted':
      return startApp;

    case 'TerminationRequestStarted':
      return startApp;

    case 'StartBankIdApplication':
      return startApp;

    case 'BankIdSignResourceRequestStarted':
    return startApp;

    case 'AuthInBankIdApplication':
      return (
        <MessageWithProgress>
          <LocalizedMessage id="inputSecurityCodeSigningMessage" />
        </MessageWithProgress>
      );

    case 'GeneralError':
      return (
        <MessageWithRetryButton onRetryClick={ onRetryClick }>
          <LocalizedMessage id="generalSigningError" />
        </MessageWithRetryButton>
      );

    case 'LoginAborted':
      return (
        <MessageWithRetryButton onRetryClick={ onRetryClick }>
          <LocalizedMessage id="loginInterruptedMessage" />
        </MessageWithRetryButton>
      );

    case 'NegotiationRequestAborted':
    case 'TerminationRequestAborted':
    case 'BankIdSignResourceRequestAborted':
      return (
        <MessageWithRetryButton onRetryClick={ onRetryClick }>
          <LocalizedMessage id="signingInterruptedMessage" />
        </MessageWithRetryButton>
      );

    case 'NegotiationRequestStarted':
      return startApp;

    default:
      return (
        <MessageWithRetryButton onRetryClick={ onRetryClick }>
          <LocalizedMessage id="unexpectedSigningError" />
        </MessageWithRetryButton>
      );
  }
};

BankIdStatus.propTypes = {
  status: PropTypes.object.isRequired,
  onRetryClick: PropTypes.func.isRequired
};

export default BankIdStatus;
