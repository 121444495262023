import { postMessage } from '@client/modules/app-integration/webview-integration/send-event';
import isEmpty from 'lodash/isEmpty';

/**
 * Open an url in the phone's browser
 *
 * @param url - The url path to be opened
 * @param event - The event that triggered the link to open
 */
export function openInBrowser(url: string, event?: Event): boolean {
  if (isEmpty(url)) {
    return true;
  } else {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    return postMessage('OPEN_LINK', { url });
  }
}
