import Loadable from 'react-loadable';
import LoadingPage from '../../../../fetching/LoadingPage';

export const LazyLoadedUserTermsPageNo = Loadable<any, any>({
  loader: async () =>
    import(/* webpackChunkName: "sb1-user-terms-en" */ './LocalizedSb1UserTermsPage').then(
      (module) => module.LocalizedSb1UserTermsPageNo
    ),
  loading: LoadingPage,
});
