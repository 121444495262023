import { LocalizedMessage } from '@client/internationalization';
import type { AmountWithCurrency } from '@client/models/subscription';
import { MessageToUser } from '@client/modules/cancellation/CancellationOutcome/components/MessageToUser';
import { InsightCancellationOutcome } from '@client/modules/InsightsPage/models';
import { makeStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

interface Props {
  serviceProviderName: string;
  messageFromProvider?: string;
  outcome: InsightCancellationOutcome;
  yearlySavings?: AmountWithCurrency;
}

const useStyles = makeStyles({
  sectionMargin: {
    margin: '0 0 8px',
  },
});

export const SuccessfulCancellationContent: React.FunctionComponent<Props> = (
  { serviceProviderName, messageFromProvider, outcome, yearlySavings },
  { localizeCostWithCurrency }
) => {
  const classes = useStyles();
  const { colors } = useTheme();

  return (
    <>
      {messageFromProvider && (
        <MessageToUser messageToUser={messageFromProvider} serviceProviderName={serviceProviderName} />
      )}
      {!isEmpty(yearlySavings) && outcome === InsightCancellationOutcome.Cancelled && (
        <>
          {messageFromProvider && <Divider />}
          <div className={classes.sectionMargin}>
            <Headline2 color={colors.textOn.surface} align="center">
              <LocalizedMessage id="yoWillNowSave" />
            </Headline2>
            <Headline2 align="center" color={colors.action.positive} style={{ marginTop: '8px' }}>
              <LocalizedMessage id="amountPerYear" values={{ cost: localizeCostWithCurrency(yearlySavings) }} />
            </Headline2>
          </div>
        </>
      )}
    </>
  );
};

SuccessfulCancellationContent.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
};
