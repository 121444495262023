import { Sentry } from '@client/assets/js/sentry';
import type { AppContext } from '@client/modules/app-context';
import { isChangePlanEnabled } from '@client/modules/app-context';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import type { History } from 'history';

export const onRequestChangePlan = (
  appContext: AppContext,
  contract: Contract,
  subscription: Subscription,
  history: History
) => {
  if (!isChangePlanEnabled(appContext.features.actionFeatures)) {
    Sentry.captureMessage(
      'Invalid feature toggling: on change plan click in container, assertion failed that change plan feature is enabled',
      {
        extra: { features: appContext.features },
      }
    );

    return;
  }

  history.push(urls.subscriptionContractChangePlanIntelligentGuidePageFun(subscription.id, contract.id));
};
