import type { CancellationId } from '@client/models/cancellation';
import type { Locale } from '@client/modules/app-context/constants';
import { languageCodeFromLocale } from '@client/modules/app-context/constants';
import type { ContractId, SubscriptionId } from '@client/modules/subscription/types';
import type { SubscriptionActionType } from './models/subscription';

export const landingPage = '/';

export const settingsRoutePrefix = '/settings';
export const settingsPage = settingsRoutePrefix;
export const userSettingsPage = `${settingsRoutePrefix}/account`;
export const notificationSettingsPage = `${settingsRoutePrefix}/notifications`;
export const unsubscribeEmailPath = (emailUserId: string) =>
  `${notificationSettingsPage}/unsubscribe-email/${emailUserId}`;
export const profilingSettingsPage = `${settingsRoutePrefix}/recommendations`;
export const viewGdprDataPage = `${settingsRoutePrefix}/gdpr-data`;
export const connectedAccountsPage = `${settingsRoutePrefix}/connected-accounts`;

export type SubscriptionRoute = (subscriptionId: SubscriptionId) => string;
export type SubscriptionContractRoute = (subscriptionId: SubscriptionId, contractId: ContractId) => string;

export const loginPage = '/sign-in';
export const createAccountPage = '/register';
export const resetPasswordPage = '/reset-password';
export const loginStatusPage = '/sign-in-status';

export const onboardingRoutePrefix = '/signup';
export const onboardingSetEmailPage = `${onboardingRoutePrefix}/email`;
export const onboardingTermsAndConsentPage = `${onboardingRoutePrefix}/terms`;
export const pushNotificationOnboarding = `${onboardingRoutePrefix}/push-notifications`;

export const cancellationDetailsPage = (cancellationId: CancellationId) => `/cancellations/${cancellationId}`;
export const subscriptionCancelBasePath: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/cancel`;
export const subscriptionCancelPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  subscriptionCancelBasePath(subscriptionId, contractId);
// Note: You should probably not use this path directly. Use onCancel or goToCancellationPage functions instead
export const terminationFormPage: SubscriptionContractRoute = subscriptionCancelBasePath;
export const terminationSignPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionCancelBasePath(subscriptionId, contractId)}/sign`;
export const terminationSentPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionCancelBasePath(subscriptionId, contractId)}/sent`;
export const terminationInstructionsPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionCancelBasePath(subscriptionId, contractId)}/instructions`;
export const cancellationGuidePage = (subscriptionId: SubscriptionId, contractId: ContractId, userMessage = false) =>
  userMessage
    ? `${subscriptionCancelBasePath(subscriptionId, contractId)}/guide?userMessage=true`
    : `${subscriptionCancelBasePath(subscriptionId, contractId)}/guide`;
export const cancellationGuideDonePage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionCancelBasePath(subscriptionId, contractId)}/guide-done`;
export const whyDidYouCancelPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionCancelBasePath(subscriptionId, contractId)}/reason`;

export const saveDesk: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/new-offer`;
export const saveDeskStartPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${saveDesk(subscriptionId, contractId)}/start`;
export const saveDeskPainChoicesPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${saveDesk(subscriptionId, contractId)}/feedback`;
export const saveDeskDetailsPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${saveDesk(subscriptionId, contractId)}/details`;
export const saveDeskConfirmedPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${saveDesk(subscriptionId, contractId)}/confirmed`;
export const saveDeskOrderSentPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${saveDesk(subscriptionId, contractId)}/order-sent`;
export const saveDeskDetailsPageVariant: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${saveDesk(subscriptionId, contractId)}/order-details`;

export const contractDetailsPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/details`;

export const merchantRoute = '/merchants/:merchantId';
export const serviceRoute = `${merchantRoute}/services/:serviceId`;
export const cancelServiceRoute = `${serviceRoute}/cancel`;
export const signCancelServiceRoute = `${cancelServiceRoute}/sign`;
export const sentCancelServiceRoute = `${cancelServiceRoute}/sent`;
export const merchantRouteFun = (merchantId: string) => `/merchants/${merchantId}`;
export const serviceRouteFun = (merchantId: string, serviceId: string) =>
  `${merchantRouteFun(merchantId)}/services/${serviceId}`;
export const cancelServiceRouteFun = (merchantId: string, serviceId: string) =>
  `${serviceRouteFun(merchantId, serviceId)}/cancel`;
export const signCancelServiceRouteFun = (merchantId: string, serviceId: string) =>
  `${cancelServiceRouteFun(merchantId, serviceId)}/sign`;
export const sentCancelServiceRouteFun = (merchantId: string, serviceId: string) =>
  `${cancelServiceRouteFun(merchantId, serviceId)}/sent`;

export const cancelServiceIntelligentGuidePage = `${cancelServiceRoute}/guide`;
export const cancelServiceIntelligentGuideDonePage = `${cancelServiceRoute}/guide-done`;
export const cancelServiceIntelligentGuidePageFun = (merchantId: string, serviceId: string, userMessage = false) =>
  userMessage
    ? `${cancelServiceRouteFun(merchantId, serviceId)}/guide?userMessage=true`
    : `${cancelServiceRouteFun(merchantId, serviceId)}/guide`;
export const cancelServiceIntelligentGuideDonePageFun: SubscriptionContractRoute = (
  merchantId: string,
  serviceId: string
) => `${cancelServiceRouteFun(merchantId, serviceId)}/guide-done`;

export const subscriptionRoutePrefix = '/subscription';
export const subscriptionContractRoutePrefix = 'contract';
export const unresolvedSubscriptionGuideBasePath = '/unresolved-subscription-guide';
export const subscriptionBasePath: SubscriptionRoute = (subscriptionId) =>
  `${subscriptionRoutePrefix}/${subscriptionId}`;
export const subscriptionContractRoute: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionBasePath(subscriptionId)}/${subscriptionContractRoutePrefix}/${contractId}`;
export const subscriptionPage: SubscriptionRoute = (subscriptionId) =>
  `${subscriptionBasePath(subscriptionId)}/details`;
export const subscriptionPaymentHistoryPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionBasePath(subscriptionId)}/${subscriptionContractRoutePrefix}/${contractId}/history`;
export const subscriptionSettingsPage: SubscriptionRoute = (subscriptionId) =>
  `${subscriptionBasePath(subscriptionId)}/settings`;
export const selectServicesPage: SubscriptionRoute = (subscriptionId) =>
  `${subscriptionBasePath(subscriptionId)}/select-services`;
export const subscriptionUpdateCostPage: SubscriptionRoute = (subscriptionId) =>
  `${subscriptionBasePath(subscriptionId)}/edit-cost`;

export const orderedSubscriptionDetailsPage = (orderId: string) => `/ordered-subscription/${orderId}`;
export const orderedSubscriptionTermsPage = (orderId: string) => `${orderedSubscriptionDetailsPage(orderId)}/terms`;

export const unresolvedSubscriptionGuidePath: SubscriptionRoute = (subscriptionId) =>
  `${unresolvedSubscriptionGuideBasePath}/${subscriptionId}`;

export const overviewPage = '/overview';
export const insightsPage = '/insights';
export const syncLoadingPage = '/synced-overview';
export const morePage = '/more';
export const menuPage = '/menu';
export const cancellationListPage = '/cancellations';

export const manualAddSubscriptionPage = '/add-subscription';
export const manualSubscriptionPricingPage = '/add-subscription-details';
export const csatNps = '/csat';
export const faqPage = (zendeskBaseUrl: string, locale: Locale) =>
  `${zendeskBaseUrl}/hc/${languageCodeFromLocale(locale)}`;

export const feedbackPage = '/feedback';

export const conditionsAndPoliciesMenu = '/conditions-and-policies';
export const conditionsAndPoliciesOnboardingPage = `${conditionsAndPoliciesMenu}/public`;
export const userTermsPage = `${conditionsAndPoliciesMenu}/terms-and-conditions`;
export const privacyPolicyPage = `${conditionsAndPoliciesMenu}/privacy-policy`;
export const securityPage = `${conditionsAndPoliciesMenu}/security`;
export const cookiesPage = `${conditionsAndPoliciesMenu}/cookies`;

export const userDeletePage = '/remove-account';
export const userDeleteSuccessPage = '/account-removed';
export const loggedOutPage = '/signed-out'; // TODO rename constant
export const signoutSuccessPage = '/sign-out-success';

export const errorPage = '/error';
export const psd2ErrorPage = '/error-psd2';

// This route is in Swedish since it affects SEO for Standalone. If we would launch this feature in other markets then we should translate.
export const externalContactUs = 'https://minatjanster.se/kundtjanst';

// These routes are in Swedish since they affect SEO for Standalone. If we would launch this feature in other markets then we should translate.
export const howToCancelListPage = '/sag-upp';
export const howToCancelServiceProviderPage = (serviceProviderNameSlug: string) =>
  `${howToCancelListPage}/${serviceProviderNameSlug}`;

export const onboard = '/onboard';
export const onboardBankSync = `${onboard}/bank-sync`;
export const onboardConsent = `${onboard}/consent`;
export const onboardBankDiscoveryWorking = `${onboard}/loading`;

export const onboardingPsd2 = '/oauth-init';
export const onboardingPsd2Consent = `${onboardingPsd2}/consent`;
export const onboardingPsd2ConsentExpired = `${onboardingPsd2}/renew-consent`;
export const onboardingPsd2Callback = '/oauth-callback';
export const onboardingPsd2CallbackWithoutCode = '/oauth-callback-without-code';

export const monthlyChecklist = '/checklist';
export const recommendation = '/recommendation';
export const addon = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `/bundled-subscription/${subscriptionId}/${contractId}`;

export const quickActions = '/quick';
export const quickActionsCancel = '/quick/cancel';

export const subscriptionSearch = `/subscription-search`;
export const subscriptionSearchWithAction = (action: SubscriptionActionType) =>
  `${subscriptionSearch}?subscriptionAction=${action}`;
export const subscriptionHub = '/subscription-hub';
export const selectService = '/select-service';
export const merchantGuideError = `/merchant-guide/error`;
export const changePaymentIntelligentGuide = `${serviceRoute}/change-payment`;
export const changePaymentIntelligentGuidePage = `${serviceRoute}/change-payment/guide`;
export const changePaymentIntelligentGuideDonePage = `${serviceRoute}/change-payment/guide-done`;

export const changePaymentIntelligentGuidePageFun = (merchantId: string, serviceId: string) =>
  `${serviceRouteFun(merchantId, serviceId)}/change-payment/guide`;
export const changePaymentIntelligentGuideDonePageFun = (merchantId: string, serviceId: string) =>
  `${serviceRouteFun(merchantId, serviceId)}/change-payment/guide-done`;

export const subscriptionContractChangePlanBasePath = (subscriptionId: SubscriptionId, contractId: ContractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/change-plan`;
export const subscriptionContractChangePlanIntelligentGuidePageFun = (
  subscriptionId: SubscriptionId,
  contractId: ContractId
) => `${subscriptionContractChangePlanBasePath(subscriptionId, contractId)}/guide`;
export const subscriptionContractChangePlanIntelligentGuideDonePageFun = (
  subscriptionId: SubscriptionId,
  contractId: ContractId
) => `${subscriptionContractChangePlanBasePath(subscriptionId, contractId)}/guide-done`;

export const changePlanIntelligentGuide = `${serviceRoute}/change-plan`;
export const changePlanIntelligentGuidePage = `${serviceRoute}/change-plan/guide`;
export const changePlanIntelligentGuideDonePage = `${serviceRoute}/change-plan/guide-done`;

export const changePlanIntelligentGuidePageFun = (merchantId: string, serviceId: string) =>
  `${serviceRouteFun(merchantId, serviceId)}/change-plan/guide`;
export const changePlanIntelligentGuideDonePageFun = (merchantId: string, serviceId: string) =>
  `${serviceRouteFun(merchantId, serviceId)}/change-plan/guide-done`;

export const subscriptionPauseBasePath: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/pause`;
export const subscriptionResumeBasePath: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionContractRoute(subscriptionId, contractId)}/resume`;

export const subscriptionPausePage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  subscriptionPauseBasePath(subscriptionId, contractId);
export const subscriptionPauseInProgressPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionPauseBasePath(subscriptionId, contractId)}/in-progress`;
export const subscriptionPauseSuccessPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionPauseBasePath(subscriptionId, contractId)}/success`;
export const subscriptionPauseErrorPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionPauseBasePath(subscriptionId, contractId)}/error`;
export const subscriptionPauseUserNotFoundErrorPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionPauseBasePath(subscriptionId, contractId)}/error-user-not-found`;

export const subscriptionResumePage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  subscriptionResumeBasePath(subscriptionId, contractId);
export const subscriptionResumeInProgressPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionResumeBasePath(subscriptionId, contractId)}/in-progress`;
export const subscriptionResumeSuccessPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionResumeBasePath(subscriptionId, contractId)}/success`;
export const subscriptionResumeErrorPage: SubscriptionContractRoute = (subscriptionId, contractId) =>
  `${subscriptionResumeBasePath(subscriptionId, contractId)}/error`;

export const signResourcePage = (
  contractId: ContractId,
  signingId: string,
  resourceToSignId: string,
  navigateToOnSuccess: string
) => `/sign/${contractId}/${signingId}/${resourceToSignId}/${navigateToOnSuccess}`;

export const swedbankRoutes = {
  // TODO: These should all be remapped to english URLs?
  subscriptionPage: `${subscriptionRoutePrefix}/:subscriptionId`,
  notificationPage: '/notifikationsinstallningar',
  userTermsPage: '/anvandarvillkor',
  privacyPolicyPage: '/integritetspolicy',
  frequentlyAskedQuestionsPage: '/vanliga-fragor',
};
