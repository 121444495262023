import last from 'lodash/last';
import { CHAT_CLOSED, CHAT_MINIMIZED, CHAT_ONLY, CHAT_OPEN } from './zendesk';
import { CHAT_ROUTE } from './zendesk-action-types';

export const selectorDialogIsOpen = (state: any) => state.zendesk.dialogOpenState === CHAT_OPEN;
export const selectorDialogIsMinimized = (state: any) => state.zendesk.dialogOpenState === CHAT_MINIMIZED;
export const selectorDialogIsClosed = (state: any) => state.zendesk.dialogOpenState === CHAT_CLOSED;
export const selectorCurrentRoute = (state: any) =>
  selectorIsChatOnly(state) ? { route: CHAT_ROUTE } : last(state.zendesk.navigation);
export const selectorIsChatOnly = (state: any) => state.zendesk.featureMode === CHAT_ONLY;
