/**
 * This code is shared with us from Sb1's module for mobilbank-communication. This is version 5.5.1.
 * Original file path is: package/lib-es/util/send-event.js
 */

/**
 * Creates an empty iFrame, and uses the URL to send data to native iOS.
 *
 * @param [payload] - Payload to be sent as JSON.
 */
export function sendIosEvent(payload: { action: string; url?: string; data?: any }) {
  const src = 'sb1mobilbank://event/?' + encodeURIComponent(JSON.stringify(payload));
  let iframe: HTMLIFrameElement | null = document.createElement('iframe');
  iframe.setAttribute('src', src);
  document.documentElement.appendChild(iframe);
  // @ts-ignore
  iframe.parentNode.removeChild(iframe);
  iframe = null;
}

export function sendAndroidEvent(eventId: string, stringPayload?: string) {
  try {
    let _window$Android;
    const stringPayloadWithFallback = stringPayload ? stringPayload : '';
    const params = Array(stringPayloadWithFallback);

    // @ts-ignore
    (_window$Android = window.Android)[eventId].apply(_window$Android, params);
  } catch (e) {
    /* eslint-disable no-console */
    console.warn('window.Android.' + eventId + ' failed: ' + e);
  }
}

export function sendAndroidEventWithPayload(eventId: string, payload: any) {
  // @ts-ignore
  sendAndroidEvent(eventId, JSON.stringify(payload));
}
