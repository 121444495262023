import type { Question } from '@client/models/cancellation-questions';
import { CancellationQuestionInputFieldSize } from '@client/models/cancellation-questions';

export function requiredQuestionLabel(text: string, required: boolean): string {
  if (required) {
    return `${text}*`;
  } else {
    return text;
  }
}

export function inputFieldSizeClass(question: Question, classes: any) {
  switch (question.inputFieldSize) {
    case CancellationQuestionInputFieldSize.Small:
      return classes.small;
    case CancellationQuestionInputFieldSize.Medium:
      return classes.medium;
    case CancellationQuestionInputFieldSize.Large:
      return classes.large;
    default:
      return;
  }
}
