import { withLocalization } from '@client/internationalization';
// @ts-ignore
import Form from '@client/modules/shared-components/form-validation/Form';
import { RadioButton, RadioGroup } from '@client/modules/shared-components/inputs/RadioButton';
import PropTypes from 'prop-types';
import type { FunctionComponent } from 'react';
import React from 'react';

interface RemoveSubscriptionFormProps {
  onChange(value: string): void;
  value: string;
}

const removeReasons = [
  'deleteReasonNeverHadTheSubscription',
  'deleteReasonIsTerminated',
  'deleteReasonSomeoneElsePaysForIt',
  'deleteReasonOther',
];

const RemoveSubscriptionFormComponent: FunctionComponent<RemoveSubscriptionFormProps> = (
  props,
  { localizeMessage }
) => {
  return (
    <Form>
      <RadioGroup name="removeReason" requiredErrorText="you must choose one option">
        {removeReasons.map((r, index) => (
          <RadioButton
            key={index}
            checked={props.value === r}
            value={localizeMessage(r)}
            label={localizeMessage(r)}
            onChange={() => props.onChange(r)}
          />
        ))}
      </RadioGroup>
    </Form>
  );
};

RemoveSubscriptionFormComponent.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const RemoveSubscriptionForm = withLocalization('subscription/WhatsWrongDialog')(
  RemoveSubscriptionFormComponent
);
