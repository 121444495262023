import { Sentry } from '@client/assets/js/sentry';
import {
  persistSearchTerm,
  selectorSearchTerm,
  setSelectedAction,
  setSelectedSupplier,
} from '@client/ducks/search-entrypoint';
import { selectorSearchPageExplored, setUserVisitedPage } from '@client/ducks/user';
import { useLocalization } from '@client/internationalization';
import { SubscriptionActionType } from '@client/models/subscription';
import type { SupplierWithServices } from '@client/models/supplier';
import { fetchSubscriptionSearchSuppliers, fetchSubscriptionSearchTopSuppliers } from '@client/models/supplier';
import { saveUserViewedSearchPage } from '@client/models/user';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { onRequestCancellationOfService } from '@client/modules/cancellation/onCancelService';
import { onChangePaymentOfService } from '@client/modules/changePayment/onChangePaymentService';
import { HowDoesItWorkLink } from '@client/modules/subscription-search/SubscriptionSearchPage/HowDoesItWorkLink';
import {
  getActionFromQuery,
  getLocalisationScopeFromAction,
} from '@client/modules/subscription-search/SubscriptionSearchPage/logic';
import {
  trackClickedBackButton,
  trackClickedMerchantAction,
  trackIntroDrawerClosed,
  trackSelectedTopMerchant,
} from '@client/modules/subscription-search/SubscriptionSearchPage/mixpanel';
import { TopMerchantsList } from '@client/modules/subscription-search/SubscriptionSearchPage/TopMerchants';
import * as urls from '@client/routes';
import type { TrackingFeature } from '@client/tracking/mixpanel-constants';
import { subscriptionActionTypeToTrackingFeature as toTrackingFeature } from '@client/tracking/mixpanel-constants';
import { navigateToMainPage } from '@client/utils/navigation';
import makeStyles from '@material-ui/styles/makeStyles';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IntroDrawer } from './IntroDrawer';
import { MerchantSearchContainer } from './MerchantSearch';

const useStyles = makeStyles({
  introText: {
    padding: '0 8px 32px 8px',
  },
  headline: {
    marginBottom: '16px',
    marginLeft: '8px',
  },
});

export const SubscriptionSearchPage = () => {
  const history = useHistory();
  const [merchants, setMerchants] = useState<SupplierWithServices[]>([]);
  const [topMerchants, setTopMerchants] = useState<SupplierWithServices[]>([]);
  const [introDrawerOpen, setIntroDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const appContext = useSelector(selectorAppContext);
  const viewedSearchPage = useSelector(selectorSearchPageExplored);
  const persistedSearchTerm = useSelector(selectorSearchTerm);
  const query: URLSearchParams = new URLSearchParams(window.location.search);
  const action = getActionFromQuery(query);
  const scope = getLocalisationScopeFromAction(action);
  const { localizeMessage } = useLocalization(scope);

  useEffect(() => {
    fetchSubscriptionSearchSuppliers(action)
      .then((merchants: SupplierWithServices[]) => {
        setMerchants(merchants);
      })
      .catch((error: Error) => {
        Sentry.captureMessage(`Could not fetch merchants for subscription search.`, { extra: { error } });
      });
    fetchSubscriptionSearchTopSuppliers(action)
      .then((merchants: SupplierWithServices[]) => {
        setTopMerchants(merchants);
      })
      .catch((error: Error) => {
        Sentry.captureMessage(`Could not fetch merchants for subscription search.`, { extra: { error } });
      });
  }, [action]);

  useEffect(() => {
    if (!viewedSearchPage && action === SubscriptionActionType.Cancel) {
      saveUserViewedSearchPage();
      dispatch(setUserVisitedPage('viewedSearchPageAt', moment().toString()));
      if (appContext.features.searchPageFeatures.showIntroScreen) {
        setIntroDrawerOpen(true);
      }
    }
  }, [dispatch, action, viewedSearchPage, appContext.features.searchPageFeatures.showIntroScreen]);

  const handleSearchedMerchant = (merchantId: string, searchTerm: string): void => {
    const merchant = merchants.find((m) => m.id === merchantId);
    dispatch(persistSearchTerm(searchTerm));
    if (!merchant) throw Error(`Could not find selected merchant with ID ${merchantId}`);

    trackAndMoveToNextStep(merchant, trackClickedMerchantAction);
  };

  const handleTopMerchantClicked = (merchantId: string): void => {
    const merchant = topMerchants.find((m) => m.id === merchantId);
    if (!merchant) throw Error(`Could not find selected top merchant with ID ${merchantId}`);

    trackAndMoveToNextStep(merchant, trackSelectedTopMerchant);
  };

  // We only want to show the back button if there's something to go back to.
  // History.length is 1 if the current page is the only page in the history. In other words, the current page is always in the history.
  const maybeHandleBackClick =
    history.length > 1
      ? () => {
          if (action === SubscriptionActionType.ChangePayment) {
            navigateToMainPage(appContext, history);
          } else {
            trackClickedBackButton(toTrackingFeature(action));
            history.goBack();
          }
        }
      : undefined;

  const trackAndMoveToNextStep = (
    merchant: SupplierWithServices,
    mixpanelFunction: (merchantName: string, merchantId: string, action: TrackingFeature, category?: string) => void
  ) => {
    dispatch(setSelectedSupplier(merchant));
    dispatch(setSelectedAction(action));
    if (merchant.services.length > 1) {
      mixpanelFunction(merchant.id, merchant.name, toTrackingFeature(action));
      history.push(urls.selectService);
    } else {
      const service = merchant.services[0];
      mixpanelFunction(merchant.id, merchant.name, toTrackingFeature(action), service.category.name);
      switch (action) {
        case SubscriptionActionType.ChangePayment:
          onChangePaymentOfService(appContext, merchant, service, history);
          break;
        case SubscriptionActionType.Cancel:
        default:
          onRequestCancellationOfService(appContext, merchant, service, history, dispatch, false);
      }
    }
  };

  const closeIntroDrawer = () => {
    setIntroDrawerOpen(false);
    trackIntroDrawerClosed(toTrackingFeature(action));
  };

  return (
    <MultiDeviceLayout
      title={localizeMessage('pageHeader')}
      footer={<FooterNavigation onBackClick={maybeHandleBackClick} />}
      hasTopNavigation={false}
    >
      <MerchantSearchContainer
        merchants={merchants}
        defaultValue={persistedSearchTerm}
        onMerchantSelected={(merchant, searchTerm) => handleSearchedMerchant(merchant, searchTerm)}
        action={action}
      />
      <Headline3 className={classes.headline}>{localizeMessage('topSearches')}</Headline3>
      <TopMerchantsList topMerchants={topMerchants} onMerchantSelected={handleTopMerchantClicked} />
      <HowDoesItWorkLink action={action} />
      <IntroDrawer open={introDrawerOpen} onClose={closeIntroDrawer} />
    </MultiDeviceLayout>
  );
};
