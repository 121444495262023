import type { ServiceToDisplay } from '@client/models/service';
import { filterRegularServicesToDisplay, filterValueAddedServicesToDisplay } from '@client/models/supplier';
import { SelectableServicesListComponent } from '@client/modules/subscription/components/SelectableServicesList/component';
import { ServiceCard } from '@client/modules/subscription/components/ServiceCard/component';
import type { Subscription } from '@client/modules/subscription/types';
import React from 'react';

export interface SelectableServicesListProps {
  subscription: Subscription;
  services: ServiceToDisplay[];
  setServices(services: ServiceToDisplay[]): void;
}

export const SelectableServicesList: React.FunctionComponent<SelectableServicesListProps> = ({
  subscription,
  services,
  setServices,
}: SelectableServicesListProps) => {
  function onServiceCheck(serviceDisplayed: ServiceToDisplay, isInputChecked: boolean) {
    const updatedService = services.map((service) => {
      if (service.serviceId === serviceDisplayed.serviceId) {
        return { ...serviceDisplayed, selected: isInputChecked };
      } else {
        return service;
      }
    });
    setServices(updatedService);
  }

  const regularServices = filterRegularServicesToDisplay(services).map((service: ServiceToDisplay, index: number) => (
    <ServiceCard key={index} service={service} onServiceCheck={onServiceCheck} contracts={subscription.contracts} />
  ));

  const valueAddedServices = filterValueAddedServicesToDisplay(services).map(
    (service: ServiceToDisplay, index: number) => (
      <ServiceCard key={index} service={service} onServiceCheck={onServiceCheck} contracts={subscription.contracts} />
    )
  );

  return (
    <SelectableServicesListComponent
      services={regularServices}
      valueAddedServices={valueAddedServices?.length ? valueAddedServices : undefined}
      supplierName={subscription?.supplier?.name}
    />
  );
};
