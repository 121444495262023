//@ts-ignore
import { onEnter } from '@client/containers/container-helpers';
import { selectorIsLoading, selectorNotLoggedIn } from '@client/ducks/user';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { branch, compose, renderNothing } from 'recompose';
import { withBaseAppContext } from '../../app-context/react';
import {
  CHAT_ROUTE,
  close,
  navigateBack,
  selectorCurrentRoute,
  selectorDialogIsMinimized,
  selectorDialogIsOpen,
} from '../ducks';
import { open } from '../ducks/zendesk-actions';
import * as chatDuck from '../ducks/zendesk-chat';
import { endChat } from '../ducks/zendesk-chat';
import { ZendeskDialog } from './component';

const mapStateToProps = (state: any) => {
  const props = {
    isOpen: selectorDialogIsOpen(state),
    isMinimized: selectorDialogIsMinimized(state),
    currentRoute: selectorCurrentRoute(state),
  };

  return {
    ...props,
    userIsLoading: selectorIsLoading(state),
    notLoggedIn: selectorNotLoggedIn(state),
  };
};

const mapDispatchToProps = (dispatch: any, { appContext }: any) => {
  return {
    onCloseDialog(shouldEndChat: boolean) {
      if (shouldEndChat) {
        dispatch(endChat());
      }
      dispatch(close());
    },
    onOpenDialog() {
      dispatch(open(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale));
    },
    onNavigateBack() {
      dispatch(navigateBack());
    },
  };
};

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: any) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onCloseDialog: () => {
      const shouldEndChat = get(stateProps, 'currentRoute.route') === CHAT_ROUTE;
      dispatchProps.onCloseDialog(shouldEndChat);
    },
  };
};

export const ZendeskDialogContainer = compose<any, any>(
  withBaseAppContext,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  branch((p: any) => p.userIsLoading, renderNothing),
  branch((p: any) => p.notLoggedIn, renderNothing),
  onEnter((props: any, { store: { dispatch } }: any) => {
    // do not initialize until user is available
    dispatch(chatDuck.initialize());
  })
)(ZendeskDialog);
