import Sentry from '@client/assets/js/sentry';
import { ELECTRICITY_GRID, INSURANCE } from '@client/constants/categories';
import { CancellationMethodType } from '@client/models/service';
import type { SupplierService, SupplierWithServices } from '@client/models/supplier';
import type { AppContext } from '@client/modules/app-context';
import { isCancellationEnabled } from '@client/modules/app-context';
import { CancellationAction } from '@client/modules/cancellation/duck';
import { CancellationConfirmationDialog } from '@client/modules/cancellation/models';
import * as urls from '@client/routes';
import type { History } from 'history';
import type { Dispatch } from 'redux';

export const onRequestCancellationOfService = (
  appContext: AppContext,
  merchant: SupplierWithServices,
  service: SupplierService,
  history: History,
  dispatch: Dispatch,
  suggestOtherActions = true
) => {
  if (!isCancellationEnabled(appContext.features.actionFeatures)) {
    Sentry.captureMessage(
      'Invalid feature toggling: on click cancel service, assertion failed that cancellation feature is enabled',
      {
        extra: { features: appContext.features },
      }
    );

    return;
  }

  const serviceCategoryName = service.category.name;
  const saveDeskIsEnabled = service.saveDeskEnabled && appContext.features.subscriptionFeatures.saveDeskEnabled;

  if (
    suggestOtherActions &&
    isCancellationEnabled(appContext.features.actionFeatures) &&
    service.terminable &&
    saveDeskIsEnabled
  ) {
    Sentry.captureMessage('Save desk not yet supported for cancel service flow');
  }
  onCancelService(appContext, merchant, service, serviceCategoryName, dispatch, history);
};

const onCancelService = (
  appContext: AppContext,
  merchant: SupplierWithServices,
  service: SupplierService,
  serviceCategoryName: string,
  dispatch: Dispatch,
  history: History
) => {
  if (!service.terminable) {
    Sentry.captureMessage('Save desk not yet supported for cancel service flow');
    dispatch(
      CancellationAction.setCancellationConfirmationDialogOpen(
        CancellationConfirmationDialog.NotCancellable,
        undefined,
        undefined,
        service,
        merchant
      )
    );
  } else if (serviceCategoryName === ELECTRICITY_GRID) {
    dispatch(
      CancellationAction.setCancellationConfirmationDialogOpen(
        CancellationConfirmationDialog.ElectricityGrid,
        undefined,
        undefined,
        service,
        merchant
      )
    );
  } else if (serviceCategoryName === INSURANCE) {
    dispatch(
      CancellationAction.setCancellationConfirmationDialogOpen(
        CancellationConfirmationDialog.Insurance,
        undefined,
        undefined,
        service,
        merchant
      )
    );
  } else {
    goToCancelServicePage(merchant, service, history, appContext);
  }
};

export const goToCancelServicePage = (
  merchant: SupplierWithServices,
  service: SupplierService,
  history: History,
  appContext: AppContext
) => {
  const extra = { extra: { market: appContext.market, appType: appContext.appType } };
  switch (service.cancellationMethod.type) {
    case CancellationMethodType.IntelligentGuide:
      history.push(urls.cancelServiceIntelligentGuidePageFun(merchant.id, service.id));
      break;
    case CancellationMethodType.PowerOfAttorney:
      history.push(urls.cancelServiceRouteFun(merchant.id, service.id));
      break;
    case CancellationMethodType.NonCancellableInstructions:
      Sentry.captureMessage(
        'Tried to visit cancellation instructions page for cancel service flow, not yet supported',
        extra
      );
      break;
    default:
      Sentry.captureMessage(
        `Tried to visit cancellation page without finding matching cancellation method ${service.cancellationMethod}`,
        extra
      );
  }
};
