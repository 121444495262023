import { withLocalization } from '@client/internationalization';
import Visible from '@client/shared-components/layout/Visible';
import PropTypes from 'prop-types';
import React from 'react';
import {
  CALL_BACK_FAILURE_ROUTE,
  CALL_BACK_ROUTE,
  CALL_BACK_SUCCESS_ROUTE,
  CALL_US_ROUTE,
  CHAT_ROUTE,
  HELP_CENTER_ROUTE,
} from '../ducks';
import { ZendeskChatContainer } from '../ZendeskChat';
import { Index } from '../ZendeskHelpCenter';
import { ZendeskCallBackContainer } from '../ZendeskHelpCenter/components/CallBack';
import { ZendeskCallBackFailureContainer } from '../ZendeskHelpCenter/components/CallBack/CallBackRequestFailure';
import { ZendeskCallBackSuccessContainer } from '../ZendeskHelpCenter/components/CallBack/CallBackRequestSuccess';
import { ZendeskCallUsContainer } from '../ZendeskHelpCenter/components/CallUs';
import { ZendeskDesktopDialog } from './components/ZendeskDesktopDialog';
import { ZendeskMobileDialog } from './components/ZendeskMobileDialog';

export const ZendeskDialog = withLocalization('zendesk')(
  ({ isOpen, onCloseDialog, currentRoute, onNavigateBack }: any) => {
    const routeMap: any = {};
    routeMap[HELP_CENTER_ROUTE] = <Index currentRoute={currentRoute} />;
    routeMap[CHAT_ROUTE] = <ZendeskChatContainer />;
    routeMap[CALL_US_ROUTE] = <ZendeskCallUsContainer onBackClick={onNavigateBack} />;
    routeMap[CALL_BACK_ROUTE] = <ZendeskCallBackContainer onBackClick={onNavigateBack} />;
    routeMap[CALL_BACK_SUCCESS_ROUTE] = <ZendeskCallBackSuccessContainer />;
    routeMap[CALL_BACK_FAILURE_ROUTE] = <ZendeskCallBackFailureContainer onBackClick={onNavigateBack} />;

    const mainRoute = currentRoute && currentRoute.route.split('/')[0];
    const visibleComponent = mainRoute && routeMap[mainRoute];

    return (
      <div>
        <Visible cssQuery="only screen and (max-height: 649px), only screen and (max-width: 639px)">
          <ZendeskMobileDialog
            onNavigateBack={onNavigateBack}
            isOpen={isOpen}
            onCloseDialog={onCloseDialog}
            visibleComponent={visibleComponent}
          />
        </Visible>
        <Visible cssQuery="only screen and (min-width: 640px) and (min-height: 650px)">
          <ZendeskDesktopDialog
            isOpen={isOpen}
            onNavigateBack={onNavigateBack}
            onCloseDialog={onCloseDialog}
            visibleComponent={visibleComponent}
          />
        </Visible>
      </div>
    );
  }
);

// @ts-ignore
ZendeskDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  currentRoute: PropTypes.object,
  onNavigateBack: PropTypes.func.isRequired,
};
