import { costOverTimeConverter } from '@client/assets/js/interval-converter';
import type { LocalizeDate } from '@client/internationalization';
import type { AmountWithCurrency } from '@client/models/subscription';
import type { Contract, PaymentInterval, Subscription, SubscriptionId } from '@client/modules/subscription/types';
import { ContractState } from '@client/modules/subscription/types';
import { parseDate } from '@client/utils/date';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import some from 'lodash/some';
import moment from 'moment';

export async function fetchSubscription(subscriptionId: SubscriptionId) {
  return axios
    .get(`/api/user/subscriptions/${subscriptionId}`)
    .then((subscriptionResponse) => subscriptionResponse.data);
}

export async function deleteSubscription(subscriptionId: SubscriptionId, reason?: any) {
  return axios.delete(`/api/user/subscriptions/${subscriptionId}`, { params: { reason } });
}

export async function updateContractsOnSubscription(
  subscriptionId: SubscriptionId,
  serviceIdsOfContractsToBeCreated: string[],
  contractsToBeDeleted: any[]
): Promise<Subscription> {
  return axios
    .put(`/api/user/subscriptions/${subscriptionId}/contracts`, {
      serviceIdsOfContractsToBeCreated,
      contractsToBeDeleted,
    })
    .then(({ data }: AxiosResponse<Subscription>) => data);
}

export async function fetchSubscriptions(): Promise<Subscription[]> {
  return axios.get('/api/user/subscriptions').then(({ data }: AxiosResponse<Subscription[]>) => data);
}

export async function createSubscription(
  supplierId: string,
  cost: AmountWithCurrency,
  paymentInterval: PaymentInterval
): Promise<Subscription> {
  return axios
    .post('/api/user/subscriptions', { supplierId, cost, paymentInterval })
    .then(({ data }: AxiosResponse<Subscription>) => data);
}

export async function createSubscriptionAndSupplier(
  supplierName: string,
  cost: AmountWithCurrency,
  paymentInterval: PaymentInterval
): Promise<Subscription> {
  return axios
    .post('/api/user/subscriptions', { supplierName, cost, paymentInterval })
    .then(({ data }: AxiosResponse<Subscription>) => data);
}

export async function updateSubscription(subscription: Subscription): Promise<Subscription> {
  return axios
    .put(`/api/user/subscriptions/${subscription.id}`, {
      cost: subscription.cost,
      paymentInterval: subscription.paymentInterval,
    })
    .then(({ data }: AxiosResponse<Subscription>) => data);
}

export function onlySubscriptionsWithActiveContracts(subscriptions: Subscription[]) {
  return _(subscriptions)
    .filter((subscription) => some(subscription.contracts, { state: ContractState.Active }))
    .value();
}

export function improvableContracts(subscriptions: Subscription[]) {
  return _(subscriptions)
    .flatMap('contracts')
    .filter((contract) => contract.optimizable)
    .value();
}

export const formatNextPaymentDate = (localizeDate: LocalizeDate) => (subscription: Subscription) => {
  if (subscription.nextPaymentDate) {
    const nextPaymentDate = parseDate(subscription.nextPaymentDate);
    if (
      (subscription.paymentInterval.unit === 'year' && subscription.paymentInterval.amount === 1) ||
      moment(nextPaymentDate).year() !== moment().year()
    ) {
      return localizeDate(nextPaymentDate, 'long');
    } else {
      return localizeDate(nextPaymentDate, 'dayAndMonth');
    }
  } else {
    return '-';
  }
};

export async function updateContract(contract: Contract): Promise<Contract> {
  return axios
    .put(`/api/user/contracts/${contract.id}`, {
      contractBindingPeriod: contract.contractBindingPeriod,
      terminationFormData: contract.terminationFormData,
      pauseFormData: contract.pauseFormData,
    })
    .then(({ data }: AxiosResponse<Contract>) => data);
}

export const amountSavedPerYear = (subscription: Subscription) => {
  // generic-actions: Don't calculate if the amount is -1, then it's a placeholder value.
  if (subscription.singleContractId && subscription.cost.amount !== -1) {
    const yearlyCost = costOverTimeConverter(subscription.cost.amount, subscription.paymentInterval, {
      unit: 'year',
      amount: 1,
    });

    return { amount: Math.round((yearlyCost + Number.EPSILON) * 100) / 100, currency: subscription.cost.currency };
  } else {
    return { amount: 0, currency: subscription.cost.currency };
  }
};
