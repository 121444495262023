import PauseButtonIcon from '@client/assets/images/pause-icon.svg';
import { LocalizedMessage } from '@client/internationalization';
import { SubscriptionActionType } from '@client/models/subscription';
import { selectorAppContext } from '@client/modules/app-context/duck';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import { ActionButton, ActionButtonWithIcon } from '@client/shared-components/ActionButton';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { isCancellationEnabled, isChangePlanEnabled, isPauseEnabled } from '../../../../app-context';
import { CancelCrossIcon } from '@minna-technologies/minna-ui/icons/Cancel';
import { ChangePlanIcon } from '@minna-technologies/minna-ui/icons/ChangePlan';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    width: '100%',
  },
});

interface Props {
  contract: Contract;
  subscription: Subscription;
  onButtonClick(action: SubscriptionActionType): void;
  supplierName: string;
}

export const PossibleActionsOnContract = ({ contract, onButtonClick }: Props): JSX.Element => {
  const classes = useStyles();
  const appContext = useSelector(selectorAppContext);
  const features = appContext.features;
  const cancelAvailable = isCancellationEnabled(features.actionFeatures);
  const changePlanAvailable = isChangePlanEnabled(features.actionFeatures);
  const pauseAvailable = isPauseEnabled(features.actionFeatures);
  const pauseActiveAndAvailable = pauseAvailable && contract.pausable;

  const roundActionButtons = (
    <>
      {pauseActiveAndAvailable && (
        <ActionButton
          icon={PauseButtonIcon}
          onClick={() => onButtonClick(SubscriptionActionType.Pause)}
          label={<LocalizedMessage id="pauseIt" />}
        />
      )}
      {cancelAvailable && contract.terminable && (
        <ActionButtonWithIcon
          Icon={CancelCrossIcon}
          label={<LocalizedMessage id="cancelQuickAction" />}
          onClick={() => onButtonClick(SubscriptionActionType.Cancel)}
          data-test="cancel-action-button"
        />
      )}
      {changePlanAvailable && contract.changePlanEnabled && (
        <ActionButtonWithIcon
          Icon={ChangePlanIcon}
          label={<LocalizedMessage id="changePlanQuickAction" />}
          onClick={() => onButtonClick(SubscriptionActionType.ChangePlan)}
          data-test="change-plan-action-button"
        />
      )}
    </>
  );

  return <div className={classes.row}>{roundActionButtons}</div>;
};
