// @ts-ignore js import, remove this when the import is typed
import { loadingPageWhileLoading, onEnter } from '@client/containers/container-helpers/index';
import { SubscriptionActionType } from '@client/models/subscription';
import type { ServiceId, SupplierId } from '@client/models/supplier';
import { sentCancellationOfServiceFailedMixpanelEvent } from '@client/modules/cancellation/mixpanel-events';
import * as urls from '@client/routes';
import type * as React from 'react';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import type { Dispatch } from 'redux';
// @ts-ignore js import, remove this when the import is typed
import AuthStatusPage from '../../../auth/AuthStatusPage';
import { CancellationAction } from '../../duck';
import { signCancelServiceLoa } from '../../websocketHelpers';
import { cancelServiceSignCompleteCallback } from '../common';

interface ContainerProps {
  merchantId: SupplierId;
  serviceId: ServiceId;
}

interface StateProps {
  status: any;
}

const mapStateToProps = (state: any): StateProps => ({
  status: state.cancellation.status,
});

type ConnectOwnProps = ContainerProps & RouteComponentProps;

interface DispatchProps {
  onReconnect(): void;
  onBackClick(): void;
  onRetryClick(): void;
  onBackToContractDetail(): void;
}

type BankIdSigningPageContainerProps = StateProps & DispatchProps & ConnectOwnProps;

const mapDispatchToProps = (
  dispatch: Dispatch,
  { history, merchantId, serviceId }: ConnectOwnProps
): DispatchProps => ({
  onReconnect: () => {
    const onTerminationSignCompleted = () =>
      cancelServiceSignCompleteCallback(dispatch, history)(merchantId, serviceId);
    const onNoSession = () => {
      history.replace(urls.subscriptionSearchWithAction(SubscriptionActionType.Cancel));
    };
    const failedSigningCallback = () => sentCancellationOfServiceFailedMixpanelEvent(merchantId, serviceId);
    dispatch<any>(
      signCancelServiceLoa(merchantId, serviceId, onTerminationSignCompleted, failedSigningCallback, true, onNoSession)
    );
  },
  onBackClick: () => {
    dispatch(CancellationAction.cancel());
    history.replace(urls.signCancelServiceRouteFun(merchantId, serviceId));
  },
  onRetryClick: () => {
    dispatch(CancellationAction.cancel());
    history.push(urls.signCancelServiceRouteFun(merchantId, serviceId));
  },
  onBackToContractDetail: () => {
    dispatch(CancellationAction.cancel());
    history.replace(urls.subscriptionSearchWithAction(SubscriptionActionType.Cancel));
  },
});

export const BankIdSigningServicePage: React.ComponentClass<ContainerProps> = compose<any, ContainerProps>(
  withRouter,
  withProps({ mobileHeight: 'full-with-no-header' }),
  connect(mapStateToProps, mapDispatchToProps),
  onEnter(({ status, onReconnect }: BankIdSigningPageContainerProps) => {
    if (!status) {
      onReconnect();
    }
  }),
  loadingPageWhileLoading((props: BankIdSigningPageContainerProps) => !props.status)
)(AuthStatusPage);
