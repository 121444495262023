import { isDeveloperToolsEnabled } from '@client/modules/developer-tools/options';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';
import Loadable from 'react-loadable';
import { branch, renderNothing } from 'recompose';

const LoadableDeveloperToolsBar = Loadable<any, any>({
  loader: async () =>
    import(/* webpackChunkName: "developer-tools" */ './DeveloperToolsBar').then((module) => module.DeveloperToolsBar),
  // eslint-disable-next-line react/display-name
  loading: () => <Body>Loading developer bar...</Body>,
});

export const LazyLoadedDeveloperToolsBar = branch(
  () => !isDeveloperToolsEnabled(),
  renderNothing
)(LoadableDeveloperToolsBar);
