import { makeStyles } from '@material-ui/core';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles({
  infoContainer: {
    padding: 8,
    display: 'grid',
    gridRowGap: '16px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(140px, 1fr))',
  },
});

export const InfoItemGrid: FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.infoContainer}>{children}</div>;
};
