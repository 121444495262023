// This file defines types and methods for dealing with preloaded resources.
import { Sentry } from '@client/assets/js/sentry';
import type { MessagesAndLocale } from '@client/internationalization/types';
import type { User } from '@client/models/user-models';
import isEmpty from 'lodash/isEmpty';
import type { AppContextIncoming } from '../app-context';

/**
 * Preloaded resources defined in `apps/end-user/client/app/index.html`.
 */
interface PreloadTypes {
  user: User;
  platformConfig: AppContextIncoming;
  messages: MessagesAndLocale;
}

declare global {
  interface Window {
    preloadedResources?: Promise<Response>; // Response contains PreloadTypes
  }
}

let preloadedResources: Partial<PreloadTypes> = {};

export const initializePreloadedResources = (preLoadedResourcesArg: Promise<Response> | undefined) => {
  if (preLoadedResourcesArg) {
    preLoadedResourcesArg
      .then((response: Response) => {
        if (response.status >= 200 && response.status <= 299) {
          response.json().then((json) => {
            preloadedResources = json as PreloadTypes;
          });
        }
      })
      .catch((reason) => {
        // Remove the failed promise from the window object.
        window.preloadedResources = undefined;
        Sentry.captureExceptionWithMessage(reason, 'Failed to prefetch resources');
      });
  }
};

// We need to wrap this function and call it here in order to make it testable.
initializePreloadedResources(window.preloadedResources);

/**
 * If the preloaded value exists for the key, it will be returned.
 * In case preloaded promise is rejected then the fallback function will be called.
 *
 * Otherwise the fallback function will be called and its value returned.
 */
export async function getPreloadedFetchOrFallback<Key extends keyof PreloadTypes>(
  key: Key,
  fallback: () => Promise<PreloadTypes[Key]>,
  count: number = 1
): Promise<PreloadTypes[Key]> {
  if (!isEmpty(preloadedResources) && preloadedResources[key]) {
    // @ts-ignore If statement should resolve this
    return Promise.resolve(preloadedResources[key]);
  } else if (window.preloadedResources && isEmpty(preloadedResources) && count <= 10) {
    // Sleep for 100ms and then retry.
    return await new Promise((r) => setTimeout(r, 100)).then(async () =>
      getPreloadedFetchOrFallback(key, fallback, count + 1)
    );
  } else {
    return fallback();
  }
}

/**
 * If the preloaded value exists for the key, it will be returned and the preloaded value will be removed.
 * Following call for the same key will result in using the fallback. This can be useful if the cache needs to be invalidated.
 * In case preloaded promise is rejected then the fallback function will be called.
 *
 * Otherwise the fallback function will be called and its value returned.
 */
export async function popPreloadedFetchOrFallback<Key extends keyof PreloadTypes>(
  key: Key,
  fallback: () => Promise<PreloadTypes[Key]>,
  count: number = 1
): Promise<PreloadTypes[Key]> {
  if (!isEmpty(preloadedResources) && preloadedResources[key]) {
    const result = preloadedResources[key];
    preloadedResources[key] = undefined;

    // @ts-ignore If statement should resolve this
    return Promise.resolve(result);
  } else if (window.preloadedResources && isEmpty(preloadedResources) && count <= 10) {
    // Sleep for 100ms and then retry.
    return await new Promise((r) => setTimeout(r, 100)).then(async () =>
      popPreloadedFetchOrFallback(key, fallback, count + 1)
    );
  } else {
    return fallback();
  }
}
