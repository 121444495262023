import PropTypes from 'prop-types';
import React from 'react';
import BankIdStatus from '@client/modules/e-signing/bank-id/components/BankIdStatus';
import MultiDeviceLayout from '../../../shared-components/layout/MultiDeviceLayout';
import styles from './styles.scss';
import { IconButton, RaisedButton } from 'material-ui';
import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import { black } from '@client/styles/colors';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { LocalizedMessage, withLocalization } from '../../../internationalization';
import { Card } from '@minna-technologies/minna-ui/components/Card';

const AuthStatusPage = withLocalization('auth/AuthStatusPage')(
  ({ status, onBackClick, onRetryClick, onBackToContractDetail, mobileHeight = 'full-with-header' }) => {
    const alreadyOptimizing = status.type === 'AlreadyOptimizationState';
    const alreadyTerminating = status.type === 'AlreadyTerminationState';
    return (
      <MultiDeviceLayout>
        <Card mobileHeight={mobileHeight} data="auth-status-page-div">
          {alreadyOptimizing ? (
            <div className={styles.padding}>
              <Body>
                <LocalizedMessage id="subscriptionAlreadyOptimizingSigningError" />
              </Body>
              <RaisedButton
                secondary
                label={<LocalizedMessage id="continueOnSigningErrorButton" />}
                onClick={onBackToContractDetail}
              />
            </div>
          ) : alreadyTerminating ? (
            <div className={styles.padding}>
              <Body>
                <LocalizedMessage id="subscriptionAlreadyTerminatingSigningError" />
              </Body>
              <RaisedButton
                secondary
                label={<LocalizedMessage id="continueOnSigningErrorButton" />}
                onClick={onBackToContractDetail}
              />
            </div>
          ) : (
            <div>
              <IconButton onClick={onBackClick} iconStyle={{ fill: black }}>
                <ArrowBack />
              </IconButton>
              <div className={styles['bankid-mobile']} />
              <div className={styles['status-message']}>
                <BankIdStatus status={status} onRetryClick={onRetryClick} />
              </div>
            </div>
          )}
        </Card>
      </MultiDeviceLayout>
    );
  }
);

AuthStatusPage.propTypes = {
  status: PropTypes.object.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onRetryClick: PropTypes.func.isRequired,
  onBackToContractDetail: PropTypes.func,
  mobileHeight: PropTypes.oneOf(['full-with-header', 'full-with-no-header']).isRequired,
};

export default AuthStatusPage;
