import Sentry from '@client/assets/js/sentry';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ErrorComponent } from '../ErrorComponent';
import { trackClickedButton, trackViewedPage } from '@client/tracking/mixpanel-events';
import { FEATURE_OVERVIEW, TrackingButtonLocation } from '@client/tracking/mixpanel-constants';
import * as routes from '@client/routes';
import { useHistory } from 'react-router-dom';
import { selectorUser } from '@client/ducks/user';
import type { User } from '@client/models/user-models';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { SyncFlowType } from '@client/modules/app-context';
import { psd2AuthenticationWithClient } from '@client/modules/onboarding/OnboardingPsd2/consent/psd2AuthenticationWithClient';

const Psd2ErrorPageComponent: React.FunctionComponent = (_, { localizeMessage }) => {
  const history = useHistory();
  const features = useSelector(selectorFeatures);
  const user: User | undefined = useSelector(selectorUser);

  const isIdentifyUser = user?.featureOverrides?.identify ? user.featureOverrides.identify : false;
  const isIdentifyUserOrIdentifyFeatureEnabled =
    isIdentifyUser || (features.identify?.identify ? features.identify.identify : false);
  const maybeLastSyncDate = user?.latestBankDiscoverySyncAt;
  const syncIsWithinLastFiveDays = maybeLastSyncDate
    ? new Date().getTime() - new Date(maybeLastSyncDate).getTime() <= 5 * 24 * 60 * 60 * 1000 // 5 days in millis
    : false;
  const getBackButtonUrl = () => {
    if (isIdentifyUserOrIdentifyFeatureEnabled && syncIsWithinLastFiveDays) {
      // This is a full user which has synced. Show the Overview.
      return routes.overviewPage;
    } else {
      // Light users or full users without a sync, go to the PSD2 Primer
      return routes.onboardingPsd2;
    }
  };

  const shouldShowBackButton = features.syncFlow?.syncFlowType === SyncFlowType.PSD2 && features.syncFlow?.onboarding;
  const shouldShowTryAgainButton =
    features.syncFlow?.syncFlowType === SyncFlowType.PSD2 && features.syncFlow?.showRetryButton;
  const tryAgainClick = async () => {
    trackClickedButton(FEATURE_OVERVIEW, 'Error page', 'Try Again', TrackingButtonLocation.CENTER);
    // Resolves the `getClientSpecificAuthenticationUrl` from BI and redirects to the bank's PSD2 authentication flow.
    const url = await psd2AuthenticationWithClient();
    history.replace(url);
  };

  const goBackClick = () => {
    trackClickedButton(FEATURE_OVERVIEW, 'Error page', 'Back', TrackingButtonLocation.BOTTOM_LEFT);
    const url = getBackButtonUrl();
    history.replace(url);
  };

  useEffect(() => {
    Sentry.captureMessage('Psd2 error page shown to user');
    trackViewedPage(FEATURE_OVERVIEW, 'Error page');
  }, []);

  return (
    <ErrorComponent
      headerText={<LocalizedMessage id="genericSyncHeaderText" />}
      imageAltText={localizeMessage('imageAltText')}
      bodyText={<LocalizedMessage id="genericSyncBodyText" />}
      recoverActionText={<LocalizedMessage id="recoverActionText" />}
      escapeActionText={<LocalizedMessage id="escapeActionText" />}
      shouldShowRecoverButton={shouldShowTryAgainButton}
      onRecoverClick={tryAgainClick}
      shouldShowEscapeButton={shouldShowTryAgainButton}
      onEscapeClick={tryAgainClick}
      shouldShowBackButton={shouldShowBackButton}
      onBackClick={goBackClick}
    />
  );
};

Psd2ErrorPageComponent.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const Psd2ErrorPageContainer = withLocalization('ErrorScreen')(Psd2ErrorPageComponent);
