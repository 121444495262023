import type { AppType, Market } from '@client/modules/app-context/constants';
import { appPlatform, getMixpanel } from '@client/tracking/mixpanel';
import { TrackingButtonName, EventNames, TrackingPageName, TrackingFeature } from '@client/tracking/mixpanel-constants';

export const trackChangePlanDrawerViewed = (market: Market, appType: AppType) => {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: TrackingFeature.ChangePlan,
    Page: TrackingPageName.ONBOARDING,
    Market: market,
    Platform: appType,
    'App platform': appPlatform(),
  });
};

export const trackChangePlanDrawerCloseButtonClicked = (market: Market, appType: AppType) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.ChangePlan,
    Page: TrackingPageName.ONBOARDING,
    Button: TrackingButtonName.CLOSE,
    Market: market,
    Platform: appType,
    'App platform': appPlatform(),
  });
};

export const trackChangePlanDrawerContinueButtonClicked = (market: Market, appType: AppType) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.ChangePlan,
    Page: TrackingPageName.ONBOARDING,
    Button: TrackingButtonName.CONTINUE,
    Market: market,
    Platform: appType,
    'App platform': appPlatform(),
  });
};
