import type { AppFeatureToggles } from '@client/modules/app-context';
import type { Subscription } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import isEmpty from 'lodash/isEmpty';

export function subscriptionPageByContracts(subscription: Subscription, features: AppFeatureToggles): string {
  if (subscription.singleContractId) {
    return urls.contractDetailsPage(subscription.id, subscription.singleContractId);
  }
  if (isEmpty(subscription.contracts)) {
    if (features.useUnresolvedSubscriptionGuide) {
      return urls.unresolvedSubscriptionGuidePath(subscription.id);
    } else {
      return urls.selectServicesPage(subscription.id);
    }
  }

  return urls.subscriptionPage(subscription.id);
}
