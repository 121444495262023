// @ts-ignore until import is typed
import { conditionalRender } from '@client/containers/container-helpers';
import { selectorIsLoading, selectorLoadingFailed, selectorLoggedIn } from '@client/ducks/user';
import { withLocalization } from '@client/internationalization';
import get from 'lodash/get';
import type React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { NavigationType } from '../../modules/app-context';
import { selectorAppContext } from '../../modules/app-context/duck';
import { HeaderComponent } from './component';
import { ProfileHeader } from './ProfileHeader';

const mapStateToProps = (state: any) => ({
  userIsLoading: selectorIsLoading(state),
  userIsLoadingFailed: selectorLoadingFailed(state),
  appContext: selectorAppContext(state),
  isLoggedIn: selectorLoggedIn(state),
});

const EmptyComponent: React.FC = () => null;

export const Header: React.ComponentClass<any> = compose<any, any>(
  withRouter,
  connect(mapStateToProps),
  conditionalRender(
    (props: any) => get(props.appContext.features, 'navigation.navigationType') === NavigationType.PROFILE,
    ProfileHeader
  ),
  // If navigation feature toggle is disabled, hide entire Header
  conditionalRender((props: any) => !get(props.appContext.features, 'navigation'), EmptyComponent),
  withLocalization('Header')
)(HeaderComponent);
