import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { selectorDisplayName } from '@client/modules/app-context/duck';
import { BasicPaper } from '@client/modules/subscription/components/BasicPaper';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { LadyHeyIllustration } from '@minna-technologies/minna-ui/illustrations/LadyHey';
import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.scss';

const UserDeleteVerifiedPageInner: FC = () => {
  const { colors } = useTheme();
  const displayName = useSelector(selectorDisplayName);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <BasicPaper>
          <div className={styles['paper-container']} style={{ backgroundColor: colors.base.background }}>
            <div data-test="account-removed-card" className={styles['paper-content']}>
              <LadyHeyIllustration />
              <div className={styles['header-row']}>
                <Title>{<LocalizedMessage id="greeting" />}</Title>
              </div>
              <div className={styles.row}>
                <Body>{<LocalizedMessage id="description" values={{ displayName: displayName }} />}</Body>
              </div>
            </div>
          </div>
        </BasicPaper>
      </div>
    </div>
  );
};

export const UserDeleteVerifiedPage = withLocalization('userSettings/UserDeleteVerifiedPage')(
  UserDeleteVerifiedPageInner
);
