import { useLocalization } from '@client/internationalization';
import { SubscriptionActionType } from '@client/models/subscription';
import { getLocalisationScopeFromAction } from '@client/modules/subscription-search/SubscriptionSearchPage/logic';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Stepper } from '@minna-technologies/minna-ui/components/Stepper';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React, { useState } from 'react';

const useStyles = makeStyles({
  howDoesItWorkContainer: {
    textAlign: 'center',
    paddingTop: '32px',
    cursor: 'pointer',
  },
  howDoesItWorkIntro: {
    paddingBottom: '16px',
  },
});

interface HowDoesItWorkLinkProps {
  action: SubscriptionActionType;
}

export const HowDoesItWorkLink = ({ action }: HowDoesItWorkLinkProps): JSX.Element | null => {
  const scope = getLocalisationScopeFromAction(action);
  const { localizeMessage } = useLocalization(scope);
  const { colors } = useTheme();
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  // No text decided for other action types yet
  if (action !== SubscriptionActionType.Cancel) {
    return null;
  }

  return (
    <>
      <Body
        className={classes.howDoesItWorkContainer}
        onClick={openModal}
        data-test="how-does-it-work-link"
        color={colors.action.primary}
        variant="bold"
      >
        {localizeMessage('howDoesItWorkLinkText')}
      </Body>
      <Modal
        open={modalOpen}
        button1={<PrimaryButton onClick={closeModal} label={localizeMessage('howDoesItWorkCloseButton')} />}
        onClose={closeModal}
        title={localizeMessage('howDoesItWorkTitle')}
      >
        <Body className={classes.howDoesItWorkIntro}>{localizeMessage('howDoesItWorkIntro')}</Body>
        <Stepper
          numbered
          steps={[
            { label: <Body>{localizeMessage('howDoesItWorkItem1')}</Body> },
            { label: <Body>{localizeMessage('howDoesItWorkItem2')}</Body> },
            { label: <Body>{localizeMessage('howDoesItWorkItem3')}</Body> },
          ]}
        />
      </Modal>
    </>
  );
};
