import { LocalizedMessage, useLocalization, withLocalization } from '@client/internationalization';
import type { LocalDate } from '@client/models/LocalDate';
import type { OffsetDateTime } from '@client/models/OffsetDateTime';
import { PauseOutcome } from '@client/modules/pause/models';
import { addDaysToDate, parseDate } from '@client/utils/date';
import { makeStyles, Step, StepConnector, StepLabel, Stepper } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { CheckCircleIcon } from '@minna-technologies/minna-ui/icons/CheckCircle';
import { PauseIcon } from '@minna-technologies/minna-ui/icons/Pause';
import { TimeClockIcon } from '@minna-technologies/minna-ui/icons/TimeClock';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles({
  paper: {
    margin: '8px 0 16px',
  },
  container: {
    margin: '16px',
    alignItems: 'start',
    display: 'grid',
    gridTemplateRows: '1fr auto',
    gridRowGap: '16px',
  },
  noPadding: {
    padding: '0',
  },
  connectorLine: {
    minHeight: '24px',
  },
  titleContainer: {
    display: 'grid',
    gridTemplateColumns: '36px 1fr',
  },
});

interface PauseOutcomeSuccessfulInnerProps {
  pausedFrom: LocalDate;
  pausedUntil: LocalDate;
  pausedAt: OffsetDateTime;
  pauseOutcome: PauseOutcome;
}

const PauseOutcomeSuccessfulInner: FC<PauseOutcomeSuccessfulInnerProps> = ({
  pausedFrom,
  pausedUntil,
  pausedAt,
  pauseOutcome,
}) => {
  const { localizeDate } = useLocalization('pause/SubscriptionPausePage');

  const classes = useStyles();
  const { colors } = useTheme();

  const resumeDate = localizeDate(addDaysToDate(pausedUntil, 1));

  return (
    <Card className={classes.paper} noPadding>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <PauseIcon bold />
          <Title>
            <LocalizedMessage
              id={
                pauseOutcome == PauseOutcome.Paused
                  ? 'pauseOutcomeAfterPauseActiveTitle'
                  : 'pauseOutcomeBeforePauseActiveTitle'
              }
            />
          </Title>
        </div>
        <Stepper
          connector={
            <StepConnector
              classes={{
                root: classes.noPadding,
                line: classes.connectorLine,
              }}
            />
          }
          style={{ padding: '0' }}
          activeStep={1}
          orientation="vertical"
        >
          <Step>
            <StepLabel
              icon={
                pauseOutcome == PauseOutcome.Paused ? (
                  <CheckCircleIcon nativeColor={colors.action.positive} />
                ) : (
                  <CheckCircleIcon nativeColor={colors.action.positive} bold />
                )
              }
            >
              {pauseOutcome == PauseOutcome.Paused ? (
                <Body color={colors.textOn.surfaceSubdued}>
                  <LocalizedMessage
                    id="pauseOutcomePauseActiveStep1"
                    values={{ pausedOnDate: localizeDate(parseDate(pausedAt)) }}
                  />
                </Body>
              ) : (
                <Body color={colors.textOn.surfaceSubdued} variant={'bold'}>
                  <LocalizedMessage
                    id="pauseOutcomePauseActiveStep1"
                    values={{ pausedOnDate: localizeDate(parseDate(pausedAt)) }}
                  />
                </Body>
              )}
            </StepLabel>
          </Step>
          <Step>
            {pauseOutcome == PauseOutcome.Paused ? (
              <StepLabel icon={<CheckCircleIcon nativeColor={colors.action.positive} bold />}>
                <Body variant="bold">
                  <LocalizedMessage id="pauseOutcomeAfterPauseActiveStep2" values={{ resumeDate: resumeDate }} />
                </Body>
              </StepLabel>
            ) : (
              <StepLabel icon={<TimeClockIcon nativeColor={colors.action.info} bold />}>
                <Body>
                  <LocalizedMessage
                    id="pauseOutcomeBeforePauseActiveStep2"
                    values={{
                      pausedFromDate: localizeDate(parseDate(pausedFrom)),
                      pausedUntilDate: localizeDate(parseDate(pausedUntil)),
                    }}
                  />
                </Body>
              </StepLabel>
            )}
          </Step>
        </Stepper>
      </div>
    </Card>
  );
};

export const PauseOutcomeSuccessful: React.ComponentType<PauseOutcomeSuccessfulInnerProps> =
  withLocalization('pause/SubscriptionPausePage')(PauseOutcomeSuccessfulInner);
