export function setItemInLocalStorage(key: string, value: string): boolean {
  try {
    localStorage.setItem(key, value);

    return true;
  } catch (e) {
    return false;
  }
}

export function getItemFromLocalStorage(key: string): string | null {
  try {
    return localStorage.getItem(key);
  } catch {
    return null;
  }
}

export function removeItemFromLocalStorage(key: string) {
  try {
    localStorage.removeItem(key);

    return undefined;
  } catch {
    return undefined;
  }
}
