export interface QuestionDetails {
  text: string;
  description?: string;
  questionLabelForCancellationRequest?: string;
}
export interface QuestionDetailsMap {
  [key: string]: QuestionDetails;
}

export type QuestionId = string;
export type AlternativeId = string;

export interface Question {
  id: QuestionId;
  required: boolean;
  details: QuestionDetailsMap;
  type: CancellationQuestionType;
  inputFieldSize?: CancellationQuestionInputFieldSize;
}

export enum CancellationQuestionInputFieldSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
}

export enum SimpleQuestionType {
  TextQuestion = 'TextQuestion',
  EmailQuestion = 'EmailQuestion',
  AddressQuestion = 'AddressQuestion',
  TelephoneQuestion = 'TelephoneQuestion',
  DateQuestion = 'DateQuestion',
}

export enum CancellationQuestionType {
  SimpleQuestion = 'SimpleQuestion',
  MultipleChoiceQuestion = 'MultipleChoiceQuestion',
  RecipientQuestion = 'RecipientQuestion',
}

export interface SimpleQuestion extends Question {
  includeInCancellationRequest: boolean;
  questionType: SimpleQuestionType;
}

export interface QuestionAlternativeDetails {
  label: string;
  userInputLabel?: string;
  questionLabelForCancellationRequest?: string;
}

export interface AlternativeDetailsMap {
  [key: string]: QuestionAlternativeDetails;
}
export interface QuestionAlternative {
  id: AlternativeId;
  details: AlternativeDetailsMap;
}

export interface RecipientQuestionAlternative extends QuestionAlternative {
  id: AlternativeId;
  recipientEmailAddress: string;
  requiresUserInput: boolean;
  details: AlternativeDetailsMap;
}

export interface RecipientQuestion extends Question {
  includeInCancellationRequest: boolean;
  alternatives: RecipientQuestionAlternative[];
}

export interface MultipleChoiceQuestionAlternative extends QuestionAlternative {
  id: AlternativeId;
  includeInCancellationRequest: boolean;
  nestedQuestions: Question[];
  details: AlternativeDetailsMap;
}

export interface MultipleChoiceQuestion extends Question {
  alternatives: MultipleChoiceQuestionAlternative[];
}

export function isQuestionWithAlternatives(question: any): question is { alternatives: QuestionAlternative[] } {
  return question.alternatives !== undefined;
}
