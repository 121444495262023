import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import delay from 'lodash/delay';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import has from 'lodash/has';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';
import startsWith from 'lodash/startsWith';
import Sentry from '../assets/js/sentry';
import { selectorAppType, selectorMarket } from '../modules/app-context/duck';
import { AppIntegration, AppPlatform } from '../modules/app-integration';
import { getGlobalStore } from '../modules/global-store';
import { getMixpanelDomain } from '@client/foundation/initUtils';

export function appPlatform(): string {
  switch (AppIntegration.getAppPlatform()) {
    case AppPlatform.ANDROID_WEBVIEW:
      return 'Android';

    case AppPlatform.IOS_WEBVIEW:
      return 'iOS';

    case AppPlatform.WEB_BROWSER_NATIVE:
    case AppPlatform.WEB_BROWSER_IFRAME:
      return 'Web';

    default:
      return 'Unknown';
  }
}

function trackProperties(properties: Record<string, unknown>) {
  const state = getGlobalStore().getState();
  const additionalProperties = omitBy(
    {
      ...properties,
      Market: selectorMarket(state),
      Platform: selectorAppType(state),
      'App platform': appPlatform(),
      Experiments: [],
    },
    isNil
  );

  return {
    ...additionalProperties,
  };
}

function mixpanelTrack(name: string, properties: Record<string, unknown>) {
  return (window as any).mixpanel.track(name, trackProperties(properties));
}

// @ts-ignore
// eslint-disable-next-line no-undef
export function createMixpanel(log: Console): Mixpanel {
  if (isLoaded()) {
    sendQueue();
    const mixpanelInstance = clone((window as any).mixpanel);
    mixpanelInstance.track = mixpanelTrack;

    return mixpanelInstance;
  } else {
    try {
      (window as any).ga('send', 'event', 'Debug', 'Mocking Mixpanel');
    } catch (ex) {
      // ignore
    }

    return createQueuedMixpanel(log);
  }
}

// @ts-ignore
// eslint-disable-next-line no-undef
export function getMixpanel(): Mixpanel {
  return createMixpanel(console);
}

function isLoaded() {
  return Object.prototype.hasOwnProperty.call(window as any, 'mixpanel') && get(window, 'mixpanel.__loaded');
}

// Store all Mixpanel calls in a queue until it becomes loaded
interface QueuedCall {
  functionPath: string;
  args: any[];
}

const callQueue: QueuedCall[] = [];

// @ts-ignore
// eslint-disable-next-line no-undef
function createQueuedMixpanel(log: Console): Mixpanel {
  const functionPaths =
    'disable time_event track track_pageview track_links track_forms register register_once alias unregister get_distinct_id identify reset name_tag set_config people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user'.split(
      ' '
    );
  const mixpanelObj = {};
  forEach(functionPaths, (functionPath) => {
    set(mixpanelObj, functionPath, (...args: any[]) => {
      try {
        if (functionPath === 'track') {
          const mixpanelProps = trackProperties(args[1]);
          log.info(`mixpanel.${functionPath}`, args[0], mixpanelProps);
          Object.entries(mixpanelProps).forEach((prop) => {
            if (isPlainObject(prop[1])) {
              log.warn(`Found suspicious object being sent to mixpanel: ${prop[0]}: ${JSON.stringify(prop[1])}`);
            }
          });
        } else if (functionPath === 'identify') {
          if (has(window, 'mixpanel.identify')) {
            (window as any).mixpanel.identify(...args);
          }
          log.info(`mixpanel.${functionPath}`, ...args);
        } else {
          log.info(`mixpanel.${functionPath}`, ...args);
        }
        callQueue.push({ functionPath, args: cloneDeep(args) });
      } catch (e) {
        // ignore
      }
    });
  });

  // @ts-ignore
  // eslint-disable-next-line no-undef
  return mixpanelObj as Mixpanel;
}

function sendQueue() {
  try {
    if (callQueue.length > 0) {
      forEach(callQueue, ({ functionPath, args }) => {
        try {
          let context;
          if (startsWith(functionPath, 'people')) {
            context = (window as any).mixpanel.people;
          } else {
            context = (window as any).mixpanel;
          }
          const func = get((window as any).mixpanel, functionPath).bind(context);
          func(...args);
        } catch (e) {
          Sentry.captureException(e, {
            extra: { functionPath, args },
          });
        }
      });

      callQueue.length = 0;
    }
  } catch (e) {
    Sentry.captureException(e, {
      extra: callQueue,
    });
  }
}

const sendQueueIfLoaded = () => {
  if (isLoaded()) {
    sendQueue();
  }
};
delay(sendQueueIfLoaded, 1500);
delay(sendQueueIfLoaded, 5000);
delay(sendQueueIfLoaded, 15000);

const sessionStart = new Date();
function sendAppClosedEvent() {
  const now = new Date();
  const durationInSeconds = (now.getTime() - sessionStart.getTime()) / 1000;
  if (navigator.sendBeacon) {
    const token = get(getMixpanel(), 'config.token');

    const data = {
      event: 'App closed',
      properties: {
        token,
        'Session duration': durationInSeconds,
        // @ts-ignore
        ...get(getMixpanel(), 'persistence.props'),
      },
    };

    const base64data = btoa(JSON.stringify(data));
    try {
      // Wrapping this in a try-catch block to further understand the TypeErrors we get for Illegal invocation
      const sendBeacon = navigator.sendBeacon.bind(navigator);
      sendBeacon(`https://${getMixpanelDomain(window.location.hostname)}/track/?data=${base64data}`);
    } catch (error) {
      Sentry.captureExceptionWithMessage(error, 'Unable to track app closed in mixpanel');
    }
  } else {
    getMixpanel().track('App closed', trackProperties({ 'Session duration': durationInSeconds }));
  }

  return null;
}

// multiple-markets
// TODO: When appcontext contains the environment, we should switch on the information in the appcontext instead
// eslint-disable-next-line complexity
export function getMixpanelKey(hostname: string): string {
  switch (hostname) {
    case 'minatjanster.se':
    case 'swedbank-frontend.minatjanster.se':
    case 'danskebank.denmark.minna.tech':
    case 'op.finland.minna.tech':
    case 'sb1.norway.minna.tech':
    case 'ing.belgium.minna.tech':
    case 'app.eu.minna.tech':
      return '27c6fe5cc70e4844dda15da56a6a4f30';
    case 'app.us.minna.tech':
      return '08788862fb4ce98194da6c8ad1890c31';
    case 'supplier.eu.minna.tech':
    case 'portal.eu.minna.tech':
    case 'portal.us.minna.tech':
      return '0f95725ffd6fca854bba66497b031765';
    case 'cancel.eu.minnatechnologies.com':
      return '3f5acc3bb374b671e55eb7a9311f8aa9';
    case 'cancel.us.minnatechnologies.com':
      return '1fef5efc9e15ad78273a4284e47b2701';
    // TODO: Remove staging under minatjanster.se and minatjanster.nu domain from here
    //       after they are retired
    case 'staging.minatjanster.se':
    case 'staging-swedbank.test.minna.io':
    case 'staging-standalone.test.minna.io':
    case 'staging-norway.minatjanster.nu':
    case 'staging-unitedkingdom.minatjanster.nu':
    case 'staging-sweden-end-user.minna.io':
    case 'staging-denmark-end-user.minna.io':
    case 'staging-norway-end-user.minna.io':
    case 'staging-belgium-end-user.minna.io':
    case 'staging-finland-end-user.minna.io':
    case 'staging-unitedkingdom-end-user.minna.io':
    case 'staging-europe-end-user.minna.io':
    case 'swedbank-test-swedbank-frontend.minatjanster.nu':
    case 'swedbank-frontend-test.minatjanster.se':
    case 'danskebank-test.minatjanster.nu':
    case 'sb1-test.minna.io':
    case 'op-test.minna.io':
    case 'ing-test.minna.io':
      return 'fdb4aafc77c3ac88699fc2cabc4fb896';
    default:
      // return empty string if hostname isn't a match
      return '';
  }
}

window.addEventListener('beforeunload', sendAppClosedEvent);
