//@ts-ignore
import { loadingPageWhileLoading } from '@client/containers/container-helpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { navigateToContactUs } from '../../../ducks';
import { clearUserSearchQuery, selectorArticles, selectorLoadingArticles } from '../../../ducks/zendesk-help-center';
import { UserSearchResult } from './component';

const mapStateToProps = (state: any) => {
  return {
    resultHasArticles: selectorArticles(state).length > 0,
    loadingArticles: selectorLoadingArticles(state),
  };
};

const mapDispatchToProps = (dispatch: any, { onBackClick }: any) => {
  return {
    onNavigateToContactUs: () => {
      dispatch(navigateToContactUs());
    },
    onBackClick: () => {
      dispatch(clearUserSearchQuery());
      onBackClick();
    },
  };
};

export const UserSearchResultContainer = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  loadingPageWhileLoading((p: any) => p.loadingArticles)
)(UserSearchResult);

UserSearchResultContainer.propTypes = {
  onBackClick: PropTypes.func.isRequired,
};
