import { BROADBAND, MOBILE } from '@client/constants/categories';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { Market } from '@client/modules/app-context/constants';
import { selectorAppContext } from '@client/modules/app-context/duck';
import type {
  OrderedBroadbandProduct,
  OrderedGenericProduct,
  OrderedMobileProduct,
  OrderedMobileProductLegacy,
  OrderedSubscription,
} from '@client/modules/switch/ordered-subscription/models';
import { OrderedBroadbandProductInfo } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionDetails/components/OrderedBroadbandProductInfo';
import { OrderedGenericProductInfo } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionDetails/components/OrderedGenericProductInfo';
import { OrderedMobileProductInfo } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionDetails/components/OrderedMobileProductInfo';
import { OrderedMobileProductInfoLegacy } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionDetails/components/OrderedMobileProductInfoLegacy';
import { isShallowCategory, priceWithCurrency } from '@client/modules/switch/ordered-subscription/utils';
import type { WithStyles } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardHeader } from '@minna-technologies/minna-ui/components/Card/CardHeader';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const styles = createStyles({
  card: {
    display: 'grid',
    gridColumnGap: '8px',
  },
});

interface OrderedSubscriptionDetailsProps {
  orderedSubscription: OrderedSubscription;
  goToTermsPage(): void;
}

const OrderedSubscriptionDetailsInner: React.FunctionComponent<
  OrderedSubscriptionDetailsProps & WithStyles<typeof styles>
> = ({ orderedSubscription, goToTermsPage, classes }, { localizeCostWithCurrency }) => {
  const appContext = useSelector(selectorAppContext);
  const categories = orderedSubscription.product.category;
  const categoryName = categories[0].name;

  const priceWithCurrencyFunc = (amount: number) => {
    return priceWithCurrency(amount, localizeCostWithCurrency, appContext.currency);
  };

  const productInfo = () => {
    // Here we assume that the only case we can have multiple categories is for energy products
    switch (categoryName) {
      case MOBILE:
        if (appContext.market === Market.Sweden) {
          const mobileProduct = orderedSubscription.product as OrderedMobileProductLegacy;

          return (
            <OrderedMobileProductInfoLegacy mobileProduct={mobileProduct} priceWithCurrency={priceWithCurrencyFunc} />
          );
        } else {
          const mobileProduct = orderedSubscription.product as OrderedMobileProduct;

          return <OrderedMobileProductInfo product={mobileProduct} goToTermsPage={goToTermsPage} />;
        }
      case BROADBAND: {
        const broadbandProduct = orderedSubscription.product as OrderedBroadbandProduct;

        return (
          <OrderedBroadbandProductInfo broadbandProduct={broadbandProduct} priceWithCurrency={priceWithCurrencyFunc} />
        );
      }
      default: {
        if (!isShallowCategory(categoryName)) {
          throw new Error(`No product info component for category ${orderedSubscription.product.category[0].name}`);
        }

        const genericProduct = orderedSubscription.product as OrderedGenericProduct;

        return <OrderedGenericProductInfo orderedProduct={genericProduct} goToTermsPage={goToTermsPage} />;
      }
    }
  };

  return (
    <Card className={classes.card}>
      <CardHeader title={<LocalizedMessage id="whatYouHaveOrdered" />} />
      {productInfo()}
    </Card>
  );
};

OrderedSubscriptionDetailsInner.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
};

export const OrderedSubscriptionDetails: React.ComponentType<OrderedSubscriptionDetailsProps> = withLocalization(
  'orderedSubscription/OrderedSubscriptionDetailsPage'
)(withStyles(styles)(OrderedSubscriptionDetailsInner));
