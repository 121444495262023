import { isSwedbankApp } from '@client/modules/app-integration/swedbank-app-integration';
import { AppIntegration } from '../modules/app-integration';

export function trackSwedbankAnalyticsEvent(event: SwedbankAnalyticsEvent) {
  if (isSwedbankApp()) {
    AppIntegration.sendAnalyticsEvent(event);
  }
}

export interface SwedbankAnalyticsEvent {
  [key: string]: unknown;
  action: string;
}
