import Sentry from '@client/assets/js/sentry';
import { selectorLoggedIn } from '@client/ducks/user';
import { selectorAppType, selectorFeatures } from '@client/modules/app-context/duck';
import { insightsPage, landingPage } from '@client/routes';
import { useBackOrDefault } from '@client/utils/hooks/history';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppType } from '../../app-context/constants';
import { PageNotFoundPage } from './component';

export const PageNotFoundContainer = () => {
  const appType = useSelector(selectorAppType);
  const onBackClick = useBackOrDefault(landingPage);
  const features = useSelector(selectorFeatures);
  const loggedIn = useSelector(selectorLoggedIn);
  const maybeUrlParameter = new URLSearchParams(window.location.search);
  const maybeErrorFallbackToInsights = maybeUrlParameter.get('onErrorFallbackToInsights') === 'true';

  useEffect(() => {
    if (appType === AppType.Swedbank) {
      Sentry.captureMessage('Rendered page not found page in Swedbank app');
    }

    if (maybeErrorFallbackToInsights) {
      Sentry.captureMessage(`Used the Insights feed page as a fallback for ${appType}`);
    }
  }, [appType, maybeErrorFallbackToInsights]);

  return features.insights && maybeErrorFallbackToInsights ? (
    <Redirect to={insightsPage} />
  ) : (
    <PageNotFoundPage onBackClick={onBackClick} isSwedbank={appType === AppType.Swedbank} isLoggedIn={loggedIn} />
  );
};

// We allow this since we were using it from so many places when introducing the lint rule.
// eslint-disable-next-line import/no-default-export
export default PageNotFoundContainer;
