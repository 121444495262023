import { LocalizedMessage, withLocalization } from '@client/internationalization';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';

const useStyles = makeStyles({
  description: {
    margin: '16px 0 32px 0',
  },

  description2: {
    margin: '23px 0 24px 0',
  },

  services: {
    marginBottom: '32px',
  },
});

export interface SelectableServicesListInnerProps {
  services: React.ReactNode[];
  valueAddedServices?: React.ReactNode[];
  supplierName?: string;
}
const SelectableServicesListInner: React.FunctionComponent<SelectableServicesListInnerProps> = ({
  services,
  valueAddedServices,
  supplierName = '',
}: SelectableServicesListInnerProps) => {
  const { colors } = useTheme();
  const classes = useStyles();

  return (
    <div>
      <div className={classes.services}>
        <Body color={colors.textOn.surfaceSubdued} className={classes.description}>
          <LocalizedMessage id="servicesSectionTitle" values={{ supplierName }} />
        </Body>
        {services}
      </div>
      {valueAddedServices && (
        <>
          <Body color={colors.textOn.surfaceSubdued} className={classes.description2}>
            <LocalizedMessage id="valueAddedServicesSectionTitle" values={{ supplierName }} />
          </Body>
          {valueAddedServices}
        </>
      )}
    </div>
  );
};

export const SelectableServicesListComponent = withLocalization('contract/SelectServicesListLolipop')(
  SelectableServicesListInner
);
