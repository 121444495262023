import type { SubscriptionActionType } from '@client/models/subscription';
import type { SupplierWithServices } from '@client/models/supplier';

export interface SearchEntryPointState {
  selectedAction?: SubscriptionActionType;
  selectedSupplier?: SupplierWithServices;
  searchTerm?: string;
}

const initialState: SearchEntryPointState = {
  selectedAction: undefined,
  selectedSupplier: undefined,
  searchTerm: undefined,
};

const NAMESPACE = 'SEARCH_ENTRYPOINT';
const ACTION_SELECTED = `${NAMESPACE}/ACTION_SELECTED`;
const SUPPLIER_SELECTED = `${NAMESPACE}/SUPPLIER_SELECTED`;
const PERSIST_SEARCH_TERM = `${NAMESPACE}/PERSIST_SEARCH_TERM`;

export function reducer(state: SearchEntryPointState = initialState, action: any) {
  switch (action.type) {
    case ACTION_SELECTED: {
      return {
        ...state,
        selectedAction: action.selectedAction,
      };
    }
    case SUPPLIER_SELECTED: {
      return {
        ...state,
        selectedSupplier: action.selectedSupplier,
      };
    }
    case PERSIST_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    }
    default: {
      return state;
    }
  }
}

export const setSelectedAction = (selectedAction: SubscriptionActionType) => ({
  type: ACTION_SELECTED,
  selectedAction,
});

export const setSelectedSupplier = (selectedSupplier: SupplierWithServices) => ({
  type: SUPPLIER_SELECTED,
  selectedSupplier,
});

export const persistSearchTerm = (searchTerm: string | undefined) => ({
  type: PERSIST_SEARCH_TERM,
  searchTerm,
});

export const selectorSelectedAction = (state: any): SubscriptionActionType => state.searchEntryPoint.selectedAction;
export const selectorSelectedSupplier = (state: any): SupplierWithServices => state.searchEntryPoint.selectedSupplier;
export const selectorSearchTerm = (state: any): string => state.searchEntryPoint.searchTerm;
