import type { AppContext } from '@client/modules/app-context';
import { SigningFeature } from '@client/modules/app-context';
import { BankIdSigningPageContainer } from '@client/modules/cancellation/signing/BankIdSigningPage';
import { NoSigningPage } from '@client/modules/cancellation/signing/NoSigningPage';
import React from 'react';
import type { ContractId, SubscriptionId } from '../../subscription/types';
import { NemIdSigningPage } from './NemIdSigningPage';

export interface SigningPageProps {
  subscriptionId: SubscriptionId;
  contractId: ContractId;
  appContext: AppContext;
}

export const SigningPage: React.FunctionComponent<SigningPageProps> = ({ subscriptionId, contractId, appContext }) => {
  switch (appContext.features.signing) {
    case SigningFeature.BANKID:
      return <BankIdSigningPageContainer subscriptionId={subscriptionId} contractId={contractId} />;
    case SigningFeature.NEMID:
      return <NemIdSigningPage subscriptionId={subscriptionId} contractId={contractId} />;
    case SigningFeature.CHECKBOX:
    case SigningFeature.CHECKBOX_WITH_PROOF:
      return <NoSigningPage subscriptionId={subscriptionId} contractId={contractId} />;
    default:
      throw new Error(`No signing page for signing feature ${appContext.features.signing}`);
  }
};
