import type { SupplierId, SupplierWithServices } from '@client/models/supplier';
import makeStyles from '@material-ui/styles/makeStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import React from 'react';

const useStyles = makeStyles({
  merchantContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '6px 8px',
  },

  merchantTopListLogoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '60px',
  },

  merchantTopListLogo: {
    maxWidth: '60px',
    maxHeight: '40px',
    margin: 'auto',
  },

  missingLogo: {
    width: '60px',
  },

  merchantCard: {
    marginTop: '10px',
    height: '65px',
  },

  logoAndBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const TopMerchantsList = ({
  topMerchants,
  onMerchantSelected,
}: {
  topMerchants: SupplierWithServices[];
  onMerchantSelected(merchantId: SupplierId): void;
}) => {
  const classes = useStyles();

  const hasLogo = (logo?: string): boolean => !isNil(logo) && !isEmpty(logo);

  const logo = (merchant: SupplierWithServices) => (
    <div className={classes.merchantTopListLogoWrapper}>
      {hasLogo(merchant.logoUrl) ? (
        <img className={classes.merchantTopListLogo} src={merchant.logoUrl} alt={`logo-${merchant.name}`} />
      ) : (
        <div className={classes.missingLogo} />
      )}
    </div>
  );

  const availableMerchantCards = topMerchants.map((merchant) => {
    return (
      <Card
        key={merchant.id}
        className={classes.merchantCard}
        onClick={() => onMerchantSelected(merchant.id)}
        data-test="top-merchants-card"
      >
        <div className={classes.logoAndBodyContainer}>
          {logo(merchant)}
          <div className={classes.merchantContainer}>
            <Body>{merchant.name}</Body>
            <ChevronRightIcon />
          </div>
        </div>
      </Card>
    );
  });

  return <div>{availableMerchantCards}</div>;
};
