//TODO: Remove this file & OpBankConsentPageV1 when OP SEP is ready and replace with OpBankConsentPageV2. feat[opSEP]
import type { AppFeatureToggles } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { OpBankConsentPageV1 } from '@client/modules/onboarding/OnboardingPsd2/consent/OpBankConsentPage/ConsentPageV1';
import { OpBankConsentPageV2 } from '@client/modules/onboarding/OnboardingPsd2/consent/OpBankConsentPage/ConsentPageV2';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

interface OpBankConsentPageChooserProps {
  features: AppFeatureToggles;
}

const OpBankConsentPageChooser = ({ features }: OpBankConsentPageChooserProps) => {
  if (features.identify?.identifyOptIn) {
    return <OpBankConsentPageV2 />;
  } else {
    return <OpBankConsentPageV1 />;
  }
};

const mapStateToProps = (state: any) => ({
  features: selectorFeatures(state),
});

export const OpBankConsentPage = compose(connect(mapStateToProps))(OpBankConsentPageChooser);
