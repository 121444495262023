import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import * as React from 'react';

export interface BulletListItemProps {
  children: React.ReactNode;
}

export const BulletListItem: React.FunctionComponent<BulletListItemProps> = ({ children }) => (
  <li>
    <Body style={{ marginBottom: '0.5em' }}>{children}</Body>
  </li>
);

export interface BulletListProps {
  children: React.ReactNode;
}

export const BulletList: React.FunctionComponent<BulletListProps> = ({ children }) => <ul>{children}</ul>;
