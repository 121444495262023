import { withLocalization } from '@client/internationalization';
import type { SupplierService, SupplierWithServices } from '@client/models/supplier';
import type { AppContext } from '@client/modules/app-context';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { CancelElectricityGridContractConfirmationDialog } from '@client/modules/cancellation/CancellationModalsContainer/CancelElectricityGridContractConfirmationDialog';
import { InsuranceCancellationInformationDialog } from '@client/modules/cancellation/CancellationModalsContainer/InsuranceCancellationInformationDialog';
import { NotCancellableContractDialog } from '@client/modules/cancellation/CancellationModalsContainer/NotCancellableContractDialog';
import {
  CancellationAction,
  selectorContractToCancel,
  selectorElectricityConfirmationOpenDialog,
  selectorServiceToCancel,
  selectorSubscriptionToCancel,
  selectorSupplierToCancel,
} from '@client/modules/cancellation/duck';
import { CancellationConfirmationDialog } from '@client/modules/cancellation/models';
import { goToCancelContractPage } from '@client/modules/cancellation/onCancelContract';
import { selectorSentFeedback } from '@client/modules/saveDesk/state';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { goToCancelServicePage } from '../onCancelService';
import { SaveDeskDialog } from './SaveDeskDialog';

const CancellationModalsContainerInner: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const openDialog: CancellationConfirmationDialog = useSelector(selectorElectricityConfirmationOpenDialog);
  const requestedContractToCancel: Contract = useSelector(selectorContractToCancel);
  const requestedSubscriptionToCancel: Subscription = useSelector(selectorSubscriptionToCancel);
  const requestedServiceToCancel: SupplierService = useSelector(selectorServiceToCancel);
  const requestedSupplierToCancel: SupplierWithServices = useSelector(selectorSupplierToCancel);
  const selectorAskedForSaveDeskOffer: boolean = useSelector(selectorSentFeedback);
  const appContext: AppContext = useSelector(selectorAppContext);

  const goToCancelForm = () => {
    dispatch(CancellationAction.setCancellationConfirmationDialogOpen());
    if (requestedSubscriptionToCancel && requestedContractToCancel) {
      goToCancelContractPage(requestedSubscriptionToCancel.id, requestedContractToCancel, history);
    } else {
      goToCancelServicePage(requestedSupplierToCancel, requestedServiceToCancel, history, appContext);
    }
  };

  const closeDialog = () => {
    dispatch(CancellationAction.setCancellationConfirmationDialogOpen());
  };

  return (requestedContractToCancel && requestedSubscriptionToCancel) || requestedServiceToCancel ? (
    <>
      {openDialog === CancellationConfirmationDialog.NotCancellable && (
        <NotCancellableContractDialog
          open={openDialog === CancellationConfirmationDialog.NotCancellable}
          onClose={closeDialog}
          contract={requestedContractToCancel}
          subscription={requestedSubscriptionToCancel}
          service={requestedServiceToCancel}
          supplier={requestedSupplierToCancel}
        />
      )}
      {openDialog === CancellationConfirmationDialog.ElectricityGrid && (
        <CancelElectricityGridContractConfirmationDialog
          open={openDialog === CancellationConfirmationDialog.ElectricityGrid}
          onClose={closeDialog}
          onContinue={goToCancelForm}
          contract={requestedContractToCancel}
          service={requestedServiceToCancel}
        />
      )}
      {openDialog === CancellationConfirmationDialog.Insurance && (
        <InsuranceCancellationInformationDialog
          open={openDialog === CancellationConfirmationDialog.Insurance}
          onClose={closeDialog}
          onContinue={goToCancelForm}
        />
      )}
      {openDialog === CancellationConfirmationDialog.SaveDesk && (
        <SaveDeskDialog
          selectorAskedForSaveDeskOffer={selectorAskedForSaveDeskOffer}
          requestedSubscriptionToCancel={requestedSubscriptionToCancel}
          open={openDialog === CancellationConfirmationDialog.SaveDesk}
          goToCancelForm={goToCancelForm}
          requestedContractToCancel={requestedContractToCancel}
          closeDialog={closeDialog}
        />
      )}
    </>
  ) : null;
};

export const CancellationModalsContainer = withLocalization('cancellation/CancellationModalsContainer')(
  CancellationModalsContainerInner
);
