import type { OffsetDateTime } from '@client/models/OffsetDateTime';
import type { AmountWithCurrency } from '@client/models/subscription';
import type { PaymentInterval, Subscription } from '@client/modules/subscription/types';
import type { OrderedSubscription } from '@client/modules/switch/ordered-subscription/models';
import * as urls from '@client/routes';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

export enum ReconsentReason {
  TokenInvalid = 'TokenInvalid',
  ConsentInvalid = 'ConsentInvalid',
}

export interface StartFetchingTransactionsResponse {
  requiresReconsent: boolean;
  reconsentReason?: ReconsentReason;
}

export interface TotalCost {
  unit: PaymentInterval;
  cost: AmountWithCurrency;
}

export interface Overview {
  subscriptions: Subscription[];
  terminatedSubscriptions: Subscription[];
  orderedSubscriptions: OrderedSubscription[];
  totalCostPerInterval: TotalCost[];
  totalCostPerIntervalResolved: TotalCost[];
  lastBankDiscoverySyncAt?: OffsetDateTime;
}

export interface OverviewResponse {
  overview?: Overview;
  requiresReconsent: boolean;
}

export interface WebUiRedirectUrl {
  maybeRedirectUrl?: string;
}

export interface DanskeBankState {
  returnToPage?: string;
}

export function isReturnToPageRedirectUrlValid(returnToPage: string) {
  if (
    returnToPage == urls.onboardingPsd2Consent ||
    returnToPage == urls.onboardingPsd2ConsentExpired ||
    returnToPage == urls.settingsPage
  ) {
    return true;
  }

  return false;
}

export function redirectUserBackToOrigin(returnToPage: string, history: any) {
  if (isReturnToPageRedirectUrlValid(returnToPage)) {
    history.push(returnToPage);
  }
}

export async function fetchOverview(): Promise<Overview> {
  return axios.get('/api/user/overview').then(({ data }: AxiosResponse<Overview>) => data);
}

export async function fetchSyncedOverview(): Promise<OverviewResponse> {
  return axios.get('/api/user/synced-overview').then(({ data }: AxiosResponse<OverviewResponse>) => data);
}

/**
 * Fetches a Uri Path from the user's session to be used for redirection
 * More details in the documentation: https://minnatechnologies.atlassian.net/wiki/spaces/GOB/pages/1618247688/Sync+Flow
 */
export async function fetchSessionWebUiPath(): Promise<string | undefined> {
  return axios
    .get('/api/user/session-webuipath')
    .then(({ data }: AxiosResponse<WebUiRedirectUrl>) => data.maybeRedirectUrl);
}

export async function triggerSyncedOverviewPsd2(requestData: any) {
  return axios.post('/api/client-oauth/fetch-transactions', requestData);
}

export async function triggerSyncedOverviewWithoutPsd2AuthCode(): Promise<StartFetchingTransactionsResponse> {
  return axios
    .post('/api/client-oauth/fetch-transactions-without-code')
    .then(({ data }: AxiosResponse<StartFetchingTransactionsResponse>) => data);
}
