import React from 'react';
import Loadable from 'react-loadable';
import LoadingPage from '../../fetching/LoadingPage';

export const LazyLoadedConditionsAndPoliciesRoute = Loadable({
  loader: async () => import(/* webpackChunkName: "conditions-and-policies-route" */ './ConditionsAndPoliciesRoute'),
  loading: LoadingPage,
  render(loaded, props) {
    const ConditionsAndPoliciesRoute = loaded.ConditionsAndPoliciesRoute;

    // @ts-ignore
    return <ConditionsAndPoliciesRoute {...props} />;
  },
});
