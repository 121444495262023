import { LocalizedMessage } from '@client/internationalization';
import type { SupplierService, SupplierWithServices } from '@client/models/supplier';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import type { WithStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';

const styles = () =>
  createStyles({
    dialogBody: {
      margin: '24px 24px 0',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '16px',
    },
    dialogActions: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: '16px',
      height: '96px',
    },
  });

const NotCancellableContractDialogInner: React.FunctionComponent<
  NotCancellableContractDialogProps & WithStyles<typeof styles>
> = ({ open, onClose, contract, subscription, service, supplier }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={<LocalizedMessage id="notCancellableContractDialogTitle" />}
      button1={
        <PrimaryButton label={<LocalizedMessage id="notCancellableContractDialogCloseLabel" />} onClick={onClose} />
      }
    >
      <Body>
        <LocalizedMessage
          id="notCancellableContractDialogText"
          values={{
            provider: subscription?.supplier?.name || supplier?.name,
            category: contract?.service?.category?.text || service?.category.text,
          }}
        />
      </Body>
    </Modal>
  );
};

interface NotCancellableContractDialogProps {
  open: boolean;
  onClose(): void;
  contract?: Contract;
  subscription?: Subscription;
  service?: SupplierService;
  supplier?: SupplierWithServices;
}

export const NotCancellableContractDialog = withStyles(styles)(NotCancellableContractDialogInner);
