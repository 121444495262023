import onboardingDrawerCancel from '@client/assets/images/onboarding-drawer/cancel-waffle.svg';
import onboardingDrawerOkHand from '@client/assets/images/onboarding-drawer/ok-hand-waffle.svg';
import type { EndUserImages } from '@client/styles/themes/defaultTheme';
import { defaultThemeOptions } from '@client/styles/themes/defaultTheme';
import { createTheme } from '@minna-technologies/minna-ui/styles/themes/minna';
import { merge } from 'lodash';

const themeImages: EndUserImages = {
  onboardingDrawer: {
    cancel: onboardingDrawerCancel,
    okHand: onboardingDrawerOkHand,
  },
};

const themeOptions = merge({}, defaultThemeOptions, { themeImages });
export const ingTheme = createTheme<EndUserImages>(themeOptions);
