import { debouncePromiseWithDelay } from '@client/assets/js/utils/debounce-promise';
import type { OffsetDateTime } from '@client/models/OffsetDateTime';
import type { Subscription, SubscriptionId } from '@client/modules/subscription/types';
import type { OrderedSubscription } from '@client/modules/switch/ordered-subscription/models';
import { parseMoment } from '@client/utils/date';
import { isNil } from 'lodash/fp';
import type { Overview, TotalCost } from './model';
import { fetchOverview } from './model';

interface State {
  overview: OverviewState;
}

export interface OverviewState {
  lastBankDiscoverySyncAt?: OffsetDateTime;
  orderedSubscriptions: OrderedSubscription[];
  pageIsLoading: boolean;
  pageLoadingFailed: boolean;
  showSuccessAfterGuide?: boolean;
  subscriptions: Subscription[];
  terminatedSubscriptions: Subscription[];
  totalCostPerInterval: TotalCost[];
  totalCostPerIntervalResolved: TotalCost[];
  unresolvedSubscriptions: Subscription[];
}

export const initialState: OverviewState = {
  orderedSubscriptions: [],
  pageIsLoading: true,
  pageLoadingFailed: false,
  subscriptions: [],
  terminatedSubscriptions: [],
  totalCostPerInterval: [],
  totalCostPerIntervalResolved: [],
  unresolvedSubscriptions: [],
};

enum OverviewAction {
  CLEAR_OVERVIEW = 'OVERVIEW/CLEAR_OVERVIEW',
  PAGE_LOADING = 'OVERVIEW/PAGE_LOADING',
  PAGE_LOADING_FAILURE = 'OVERVIEW/PAGE_LOADING_FAILURE',
  PAGE_LOADING_SUCCESS = 'OVERVIEW/PAGE_LOADING_SUCCESS',
  UPDATE_OVERVIEW = 'OVERVIEW/UPDATE_OVERVIEW',
  UPDATE_UNRESOLVED_SUBSCRIPTIONS = 'OVERVIEW/UPDATE_UNRESOLVED_SUBSCRIPTIONS',
}

export function reducer(state: OverviewState = initialState, action: Action) {
  switch (action.type) {
    case OverviewAction.PAGE_LOADING:
      return {
        ...state,
        pageIsLoading: true,
      };
    case OverviewAction.PAGE_LOADING_FAILURE:
      return {
        ...state,
        pageIsLoading: false,
        pageLoadingFailed: true,
      };
    case OverviewAction.PAGE_LOADING_SUCCESS: {
      return {
        ...state,
        lastBankDiscoverySyncAt: !isNil(action.overview.lastBankDiscoverySyncAt)
          ? parseMoment(action.overview.lastBankDiscoverySyncAt)
          : undefined,
        pageIsLoading: false,
        pageLoadingFailed: false,
        subscriptions: action.overview.subscriptions,
        terminatedSubscriptions: action.overview.terminatedSubscriptions,
        orderedSubscriptions: action.overview.orderedSubscriptions,
        totalCostPerInterval: action.overview.totalCostPerInterval,
        totalCostPerIntervalResolved: action.overview.totalCostPerIntervalResolved,
      };
    }
    case OverviewAction.UPDATE_OVERVIEW: {
      return {
        ...state,
        pageIsLoading: false,
        subscriptions: action.overview.subscriptions,
        orderedSubscriptions: action.overview.orderedSubscriptions,
        totalCostPerInterval: action.overview.totalCostPerInterval,
        totalCostPerIntervalResolved: action.overview.totalCostPerIntervalResolved,
      };
    }
    case OverviewAction.UPDATE_UNRESOLVED_SUBSCRIPTIONS: {
      return {
        ...state,
        unresolvedSubscriptions: action.overview.unresolvedSubscriptions,
        showSuccessAfterGuide: action.overview.showSuccessAfterGuide,
      };
    }
    case OverviewAction.CLEAR_OVERVIEW: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}

// Actions

interface PageLoadingAction {
  type: OverviewAction.PAGE_LOADING;
}

export const pageLoading = (): PageLoadingAction => ({ type: OverviewAction.PAGE_LOADING });

interface PageLoadingFailureAction {
  type: OverviewAction.PAGE_LOADING_FAILURE;
}

export const pageLoadingFailure = (): PageLoadingFailureAction => ({ type: OverviewAction.PAGE_LOADING_FAILURE });

interface PageLoadingSuccessAction {
  type: OverviewAction.PAGE_LOADING_SUCCESS;
  overview: Overview;
}

export const pageLoadingSuccess = (overview: Overview): PageLoadingSuccessAction => ({
  type: OverviewAction.PAGE_LOADING_SUCCESS,
  overview,
});

interface UpdateOverviewAction {
  type: OverviewAction.UPDATE_OVERVIEW;
  overview: Overview;
}

export const updateOverview = (overview: Overview): UpdateOverviewAction => ({
  type: OverviewAction.UPDATE_OVERVIEW,
  overview,
});

interface UpdateUnresolvedSubscriptionsAction {
  type: OverviewAction.UPDATE_UNRESOLVED_SUBSCRIPTIONS;
  overview: {
    showSuccessAfterGuide: boolean;
    unresolvedSubscriptions: Subscription[];
  };
}

export const updateUnresolvedSubscriptions = (overview: {
  showSuccessAfterGuide: boolean;
  unresolvedSubscriptions: Subscription[];
}): UpdateUnresolvedSubscriptionsAction => ({
  type: OverviewAction.UPDATE_UNRESOLVED_SUBSCRIPTIONS,
  overview,
});

interface ClearOverviewAction {
  type: OverviewAction.CLEAR_OVERVIEW;
}

export const clearOverview = (): ClearOverviewAction => ({ type: OverviewAction.CLEAR_OVERVIEW });

type Action =
  | ClearOverviewAction
  | PageLoadingAction
  | PageLoadingFailureAction
  | PageLoadingSuccessAction
  | UpdateOverviewAction
  | UpdateUnresolvedSubscriptionsAction;

// Selectors

export const selectorSubscriptionById =
  (subscriptionId: SubscriptionId) =>
  (state: State): Subscription | undefined =>
    state.overview.subscriptions?.find((subscription: Subscription) => subscription.id === subscriptionId);

export const selectorSubscriptions = (state: State): Subscription[] => state.overview.subscriptions;

export const selectorUnresolvedSubscriptions = (state: State): Subscription[] =>
  state.overview.subscriptions?.filter((subscription: Subscription) => subscription.contracts.length === 0);

export const selectorUnresolvedSubscriptionsForGuide = (state: State): Subscription[] =>
  state.overview.unresolvedSubscriptions;

export const selectorShowSuccessModalAfterGuide = (state: State): boolean => !!state.overview.showSuccessAfterGuide;

export const selectorTerminatedSubscriptions = (state: State): Subscription[] => state.overview.terminatedSubscriptions;

export const selectorIsLoading = (state: State): boolean =>
  state.overview.pageIsLoading || (!state.overview.pageLoadingFailed && !state.overview.subscriptions);

export const selectorIsPageLoading = (state: State): boolean => state.overview.pageIsLoading;

export const selectorLoadingFailed = (state: State): boolean => state.overview.pageLoadingFailed;

export const selectorTotalCostPerInterval = (state: State): TotalCost[] => state.overview.totalCostPerInterval;

export const selectorTotalCostPerIntervalResolved = (state: State): TotalCost[] =>
  state.overview.totalCostPerIntervalResolved;

export const selectorOrderedSubscriptions = (state: State): OrderedSubscription[] =>
  state.overview.orderedSubscriptions;

export const selectorLastBankDiscoveryDate = (state: State): OffsetDateTime | undefined =>
  state.overview.lastBankDiscoverySyncAt;

export const getAndRefreshOverview: () => Promise<Overview> = debouncePromiseWithDelay(3000, async () => {
  return fetchOverview();
});
