import Sentry from '@client/assets/js/sentry';
import { saveUserServiceIntroductionSeen, selectorQuickCancelServiceExplored, setUser } from '@client/ducks/user';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { SubscriptionListItemWithActions } from '@client/modules/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions';
import { ContractType } from '@client/modules/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/ContractInformation/ContractInformation';
import { QuickActionCancelDrawer } from '@client/modules/QuickActions/CancelDrawer';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import { FrontendSubscriptionType } from '@client/modules/subscription/types';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_OVERVIEW } from '@client/tracking/mixpanel-constants';
import type { WithStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../styles.scss';

interface QuickActionsCancelComponentProps {
  cancellableSubscriptions: CancellableSubscriptions;
  handleContractClick(subscription: Subscription, contract: Contract): void;
  handleSubscriptionClick(subscription: Subscription): void;
  handleAddManualSubscriptionClick(): void;
  onBackClick(): void;
  onContractCancelClick(subscription: Subscription, contract: Contract): void;
  onHelpClick?(): void;
}

type QuickActionsCancelInnerComponentProps = QuickActionsCancelComponentProps & WithStyles<typeof styless>;

export interface CancellableSubscriptions {
  list: Subscription[];
  count: number;
}

const styless = {
  paperContainer: {
    margin: '16px 0',
  },
  title: {
    margin: '8px auto 8px auto',
    textAlign: 'center' as const,
  },
  body: {
    margin: '40px auto 24px auto',
    textAlign: 'center' as const,
  },
};

/* eslint-disable  */
const QuickActionsCancelPageComponentInner: React.FunctionComponent<QuickActionsCancelInnerComponentProps> = ({
  classes,
  onBackClick,
  onContractCancelClick,
  handleSubscriptionClick,
  handleAddManualSubscriptionClick,
  handleContractClick,
  onHelpClick,
  cancellableSubscriptions,
}: QuickActionsCancelInnerComponentProps) => {
  const [cancelDrawerOpen, setCancelDrawerOpen] = useState(false);
  const shouldOpenQuickCancelDrawer = !useSelector(selectorQuickCancelServiceExplored);
  const dispatch = useDispatch();
  useEffect(() => {
    if (shouldOpenQuickCancelDrawer) {
      getMixpanel().track(EventNames.VIEWED_GUIDE, {
        Feature: FEATURE_OVERVIEW,
        Page: 'Cancel quick action',
      });
      setCancelDrawerOpen(true);
    }
  }, []);
  const handleDrawerClose = () => {
    setCancelDrawerOpen(false);
    saveUserServiceIntroductionSeen('ViewedQuickCancelServiceIntroduction')
      .then((user) => dispatch(setUser(user)))
      .catch((error) => {
        Sentry.captureExceptionWithMessage(error, 'Failed to save service introduction page viewed');
      });
  };
  const cancellableCards = cancellableSubscriptions.list.map((subscription) => (
    <div key={subscription.id} className={styles.listItem}>
      <SubscriptionListItemWithActions
        subscription={subscription}
        onSubscriptionClick={handleSubscriptionClick}
        navigateToContractDetailsPage={onContractCancelClick}
        contractType={ContractType.Cancelable}
        subscriptionType={FrontendSubscriptionType.OtherSubscription}
        onContractClick={(contract) => {
          handleContractClick(subscription, contract);
        }}
      />
    </div>
  ));
  const contentWithCards = <div className={styles.container}>{cancellableCards}</div>;
  const emptyContent = (
    <Card variant="border" className={classes.paperContainer}>
      <Title className={classes.title}>
        <LocalizedMessage id={'emptyContentTitle'} />
      </Title>
      <Body className={classes.body}>
        <LocalizedMessage id={'emptyContentBody'} />
      </Body>
      <SecondaryButton
        fullWidth
        onClick={handleAddManualSubscriptionClick}
        label={<LocalizedMessage id={'emptyContentButton'} />}
      />
    </Card>
  );
  const headerTitle =
    cancellableSubscriptions.count > 0 ? (
      <LocalizedMessage id="headerTitle" />
    ) : (
      <LocalizedMessage id="headerTitleEmptyContent" />
    );

  return (
    <MultiDeviceLayout
      hasTopNavigation={false}
      title={headerTitle}
      footer={<FooterNavigation onBackClick={onBackClick} onHelpClick={onHelpClick} />}
    >
      {cancellableSubscriptions.count > 0 ? contentWithCards : emptyContent}
      <QuickActionCancelDrawer open={cancelDrawerOpen} onClose={handleDrawerClose} />
    </MultiDeviceLayout>
  );
};

export const QuickActionsCancelPageComponent = withLocalization('overview/QuickActionsCancelV2')(
  withStyles(styless)(QuickActionsCancelPageComponentInner)
);
