import bankidSecureSvg from '@client/assets/images/icons/bankid-secure.svg';
import { LocalizedMessage } from '@client/internationalization';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { SslCertifiedIcon } from '@client/modules/shared-components/icons/SslCertifiedIcon';
import { makeStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '16px auto 0',
  },
  header: {
    marginBottom: '24px',
    textAlign: 'center',
    margin: '2vh',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '222px',
    marginTop: '16px',
  },
  text: {
    maxWidth: 250,
    marginBottom: 48,
    textAlign: 'center',
  },
}));

interface NoSubscriptionsSectionProps {
  goToAddManualSubscription(): void;
}

export const NoSubscriptionsSection: React.FC<NoSubscriptionsSectionProps> = ({ goToAddManualSubscription }) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const features = useSelector(selectorFeatures);

  return (
    <Card className={classes.container}>
      <Title className={classes.header}>
        <LocalizedMessage id="noSubscriptions" />
      </Title>
      <Body color={colors.textOn.surfaceSubdued} className={classes.text}>
        <LocalizedMessage id="noSubscriptionsAddSubscriptionDescription" />
      </Body>

      {features.identify?.overview?.manuallyAddedSubscriptions && (
        <PrimaryButton
          data-test="add-manual-subscription"
          label={<LocalizedMessage id="addSubscriptionButton" />}
          onClick={goToAddManualSubscription}
          fullWidth
        />
      )}
      <div className={classes.icons}>
        {features.identify?.overview?.sslCertifiedIcon && <SslCertifiedIcon />}
        {features.identify?.overview?.bankIdLogo && <img src={bankidSecureSvg} alt="" role="presentation" />}
      </div>
    </Card>
  );
};
