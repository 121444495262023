import type { AmountWithCurrency } from '@client/models/subscription';
import type {
  MobileImproveFlowInformation,
  MobileProduct,
  MobileSubscriptionFilter,
} from '@client/modules/switch/mobile/models';
import { MinimumAirTimeUnit } from '@client/modules/switch/mobile/models';
import type { ActionsUnion } from '@client/utils/redux';
import { createAction } from '@client/utils/redux';

export interface MobileGuideState {
  productsIsLoading?: boolean;
  productsLoadingFailed?: boolean;
  sortedMobileProducts?: MobileProduct[];
  chosenProduct?: MobileProduct;
  filter: MobileSubscriptionFilter;
  agreementType?: string;
  placingOrder: boolean;
  flowInformation?: MobileImproveFlowInformation;
  currentContractPricePerMonth?: AmountWithCurrency;
}

const initialState: MobileGuideState = {
  filter: {
    requestedDataCapacity: 4,
    currentDataCapacity: 4,
    minimumAirTime: 8,
    minimumAirTimeUnit: MinimumAirTimeUnit.HOURS,
  },
  placingOrder: false,
};

/*
 * ACTIONS AND ACTION CREATORS
 */
enum ActionKeys {
  UPDATE_FILTER_PROPERTY = 'MOBILE_GUIDE/UPDATE_FILTER_PROPERTY',
  UPDATE_AGREEMENT_TYPE = 'MOBILE_GUIDE/UPDATE_AGREEMENT_TYPE',
  SUPPLIERS_WITH_PRODUCTS_LOADING = 'MOBILE_GUIDE/SUPPLIERS_WITH_PRODUCTS_LOADING',
  SUPPLIERS_WITH_PRODUCTS_LOADING_SUCCESS = 'MOBILE_GUIDE/SUPPLIERS_WITH_PRODUCTS_LOADING_SUCCESS',
  SUPPLIERS_WITH_PRODUCTS_LOADING_FAILURE = 'MOBILE_GUIDE/SUPPLIERS_WITH_PRODUCTS_LOADING_FAILURE',
  SET_CHOSEN_PRODUCT = 'MOBILE_GUIDE/SET_CHOSEN_PRODUCT',
  SET_CURRENT_CONTRACT_PRICE_PER_MONTH = 'MOBILE_GUIDE/SET_CURRENT_CONTRACT_PRICE_PER_MONTH',
  SET_INITIAL_ORDER_PAGE_STATE = 'MOBILE_GUIDE/SET_INITIAL_ORDER_PAGE_STATE',
  SET_PLACING_ORDER = 'MOBILE_GUIDE/SET_PLACING_ORDER',
  SET_INITIAL_STATE = 'MOBILE_GUIDE/SET_INITIAL_STATE',
  SET_FLOW_INFORMATION = 'SET_FLOW_INFORMATION',
}

export const MobileGuideActions = {
  updateFilterProperty: (filterField: keyof MobileSubscriptionFilter, filterValue: any) =>
    createAction(ActionKeys.UPDATE_FILTER_PROPERTY, { filterField, filterValue }),
  updateAgreementType: (agreementType: string, currentContractPricePerMonth: AmountWithCurrency) =>
    createAction(ActionKeys.UPDATE_AGREEMENT_TYPE, { agreementType, currentContractPricePerMonth }),
  suppliersWithProductsLoading: (loading: boolean) => createAction(ActionKeys.SUPPLIERS_WITH_PRODUCTS_LOADING, loading),
  suppliersWithProductsLoadingFailure: () => createAction(ActionKeys.SUPPLIERS_WITH_PRODUCTS_LOADING_FAILURE),
  suppliersWithProductsLoadingSuccess: (suppliersWithProducts: any[]) =>
    createAction(ActionKeys.SUPPLIERS_WITH_PRODUCTS_LOADING_SUCCESS, suppliersWithProducts),
  setChosenProduct: (product: MobileProduct) => createAction(ActionKeys.SET_CHOSEN_PRODUCT, product),
  setCurrentContractPricePerMonth: (currentContractPricePerMonth?: AmountWithCurrency) =>
    createAction(ActionKeys.SET_CURRENT_CONTRACT_PRICE_PER_MONTH, { currentContractPricePerMonth }),
  setInitialOrderPageState: () => createAction(ActionKeys.SET_INITIAL_ORDER_PAGE_STATE),
  setPlacingOrder: (placingOrder: boolean) => createAction(ActionKeys.SET_PLACING_ORDER, placingOrder),
  setInitialState: () => createAction(ActionKeys.SET_INITIAL_STATE),
  setFlowInformation: (flowInformation: MobileImproveFlowInformation) =>
    createAction(ActionKeys.SET_FLOW_INFORMATION, flowInformation),
};

export type Actions = ActionsUnion<typeof MobileGuideActions>;

// eslint-disable-next-line , complexity, ,
export function reducer(state: MobileGuideState = initialState, action: Actions): MobileGuideState {
  switch (action.type) {
    case ActionKeys.UPDATE_FILTER_PROPERTY: {
      return {
        ...state,
        filter: { ...state.filter, [action.payload.filterField]: action.payload.filterValue },
      };
    }
    case ActionKeys.UPDATE_AGREEMENT_TYPE: {
      return {
        ...state,
        agreementType: action.payload.agreementType,
      };
    }
    case ActionKeys.SUPPLIERS_WITH_PRODUCTS_LOADING:
      return {
        ...state,
        productsIsLoading: action.payload,
      };
    case ActionKeys.SUPPLIERS_WITH_PRODUCTS_LOADING_FAILURE:
      return {
        ...state,
        productsIsLoading: false,
        productsLoadingFailed: true,
      };
    case ActionKeys.SET_FLOW_INFORMATION: {
      return {
        ...state,
        flowInformation: action.payload,
      };
    }
    case ActionKeys.SUPPLIERS_WITH_PRODUCTS_LOADING_SUCCESS: {
      return {
        ...state,
        productsIsLoading: false,
        productsLoadingFailed: false,
        sortedMobileProducts: action.payload,
      };
    }
    case ActionKeys.SET_CHOSEN_PRODUCT: {
      return {
        ...state,
        chosenProduct: action.payload,
      };
    }
    case ActionKeys.SET_CURRENT_CONTRACT_PRICE_PER_MONTH: {
      return {
        ...state,
        currentContractPricePerMonth: action.payload.currentContractPricePerMonth,
      };
    }
    case ActionKeys.SET_PLACING_ORDER: {
      return {
        ...state,
        placingOrder: action.payload,
      };
    }
    case ActionKeys.SET_INITIAL_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

interface State {
  mobileGuide: MobileGuideState;
}

// selectors
export const selectorFilter = (state: State) => state.mobileGuide.filter;
export const selectorAgreementType = (state: State) => state.mobileGuide.agreementType;
export const selectorCurrentContractPricePerMonth = (state: State) => state.mobileGuide.currentContractPricePerMonth;
export const selectorSuppliersWithProductsIsLoading = (state: State) => state.mobileGuide.productsIsLoading || false;
export const selectorSuppliersWithProductsLoadingFailed = (state: State) => state.mobileGuide.productsLoadingFailed;
export const selectorChosenMobileProduct = (state: State) => state.mobileGuide.chosenProduct;
export const selectorSuppliersWithProducts = (state: State) => state.mobileGuide.sortedMobileProducts || [];
export const selectorPlacingOrder = (state: State) => state.mobileGuide.placingOrder;
export const selectorFlowInformation = (state: State) => state.mobileGuide.flowInformation;
