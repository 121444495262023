import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { AmountWithCurrency } from '@client/models/subscription';
import type { WithStyles } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardLayout } from '@minna-technologies/minna-ui/components/Layouts/CardLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import { InfoIcon } from '@minna-technologies/minna-ui/icons/Info';
import PropTypes from 'prop-types';
import type { ReactElement } from 'react';
import React from 'react';

const styles = createStyles({
  costRow: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  alignHorizontally: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  details: {
    margin: '0 -8px',
  },
  marginBottom: {
    marginBottom: 16,
  },
});

export interface LabelValue {
  label: string;
  value: string;
}

export interface ProductInfo {
  title?: string;
  labelValues: LabelValue[];
}

interface OrderedSubscriptionInfoCardInnerProps {
  monthlyCost?: AmountWithCurrency;
  discountedCost?: AmountWithCurrency;
  discountPeriod?: number;
  productInfos: ProductInfo[];
  extra?: string[];
  estimatedCost?: boolean;
  onGoToTermsAndConditionsClick?(): void;
  onInfoClick?(): void;
}

const OrderedSubscriptionInfoCardInner: React.FunctionComponent<
  OrderedSubscriptionInfoCardInnerProps & WithStyles<typeof styles>
> = (
  {
    monthlyCost,
    onGoToTermsAndConditionsClick,
    productInfos,
    onInfoClick,
    extra = [],
    estimatedCost,
    discountedCost,
    discountPeriod,
    classes,
  },
  { localizeCostWithCurrency }
) => {
  const { colors } = useTheme();
  const extraItems = extra?.map((item, index) => (
    <Caption key={index} color={colors.textOn.surfaceSubdued}>{`+ ${item}`}</Caption>
  ));

  const infoCards = productInfos.map((productInfo, index) => (
    <ProductInfoCard key={index} title={productInfo.title} labelValueItems={productInfo.labelValues} />
  ));

  return (
    <>
      {estimatedCost && (
        <Caption color={colors.textOn.surfaceSubdued}>
          <LocalizedMessage id="estimatedMonthlyCost" />
        </Caption>
      )}
      <CardLayout>
        <div className={classes.alignHorizontally}>
          <div>
            {(discountedCost || monthlyCost) && (
              <Headline2 color={colors.action.primary}>
                <LocalizedMessage
                  id="perMonth"
                  values={{ monthlyCost: localizeCostWithCurrency(discountedCost || monthlyCost) }}
                />
              </Headline2>
            )}
            {discountPeriod && (
              <Caption color={colors.textOn.surfaceSubdued} inline>
                <LocalizedMessage id="discountPeriod" values={{ months: discountPeriod }} />
              </Caption>
            )}
            {discountedCost && (
              <Caption color={colors.textOn.surfaceSubdued} inline>
                <LocalizedMessage id="regularPrice" values={{ cost: localizeCostWithCurrency(monthlyCost) }} />
              </Caption>
            )}
            {extraItems}
          </div>
          {onInfoClick && <InfoIcon nativeColor={colors.action.primary} onClick={onInfoClick} />}
        </div>
        <div className={classes.details}>
          {infoCards}
          {onGoToTermsAndConditionsClick && (
            <Card className={classes.alignHorizontally} onClick={onGoToTermsAndConditionsClick} variant="border">
              <Title>
                <LocalizedMessage id="termsAndConditions" />
              </Title>
              <ChevronRightIcon />
            </Card>
          )}
        </div>
      </CardLayout>
    </>
  );
};

OrderedSubscriptionInfoCardInner.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
};

export const OrderedSubscriptionInfoCard = withLocalization('orderedSubscription/OrderedSubscriptionInfoCard')(
  withStyles(styles)(OrderedSubscriptionInfoCardInner)
);

interface ProductInfoCardProps {
  title?: string | ReactElement;
  labelValueItems: LabelValue[];
}
const ProductInfoCardInner: React.FunctionComponent<ProductInfoCardProps & WithStyles<typeof styles>> = ({
  title,
  labelValueItems,
  classes,
}) => {
  return (
    <Card variant="border" className={classes.marginBottom}>
      <Body variant="bold">{title}</Body>
      {labelValueItems.map((item, index) => (
        <div className={classes.costRow} key={index}>
          <Body>{item.label}</Body>
          <Body variant="bold">{item.value}</Body>
        </div>
      ))}
    </Card>
  );
};

const ProductInfoCard: React.ComponentType<ProductInfoCardProps> = withStyles(styles)(ProductInfoCardInner);
