import { onEnter } from '@client/containers/container-helpers/onEnter';
import { acceptPrivacyPolicyAndTermsOfUse, selectorAcceptedPrivacyPolicyAndTermsOfUse } from '@client/ducks/user';
import { withFeatures } from '@client/feature-toggling';
import { withLocalization } from '@client/internationalization';
import type { AppFeatureToggles } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import * as urls from '@client/routes';
import type { History } from 'history';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import type { Dispatch } from 'redux';
import { WelcomePage } from './component';

const mapStateToProps = (state: any) => ({
  features: selectorFeatures(state),
  agreedToTerms: selectorAcceptedPrivacyPolicyAndTermsOfUse(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { history }: { history: History }) => ({
  onNavigateToNextPage: () => {
    history.push(urls.onboardingPsd2Consent);
  },
  onAgreeToPrivacyPolicyAndTermsOfUseChanged: (didAgree: boolean) => {
    dispatch(acceptPrivacyPolicyAndTermsOfUse(didAgree));
  },
});

export const WelcomePageContainer = compose(
  withRouter,
  withFeatures,
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('onboarding-psd2/WelcomePage'),
  onEnter(({ features, onNavigateToNextPage }: { features: AppFeatureToggles; onNavigateToNextPage: () => void }) => {
    if (!features.syncFlow?.showWelcomeScreen) {
      onNavigateToNextPage();
    }
  })
)(WelcomePage);
