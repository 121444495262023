import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { makeStyles } from '@material-ui/core/styles';
import { StarRating } from '@minna-technologies/minna-ui/components/StarRating';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  ratingContent: {
    display: 'flex',
    justifyContent: 'center',
    margin: '32px 16px 16px 16px',
    backgroundColor: theme.colors.base.background,
    borderRadius: theme.borderRadius,
    paddingTop: '24px',
    flexDirection: 'column',
  },
  ratingBox: {
    marginBottom: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '56px',
    justifyContent: 'space-between',
  },
}));

interface RatingBoxProps {
  cancel?: boolean;
  overview?: boolean;
  discover?: boolean;
  overviewLabel?: React.ReactNode | string;
  discoverLabel?: React.ReactNode | string;
  cancelLabel?: React.ReactNode | string;
  onOverviewRatingSet?: (rating: number) => void;
  onCancelRatingSet?: (rating: number) => void;
  onDiscoverRatingSet?: (rating: number) => void;
}

export const Component: React.FunctionComponent<RatingBoxProps> = ({
  cancel,
  overview,
  discover,
  overviewLabel,
  discoverLabel,
  cancelLabel,
  onOverviewRatingSet,
  onCancelRatingSet,
  onDiscoverRatingSet,
}: RatingBoxProps) => {
  const classes = useStyles();
  const [overviewRating, setOverviewRating] = useState(0);
  const [cancelRating, setCancelRating] = useState(0);
  const [discoverRating, setDiscoverRating] = useState(0);
  const onOverviewRatingChange = (newRating: number) => {
    setOverviewRating(newRating);
    if (onOverviewRatingSet) onOverviewRatingSet(newRating);
  };
  const onCancelRatingChange = (newRating: number) => {
    setCancelRating(newRating);
    if (onCancelRatingSet) onCancelRatingSet(newRating);
  };
  const onDiscoverRatingChange = (newRating: number) => {
    setDiscoverRating(newRating);
    if (onDiscoverRatingSet) onDiscoverRatingSet(newRating);
  };

  return (
    <div className={classes.ratingContent}>
      {overview && (
        <div className={classes.ratingBox}>
          <div>
            <Body variant={'bold'}>{overviewLabel ? overviewLabel : <LocalizedMessage id={'overviewService'} />}</Body>
          </div>
          <StarRating currentRating={overviewRating} onRatingChange={onOverviewRatingChange} />
        </div>
      )}
      {cancel && (
        <div className={classes.ratingBox}>
          <div>
            <Body variant={'bold'}>{cancelLabel ? cancelLabel : <LocalizedMessage id={'cancelService'} />}</Body>
          </div>
          <StarRating currentRating={cancelRating} onRatingChange={onCancelRatingChange} />
        </div>
      )}
      {discover && (
        <div className={classes.ratingBox}>
          <div>
            <Body variant={'bold'}>{discoverLabel ? discoverLabel : <LocalizedMessage id={'discoverService'} />}</Body>
          </div>
          <StarRating currentRating={discoverRating} onRatingChange={onDiscoverRatingChange} />
        </div>
      )}
    </div>
  );
};

export const RatingBox = withLocalization('feedback-modal')(Component);
