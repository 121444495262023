// @ts-ignore js import, remove this when the import is typed
import { loadingPageWhileLoading, onEnter, onPropsChanged } from '@client/containers/container-helpers';
import type { AppContext } from '@client/modules/app-context';
import { getIngBankContactUrl } from '@client/utils/support';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import { AppType } from '../../../../app-context/constants';
import { withAppContext } from '../../../../app-context/react';
// @ts-ignore js import, remove this when the import is typed
import { navigateToCategory, navigateToContactUs } from '../../../ducks';
import {
  filterArticlesByLabels,
  selectorCategories,
  selectorLabels,
  selectorLoadingArticles,
  selectorLoadingCategories,
} from '../../../ducks/zendesk-help-center';
import { StartScreen } from './component';

const mapStateToProps = (state: any) => ({
  categories: selectorCategories(state),
  labels: selectorLabels(state),
  loadingArticles: selectorLoadingArticles(state),
  loadingCategories: selectorLoadingCategories(state),
});

const mapDispatchToProps = (dispatch: Dispatch, { appContext }: { appContext: AppContext }) => ({
  onNavigateToContactUs: () => {
    // TODO: use feature toggle when adding full support for 'contact your bank' in Zendesk
    if (appContext.appType === AppType.ING) {
      window.open(getIngBankContactUrl(appContext.locale), '_blank', 'noopener noreferrer external nofollow');
    } else {
      dispatch(navigateToContactUs());
    }
  },
  onNavigateToCategory: (categoryId: string) => {
    dispatch(navigateToCategory(categoryId));
  },
});

interface StartScreenContainerProps {
  appContext: AppContext;
  labels: any;
}

export const StartScreenContainer = compose(
  withAppContext,
  connect(mapStateToProps, mapDispatchToProps),
  onEnter(({ appContext, labels }: StartScreenContainerProps, { store }: any) => {
    store.dispatch(filterArticlesByLabels(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, labels));
  }),
  onPropsChanged(
    (previousProps: any, nextProps: any) => !isEqual(previousProps.labels, nextProps.labels),
    ({ appContext, labels }: StartScreenContainerProps, { store }: any) => {
      store.dispatch(filterArticlesByLabels(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, labels));
    }
  ),
  loadingPageWhileLoading((p: any) => p.loadingArticles || p.loadingCategories)
  // @ts-ignore
)(StartScreen);
