import type { AppContext } from '@client/modules/app-context';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardRowLink } from '@minna-technologies/minna-ui/components/CardRowLink';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import React from 'react';
import { AppType } from '../../../../app-context/constants';
import { ContactUsButton } from '../ContactUsButton';

const useStyles = makeStyles((theme) => ({
  startScreenContainer: {
    marginBottom: theme.spacing(2),
  },
}));

interface StartScreenProps {
  appContext: AppContext;
  categories: [any];
  onNavigateToContactUs(): void;
  onNavigateToCategory(): void;
}

interface BrowseHelpCenterProps {
  categories: [any];
  onNavigateToCategory(categoryId: string): void;
}

export const StartScreen: React.FunctionComponent<StartScreenProps> = ({
  appContext,
  categories,
  onNavigateToContactUs,
  onNavigateToCategory,
}) => {
  const features = appContext.features;
  // TODO: use feature toggle when adding full support for 'contact your bank' in Zendesk
  const isContactSupportEnabled =
    features.helpCenter &&
    (features.helpCenter.chatEnabled ||
      features.helpCenter.callUsEnabled ||
      features.helpCenter.callBackEnabled ||
      features.helpCenter.emailUsEnabled ||
      appContext.appType === AppType.ING);

  return (
    <div>
      <BrowseHelpCenter categories={categories} onNavigateToCategory={onNavigateToCategory} />
      <Divider />
      {isContactSupportEnabled && <ContactUsButton onNavigateToContactUs={onNavigateToContactUs} />}
    </div>
  );
};

const BrowseHelpCenter: React.FunctionComponent<BrowseHelpCenterProps> = ({ categories, onNavigateToCategory }) => {
  const classes = useStyles();
  const categoriesLength = categories.length;

  return (
    <div className={classes.startScreenContainer}>
      <Card variant="border" noPadding data-test="zendesk-help-center-start-screen-div">
        {categories.map((category, index) => (
          <div key={category.id}>
            <CardRowLink onClick={() => onNavigateToCategory(category.id)} body={category.name} />
            {index !== categoriesLength - 1 && <Divider slim />}
          </div>
        ))}
      </Card>
    </div>
  );
};
