import { Sentry } from '@client/assets/js/sentry';
import type { AppContext } from '@client/modules/app-context';
import { Market } from '@client/modules/app-context/constants';
import { withAppContext } from '@client/modules/app-context/react';
import type { ImgHTMLAttributes } from 'react';
import React from 'react';
import svSeIcon from './localization/ssl-certified.sv-SE.svg';

export const SslCertifiedIcon = withAppContext(
  ({
    appContext,
    style,
  }: {
    appContext: AppContext;
    style?: React.DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  }) => {
    if (appContext.market !== Market.Sweden) {
      Sentry.captureMessage('Showing Swedish SSL-icon in other market than Sweden', {
        extra: { locale: appContext.locale, market: appContext.market },
      });
    }

    return <img src={svSeIcon} style={style} alt="" role="presentation" />;
  }
);
