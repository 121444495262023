import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';

const RippleButton = ({ style, children, onClick }: any) => {
  const enhancedButtonStyle = {
    border: 0,
    display: 'flex',
    flexDirection: 'inherit',
    textTransform: 'none',
    fontWeight: 'inherit',
    padding: 0,
    ...style,
  };

  return (
    <Button
      component="div"
      classes={{ label: styles.fullWidthLabel }}
      style={enhancedButtonStyle}
      disabled={!onClick}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

RippleButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

// eslint-disable-next-line import/no-default-export
export default RippleButton;
