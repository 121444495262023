import type { CategoryName } from '@client/constants/categories';
import { selectorAppContext } from '@client/modules/app-context/duck';
import {
  CancellationReasonsDialogComponent,
  isItApplicableToShowCancellationReasonsDialog,
} from '@client/modules/cancellation/CancellationReasonsDialog/component';
import { sendViewedGuideMixpanelEvent } from '@client/modules/cancellation/CancellationReasonsDialog/mixpanel';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

interface CancellationReasonsDialogProps {
  providerName: string;
  categoryName: CategoryName;
  saveDeskEnabled: boolean;
}

export const CancellationReasonsDialog: React.FunctionComponent<CancellationReasonsDialogProps> = ({
  providerName,
  categoryName,
  saveDeskEnabled,
}) => {
  const [open, setOpen] = React.useState(true);
  const appContext = useSelector(selectorAppContext);
  const applicableToShowDialog = useMemo(
    () =>
      isItApplicableToShowCancellationReasonsDialog(
        appContext.market,
        appContext.appType,
        categoryName,
        saveDeskEnabled
      ),
    [categoryName, saveDeskEnabled, appContext.appType, appContext.market]
  );

  useEffect(() => {
    if (applicableToShowDialog && open) {
      sendViewedGuideMixpanelEvent(categoryName, providerName);
    }
  }, [open, applicableToShowDialog, categoryName, providerName]);

  return (
    <>
      {applicableToShowDialog && (
        <CancellationReasonsDialogComponent
          categoryName={categoryName}
          providerName={providerName}
          dialogOpen={open}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};
