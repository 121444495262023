import onboardingDrawerCancel from '@client/assets/images/onboarding-drawer/cancel.svg';
import onboardingDrawerOkHand from '@client/assets/images/onboarding-drawer/ok-hand.svg';
import type { Theme, ThemeOptions } from '@material-ui/core/styles';
import { createTheme, minnaThemeOptions } from '@minna-technologies/minna-ui/styles/themes/minna';
import { merge } from 'lodash';

/**
 * Images that can be customized per customer
 */
export interface EndUserImages {
  onboardingDrawer: {
    cancel: string;
    okHand: string;
  };
}

/**
 * Default images in case a custom theme is not used
 */
const themeImages: EndUserImages = {
  onboardingDrawer: {
    cancel: onboardingDrawerCancel,
    okHand: onboardingDrawerOkHand,
  },
};

export const defaultThemeOptions: ThemeOptions<EndUserImages> = merge({}, minnaThemeOptions, { themeImages });

/**
 * The default theme that will be applied if no custom theming is implemented
 */
export const defaultTheme: Theme<EndUserImages> = createTheme<EndUserImages>(defaultThemeOptions);
