import { LocalizedMessage } from '@client/internationalization';
import { RejectedReason } from '@client/models/order';
import type { WithStyles } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import PropTypes from 'prop-types';
import React from 'react';

export interface FailedOrderContentInnerProps {
  newServiceProviderName: string;
  serviceProviderName?: string;
  rejectedReason?: RejectedReason;
  bindingTimeEndsAt?: string;
  message?: string;
}

const styles = createStyles({
  sectionMargin: {
    margin: '8px 0',
  },
  message: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
});

const FailedOrderContentInner: React.FunctionComponent<FailedOrderContentInnerProps & WithStyles<typeof styles>> = (
  { classes, serviceProviderName, newServiceProviderName, bindingTimeEndsAt, rejectedReason, message },
  { localizeDate }
) => {
  const getRejectedReasonText = (reason: RejectedReason, bindingTime?: string) => {
    switch (reason) {
      case RejectedReason.AlreadyCustomer:
        return (
          <LocalizedMessage
            id="failedIndependentOrderAlreadyACustomer"
            values={{ newSupplier: newServiceProviderName }}
          />
        );
      case RejectedReason.HasBindingPeriod:
        return bindingTime ? (
          <LocalizedMessage
            id="failedSwitchBindingTimeWithDate2"
            values={{ bindingTimeEndsAt: localizeDate(bindingTimeEndsAt), currentSupplier: serviceProviderName }}
          />
        ) : (
          <LocalizedMessage id="failedSwitchBindingTime" values={{ currentSupplier: serviceProviderName }} />
        );
      case RejectedReason.CreditCheckFailed:
        // Here we don't want to be too explicit about the rejection reason
        return <LocalizedMessage id="failedIndependentOrderCreditCheckFailed" />;
      case RejectedReason.UnknownAddress:
        return <LocalizedMessage id="failedIndependentOrderUnknownAddress" />;
      default:
        return undefined;
    }
  };

  const reasonText = rejectedReason && getRejectedReasonText(rejectedReason, bindingTimeEndsAt);

  return (
    <>
      {reasonText && (
        <div className={classes.sectionMargin}>
          <Body>{reasonText}</Body>
        </div>
      )}
      {message && (
        <div className={classes.sectionMargin}>
          <Caption style={{ marginBottom: '4px' }}>
            <LocalizedMessage id="messageFromSupplier" values={{ supplierName: serviceProviderName }} />
          </Caption>
          <Body className={classes.message}>{message}</Body>
        </div>
      )}
    </>
  );
};

export const FailedOrderContent = withStyles(styles)(FailedOrderContentInner);

FailedOrderContentInner.contextTypes = {
  localizeDate: PropTypes.func.isRequired,
};
