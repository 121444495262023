import type { EndUserImages } from '@client/styles/themes/defaultTheme';
import { defaultThemeOptions } from '@client/styles/themes/defaultTheme';
import type { Theme } from '@material-ui/core';
import { createTheme } from '@minna-technologies/minna-ui/styles/themes/minna';

export const littleBirdieTheme: Theme<EndUserImages> = createTheme<EndUserImages>({
  colors: {
    action: {
      primary: '#29235C',
      primaryHover: '#433D76',
      primaryPressed: '#100A43',
      primaryDisabled: '#DDDDDD',
      secondary: '#818181',
      secondaryPressed: '#4a4b4b',
      secondaryHover: '#4a4b4b',
      secondaryDisabled: '#DDDDDD',
      info: '#F398C2',
      infoPressed: '#DA7FA9',
      infoHover: '#FFB2DC',
      positive: '#558C4D',
      positivePressed: '#3D7635',
      positiveHover: '#286120',
      danger: '#E43F5C',
      dangerPressed: '#C12641',
      dangerHover: '#9E1830',
    },
    textOn: {
      surface: '#222222',
      surfaceSubdued: '#4a4b4b',
      primary: '#ffffff',
      secondary: '#222222',
      disabled: 'rgba(34, 34, 34, 0.8)',
      info: '#192133',
      positive: '#1F3D1B',
      danger: '#430D17',
      badge: '#ffffff',
    },
    element: {
      rating: '#FF9800',
      badgeSurface: '#1D82A1',
    },
    base: {
      surface: '#ffffff',
      border: '#B0B0B0',
      background: '#f8f8f8',
      info: '#D8E3FF',
      positive: '#D1EFCD',
      danger: '#F5BFC9',
    },
  },
  cardShadow: 'none',
  modalShadow: '0px 0px 0px 1px rgba(229,229,229,1)',
  selectButtonShadowSelected: '00px 3px 3px rgba(34, 34, 34, 0.1), 0px 6px 12px rgba(34, 34, 34, 0.15)',
  selectButtonShadowDefault: '0px 2px 2px rgba(34, 34, 34, 0.2)',
  nextStepShadow:
    '0px 0px 3px rgba(34, 34, 34, 0.2), 0px 2px 5px rgba(34, 34, 34, 0.1), 0px 5px 10px rgba(34, 34, 34, 0.2)',
  floatingShadow:
    '0px 1px 1px rgba(34, 34, 34, 0.06), 0px 2px 3px rgba(34, 34, 34, 0.1), 0px 3px 10px rgba(34, 34, 34, 0.1)',
  borderRadius: '8px',
  transitionDuration: '0.2s',
  borderRadiusPrimaryButton: '14px',
  borderRadiusSecondaryButton: '14px',
  themeButtons: {
    positiveButton: {
      backgroundColor: '#29235C',
      color: '#FFFFFF',
    },
    positiveFloatingButton: {
      backgroundColor: '#29235C',
      color: '#FFFFFF',
    },
  },
  themeFonts: {
    headline1: {
      fontFamily: 'Mulish',
    },
    headline2: {
      fontFamily: 'Mulish',
    },
    headline3: {
      fontFamily: 'Mulish',
    },
    subheading1: {
      fontFamily: 'Mulish',
    },
    subheading2: {
      fontFamily: 'Mulish',
    },
    title: {
      fontFamily: 'Mulish',
    },
    buttonText: {
      fontFamily: 'Mulish',
    },
    bodyText: {
      fontFamily: 'Mulish',
    },
  },
  themeImages: defaultThemeOptions.themeImages, // No custom images
});
