import type { SaveDeskAction } from './actions';
import { SaveDeskEvent } from './events';
import type { IState } from './state';
import { initState } from './state';

export const reducer = (state: IState = initState, action: SaveDeskAction): IState => {
  switch (action.type) {
    case SaveDeskEvent.SENT_FEEDBACK:
      return { ...state, sentFeedback: action.payload };

    case SaveDeskEvent.SET_CANCELLATION_REASON:
      return { ...state, cancellationReason: action.payload };

    case SaveDeskEvent.SET_OFFER:
      return { ...state, offer: action.payload };

    default:
      return state;
  }
};
