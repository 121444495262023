import React from 'react';

/**
 * This hook helps you keep track of if the app is still open,
 * useful if you have behaviour behind a timer that should only be triggered if the user is still in the app.
 */
export function useDocumentHidden(): boolean {
  const [documentHidden, setDocumentHidden] = React.useState<boolean>(false);

  const visibilityChangeHandler = () => {
    setDocumentHidden(document.hidden);
  };

  // Add event listeners
  React.useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChangeHandler);
    // Remove event listeners on cleanup
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, []);
  return documentHidden;
}
