import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { forceLoadMessagesAndLocale } from '@client/internationalization/load';
import type { Locale } from '@client/modules/app-context/constants';
import { AppType } from '@client/modules/app-context/constants';
import { AppIntegration } from '@client/modules/app-integration';
import MultiDeviceLayout from '@client/shared-components/layout/MultiDeviceLayout';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import styles from './styles.scss';

const LoggedOutPageInner: React.ComponentType = (_, { localizeMessage }) => {
  const hasMessages = useCallback(() => localizeMessage('pageTitle') !== 'MISSING', [localizeMessage]);
  const closeApp = () => {
    AppIntegration.closeApplication();
  };

  /*
   * We know nothing about the user at this point. Trying to fetch messages based on the browser locale,
   * else we fall back to English.*/
  useEffect(() => {
    // If the user is a logged out standalone user then we want to force them out of the app
    // and make them see the unbounce page. This check can be removed at 2022-09
    if (window.location.href.includes('minatjanster.se')) {
      window.location.href = 'https://www.minatjanster.se';
    }
    if (!hasMessages()) {
      try {
        const browserLocale = navigator.language as Locale;
        forceLoadMessagesAndLocale(browserLocale, AppType.Undefined);
      } catch (error) {
        return;
      }
    }
  }, [hasMessages]);

  return (
    <MultiDeviceLayout>
      {hasMessages() ? (
        <Paper classes={{ root: styles.paper }}>
          <Title className={styles['margin-bottom']}>
            <LocalizedMessage id="pageTitle" />
          </Title>
          <Body style={{ marginBottom: 32 }}>
            <LocalizedMessage id="description" />
          </Body>
          {!!AppIntegration.closeApplication && (
            <Button variant="contained" color="primary" classes={{ root: styles.button }} onClick={closeApp}>
              <LocalizedMessage id="okButton" />
            </Button>
          )}
        </Paper>
      ) : (
        <Paper classes={{ root: styles.paper }}>
          <Title className={styles['margin-bottom']}>You have been signed out</Title>
          <Body style={{ marginBottom: 32 }}>
            After 15 minutes of inactivity, you are automatically signed out. To continue, please sign in again.
          </Body>
          {!!AppIntegration.closeApplication && (
            <Button variant="contained" color="primary" classes={{ root: styles.button }} onClick={closeApp}>
              Ok
            </Button>
          )}
        </Paper>
      )}
    </MultiDeviceLayout>
  );
};

LoggedOutPageInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const LoggedOutPage = withLocalization('error-handling/LoggedOutPage')(LoggedOutPageInner);
