import { useLocalization } from '@client/internationalization';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import { makeStyles } from '@material-ui/core';
import { PositiveButton } from '@minna-technologies/minna-ui/components/Buttons/PositiveButton';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Drawer } from '@minna-technologies/minna-ui/components/Drawer';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onRequestChangePlan } from '@client/modules/change-plan/onRequestChangePlan';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import {
  trackChangePlanDrawerCloseButtonClicked,
  trackChangePlanDrawerContinueButtonClicked,
  trackChangePlanDrawerViewed,
} from '@client/modules/overview/OverviewPage/components/ChangePlanDrawer/mixpanel-events';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridRowGap: '16px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '16px auto',
  },
}));

export const ChangePlanDrawer = ({
  onSeenDrawer,
  contract,
  subscription,
}: {
  onSeenDrawer(): void;
  contract: Contract;
  subscription: Subscription;
}) => {
  const { localizeMessage } = useLocalization('change-plan/ChangePlanServiceIntroductionDrawer');
  const classes = useStyles();
  const appContext = useSelector(selectorAppContext);
  const [isChangePlanDrawerOpen, setIsChangePlanDrawerOpen] = useState(true);
  const history = useHistory();

  useMountEffect(() => {
    trackChangePlanDrawerViewed(appContext.market, appContext.appType);
  });

  const handleDrawerCloseButtonClicked = () => {
    trackChangePlanDrawerCloseButtonClicked(appContext.market, appContext.appType);
    setIsChangePlanDrawerOpen(!isChangePlanDrawerOpen);
    onSeenDrawer();
  };

  const handleOnGetStartedClick = () => {
    trackChangePlanDrawerContinueButtonClicked(appContext.market, appContext.appType);
    onRequestChangePlan(appContext, contract, subscription, history);
    onSeenDrawer();
  };

  return (
    <MultiDeviceLayout>
      <Drawer open={isChangePlanDrawerOpen} onClose={handleDrawerCloseButtonClicked}>
        <div data-test="change-plan-service-introduction-drawer" className={classes.container}>
          <Headline2>{localizeMessage('title')}</Headline2>
          <Body>{localizeMessage('descriptionText1')}</Body>
          <Divider />
          <div className={classes.buttonContainer}>
            <PositiveButton
              label={localizeMessage('buttonLabel')}
              onClick={handleOnGetStartedClick}
              data-test="change-plan-drawer-get-started-button"
            />
          </div>
        </div>
      </Drawer>
    </MultiDeviceLayout>
  );
};
