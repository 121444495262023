import type { AxiosResponse } from 'axios';
import axios from 'axios';
import moment from 'moment';
import type { NemIdJsonParameters } from './models';

export async function initiateNemIdSigning(contractId: string): Promise<NemIdJsonParameters> {
  return axios
    .get(`/api/user/contracts/${contractId}/nem-id/init`)
    .then((response: AxiosResponse<NemIdJsonParameters>) => response.data);
}

export async function requestCancellation(contractId: string, signingStartedAt: Date, nemIdResponse: string) {
  return axios.post(`/api/user/contracts/${contractId}/request-cancellation`, {
    encodedResponse: nemIdResponse,
    signingStartedAt: moment(signingStartedAt).format(),
  });
}
