import { withLocalization } from '@client/internationalization';
import { SubscriptionActionType } from '@client/models/subscription';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { onRequestCancellationOfContract } from '@client/modules/cancellation/onCancelContract';
import { onRequestPause } from '@client/modules/pause/onRequestPause';
import type { Contract, Subscription, SubscriptionId, SubscriptionSupplier } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import {
  FEATURE_OVERVIEW,
  TrackingButtonName,
  TrackingFeature,
  TrackingPageName,
} from '@client/tracking/mixpanel-constants';
import { sendButtonClickedEvent } from '@client/tracking/mixpanel-tracking-events';
import { Drawer } from '@minna-technologies/minna-ui/components/Drawer';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ContractActionContent } from './components/ContractActionContent';
import { onRequestChangePlan } from '@client/modules/change-plan/onRequestChangePlan';
import { selectorUser, setUser } from '@client/ducks/user';
import type { User } from '@client/models/user-models';
import { ChangePlanDrawer } from '@client/modules/overview/OverviewPage/components/ChangePlanDrawer';
import { saveUserViewedChangePlanDrawer } from '@client/models/user';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import moment from 'moment';
import { Sentry } from '@client/assets/js/sentry';
import { clickedSubscriptionActionMixpanelEvent } from '@client/modules/subscription/mixpanel';

function trackButtonClickedEvent(
  supplier: SubscriptionSupplier,
  contract: Contract,
  action: string,
  monthlyCost?: number
): void {
  sendButtonClickedEvent(FEATURE_OVERVIEW, 'Action Drawer', action, {
    'Supplier name': supplier.name,
    'Supplier ID': supplier.id,
    Category: contract.service.category.name,
    Location: 'Bottom',
    'Monthly Cost': monthlyCost,
  });
}

interface Props {
  contract: Contract;
  open: boolean;
  subscription: Subscription;
  subscriptionId: SubscriptionId;
  supplier: SubscriptionSupplier;
  close(): void;
}

const Component: React.FunctionComponent<Props> = ({
  contract,
  open,
  subscription,
  subscriptionId,
  supplier,
  close,
}: Props) => {
  const dispatch = useDispatch();
  const [showChangePlanServiceIntroductionDrawer, setShowChangePlanServiceIntroductionDrawer] = useState(false);
  const user: User = useSelector(selectorUser);
  const history = useHistory();

  const appContext = useSelector(selectorAppContext);

  const onShowTerminationInstructionsPage = (subscriptionId: SubscriptionId, contract: Contract): void => {
    history.push(urls.terminationInstructionsPage(subscriptionId, contract.id), {
      previousUrl: urls.overviewPage,
    });
  };

  function onButtonClick(action: SubscriptionActionType): void {
    switch (action) {
      case SubscriptionActionType.Cancel:
        onRequestCancellationOfContract(appContext, contract, subscription, history, dispatch);
        break;
      case SubscriptionActionType.Pause:
        onRequestPause(appContext, contract, subscription, history);
        break;
      case SubscriptionActionType.ChangePlan:
        if (!user.visitedPages.viewedChangePlanServiceIntroductionAt) {
          // We close the subscription actions drawer
          close();
          setShowChangePlanServiceIntroductionDrawer((curr) => !curr);
        } else {
          clickedSubscriptionActionMixpanelEvent(
            TrackingFeature.ChangePlan,
            contract,
            subscription,
            TrackingButtonName.CHANGE_PLAN,
            appContext.market,
            appContext.appType,
            TrackingPageName.ACTION_DRAWER
          );
          onRequestChangePlan(appContext, contract, subscription, history);
        }
        break;
      default:
        return;
    }
  }

  function onReadMoreClicked(): void {
    trackButtonClickedEvent(supplier, contract, 'Read more', subscription.cost.amount);
    if (contract.service.terminationInstructions) onShowTerminationInstructionsPage(subscriptionId, contract);
  }

  const onSeenChangePlanDrawer = () => {
    setShowChangePlanServiceIntroductionDrawer((curr) => !curr);
    saveUserViewedChangePlanDrawer()
      .then((updatedUser: User) => dispatch(setUser(updatedUser)))
      .catch((error: Error) => {
        const visitedPagesModified = cloneDeep(user?.visitedPages) || {};
        set(visitedPagesModified, 'viewedChangePlanServiceIntroductionAt', moment());
        dispatch(setUser({ ...user, visitedPages: visitedPagesModified }));
        Sentry.captureExceptionWithMessage(error, 'Failed to update visited pages on user model');
      });
  };

  if (showChangePlanServiceIntroductionDrawer) {
    return <ChangePlanDrawer contract={contract} subscription={subscription} onSeenDrawer={onSeenChangePlanDrawer} />;
  }

  return (
    <>
      <Drawer open={open} onClose={close}>
        {contract && subscription && (
          <ContractActionContent
            contract={contract}
            subscription={subscription}
            supplier={supplier}
            onButtonClick={onButtonClick}
            onReadMoreClicked={onReadMoreClicked}
          />
        )}
      </Drawer>
    </>
  );
};

export const ContractAction = withLocalization('overview/ContractAction')(Component);
