import {handle} from 'redux-pack';
import {getUserNotificationSettings, patchUserNotificationSettings} from '../../../../models/user';
import get from 'lodash/get';

export const FETCH = 'NOTIFICATION_SETTINGS/FETCH';
export const UPDATE = 'NOTIFICATION_SETTINGS/UPDATE';
export const SET_SETTING = 'NOTIFICATION_SETTINGS/SET_SETTING';


export default function reducer(state = {}, action) {
  switch (action.type) {
    case FETCH:
      return handle(state, action, {
        start: () => ({isLoading: true}),
        finish: s => ({...s, isLoading: false}),
        failure: s => ({...s, loadingFailed: true}),
        success: s => ({...s, settings: action.payload})
      });

    case UPDATE:
      return handle(state, action, {
        start: s => ({...s, isUpdating: true, updateFailed: false}),
        finish: s => ({...s, isUpdating: false}),
        failure: s => ({...s, updateFailed: true}),
        success: s => ({...s, settings: action.payload})
      });

    case SET_SETTING:
      return {
        ...state, settings: {...state.settings, [action.setting]: action.enabled}
      };

    default:
      return state;
  }
}

export const fetchNotificationSettings = (emailUserId) => {
  return {type: FETCH, promise: getUserNotificationSettings(emailUserId)};
};

export const updateNotificationSetting = (emailUserId, setting, enabled) => {
  return (dispatch) => {
    dispatch({type: SET_SETTING, setting, enabled});

    const updatePromise = patchUserNotificationSettings({[setting]: enabled}, emailUserId);
    dispatch({type: UPDATE, promise: updatePromise});
  };
};

export const selectorNotificationSettings = (state) => state.notificationSettings.settings;
export const selectorIsLoading = (state) => get(state, 'notificationSettings.isLoading', true);
export const selectorLoadingFailed = (state) => state.notificationSettings.loadingFailed;
export const selectorUpdateFailed = (state) => state.notificationSettings.updateFailed;
