import { selectorSelectedAction, selectorSelectedSupplier } from '@client/ducks/search-entrypoint';
import { SubscriptionActionType } from '@client/models/subscription';
import type { SupplierService } from '@client/models/supplier';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { onRequestCancellationOfService } from '@client/modules/cancellation/onCancelService';
import { onChangePaymentOfService } from '@client/modules/changePayment/onChangePaymentService';
import {
  trackClickedServiceAction,
  trackViewedSelectService,
} from '@client/modules/subscription-search/SubscriptionSearchPage/mixpanel';
import { SelectServicesPageComponent } from '@client/shared-components/SelectServicesPage/component';
import { subscriptionActionTypeToTrackingFeature as toTrackingFeature } from '@client/tracking/mixpanel-constants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const SelectServicesPage: React.FunctionComponent = () => {
  const selectedSupplier = useSelector(selectorSelectedSupplier);
  const selectedAction = useSelector(selectorSelectedAction);
  const appContext = useSelector(selectorAppContext);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    trackViewedSelectService(toTrackingFeature(selectedAction));
  }, [selectedAction]);

  function onServiceClicked(service: SupplierService) {
    if (selectedAction === SubscriptionActionType.Cancel) {
      trackClickedServiceAction(
        selectedSupplier.id,
        selectedSupplier.name,
        service.id,
        toTrackingFeature(selectedAction),
        service.category.name
      );
      onRequestCancellationOfService(appContext, selectedSupplier, service, history, dispatch, false);
    } else if (selectedAction === SubscriptionActionType.ChangePayment) {
      onChangePaymentOfService(appContext, selectedSupplier, service, history);
    }
  }

  return (
    <SelectServicesPageComponent
      services={selectedSupplier.services}
      supplierName={selectedSupplier.name}
      action={selectedAction}
      onServiceClicked={onServiceClicked}
    />
  );
};
