import { ThemeType } from '@client/models/ThemeType';
import { selectorFeaturesOrUndefined } from '@client/modules/app-context/duck';
import { anyfinTheme } from '@client/styles/themes/anyfin/anyfinTheme';
import type { EndUserImages } from '@client/styles/themes/defaultTheme';
import { defaultTheme } from '@client/styles/themes/defaultTheme';
import { ingTheme } from '@client/styles/themes/ingTheme';
import { littleBirdieTheme } from '@client/styles/themes/littlebirdieTheme';
import { opBankTheme } from '@client/styles/themes/opTheme';
import { zlantarTheme } from '@client/styles/themes/zlantarTheme';
import type { Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';

/**
 * Returns the application's theme from minna-ui.
 */
export function useAppTheme(): Theme<EndUserImages> {
  const maybeFeatures = useSelector(selectorFeaturesOrUndefined);
  const theme = maybeFeatures?.theme || ThemeType.MINNA;

  switch (theme) {
    case ThemeType.OpBank:
      return opBankTheme;
    case ThemeType.Anyfin:
      return anyfinTheme;
    case ThemeType.LittleBirdie:
      return littleBirdieTheme;
    case ThemeType.Zlantar:
      return zlantarTheme;
    case ThemeType.WAFFLE:
      return ingTheme;
    default:
      return defaultTheme;
  }
}
