import { getMixpanel } from '@client/tracking/mixpanel';
import { FEATURE_OVERVIEW } from '@client/tracking/mixpanel-constants';

export function dispatchSubscriptionConvertedMixpanelEvent() {
  getMixpanel().track('Subscription converted', {
    Feature: FEATURE_OVERVIEW,
    Page: 'Edit cost',
    Button: 'Subscription converted',
  });
}
