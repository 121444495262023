import { LocalizedMessage, withLocalization } from '@client/internationalization';
import MultiDeviceLayout from '@client/shared-components/layout/MultiDeviceLayout';
import PageNavigationBar from '@client/shared-components/PageNavigationBar';
import React from 'react';

export interface NemIdSigningPageProps {
  nemIdUrl: string;
  onBackClick(): void;
}

const NemIdSigningPageInnerComponent: React.FunctionComponent<NemIdSigningPageProps> = ({ nemIdUrl, onBackClick }) => {
  const header = (
    <PageNavigationBar backgroundColor="white" onBackClick={onBackClick} title={<LocalizedMessage id="pageTitle" />} />
  );

  return (
    <MultiDeviceLayout header={header}>
      <iframe height="420" sandbox="allow-same-origin allow-scripts allow-forms" id="nemid_iframe" src={nemIdUrl} />
    </MultiDeviceLayout>
  );
};

export const NemIdSigningPageComponent = withLocalization('e-signing/nem-id/NemIdSigningPage')(
  NemIdSigningPageInnerComponent
);
