import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import type { LoadingComponentProps } from 'react-loadable';
import classes from './styles.scss';

export interface LoadingBoxProps extends LoadingComponentProps {
  height: string;
}

export const LoadingBox: React.FunctionComponent<LoadingBoxProps> = ({ height, pastDelay, error }) => {
  // This component can be improved by showing the user when an error have occurred.
  return (
    <div className={classes.container} style={{ height: height }}>
      {(pastDelay || error) && <CircularProgress />}
    </div>
  );
};
