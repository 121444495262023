import Sentry from '@client/assets/js/sentry';
import type { SupplierWithServices } from '@client/models/supplier';
import { fetchSupplier } from '@client/models/supplier';
import { selectorUnresolvedSubscriptionsForGuide } from '@client/modules/overview/duck';
import { updateContractsOnSubscription } from '@client/modules/subscription/model';
import type { ServiceId, Subscription, SubscriptionId } from '@client/modules/subscription/types';
import clone from 'lodash/clone';
import without from 'lodash/without';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FlowPageUnresolvedSubscriptionComponent } from './component';

export interface FlowPageUnresolvedSubscriptionGuideProps {
  unresolvedSubscription: Subscription;
  updateSubscriptionWithServices(subscription: Subscription): void;
  deleteSubscription(id: SubscriptionId): void;
  goForward(): void;
  goBack(): void;
}
export const FlowPageUnresolvedSubscription: React.FunctionComponent<FlowPageUnresolvedSubscriptionGuideProps> = ({
  unresolvedSubscription,
  goForward,
  updateSubscriptionWithServices,
  deleteSubscription,
  goBack,
}: FlowPageUnresolvedSubscriptionGuideProps) => {
  const unresolvedSubscriptions = useSelector(selectorUnresolvedSubscriptionsForGuide);
  const [currentSupplier, setCurrentSupplier] = useState<SupplierWithServices | null>(null);
  // Enough to keep in state here or move to route level or call store if needed?
  const [selectedServices, setSelectedServices] = useState<ServiceId[]>([]);
  const [initialSelectedServices, setInitialSelectedServices] = useState<ServiceId[]>([]);

  useEffect(() => {
    fetchSupplier(unresolvedSubscription.supplier.id).then((supplier) => {
      setCurrentSupplier(supplier);
    });
    const currentSubscriptionServices = unresolvedSubscription.contracts.map((contract) => contract.service.id);
    setSelectedServices(currentSubscriptionServices);
    setInitialSelectedServices(currentSubscriptionServices);
  }, [unresolvedSubscription]);

  function onServiceButtonClicked(service: ServiceId, selected: boolean) {
    const selectedServicesClone = clone(selectedServices) || [];
    if (selected) {
      selectedServicesClone.push(service);
      setSelectedServices(selectedServicesClone);
    } else {
      setSelectedServices(without(selectedServices, service));
    }
  }
  function somethingWrongDeleteAction() {
    deleteSubscription(unresolvedSubscription.id);
    goForward();
  }

  function goForwardAction() {
    // TODO handle this for the edit subscription case
    const contractsToBeAdded: string[] = [];
    const contractsToBeDeleted: string[] = [];
    selectedServices.forEach((serviceId) => {
      if (!initialSelectedServices.includes(serviceId)) {
        contractsToBeAdded.push(serviceId);
      }
    });
    initialSelectedServices.forEach((serviceId) => {
      if (!selectedServices.includes(serviceId)) {
        const removedContract = unresolvedSubscription.contracts.find((contract) => contract.service.id === serviceId);
        if (removedContract) {
          contractsToBeDeleted.push(removedContract.id);
        }
      }
    });

    updateContractsOnSubscription(unresolvedSubscription.id, contractsToBeAdded, contractsToBeDeleted)
      .then((subscription: Subscription) => {
        updateSubscriptionWithServices(subscription);
      })
      .catch((error) => {
        Sentry.captureExceptionWithMessage(error, 'Failed to update contracts from unresolved-guide');
      });
    goForward();
  }

  const partOfMultipleSubscriptions =
    unresolvedSubscriptions.filter(
      (subscription: Subscription) => subscription.supplier.id === unresolvedSubscription?.supplier.id
    ).length > 1;

  return unresolvedSubscription && currentSupplier ? (
    <FlowPageUnresolvedSubscriptionComponent
      currentSupplier={currentSupplier}
      unresolvedSubscription={unresolvedSubscription}
      partOfMultipleSubscriptions={partOfMultipleSubscriptions}
      toggleSelectService={onServiceButtonClicked}
      somethingWrongDeleteAction={somethingWrongDeleteAction}
      selectedServices={selectedServices}
      guideBackAction={goBack}
      guideForwardAction={goForwardAction}
    />
  ) : null; // TODO: Show spinner here?
};
