import { LocalizedMessage } from '@client/internationalization';
import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { CommentIcon } from '@minna-technologies/minna-ui/icons/Comment';
import type { FC } from 'react';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  messageHeadline: {
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    alignItems: 'center',
  },
  readMoreLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 8,
  },
  content: {
    margin: '0 16px 8px',
    backgroundColor: theme.colors.base.background,
    padding: '16px',
    borderRadius: '8px',
  },
  message: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    marginTop: '8px',
  },
}));

interface Props {
  messageToUser: string;
  serviceProviderName?: string;
}

export const MessageToUser: FC<Props> = ({ messageToUser, serviceProviderName }) => {
  const classes = useStyles();
  const [messageIsExpanded, setMessageIsExpanded] = useState(true);
  const truncateText = messageToUser.length > 120;
  const message = truncateText && !messageIsExpanded ? `${messageToUser.slice(0, 100)}...` : messageToUser;
  const { colors } = useTheme();

  return (
    <>
      <Divider />
      <div className={classes.content}>
        <div className={classes.messageHeadline}>
          <CommentIcon nativeColor={colors.textOn.surface} />
          <Caption color={colors.textOn.surface} variant="bold" style={{ fontSize: '16px' }}>
            {serviceProviderName ? (
              <LocalizedMessage id="messageFromSupplier" values={{ supplierName: serviceProviderName }} />
            ) : (
              <LocalizedMessage id="messageFromCustomerSupport" />
            )}
          </Caption>
        </div>
        <Caption color={colors.textOn.surface} className={classes.message} style={{ fontSize: '16px' }}>
          {message}
        </Caption>
        {truncateText && (
          <span className={classes.readMoreLink}>
            <Body variant="link" onClick={() => setMessageIsExpanded(!messageIsExpanded)}>
              {messageIsExpanded ? <LocalizedMessage id="readLessLink" /> : <LocalizedMessage id="readMoreLink" />}
            </Body>
          </span>
        )}
      </div>
    </>
  );
};
