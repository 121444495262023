import type { SelectableService, ServiceToDisplay } from '@client/models/service';
import type { Supplier } from '@client/models/supplier';
import type { UserInformationForm } from '@client/modules/cancellation/models';
import type { Contract, ContractId, Subscription, SubscriptionId } from '@client/modules/subscription/types';
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import isEqual from 'lodash/isEqual';
import reject from 'lodash/reject';
import { handle } from 'redux-pack';

const initialState: SubscriptionState = {
  subscriptionIsLoading: false,
  subscriptionLoadingFailed: false,
  subscription: undefined,
  supplierIsLoading: false,
  supplierLoadingFailed: false,
  supplier: undefined,
  selectedServices: [],
  subscriptionUpdateSucceeded: false,
  subscriptionUpdateFailed: false,
  confirmationDialogOpen: false,
  userInformationForm: {},
};

interface SubscriptionState {
  subscriptionIsLoading: boolean;
  subscriptionLoadingFailed: boolean;
  subscription?: Subscription;
  supplierIsLoading: boolean;
  supplierLoadingFailed: boolean;
  supplier?: Supplier;
  selectedServices: SelectableService[];
  subscriptionUpdateSucceeded: boolean;
  subscriptionUpdateFailed: boolean;
  confirmationDialogOpen: boolean;
  userInformationForm: UserInformationForm;
}

const NAMESPACE = 'SUBSCRIPTION';
const SET_UPDATED_SUBSCRIPTION = `${NAMESPACE}/SET_UPDATED_SUBSCRIPTION`;
const UPDATE_SUBSCRIPTION = `${NAMESPACE}/UPDATE_SUBSCRIPTION`;
const UPDATE_SELECTED_SERVICES = `${NAMESPACE}/UPDATE_SELECTED_SERVICES`;
const SET_SUPPLIER = `${NAMESPACE}/SET_SUPPLIER`;
const LOAD_SUBSCRIPTION = `${NAMESPACE}/LOAD_SUBSCRIPTION`;
const UPDATE_USER_INFORMATION_FORM = `${NAMESPACE}/UPDATE_USER_INFORMATION_FORM`;
const UPDATE_USER_INFORMATION_FORM_FIELD = `${NAMESPACE}/UPDATE_USER_INFORMATION_FORM_FIELD`;
const LOADING = `${NAMESPACE}/LOADING`;
const LOADING_FAILURE = `${NAMESPACE}/LOADING_FAILURE`;
const LOADING_SUCCESS = `${NAMESPACE}/LOADING_SUCCESS`;
const SUPPLIER_LOADING = `${NAMESPACE}/SUPPLIER_LOADING`;
const SUPPLIER_LOADING_FAILURE = `${NAMESPACE}/SUPPLIER_LOADING_FAILURE`;
const SUPPLIER_LOADING_SUCCESS = `${NAMESPACE}/SUPPLIER_LOADING_SUCCESS`;
const UPDATE_CONTRACT = `${NAMESPACE}/UPDATE_CONTRACT`;
const SET_INITIAL_STATE = `${NAMESPACE}/SET_INITIAL_STATE`;
const CLEAR_SUBSCRIPTION_UPDATE_OUTCOME = `${NAMESPACE}/CLEAR_SUBSCRIPTION_UPDATE_OUTCOME`;

export function subscriptionReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_UPDATED_SUBSCRIPTION: {
      return {
        ...state,
        subscription: action.subscription,
      };
    }
    case UPDATE_SUBSCRIPTION:
      return handle(state, action, {
        start: (s) => ({
          ...s,
          subscriptionIsUpdating: true,
          subscriptionUpdateFailed: false,
          subscriptionUpdateSucceeded: false,
        }),
        finish: (s) => ({ ...s, subscriptionIsUpdating: false }),
        failure: (s) => ({ ...s, subscriptionUpdateFailed: true }),
        success: (s) => ({ ...s, subscription: action.payload, subscriptionUpdateSucceeded: true }),
      });
    case UPDATE_SELECTED_SERVICES: {
      return {
        ...state,
        selectedServices: action.selectedServices,
      };
    }
    case SET_SUPPLIER: {
      return {
        ...state,
        supplier: action.supplier,
      };
    }
    case UPDATE_CONTRACT: {
      // @ts-ignore
      const updatedContracts = reject(state.subscription.contracts, { id: action.contract.id });

      updatedContracts.push(action.contract);

      return {
        ...state,
        subscription: { ...state.subscription, contracts: updatedContracts },
      };
    }
    case LOAD_SUBSCRIPTION:
      return handle(state, action, {
        start: (s) => ({
          ...s,
          subscriptionIsLoading: true,
          subscriptionLoadingFailed: false,
          subscriptionLoadingSucceeded: false,
        }),
        finish: (s) => ({ ...s, subscriptionIsLoading: false }),
        failure: (s) => ({ ...s, subscriptionLoadingFailed: true }),
        success: (s) => ({ ...s, subscription: action.payload, subscriptionLoadingSucceeded: true }),
      });
    case LOADING:
      return {
        ...state,
        subscriptionIsLoading: true,
      };
    case LOADING_FAILURE:
      return {
        ...state,
        subscriptionIsLoading: false,
        subscriptionLoadingFailed: true,
      };
    case LOADING_SUCCESS: {
      return {
        ...state,
        subscriptionIsLoading: false,
        subscriptionLoadingFailed: false,
        subscription: action.subscription,
      };
    }
    case SUPPLIER_LOADING:
      return {
        ...state,
        supplierIsLoading: true,
      };
    case SUPPLIER_LOADING_FAILURE:
      return {
        ...state,
        supplierIsLoading: false,
        supplierLoadingFailed: true,
      };
    case SUPPLIER_LOADING_SUCCESS: {
      return {
        ...state,
        supplierIsLoading: false,
        supplierLoadingFailed: false,
        supplier: action.supplier,
      };
    }
    case CLEAR_SUBSCRIPTION_UPDATE_OUTCOME: {
      return {
        ...state,
        subscriptionUpdateFailed: false,
        subscriptionUpdateSucceeded: false,
      };
    }

    case UPDATE_USER_INFORMATION_FORM: {
      return {
        ...state,
        userInformationForm: action.payload,
      };
    }

    case UPDATE_USER_INFORMATION_FORM_FIELD: {
      const { fieldName, newValue } = action.payload;

      return {
        ...state,
        userInformationForm: { ...state.userInformationForm, [fieldName]: newValue },
      };
    }

    case SET_INITIAL_STATE:
      return {
        ...initialState,
      };
    default: {
      return state;
    }
  }
}

export const setUpdatedSubscription = (subscription: Subscription) => ({
  type: SET_UPDATED_SUBSCRIPTION,
  subscription: subscription,
});

/**
 * @loadSubscriptionPromise - a promise for fetching a user
 */
export const loadSubscription = (loadSubscriptionPromise: Promise<Subscription>) => (dispatch: any) =>
  dispatch({ type: LOAD_SUBSCRIPTION, promise: loadSubscriptionPromise });

/**
 * @updateSubscriptionPromise - a promise for updating a user
 */
export const updateSubscription = (updateSubscriptionPromise: Promise<Subscription>) => (dispatch: any) =>
  dispatch({ type: UPDATE_SUBSCRIPTION, promise: updateSubscriptionPromise });

export const clearSubscriptionUpdateOutcome = () => ({
  type: CLEAR_SUBSCRIPTION_UPDATE_OUTCOME,
});

export const updateSelectedServices = (selectedServices: SelectableService[]) => ({
  type: UPDATE_SELECTED_SERVICES,
  selectedServices,
});

export const updateContract = (contract: Contract) => ({
  type: UPDATE_CONTRACT,
  contract,
});

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const subscriptionIsLoading = () => ({ type: LOADING });
export const subscriptionLoadingFailure = () => ({ type: LOADING_FAILURE });
export const subscriptionLoadingSuccess = (subscription: Subscription) => ({
  type: LOADING_SUCCESS,
  subscription,
});

export const updateUserInformationFormField = (fieldName: keyof UserInformationForm, newValue: any) => ({
  type: UPDATE_USER_INFORMATION_FORM_FIELD,
  payload: { fieldName, newValue },
});

export const updateUserInformationForm = (userInformationForm: UserInformationForm) => ({
  type: UPDATE_USER_INFORMATION_FORM,
  payload: userInformationForm,
});

export const supplierIsLoading = () => ({ type: SUPPLIER_LOADING });
export const supplierLoadingFailure = () => ({ type: SUPPLIER_LOADING_FAILURE });
export const supplierLoadingSuccess = (supplier: any) => ({
  type: SUPPLIER_LOADING_SUCCESS,
  supplier,
});

interface State {
  subscription: SubscriptionState;
}

const subscriptionNotDefinedInState = (state: State) => !state.subscription.subscription;
const notCorrectSubscriptionInState = (state: State, subscriptionId: SubscriptionId) =>
  !isEqual(get(state.subscription.subscription, 'id'), subscriptionId);

const supplierNotDefinedInState = (state: State): boolean =>
  !state.subscription.supplierLoadingFailed && !state.subscription.supplier;

export const notCorrectSupplierInState = (state: State): boolean =>
  !isEqual(get(state.subscription.supplier, 'id'), state.subscription?.subscription?.supplier.id);

export const selectorSubscription = (state: State) => state.subscription.subscription;
export const selectorContract = (state: State, contractId: ContractId): Contract | undefined => {
  const subscriptionContracts = state.subscription.subscription?.contracts ?? [];

  return find(subscriptionContracts, { id: contractId });
};
export const selectorSubscriptionIsLoading = (state: State, subscriptionId: SubscriptionId) =>
  state.subscription.subscriptionIsLoading ||
  state.subscription.subscriptionLoadingFailed ||
  subscriptionNotDefinedInState(state) ||
  notCorrectSubscriptionInState(state, subscriptionId);
export const selectorSupplierIsLoading = (state: State) =>
  state.subscription.supplierIsLoading || supplierNotDefinedInState(state) || notCorrectSupplierInState(state);
export const selectorSubscriptionLoadingFailed = (state: State) => state.subscription.subscriptionLoadingFailed;
export const selectorSupplierLoadingFailed = (state: State) => state.subscription.supplierLoadingFailed;
export const selectorUserInformationForm = (state: State) => state.subscription.userInformationForm;
export const selectorSelectedServices = (state: State) => state.subscription.selectedServices;
export const selectorServicesToDisplay = (state: State): ServiceToDisplay[] => {
  const selectedServices = state.subscription.selectedServices;
  const services = state.subscription.supplier?.services ?? [];

  return _(services)
    .map((service) => {
      const selectedService = find(selectedServices, { serviceId: service.id });

      return { ...service, ...selectedService };
    })
    .sortBy('name')
    .value();
};
export const selectorSubscriptionUpdateSucceeded = (state: State) => state.subscription.subscriptionUpdateSucceeded;
export const selectorSubscriptionUpdateFailed = (state: State) => state.subscription.subscriptionUpdateFailed;

export const selectorLatestPayment = (state: State) => {
  const subscription = selectorSubscription(state);
  if (subscription) {
    return subscription.source === 'bank-discovery' ? head(subscription.bankEvents)?.cost : subscription.cost;
  }

  return undefined;
};
