import PropTypes from 'prop-types';
import React from 'react';
import {Snackbar, RaisedButton} from 'material-ui';
import NotificationToggle from '../NotificationSettingsPage/components/NotificationToggle';
import Form from '../../shared-components/form-validation/Form';
import styles from './styles.scss';
import {Card} from '@minna-technologies/minna-ui/components/Card';
import MultiDeviceLayout from '../../../shared-components/layout/MultiDeviceLayout';
import PageNavigationBar from '../../../shared-components/PageNavigationBar';
import {EmailField} from '../../shared-components/inputs/EmailField';

//This component uses NotificationToggle from the settings module.
//This component should probably be moved to that module.
class SwedbankNotificationSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractChangeEmail: props.notificationSettings.contractChangeEmail,
      userEmailAddress: props.userEmailAddress,
      inputChanged: false
    };
  }

  handleUpdateMailingListSetting(value) {
    this.setState({
      ...this.state,
      contractChangeEmail: value,
      inputChanged: true
    });
  }

  handleUpdateUserEmailAddress(event) {
    this.setState({
      ...this.state,
      userEmailAddress: event.target.value.trim(),
      inputChanged: true
    });
  }

  handleSubmittingChanges() {
    const {contractChangeEmail, userEmailAddress} = this.state;
    this.setState({...this.state, inputChanged: false});
    this.props.submitUpdateSettings(userEmailAddress, contractChangeEmail);
  }

  render() {
    const {updateFailed, updateSucceeded} = this.props;
    const {contractChangeEmail, userEmailAddress, inputChanged} = this.state;
    const isCtaDisabled =
      inputChanged === false || Boolean(userEmailAddress) === false;

    const updateFailedNotice = (
      <Snackbar
        autoHideDuration={ 5000 }
        open={ updateFailed && !inputChanged }
        message="Ett okänt fel uppstod. Försök igen."
      />
    );

    const updateSuccessfulNotice = (
      <Snackbar
        autoHideDuration={ 5000 }
        open={ updateSucceeded && !inputChanged }
        message="Inställningarna uppdaterades"
      />
    );

    return (
      <MultiDeviceLayout header={ <PageNavigationBar title="Inställningar" primary /> }>
        <Card className={ styles.container }>
          <Form onValidSubmit={ this.handleSubmittingChanges.bind(this) }>
            <NotificationToggle
              title="Bevaka abonnemang"
              description="Bli meddelad via e-post när vi upptäcker prisförändringar i dina abonnemang."
              active={ contractChangeEmail }
              onUpdate={ this.handleUpdateMailingListSetting.bind(this) }
            />
            <EmailField
              inputProps={ {
                'data-test': 'notifications-email'
              } }
              name="userEmailAddress"
              label="E-post"
              hintText="Ange e-post"
              fullWidth
              className={ styles['email-field'] }
              requiredErrorText="E-postadress saknas"
              defaultValue={ userEmailAddress }
              onChange={ this.handleUpdateUserEmailAddress.bind(this) }
            />
            <RaisedButton
              data-test="save-notification-settings"
              label="Spara ändringar"
              primary
              fullWidth
              type="submit"
              disabled={ isCtaDisabled }
            />
          </Form>
        </Card>
        {updateFailedNotice}
        {updateSuccessfulNotice}
      </MultiDeviceLayout>
    );
  }
}

SwedbankNotificationSettingsPage.propTypes = {
  notificationSettings: PropTypes.object.isRequired,
  userEmailAddress: PropTypes.string,
  updateFailed: PropTypes.bool,
  updateSucceeded: PropTypes.bool,
  submitUpdateSettings: PropTypes.func.isRequired
};

SwedbankNotificationSettingsPage.defaultProps = {
  updateFailed: false
};

export default SwedbankNotificationSettingsPage;
