import type { OffsetDateTime } from '@client/models/OffsetDateTime';
import { offsetDateTimeFormat } from '@client/models/OffsetDateTime';
import { selectorSubscription } from '@client/modules/subscription';
import { ContractCardInfoChip } from '@client/modules/subscription/components/ContractCardInfoChip/component';
import {
  buttonClickedSubscriptionPageMixpanelEvent,
  viewSubscriptionPageMixpanelEvent,
} from '@client/modules/subscription/mixpanel';
import { SubscriptionDetailsPageComponent } from '@client/modules/subscription/SubscriptionDetailsPage/component';
import { ContractCard } from '@client/modules/subscription/SubscriptionDetailsPage/components/ContractCard/component';
import type { Contract } from '@client/modules/subscription/types';
import { ContractState } from '@client/modules/subscription/types';
import { contractDetailsPage } from '@client/routes';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const isTerminatedLongerThan30days = (dateTime: OffsetDateTime) => {
  return moment().isBefore(moment(dateTime, offsetDateTimeFormat).add(30, 'day'));
};

export const SubscriptionDetailsPage: React.FunctionComponent = () => {
  const subscription = useSelector(selectorSubscription);
  const pageName = 'Subscription details';
  function contractCardClicked(contractId: string) {
    if (subscription) {
      buttonClickedSubscriptionPageMixpanelEvent(subscription, 'Continue', pageName);
      history.push(contractDetailsPage(subscription.id, contractId), { previousUrl: history.location.pathname });
    }
  }
  const contracts: any[] = (subscription?.contracts ?? [])
    .filter((contract: Contract) => contract.state !== ContractState.Terminated)
    .sort((c1, c2) => c1.service.id.localeCompare(c2.service.id))
    .map((contract: Contract) => {
      const bindingTimeInfo = <ContractCardInfoChip contract={contract} />;

      return (
        <ContractCard
          key={contract.id}
          category={contract.service.category}
          categoryName={contract.service.name}
          onContractCardClicked={() => {
            contractCardClicked(contract.id);
          }}
          bottomMessage={bindingTimeInfo}
        />
      );
    });
  const cancelledContracts: any[] = (subscription?.contracts ?? [])
    .filter((contract: Contract) =>
      contract.state === ContractState.Terminated && contract.terminatedAt
        ? isTerminatedLongerThan30days(contract?.terminatedAt)
        : false
    )
    // TODO figure out the sort logic here. found in SubscriptionPage/variantC.tsx
    // .sort((contract: Contract) => {
    //   if (ALL_LOAN_CATEGORIES_ORDERED.includes(contract.service.category.name)) {
    //     return ALL_LOAN_CATEGORIES_ORDERED.indexOf(contract.service.category.name);
    //   } else {
    //     return contract.service.name;
    //   }
    // })
    // eslint-disable-next-line
    .map((contract: Contract) => {
      const bindingTimeInfo = <ContractCardInfoChip contract={contract} />;

      return (
        <ContractCard
          key={contract.id}
          category={contract.service.category}
          categoryName={contract.service.name}
          onContractCardClicked={() => {
            contractCardClicked(contract.id);
          }}
          bottomMessage={bindingTimeInfo}
        />
      );
    });

  const contractCancellationHistory: Contract[] = (subscription?.contracts ?? [])
    .filter((contract: Contract) =>
      contract.state === ContractState.Terminated && contract.terminatedAt
        ? !isTerminatedLongerThan30days(contract?.terminatedAt)
        : false
    )
    .sort((contract1: Contract, contract2: Contract) =>
      moment(contract1?.terminatedAt, offsetDateTimeFormat).diff(moment(contract2?.terminatedAt, offsetDateTimeFormat))
    );

  const history = useHistory();
  useMountEffect(() => {
    if (subscription) {
      viewSubscriptionPageMixpanelEvent(subscription, pageName);
    }
  });

  return (
    <SubscriptionDetailsPageComponent
      logoUrl={subscription?.supplier?.logoUrl}
      serviceProvider={subscription?.supplier?.name}
      contracts={contracts}
      cancelledContracts={cancelledContracts}
      cost={subscription?.cost}
      paymentInterval={subscription?.paymentInterval}
      contractCancellationHistory={contractCancellationHistory}
    />
  );
};
