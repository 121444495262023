import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { selectorSubscription } from '@client/modules/subscription';
import { BankEventListItem } from '@client/modules/subscription/PriceHistoryPage/components/BankEventListItem';
import type { BankEvent } from '@client/modules/subscription/types';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { parseDate } from '@client/utils/date';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.scss';

export const TransactionHistoryComponent: React.FunctionComponent = (
  // eslint-disable-next-line no-empty-pattern
  {},
  { localizeDate, localizeCostWithCurrency }
) => {
  const subscription = useSelector(selectorSubscription);
  const bankEvents = subscription?.bankEvents ?? [];
  let totalAmount = 0;
  const toDate = bankEvents.length ? parseDate(bankEvents[0].date) : '';
  const fromDate = bankEvents.length ? parseDate(bankEvents[bankEvents.length - 1].date) : '';
  const bankEventsList = bankEvents.map((bankEvent: BankEvent, index: number) => {
    totalAmount += bankEvent.cost.amount;

    return (
      <div key={index}>
        <BankEventListItem bankEvent={bankEvent} />
      </div>
    );
  });
  const totalCost = { amount: totalAmount, currency: bankEvents.length && bankEvents[0].cost.currency };

  return (
    <Card>
      <div className={styles.header}>
        <div className={styles.column}>
          <Title>
            <LocalizedMessage id="totalCost" />
          </Title>
          <Body>{`${localizeDate(fromDate, 'monthAndYear')} - ${localizeDate(toDate, 'monthAndYear')}`}</Body>
        </div>
        <div className={styles.reversedColumn}>
          <Body variant="bold">{localizeCostWithCurrency(totalCost, 2)}</Body>
        </div>
      </div>
      {bankEventsList}
    </Card>
  );
};

TransactionHistoryComponent.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
  localizeDate: PropTypes.func.isRequired,
};

export const TransactionHistory = withLocalization('contract/TransactionHistoryLolipop')(TransactionHistoryComponent);
