import Sentry from '@client/assets/js/sentry';
import { ELECTRICITY_GRID, INSURANCE } from '@client/constants/categories';
import { CancellationMethodType } from '@client/models/service';
import type { AppContext } from '@client/modules/app-context';
import { isCancellationEnabled } from '@client/modules/app-context';
import { CancellationAction } from '@client/modules/cancellation/duck';
import { CancellationConfirmationDialog } from '@client/modules/cancellation/models';
import { clickedContractActionMixpanelEventDeprecated } from '@client/modules/subscription/mixpanel';
import type { Contract, Subscription, SubscriptionId } from '@client/modules/subscription/types';
import { ContractState } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import type { TrackingPageName } from '@client/tracking/mixpanel-constants';
import type { History } from 'history';
import type { Dispatch } from 'redux';

export const onRequestCancellationOfContract = (
  appContext: AppContext,
  contract: Contract,
  subscription: Subscription,
  history: History,
  dispatch: Dispatch,
  suggestOtherActions = true,
  pageName?: TrackingPageName
) => {
  if (!isCancellationEnabled(appContext.features.actionFeatures)) {
    Sentry.captureMessage(
      'Invalid feature toggling: on cancel click in container, assertion failed that cancellation feature is enabled',
      {
        extra: { features: appContext.features },
      }
    );

    return;
  }

  clickedContractActionMixpanelEventDeprecated(contract, subscription, 'Cancel', pageName);
  const serviceCategoryName = contract.service.category.name;
  const saveDeskIsEnabled = contract.saveDeskEnabled && appContext.features.subscriptionFeatures.saveDeskEnabled;

  if (
    suggestOtherActions &&
    isCancellationEnabled(appContext.features.actionFeatures) &&
    isCancellable(contract) &&
    saveDeskIsEnabled
  ) {
    dispatch(
      CancellationAction.setCancellationConfirmationDialogOpen(
        CancellationConfirmationDialog.SaveDesk,
        contract,
        subscription
      )
    );
  } else {
    onCancelContract(appContext, contract, subscription, serviceCategoryName, dispatch, history);
  }
};

const onCancelContract = (
  appContext: AppContext,
  contract: Contract,
  subscription: Subscription,
  serviceCategoryName: string,
  dispatch: Dispatch,
  history: History
) => {
  const showCancellationInstructions = Boolean(contract.service.terminationInstructions) && !contract.terminable;

  if (!isCancellable(contract)) {
    dispatch(
      CancellationAction.setCancellationConfirmationDialogOpen(
        CancellationConfirmationDialog.NotCancellable,
        contract,
        subscription
      )
    );
  } else if (showCancellationInstructions) {
    history.push(urls.terminationInstructionsPage(subscription.id, contract.id));
  } else if (serviceCategoryName === ELECTRICITY_GRID) {
    dispatch(
      CancellationAction.setCancellationConfirmationDialogOpen(
        CancellationConfirmationDialog.ElectricityGrid,
        contract,
        subscription
      )
    );
  } else if (serviceCategoryName === INSURANCE) {
    dispatch(
      CancellationAction.setCancellationConfirmationDialogOpen(
        CancellationConfirmationDialog.Insurance,
        contract,
        subscription
      )
    );
  } else {
    goToCancelContractPage(subscription.id, contract, history);
  }
};

export const isCancellable = (contract: Contract) =>
  (contract.state === ContractState.Active && contract.terminable) || contract.service.terminationInstructions;

export const goToCancelContractPage = (subscriptionId: SubscriptionId, contract: Contract, history: History) => {
  switch (contract.cancellationMethod.type) {
    case CancellationMethodType.IntelligentGuide:
      history.push(urls.cancellationGuidePage(subscriptionId, contract.id));
      break;
    case CancellationMethodType.PowerOfAttorney:
      history.push(urls.terminationFormPage(subscriptionId, contract.id));
      break;
    case CancellationMethodType.NonCancellableInstructions:
      if (contract.service.terminationInstructions) {
        history.push(urls.terminationInstructionsPage(subscriptionId, contract.id));
      } else {
        Sentry.captureMessage(
          'Tried to visit cancellation instructions page without having cancellation instruction on service',
          { extra: { contract, subscriptionId } }
        );
      }
      break;
    default:
      Sentry.captureMessage(
        `Tried to visit cancellation page without finding matching cancellation method ${contract.cancellationMethod.type}`,
        {
          extra: { contract, subscriptionId },
        }
      );
  }
};
