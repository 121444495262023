import ingLinkIllustration from '@client/assets/images/ing-link-illustration.svg';
import opLinkIllustration from '@client/assets/images/op-link-illustration.svg';
import { AppType } from '@client/modules/app-context/constants';
import { phoneMaxWidth } from '@client/styles/responsive-helpers';
import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { ElectricityNetworkIcon } from '@minna-technologies/minna-ui/icons/ElectricityNetwork';
import type { FunctionComponent } from 'react';
import React from 'react';
import Div100vh from 'react-div-100vh';
import { ConsentPageHeader, ConsentPageHeaderType } from '../components/ConsentPageHeader';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';

interface ConsentPageWrapperProps {
  appType: AppType;
  children: any;
  trackViewedPage: () => void;
}

interface StyleProps {
  appType: AppType;
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  electricityIcon: {
    color: theme.colors.base.surface,
    transform: 'rotate(45deg)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    backgroundColor: (props) =>
      props.appType === AppType.OP ? theme.colors.base.background : theme.colors.base.surface,
    [theme.breakpoints.up(phoneMaxWidth)]: {
      minHeight: '100vh',
      overflow: 'auto',
    },
    '@supports (padding-bottom: calc(env(safe-area-inset-bottom)))': {
      paddingBottom: 'calc(env(safe-area-inset-bottom))',
      minHeight: 'calc(100vh - 64px - env(safe-area-inset-bottom))',
    },
  },
  containerInner: {
    padding: (props) => (props.appType === AppType.OP ? '0px' : '16px'),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    textAlign: 'center',
  },
}));

export const ConsentPageWrapper: FunctionComponent<ConsentPageWrapperProps> = ({
  appType,
  children,
  trackViewedPage,
}) => {
  const classes = useStyles({ appType });

  useMountEffect(() => {
    trackViewedPage();
  });

  const consentPageHeader = (() => {
    switch (appType) {
      case AppType.ING:
        return (
          <ConsentPageHeader
            illustration={ingLinkIllustration}
            altText={'ING Bank'}
            headerType={ConsentPageHeaderType.OldHeader}
          />
        );
      case AppType.OP:
        return (
          <ConsentPageHeader
            illustration={opLinkIllustration}
            altText={'OP Bank'}
            headerType={ConsentPageHeaderType.TransparentHeader}
          />
        );
      default:
        return (
          <ConsentPageHeader
            icon={<ElectricityNetworkIcon size={100} className={classes.electricityIcon} />}
            altText={''} // TODO Maybe fix this, could cause lokalization issues
            headerType={ConsentPageHeaderType.GenericHeader}
          />
        );
    }
  })();

  return (
    <MultiDeviceLayout>
      <Div100vh className={classes.container}>
        {consentPageHeader}
        <div className={classes.containerInner}>{children}</div>
      </Div100vh>
    </MultiDeviceLayout>
  );
};
