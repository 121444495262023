import type { Question } from '@client/models/cancellation-questions';
import type { SupplierService, SupplierWithServices } from '@client/models/supplier';
import type { CancellationConfirmationDialog } from '@client/modules/cancellation/models';
import { closeConnection } from '@client/modules/cancellation/websocketHelpers';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import type { ActionsUnion } from '@client/utils/redux';
import { createAction } from '@client/utils/redux';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import set from 'lodash/set';

export interface CancellationState {
  formSubmitted: boolean;
  currentRating: number;
  terminationAnswers: any;
  terminationAnswersForContractId: any;
  previewLoaText: string;
  previewDialogLoaOpen: boolean;
  terminationQuestions: Question[];
  connectionStarted: boolean;
  loaPreview?: LoaPreview;
  desiredTerminationAt?: Date;
  status?: any;
  isSendingCancellation?: boolean;
  contractToCancel?: Contract;
  subscriptionToCancel?: Subscription;
  serviceToCancel?: SupplierService;
  supplierToCancel?: SupplierWithServices;
  electricityConfirmationDialogOpen?: CancellationConfirmationDialog;
  isCancelButtonClicked: boolean;
}

export interface LoaPreview {
  loaText: string;
  user: any;
  desiredTerminationAt?: string;
  terminationQuestionResults: any[];
  supplierName: string;
}

const initialState: CancellationState = {
  formSubmitted: false,
  currentRating: 0,
  terminationAnswers: {},
  terminationAnswersForContractId: {},
  previewLoaText: '',
  previewDialogLoaOpen: false,
  terminationQuestions: [],
  connectionStarted: false,
  isCancelButtonClicked: false,
};

/*
 * ACTIONS AND ACTION CREATORS
 */

enum ActionKeys {
  SIGNING_CONNECTION_STARTED = 'TERMINATION/SIGNING_CONNECTION_STARTED',
  SIGNING_CONNECTION_CLOSED = 'TERMINATION/SIGNING_CONNECTION_CLOSED',
  SIGNING_STATUS_CHANGED = 'TERMINATION/SIGNING_STATUS_CHANGED',
  SET_TERMINATION_ANSWER = 'TERMINATION/SET_TERMINATION_ANSWER',
  SET_TERMINATION_ANSWERS = 'TERMINATION/SET_TERMINATION_ANSWERS',
  SET_DESIRED_TERMINATION_DATE = 'TERMINATION/SET_DESIRED_TERMINATION_DATE',
  SET_CURRENT_RATING = 'TERMINATION/SET_CURRENT_RATING',
  UPDATE_TERMINATION_INFORMATION = 'TERMINATION/UPDATE_TERMINATION_INFORMATION',
  SET_LOA_PREVIEW = 'TERMINATION/SET_LOA_PREVIEW',
  SIGNING_CANCEL = 'TERMINATION/SIGNING_CANCEL',
  PREVIEW_LOA_DIALOG_OPEN = 'TERMINATION/PREVIEW_LOA_DIALOG_OPEN',
  SET_IS_SENDING_CANCELLATION = 'TERMINATION/SET_IS_SENDING_CANCELLATION',
  ELECTRICITY_CONFIRMATION_DIALOG_OPEN = 'TERMINATION/ELECTRICITY_CONFIRMATION_DIALOG_OPEN',
  SET_CANCEL_BUTTON_CLICKED = 'TERMINATION/SET_CANCEL_BUTTON_CLICKED',
}

export const CancellationAction = {
  setCancellationConfirmationDialogOpen: (
    openDialog?: CancellationConfirmationDialog,
    contract?: Contract,
    subscription?: Subscription,
    service?: SupplierService,
    supplier?: SupplierWithServices
  ) =>
    createAction(ActionKeys.ELECTRICITY_CONFIRMATION_DIALOG_OPEN, {
      openDialog,
      contract,
      subscription,
      service,
      supplier,
    }),
  setPreviewDialogLoaOpen: (open: boolean) => createAction(ActionKeys.PREVIEW_LOA_DIALOG_OPEN, open),
  statusChanged: (status: any) => createAction(ActionKeys.SIGNING_STATUS_CHANGED, status),
  connectionStarted: () => createAction(ActionKeys.SIGNING_CONNECTION_STARTED),
  connectionClosed: () => createAction(ActionKeys.SIGNING_CONNECTION_CLOSED),
  cancel: () => {
    closeConnection();

    return createAction(ActionKeys.SIGNING_CANCEL);
  },
  setTerminationAnswer: (questionId: string, value: any) =>
    createAction(ActionKeys.SET_TERMINATION_ANSWER, { questionId, value }),
  setDesiredTerminationAt: (desiredDate?: Date) => createAction(ActionKeys.SET_DESIRED_TERMINATION_DATE, desiredDate),
  setCancelButtonClicked: (isCancelButtonClicked: boolean) =>
    createAction(ActionKeys.SET_CANCEL_BUTTON_CLICKED, isCancelButtonClicked),
  setCurrentRating: (currentRating: number) => createAction(ActionKeys.SET_CURRENT_RATING, currentRating),
  updateTerminationInformation: (cancellationQuestions: Question[]) =>
    createAction(ActionKeys.UPDATE_TERMINATION_INFORMATION, { cancellationQuestions }),
  setLoaPreview: (loaPreview: any) => createAction(ActionKeys.SET_LOA_PREVIEW, loaPreview),
  setInitialFormAnswers: (contract: Contract) => {
    const existingTerminationAnswers = get(contract, 'terminationFormData.answers', []);
    const terminationAnswers = {};
    forEach(existingTerminationAnswers, (answer) => {
      const typesValueMap = {
        EmailAnswer: ['emailAddress'],
        TextAnswer: ['text'],
        TelephoneAnswer: ['number'],
        AddressAnswer: ['city', 'postCode', 'street'],
        DateAnswer: ['date'],
        NestedAnswer: ['nestedQuestionAlternativeId'],
        RecipientAnswer: ['alternativeId'],
      };

      const { type, questionId } = answer;

      const answerObject = {};
      // @ts-ignore
      forEach(typesValueMap[type], (path) => {
        const value = get(answer, path);
        set(answerObject, path, value);
      });

      // @ts-ignore
      terminationAnswers[questionId] = answerObject;
    });
    const contractId = contract.id;

    return createAction(ActionKeys.SET_TERMINATION_ANSWERS, { terminationAnswers, contractId });
  },
  setIsSendingCancellation: (isSending: boolean) => createAction(ActionKeys.SET_IS_SENDING_CANCELLATION, isSending),
};

export type CancellationActions = ActionsUnion<typeof CancellationAction>;

export function reducer(state: CancellationState = initialState, action: CancellationActions): CancellationState {
  switch (action.type) {
    case ActionKeys.SIGNING_CONNECTION_STARTED:
      return { ...state, connectionStarted: true, status: { type: 'Connecting' } };

    case ActionKeys.SIGNING_CONNECTION_CLOSED:
      if (get(state, 'status.finished')) {
        return { ...state, connectionStarted: false };
      } else {
        return { ...state, connectionStarted: false, status: { type: 'GeneralError' } };
      }
    case ActionKeys.SIGNING_STATUS_CHANGED: {
      return { ...state, status: action.payload };
    }
    case ActionKeys.SIGNING_CANCEL: {
      return { ...state, connectionStarted: false, status: undefined };
    }
    case ActionKeys.SET_TERMINATION_ANSWER: {
      const { questionId, value } = action.payload;

      return {
        ...state,
        terminationAnswers: { ...state.terminationAnswers, [questionId]: value },
      };
    }
    case ActionKeys.SET_TERMINATION_ANSWERS: {
      if (get(state, 'terminationAnswersForContractId') !== action.payload.contractId) {
        return {
          ...state,
          terminationAnswers: action.payload.terminationAnswers,
          terminationAnswersForContractId: action.payload.contractId,
        };
      } else {
        return state;
      }
    }
    case ActionKeys.SET_DESIRED_TERMINATION_DATE: {
      return { ...state, desiredTerminationAt: action.payload };
    }
    case ActionKeys.SET_CANCEL_BUTTON_CLICKED: {
      return { ...state, isCancelButtonClicked: action.payload };
    }
    case ActionKeys.SET_CURRENT_RATING: {
      return { ...state, currentRating: action.payload };
    }
    case ActionKeys.UPDATE_TERMINATION_INFORMATION: {
      return {
        ...state,
        terminationQuestions: action.payload.cancellationQuestions,
      };
    }
    case ActionKeys.SET_LOA_PREVIEW: {
      return { ...state, loaPreview: action.payload };
    }
    case ActionKeys.PREVIEW_LOA_DIALOG_OPEN: {
      return { ...state, previewDialogLoaOpen: action.payload };
    }
    case ActionKeys.SET_IS_SENDING_CANCELLATION: {
      return { ...state, isSendingCancellation: action.payload };
    }
    case ActionKeys.ELECTRICITY_CONFIRMATION_DIALOG_OPEN: {
      return {
        ...state,
        electricityConfirmationDialogOpen: action.payload.openDialog,
        contractToCancel: action.payload.contract,
        subscriptionToCancel: action.payload.subscription,
        serviceToCancel: action.payload.service,
        supplierToCancel: action.payload.supplier,
      };
    }
    default:
      return state;
  }
}

export const selectorSubscriptionToCancel = (state: any) => state.cancellation.subscriptionToCancel;
export const selectorContractToCancel = (state: any) => state.cancellation.contractToCancel;
export const selectorServiceToCancel = (state: any) => state.cancellation.serviceToCancel;
export const selectorSupplierToCancel = (state: any) => state.cancellation.supplierToCancel;
export const selectorElectricityConfirmationOpenDialog = (state: any) =>
  state.cancellation.electricityConfirmationDialogOpen;
export const selectorPreviewDialogLoaOpen = (state: any) => state.cancellation.previewDialogLoaOpen;
export const selectorTerminationQuestions = (state: any) => state.cancellation.terminationQuestions;
export const selectorTerminationAnswers = (state: any) => state.cancellation.terminationAnswers;
export const selectorLoaPreview = (state: any) => state.cancellation.loaPreview;
export const selectorFormSubmitted = (state: any) => state.cancellation.formSubmitted;
export const selectorDesiredTerminationAt = (state: any) => state.cancellation.desiredTerminationAt;
export const selectorSigningStatus = (state: any) => state.cancellation.status;
export const selectorCurrentRating = (state: any) => state.cancellation.currentRating;
export const selectorIsSendingCancellation = (state: any) => state.cancellation.isSendingCancellation;
export const selectorIsCancelButtonClicked = (state: any) => state.cancellation.isCancelButtonClicked;
