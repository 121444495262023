import { LocalizedMessage } from '@client/internationalization';
import { AppType, Market } from '@client/modules/app-context/constants';
import * as urls from '@client/routes';
import { UserMenuWithIconHeaderButton } from '@client/shared-components/Header/UserMenuWithIconHeaderButton';
import { ingColors } from '@client/styles/themes/ingColors';
import { LABEL_FAQ_GENERAL } from '@client/zendesk';
import type { Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import createStyles from '@material-ui/core/styles/createStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import type { History } from 'history';
import PropTypes from 'prop-types';
import React from 'react';
import type { AppContext } from '../../modules/app-context';
import Visible from '../layout/Visible';
import { LoginButton } from './LoginButton';
import { Logo } from './Logo';
import { Tab } from './Tab';
import { ZendeskHeaderHelpButton } from './ZendeskHeaderHelpButton';

interface HeaderProps {
  isLoggedIn: boolean;
  showLoginButton?: boolean;
  location: Location;
  appContext: AppContext;
  history: History;
  classes: any;
}

const styles = ({ colors }: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: colors.base.surface,
    },
    header: {
      minHeight: '56px',
      maxHeight: '56px',
      backgroundColor: colors.base.surface,
      boxShadow: '0 1px 6px rgba(0,0,0,0.12), 0 1px 4px rgba(0,0,0,0.12)',
      paddingLeft: '0',
      paddingRight: '0',
      position: 'sticky',
      top: 0,
      zIndex: 1200,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    toolbar: {
      width: '100%',
      maxWidth: '640px',
      //Adds extra padding to compensate padding added by Zendesk button
      paddingLeft: '12px',
      paddingRight: '0',
      margin: '0 auto',
    },
    mobileTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
    mobileTitleCentered: {
      display: 'block',
      textAlign: 'center',
    },
    mobileTitleLeftAligned: {
      display: 'block',
      textAlign: 'left',
    },
    minDesktopTitle: {
      display: 'flex',
      alignItems: 'start',
    },
    toolbarGroup: {
      maxWidth: '672px',
      width: '100%',
      height: '56px',
      margin: '0',
      paddingLeft: '16px',
      paddingRight: '16px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    toolbarGroupWithZendeskIcon: {
      paddingRight: '4px',
    },
    rightElements: {
      height: '56px',
      alignItems: 'center',
      display: 'flex',
    },
    button: {
      width: 56,
      height: 56,
    },
    // Adds extra padding to compensate missing Zendesk button
    noButtonOnTheRight: {
      paddingRight: '48px',
    },
  });

// eslint-disable-next-line , complexity
export const HeaderComponentInner: React.FunctionComponent<HeaderProps> = (
  { isLoggedIn, showLoginButton = true, location, appContext, history, classes },
  { localizeMessage }
) => {
  const { colors } = useTheme();
  const { features } = appContext;
  // TODO: remove when custom theming is available
  const isIng = appContext.appType === AppType.ING && appContext.market === Market.Belgium;
  const isOp = appContext.appType === AppType.OP;

  const menuUrl = urls.menuPage;

  const loggedOutRightElementsMobile = !isLoggedIn && showLoginButton && (
    <LoginButton label={<LocalizedMessage id="loginButton" />} />
  );
  const loggedOutRightElementsTabletUp = !isLoggedIn && showLoginButton && (
    <LoginButton variant={'contained'} label={<LocalizedMessage id="loginButtonTabletUp" />} />
  );

  const helpIcon = features.helpCenter.enabled && features.navigation && (
    <ZendeskHeaderHelpButton
      query={LABEL_FAQ_GENERAL}
      iconColor={isIng ? ingColors.primary : colors.action.primary}
      className={classes.button}
    />
  );

  const loggedInRightElementsMobile = (
    <>
      {features.navigation?.userMenuIcon && (
        <UserMenuWithIconHeaderButton
          iconColor={isIng ? ingColors.primary : colors.action.primary}
          onClick={() => history.push(menuUrl)}
        />
      )}
      {helpIcon}
    </>
  );

  function isActive(path: string) {
    return location.pathname === path;
  }

  const toolbarClasses = () => {
    if (!helpIcon) {
      return classNames(classes.toolbar, classes.noButtonOnTheRight);
    } else {
      return classes.toolbar;
    }
  };

  const mobileTitleClasses = () => {
    if (isLoggedIn) {
      return classNames(classes.mobileTitle, classes.mobileTitleLeftAligned);
    } else {
      return classes.mobileTitle;
    }
  };

  const dashboardNavigation = isLoggedIn && !isOp && (
    <>
      {features.identify?.overview && (
        <Tab
          data-test="top-navigation-overview"
          label={localizeMessage('dashboardNavigationButtonOverview')}
          path={urls.overviewPage}
          active={isActive(urls.overviewPage)}
          isIng={isIng}
        />
      )}
      {features.insights && (
        <Tab
          data-test="top-navigation-improve"
          label={localizeMessage('dashboardNavigationButtonInsights')}
          path={urls.insightsPage}
          active={isActive(urls.insightsPage)}
          isIng={isIng}
        />
      )}
      {features.settings && (
        <Tab
          data-test="top-navigation-more"
          label={
            <>
              {features.navigation?.userMenuIcon ? (
                <UserMenuWithIconHeaderButton iconColor={isIng ? ingColors.primary : colors.action.primary} />
              ) : (
                localizeMessage('dashboardNavigationButtonMore')
              )}
            </>
          }
          path={menuUrl}
          active={isActive(menuUrl)}
          isIng={isIng}
        />
      )}
    </>
  );

  return (
    <>
      <Visible on="min-md">
        <Toolbar data-test="top-navigation" className={classes.header}>
          <div
            className={
              helpIcon ? classNames(classes.toolbarGroup, classes.toolbarGroupWithZendeskIcon) : classes.toolbarGroup
            }
          >
            <div className={classes.minDesktopTitle}>
              <Logo isLoggedIn={isLoggedIn} appContext={appContext} type={isIng ? 'text' : 'image'} />
            </div>
            <div className={classes.rightElements}>
              {dashboardNavigation}
              {loggedOutRightElementsTabletUp}
              {isLoggedIn && helpIcon}
            </div>
          </div>
        </Toolbar>
      </Visible>
      <Visible on="max-sm">
        <AppBar position="sticky" className={classes.appBar}>
          <Toolbar className={toolbarClasses()}>
            <div className={mobileTitleClasses()}>
              <Logo isLoggedIn={isLoggedIn} appContext={appContext} type={isIng ? 'text' : 'image'} />{' '}
            </div>
            {loggedOutRightElementsMobile || loggedInRightElementsMobile}
          </Toolbar>
        </AppBar>
      </Visible>
    </>
  );
};

HeaderComponentInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const HeaderComponent = withStyles(styles)(HeaderComponentInner);
