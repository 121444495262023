// @ts-ignore
import { isAndroidApp, isIosApp, isNative, isSimulatingNative } from './device-helper';
// @ts-ignore
import { sendAndroidEvent, sendIosEvent } from './send-event';

const EVENT_ID = 'openInBrowser';

/**
 * This code is shared with us from Sb1's module for mobilbank-communication. This is version 5.5.1.
 * Original file path is: package/lib-es/events/open-in-browser.js
 */

/**
 * Open an url in the phone's browser
 *
 * @param url - The url path to be opened
 */
export function openInBrowser(url: string): void {
  if (isIosApp()) {
    sendIosEvent({
      action: EVENT_ID,
      url: url,
    });
  } else if (isAndroidApp()) {
    // @ts-ignore
    sendAndroidEvent(EVENT_ID, url);
  } else if (isSimulatingNative() || !isNative()) {
    window.open(url, '_blank');
  }
}
