import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { selectorDisplayName } from '@client/modules/app-context/duck';
import { DoneIcon } from '@client/modules/subscription/unresolved-guide/icons/DoneIcon';
import { TrackingButtonLocation } from '@client/tracking/mixpanel-constants';
import {
  clickedSubscriptionProtectionPageEvent,
  viewSubscriptionProtectionPageEvent,
} from '@client/tracking/mixpanel-events';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useTheme from '@material-ui/core/styles/useTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { CloseIcon } from '@minna-technologies/minna-ui/icons/Close';
import List from 'material-ui/List/List';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.scss';

const EnableNotificationsDialogComponent: React.FunctionComponent<EnableNotificationsDialogProps> = ({
  onClose,
  onEnableNotificationClicked,
  onSkipClicked,
  open,
}) => {
  const { colors } = useTheme();
  const displayName = useSelector(selectorDisplayName);
  useEffect(() => {
    viewSubscriptionProtectionPageEvent();
  }, []);

  return (
    <StyledDialog open={open} maxWidth="sm" onClose={(event, reason) => onClose(reason)}>
      <StyledDialogTitle disableTypography>
        <Title color={colors.action.primary} style={{ flexGrow: 1, textAlign: 'center' }}>
          <LocalizedMessage id="subscriptionProtection" />
        </Title>
        <CloseIcon
          onClick={() => {
            clickedSubscriptionProtectionPageEvent('Close', TrackingButtonLocation.TOP_RIGHT);
            onClose();
          }}
        />
      </StyledDialogTitle>
      <DialogContent>
        <Body>
          <LocalizedMessage id="weCanHelpYou" />
        </Body>
        <List>
          <div className={styles.listItem}>
            <DoneIcon style={{ marginRight: '8px' }} />
            <Body>
              <LocalizedMessage id="providerChargesMore" />
            </Body>
          </div>
          <div className={styles.listItem}>
            <DoneIcon style={{ marginRight: '8px' }} />
            <Body>
              <LocalizedMessage id="weFindNewSubscriptions" />
            </Body>
          </div>
          <div className={styles.listItem}>
            <DoneIcon style={{ marginRight: '8px' }} />
            <Body>
              <LocalizedMessage id="bindingPeriodExpires" />
            </Body>
          </div>
        </List>
        <Body>
          <LocalizedMessage id="muchMoreV2" values={{ displayName: displayName }} />
        </Body>
      </DialogContent>
      <DialogActions style={{ flexDirection: 'column', padding: '8px 12px' }}>
        <StyledSecondaryButton
          label={<LocalizedMessage id="skip" />}
          onClick={() => {
            clickedSubscriptionProtectionPageEvent('Skip', TrackingButtonLocation.BOTTOM_CENTER);
            onSkipClicked();
          }}
        />
        <StyledPrimaryButton
          label={<LocalizedMessage id="activateProtection" />}
          onClick={() => {
            clickedSubscriptionProtectionPageEvent('Activate Notifications', TrackingButtonLocation.BOTTOM_CENTER);
            onEnableNotificationClicked();
          }}
        />
      </DialogActions>
    </StyledDialog>
  );
};

export interface EnableNotificationsDialogProps {
  onClose(reason?: string): void;
  onEnableNotificationClicked(): void;
  onSkipClicked(): void;
  open: boolean;
}

export const EnableNotificationsDialog = withLocalization('overview/InsightsPage/OnboardingInsightCard')(
  EnableNotificationsDialogComponent
);

const StyledDialog = withStyles({
  paperWidthSm: {
    width: '343px',
    flexShrink: 0,
  },
})(Dialog);

const StyledDialogTitle = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
})(DialogTitle);

const StyledPrimaryButton = withStyles({
  root: {
    margin: '0 16px 4px 16px',
    width: '100%',
  },
})(PrimaryButton);

const StyledSecondaryButton = withStyles((theme) => ({
  root: {
    margin: '0 16px 16px 16px',
    width: '100%',
    color: theme.colors.textOn.surfaceSubdued,
  },
}))(SecondaryButton);
