import type { Category } from '@client/models/service';
import type { AmountWithCurrency } from '@client/models/subscription';
import type { UserMessage } from '@client/modules/subscription/types';
import type { CampaignPeriod, ServiceProvider, TermsAndConditions } from '@client/modules/switch/common/models';
import type {
  DataCapacityInGb,
  ExtraDataCampaign,
  MessagesLimitation,
  PhoneCallsLimitation,
} from '@client/modules/switch/mobile/models';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

export type OrderId = string;

export interface OrderedSubscription {
  orderId: OrderId;
  product: OrderedProduct;
  portingOrStartDate: string;
  waitingForTerminationInformation: boolean;
  oldSupplierName: string;
  actionRequestedAt: string;
  userMessage: UserMessage;
  userRating?: number;
}

export enum ProductWithSupplierInformationType {
  GenericProductWithSupplierInformation = 'GenericProductWithSupplierInformation',
  MobileOrderedProductAndProviderInformation = 'MobileOrderedProductAndProviderInformation',
  MobileOptimizeProductWithSupplierInformation = 'MobileOptimizeProductWithSupplierInformation',
  BroadbandProductWithSupplierInformation = 'BroadbandProductWithSupplierInformation',
}

export interface OrderedProduct {
  type: ProductWithSupplierInformationType;
  category: Category[];
  supplierInformation: ServiceProvider;
  information: ProductInformation;
  termsAndConditions?: TermsAndConditions[];
}

export interface OrderedGenericProduct extends OrderedProduct {
  type: ProductWithSupplierInformationType.GenericProductWithSupplierInformation;
  category: Category[];
  orderCreatedAt: string;
  monthlyCost: AmountWithCurrency;
  startUpFee: AmountWithCurrency;
}

export interface OrderedMobileProductLegacy extends OrderedProduct {
  type: ProductWithSupplierInformationType.MobileOptimizeProductWithSupplierInformation;
  category: Category[];
  orderCreatedAt: string;
  information: MobileProductInformationLegacy;
  pricing: ProductPricing;
  campaign?: MobileCampaign;
}

export interface OrderedMobileProduct extends OrderedProduct {
  type: ProductWithSupplierInformationType.MobileOrderedProductAndProviderInformation;
  category: Category[];
  termsAndConditions?: TermsAndConditions[];
  supplierInformation: ServiceProvider;
  information: MobileOrderedProductInformation;
}

export interface MobileOrderedProductInformation extends OrderedProduct {
  name: string;
  description: string;
  terminationTime: number;
  bindingPeriod: number;
  monthlyCost: AmountWithCurrency;
  dataCapacityInGb: DataCapacityInGb;
  euDataCapacityInGb?: DataCapacityInGb;
  messagesLimitation: MessagesLimitation;
  phoneCallsLimitation: PhoneCallsLimitation;
  fees: { startUp?: AmountWithCurrency };
  campaign?: CampaignPeriod;
  extraData?: ExtraDataCampaign;
  welcomeDiscount?: AmountWithCurrency;
}

export interface OrderedBroadbandProduct extends OrderedProduct {
  type: ProductWithSupplierInformationType.BroadbandProductWithSupplierInformation;
  category: Category[];
  orderCreatedAt: string;
  information: ProductInformation;
  price: BroadbandPrice;
  fees?: BroadbandFees;
  networkType: string;
  speed: {
    up: number;
    down: number;
  };
}

export interface MobileCampaign {
  welcomeDiscount?: AmountWithCurrency;
  period?: number;
  onlyForNewCustomers: boolean;
  discountedPricing: MobileProductPricing;
}

interface MobileProductPricing {
  monthlyCost: number;
  costPerSms?: number;
  costPerPhoneCallMinute?: number;
  costPerGbOfData?: number;
}

export interface ProductInformation {
  name: string;
  description: string;
  bindingPeriod: number;
  terminationTime: number;
}

export interface MobileProductInformationLegacy extends ProductInformation {
  name: string;
  description: string;
  bindingPeriod: number;
  terminationTime: number;
  dataCapacityInGb: MobileDataCapacityLimitation;
}

export interface ProductPricing {
  monthlyCost: number;
}

type Gigabyte = number;

export enum MobileDataCapacityLimitationType {
  UnlimitedDataCapacity = 'UnlimitedDataCapacity',
  LimitedDataCapacity = 'LimitedDataCapacity',
}

type MobileDataCapacityLimitation = UnlimitedDataCapacity | LimitedDataCapacity;

interface UnlimitedDataCapacity {
  name: MobileDataCapacityLimitationType.UnlimitedDataCapacity;
}

interface LimitedDataCapacity {
  name: MobileDataCapacityLimitationType.LimitedDataCapacity;
  amount: Gigabyte;
}

export interface BroadbandFees {
  router?: number;
  freight?: number;
  startup?: number;
}

export interface BroadbandPrice {
  fullMonthlyCost: number;
  campaignPrice?: number;
  discountMonths?: number;
  secondCampaignPrice?: number;
  secondCampaignDiscountMonths?: number;
}

export const fetchOrderedSubscription = async (orderId: OrderId): Promise<OrderedSubscription | undefined> =>
  axios
    .get(`/api/user/ordered-subscriptions/${orderId}`)
    .then(({ data }: AxiosResponse<OrderedSubscription | undefined>) => data);
