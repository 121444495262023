import { withLocalization } from '@client/internationalization';
import { ingColors } from '@client/styles/themes/ingColors';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ButtonText } from '@minna-technologies/minna-ui/components/Typography/ButtonText';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';

const borderWidth = '4px';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 40,
    minHeight: 40,
    minWidth: 48,
    padding: '0 16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.base.surface,
    },
  },
  container: {
    minHeight: '56px',
    maxHeight: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  active: {
    borderBottom: `${theme.colors.action.primary} ${borderWidth} solid`,
    // border top is needed to align active button text with other buttons
    borderTop: `transparent ${borderWidth} solid`,
  },
  activeIng: {
    borderBottom: `${ingColors.primary} ${borderWidth} solid`,
    // border top is needed to align active button text with other buttons
    borderTop: `transparent ${borderWidth} solid`,
  },
}));

interface TabInnerProps {
  label: ReactNode;
  active?: boolean;
  classes?: any;
  'data-test'?: string;
  // eslint-disable-next-line
  path: string;
  isIng: boolean;
}

const TabInner: React.FunctionComponent<TabInnerProps> = ({ label, path, 'data-test': dataTest, active, isIng }) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const activeClass = isIng ? classes.activeIng : classes.active;

  const labelColor = () => {
    if (active && isIng) {
      return ingColors.primary;
    } else if (active) {
      return colors.action.primary;
    } else {
      return colors.base.border;
    }
  };

  return (
    <div className={classNames(classes.container, active && activeClass)}>
      <Button
        // @ts-ignore
        component={Link}
        to={path}
        data-test={dataTest}
        className={classes.button}
        disableRipple
      >
        <ButtonText color={labelColor()}>{label}</ButtonText>
      </Button>
    </div>
  );
};

export const Tab = compose<TabInnerProps, any>(withLocalization('Header'))(TabInner);
