import notificationSettings from './NotificationSettingsPage/NotificationSettingsPage/duck';
import {reducer as profilingSettings} from './ProfilingSettingsPage/duck';
import {reducer as userSettings} from './UserSettingsPage/duck';

const settings = {
  notificationSettings,
  profilingSettings,
  userSettings
};

export default settings;
