import { Sentry } from '@client/assets/js/sentry';
import { selectorAcceptedPrivacyPolicyAndTermsOfUse } from '@client/ducks/user';
import { withLocalization } from '@client/internationalization';
import { acceptPrivacyPolicyAndTermsOfUse } from '@client/models/user';
import type { AppFeatureToggles } from '@client/modules/app-context';
import type { AppType, Market } from '@client/modules/app-context/constants';
import { selectorAppType, selectorFeatures, selectorMarket } from '@client/modules/app-context/duck';
import { TrackingPageName } from '@client/tracking/mixpanel-constants';
import { finishedOnboardingMixpanelEvent } from '@client/tracking/mixpanel-events';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import { psd2AuthenticationWithClient } from '../psd2AuthenticationWithClient';
import { ConsentPage } from './component';

interface ConsentPageContainerProps {
  market: Market;
  appType: AppType;
  features: AppFeatureToggles;
  agreedToTerms: boolean;
}

const mapStateToProps = (state: any) => ({
  market: selectorMarket(state),
  appType: selectorAppType(state),
  features: selectorFeatures(state),
  agreedToTerms: selectorAcceptedPrivacyPolicyAndTermsOfUse(state),
});

export const ConsentPageContainer = compose(
  connect(mapStateToProps),
  withLocalization('onboarding-psd2/ConsentPage'),
  withProps(({ market, appType, features, agreedToTerms }: ConsentPageContainerProps) => ({
    onContinueToConsentClick: async () => {
      const shouldSaveAgreedToPrivacyPolicyAndUserTerms =
        features.conditionsAndPolicies && features.conditionsAndPolicies.saveUserConsent;
      if (shouldSaveAgreedToPrivacyPolicyAndUserTerms) {
        if (agreedToTerms) {
          try {
            await acceptPrivacyPolicyAndTermsOfUse();
          } catch (error) {
            Sentry.captureExceptionWithMessage(
              error,
              `Failed to store use consent for market: ${market} and  appType: ${appType} `
            );
            throw error;
          }
          finishedOnboardingMixpanelEvent(TrackingPageName.NEW_CONSENT, market, appType);
          const url = await psd2AuthenticationWithClient();
          window.location = url;
        }
      } else {
        finishedOnboardingMixpanelEvent(TrackingPageName.NEW_CONSENT, market, appType);
        const url = await psd2AuthenticationWithClient();
        window.location = url;
      }
    },
  }))
  // @ts-ignore
)(ConsentPage);
