import { Sentry } from '@client/assets/js/sentry';
import { landingPage } from '@client/routes';
import type { History } from 'history';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * We don't have a consistent way to handle history in our app.
 * Sometimes the back-button pushes a new entry to the history stack, sometimes it navigates backwards in history.
 *
 * Some day we need to fix this properly, but currently you need to think very hard and be careful about
 * history-manipulation for any page you create. Other pages might have some undisclosed assumptions about the
 * history stack to function as expected.
 */

/*
 * @deprecated Use useBackOrDefault instead.
 * A common pattern is to want to navigate back in history - or back to a good default if that fails.
 */
export function goBackOrDefault(history: History, backup: string = landingPage) {
  if (history.length > 1) {
    Sentry.addBreadcrumb({
      message: 'Navigated backwards in history.',
      category: 'navigation',
      data: { currentUrl: history.location.pathname },
    });
    history.goBack();
  } else {
    Sentry.addBreadcrumb({
      message: 'Tried to navigate backwards with empty history.',
      category: 'navigation',
      data: { currentUrl: history.location.pathname, backup },
    });
    history.replace(backup);
  }
}

// A common pattern is to want to pass a "go back" function to a component.
export function useBackOrDefault(backup: string = landingPage): () => void {
  const history = useHistory();

  return useCallback(() => goBackOrDefault(history, backup), [history, backup]);
}

// Returns "go back" function or 'undefined' if current page is the first one the user visits
// Should be used for publicly displayed pages
export function useBackOrUndefined() {
  try {
    return useBackOrDefault();
  } catch {
    return undefined;
  }
}
