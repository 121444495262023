import type { CategoryName } from '@client/constants/categories';
import type { Question } from '@client/models/cancellation-questions';
import type { LocalDate } from '@client/models/LocalDate';
import type { OffsetDateTime } from '@client/models/OffsetDateTime';
import type { OrderState, RejectedReason } from '@client/models/order';
import type { CancellationMethodType } from '@client/models/service';
import type { AmountWithCurrency } from '@client/models/subscription';
import type { UserId } from '@client/models/user-models';
import type { ZonedDateTime } from '@client/models/ZonedDateTime';
import type { CancellationOutcome } from '@client/modules/cancellation/models';
import type { ActionType } from '@client/modules/InsightsPage/models';
import type { PauseMethod, PauseOutcome } from '@client/modules/pause/models';
import moment from 'moment';

export type SupplierId = string;
export type ServiceId = string;
export type ContractId = string;
export type SubscriptionId = string;
export const BindingTimeMonitoringThresholdInDays = 40;

export enum BindingPeriodType {
  UnknownContractBindingPeriod = 'UnknownContractBindingPeriod',
  NoContractBindingPeriod = 'NoContractBindingPeriod',
  FixedContractBindingPeriod = 'FixedContractBindingPeriod',
}

export type ContractBindingPeriod =
  | {
      type: BindingPeriodType.UnknownContractBindingPeriod | BindingPeriodType.NoContractBindingPeriod;
      endsAt?: undefined;
      setByUserAt?: undefined;
    }
  | FixedContractBindingPeriod;

export interface FixedContractBindingPeriod {
  type: BindingPeriodType.FixedContractBindingPeriod;
  endsAt: LocalDate;
  setByUserAt: LocalDate;
}

export enum ContractState {
  Active = 'Active',
  Terminating = 'Terminating',
  Terminated = 'Terminated',
  /** @deprecated */
  Optimizing = 'Optimizing',
  /** @deprecated */
  Optimized = 'Optimized',
  /** @deprecated */
  Negotiating = 'Negotiating',
}

export interface Contract {
  id: ContractId;
  service: SubscriptionService;
  contractBindingPeriod: ContractBindingPeriod;
  /** @deprecated */
  optimizable: boolean;
  state: ContractState;
  terminable: boolean;
  // FIXME: pauseable property is a prop that doesn't exist on the model in backend, possible bugs caused by this?
  pausable: boolean;
  saveDeskEnabled: boolean;
  changePlanEnabled: boolean;
  changeDataplanRequestDate?: string;
  cancellationInfo?: CancellationInfo;
  pauseInfo?: PauseInfo;
  saveDeskInfo?: SaveDeskInfo;
  optimizationInfo?: OptimizationInfo;
  lastCompletedAction?: ActionType;
  terminatedAt?: OffsetDateTime;
  contractInformation?: ContractInformation;
  terminationFormData?: {
    answers: any[];
    desiredTerminationAt?: string;
  };
  pauseFormData?: {
    pauseMethod: PauseMethod;
    formSubmitted: boolean;
    answers: any;
  };
  pausedAt?: OffsetDateTime;
  cancellationMethod: { type: CancellationMethodType };
}

export interface ContractInformation {
  type: ContractInformationType;
}

enum ContractInformationType {
  MobileContractInformation = 'MobileContractInformation',
  ElectricityContractInformation = 'ElectricityContractInformation',
  BroadbandContractInformation = 'BroadbandContractInformation',
}

export interface BindingTimeContract extends Contract {
  contractBindingPeriod: FixedContractBindingPeriod;
}

export interface SubscriptionSupplier {
  id: SupplierId;
  name: string;
  logoUrl: string;
  singleServiceSupplier?: boolean;
  verified?: boolean;
}
export interface SubscriptionService {
  id: ServiceId;
  name: string;
  category: ServiceCategory;
  terminationInstructions: any;
  terminationQuestions: Question[];
}

export interface ServiceCategory {
  name: CategoryName;
  text: string;
}

/** @deprecated */
export interface OptimizationInfo {
  orderState: OrderState;
  orderSucceeded: boolean;
  newSupplierName: string;
  portingDate?: string;
  startingDate?: string;
  newProductName: string;
  waitingForTerminationInformation: boolean;
  rejectedReason?: RejectedReason;
  bindingTimeEndsAt?: string;
  message?: string;
  userRating?: number;
  orderId: string;
  orderRequestDate: string;
}

export interface CancellationInfo {
  cancellationId: string;
  cancellationRequestDate: string;
  cancelledAt?: string;
  cancellationReminderSentDate?: string;
  userRating?: number;
  messageToUser?: string;
  actionOutcome?: CancellationOutcome;
  yearlySavings?: AmountWithCurrency;
  bindingPeriodEndsAt?: string;
  lastPaymentAt?: string;
}

export interface SaveDeskInfo {
  saveDeskId?: string;
  providerId: string;
  providerName: string;
  sentOffer: boolean;
  userAcceptedOfferAt?: string;
  requestedAt: string;
}

export type SubscriptionPauseId = string;

export interface PauseInfo {
  id: SubscriptionPauseId;
  pausedFrom: OffsetDateTime;
  pausedUntil?: OffsetDateTime;
  actionOutcome: PauseOutcome;
  userRating?: number;
}

export enum ResumeMethod {
  Immediate = 'Immediate',
  FromNextBillingCycle = 'FromNextBillingCycle',
}

export interface SubscriptionResume {
  id: string;
  userId: UserId;
  serviceProviderId: SupplierId;
  serviceProviderName: string;
  serviceId: ServiceId;
  serviceName: string;
  resumedFrom: OffsetDateTime;
  externalSubscriptionId: string;
  createdAt: OffsetDateTime;
  nextBilling: NextBilling;
}

export interface NextBilling {
  date: OffsetDateTime;
  cost: AmountWithCurrency;
}

export enum SubscriptionSource {
  BankDiscovery = 'bank-discovery',
  Manual = 'manual',
  Converted = 'bank-discovery-override',
  Api = 'api',
}

export interface Subscription {
  id: SubscriptionId;
  createdAt: ZonedDateTime;
  supplier: SubscriptionSupplier;
  cost: AmountWithCurrency;
  paymentInterval: PaymentInterval;
  source: SubscriptionSource;
  bankEvents?: BankEvent[];
  nextPaymentDate?: string;
  state: SubscriptionState;
  singleContractId?: ContractId;
  contracts: Contract[];
  providerId?: string;
  userMessage: UserMessage;
  paid?: boolean;
}

export enum FrontendSubscriptionType {
  LockedSubscription = 'LockedSubscription',
  TerminatedSubscription = 'TerminatedSubscription',
  OtherSubscription = 'OtherSubscription',
}

export enum SubscriptionState {
  MoreInformationNeeded = 'MoreInformationNeeded',
  Active = 'Active',
  OneContractTerminating = 'OneContractTerminating',
  MultipleContractsTerminating = 'MultipleContractsTerminating',
  MultipleTasksOngoing = 'MultipleTasksOngoing',
  Terminated = 'Terminated',
}

export interface UserMessage {
  id: string;
  userId: UserId;
  contractId: ContractId;
  createdAt: string;
  message: { type: string };
}

export interface PaymentInterval {
  amount: number;
  unit: IntervalUnit;
}

export type IntervalUnit = 'day' | 'week' | 'month' | 'year';

export interface BankEvent {
  date: string;
  description: string;
  cost: AmountWithCurrency;
}

export function isWithinBindingPeriod(contract: Contract): contract is BindingTimeContract {
  if (
    contract.contractBindingPeriod.type === BindingPeriodType.FixedContractBindingPeriod &&
    moment(contract.contractBindingPeriod.endsAt).isAfter(moment())
  ) {
    return true;
  } else {
    return false;
  }
}

export const hasTerminationInstructions = (contract?: Contract): boolean =>
  contract?.service.terminationInstructions ?? false;
