import { LocalizedMessage } from '@client/internationalization';
import type {
  MultipleChoiceQuestionAlternative,
  QuestionDetailsMap,
  QuestionId,
  SimpleQuestion,
} from '@client/models/cancellation-questions';
import type { Locale, Market } from '@client/modules/app-context/constants';
import { CancellationQuestionComponent } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/CancellationQuestion/index';
import { requiredQuestionLabel } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/logic';
import type { AddressInformation } from '@client/modules/cancellation/CancellationFormPage/models';
import { detailsByLocale } from '@client/utils/locale';
import useTheme from '@material-ui/core/styles/useTheme';
import { RHFormGroup } from '@minna-technologies/minna-ui/components/Form/RHFormGroup';
import { RHRadio } from '@minna-technologies/minna-ui/components/Form/RHRadio';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import get from 'lodash/get';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import * as React from 'react';

interface MultipleChoiceQuestionProps {
  locale: Locale;
  id: string;
  required: boolean;
  details: QuestionDetailsMap;
  formSubmitted: boolean;
  alternatives: MultipleChoiceQuestionAlternative[];
  answers: any[];
  market: Market;
  addressOnUser: AddressInformation;
  onSetAnswer(questionId: string, value: any): void;
  watchRecipientQuestion(questionId: QuestionId): any;
  watchNestedQuestion(questionId: QuestionId): any;
}

export function checkNestedQuestionAnswerForError(answer: any) {
  const nestedQuestionAlternativeId = get(answer, 'nestedQuestionAlternativeId', '');
  if (nestedQuestionAlternativeId === '') {
    return <LocalizedMessage id="nestedQuestionNoSelectedOptionError" />;
  } else {
    return undefined;
  }
}

export const MultipleChoiceQuestion: React.FunctionComponent<MultipleChoiceQuestionProps> = (
  {
    alternatives,
    details,
    required,
    id,
    onSetAnswer,
    formSubmitted,
    locale,
    answers,
    market,
    addressOnUser,
    watchRecipientQuestion,
    watchNestedQuestion,
  },
  { localizeMessage }
) => {
  const { colors } = useTheme();

  const renderNestedQuestions = (alternative: MultipleChoiceQuestionAlternative) => {
    const questions = alternative.nestedQuestions;
    const questionNodes = map(questions, (question) => {
      return (
        <CancellationQuestionComponent
          locale={locale}
          key={question.id}
          type={question.type}
          question={question as SimpleQuestion}
          onSetAnswer={onSetAnswer}
          formSubmitted={formSubmitted}
          answers={answers}
          isNested
          market={market}
          addressOnUser={addressOnUser}
          watchRecipientQuestion={watchRecipientQuestion}
          watchNestedQuestion={watchNestedQuestion}
        />
      );
    });
    if (questions && questions.length > 0) {
      return [
        <div key="question-container" style={{ margin: '-16px 0 0' }}>
          {questionNodes}
        </div>,
      ];
    } else {
      return undefined;
    }
  };

  const getAlternativeFromId = (alternativeId: QuestionId) => {
    return alternatives.find((obj) => {
      return obj.id === alternativeId;
    });
  };

  const selectedValue = watchNestedQuestion(id);

  const renderAlternatives = () => {
    const nestedAlternatives = alternatives.map((alternative: MultipleChoiceQuestionAlternative) => {
      const label = alternative.details[locale]?.label ?? '';

      return <RHRadio key={alternative.id} name={alternative.id} value={alternative.id} label={label} />;
    });

    return (
      <>
        <RHFormGroup
          name={'nestedQuestion' + id}
          validation={{ required: required ? localizeMessage('nestedQuestionNoSelectedOptionError') : false }}
        >
          {nestedAlternatives}
        </RHFormGroup>
        <div style={{ marginLeft: '-3rem', marginTop: '1rem' }}>
          {selectedValue
            ? renderNestedQuestions(getAlternativeFromId(selectedValue) as MultipleChoiceQuestionAlternative)
            : null}
        </div>
      </>
    );
  };

  const { text, description } = detailsByLocale(details, locale);
  const label = requiredQuestionLabel(text, required);

  return (
    <div>
      <Body>{label}</Body>
      <Body color={colors.textOn.surfaceSubdued}> {description} </Body>
      {renderAlternatives()}
    </div>
  );
};

MultipleChoiceQuestion.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
