import { Sentry } from '@client/assets/js/sentry';
import requireLoggedInUser from '@client/containers/requireLoggedInUser';
import { logOut } from '@client/ducks/user';
import { withFeatures } from '@client/feature-toggling';
import { logoutUser } from '@client/models/user';
import type { Locale } from '@client/modules/app-context/constants';
import { AppType } from '@client/modules/app-context/constants';
import * as urls from '@client/routes';
import { FEATURE_SIGN_OUT, TrackingPageName } from '@client/tracking/mixpanel-constants';
import { sendButtonClickedEvent } from '@client/tracking/mixpanel-tracking-events';
import { getIngBankContactUrl } from '@client/utils/support';
import type { AxiosError } from 'axios';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import type { AppContext } from '../app-context';
import { withAppContext } from '../app-context/react';
import { getFaqCategoryId, navigateToCategory, navigateToContactUs, open } from '../Zendesk/ducks/zendesk-actions';
import { MenuPage } from './component';
import { withDialogStateHandler } from '@client/components/component-helpers';
import { withRouter } from 'react-router-dom';
import { withLocalization } from '@client/internationalization';

const redirectUserToLogoutScreen = (appContext: AppContext, userLocale: Locale) => {
  if (appContext.features.logout && appContext.features.logout.logoutConfirmationFlow) {
    window.location.href = `${urls.signoutSuccessPage}?locale=${userLocale}`;
  } else {
    window.location.href = urls.landingPage;
  }
};

const mapDispatchToProps = (dispatch: any, { appContext }: { appContext: AppContext }) => ({
  onContactUsClick: () => {
    if (appContext.appType === AppType.ING) {
      window.open(getIngBankContactUrl(appContext.locale), '_blank', 'noopener noreferrer external nofollow');
    } else {
      dispatch(navigateToContactUs());
      dispatch(open(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale));
    }
  },
  onFAQClick: () => {
    const categoryId = getFaqCategoryId(appContext.market);
    if (categoryId) {
      dispatch(navigateToCategory(categoryId));
    }
    dispatch(open(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale));
  },
  onLogout: () => {
    const userLocale = appContext.locale;
    logoutUser()
      .then(() => {
        dispatch(logOut());
        sendButtonClickedEvent(FEATURE_SIGN_OUT, TrackingPageName.MENU, 'Confirm sign out');
        redirectUserToLogoutScreen(appContext, userLocale);
      })
      .catch((axioxError: AxiosError) => {
        if (axioxError.response?.status === 401) {
          redirectUserToLogoutScreen(appContext, userLocale);
        } else {
          Sentry.captureExceptionWithMessage(axioxError, 'Unable to log out');
        }
      });
  },
});

interface MenuPageContainerProps {
  currentLocation: string;
}

export const MenuPageContainer = compose<MenuPageContainerProps, any>(
  requireLoggedInUser,
  withFeatures,
  withAppContext,
  connect(null, mapDispatchToProps),
  mapProps(({ features, onFAQClick, onContactUsClick, onLogout, currentLocation }: any) => ({
    currentLocation: currentLocation,
    showFeedback: features.navigation && features.navigation.feedback,
    onFAQClick: features.helpCenter.enabled ? onFAQClick : undefined,
    onContactUsClick: features.helpCenter.enabled ? onContactUsClick : undefined,
    onLogout: features.logout && features.logout.enabled ? onLogout : undefined,
  })),
  withDialogStateHandler,
  withFeatures,
  withRouter,
  withLocalization('MenuPage')
  // @ts-ignore
)(MenuPage);
