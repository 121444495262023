import { CancellationReasonDialog } from '@client/modules/saveDesk/CancellationReasonDialog';
import { SaveDeskOfferDialog } from '@client/modules/saveDesk/SaveDeskOfferDialog';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import React from 'react';

interface SaveDeskDialogProps {
  selectorAskedForSaveDeskOffer: boolean;
  requestedSubscriptionToCancel: Subscription;
  open: boolean;
  goToCancelForm(): void;
  requestedContractToCancel: Contract;
  closeDialog(): void;
}

export const SaveDeskDialog: React.FunctionComponent<SaveDeskDialogProps> = ({
  selectorAskedForSaveDeskOffer,
  requestedSubscriptionToCancel,
  open,
  goToCancelForm,
  requestedContractToCancel,
  closeDialog,
}) => {
  return (
    <>
      {selectorAskedForSaveDeskOffer ? (
        <SaveDeskOfferDialog
          subscription={requestedSubscriptionToCancel}
          open={open}
          goToCancelForm={goToCancelForm}
          contract={requestedContractToCancel}
          onClose={closeDialog}
        />
      ) : (
        <CancellationReasonDialog
          subscription={requestedSubscriptionToCancel}
          open={open}
          onClose={closeDialog}
          goToCancelForm={goToCancelForm}
          contract={requestedContractToCancel}
        />
      )}
    </>
  );
};
