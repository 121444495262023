import Sentry from '@client/assets/js/sentry';
import { debouncePromise } from '@client/assets/js/utils/debounce-promise';
import type { CategoryName } from '@client/constants/categories';
// @ts-ignore
import { errorPageOnFailure, onEnter, redirect } from '@client/containers/container-helpers';
import requireLoggedInUser from '@client/containers/requireLoggedInUser';
import {
  pageLoadingFailure,
  selectorCost,
  selectorGoToOptimizeCategory,
  selectorLoadingFailed,
  selectorPaymentInterval,
  selectorSelectedSupplier,
  setCost,
  setPaymentInterval,
} from '@client/ducks/manual-add-supplier';
import type { SupplierWithServices } from '@client/models/supplier';
import type { PaymentInterval } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import {
  createdSubscriptionManuallyMixpanelEvent,
  viewedManualSubscriptionPricingPageMixpanelEvent,
} from '@client/tracking/mixpanel-events';
import { parseAmountWithDecimals } from '@client/utils/cost';
import get from 'lodash/get';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { Action, Dispatch } from 'redux';
import type { AppContext } from '../app-context';
import { withAppContext } from '../app-context/react';
import { createSubscription, createSubscriptionAndSupplier } from '../subscription';
import { ManualContractPricingPage } from './component';

interface ManualContractPricingPageProps {
  goToOptimizeCategory: CategoryName;
}

interface MapStateProps {
  supplier: SupplierWithServices;
  cost: number;
  selectedPaymentInterval: PaymentInterval;
  loadingFailed: boolean;
  goToOptimizeCategory: CategoryName;
}

const mapStateToProps = (state: any): MapStateProps => {
  const goToOptimizeCategory = selectorGoToOptimizeCategory(state);
  return {
    supplier: selectorSelectedSupplier(state),
    cost: selectorCost(state),
    selectedPaymentInterval: selectorPaymentInterval(state),
    loadingFailed: selectorLoadingFailed(state),
    goToOptimizeCategory,
  };
};

interface DispatchProps {
  onBackClick(): void;
  onChangeCost(cost: string): void;
  onChangePaymentInterval(interval: PaymentInterval): void;
  onSubmit(supplier: SupplierWithServices, amount: number, selectedPaymentInterval: PaymentInterval): Promise<void>;
}

const mapDispatchToProps = (
  dispatch: Dispatch<Action<string>>,
  { appContext, history }: { appContext: AppContext } & RouteComponentProps
): DispatchProps => ({
  onBackClick: () => {
    history.goBack();
  },
  onChangeCost: (cost: string) => {
    dispatch(setCost(parseAmountWithDecimals(cost)));
  },
  onChangePaymentInterval: (interval: PaymentInterval) => {
    dispatch(setPaymentInterval(interval));
  },
  onSubmit: debouncePromise(async (supplier, amount, selectedPaymentInterval) => {
    const pickedExistingSupplier = Boolean(supplier.id);

    const cost = { amount, currency: appContext.currency };
    const createSubscriptionPromise = pickedExistingSupplier
      ? createSubscription(supplier.id, cost, selectedPaymentInterval)
      : createSubscriptionAndSupplier(supplier.name, cost, selectedPaymentInterval);

    return createSubscriptionPromise
      .then((subscription) => {
        createdSubscriptionManuallyMixpanelEvent(subscription);
        const state = get(subscription, 'state');

        if (state === 'MoreInformationNeeded') {
          const subscriptionId = get(subscription, 'id');
          history.push(urls.selectServicesPage(subscriptionId));
        } else {
          history.push(urls.overviewPage);
        }
      })
      .catch((error) => {
        dispatch(pageLoadingFailure());
        Sentry.captureExceptionWithMessage(error, 'Failed to create subscription');
      });
  }),
});

const mergeProps = (
  mapProps: MapStateProps,
  dispatchProps: DispatchProps,
  ownProps: ManualContractPricingPageProps & { appContext: AppContext } & RouteComponentProps
): any => {
  return {
    ...ownProps,
    ...mapProps,
    ...dispatchProps,
    currency: ownProps.appContext.currency,
    isHelpCenterEnabled: ownProps.appContext.features.helpCenter.enabled ?? false,
    onSubmit: () => {
      dispatchProps.onSubmit(mapProps.supplier, mapProps.cost, mapProps.selectedPaymentInterval);
    },
  };
};

// TODO: Refactor this to use hooks where it is applicable
export const ManualSubscriptionPricingContainer = compose(
  withAppContext,
  requireLoggedInUser,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  onEnter(({ goToOptimizeCategory }: MapStateProps) => {
    viewedManualSubscriptionPricingPageMixpanelEvent(goToOptimizeCategory);
  }),
  redirect((p: MapStateProps) => !p.supplier, urls.manualAddSubscriptionPage),
  errorPageOnFailure((p: MapStateProps) => p.loadingFailed)
  // @ts-ignore
)(ManualContractPricingPage);
