import { LocalizedMessage } from '@client/internationalization';
import type { PersonName, User } from '@client/models/user-models';
import type { AppContext } from '@client/modules/app-context';
// @ts-ignore js import, remove this when the import is typed
import Form from '@client/modules/shared-components/form-validation/Form';
import { EmailField } from '@client/modules/shared-components/inputs/EmailField';
import { MobilePhoneNumberField } from '@client/modules/shared-components/inputs/MobilePhoneNumberField';
import { SelectField } from '@client/modules/shared-components/inputs/SelectField';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { blueTheme, white } from '@client/styles/colors';
import MenuItem from '@material-ui/core/MenuItem';
import get from 'lodash/get';
import { RaisedButton } from 'material-ui';
import Paper from 'material-ui/Paper';
import React, { useState } from 'react';
import { ZendeskSubHeader } from '../../../ZendeskSubHeader';
import styles from './styles.scss';

const timeSlots = [
  '9.00-9.30',
  '9.30-10.00',
  '10.00-10.30',
  '10.30-11.00',
  '11.00-11.30',
  '11.30-12.00',
  '13.00-13.30',
  '13.30-14.00',
  '14.00-14.30',
  '14.30-15.00',
  '15.00-15.30',
  '15.30-16.00',
];

interface ZendeskCallBackProps {
  user: User;
  onNavigateToCallBackSuccess: any;
  handleSubmit(): void;
  appContext: AppContext;
}

// eslint-disable-next-line
export const ZendeskCallBackComponent: React.FunctionComponent<ZendeskCallBackProps> = ({
  user,
  appContext,
  onNavigateToCallBackSuccess,
}) => {
  const email = get(user, 'emailAddress');
  const phoneNumber = get(user, 'phoneNumber');
  const [timeSlotInput, setTimeSlotInput] = useState('');
  const [emailInput, setEmailInput] = useState(email);
  const [phoneInput, setPhoneInput] = useState(phoneNumber);

  // Zendesk API requires name of type string
  const getUserNameString = () => {
    const userName: PersonName | string = get(user, 'name', '');

    // @ts-ignore
    return userName === '' ? '' : `${userName.first} ${userName.last}`;
  };

  const submitHandler = () => {
    const requestData = { emailInput, phoneInput, timeSlotInput };
    const userData = { name: getUserNameString(), market: appContext.market };
    if (emailInput && phoneInput && timeSlotInput) {
      onNavigateToCallBackSuccess(requestData, userData, appContext.features.helpCenter.zendeskBaseUrl);
    }
  };

  const isTimeSlotDisabled = (timeSlot: string) => {
    const dateNow = new Date();
    const dayNumber = dateNow.getDay();
    const hourNow: number = dateNow.getHours();
    const minutesNow: number = dateNow.getMinutes();
    const timeStartArray: number[] = timeSlot
      .split('-')[0]
      .split('.')
      .map((i) => parseInt(i, 10));
    // Disable every slot on Sunday or Saturday
    if (dayNumber === 0 || dayNumber === 6) {
      return true;
      // Disable 3 timeslots on Monday
    } else if (
      dayNumber === 1 &&
      (timeSlot === '9.00-9.30' || timeSlot === '9.30-10.00' || timeSlot === '10.00-10.30')
    ) {
      return true;
      // disable past timeslots
    } else if (timeStartArray[0] < hourNow) {
      return true;
      // if it is less than half past current hour, do not disable slot starting at half past current hour
    } else if (timeStartArray[0] === hourNow && timeStartArray[1] < minutesNow) {
      return true;
    } else {
      return false;
    }
  };

  const timeSlotItems = timeSlots.map((timeSlotItem) => {
    return (
      <MenuItem
        value={timeSlotItem}
        key={timeSlotItem}
        data-test={`time-slot-item`}
        className={isTimeSlotDisabled(timeSlotItem) ? 'disabled' : ''}
        disabled={isTimeSlotDisabled(timeSlotItem)}
      >
        {timeSlotItem}
      </MenuItem>
    );
  });

  return (
    <Paper className={styles.paper}>
      <div className={styles.container}>
        <ZendeskSubHeader title={<LocalizedMessage id="callBackTitle" />} />
        <Caption className={styles['force-margin-top']}>
          <LocalizedMessage id="contactUsSectionCallBackOpenHoursSubtitle" />
        </Caption>
        <Body>
          <LocalizedMessage id="contactUsSectionCallBackOpenHours" />
        </Body>
        <Body className={styles['force-margin-top']}>
          <LocalizedMessage id="callBackDescription" />
        </Body>
        <Form className={`${styles['force-margin-top']} ${styles['form-content']}`} onValidSubmit={submitHandler}>
          <SelectField
            label={<LocalizedMessage id="callBackRequestSelectTimeLabel" />}
            data-test="time-slot-select"
            name="timeSlotSelect"
            fullWidth
            requiredErrorText={<LocalizedMessage id="callBackRequestSelectTimeErrorText" />}
            onChange={(event: React.ChangeEvent<any>) => setTimeSlotInput(event.target.value)}
          >
            {timeSlotItems}
          </SelectField>
          <MobilePhoneNumberField
            inputProps={{ 'data-test': 'phone-number' }}
            fullWidth
            defaultValue={phoneNumber}
            name="phoneInput"
            required
            onChange={(event) => setPhoneInput(event.target.value)}
          />
          <EmailField
            inputProps={{
              'data-test': 'email-address',
            }}
            fullWidth
            defaultValue={email}
            name="emailInput"
            required
            onChange={(event) => setEmailInput(event.target.value)}
          />
          <Body>
            <LocalizedMessage id="callBackHelperTest" />
          </Body>
          <RaisedButton
            data-test="submit-button"
            className={styles['right-button']}
            label={<LocalizedMessage id="callBackRequestSubmitButton" />}
            backgroundColor={blueTheme}
            labelColor={white}
            type="submit"
          />
        </Form>
      </div>
    </Paper>
  );
};
