import { SubscriptionActionType } from '@client/models/subscription';

export function getActionFromQuery(query: URLSearchParams): SubscriptionActionType {
  //TODO check feature toggles and error if not supported?
  switch (query.get('subscriptionAction')) {
    case SubscriptionActionType.Cancel:
      return SubscriptionActionType.Cancel;
    case SubscriptionActionType.ChangePayment:
      return SubscriptionActionType.ChangePayment;
    default:
      return SubscriptionActionType.Cancel;
  }
}

export function getLocalisationScopeFromAction(action: SubscriptionActionType) {
  switch (action) {
    case SubscriptionActionType.ChangePayment:
      return 'genericActions/SubscriptionSearchPage/ChangePayment';
    case SubscriptionActionType.Cancel:
    default:
      return 'genericActions/SubscriptionSearchPage/Cancel';
  }
}
