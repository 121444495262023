import { LocalizedMessage } from '@client/internationalization';
import { MessageToUser } from '@client/modules/cancellation/CancellationOutcome/components/MessageToUser';
import { TryCancellationAgainButton } from '@client/modules/cancellation/CancellationOutcome/components/TryCancellationAgainButton/component';
import type { ContractId, SubscriptionId } from '@client/modules/subscription/types';
import { makeStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import type { FC } from 'react';
import React from 'react';
import type { TrackingPageName } from '@client/tracking/mixpanel-constants';

const useStyles = makeStyles({
  content: {
    margin: '8px 16px',
  },
});

interface Props {
  messageFromProvider?: string;
  serviceProviderName: string;
  pageName: TrackingPageName;
  subscriptionId?: SubscriptionId;
  contractId?: ContractId;
  onTryAgainClick?(): void;
}

export const NoSuchAccountContent: FC<Props> = ({
  onTryAgainClick,
  messageFromProvider,
  serviceProviderName,
  contractId,
  subscriptionId,
  pageName,
}) => {
  const classes = useStyles();
  const { colors } = useTheme();

  return (
    <>
      <div className={classes.content}>
        <Title color={colors.textOn.surfaceSubdued} style={{ marginBottom: '4px' }}>
          <LocalizedMessage id="whatCanYouDo" />
        </Title>
        <Body>
          <LocalizedMessage id="noSuchAccountDescription" />
        </Body>
      </div>
      {messageFromProvider && messageFromProvider.length !== 0 && (
        <MessageToUser messageToUser={messageFromProvider} serviceProviderName={serviceProviderName} />
      )}
      {subscriptionId && contractId && (
        <div className={classes.content}>
          <TryCancellationAgainButton
            pageName={pageName}
            serviceProviderName={serviceProviderName}
            action={onTryAgainClick}
            subscriptionId={subscriptionId}
            contractId={contractId}
          />
        </div>
      )}
    </>
  );
};
