import type { OrderState } from '@client/models/order';
import { successfulOrderStates } from '@client/models/order';
import type { CategoryName } from '@client/modules/InsightsPage/models';
import { getMixpanel } from '@client/tracking/mixpanel';
import {
  EventNames,
  FEATURE_INDEPENDENT_ORDER,
  FEATURE_OVERVIEW,
  TrackingPageName,
} from '@client/tracking/mixpanel-constants';

export function rateCompletedOrderMixpanelEvent(
  pageName: string,
  newSupplierName: string,
  category: string,
  rating: number,
  inputValue: string,
  oldSupplierName?: string,
  state?: OrderState
) {
  rateCompletedIndependentOrderMixpanelEvent(pageName, newSupplierName, category, rating, inputValue, state);
}

export function rateCompletedIndependentOrderMixpanelEvent(
  pageName: string,
  supplierName: string,
  category: string,
  rating: number,
  inputValue: string,
  state?: OrderState
) {
  getMixpanel().track(EventNames.RATED_EXPERIENCE, {
    Feature: FEATURE_INDEPENDENT_ORDER,
    Category: category,
    'Supplier name': supplierName,
    Page: pageName,
    'Feedback type': 'Completion',
    Value: inputValue,
    'mp_.rating': rating,
    'Action outcome': state,
    'Action successful': state && successfulOrderStates.includes(state),
  });
}

export function viewPageOrderedSubscriptionMixpanelEvent(
  newServiceProviderName: string,
  categoryName: CategoryName[],
  oldServiceProviderName?: string
) {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: FEATURE_OVERVIEW,
    Page: TrackingPageName.ORDERED_SUBSCRIPTION,
    Category: categoryName,
    'New supplier name': newServiceProviderName,
    'Old supplier name': oldServiceProviderName,
  });
}
