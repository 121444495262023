import { useLocalization } from '@client/internationalization';
import type { PastAction } from '@client/models/cancellation';
import type { Locale } from '@client/modules/app-context/constants';
import { selectorLocale } from '@client/modules/app-context/duck';
import {
  CancellationOutcome,
  CancellationState,
  successfulCancellationOutcomes,
} from '@client/modules/cancellation/models';
import { ServiceNameWithIcon } from '@client/shared-components/ServiceNameWithIcon';
import useTheme from '@material-ui/core/styles/useTheme';
import { makeStyles } from '@material-ui/styles';
import { Badge } from '@minna-technologies/minna-ui/components/Badge';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { CheckCircleIcon } from '@minna-technologies/minna-ui/icons/CheckCircle';
import { DeleteIcon } from '@minna-technologies/minna-ui/icons/Delete';
import { InfoIcon } from '@minna-technologies/minna-ui/icons/Info';
import React from 'react';
import { useSelector } from 'react-redux';

interface PastActionCardHeaderProps {
  pastAction: PastAction;
}

const useStyles = makeStyles({
  aboveDividerDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  merchantName: {
    marginBottom: '4px',
  },
});

export const PastActionCardHeader = (props: PastActionCardHeaderProps) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const locale: Locale = useSelector(selectorLocale);

  const { localizeMessage } = useLocalization('subscriptionHub/subscriptionHub');

  const getStatusBadge = (pastAction: PastAction) => {
    if (pastAction.status !== CancellationState.Finished) {
      return (
        <Badge
          color={colors.textOn.info}
          icon={<InfoIcon bold />}
          label={localizeMessage('cancellationOngoing')}
          backgroundColor={colors.base.info}
        />
      );
    } else if (pastAction.outcome && successfulCancellationOutcomes.includes(pastAction.outcome)) {
      return (
        <Badge
          color={colors.textOn.positive}
          label={localizeMessage('cancellationConfirmed')}
          icon={<CheckCircleIcon bold />}
          backgroundColor={colors.base.positive}
        />
      );
    } else if (pastAction.outcome && pastAction.outcome == CancellationOutcome.ServiceProviderContactedUser) {
      return (
        <Badge
          color={colors.textOn.danger}
          label={localizeMessage('cancellationClosed')}
          icon={<DeleteIcon bold />}
          backgroundColor={colors.base.danger}
        />
      );
    } else {
      return (
        <Badge
          color={colors.textOn.danger}
          label={localizeMessage('cancellationFailed')}
          backgroundColor={colors.base.danger}
          icon={<DeleteIcon bold />}
        />
      );
    }
  };

  return (
    <div className={classes.aboveDividerDiv}>
      <div>
        <Body className={classes.merchantName} variant="bold">
          {props.pastAction.merchantName}
        </Body>
        <ServiceNameWithIcon
          categoryName={props.pastAction.category.name.name}
          serviceName={props.pastAction.serviceName}
          localizedCategoryName={props.pastAction.category.localizedNames[locale]}
        />
      </div>
      {getStatusBadge(props.pastAction)}
    </div>
  );
};
