import ResumeButtonIcon from '@client/assets/images/action-resume.svg';
import PauseButtonIcon from '@client/assets/images/pause-icon.svg';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { SubscriptionActionType } from '@client/models/subscription';
import { makeStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { CardLink } from '@minna-technologies/minna-ui/components/CardLink';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import React from 'react';
import { CancelCrossIcon } from '@minna-technologies/minna-ui/icons/Cancel';
import { ChangePlanIcon } from '@minna-technologies/minna-ui/icons/ChangePlan';

const useStyles = makeStyles((theme) => ({
  actionButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  iconContainer: {
    marginRight: theme.spacing(2),
  },

  textContainer: {
    flexGrow: 1,
    marginBottom: '2px',
  },

  reactIconCircleBackground: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.action.primary,
  },
  reactIcon: {
    color: theme.colors.textOn.badge,
  },
}));

interface Props {
  actionType: SubscriptionActionType;
  'data-test'?: string;
  onClick(): void;
}

interface RectangularActionButtonComponents {
  Icon: string | React.ElementType;
  label: string;
  caption: string;
}

const Component: React.FunctionComponent<Props> = ({ actionType, onClick, 'data-test': dataTest }: Props) => {
  const { colors } = useTheme();
  const classes = useStyles();

  function getIconComponents(): RectangularActionButtonComponents | undefined {
    switch (actionType) {
      case SubscriptionActionType.Cancel:
        return {
          Icon: CancelCrossIcon,
          label: 'cancel',
          caption: 'cancelCaptionText',
        };
      case SubscriptionActionType.ChangePlan:
        return {
          Icon: ChangePlanIcon,
          label: 'changePlan',
          caption: 'changePlanCaptionText',
        };
      case SubscriptionActionType.Pause:
        return {
          Icon: PauseButtonIcon,
          label: 'pause',
          caption: 'pauseCaptionText',
        };
      case SubscriptionActionType.Resume:
        return {
          Icon: ResumeButtonIcon,
          label: 'resume',
          caption: 'resumeCaptionText',
        };
      default:
        return undefined;
    }
  }

  const { Icon, label, caption } = getIconComponents() as RectangularActionButtonComponents;

  return (
    <CardLink onClick={onClick} data-test={dataTest} style={{ margin: '0.5rem 0 1rem' }}>
      <div className={classes.actionButtonContainer}>
        <div className={classes.iconContainer}>
          {typeof Icon === 'string' ? (
            <img src={Icon} alt="icon" />
          ) : (
            <div className={classes.reactIconCircleBackground}>
              <Icon className={classes.reactIcon} alt="icon" />
            </div>
          )}
        </div>

        <div className={classes.textContainer}>
          <Title>
            <LocalizedMessage id={label} />
          </Title>
          <Body color={colors.base.border}>
            <LocalizedMessage id={caption} />
          </Body>
        </div>
      </div>
    </CardLink>
  );
};

export const RectangularActionButton = withLocalization('BadgeCtas')(Component);
