import { onEnter } from '@client/containers/container-helpers/onEnter';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { CancellationInstructionsPageComponent } from '@client/modules/cancellation/CancellationInstructionsPage/component';
import type { Contract, ContractId, Subscription, SubscriptionId } from '@client/modules/subscription/types';
import { viewedCancellationInstructionsPageMixpanelEvent } from '@client/tracking/mixpanel-events';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
// @ts-ignore js import, remove this when the import is typed
import { selectorContract, selectorSubscription } from '../../subscription';

interface ContainerProps {
  contractId: ContractId;
  subscriptionId: SubscriptionId;
}

interface StateProps {
  contract: Contract;
  subscription: Subscription;
}

const mapStateToProps = (state: any, { contractId }: ContainerProps) => ({
  features: selectorFeatures(state),
  contract: selectorContract(state, contractId),
  subscription: selectorSubscription(state),
});

type CancellationInstructionsPageContainerProps = ContainerProps & StateProps;

export const CancellationInstructionsPageContainer = compose<any, ContainerProps>(
  connect(mapStateToProps),
  onEnter(({ subscription, contract }: CancellationInstructionsPageContainerProps) => {
    viewedCancellationInstructionsPageMixpanelEvent(subscription, contract);
  }),
  withProps(({ contract, subscription }: CancellationInstructionsPageContainerProps) => ({
    supplierName: subscription.supplier.name,
    terminationInstructions: contract.service.terminationInstructions,
  }))
)(CancellationInstructionsPageComponent);
