import { withAppContext } from '@client/modules/app-context/react';
import type { Dispatch } from '@client/utils/redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// @ts-ignore js import, remove this when the import is typed
import { navigateToContactUs } from '../../../../ducks';
// @ts-ignore js import, remove this when the import is typed
import { selectorZendeskCallBackRequestData } from '../../../../ducks/zendesk';
import { ZendeskCallBackSuccess } from './CallBackSuccess';

const mapStateToProps = (state: any) => ({
  callBackRequestData: selectorZendeskCallBackRequestData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({ onCloseButton: async () => dispatch(navigateToContactUs()) });

export const ZendeskCallBackSuccessContainer = compose<any, any>(
  withAppContext,
  connect(mapStateToProps, mapDispatchToProps)
)(ZendeskCallBackSuccess);
