import type { CategoryName } from '@client/constants/categories';
import { FITNESS } from '@client/constants/categories';
import type { LocalizeCostWithCurrency } from '@client/internationalization';
import type { Currency } from '@client/models/subscription';

export const isShallowCategory = (categoryName: CategoryName) => {
  const shallowCategories: CategoryName[] = [
    FITNESS,
    'charity',
    'game',
    'membership',
    'grocery-bag',
    'mobile-app',
    'news',
    'subscription-box',
    'tv',
    'streaming-media',
  ];

  return shallowCategories.includes(categoryName);
};

export const priceWithCurrency = (
  amount: number,
  localizeCostWithCurrency: LocalizeCostWithCurrency,
  currency: Currency
) => {
  return localizeCostWithCurrency({ amount: amount, currency: currency });
};
