import {
  cancelOnboardingPSD2PrimerEvent,
  continueOnboardingPSD2PrimerEvent,
  sendViewedPSD2PrimerEvent,
} from './mixpanel';
import { Sentry } from '@client/assets/js/sentry';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { acceptPrivacyPolicyAndTermsOfUse } from '@client/models/user';
import { selectorAppType } from '@client/modules/app-context/duck';
import { ConsentPageWrapper } from '@client/modules/onboarding/OnboardingPsd2/consent/ConsentPageWrapper';
import { psd2AuthenticationWithClient } from '@client/modules/onboarding/OnboardingPsd2/consent/psd2AuthenticationWithClient';
import { subscriptionHub } from '@client/routes';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { HistoryIcon } from '@minna-technologies/minna-ui/icons/History';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debouncePromise } from '@client/assets/js/utils/debounce-promise';

const useStyles = makeStyles(() => ({
  introductionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '16px',
    paddingBottom: '8px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '16px',
    marginTop: '16px',
    flexShrink: 0,
    width: '100%',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  iconWrapper: {
    flex: 0,
    marginRight: '16px',
    alignSelf: 'flex-start',
    boxSizing: 'content-box',
    height: '24px',
    width: '24px',
  },
  contentWrapper: {
    textAlign: 'left',
    paddingLeft: '40px',
  },
  bottomContainer: {
    padding: '8px 8px 56px 8px',
  },
  button: {
    margin: '8px 0',
  },
}));

const OpBankConsentPageInner = () => {
  const { colors } = useTheme();
  const appType = useSelector(selectorAppType);
  const styles = useStyles();

  const history = useHistory();

  const onContinueToConsentClick = debouncePromise(async () => {
    try {
      await acceptPrivacyPolicyAndTermsOfUse();
    } catch (error) {
      Sentry.captureExceptionWithMessage(error, `Failed to accept privacy policy and terms of use`);
      throw error;
    }
    continueOnboardingPSD2PrimerEvent();
    window.location = await psd2AuthenticationWithClient();
  });

  const onNotNowClick = () => {
    cancelOnboardingPSD2PrimerEvent();
    history.replace(subscriptionHub);
  };

  return (
    <ConsentPageWrapper appType={appType} trackViewedPage={() => sendViewedPSD2PrimerEvent()}>
      <div>
        <div className={styles.introductionContainer}>
          <Headline2 data-test={'headline'}>
            <LocalizedMessage id="headline" />
          </Headline2>
        </div>
        <div>
          <Body>
            <LocalizedMessage id="subtextReason" />
          </Body>
          <br />
          <Body>
            <LocalizedMessage id="subtextNextStep" />
          </Body>
        </div>
        <div>
          <Card className={styles.card} data-test={'card-two'}>
            <div className={styles.cardHeader}>
              <div className={styles.iconWrapper}>
                <HistoryIcon nativeColor={colors.action.primary} />
              </div>
              <Body variant="bold">
                <LocalizedMessage id="transactionHistoryHeadline" />
              </Body>
            </div>
            <div className={styles.contentWrapper}>
              <Caption style={{ color: colors.textOn.surfaceSubdued }}>
                <LocalizedMessage id="transactionHistoryDescription1" />
              </Caption>
            </div>
          </Card>
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.button}>
          <PrimaryButton
            fullWidth
            label={<LocalizedMessage id="selectAccountsLabel" />}
            onClick={onContinueToConsentClick}
            data-test={'continue-button'}
          />
        </div>
        <div className={styles.button}>
          <SecondaryButton
            fullWidth
            label={<LocalizedMessage id="notNowLabel" />}
            onClick={onNotNowClick}
            data-test={'cancel-button'}
          />
        </div>
      </div>
    </ConsentPageWrapper>
  );
};

export const OpBankConsentPageV2 = withLocalization('onboarding-psd2/ConsentPage/OpBankConsentPageV2')(
  OpBankConsentPageInner
);
