import type { Fetchable } from '@client/models/Fetchable';
import { fetchableHandle, FetchableNotStarted } from '@client/models/Fetchable';
import { LoadingState } from '@client/models/LoadingState';
import type { ProfilingSetting, UpdatingStates } from '@client/models/user-models';
import type { Actions } from '@client/modules/settings/ProfilingSettingsPage/actions';
import { ActionKeys } from '@client/modules/settings/ProfilingSettingsPage/actions';
import map from 'lodash/map';

export interface ProfilingSettingsState {
  profilingSettings: Fetchable<ProfilingSetting[]>;
  updatingState?: UpdatingStates;
}

const initialState: ProfilingSettingsState = {
  profilingSettings: FetchableNotStarted,
  updatingState: undefined,
};

export function reducer(state: ProfilingSettingsState = initialState, action: Actions): ProfilingSettingsState {
  switch (action.type) {
    case ActionKeys.FETCH_PROFILING_SETTINGS:
      return fetchableHandle(state, action, (profilingSettings) => ({
        ...state,
        profilingSettings: profilingSettings,
      }));
    case ActionKeys.UPDATE_PROFILING_SETTINGS: {
      const updatedProfilingSettings = updateProfilingSettings(
        state.profilingSettings,
        action.payload.profilingSetting
      );

      return { ...state, profilingSettings: updatedProfilingSettings, updatingState: action.payload.updatingStatus };
    }
    case ActionKeys.UPDATE_PROFILING_SETTINGS_COMPLETED:
      return { ...state, updatingState: action.payload };
    case ActionKeys.UPDATE_PROFILING_SETTINGS_FAILED: {
      const resetProfilingSettings = updateProfilingSettings(state.profilingSettings, action.payload.profilingSetting);

      return { ...state, profilingSettings: resetProfilingSettings, updatingState: action.payload.updatingStatus };
    }
    case ActionKeys.CLEAR_PROFILING_SETTINGS_FAILED_STATUS:
      return { ...state, updatingState: undefined };
    default:
      return state;
  }
}

const updateProfilingSettings = (
  profilingSettings: Fetchable<ProfilingSetting[]>,
  updatedSetting: ProfilingSetting
): Fetchable<ProfilingSetting[]> => {
  if (profilingSettings.type === LoadingState.Success) {
    const updatedSettings = map(profilingSettings.data, (setting) =>
      setting.setting === updatedSetting.setting ? updatedSetting : setting
    );

    return {
      type: profilingSettings.type,
      data: updatedSettings,
    };
  }

  return profilingSettings;
};
