export enum PauseMethodType {
  PauseDuration = 'PauseDuration',
}

export enum PauseDurationUnit {
  Month = 'Month',
}

export interface PauseMethod {
  type: PauseMethodType;
}

export interface PauseDuration extends PauseMethod {
  type: PauseMethodType.PauseDuration;
  amount: number;
  unit: PauseDurationUnit;
}

export enum PauseOutcome {
  Paused = 'Paused',
  Pausing = 'Pausing',
}

export enum PauseError {
  ThirdPartyUserNotFound = 'ThirdPartyUserNotFound',
  NoPausableServicesFoundException = 'NoPausableServicesFoundException',
  InvalidContentTypeException = 'InvalidContentTypeException',
}
