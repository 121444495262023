import get from 'lodash/get';

const NAMESPACE = 'DELETE_USER';
const SET_REASON = `${NAMESPACE}/SET_REASON`;
const SET_OTHER_REASON = `${NAMESPACE}/SET_OTHER_REASON`;

// eslint-disable-next-line import/no-default-export
export default function reducer(state = {}, action: any) {
  switch (action.type) {
    case SET_REASON: {
      return {
        ...state,
        selectedReason: action.reason,
      };
    }
    case SET_OTHER_REASON: {
      return {
        ...state,
        otherReasonText: action.otherReasonText,
      };
    }
    default:
      return state;
  }
}

export const setReason = (reason: any) => ({
  type: SET_REASON,
  reason: reason,
});

export const setOtherReasonText = (otherReasonText: string) => ({
  type: SET_OTHER_REASON,
  otherReasonText: otherReasonText,
});

export const selectorIsStandaloneAndSwedbankUser = (state: any) =>
  get(state, 'user.data.isStandaloneAndSwedbankUser', false);

export const selectorSelectedReason = (state: any) => get(state.deleteUser, 'selectedReason');

export const selectorReasonText = (state: any) => {
  const selectedReason = selectorSelectedReason(state);

  let reasonText;
  if (get(selectedReason, 'userInput', false)) {
    reasonText = get(state.deleteUser, 'otherReasonText');
  } else {
    reasonText = get(selectedReason, 'value');
  }

  return reasonText;
};
