import { LocalizedMessage, withLocalization } from '@client/internationalization';
// @ts-ignore
import Form from '@client/modules/shared-components/form-validation/Form';
import { RadioButton, RadioGroup } from '@client/modules/shared-components/inputs/RadioButton';
import type { FunctionComponent } from 'react';
import React from 'react';

export const NOT_A_SUBSCRIPTION = 'whatsWrongNotASubscription';
export const INVALID_COST = 'whatsWrongInvalidCost';

interface WhatsWrongFormProps {
  step: string;
  onChange(step: string): void;
}

const WhatsWrongFormComponent: FunctionComponent<WhatsWrongFormProps> = (props) => {
  return (
    <Form>
      <RadioGroup name={'whats_wrong'}>
        {[NOT_A_SUBSCRIPTION, INVALID_COST].map((step, index) => (
          <RadioButton
            key={index}
            onChange={() => props.onChange(step)}
            value={step}
            label={
              <span style={{ fontSize: '1rem' }}>
                <LocalizedMessage id={step} />
              </span>
            }
            checked={props.step === step}
          />
        ))}
      </RadioGroup>
    </Form>
  );
};

export const WhatsWrongForm = withLocalization('subscription/WhatsWrongDialog')(WhatsWrongFormComponent);
