import type { Contract, Subscription } from '@client/modules/subscription/types';
import { getMixpanel } from '@client/tracking/mixpanel';
import type { TrackingFeature, TrackingButtonName } from '@client/tracking/mixpanel-constants';
import {
  TrackingButtonLocation,
  EventNames,
  FEATURE_OVERVIEW,
  TrackingPageName,
} from '@client/tracking/mixpanel-constants';
import { sendButtonClickedEvent } from '@client/tracking/mixpanel-tracking-events';
import type { AppType, Market } from '@client/modules/app-context/constants';

export function viewSubscriptionPageMixpanelEvent(subscription: Subscription, page: string, extra = {}) {
  getMixpanel().track('Viewed subscription', {
    Feature: FEATURE_OVERVIEW,
    Page: page,
    'Supplier name': subscription.supplier.name ?? '',
    'Supplier id': subscription.supplier.id,
    ...extra,
  });
}

export function viewPageMixpanelEvent(subscription: Subscription, page: string, extra = {}) {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: FEATURE_OVERVIEW,
    Page: page,
    'Supplier name': subscription.supplier.name ?? '',
    'Supplier id': subscription.supplier.id,
    ...extra,
  });
}

export function buttonClickedSubscriptionPageMixpanelEvent(
  subscription: Subscription,
  button: string,
  page: string,
  extra = {}
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_OVERVIEW,
    Page: page,
    Button: button,
    'Supplier name': subscription.supplier.name ?? '',
    'Supplier id': subscription.supplier.id,
    ...extra,
  });
}

export function updateBindingTimeContractDetailsMixpanelEvent(
  subscription: Subscription,
  pageName: string,
  bindingTime: string,
  contract?: Contract
) {
  getMixpanel().track('Updated binding time', {
    Feature: FEATURE_OVERVIEW,
    Page: pageName,
    Bindingtime: bindingTime,
    Category: contract?.service.category.name,
    'Supplier name': subscription.supplier.name,
    'Supplier id': subscription.supplier.id,
  });
}

// @deprecated `clickedSubscriptionActionMixpanelEvent` should be used instead
export function clickedContractActionMixpanelEventDeprecated(
  contract: Contract,
  subscription: Subscription,
  button: string,
  pageName?: string,
  location?: TrackingButtonLocation
) {
  sendButtonClickedEvent(FEATURE_OVERVIEW, pageName ?? TrackingPageName.CONTRACT_DETAILS, button, {
    'Supplier name': subscription.supplier.name,
    'Supplier ID': subscription.supplier.id,
    Category: contract.service.category.name,
    Location: location ?? TrackingButtonLocation.TOP,
    'Monthly Cost': subscription.cost.amount,
  });
}

export function clickedSubscriptionActionMixpanelEvent(
  feature: TrackingFeature,
  contract: Contract,
  subscription: Subscription,
  button: TrackingButtonName,
  market: Market,
  platform: AppType,
  pageName: TrackingPageName
) {
  sendButtonClickedEvent(feature, pageName, button, {
    'Service name': contract.service.name,
    'Supplier name': subscription.supplier.name,
    Market: market,
    Platform: platform,
  });
}
