import type { LocalizeCostWithCurrency, WithLocalizationContextType } from '@client/internationalization';
import { withLocalization } from '@client/internationalization';
import { AppIntegration } from '@client/modules/app-integration';
import { CallsLimitation } from '@client/modules/switch/mobile/models';
import type {
  MobileOrderedProductInformation,
  OrderedMobileProduct,
} from '@client/modules/switch/ordered-subscription/models';
import type {
  LabelValue,
  ProductInfo,
} from '@client/modules/switch/ordered-subscription/OrderedSubscriptionDetails/components/OrderedSubscriptionInfoCard';
import { OrderedSubscriptionInfoCard } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionDetails/components/OrderedSubscriptionInfoCard';
import type { WithStyles } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import compact from 'lodash/compact';
import PropTypes from 'prop-types';
import React from 'react';

const styles = createStyles({
  costRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  alignHorizontally: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

interface OrderedMobileProductInfoInnerProps {
  product: OrderedMobileProduct;
  goToTermsPage(): void;
}

const OrderedMobileProductInfoInner: React.FunctionComponent<
  OrderedMobileProductInfoInnerProps & WithStyles<typeof styles>
> = ({ product, goToTermsPage }, { localizeCostWithCurrency, localizeMessage }: WithLocalizationContextType) => {
  const onGoToTermsAndConditionsClick = () => {
    if (product.termsAndConditions?.length !== 0) {
      return goToTermsPage;
    } else if (product.supplierInformation.termsUrl) {
      return () => AppIntegration.openExternalWebPage(product.supplierInformation.termsUrl ?? '');
    } else {
      return undefined;
    }
  };

  return (
    <OrderedSubscriptionInfoCard
      onGoToTermsAndConditionsClick={onGoToTermsAndConditionsClick()}
      monthlyCost={product.information.monthlyCost}
      extra={product.information && getExtraInfo(product.information, localizeCostWithCurrency, localizeMessage)}
      productInfos={compact([
        product.information && getMobileProductInfo(product.information, localizeCostWithCurrency, localizeMessage),
      ])}
    />
  );
};

OrderedMobileProductInfoInner.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
  localizeMessage: PropTypes.func.isRequired,
};

export const OrderedMobileProductInfo: React.ComponentType<OrderedMobileProductInfoInnerProps> = withLocalization(
  'orderedSubscription/OrderedMobileProductInfo'
)(withStyles(styles)(OrderedMobileProductInfoInner));

const getMobileProductInfo = (
  product: MobileOrderedProductInformation,
  localizeCostWithCurrency: LocalizeCostWithCurrency,
  localizeMessage: (key: string, values?: any) => string
): ProductInfo => {
  const labelValues: LabelValue[] = compact([
    {
      label: localizeMessage('data'),
      value: product.dataCapacityInGb.amount
        ? localizeMessage('dataAmount', { amount: product.dataCapacityInGb.amount })
        : localizeMessage('unlimited'),
    },
    product.euDataCapacityInGb && {
      label: localizeMessage('euData'),
      value: product.euDataCapacityInGb.amount
        ? localizeMessage('dataAmount', { amount: product.euDataCapacityInGb.amount })
        : localizeMessage('unlimited'),
    },
    {
      label: localizeMessage('calls'),
      value:
        product.phoneCallsLimitation.name === CallsLimitation.UNLIMITED
          ? localizeMessage('unlimited')
          : product.phoneCallsLimitation.minutes
          ? localizeMessage('callsInMinutes', { minutes: product.phoneCallsLimitation.minutes })
          : localizeMessage('callsInHours', { hours: product.phoneCallsLimitation.hours }),
    },
    {
      label: localizeMessage('texts'),
      value: product.messagesLimitation.amount
        ? localizeMessage('limitedTexts', { message: product.messagesLimitation.amount })
        : localizeMessage('unlimited'),
    },
    {
      label: localizeMessage('bindingPeriod'),
      value: localizeMessage('bindingPeriodLength', { months: product.bindingPeriod }),
    },
    {
      label: localizeMessage('noticePeriod'),
      value: localizeMessage('noticePeriodLength', { months: product.terminationTime }),
    },
  ]);

  return {
    labelValues,
  };
};

const getExtraInfo = (
  product: MobileOrderedProductInformation,
  localizeCostWithCurrency: LocalizeCostWithCurrency,
  localizeMessage: (key: string, values?: any) => string
): string[] => {
  return compact([
    product.welcomeDiscount &&
      localizeMessage('welcomeDiscount', { discount: localizeCostWithCurrency(product.welcomeDiscount) }),
    product.extraData?.monthly &&
      localizeMessage('extraDataMonthly', {
        months: product.extraData.monthly.toMonth - product.extraData.monthly.fromMonth,
        amount: product.extraData.monthly.amount,
      }),
    product.extraData?.oneTimeAmount &&
      localizeMessage('extraDataOneTime', { amount: product.extraData.oneTimeAmount }),
  ]);
};
