import { selectorSelectedSupplier } from '@client/ducks/manual-add-supplier';
import type { SupplierService, SupplierWithServices } from '@client/models/supplier';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { IntelligentGuideDoneComponent } from '@client/modules/intelligent-guides/components/IntelligentGuideDonePage';
import {
  trackRatedIntelligentGuideExperience,
  trackViewedIntelligentGuidePage,
} from '@client/modules/intelligent-guides/mixpanel';
import * as urls from '@client/routes';
import { TrackingButtonName, TrackingFeature, TrackingPageName } from '@client/tracking/mixpanel-constants';
import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { trackCancellationSuccessfulButtonClick } from '../../mixpanel-events';
import {
  trackClosedUpdateBankAppModal,
  trackViewedUpdateBankAppModal,
} from '@minna-components/UpdateBankAppModal/mixpanel-events';
import { CancellationMethodType } from '@client/models/service';

interface Props {
  merchantId: string;
  serviceId: string;
}

export const IntelligentGuideDonePageServiceWrapper: FC<Props> = ({ merchantId, serviceId }) => {
  const history = useHistory();
  const appContext = useSelector(selectorAppContext);
  const selectedMerchantWithServices: SupplierWithServices | undefined = useSelector(selectorSelectedSupplier);
  const selectedService: SupplierService | undefined = selectedMerchantWithServices?.services.find(
    (service) => service.id === serviceId
  );

  const trackCloseButtonClicked = () => {
    trackCancellationSuccessfulButtonClick(
      TrackingButtonName.CLOSE,
      selectedMerchantWithServices?.id ?? '',
      selectedMerchantWithServices?.name ?? '',
      selectedService?.id ?? '',
      selectedService?.name ?? '',
      selectedService?.category?.name ?? '',
      appContext.market,
      appContext.appType,
      CancellationMethodType.IntelligentGuide
    );
  };
  const onRedirectUser = () => {
    history.push(urls.subscriptionHub);
  };

  const onRatedCancellationGuideExperience = (rating: number) => {
    trackRatedIntelligentGuideExperience(
      TrackingFeature.CancelIG, // TODO: this should be changed to 'Cancel'
      merchantId,
      serviceId,
      selectedService?.category.name ?? '',
      selectedService?.name ?? '',
      selectedMerchantWithServices?.name ?? '',
      rating,
      appContext.market,
      appContext.appType
    );
  };

  const onTrackItelligentGuideSuccessPageViewed = () => {
    trackViewedIntelligentGuidePage(
      TrackingFeature.CancelIG,
      TrackingPageName.SUCCESS_SCREEN,
      selectedMerchantWithServices?.name ?? '',
      selectedService?.category.name ?? '',
      selectedService?.name ?? '',
      appContext.market,
      appContext.appType,
      serviceId,
      undefined,
      merchantId
    );
  };

  const onClickStartNewSavingsButton = () => {
    trackCancellationSuccessfulButtonClick(
      TrackingButtonName.START_SAVING,
      merchantId,
      selectedMerchantWithServices?.name ?? '',
      serviceId,
      selectedService?.name ?? '',
      selectedService?.category.name ?? '',
      appContext.market,
      appContext.appType,
      CancellationMethodType.IntelligentGuide
    );
  };

  const onViewedUpdateBankAppModal = () => {
    trackViewedUpdateBankAppModal(
      selectedMerchantWithServices?.id ?? '',
      selectedMerchantWithServices?.name ?? '',
      selectedService?.id ?? '',
      selectedService?.name ?? '',
      selectedService?.category?.name ?? '',
      appContext.market,
      appContext.appType,
      CancellationMethodType.IntelligentGuide,
      ''
    );
  };
  const onCloseUpdateBankAppModal = () => {
    trackClosedUpdateBankAppModal(
      selectedMerchantWithServices?.id ?? '',
      selectedMerchantWithServices?.name ?? '',
      selectedService?.id ?? '',
      selectedService?.name ?? '',
      selectedService?.category?.name ?? '',
      appContext.market,
      appContext.appType,
      CancellationMethodType.IntelligentGuide,
      ''
    );
  };
  return (
    <IntelligentGuideDoneComponent
      showCancelAndSaveEntryPoint
      trackCloseButtonClicked={trackCloseButtonClicked}
      onRedirectUser={onRedirectUser}
      trackRatedIntelligentGuideExperience={onRatedCancellationGuideExperience}
      trackIntelligentGuideSuccessPageViewed={onTrackItelligentGuideSuccessPageViewed}
      trackStartNewSavingsButtonClicked={onClickStartNewSavingsButton}
      trackViewedUpdateBankAppModal={onViewedUpdateBankAppModal}
      trackClosedUpdateBankAppModal={onCloseUpdateBankAppModal}
    />
  );
};
