import type { Fetchable } from '@client/models/Fetchable';
import { fetchableHandle, FetchableNotStarted, mapSuccess } from '@client/models/Fetchable';
import { isOnboardingInsight } from '@client/modules/InsightsPage/utils';
import type { ActionsUnion } from '@client/utils/redux';
import { createAction, createPromiseAction } from '@client/utils/redux';
import get from 'lodash/get';
import reject from 'lodash/reject';
import type { Insight, InsightId } from './models';
import { fetchInsightCards, InsightContentType } from './models';

export interface InsightState {
  insights: Fetchable<Insight[]>;
  resolveSubscriptionGuideCompleted: boolean;
  computedInsightsCount: number;
}

const initialState: InsightState = {
  insights: FetchableNotStarted,
  computedInsightsCount: -1,
  resolveSubscriptionGuideCompleted: false,
};

enum ActionKeys {
  FETCH_INSIGHTS = 'INSIGHTS/FETCH_INSIGHTS',
  RESOLVE_INSIGHT = 'INSIGHTS/RESOLVE_INSIGHT',
  UPDATE_RESOLVE_SUBSCRIPTION_GUIDE_COMPLETED = 'INSIGHTS/UPDATE_RESOLVE_SUBSCRIPTION_GUIDE_COMPLETED',
}

export const InsightActions = {
  fetchInsights: () => createPromiseAction(ActionKeys.FETCH_INSIGHTS, fetchInsightCards()),
  resolveInsight: (insightId: InsightId) => createAction(ActionKeys.RESOLVE_INSIGHT, insightId),
  updateResolveSubscriptionsGuideCompleted: () => createAction(ActionKeys.UPDATE_RESOLVE_SUBSCRIPTION_GUIDE_COMPLETED),
};

type Actions = ActionsUnion<typeof InsightActions>;

export function reducer(state: InsightState = initialState, action: Actions): InsightState {
  switch (action.type) {
    case ActionKeys.FETCH_INSIGHTS:
      return fetchableHandle(state, action, (f) => {
        let computedInsightsCount = state.computedInsightsCount;
        if (f.type === 'SUCCESS') {
          const maybeOnboardingInsight = f.data?.find(
            (insight: Insight) => insight.content.type === InsightContentType.OnboardingInsight
          );
          // Decision to ignore subscription actions (Change payment IG)
          const numOfsubscriptionActions = f.data.filter(
            (insight) => insight.content.type === InsightContentType.SubscriptionActionOutcome
          ).length;
          computedInsightsCount = f.data.length - numOfsubscriptionActions - (maybeOnboardingInsight ? 1 : 0);
        }
        return { ...state, insights: f, computedInsightsCount };
      });

    case ActionKeys.RESOLVE_INSIGHT:
      return {
        ...state,
        insights: mapSuccess(state.insights, (insights) => reject(insights, { id: action.payload })),
        computedInsightsCount: state.computedInsightsCount > 0 ? state.computedInsightsCount - 1 : 0,
      };
    case ActionKeys.UPDATE_RESOLVE_SUBSCRIPTION_GUIDE_COMPLETED:
      return {
        ...state,
        resolveSubscriptionGuideCompleted: true,
      };

    default:
      return state;
  }
}

interface State {
  insights: InsightState;
}

export const selectorFetchableInsights = (state: State): Fetchable<Insight[]> =>
  get(state, 'insights.insights', FetchableNotStarted);

// @ts-ignore Looks suspicious if this even works
export const selectorInsights = (state: State): Insight[] => state.insights.insights?.data ?? [];

export const selectorOnboardingInsight = (state: State): Insight | undefined =>
  // @ts-ignore Looks suspicious if this even works
  findOnboardingInsight(state.insights.insights.data ?? []);

export const selectorIsResolveSubscriptionGuideComplete = (state: State): boolean =>
  get(state, 'insights.resolveSubscriptionGuideCompleted');

export const selectorComputedInsightsCount = (state: State): number => get(state, 'insights.computedInsightsCount', 0);

export const findOnboardingInsight = (insights: Insight[]): Insight | undefined => insights.find(isOnboardingInsight);
