import type { IntlShape, MessageDescriptor } from '@formatjs/intl';
import type { FormatNumberOptions } from '@formatjs/intl/lib/src/types';
import type { FormatXMLElementFn, Options as IntlMessageFormatOptions, PrimitiveType } from 'intl-messageformat';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import forOwn from 'lodash/forOwn';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import tail from 'lodash/tail';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import type { InferableComponentEnhancer } from 'react-redux';
import uuid from 'uuid';
import { applicationInformation } from '../ApplicationInformation';
import Sentry from '../assets/js/sentry';
import type { AmountWithCurrency } from '../models/subscription';
import { Currency } from '../models/subscription';
import { Market } from '../modules/app-context/constants';

export const withLocalization =
  (
    localizationNamespace: string | ((props: any) => string)
    // @ts-ignore
  ): InferableComponentEnhancer<{ WrappedWithLocalizationInterface }> =>
  // @ts-ignore
  (WrappedComponent: any) => {
    class WrappedWithLocalization extends React.Component<{ intl: IntlShape<any> }> {
      public getChildContext() {
        const namespace = isFunction(localizationNamespace) ? localizationNamespace(this.props) : localizationNamespace;

        return localizationFunctions(this.props.intl, namespace);
      }
      public render() {
        return <WrappedComponent {...this.props} />;
      }
    }
    //@ts-ignore
    WrappedWithLocalization.childContextTypes = {
      localizeMessage: PropTypes.func.isRequired,
      localizeCostWithCurrency: PropTypes.func.isRequired,
      localizeCostWithCentesimalCurrency: PropTypes.func.isRequired,
      localizeCurrency: PropTypes.func.isRequired,
      isCurrencyAfterNumber: PropTypes.func.isRequired,
      localizeNumber: PropTypes.func.isRequired,
      localizeDate: PropTypes.func.isRequired,
      locale: PropTypes.string.isRequired,
      localizationNamespace: PropTypes.string,
    };

    return withIntl(WrappedWithLocalization);
  };

function withIntl(Component: any) {
  return function WrappedComponent(props: any) {
    const intl = useIntl();

    return <Component {...props} intl={intl} />;
  };
}

export const useLocalization = (localizationNamespace: string): WithLocalizationContextType => {
  return localizationFunctions(useIntl(), localizationNamespace);
};

const localizationFunctions = (intl: IntlShape<any>, localizationNamespace: string) => {
  return {
    localizeCostWithCurrency: localizeCostWithCurrency(intl.formatNumber),
    localizeMessage: localizeMessageFromNamespace(localizationNamespace, intl.formatMessage),
    localizeCostWithCentesimalCurrency: localizeCostWithCentesimalCurrency(intl.formatNumber),
    localizeCurrency: localizeCurrency(intl.formatNumber),
    isCurrencyAfterNumber: isCurrencyAfterNumber(intl.formatNumber),
    localizeNumber: localizeNumber(intl.formatNumber),
    localizeDate: (date: string | Date, format?: string) => intl.formatDate(date, { format }),
    locale: intl.locale,
    localizationNamespace,
  };
};

function getDisplayName(WrappedComponent: any) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * Creates a HOC which injects a localized version of `fn` as a property
 *
 * @param namespace - the translation namespace used by the function
 * @param func - e.g. function(localizeMessage) {...}
 * @param propName - will inject the new localized function under this name
 *
 * @returns a higher-order component
 *
 * @usage
 * This is useful if you're creating a function that requires translations.
 * This gives you a wrapper, which you can use to inject a "localized" version of your function into your components.
 *
 * @example
 * // en-GB.json
 * "isEven": "{x} is not even at all!!!"
 * "isOdd": "{x} is super even :)"
 *
 * // Function that uses translation
 * const checkEven = localizeMessage => x => {
 *   if (x % 2 === 0) {
 *     return localizeMessage('isEven', {x})
 *   } else {
 *     return localizeMessage('isOdd', {x})
 *   }
 * }
 *
 * // HOC that injects function
 * const injectCheckEven = withLocalizedFunctionProp('check-even-namespace', checkEven, 'checkEven')
 *
 * // Component that wants to use function
 * const MyComponent = injectCheckEven(({some, props, checkEven}) => {
 *   const isEven = checkEven(1); // "1 is not even at all!!!"
 *   return <div>{ isEven.toUpperCase() }</div>
 * });
 */
export const withLocalizedFunctionProp =
  (namespace: string, func: any, propName: string) =>
  (WrappedComponent: any) =>
  // eslint-disable-next-line react/display-name
  (props: any) => {
    const InjectedWithContext: React.FunctionComponent = (
      translationProps: any,
      { localizeMessage, localizeDate, localizeNumber, localizeCurrency }: WithLocalizationContextType
    ) => {
      const fnProp = {
        [propName]: func(localizeMessage, localizeDate, localizeNumber, localizeCurrency),
      };

      return <WrappedComponent {...props} {...fnProp} />;
    };
    InjectedWithContext.contextTypes = {
      localizeMessage: PropTypes.func.isRequired,
      localizeDate: PropTypes.func.isRequired,
      localizeNumber: PropTypes.func.isRequired,
      localizeCurrency: PropTypes.func.isRequired,
    };
    const InjectedWithEverything = withLocalization(namespace)(InjectedWithContext);
    InjectedWithEverything.displayName = `InjectedWithEverything(${getDisplayName(InjectedWithContext)})`;

    return <InjectedWithEverything />;
  };

const localizeMessageFromNamespace =
  (
    localizationNamespace: string,
    formatMessage: (
      descriptor: MessageDescriptor,
      values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
      opts?: IntlMessageFormatOptions
    ) => string
  ) =>
  (id: string, values?: { [variableName: string]: string | number }): any => {
    const idNamespaceIncluded = localizationNamespace ? `${localizationNamespace}.${id}` : id;
    const valuesWithHtmlDefinition: any = {
      ...values,
      /* eslint-disable react/display-name */
      b: (chunks: string) => <strong>{chunks}</strong>,
      strong: (chunks: string) => <strong>{chunks}</strong>,
      i: (chunks: string) => <i>{chunks}</i>,
      em: (chunks: string) => <em>{chunks}</em>,
      /* eslint-enable react/display-name */
    };
    const message = formatMessage({ id: idNamespaceIncluded }, valuesWithHtmlDefinition);
    // If we have missed a translation we want to show a short default text instead of
    // the long translation id that can break the page layout.
    if (message === idNamespaceIncluded) {
      if (applicationInformation.name !== 'admin') {
        // Localization is currently not supported in Admin and will not be until there is a requested requirement,
        // and until then and we want to avoid spamming Sentry.
        // Currently, we are using older shared components that relies in Localization in a few places in Admin.
        // The plan is to remove the usages of these components, and start using Minna UI components
        // which does not rely on Localization.
        const reportToSentry = () => Sentry.captureMessage(`Missing translation for ${idNamespaceIncluded}`);
        // Limit how many times this can be called in development mode, since it's often expected that translations are missing
        // for an entire page when implementing new functionality, and logging all of these affects performance.
        if (process.env.NODE_ENV === 'development') {
          const debouncedReportToSentry = debounce(reportToSentry, 1000);
          debouncedReportToSentry();
        } else {
          reportToSentry();
        }
      }

      return 'MISSING';
    }

    return message;
  };

// NOTE: this function is intended to produce the same result as the function with the same name in Cogs, please
// make sure to do any changes to it in both places!
const localizeCostWithCurrency =
  (formatNumber: (value: number, options?: FormatNumberOptions) => string) =>
  (cost: AmountWithCurrency, optionalDecimals: number = 2): string => {
    if (optionalDecimals > 0) {
      const afterDecimal = cost.amount.toFixed(optionalDecimals).split('.');
      if (afterDecimal.length > 1 && parseInt(afterDecimal[1], 10) > 0) {
        return formatNumber(cost.amount, {
          style: 'currency',
          currency: cost.currency,
          minimumFractionDigits: optionalDecimals,
          maximumFractionDigits: optionalDecimals,
        });
      }
    }

    return formatNumber(cost.amount, {
      style: 'currency',
      currency: cost.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

// NOTE: this function is intended to produce the same result as the function with the same name in Cogs, please
// make sure to do any changes to it in both places!
const localizeCostWithCentesimalCurrency =
  (formatNumber: (value: number, options?: FormatNumberOptions) => string) =>
  (cost: AmountWithCurrency, market: Market): string => {
    const amount = formatNumber(cost.amount * 100, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    const centesimalCurrency = centesimalCurrencyFromCurrency(cost.currency, market);

    return `${amount}${centesimalCurrency}`;
  };

const centesimalCurrencyFromCurrency = (currency: Currency, market: Market) => {
  // NBSP used when needed here to not get line breaks between a currency and its number. This matches what react-intl and icu4j does for non-centesimal currencies
  switch (currency) {
    case Currency.SEK:
      // eslint-disable-next-line no-irregular-whitespace
      return ' öre';
    case Currency.DKK:
      // eslint-disable-next-line no-irregular-whitespace
      return ' øre';
    case Currency.GBP:
      return 'p';
    case Currency.NOK:
      // eslint-disable-next-line no-irregular-whitespace
      return ' øre';
    case Currency.EUR:
      if (market === Market.Finland) {
        // eslint-disable-next-line no-irregular-whitespace
        return ' snt';
      } else {
        // eslint-disable-next-line no-irregular-whitespace
        return ' cents';
      }
    default:
      Sentry.captureMessage(`No centesimal currency added for ${currency}`);

      return '';
  }
};

const localizeNumber =
  (formatNumber: (value: number, options?: FormatNumberOptions) => string) =>
  (number: number, optionalDecimals = 0) => {
    if (optionalDecimals > 0) {
      const decimals = Math.abs(Math.round((number * 100) % 100));
      if (decimals !== 0 && decimals !== 100) {
        return formatNumber(number, {
          style: 'decimal',
          minimumFractionDigits: optionalDecimals,
          maximumFractionDigits: optionalDecimals,
        });
      }
    }

    return formatNumber(number, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

const isCurrencyAfterNumber =
  (formatNumber: (value: number, options?: FormatNumberOptions) => string) => (currency: Currency) => {
    const zeroFormattedWithCurrency = formatNumber(0, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return zeroFormattedWithCurrency[0] === '0';
  };

const localizeCurrency =
  (formatNumber: (value: number, options?: FormatNumberOptions) => string) => (currency: Currency) => {
    const zeroFormattedWithCurrency = formatNumber(0, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return zeroFormattedWithCurrency.replace('0', '').trim();
  };

export const LocalizedMessage: React.FunctionComponent<ILocalizedMessageProps> = (
  { id, values },
  { localizeMessage }
) => localizeMessage(id, values);

LocalizedMessage.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const LocalizedMessageWithCurrency: React.FunctionComponent<ILocalizedMessageWithCurrencyProps> = (
  { id, values, currency },
  { localizeMessage, localizeCurrency }
) => localizeMessage(id, { ...values, currency: localizeCurrency(currency) });

LocalizedMessageWithCurrency.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
  localizeCurrency: PropTypes.func.isRequired,
};

export const LocalizedMessageWithElementsInjected: React.FunctionComponent<{
  id: string;
  values?: { [variableName: string]: any };
  elements: { [elementName: string]: React.ReactNode };
}> = ({ id, values, elements }, { localizeMessage }) => {
  const uniqueIdElementMap = new Map();
  const allValuesForLocalization = cloneDeep(values || {});
  forOwn(elements, (element, elementKeyInLocalizationString) => {
    const uniqueIdForSplit = uuid.v4();
    allValuesForLocalization[elementKeyInLocalizationString] = uniqueIdForSplit;
    uniqueIdElementMap.set(uniqueIdForSplit, element);
  });
  const localizedMessage = localizeMessage(id, allValuesForLocalization);
  const uniqueIdsForSplit = Array.from(uniqueIdElementMap.keys());
  // create a regex that groups every text part and every unique id
  const splitRegexFromUniqueIds = new RegExp(`(.*)${uniqueIdsForSplit.map((id) => `(${id})`).join('(.*)')}(.*)`);
  // get all groups matched by the regex
  const localizedMessageSplitted = tail(splitRegexFromUniqueIds.exec(localizedMessage));
  const arrayWithContent = map(localizedMessageSplitted, (contentPiece: any, i: number) => {
    const contentPieceIsElement = uniqueIdElementMap.has(contentPiece);
    if (contentPieceIsElement) {
      const elementKey = contentPiece;

      return React.cloneElement(uniqueIdElementMap.get(elementKey), { key: elementKey });
    } else {
      return <span key={i}>{contentPiece}</span>;
    }
  });

  return <>{arrayWithContent}</>;
};

LocalizedMessageWithElementsInjected.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export type LocalizeCostWithCurrency = (cost: AmountWithCurrency, optionalDecimals?: number) => string;

export type LocalizeCostWithCentesimalCurrency = (
  cost: AmountWithCurrency,
  market: Market,
  optionalDecimals?: number
) => string;

export type LocalizeMessage = (id: string, objects?: { [key: string]: string | number }) => string;

export type LocalizeCurrency = (currency: Currency) => string;

export type IsCurrencyAfterNumber = (currency: Currency) => boolean;

export type LocalizeNumber = (number: number, optionalDecimals?: number) => string;

export type LocalizeDate = (date: string | Date, format?: string) => string;

export interface ILocalizedMessageProps {
  id: string;
  values?: { [variableName: string]: any };
}

export interface ILocalizedMessageWithCurrencyProps {
  id: string;
  values?: { [variableName: string]: any };
  currency: Currency;
}

export interface WithLocalizationContextType {
  localizeCostWithCurrency: LocalizeCostWithCurrency;
  localizeMessage: LocalizeMessage;
  localizeCurrency: LocalizeCurrency;
  isCurrencyAfterNumber: IsCurrencyAfterNumber;
  localizeCostWithCentesimalCurrency: LocalizeCostWithCentesimalCurrency;
  localizeNumber: LocalizeNumber;
  localizeDate: LocalizeDate;
  locale: string;
  localizationNamespace: string;
}
