import type { AmountWithCurrency } from '@client/models/subscription';
import type { FitnessProduct, GymMembership } from '@client/modules/switch/fitness/models';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface FitnessGuide {
  currentYearlyCost?: AmountWithCurrency;
  customerSegment?: GymMembership;
  products?: FitnessProduct[];
  productsLoading: boolean;
  productsLoadingFailed: boolean;
  chosenProduct?: FitnessProduct;
}

const initialState: FitnessGuide = {
  productsLoading: false,
  productsLoadingFailed: false,
};

export const { reducer, actions: FitnessGuideActions } = createSlice({
  name: 'FITNESS_GUIDE',
  initialState: initialState,
  reducers: {
    initialize: (state, action: PayloadAction<FitnessGuide>) => action.payload,
    reset: () => initialState,
    setCustomerSegment: (state, action: PayloadAction<GymMembership>) => {
      state.customerSegment = action.payload;
    },
    setCurrentYearlyCost: (state, action: PayloadAction<AmountWithCurrency | undefined>) => {
      state.currentYearlyCost = action.payload;
    },
    setProductsLoading: (state) => {
      state.productsLoading = true;
    },
    setProductsLoadingFailed: (state) => {
      state.productsLoading = false;
      state.productsLoadingFailed = true;
    },
    setProducts: (state, action: PayloadAction<FitnessProduct[]>) => {
      state.products = action.payload;
      state.productsLoadingFailed = false;
      state.productsLoading = false;
    },
    setChosenProduct: (state, action: PayloadAction<FitnessProduct>) => {
      state.chosenProduct = action.payload;
    },
  },
});

interface State {
  fitnessGuide: FitnessGuide;
}

export const selectorCurrentYearlyCost = (state: State) => state.fitnessGuide.currentYearlyCost;
export const selectorCustomerSegment = (state: State) => state.fitnessGuide.customerSegment;
export const selectorProducts = (state: State) => state.fitnessGuide.products;
export const selectorChosenProduct = (state: State) => state.fitnessGuide.chosenProduct;
export const selectorProductsLoading = (state: State) => state.fitnessGuide.productsLoading;
