import PropTypes from 'prop-types';
import React from 'react';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import {validationContextPropType} from '../Form';
import getDisplayName from 'recompose/getDisplayName';

export class ValidationFaCC extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    validators: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.func),
      PropTypes.arrayOf(PropTypes.func)
    ]).isRequired,
    children: PropTypes.func.isRequired
  };

  static defaultProps = {
    validators: []
  };

  static contextTypes = {
    validation: validationContextPropType.isRequired
  };

  state = {
    valid: true,
    errors: null
  };

  UNSAFE_componentWillMount() {
    this.context.validation.register(this);

    const {value, validators} = this.props;
    this.setState(this.computeState(value, validators));
  }

  componentWillUnmount() {
    this.context.validation.unregister(this);
  }

  UNSAFE_componentWillReceiveProps({value, validators}) {
    this.setState(this.computeState(value, validators));
  }

  computeState = (value, validators) => {
    const errors = this.validate(value, validators);
    const valid = isEmpty(errors);

    return {
      valid,
      errors
    };
  };

  validate = (value, validators) => {
    if (isArray(validators)) {
      const evaluated = map(validators, validator => validator(value));
      return compact(evaluated);
    } else {
      const evaluated = mapValues(validators, validator => validator(value));
      return pickBy(evaluated, result => Boolean(result));
    }
  };

  render() {
    return this.props.children(this.state);
  }
}

export default function (WrappedComponent) {
  const ValidationHOC = (props) => (
    <ValidationFaCC { ...props }>
      { (state) => {
        const childProps = omit(props, ['validators']);
        return (
          <WrappedComponent { ...childProps } { ...state } />
        );
      }}
    </ValidationFaCC>
  );

  ValidationHOC.displayName = `ValidationHOC(${getDisplayName(WrappedComponent)})`;

  ValidationHOC.propTypes = omit(ValidationFaCC.propTypes, ['children']);

  return ValidationHOC;
}
