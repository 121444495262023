// Kind of copy-pasted from Core's ApiGen
// When updating this model also update the copy in '/integration-tests/cypress/models/platform.ts'

/*
 * Contains information about the users specific platform. Could include more detailed information as well.
 * For example Swedbank also contains detailed information about which bank inside the Swedbank platform the user
 * is connected to.
 */

export enum PlatformName {
  Undefined = 'Undefined',
  OpBank = 'OpBank',
  Standalone = 'Standalone',
  Swedbank = 'Swedbank',
  Ing = 'Ing',
  DanskeBank = 'DanskeBank',
  Sb1 = 'Sb1',
  Lloyds = 'Lloyds',
  Anyfin = 'Anyfin',
  Zlantar = 'Zlantar',
  Steven = 'Steven',
  CapitalOne = 'CapitalOne',
  LittleBirdie = 'LittleBirdie',
}

export interface Platform {
  name: PlatformName;
  branch?: string;
}

export interface Standalone extends Platform {
  name: PlatformName.Standalone;
}
export const Standalone: Standalone = { name: PlatformName.Standalone };

export interface DanskeBank extends Platform {
  name: PlatformName.DanskeBank;
}
export const DanskeBank: DanskeBank = { name: PlatformName.DanskeBank };

export interface Sb1 extends Platform {
  name: PlatformName.Sb1;
}
export const Sb1: Sb1 = { name: PlatformName.Sb1 };

export type SwedbankBankId = string;

export interface Swedbank extends Platform {
  /**
   * Specific bank inside of Swedbank. There exist some cases where we don't know which Swedbank bank the user came from, for example when the user is using the Standalone.
   */
  bankId?: SwedbankBankId;
  name: PlatformName.Swedbank;
}
export const SwedbankPrivate: Swedbank = {
  name: PlatformName.Swedbank,
  bankId: '08999',
};

export interface Undefined extends Platform {
  name: PlatformName.Undefined;
}
export const Undefined: Undefined = { name: PlatformName.Undefined };

export interface Ing extends Platform {
  name: PlatformName.Ing;
}
export const Ing: Ing = { name: PlatformName.Ing };

export interface OpBank extends Platform {
  name: PlatformName.OpBank;
}
export const OpBank: OpBank = { name: PlatformName.OpBank };
