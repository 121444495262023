import type { AppIntegrationImplementation } from '@client/modules/app-integration';
import { AppIntegrationType, AppPlatform } from '@client/modules/app-integration';
import { closeWebPage } from '@client/modules/app-integration/sb1/close-web-page';
import Cookies from 'js-cookie';
import includes from 'lodash/includes';
import { openInBrowser } from './sb1/open-in-browser';

function getNativeVersionFromUserAgent() {
  const userAgent = navigator.userAgent;
  const MOBILBANK_STRING = 'Mobilbank/';

  if (includes(userAgent, MOBILBANK_STRING)) {
    return userAgent.split(MOBILBANK_STRING)[1].split(' ')[0];
  }

  return '';
}

function getAppPlatformInternal() {
  const userAgent = navigator.userAgent;

  if (userAgent.match(/(Android)\s+([\d.]+)/)) {
    return AppPlatform.ANDROID_WEBVIEW;
  } else {
    return AppPlatform.IOS_WEBVIEW;
  }
}

function getAppIntegrationTypeInternal() {
  if (Cookies.get('isMobilbankApp') !== undefined || getNativeVersionFromUserAgent()) {
    return AppIntegrationType.MOBILE_APPLICATION;
  } else {
    return AppIntegrationType.WEB_BROWSER;
  }
}

export const appIntegration: AppIntegrationImplementation = {
  getAppIntegrationType() {
    return getAppIntegrationTypeInternal();
  },
  getAppPlatform() {
    return getAppPlatformInternal();
  },
  closeApplication: closeWebView,
  closeApplicationOrGoTo: closeWebView,
  sendAnalyticsEvent,
  openExternalWebPage,
  openInternalPage,
};

function closeWebView(): boolean {
  closeWebPage();
  return true;
}

function sendAnalyticsEvent() {
  return true;
}

function openExternalWebPage(url: string): boolean {
  openInBrowser(url);
  return true;
}

function openInternalPage(url: string): boolean {
  openInBrowser(url);
  return true;
}
