import type { AppIntegrationImplementation } from '@client/modules/app-integration';
import { AppIntegrationType, AppPlatform } from '@client/modules/app-integration';
import { closeWebPage } from '@client/modules/app-integration/webview-integration/close-web-page';
import { isAndroidApp } from '@client/modules/app-integration/webview-integration/device-helper';
import { openInBrowser } from '@client/modules/app-integration/webview-integration/open-in-browser';

declare global {
  interface Window {
    webkit?: { messageHandlers: any };
    nativeHost?: any;
    onWebViewHostMessage?(message: any): void;
  }
}

function getAppPlatformInternal() {
  if (isAndroidApp()) {
    return AppPlatform.ANDROID_WEBVIEW;
  } else {
    return AppPlatform.IOS_WEBVIEW;
  }
}

function getAppIntegrationTypeInternal() {
  return AppIntegrationType.MOBILE_APPLICATION;
}

export const appIntegration: AppIntegrationImplementation = {
  getAppIntegrationType() {
    return getAppIntegrationTypeInternal();
  },
  getAppPlatform() {
    return getAppPlatformInternal();
  },
  closeApplication: closeWebView,
  closeApplicationOrGoTo: closeWebView,
  sendAnalyticsEvent,
  openExternalWebPage,
  openInternalPage,
};

function closeWebView(): boolean {
  closeWebPage();
  return true;
}

function sendAnalyticsEvent() {
  return true;
}

function openExternalWebPage(url: string): boolean {
  openInBrowser(url);
  return true;
}

function openInternalPage(url: string): boolean {
  openInBrowser(url);
  return true;
}
