import { LocalizedMessage, withLocalization } from '@client/internationalization';
// @ts-ignore
import Form from '@client/modules/shared-components/form-validation/Form';
import { RadioButton, RadioGroup } from '@client/modules/shared-components/inputs/RadioButton';
import { selectorSubscription } from '@client/modules/subscription';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export interface RemoveSubscriptionDialogProps {
  open: boolean;
  goBack(): void;
  removeSubscription(reason: string): void;
}

// eslint-disable-next-line
export const RemoveSubscriptionDialogComponent: React.FunctionComponent<RemoveSubscriptionDialogProps> = (
  props: RemoveSubscriptionDialogProps,
  { localizeMessage }
) => {
  const subscription = useSelector(selectorSubscription);
  const [selectedReason, setSelectedReason] = useState('');
  const { colors } = useTheme();

  return (
    <Modal
      open={props.open}
      title={<LocalizedMessage id="deleteSubscriptionDialogTitle" />}
      button1={
        <PrimaryButton
          label={localizeMessage('deleteSubscriptionDialogOptionConfirm')}
          onClick={() => props.removeSubscription(selectedReason)}
        />
      }
      button2={
        <SecondaryButton label={localizeMessage('deleteSubscriptionDialogOptionAbortV2')} onClick={props.goBack} />
      }
      onClose={props.goBack}
    >
      <Body>
        <LocalizedMessage
          id="deleteSubscriptionDialogDescription"
          values={{ supplierName: subscription?.supplier.name }}
        />
      </Body>
      <Caption color={colors.textOn.surfaceSubdued} style={{ margin: '8px 0 16px 0' }}>
        <LocalizedMessage id="deleteSubscriptionCardDescription" />
      </Caption>
      <Form>
        <RadioGroup name="deletionReason" requiredErrorText="you must choose one option">
          <RadioButton
            checked={selectedReason === localizeMessage('deleteReasonNeverHadTheSubscription')}
            value={localizeMessage('deleteReasonNeverHadTheSubscription')}
            label={localizeMessage('deleteReasonNeverHadTheSubscription')}
            onChange={(e) => setSelectedReason(e.target.value)}
          />
          <RadioButton
            checked={selectedReason === localizeMessage('deleteReasonIsTerminated')}
            value={localizeMessage('deleteReasonIsTerminated')}
            label={localizeMessage('deleteReasonIsTerminated')}
            onChange={(e) => setSelectedReason(e.target.value)}
          />
          <RadioButton
            checked={selectedReason === localizeMessage('deleteReasonSomeoneElsePaysForIt')}
            value={localizeMessage('deleteReasonSomeoneElsePaysForIt')}
            label={localizeMessage('deleteReasonSomeoneElsePaysForIt')}
            onChange={(e) => setSelectedReason(e.target.value)}
          />
          <RadioButton
            checked={selectedReason === localizeMessage('deleteReasonOther')}
            value={localizeMessage('deleteReasonOther')}
            label={localizeMessage('deleteReasonOther')}
            onChange={(e) => setSelectedReason(e.target.value)}
          />
        </RadioGroup>
      </Form>
    </Modal>
  );
};

RemoveSubscriptionDialogComponent.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const RemoveSubscriptionDialog = withLocalization('subscription/RemoveSubscriptionDialog')(
  RemoveSubscriptionDialogComponent
);
