import { LocalizedMessage } from '@client/internationalization';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { ContactUsButton } from '@client/shared-components/ContactUsButton';
import { makeStyles } from '@material-ui/core';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  sectionMargin: {
    margin: '0 0 24px',
  },
  content: {
    margin: '8px 16px 16px',
  },
});

export interface Props {
  serviceProviderName: string;
  pageName: string;
}

export const NoAnswerFromUserContent: FC<Props> = ({ serviceProviderName, pageName }) => {
  const classes = useStyles();
  const features = useSelector(selectorFeatures);

  return (
    <div className={classes.content}>
      <div className={classes.sectionMargin}>
        <Body>
          <LocalizedMessage id="noAnswerFromUserDescription1" values={{ supplierName: serviceProviderName }} />
        </Body>
        <br />
        {features.helpCenter.enabled && (
          <Body>
            <LocalizedMessage id="noAnswerFromUserDescription2" />
          </Body>
        )}
      </div>
      {features.helpCenter.enabled && (
        <ContactUsButton
          pageName={pageName}
          insightCardName="CancellationOutcomeNoAnswerFromUser"
          supplierName={serviceProviderName}
        />
      )}
    </div>
  );
};
