import type { ContractId, SubscriptionId } from '@client/modules/subscription/types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import type { SubscriptionRoute } from '../routes';
import * as englishUrls from '../routes';
import * as swedishUrls from './deprecated-swedish-routes';

const subscriptionContractTranslations: [
  englishUrls.SubscriptionContractRoute,
  englishUrls.SubscriptionContractRoute
][] = [
  [swedishUrls.contractDetailsPage, englishUrls.contractDetailsPage],
  [swedishUrls.saveDesk, englishUrls.saveDesk],
  [swedishUrls.saveDeskConfirmedPage, englishUrls.saveDeskConfirmedPage],
  [swedishUrls.saveDeskDetailsPage, englishUrls.saveDeskDetailsPage],
  [swedishUrls.saveDeskPainChoicesPage, englishUrls.saveDeskPainChoicesPage],
  [swedishUrls.saveDeskStartPage, englishUrls.saveDeskStartPage],
  [swedishUrls.selectServicesPage, englishUrls.selectServicesPage],
  [swedishUrls.subscriptionContractRoute, englishUrls.subscriptionContractRoute],
  [swedishUrls.subscriptionPaymentHistoryPage, englishUrls.subscriptionPaymentHistoryPage],
  [swedishUrls.orderedSubscriptionDetailsPage, englishUrls.orderedSubscriptionDetailsPage],
  [swedishUrls.terminate, englishUrls.subscriptionCancelBasePath],
  [swedishUrls.terminationFormPage, englishUrls.terminationFormPage],
  [swedishUrls.terminationInstructionsPage, englishUrls.terminationInstructionsPage],
  [swedishUrls.terminationSentPage, englishUrls.terminationSentPage],
  [swedishUrls.terminationSignPage, englishUrls.terminationSignPage],
  [swedishUrls.whyDidYouCancelPage, englishUrls.whyDidYouCancelPage],
];

const subscriptionTranslations: [SubscriptionRoute, SubscriptionRoute][] = [
  [swedishUrls.subscriptionPage, englishUrls.subscriptionPage],
  [swedishUrls.subscriptionRoute, englishUrls.subscriptionBasePath],
  [swedishUrls.subscriptionSettingsPage, englishUrls.subscriptionSettingsPage],
  [swedishUrls.subscriptionUpdateCostPage, englishUrls.subscriptionUpdateCostPage],
];

const staticTranslations: [string, string][] = [
  [swedishUrls.cancellationListPage, englishUrls.cancellationListPage],
  [swedishUrls.conditionsAndPoliciesMenu, englishUrls.conditionsAndPoliciesMenu],
  [swedishUrls.conditionsAndPoliciesOnboardingPage, englishUrls.conditionsAndPoliciesOnboardingPage],
  [swedishUrls.cookiesPage, englishUrls.cookiesPage],
  [swedishUrls.createAccountPage, englishUrls.createAccountPage],
  [swedishUrls.feedbackPage, englishUrls.feedbackPage],
  [swedishUrls.improvementPage, englishUrls.insightsPage],
  [swedishUrls.loggedOutPage, englishUrls.loggedOutPage],
  [swedishUrls.loginPage, englishUrls.loginPage],
  [swedishUrls.loginStatusPage, englishUrls.loginStatusPage],
  [swedishUrls.manualAddSubscriptionPage, englishUrls.manualAddSubscriptionPage],
  [swedishUrls.manualSubscriptionPricingPage, englishUrls.manualSubscriptionPricingPage],
  [swedishUrls.menuPage, englishUrls.menuPage],
  [swedishUrls.monthlyChecklist, englishUrls.monthlyChecklist],
  [swedishUrls.morePage, englishUrls.morePage],
  [swedishUrls.notificationSettingsPage, englishUrls.notificationSettingsPage],
  [swedishUrls.onboard, englishUrls.onboard],
  [swedishUrls.onboardBankDiscoveryWorking, englishUrls.onboardBankDiscoveryWorking],
  [swedishUrls.onboardBankSync, englishUrls.onboardBankSync],
  [swedishUrls.onboardConsent, englishUrls.onboardConsent],
  [swedishUrls.onboardingPsd2, englishUrls.onboardingPsd2],
  [swedishUrls.onboardingPsd2Callback, englishUrls.onboardingPsd2Callback],
  [swedishUrls.onboardingPsd2Consent, englishUrls.onboardingPsd2Consent],
  [swedishUrls.onboardingPsd2ConsentExpired, englishUrls.onboardingPsd2ConsentExpired],
  [swedishUrls.onboardingSetEmailPage, englishUrls.onboardingSetEmailPage],
  [swedishUrls.onboardingTermsAndConsentPage, englishUrls.onboardingTermsAndConsentPage],
  [swedishUrls.overviewPage, englishUrls.overviewPage],
  [swedishUrls.syncLoadingPage, englishUrls.syncLoadingPage],
  [swedishUrls.privacyPolicyPage, englishUrls.privacyPolicyPage],
  [swedishUrls.profilingSettingsPage, englishUrls.profilingSettingsPage],
  [swedishUrls.pushNotificationOnboarding, englishUrls.pushNotificationOnboarding],
  [swedishUrls.quickActions, englishUrls.quickActions],
  [swedishUrls.quickActionsCancel, englishUrls.quickActionsCancel],
  [swedishUrls.recommendation, englishUrls.recommendation],
  [swedishUrls.resetPasswordPage, englishUrls.resetPasswordPage],
  [swedishUrls.securityPage, englishUrls.securityPage],
  [swedishUrls.settingsPage, englishUrls.settingsPage],
  [swedishUrls.signoutSuccessPage, englishUrls.signoutSuccessPage],
  [swedishUrls.subscriptionContractRoutePrefix, englishUrls.subscriptionContractRoutePrefix],
  [swedishUrls.subscriptionRoutePrefix, englishUrls.subscriptionRoutePrefix],
  [swedishUrls.userDeletePage, englishUrls.userDeletePage],
  [swedishUrls.userDeleteSuccessPage, englishUrls.userDeleteSuccessPage],
  [swedishUrls.userSettingsPage, englishUrls.userSettingsPage],
  [swedishUrls.userTermsPage, englishUrls.userTermsPage],
  [swedishUrls.viewGdprDataPage, englishUrls.viewGdprDataPage],
];

const emailRedirections: [string, string][] = [
  [swedishUrls.unsubscribeEmailPath(':emailUserId'), englishUrls.unsubscribeEmailPath(':emailUserId')],
];

export function swedishToEnglishRedirections(): React.ReactElement[] {
  function subscriptionContractRoutes(subscriptionId: SubscriptionId, contractId: ContractId) {
    const redirections = subscriptionContractTranslations.map(([swedishUrl, englishUrl]) => (
      <Redirect
        key={swedishUrl(subscriptionId, contractId)}
        from={swedishUrl(subscriptionId, contractId)}
        to={englishUrl(subscriptionId, contractId)}
        exact
      />
    ));

    return <Switch>{redirections}</Switch>;
  }

  function subscriptionRoutes(subscriptionId: SubscriptionId) {
    const redirections = subscriptionTranslations.map(([swedishUrl, englishUrl]) => (
      <Redirect
        key={swedishUrl(subscriptionId)}
        from={swedishUrl(subscriptionId)}
        to={englishUrl(subscriptionId)}
        exact
      />
    ));

    return <Switch>{redirections}</Switch>;
  }

  const staticRoutes = staticTranslations.map(([swedishUrl, englishUrl]) => (
    <Redirect key={swedishUrl} from={swedishUrl} to={englishUrl} exact />
  ));

  const emailRoutes = emailRedirections.map(([swedishUrl, englishUrl]) => (
    <Redirect key={swedishUrl} from={swedishUrl} to={englishUrl} exact />
  ));

  return [
    <Route
      key="subscription-contracts"
      path={`${swedishUrls.subscriptionRoutePrefix}/:subscriptionId/${swedishUrls.subscriptionContractRoutePrefix}/:contractId`}
      render={({
        match: {
          params: { subscriptionId, contractId },
        },
      }) => subscriptionContractRoutes(subscriptionId, contractId)}
    />,
    <Route
      key="subscription"
      path={`${swedishUrls.subscriptionRoutePrefix}/:subscriptionId`}
      render={({
        match: {
          params: { subscriptionId },
        },
      }) => subscriptionRoutes(subscriptionId)}
    />,
    <Redirect
      key="signing"
      from={`/signera/:contractId/:signingId/:resourceToSignId/:navigateToOnSuccess`}
      to={englishUrls.signResourcePage(':contractId', ':signingId', ':resourceToSignId', ':navigateToOnSuccess')}
    />,
  ]
    .concat(staticRoutes)
    .concat(emailRoutes);
}
