import jsCookie from 'js-cookie';

/**
 * This code is shared with us from Sb1's module for mobilbank-communication. This is version 5.5.1.
 * Original file path is: package/lib-es/util/device-helper.js
 */

const iPadUserAgentPattern = /(iPad).*OS\s([\d_]+)/;

function getNativeVersionFromUserAgent() {
  const userAgent = navigator.userAgent;
  const MOBILBANK_STRING = 'Mobilbank/';

  if (userAgent.indexOf(MOBILBANK_STRING) !== -1) {
    return userAgent.split(MOBILBANK_STRING)[1].split(' ')[0];
  }

  return null;
}

export function getOs(userAgent: any) {
  const returnObj: any = {};

  const windows = userAgent.match(/(Windows\sNT)\s([\d.]+)/i);
  const mac = userAgent.match(/(os\sx)\s([\d_]+)/i);
  const linux = userAgent.match(/(linux)\s([A-z|0-9]+)/i);

  const android = userAgent.match(/(Android)\s+([\d.]+)/);
  const iphone = userAgent.match(/(iPhone\sOS)\s([\d_]+)/);
  const ipad = userAgent.match(iPadUserAgentPattern);
  const webos = userAgent.match(/(webOS)\/([\d.]+)/);
  const symbian = userAgent.match(/(Symbian|SymbianOS)\/([\d.]+)/);
  const windowsPhone = userAgent.match(/Windows\sPhone(?:\sOS)?\s([\d.]+)/);

  if (windows && !windowsPhone) {
    returnObj.windows = true;
    returnObj.version = windows[2];
  } else if (mac) {
    returnObj.osx = true;
    returnObj.version = mac[2].replace(/_/g, '.');
  } else if (linux && !android) {
    returnObj.linux = true;
    returnObj.version = linux[2];
  } else if (windowsPhone) {
    returnObj.windowsPhone = true;
    returnObj.version = windowsPhone[1];
  } else if (android) {
    returnObj.android = true;
    returnObj.version = android[2];
  } else if (iphone && !ipad) {
    returnObj.iphone = true;
    returnObj.ios = true;
    returnObj.version = iphone[2].replace(/_/g, '.');
  } else if (ipad) {
    returnObj.ipad = true;
    returnObj.ios = true;
    returnObj.version = ipad[2].replace(/_/g, '.');
  } else if (webos) {
    returnObj.webos = true;
    returnObj.version = webos[2];
  } else if (symbian) {
    returnObj.symbian = true;
    returnObj.version = symbian[2];
  }

  return returnObj;
}

export function isNative() {
  return jsCookie.get('isMobilbankApp') !== undefined || !!getNativeVersionFromUserAgent();
}

export function isAndroidApp() {
  return isNative() && getOs(navigator.userAgent).android;
}

export function isIosApp() {
  return isNative() && getOs(navigator.userAgent).ios;
}

export function isIpadApp() {
  return isIosApp() && !!navigator.userAgent.match(iPadUserAgentPattern);
}

export function isSimulatingNative() {
  const userAgent = navigator.userAgent;
  const SIMULATION_STRING = /Simulated/i;
  return userAgent.search(SIMULATION_STRING) !== -1;
}

export function iosAppVersionIsEqualOrAbove(versionRequired: string) {
  return isIosApp() && nativeVersionIsEqualOrAbove(versionRequired);
}

export function osVersionIsEqualOrAbove(versionRequired: string) {
  return versionNumberIsEqualOrAbove(getOs(navigator.userAgent).version, versionRequired);
}

export function androidAppVersionIsEqualOrAbove(versionRequired: string) {
  return isAndroidApp() && nativeVersionIsEqualOrAbove(versionRequired);
}

export function versionNumberIsEqualOrAbove(v1: string, v2: string) {
  const versionNumbers1: any[] = v1.split('.');
  const versionNumbers2: any[] = v2.split('.');

  if (versionNumbers1.length !== versionNumbers2.length) {
    if (versionNumbers1.length > versionNumbers2.length) {
      for (let i = versionNumbers2.length; i < versionNumbers1.length; i++) {
        versionNumbers2[i] = 0;
      }
    } else {
      for (let _i = versionNumbers1.length; _i < versionNumbers2.length; _i++) {
        versionNumbers1[_i] = 0;
      }
    }
  }

  for (let _i2 = 0; _i2 < versionNumbers1.length; _i2++) {
    if (parseInt(versionNumbers1[_i2], 10) < parseInt(versionNumbers2[_i2], 10)) {
      return false;
    } else if (parseInt(versionNumbers1[_i2], 10) > parseInt(versionNumbers2[_i2], 10)) {
      return true;
    }
  }

  return true;
}

function nativeVersionIsEqualOrAbove(versionRequired: string) {
  const version = getNativeVersionFromUserAgent();
  return !!(version && versionNumberIsEqualOrAbove(version, versionRequired));
}
