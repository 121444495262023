//@ts-ignore
import { loadingPageWhileLoading, onEnter } from '@client/containers/container-helpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withAppContext } from '../../../../app-context/react';
import { filterArticlesBySection, selectorLoadingArticles } from '../../../ducks/zendesk-help-center';
import { DisplaySection } from './component';

const mapStateToProps = (state: any) => {
  return {
    loadingArticles: selectorLoadingArticles(state),
  };
};

export const DisplaySectionContainer = compose<any, any>(
  withAppContext,
  connect(mapStateToProps),
  onEnter(({ appContext, section }: any, { store }: any) => {
    store.dispatch(
      filterArticlesBySection(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, section.id)
    );
  }),
  loadingPageWhileLoading((p: any) => p.loadingArticles)
)(DisplaySection);

DisplaySectionContainer.propTypes = {
  section: PropTypes.object.isRequired,
  onBackClick: PropTypes.func.isRequired,
};
