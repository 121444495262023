import type { Market } from '@client/modules/app-context/constants';

export enum RatingType {
  InvestmentAccountOpeningRating = 'InvestmentAccountOpeningRating',
  IndependentOrderRating = 'IndependentOrderRating',
  SwitchOrderRating = 'SwitchOrderRating',
  SaveDeskOrderRating = 'SaveDeskOrderRating',
  CancellationRating = 'CancellationRating',
  PauseRating = 'PauseRating',
  ResumeRating = 'ResumeRating',
}

export type RatingRequest =
  | {
      rating: number;
      maxRating: number;
      ratingType: RatingType;
      value?: string;
      ratingTypeUUID: string;
    }
  | {
      rating: number;
      maxRating: number;
      ratingType: RatingType;
      value?: string;
      ratingTypeUUID?: string;
      feedbackType: FeedbackType.Completed;
      categoryName?: { name: string };
      market: Market;
    }
  | {
      rating: number;
      maxRating: number;
      ratingType: RatingType;
      feedbackType?: FeedbackType.Flow;
      categoryName?: { name: string };
      market: Market;
    };

export enum FeedbackType {
  Completed = 'Completed',
  Flow = 'Flow',
}
