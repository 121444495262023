import type { Contract, Subscription } from '../subscription/types';
import type { Props } from './mixpanel-events';

export const fireMixpanelEvent = (
  subscription: Subscription,
  contract: Contract | undefined | null,
  fn: (props: Props) => void
) => {
  const { supplier } = subscription;

  if (!supplier || !contract) return;

  const { id, name } = supplier;

  fn({
    supplierName: name,
    supplierId: id,
    category: contract.service.category.name,
    monthlyCost: subscription.cost.amount.toString(),
  });
};
