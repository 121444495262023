import { LocalizedMessage } from '@client/internationalization';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  subheading: {
    color: theme.colors.textOn.surface,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface ContactUsButtonProps {
  onNavigateToContactUs(): void;
}

export const ContactUsButton: React.FunctionComponent<ContactUsButtonProps> = ({ onNavigateToContactUs }) => {
  const classes = useStyles();

  return (
    <Card variant="border" className={classes.container}>
      <Subheading2 className={classes.subheading}>
        <LocalizedMessage id="contactUsTitle" />
      </Subheading2>
      <div className={classes.buttonContainer}>
        <PrimaryButton
          data-test="zendesk-contact-us-button"
          label={<LocalizedMessage id="contactUsButton" />}
          onClick={onNavigateToContactUs}
        />
      </div>
    </Card>
  );
};
