import { PlatformName } from '@client/models/platform';
import { defaultWebBrowserNativeImplementation } from '@client/modules/app-integration/default-web-browser-native-app-integration';
import type { AppIntegrationImplementation } from '@client/modules/app-integration/index';
import { setAppIntegrationImplementation } from '@client/modules/app-integration/index';
import { appIntegration as sb1AppIntegration } from '@client/modules/app-integration/sb1-app-integration';
import { getAndSetupStandaloneMobileAppIntegration } from '@client/modules/app-integration/standalone/standalone-app-integration';
import { getAndSetupSwedbankAppIntegration } from '@client/modules/app-integration/swedbank-app-integration';
import { appIntegration as defaultNativeAppIntegration } from '@client/modules/app-integration/webview-integration/default-native-app-integration';
import type { History } from 'history';

export function startAppIntegration(platformName: PlatformName, history: History) {
  const appIntegrationFunction = getAppIntegration(platformName);
  setAppIntegrationImplementation(appIntegrationFunction(history));
}

function getAppIntegration(platformName: PlatformName): (history: History) => AppIntegrationImplementation {
  switch (platformName) {
    case PlatformName.Standalone:
      return getAndSetupStandaloneMobileAppIntegration;
    case PlatformName.Swedbank:
      return getAndSetupSwedbankAppIntegration;
    case PlatformName.Sb1:
      return () => sb1AppIntegration;
    case PlatformName.Steven:
      return () => defaultNativeAppIntegration;
    default:
      return defaultWebBrowserNativeImplementation;
  }
}
