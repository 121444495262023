import styles from '@client/modules/overview/OverviewPage/components/SubscriptionList/styles.scss';
import { withGetSubscriptionOverviewMessage } from '@client/modules/overview/OverviewPage/components/SubscriptionList/SubscriptionListItem/SubscriptionChanged/subscriptionChangeUtils';
import type { UserMessage } from '@client/modules/subscription/types';
import type { OrderedSubscription } from '@client/modules/switch/ordered-subscription/models';
import RippleButton from '@client/shared-components/RippleButton';
import { SupplierLogo } from '@client/shared-components/SupplierLogo';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import type { FC } from 'react';
import React from 'react';
import {
  ContractInformation,
  ContractType,
} from '../SubscriptionListItemWithActions/ContractInformation/ContractInformation';

export interface Props {
  orderedSubscriptions: OrderedSubscription[];
  onOrderedSubscriptionClick(orderId: string): void;
  getSubscriptionOverviewMessage(userMessage: UserMessage): any;
}

export const OrderedSubscriptionsListComponent: FC<Props> = ({ orderedSubscriptions, onOrderedSubscriptionClick }) => {
  const orderedSubscriptionsComponents = orderedSubscriptions.map((orderedSubscription) => {
    const orderedSubscriptionCategoryItems = orderedSubscription.product.category.map((category, i) => {
      return (
        <div key={`${orderedSubscription.orderId}-${i}`}>
          <Divider style={{ margin: '0 8px' }} />
          <ListItem
            onClick={() => onOrderedSubscriptionClick(orderedSubscription.orderId)}
            button
            style={{ padding: '12px 8px 12px 12px' }}
          >
            <ContractInformation
              serviceName={category.name}
              serviceText={category.name}
              contractType={ContractType.Ordered}
            />
          </ListItem>
        </div>
      );
    });

    return (
      <Card key={orderedSubscription.orderId} style={{ marginBottom: '16px' }} noPadding>
        <div className={styles.subscriptionWrapper}>
          <RippleButton
            onClick={() => onOrderedSubscriptionClick(orderedSubscription.orderId)}
            style={{ borderRadius: '8px' }}
          >
            <div className={styles['subscription-info']}>
              <div>
                <SupplierLogo supplier={orderedSubscription.product.supplierInformation} />
              </div>
            </div>
          </RippleButton>
          <List disablePadding>{orderedSubscriptionCategoryItems}</List>
        </div>
      </Card>
    );
  });

  return <div>{orderedSubscriptionsComponents}</div>;
};

export const OrderedSubscriptionsList = withGetSubscriptionOverviewMessage(OrderedSubscriptionsListComponent);
