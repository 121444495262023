import type { WithLocalizationContextType } from '@client/internationalization';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { AmountWithCurrency } from '@client/models/subscription';
import { Currency } from '@client/models/subscription';
import type { AppFeatureToggles } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { minHeightContainer, secondaryColor } from '@client/modules/onboarding-overview-drawer/utils';
import {
  selectorSubscriptions,
  selectorTotalCostPerInterval,
  selectorTotalCostPerIntervalResolved,
} from '@client/modules/overview/duck';
import type { TotalCost } from '@client/modules/overview/model';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_ONBOARDING_ACTION_DRAWER } from '@client/tracking/mixpanel-constants';
import { useAppTheme } from '@client/utils/hooks/use-app-theme';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import { isNil } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.scss';

export const SyncDoneSlideComponent: React.FunctionComponent = (
  // eslint-disable-next-line no-empty-pattern
  {},
  { localizeCostWithCurrency }: WithLocalizationContextType
) => {
  const { colors, themeImages } = useAppTheme();
  const [monthlyCost, setMonthlyCost] = useState<AmountWithCurrency>({ amount: 0, currency: Currency.SEK });
  const userSubscriptions = useSelector(selectorSubscriptions);
  const features: AppFeatureToggles = useSelector(selectorFeatures);
  const unresolvedSubscriptionGuidesEnabled = features.useUnresolvedSubscriptionGuide;

  const selector = unresolvedSubscriptionGuidesEnabled
    ? selectorTotalCostPerIntervalResolved
    : selectorTotalCostPerInterval;

  const costsPerIntervals = useSelector(selector);

  const highlightColor = secondaryColor(features.theme, colors.action.secondary);

  useMountEffect(() => {
    getMixpanel().track(EventNames.VIEWED_GUIDE, {
      Feature: FEATURE_ONBOARDING_ACTION_DRAWER,
      Page: 'Introduction',
    });

    const costPerMonth = costsPerIntervals.find(
      (costPerUnit: TotalCost) => costPerUnit.unit.amount === 1 && costPerUnit.unit.unit === 'month'
    );

    if (!isNil(costPerMonth) && !isNil(costPerMonth.cost)) {
      setMonthlyCost(costPerMonth.cost);
    }
  });

  return (
    <div className={minHeightContainer(features.theme)}>
      <div className={[styles.centeredContainer, styles.imageSpacing].join(' ')}>
        <img alt={'OK icon'} src={themeImages.onboardingDrawer.okHand} />
      </div>
      <div className={[styles.centeredContainer, styles.titleSpacing].join(' ')}>
        <Headline2>
          <LocalizedMessage id={'allDoneTitle'} />
        </Headline2>
      </div>
      <div className={[styles.centeredContainer, styles.subscriptionsSpacing].join(' ')}>
        <div className={styles.subscriptionsText}>
          <Subheading1>
            <LocalizedMessage id={'weFoundStart'} />
          </Subheading1>
          <Headline1 style={{ color: highlightColor, margin: '0 4px' }} data-test="onboarding-drawer-subscriptions">
            {userSubscriptions?.length || 0}
          </Headline1>
          <Subheading1>
            <LocalizedMessage id={'weFoundEnd'} />
          </Subheading1>
        </div>
      </div>
      <div className={styles.centeredContainer}>
        <Caption>
          <LocalizedMessage id={'average'} />
        </Caption>
      </div>
      {monthlyCost.amount > 0 && (
        <>
          <div className={[styles.centeredContainer, styles.monthlyCostTitleSpacing].join(' ')}>
            <Subheading1>
              <LocalizedMessage id={'monthlyTotal'} />
            </Subheading1>
          </div>
          <div className={styles.centeredContainer}>
            <Headline1 style={{ color: highlightColor }} data-test="onboarding-drawer-monthly-cost">
              {localizeCostWithCurrency(monthlyCost)}
            </Headline1>
          </div>
        </>
      )}
    </div>
  );
};

SyncDoneSlideComponent.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
};

export const SyncDoneSlide = withLocalization('overview/OnboardingDrawer')(SyncDoneSlideComponent);
