import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import type { FC } from 'react';
import React from 'react';
import styles from './styles.scss';

interface Props {
  title: React.ReactNode;
  body: React.ReactNode;
  'data-test'?: string;
  settingsItemClicked(): void;
}

export const SubscriptionSettingsItem: FC<Props> = ({ title, body, settingsItemClicked, ...props }) => {
  const { colors } = useTheme();

  return (
    <Card className={styles.settingsCard} onClick={settingsItemClicked} data-test={props['data-test']}>
      <div>
        <Subheading2>{title}</Subheading2>
        <Body color={colors.textOn.surfaceSubdued} style={{ marginTop: '4px' }}>
          {body}
        </Body>
      </div>
      <div className={styles.chevron}>
        <ChevronRightIcon nativeColor={colors.textOn.surfaceSubdued} />
      </div>
    </Card>
  );
};
