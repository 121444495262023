import { LocalizedMessage } from '@client/internationalization';
import { MessageToUser } from '@client/modules/cancellation/CancellationOutcome/components/MessageToUser';
import { makeStyles } from '@material-ui/core';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles({
  sectionMargin: {
    margin: '0 0 8px',
  },
  content: {
    margin: '8px 16px',
  },
});

interface Props {
  serviceProviderName: string;
  bindingTimeEndsAt?: string;
  messageFromProvider?: string;
}

export const BindingTimeContent: FC<Props> = (
  { serviceProviderName, messageFromProvider, bindingTimeEndsAt },
  { localizeDate }
) => {
  const classes = useStyles();

  return (
    <div className={classes.sectionMargin}>
      <div className={classes.content}>
        {bindingTimeEndsAt ? (
          <>
            <Body>
              <LocalizedMessage
                id="bindingTime"
                values={{ supplierName: serviceProviderName, endDate: localizeDate(bindingTimeEndsAt) }}
              />
            </Body>
            <br />
            <Body>
              <LocalizedMessage id="weWillRemindYou" />
            </Body>
          </>
        ) : (
          <Body>
            <LocalizedMessage id="bindingTimeNoDate" values={{ supplierName: serviceProviderName }} />
          </Body>
        )}
      </div>
      {messageFromProvider && (
        <MessageToUser messageToUser={messageFromProvider} serviceProviderName={serviceProviderName} />
      )}
    </div>
  );
};

BindingTimeContent.contextTypes = {
  localizeDate: PropTypes.func.isRequired,
  localizeCostWithCurrency: PropTypes.func.isRequired,
};
