import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { RemoveSubscriptionDialog } from '@client/modules/subscription/SubscriptionSettingsPage/components/RemoveSubscriptionDialog';
import { SubscriptionSettingsItem } from '@client/modules/subscription/SubscriptionSettingsPage/components/SubscriptionSettingsItem';
import type { Subscription } from '@client/modules/subscription/types';
import { contractDetailsPage, subscriptionPage } from '@client/routes';
import { ContractHeaderDeprecated } from '@minna-technologies/minna-ui/components/ContractHeaderDeprecated';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import type { History } from 'history';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';

interface SubscriptionSettingsPageComponentInnerProps {
  history: History;
  subscription: Subscription;
  editServicesEnabled: boolean;
  changeCostEnabled: boolean;
  removeFromOverviewEnabled: boolean;
  dialogOpen: boolean;
  handleRemoveFromOverviewClicked(reason: string): void;
  setDialogOpen(open: boolean): void;
  handleChangeServicesClicked(): void;
  handleEditCostClicked(): void;
  removeSubscriptionClicked(): void;
}

const SubscriptionSettingsPageComponentInner: React.FunctionComponent<SubscriptionSettingsPageComponentInnerProps> = (
  {
    handleChangeServicesClicked,
    handleEditCostClicked,
    removeSubscriptionClicked,
    changeCostEnabled,
    removeFromOverviewEnabled,
    editServicesEnabled,
    history,
    handleRemoveFromOverviewClicked,
    setDialogOpen,
    dialogOpen,
    subscription,
  },
  { localizeMessage }
) => {
  const logoUrl = subscription.supplier.logoUrl;
  const serviceProvider = subscription.supplier?.name;

  const editServicesCard = (
    <SubscriptionSettingsItem
      settingsItemClicked={handleChangeServicesClicked}
      title={<LocalizedMessage id="updateServicesTitle" />}
      body={<LocalizedMessage id="updateServicesBody" values={{ provider: subscription.supplier.name }} />}
      data-test="subscription-update-services"
    />
  );
  const changeCostCard = (
    <SubscriptionSettingsItem
      settingsItemClicked={handleEditCostClicked}
      title={<LocalizedMessage id="editCostTitle" />}
      body={<LocalizedMessage id="editCostBody" />}
      data-test="subscription-update-cost"
    />
  );
  const removeFromOverviewCard = (
    <SubscriptionSettingsItem
      settingsItemClicked={removeSubscriptionClicked}
      title={<LocalizedMessage id="removeTitle" />}
      body={<LocalizedMessage id="deleteBody" />}
      data-test="subscription-remove"
    />
  );

  const customGoBack = () =>
    history.push(
      subscription.contracts.length > 1
        ? subscriptionPage(subscription.id)
        : contractDetailsPage(subscription.id, subscription.contracts[0].id)
    );

  return (
    <MultiDeviceLayout
      fullWidthContent={
        <ContractHeaderDeprecated
          providerLogo={logoUrl ?? ''}
          providerName={serviceProvider ?? ''}
          subHeading={serviceProvider ?? ''}
          title={localizeMessage('settingsTitle')}
        />
      }
      footer={<FooterNavigation onBackClick={customGoBack} />}
      hasTopNavigation={false}
    >
      <div className={styles.content}>
        {changeCostEnabled && changeCostCard}
        {removeFromOverviewEnabled && removeFromOverviewCard}
        {editServicesEnabled && editServicesCard}
      </div>
      <RemoveSubscriptionDialog
        goBack={() => setDialogOpen(false)}
        removeSubscription={handleRemoveFromOverviewClicked}
        open={dialogOpen}
      />
    </MultiDeviceLayout>
  );
};

SubscriptionSettingsPageComponentInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const SubscriptionSettingsPageComponent = withLocalization('contract/SettingsPageLolipop')(
  SubscriptionSettingsPageComponentInner
);
