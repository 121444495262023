import { CancellationMethodType } from '@client/models/service';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { selectorSubscriptions } from '@client/modules/overview/duck';
import { isBindingPeriodEndingSoon } from '@client/modules/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/utils';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_OVERVIEW } from '@client/tracking/mixpanel-constants';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import { useZendesk } from '@client/utils/hooks/use-zendesk';
import { LABEL_CANCELLATIONS } from '@client/zendesk';
import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { mixpanelClickButtonEvent } from '../mixpanelEvents';
import { QuickActionsCancelPageComponent } from './component';

const pageName = 'Cancel quick action';

interface CancellableSubscriptions {
  list: Subscription[];
  count: number;
}

export interface Props {
  onBackClick(): void;
  onAddManualSubscription(): void;
  onSubscriptionClick(subscription: Subscription): void;
  onContractCancelClick(subscription: Subscription, contract: Contract): void;
}

export const QuickActionsCancelPage: FC<Props> = ({
  onBackClick,
  onAddManualSubscription,
  onSubscriptionClick,
  onContractCancelClick,
}) => {
  useMountEffect(() => {
    getMixpanel().track(EventNames.VIEWED_PAGE, {
      Feature: FEATURE_OVERVIEW,
      Page: pageName,
      'Active contracts': cancellableSubscriptions.count,
    });
  });
  const features = useSelector(selectorFeatures);
  const onHelpClick = useZendesk(LABEL_CANCELLATIONS);
  const cancellableSubscriptions: CancellableSubscriptions = useSelector(selectorSubscriptions).reduce(
    (acc: CancellableSubscriptions, subscription: Subscription) => {
      const tempSubscription: Subscription = {
        ...subscription,
        contracts: subscription.contracts.filter(
          (c) =>
            c.terminable &&
            isBindingPeriodEndingSoon(c) &&
            c.cancellationMethod.type !== CancellationMethodType.BlockPayment
        ),
      };
      if (tempSubscription.contracts.length) {
        return { list: [...acc.list, tempSubscription], count: acc.count + tempSubscription.contracts.length };
      }

      return acc;
    },
    { list: [], count: 0 }
  );

  const handleSubscriptionClick = (subscription: Subscription) => {
    mixpanelClickButtonEvent(pageName, 'View subscription', {
      'Supplier ID': subscription.supplier.id,
      'Supplier Name': subscription.supplier.name,
    });
    onSubscriptionClick(subscription);
  };
  const handleContractClick = (subscription: Subscription, contract: Contract) => {
    onContractCancelClick(subscription, contract);
  };
  const handleAddManualSubscriptionClick = () => {
    mixpanelClickButtonEvent(pageName, 'Add subscriptions');
    onAddManualSubscription();
  };

  return (
    <QuickActionsCancelPageComponent
      cancellableSubscriptions={cancellableSubscriptions}
      handleAddManualSubscriptionClick={handleAddManualSubscriptionClick}
      handleContractClick={handleContractClick}
      handleSubscriptionClick={handleSubscriptionClick}
      onBackClick={onBackClick}
      onContractCancelClick={onContractCancelClick}
      onHelpClick={features.helpCenter?.enabled ? onHelpClick : undefined}
    />
  );
};
