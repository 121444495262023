//@ts-ignore
import { loadingPageWhileLoading, onEnter, onExit, onPropsChanged } from '@client/containers/container-helpers';
import type { AppContext } from '@client/modules/app-context';
import { AppType } from '@client/modules/app-context/constants';
import { getIngBankContactUrl } from '@client/utils/support';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import { withAppContext } from '../../../../app-context/react';
import { navigateToContactUs } from '../../../ducks';
import {
  fetchArticle,
  filterArticlesBySection,
  selectorArticle,
  selectorLoadingArticle,
  selectorLoadingArticles,
} from '../../../ducks/zendesk-help-center';
import { clearZendeskArticleState } from '../../../ducks/zendesk-help-center/zendesk-help-center-actions';
import { DisplayArticle } from './component';

const mapStateToProps = (state: any) => {
  return {
    article: selectorArticle(state),
    loadingArticle: selectorLoadingArticle(state),
    loadingArticles: selectorLoadingArticles(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { appContext }: { appContext: AppContext }) => ({
  onNavigateToContactUs: () => {
    // TODO: use feature toggle when adding full support for 'contact your bank' in Zendesk
    if (appContext.appType === AppType.ING) {
      window.open(getIngBankContactUrl(appContext.locale), '_blank', 'noopener noreferrer external nofollow');
    } else {
      dispatch(navigateToContactUs());
    }
  },
});

export const Index = compose<any, any>(
  withAppContext,
  connect(mapStateToProps, mapDispatchToProps),
  onEnter(({ appContext, articleId, sectionId }: any, { store }: any) => {
    store.dispatch(fetchArticle(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, articleId));
    store.dispatch(
      filterArticlesBySection(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, sectionId)
    );
  }),
  onPropsChanged(
    (previousProp: any, nextProps: any) => previousProp.articleId !== nextProps.articleId,
    ({ appContext, articleId, sectionId }: any, { store }: any) => {
      store.dispatch(fetchArticle(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, articleId));
      store.dispatch(
        filterArticlesBySection(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, sectionId)
      );
    }
  ),
  onExit((props: any, { store: { dispatch } }: any) => {
    dispatch(clearZendeskArticleState());
  }),
  loadingPageWhileLoading((p: any) => !p.article || p.loadingArticle || p.loadingArticles)
)(DisplayArticle);

Index.propTypes = {
  articleId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  onBackClick: PropTypes.func.isRequired,
};
