import type { CategoryName } from '@client/constants/categories';
import { CHARITY } from '@client/constants/categories';
import { useLocalization } from '@client/internationalization';
import type { AmountWithCurrency } from '@client/models/subscription';
import { CancellationReasonsDialog } from '@client/modules/cancellation/CancellationReasonsDialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { StarRating } from '@minna-technologies/minna-ui/components/StarRating';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { PaperPlaneIllustration } from '@minna-technologies/minna-ui/illustrations/PaperPlane';
import { selectorAppContext, selectorFeatures } from '@client/modules/app-context/duck';
import { useSelector } from 'react-redux';
import { AppIntegration } from '@client/modules/app-integration';
import React, { useCallback, useState } from 'react';
import { useDocumentHidden } from '@client/utils/hooks/use-document-hidden';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import { trackClickedCancelSuccessCloseButton, trackViewedSuccessScreen } from '@client/tracking/mixpanel-events';
import type { CancellationMethodType } from '@client/models/service';
import type { SupplierService, SupplierWithServices } from '@client/models/supplier';
import { trackCancellationSuccessfulButtonClick } from '@client/modules/cancellation/mixpanel-events';
import { TrackingButtonName } from '@client/tracking/mixpanel-constants';
import {
  DELAY_MS_BEFORE_OPEN_UPDATE_BANK_APP_MODAL,
  shouldOpenUpdateBankAppModal,
} from '@minna-components/UpdateBankAppModal/utils';
import { UpdateBankAppModal } from '@minna-components/UpdateBankAppModal';
import {
  trackClosedUpdateBankAppModal,
  trackViewedUpdateBankAppModal,
} from '@minna-components/UpdateBankAppModal/mixpanel-events';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { YouSavedComponent } from '@minna-components/YouSavedComponent';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import { tabletMinWidth } from '@client/styles/responsive-helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.colors.base.surface,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100vh',
    [theme.breakpoints.up(tabletMinWidth)]: {
      height: 'calc(100vh - 56px)',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '32px',
    marginTop: '48px',
    width: '100%',
    height: '100%',
  },
  titleAndText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 24px',
    rowGap: '16px',
  },
  buttonsContainer: {
    borderTop: `2px solid ${theme.colors.base.background}`,
    padding: '24px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    heigh: '142px',
    width: '142px',
  },
  rating: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '8px',
    justifyContent: 'flex-end',
  },
  marginBottom: {
    marginBottom: '8px',
  },
}));

interface CancellationSentPageProps {
  amountSavedPerYear?: AmountWithCurrency;
  contract?: Contract;
  merchant: SupplierWithServices;
  service: SupplierService;
  categoryName: CategoryName;
  subscriptionMethod: CancellationMethodType;
  subscription?: Subscription;
  saveDeskEnabled: boolean;

  onRate(newRating: number): void;
  onRedirectUser?(): void;
}

export const CancellationSentPage = ({
  amountSavedPerYear,
  contract,
  merchant,
  service,
  onRate,
  onRedirectUser,
  categoryName,
  subscriptionMethod,
  subscription,
  saveDeskEnabled,
}: CancellationSentPageProps): JSX.Element => {
  const { colors } = useTheme();
  const classes = useStyles();
  const { localizeMessage } = useLocalization('cancellation/CancellationSentPage');
  const appContext = useSelector(selectorAppContext);
  const features = useSelector(selectorFeatures);

  const merchantId = merchant?.id;
  const merchantName = merchant?.name;
  const serviceId = service?.id;
  const serviceName = service?.name;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [currentRating, onRatingChange] = useState(0);
  const userWentAway = useDocumentHidden();

  const onClick = () => {
    trackClickedCancelSuccessCloseButton(
      merchantName,
      merchantId,
      subscriptionMethod,
      serviceName,
      serviceId,
      categoryName,
      amountSavedPerYear?.amount
    );
    onRate(currentRating);
    if (onRedirectUser) onRedirectUser();
  };

  const [openUpdateBankApp, setOpenUpdateBankApp] = React.useState<boolean>(false);
  const maybeOpenUpdateBankApp = useCallback(() => {
    if (shouldOpenUpdateBankAppModal(appContext.appType) && !userWentAway) {
      trackViewedUpdateBankAppModal(
        merchantId ?? '',
        merchantName ?? '',
        serviceId ?? '',
        serviceName ?? '',
        service?.category.name ?? '',
        appContext.market,
        appContext.appType,
        service?.cancellationMethod.type,
        subscription?.source ?? ''
      );
      setOpenUpdateBankApp(true);
    }
    setIsLoading(false);
  }, [
    appContext.appType,
    appContext.market,
    merchantId,
    merchantName,
    service?.cancellationMethod.type,
    service?.category.name,
    serviceId,
    serviceName,
    subscription?.source,
    userWentAway,
  ]);
  const closeUpdateBankApp = () => {
    trackClosedUpdateBankAppModal(
      merchantId ?? '',
      merchantName ?? '',
      serviceId ?? '',
      serviceName ?? '',
      service?.category.name ?? '',
      appContext.market,
      appContext.appType,
      service?.cancellationMethod.type,
      subscription?.source ?? ''
    );
    setOpenUpdateBankApp(false);
    if (onRedirectUser) onRedirectUser();
  };

  const onClickCancelAndSaveButton = (event?: any) => {
    trackCancellationSuccessfulButtonClick(
      TrackingButtonName.START_SAVING,
      merchantId ?? '',
      merchantName ?? '',
      serviceId ?? '',
      serviceName ?? '',
      service?.category.name ?? '',
      appContext.market,
      appContext.appType,
      service?.cancellationMethod.type,
      amountSavedPerYear?.amount
    );
    onRate(currentRating);
    const internalUrl: string | undefined = features.subscriptionFeatures.cancelAndSave.urlString;
    if (internalUrl) {
      setIsLoading(true);
      AppIntegration.openInternalPage(internalUrl, event);
      setTimeout(maybeOpenUpdateBankApp, DELAY_MS_BEFORE_OPEN_UPDATE_BANK_APP_MODAL);
    }
  };

  useMountEffect(() => {
    trackViewedSuccessScreen(
      merchantName,
      merchantId,
      subscriptionMethod,
      serviceName,
      serviceId,
      categoryName,
      contract?.id,
      subscription?.source
    );
  });

  return (
    <MultiDeviceLayout backgroundColor={colors.base.surface}>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.imageContainer}>
            <PaperPlaneIllustration className={classes.image} />
          </div>
          <div className={classes.titleAndText}>
            <Headline1 align="center" color={colors.textOn.surface}>
              {localizeMessage('cancellationSentTitle')}
            </Headline1>
            <Body align="center">{localizeMessage('terminationSuccessMessage2', { supplierName: merchantName })}</Body>
          </div>
          {amountSavedPerYear && amountSavedPerYear.amount > 0 && categoryName !== CHARITY && (
            <YouSavedComponent amountSaved={amountSavedPerYear} />
          )}
          <div className={classes.rating}>
            <Body align="center" className={classes.marginBottom}>
              {localizeMessage('terminationRatingDescription')}
            </Body>
            <StarRating currentRating={currentRating} onRatingChange={onRatingChange} />
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          {features.subscriptionFeatures.cancelAndSave.enabled && (
            <PrimaryButton
              label={localizeMessage('startNewSavingsButton')}
              fullWidth
              onClick={onClickCancelAndSaveButton}
              loading={isLoading}
              data-test="cancel-and-save-button"
            />
          )}
          <SecondaryButton
            onClick={onClick}
            label={localizeMessage('cancelationSentCloseButton')}
            data-test="cancellation-sent-close-button"
            fullWidth
            disabled={isLoading}
          />
        </div>
      </div>
      <UpdateBankAppModal dialogOpen={openUpdateBankApp} closeDialog={closeUpdateBankApp} />
      <CancellationReasonsDialog
        categoryName={categoryName}
        providerName={merchantName}
        saveDeskEnabled={saveDeskEnabled}
      />
    </MultiDeviceLayout>
  );
};
