import makeStyles from '@material-ui/core/styles/makeStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardRowLink } from '@minna-technologies/minna-ui/components/CardRowLink';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import React from 'react';
import { ARTICLES_FILTERED_BY_CATEGORY } from '../../../ducks/zendesk-help-center';
import { ZendeskSubHeader } from '../../../ZendeskSubHeader';
import { Index } from '../ArticlesResult';

const useStyles = makeStyles((theme) => ({
  displayCategoryCard: {
    padding: theme.spacing(2, 0, 1),
  },
  displayCategorySubheader: {
    padding: theme.spacing(0, 2),
  },
  articlesDivider: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
}));

interface ZendeskCategorySection {
  id: number;
  name: string;
}

interface ZendeskCategory {
  id: number;
  name: string;
  sections: ZendeskCategorySection[];
}

interface DisplayCategoryProps {
  category: ZendeskCategory;
  onNavigateToSection(): void;
}

export const DisplayCategory: React.FC<DisplayCategoryProps> = ({ category, onNavigateToSection }) => {
  const { sections, id } = category;
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.displayCategoryCard} variant="border">
        <div className={classes.displayCategorySubheader}>
          <ZendeskSubHeader title={category.name} />
        </div>
        <Sections sections={sections} onNavigateToSection={onNavigateToSection} categoryId={id} />
      </Card>
      <Divider className={classes.articlesDivider} />
      <Index articlesFilteredBy={ARTICLES_FILTERED_BY_CATEGORY} />
    </div>
  );
};

interface SectionProps {
  categoryId: number;
  sections: any[];
  onNavigateToSection(categoryId: number, sectionId: string): void;
}

const Sections: React.FC<SectionProps> = ({ categoryId, sections, onNavigateToSection }) => {
  const sectionsLength = sections.length;

  return (
    <>
      {sections.map((section: any, index: number) => (
        <div key={section.id}>
          <CardRowLink onClick={() => onNavigateToSection(categoryId, section.id)} body={section.name} />
          {index !== sectionsLength - 1 && <Divider slim />}
        </div>
      ))}
    </>
  );
};
