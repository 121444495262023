import { searchSuppliers } from '@client/assets/js/search-suppliers';
import { LocalizedMessage } from '@client/internationalization';
import type { SupplierId, SupplierWithServices } from '@client/models/supplier';
import { eqIgnoreCase } from '@client/utils/utils';
import { MenuItem, MenuList } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { SearchBar } from '@minna-technologies/minna-ui/components/Inputs/SearchBar';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { AddIcon } from '@minna-technologies/minna-ui/icons/Add';
import { some, trim } from 'lodash/fp';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import take from 'lodash/fp/take';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useState } from 'react';

const useStyles = makeStyles({
  menu: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
  },

  menuItem: {
    margin: '2px 0',
    minHeight: '36px',
    padding: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  menuItemAdd: {
    display: 'flex',
    flewDirection: 'column',
    minHeight: '30px',
  },

  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  searchAlternativeLogoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '60px',
    margin: '8px 16px 8px 8px',
  },

  searchAlternativeLogo: {
    maxWidth: '60px',
    maxHeight: '40px',
    margin: 'auto',
  },

  searchAlternativeText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
  },

  missingLogo: {
    width: '60px',
  },

  addIcon: {
    marginRight: '8px',
  },
});

interface Props {
  merchants: SupplierWithServices[];
  defaultValue?: string;
  onSelect(merchantName?: string, serviceId?: string): void;
}

export const MerchantSearchBar: FC<Props> = ({ merchants, onSelect, defaultValue }, { localizeMessage }) => {
  const { colors } = useTheme();
  const classes = useStyles();

  const [hasFocus, setHasFocus] = useState(false);
  const [matchingMerchants, setMatchingMerchants] = useState<SupplierWithServices[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleUpdateInput = (inputValue: string) => {
    const trimmedInputValue = trim(inputValue);

    if (!hasFocus) {
      setHasFocus(true);
    }

    setSearchTerm(trimmedInputValue);

    const matchingMerchants = searchSuppliers(trimmedInputValue, merchants);
    setMatchingMerchants(matchingMerchants.length >= 3 ? take(3, matchingMerchants) : matchingMerchants);
  };

  const handleSelected = (merchantName: string, merchantId: SupplierId): void => {
    setHasFocus(false);
    onSelect(merchantName, merchantId);
  };

  const handleAdd = (merchantName: string): void => {
    setHasFocus(false);
    setSearchTerm(merchantName);

    if (isEmpty(merchantName)) {
      onSelect('', '');
    } else {
      onSelect(merchantName, '');
    }
  };

  const handleFocus = (): void => {
    setHasFocus(true);
  };

  const hasLogo = (logo?: string): boolean => !isNil(logo) && !isEmpty(logo);

  const searchInput = (
    <SearchBar
      name="merchantName"
      data-test={'manual-subscription-input'}
      defaultValue={defaultValue}
      placeholder={localizeMessage('chooseSupplier')}
      fullWidth
      inputProps={{
        onFocus: handleFocus,
      }}
      onChange={(e) => handleUpdateInput(e.target.value)}
    />
  );

  const logo = (merchant: SupplierWithServices) => (
    <div className={classes.searchAlternativeLogoWrapper}>
      {hasLogo(merchant.logoUrl) ? (
        <img className={classes.searchAlternativeLogo} src={merchant.logoUrl} />
      ) : (
        <div className={classes.missingLogo} />
      )}
    </div>
  );

  const menuItems = (merchants: SupplierWithServices[]) =>
    merchants.map((merchant, i) => (
      <MenuItem
        key={i}
        className={classes.menuItem}
        disableRipple
        onClick={() => handleSelected(merchant.name, merchant.id)}
      >
        <div className={classes.flexContainer}>
          {logo(merchant)}
          <Body color={colors.textOn.surfaceSubdued} className={classes.searchAlternativeText}>
            {merchant.name}
          </Body>
        </div>
      </MenuItem>
    ));

  const addMenuItem = (searchTerm: string) => {
    const trimmedSearchTerm = trim(searchTerm);
    const exactNameMatch = (merchant: SupplierWithServices): boolean => eqIgnoreCase(trimmedSearchTerm)(merchant.name);

    return trimmedSearchTerm.length > 0 && !some(exactNameMatch, merchants) ? (
      <MenuItem
        className={`${classes.menuItem} ${classes.menuItemAdd}`}
        data-test={'manual-exact-search'}
        disableRipple
        onClick={() => handleAdd(searchTerm)}
      >
        <AddIcon nativeColor={colors.textOn.surfaceSubdued} className={classes.addIcon} />
        <Body color={colors.textOn.surfaceSubdued}>
          <LocalizedMessage id="addNewPrefix" />
          {` ${searchTerm}`}
        </Body>
      </MenuItem>
    ) : null;
  };

  return (
    <div>
      {searchInput}
      {hasFocus && !isEmpty(searchTerm) && (
        <Card>
          <MenuList className={classes.menu}>
            {menuItems(matchingMerchants)}
            {addMenuItem(searchTerm)}
          </MenuList>
        </Card>
      )}
    </div>
  );
};

MerchantSearchBar.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
