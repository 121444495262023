import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { TermsSection } from '@client/modules/cancellation/CancellationFormPage/components/CancellationTermsSection';
import type { ContractId } from '@client/modules/subscription/types';
import { TrackingButtonLocation } from '@client/tracking/mixpanel-constants';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardHeader } from '@minna-technologies/minna-ui/components/Card/CardHeader';
import { Checkbox } from '@minna-technologies/minna-ui/components/Inputs/Checkbox';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { LoaDocumentContainer } from '../LoaDocument';

interface ConfirmSectionProps {
  contractId?: ContractId;
  supplierName: string;
  useCancellationTerms?: boolean;
  checkboxSigningDialogOpen: boolean;
  merchantId?: string;
  serviceId?: string;

  onValid(callback: () => void): void;
  onPreviewLoa(requestPreviewLoa: () => void): void;
  onSendCancellation(): void;
  setCheckboxSigningDialogOpen(checkboxSigningDialogOpen: boolean): void;
  clickedButtonMixpanelEvent(buttonName: string, buttonLocation: TrackingButtonLocation): void;
  viewCancellationTermsDialog(): void;
}

const useStyles = makeStyles(() => ({
  previewButton: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  confirmDialogDescriptionContainer: {
    marginBottom: '1rem',
  },
}));

const ConfirmSectionComponentInner: React.FunctionComponent<ConfirmSectionProps> = (
  {
    supplierName,
    onValid,
    contractId,
    onSendCancellation,
    useCancellationTerms = false,
    onPreviewLoa,
    checkboxSigningDialogOpen,
    setCheckboxSigningDialogOpen,
    clickedButtonMixpanelEvent,
    viewCancellationTermsDialog,
    merchantId,
    serviceId,
  },
  { localizeMessage }
) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const [isChecked, setChecked] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const clickedSendCancellationButton = () => {
    clickedButtonMixpanelEvent('Confirm cancellation', TrackingButtonLocation.BOTTOM_RIGHT);
    onValid(onSendCancellation);
  };
  const clickedCloseConfirmationButton = () => {
    clickedButtonMixpanelEvent('Close confirmation', TrackingButtonLocation.BOTTOM_LEFT);
    setCheckboxSigningDialogOpen(false);
  };
  const viewedCancellationTermsMixpanelEvent = () => {
    viewCancellationTermsDialog();
  };
  const toggleTermsDialog = () => {
    setCheckboxSigningDialogOpen(!checkboxSigningDialogOpen);
    setTermsDialogOpen(!termsDialogOpen);
  };

  const confirmDialogDescription = useCancellationTerms ? (
    <div className={classes.confirmDialogDescriptionContainer}>
      <Body>
        <LocalizedMessage id="confirmCancellationDialogDescriptionWithTermsParagraph1" values={{ supplierName }} />
      </Body>
      <br />
      <Body>
        <LocalizedMessage id="confirmCancellationDialogDescriptionWithTermsBeforeLink" />
        &nbsp;
        <Body color={colors.action.primary} variant="link" onClick={toggleTermsDialog}>
          <LocalizedMessage id="confirmCancellationDialogDescriptionWithTermsLink" />
        </Body>
        &nbsp;
        <LocalizedMessage id="confirmCancellationDialogDescriptionWithTermsAfterLink" values={{ supplierName }} />
      </Body>
    </div>
  ) : (
    <Body color={colors.textOn.surfaceSubdued} style={{ marginBottom: '16px' }}>
      <LocalizedMessage id="confirmCancellationDialogDescription" values={{ supplierName }} />
    </Body>
  );

  const modal = (
    <Modal
      title={<LocalizedMessage id="confirmCancellationDialogTitle" />}
      open={checkboxSigningDialogOpen}
      onClose={() => setCheckboxSigningDialogOpen(false)}
      buttonLayout="horizontal"
      button1={
        <PrimaryButton
          label={<LocalizedMessage id="confirmButton" />}
          onClick={clickedSendCancellationButton}
          disabled={!isChecked}
          data-test="dialog-primary-button"
        />
      }
      button2={
        <SecondaryButton label={<LocalizedMessage id="closeButton" />} onClick={clickedCloseConfirmationButton} />
      }
    >
      {confirmDialogDescription}
      <Checkbox
        checked={isChecked}
        value={isChecked}
        onChange={() => setChecked(!isChecked)}
        label={localizeMessage('checkBoxLabel', { supplierName })}
        data-test="permissionCheckbox"
      />
    </Modal>
  );

  return (
    <>
      <Card>
        <CardHeader title={<LocalizedMessage id="confirmCancellationTitle" />} />

        <Body>
          <LocalizedMessage id="confirmCancellationDescription" values={{ supplierName }} />
        </Body>

        <div className={classes.previewButton}>
          <LoaDocumentContainer
            onPreviewLoa={onPreviewLoa}
            contractId={contractId}
            merchantId={merchantId}
            serviceId={serviceId}
          />
        </div>

        {modal}
      </Card>
      {useCancellationTerms && (
        <TermsSection
          isOpen={termsDialogOpen}
          closeTermsDialog={toggleTermsDialog}
          trackViewedEvent={viewedCancellationTermsMixpanelEvent}
        />
      )}
    </>
  );
};

ConfirmSectionComponentInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const ConfirmSectionComponent = withLocalization('cancellation/CancellationFormPage/ConfirmSection')(
  ConfirmSectionComponentInner
);
