import * as CATEGORIES from '../constants/categories';
import type { Contract, Subscription } from '../modules/subscription/types';
import * as mobileMixpanel from '../modules/switch/mobile/mixPanelEvents';
import type { MobileProduct } from '../modules/switch/mobile/models';
import * as mixpanel from './mixpanel-events';
import * as swedbankAnalytics from './swedbank-analytics-events';

export function viewDashboardTrackingEvent(noOfActiveSubscriptions: number, noOfUnexploredImprovableContracts: number) {
  mixpanel.viewDashboardMixpanelEvent(noOfActiveSubscriptions, noOfUnexploredImprovableContracts);
  swedbankAnalytics.viewDashboardAnalyticsEvent();
}

export function viewProductTrackingEvent(
  isRecommendation: boolean,
  productName: string,
  supplierName: string,
  subscription?: Subscription,
  contract?: Contract,
  productRank?: number | string,
  amountSavedFirstYear?: number
) {
  mixpanel.viewProductMixpanelEvent(
    subscription,
    contract,
    productName,
    supplierName,
    amountSavedFirstYear,
    productRank,
    isRecommendation
  );
}

export function enterOrderFormPageTrackingEvent(
  productName: string,
  subscription?: Subscription,
  contract?: Contract,
  inFlow = true,
  productRank?: string | number
) {
  mixpanel.enterOrderFormPageMixpanelEvent(productName, subscription, contract, inFlow, productRank);
}

export function mobileIndependentOrderPlacedTrackingEvent(
  mobileProduct: MobileProduct,
  filter: any,
  productRank?: string | number
) {
  mobileMixpanel.mobileIndependentOrderPlacedMixpanelEvent(mobileProduct, filter, productRank);
  swedbankAnalytics.userRequestedIndependentOrderAnalyticsEvent(CATEGORIES.MOBILE, mobileProduct.serviceProvider.name);
}

export function sentCancellationOfContractFinishTrackingEvent(
  subscription: Subscription,
  amountSavedPerYear: number,
  contract?: Contract
) {
  mixpanel.sentCancellationOfContractFinishMixpanelEvent(subscription, amountSavedPerYear, contract);
  swedbankAnalytics.userRequestedCancellationAnalyticsEvent(subscription, amountSavedPerYear, contract);
}
