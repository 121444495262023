import React from 'react';
import Loadable from 'react-loadable';
import LoadingPage from '../../fetching/LoadingPage';

export const LazyLoadedChangePaymentRoute = Loadable<any, any>({
  loader: async () => import(/* webpackChunkName: "change-payment-route" */ './ChangePaymentRoute'),
  loading: LoadingPage,
  render(loaded, props) {
    const ChangePaymentRoute = loaded.ChangePaymentRoute;

    // @ts-ignore
    return <ChangePaymentRoute {...props} />;
  },
});
