import { useLocalization } from '@client/internationalization';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { selectorTotalCostPerInterval, selectorTotalCostPerIntervalResolved } from '@client/modules/overview/duck';
import type { TotalCost } from '@client/modules/overview/model';
import { IntervalToText } from '@client/modules/shared-components/interval-to-string';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import find from 'lodash/find';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  priceInterval: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    cursor: 'pointer',
    lineHeight: '16px',
    alignItems: 'baseline',
    marginTop: '4px',
  },

  price: {
    fontFeatureSettings: "'pnum' on, 'lnum' on",
    paddingRight: '8px',
    lineHeight: '16px',
  },

  interval: {
    lineHeight: '16px',
  },

  oldPaper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '24px 16px 16px 16px',
    backgroundColor: theme.colors.base.surface,
  },
}));

export const OverviewTotalCost: FC = () => {
  const classes = useStyles();
  const { colors } = useTheme();
  const { localizeMessage, localizeCostWithCurrency } = useLocalization('overview/OverviewPage');

  const features = useSelector(selectorFeatures);

  const [intervalIndex, setIntervalIndex] = useState(2);
  const unresolvedSubscriptionGuidesEnabled = features.useUnresolvedSubscriptionGuide;

  const selector = unresolvedSubscriptionGuidesEnabled
    ? selectorTotalCostPerIntervalResolved
    : selectorTotalCostPerInterval;

  const totalCostPerInterval = useSelector(selector);

  const intervals = [
    { amount: 1, unit: 'day' },
    { amount: 1, unit: 'week' },
    { amount: 1, unit: 'month' },
    { amount: 3, unit: 'month' },
    { amount: 1, unit: 'year' },
  ];

  const handleIntervalChange = () => {
    const newIntervalIndex = intervalIndex < intervals.length - 1 ? intervalIndex + 1 : 0;
    setIntervalIndex(newIntervalIndex);
  };

  const currentTotalCost = find(totalCostPerInterval, { unit: intervals[intervalIndex] }) as TotalCost;
  const unit = currentTotalCost?.unit;
  const priceWithCurrency = localizeCostWithCurrency(currentTotalCost?.cost);

  return (
    <div data-test="overview-total-cost" className={classes.oldPaper}>
      <Headline2>{localizeMessage('youPayOnAverage')}</Headline2>
      <div onClick={handleIntervalChange} role="button" className={classes.priceInterval}>
        <Headline2 style={{ color: colors.action.primary }} className={classes.price}>
          {priceWithCurrency}
        </Headline2>
        <Body className={classes.interval}>
          <span style={{ marginRight: '4px' }}>{localizeMessage('per')}</span>
          <IntervalToText interval={unit} />
        </Body>
      </div>
    </div>
  );
};
