import { LocalizedMessage, withLocalization } from '@client/internationalization';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { HistoryIcon } from '@minna-technologies/minna-ui/icons/History';
import React from 'react';
import styles from '../styles.scss';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';

export const HistoryCard = withLocalization('onboarding-psd2/Cards')(() => {
  const { colors } = useTheme();

  return (
    <Card className={styles.card} data-test={'card-two'}>
      <div className={styles['card-header']}>
        <div className={styles['icon-wrapper']}>
          <HistoryIcon />
        </div>
        <Body variant="bold">
          <LocalizedMessage id="transactionDataCardHealine" />
        </Body>
      </div>
      <div className={styles['content-wrapper']}>
        <Caption style={{ color: colors.textOn.surfaceSubdued, margin: '16px 0' }}>
          <LocalizedMessage id="transactionDataCardDescriptionOne" />
        </Caption>
        <Caption style={{ color: colors.textOn.surfaceSubdued, margin: '16px 0 0' }}>
          <LocalizedMessage id="transactionDataCardDescriptionTwo" />
        </Caption>
      </div>
    </Card>
  );
});
