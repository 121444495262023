import { default as MUISwitch } from '@material-ui/core/Switch';
import * as React from 'react';

interface SwitchProps {
  checked: boolean;
  disabled?: boolean;

  /**
   * Callback fired when the state is changed.
   */
  onChange(checked: boolean): void;
}

export const Switch: React.FunctionComponent<SwitchProps> = ({ onChange, ...props }) => {
  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange(checked);
  };

  return <MUISwitch color="primary" onChange={handleChange} {...props} />;
};
