import Icon from '@client/assets/images/overview-subscription-hub-op-icon.svg';
import { useLocalization } from '@client/internationalization';
import { sendClickedSubscriptionOverviewWidget } from '@client/modules/subscription-hub/mixpanel';
import { useTheme } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { ButtonText } from '@minna-technologies/minna-ui/components/Typography/ButtonText';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as urls from '../../../routes';
import { Sentry } from '@client/assets/js/sentry';

const useStyles = makeStyles(() => ({
  actionButtonContainer: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignContent: 'center',
    alignItems: 'center',
  },
  actionButtonTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const StyledFloatingButton = withStyles({
  root: {
    display: 'contents',
  },
})(Fab);

const StyledButtonText = withStyles({
  root: {
    lineHeight: '1.25rem',
    cursor: 'pointer',
  },
})(ButtonText);

export interface SubscriptionOverviewButtonProps {
  identifyEnabled: boolean;
  identifyOptInEnabled: boolean;
}

export const SubscriptionOverviewButton = (props: SubscriptionOverviewButtonProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { colors } = useTheme();
  const { localizeMessage } = useLocalization('subscriptionHub/subscriptionHub');

  const onSubscriptionOverviewClicked = () => {
    sendClickedSubscriptionOverviewWidget();
    if (props.identifyEnabled) {
      history.push(urls.overviewPage);
    } else if (props.identifyOptInEnabled) {
      history.push(urls.onboardingPsd2);
    } else {
      Sentry.captureMessage('Subscription overview button clicked, but no way to get there');
    }
  };

  return (
    <Card
      className={classes.actionButtonContainer}
      onClick={onSubscriptionOverviewClicked}
      data-test="subscription-overview-button"
      variant="shadow"
    >
      <div className={classes.actionButton}>
        <StyledFloatingButton disableRipple>
          <img
            src={Icon}
            alt="icon"
            style={{ marginLeft: '8px', marginRight: '16px', width: '48px', height: '48px' }}
          />
        </StyledFloatingButton>
        <StyledButtonText
          className={classes.actionButtonTextContainer}
          style={{ lineHeight: '16px', cursor: 'pointer' }}
        >
          <Body variant={'bold'}>{localizeMessage('subscriptionOverviewButtonTitle')}</Body>
          <Caption color={colors.textOn.surfaceSubdued}>{localizeMessage('subscriptionOverviewButtonText')}</Caption>
        </StyledButtonText>
      </div>
      <ChevronRightIcon />
    </Card>
  );
};
