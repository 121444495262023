import type { CancellationReason } from '@client/modules/saveDesk/api';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, TrackingFeature, TrackingFeedbackType } from '@client/tracking/mixpanel-constants';
import capitalize from 'lodash/capitalize';

export interface Props {
  supplierName: string;
  supplierId: string;
  category: string;
  monthlyCost: string;
}

const getBaseProps = (props: Props, page?: string, button?: string) => ({
  Feature: TrackingFeature.SaveDesk,
  Page: page,
  'Supplier name': props.supplierName,
  'Supplier ID': props.supplierId,
  Category: capitalize(props.category),
  monthlyCost: props.monthlyCost,
  Button: button,
});

const makeCancellationReasonDialogEvent =
  (eventName: EventNames, cancellationReason?: string, buttonName?: string) => (props: Props) => {
    getMixpanel().track(eventName, {
      ...getBaseProps(props, 'Cancellation reasons', buttonName),
      Dialog: 'Cancellation reasons',
      'Cancellation reason': cancellationReason,
    });
  };
export const viewedCancellationReasonDialog = makeCancellationReasonDialogEvent(EventNames.VIEWED_DIALOG);
export const clickedCancellationReasonButton = (buttonName: string, cancellationReason: CancellationReason) =>
  makeCancellationReasonDialogEvent(EventNames.CLICKED_BUTTON, cancellationReason, buttonName);

export const viewedSaveDeskOfferDialogEvent = (cancellationReason?: string) => (props: Props) => {
  getMixpanel().track(EventNames.VIEWED_DIALOG, {
    ...getBaseProps(props),
    Dialog: 'SaveDesk offer',
    'Cancellation reason': cancellationReason,
  });
};

export const viewedSaveDeskOrderDetailsVariant = (cancelReason?: CancellationReason) => (props: Props) => {
  getMixpanel().track(EventNames.VIEWED_DIALOG, {
    ...getBaseProps(props),
    Dialog: 'Order details',
    'Cancellation reason': cancelReason,
  });
};

export const clickedSaveDeskOfferPageButton = (cancelReason?: string, buttonName?: string) => (props: Props) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    ...getBaseProps(props, 'Cancellation reasons', buttonName),
    Dialog: 'SaveDesk offer',
    'Cancellation reason': cancelReason,
  });
};

interface SaveDeskInsightCardButtonClickProps {
  card: 'Provider rejected' | 'Contract updated';
  button: 'Close' | 'Send';
}

export const saveDeskInsightCardButtonClick = (props: SaveDeskInsightCardButtonClickProps) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.SaveDesk,
    Card: props.card,
    Button: props.button,
  });
};

interface OrderDetailsPageProps {
  supplierName: string;
  supplierId: string;
  category: string;
  monthlyCost: string;
  cancellationReason: CancellationReason;
}

const makeOrderDetailsPageEvent =
  (page: string, eventName: EventNames, button?: string) => (props: OrderDetailsPageProps) => {
    getMixpanel().track(eventName, {
      ...getBaseProps(props, page, button),
      cancellationReason: props.cancellationReason,
    });
  };

export const saveDeskOrderSent = makeOrderDetailsPageEvent('Order details', EventNames.ORDER_SENT);

interface PainsPageProps extends Props {
  pains?: any[];
  reason?: string;
}

interface RatedExperienceProps extends PainsPageProps {
  cancellationReason?: CancellationReason;
  rating: number;
  comment: string;
}

export const ratedSaveDeskExperienceMixpanelEvent = (props: RatedExperienceProps) => {
  getMixpanel().track(EventNames.RATED_EXPERIENCE, {
    ...getBaseProps(props),
    mp_rating: props.rating,
    'Feedback type': TrackingFeedbackType.Guide,
    reason: props.cancellationReason,
    comment: props.comment,
  });
};
