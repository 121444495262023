import Sentry from '@client/assets/js/sentry';
import { useLocalization } from '@client/internationalization';
import {
  cancelUsingIntelligentGuide,
  removeViewedCancellationGuide,
  viewedCancellationGuide,
} from '@client/modules/cancellation/api';
import LoadingPage from '@client/modules/fetching/LoadingPage';
import { getContractIntelligentGuide, saveViewedIntelligentGuideEvent } from '@client/modules/intelligent-guides/api';
import { IntelligentGuidePageComponent } from '@client/modules/intelligent-guides/components/IntelligentGuidePageComponent';
import {
  sendClickedButtonMixpanelEvent,
  sendViewedGuideCancellationGuideMixpanelEvent,
} from '@client/modules/intelligent-guides/mixpanel';
import type { IntelligentGuide } from '@client/modules/intelligent-guides/models';
import { selectorContract, selectorSubscription, setUpdatedSubscription } from '@client/modules/subscription';
import type { Subscription } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import { TrackingButtonLocation, TrackingButtonName, TrackingPageName } from '@client/tracking/mixpanel-constants';
import { useBackOrDefault } from '@client/utils/hooks/history';
import { ContractHeader, ContractHeaderLogo } from '@minna-technologies/minna-ui/components/ContractHeader';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SubscriptionActionType } from '@client/models/subscription';
import { useCallback, useEffect, useState } from 'react';
import type { Overview } from '@client/modules/overview/model';
import { fetchOverview } from '@client/modules/overview/model';
import { updateOverview } from '@client/modules/overview/duck';
import queryString from 'querystring';

interface Props {
  subscriptionId: string;
  contractId: string;
}

export const IntelligentGuidePage = ({ subscriptionId, contractId }: Props): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { localizeMessage } = useLocalization('cancellation/IntelligentGuidePage');
  const subscription: Subscription = useSelector(selectorSubscription) as Subscription;
  const contract = useSelector((state: any) => selectorContract(state, contractId));
  const query = queryString.parse(window.location.search.slice(1));

  const [intelligentGuide, setIntelligentGuide] = useState<IntelligentGuide | undefined>(undefined);
  useEffect(() => {
    getContractIntelligentGuide(contractId, SubscriptionActionType.Cancel).then((data) => {
      setIntelligentGuide(data);
    });
  }, [contractId]);
  const onSubmitDialog = () => {
    removeViewedCancellationGuide(contractId)
      .then(() => updateOverviewAndSubscription())
      .then(() => history.push(urls.overviewPage));
  };
  const onViewedCancellationGuide = () => {
    if (contract) {
      viewedCancellationGuide(contract?.id)
        .then(() => updateOverviewAndSubscription())
        .catch((error: Error) => {
          Sentry.captureExceptionWithMessage(
            error,
            `Failed to update visited cancellation guide page for contract ${contractId}`
          );
        });
    } else {
      Sentry.captureMessage('Failed to update visited cancellation guide page, missing ContractId');
    }
  };
  const onCancel = () => {
    cancelUsingIntelligentGuide(contractId)
      .then(() => updateOverviewAndSubscription())
      .then(() => history.push(urls.cancellationGuideDonePage(subscriptionId, contractId)))
      .catch((error: Error) => {
        Sentry.captureExceptionWithMessage(error, `Failed to set contract ${contractId} as cancelled`);
      });
  };

  const backOrDefault = useBackOrDefault(urls.contractDetailsPage(subscriptionId, contractId));

  const updateAndBack = () => {
    updateOverviewAndSubscription();
    // If we entered the guide from a user message on the overview we should go back to the overview.
    if (query.userMessage) {
      history.push(urls.overviewPage);
    } else {
      backOrDefault();
    }
  };

  const sendClickedButtonMixpanelEventWithContract = (
    buttonLocation: TrackingButtonLocation,
    buttonName: string,
    page: string = TrackingPageName.CANCEL_INTELLIGENT_GUIDE,
    reason?: string
  ) => {
    sendClickedButtonMixpanelEvent(page, buttonLocation, buttonName, subscription, contract, reason);
  };

  const trackIntelligentGuideClickedButton = () => {
    sendClickedButtonMixpanelEvent(
      TrackingPageName.CANCEL_INTELLIGENT_GUIDE,
      TrackingButtonLocation.CENTER,
      TrackingButtonName.BUTTON,
      subscription,
      contract
    );
  };

  const updateOverviewAndSubscription = useCallback(() => {
    if (subscription) {
      // Refetch overview so that we get the correct user messages.
      fetchOverview()
        .then((overview) => {
          dispatch(updateOverview(overview));
          return overview;
        })
        .then((overview: Overview) => overview.subscriptions.find((s) => s.id === subscription.id))
        .then((updatedSubscription: Subscription | undefined) => {
          if (updatedSubscription) {
            return dispatch(setUpdatedSubscription(updatedSubscription));
          } else return;
        });
    }
  }, [dispatch, subscription]);

  const trackViewedGuide = useCallback(() => {
    updateOverviewAndSubscription();
    if (contract?.service.id) {
      saveViewedIntelligentGuideEvent(
        SubscriptionActionType.Cancel,
        subscription.supplier.id,
        subscription.supplier.name,
        contract.service.id,
        contract?.service.name ?? '',
        contract?.service.category.name ?? '',
        contract?.id,
        subscription?.source
      ).catch((error) => {
        Sentry.captureExceptionWithMessage(
          error,
          `Failed to save viewed CANCEL MERCHANT_GUIDE event for contract ${contract?.id}`
        );
      });
    }
    sendViewedGuideCancellationGuideMixpanelEvent(
      TrackingPageName.CANCEL_INTELLIGENT_GUIDE,
      subscription.supplier.name
    );
  }, [subscription, contract, updateOverviewAndSubscription]);

  const header = (
    <ContractHeader
      title={contract?.service?.category?.text ?? ''}
      subHeading={subscription.supplier.name}
      logo={
        <ContractHeaderLogo logoUrl={subscription.supplier?.logoUrl ?? ''} merchantName={subscription.supplier.name} />
      }
    />
  );

  return (
    (intelligentGuide && (
      <IntelligentGuidePageComponent
        header={header}
        intelligentGuide={intelligentGuide}
        dialogPageName={TrackingPageName.CANCEL_INTELLIGENT_GUIDE_DIALOG_REASON}
        linkName={TrackingButtonName.LINK}
        title={localizeMessage('headline')}
        feedbackSectionTitle={localizeMessage('didYouCancel', { providerName: subscription.supplier.name })}
        modalTitle={localizeMessage('whyNotCancel')}
        onGoBack={updateAndBack}
        onConfirmAction={onCancel}
        onSubmitDeclineDialog={onSubmitDialog}
        onClickAnyButton={sendClickedButtonMixpanelEventWithContract}
        onViewGuideNotFromUserMessage={onViewedCancellationGuide}
        trackViewedGuide={trackViewedGuide}
        trackClickedIntelligentGuideButton={trackIntelligentGuideClickedButton}
        showFeedbackSection={subscription.userMessage.message.type === 'DidYouCancelMessage'}
        actionType={SubscriptionActionType.Cancel}
      />
    )) || <LoadingPage />
  );
};
