import { withLocalizedFunctionProp } from '@client/internationalization';
import get from 'lodash/get';

export enum Color {
  ORANGE = 'orange',
  GREEN = 'green',
  PURPLE = 'purple',
}

export enum Alignment {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center',
}

export const ACTIVE = 'ActiveMessage';
export const NEW_CONTRACT = 'NewContractMessage';
export const MORE_INFORMATION_NEEDED = 'MoreInformationNeededMessage';
export const COMPLEX_SUBSCRIPTION = 'ComplexSubscriptionMessage';
export const PRICE_INCREASED = 'PriceIncreasedMessage';
export const PRICE_DECREASED = 'PriceDecreasedMessage';
export const BINDING_PERIOD_ENDING_OPTIMIZABLE = 'OptimizableContractBindingPeriodEndingMessage';
export const BINDING_PERIOD_ENDING_NEGOTIABLE = 'NegotiableContractBindingPeriodEndingMessage';
export const BINDING_PERIOD_ENDING = 'BindingPeriodEndingMessage';
export const CHANGE_DATA_PLAN_ACCEPTED = 'ChangeDataPlanAcceptedMessage';
export const CHANGE_DATA_PLAN_FAILED = 'ChangeDataPlanFailedMessage';
export const CHANGE_DATA_PLAN = 'ChangeDataPlanOngoingMessage';
export const TERMINATION_CONTRACT = 'TerminationOngoingMessage';
export const TERMINATION_ACCEPTED = 'TerminationAcceptedMessage';
export const TERMINATION_FAILED = 'TerminationFailedMessage';
export const ORDER_ACCEPTED = 'OrderAcceptedMessage';
export const ORDERED_CONTRACT = 'ContractOrderedMessage';
export const DID_YOU_CANCEL = 'DidYouCancelMessage';

const getOverviewMessages = (localizeMessage: (title: string, extra?: any) => string) => {
  return {
    [ACTIVE]: {},
    [COMPLEX_SUBSCRIPTION]: {},
    [PRICE_INCREASED]: {},
    [PRICE_DECREASED]: {},
    [BINDING_PERIOD_ENDING_NEGOTIABLE]: {},
    [BINDING_PERIOD_ENDING_OPTIMIZABLE]: {},
    [BINDING_PERIOD_ENDING]: {},
    [NEW_CONTRACT]: {},
    [CHANGE_DATA_PLAN]: {},
    [CHANGE_DATA_PLAN_ACCEPTED]: {},
    [CHANGE_DATA_PLAN_FAILED]: {},
    [TERMINATION_CONTRACT]: {},
    [TERMINATION_ACCEPTED]: {},
    [TERMINATION_FAILED]: {},
    [ORDER_ACCEPTED]: {},
    [ORDERED_CONTRACT]: {},
    [MORE_INFORMATION_NEEDED]: {
      header: localizeMessage('messageMoreInformationNeededHeader'),
      body: { start: localizeMessage('messageMoreInformationNeededStart') },
      color: Color.ORANGE,
      alignArrow: Alignment.LEFT,
    },
  };
};

const getSubscriptionOverviewMessage =
  (localizeMessage: (title: string, extra?: any) => string) => (userMessage: any) => {
    const message = get(userMessage, 'message', {});
    const overviewMessages = getOverviewMessages(localizeMessage);
    const userMessageType = get(message, 'type', ACTIVE);

    return get(overviewMessages, userMessageType, {});
  };

export const withGetSubscriptionOverviewMessage = withLocalizedFunctionProp(
  'overview/OverviewPage',
  getSubscriptionOverviewMessage,
  'getSubscriptionOverviewMessage'
);
