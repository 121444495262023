export const SET_DIALOG_OPEN_STATE = 'ZENDESK/SET_DIALOG_OPEN_STATE';
export const PUSH_ROUTE = 'ZENDESK/PUSH_ROUTE';
export const POP_ROUTE = 'ZENDESK/POP_ROUTE';
export const RESET_NAVIGATION_ON_CLOSE = 'ZENDESK/RESET_NAVIGATION_ON_CLOSE';

export const HELP_CENTER_ROUTE = 'help-center';
export const CHAT_ROUTE = 'chat';
export const CALL_US_ROUTE = 'call-us';
export const CALL_BACK_ROUTE = 'call-back';
export const CALL_BACK_SUCCESS_ROUTE = 'call-back-success';
export const CALL_BACK_FAILURE_ROUTE = 'call-back-failure';
export const CATEGORY_ROUTE = `${HELP_CENTER_ROUTE}/category`;
export const USER_SEARCH_RESULT_ROUTE = `${HELP_CENTER_ROUTE}/user-search`;
export const ARTICLE_DETAILS_ROUTE = `${HELP_CENTER_ROUTE}/article`;
export const SECTION_ROUTE = `${HELP_CENTER_ROUTE}/section`;
export const CONTACT_US_ROUTE = `${HELP_CENTER_ROUTE}/contact-us`;
export const EMAIL_US_ROUTE = `${HELP_CENTER_ROUTE}/email-us`;

export const SAVE_CALL_BACK_REQUEST_DATA = 'SAVE_CALL_BACK_REQUEST_DATA';
