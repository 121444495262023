import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { User } from '@client/models/user-models';
import { useTheme } from '@material-ui/core/styles';
import { HelpIcon } from '@minna-technologies/minna-ui/icons/Help';
import { FlatButton } from 'material-ui';
import React from 'react';

interface Props {
  user: User;
  query: any;
  onOpenDialog(query: any, user: User): void;
  className?: string;
  iconPrimaryColor?: boolean;
  hideLabelOnXs?: boolean;
  hideLabel?: boolean;
}

const ZendeskNavbarHelpButtonInner = ({
  user,
  query,
  className,
  iconPrimaryColor,
  onOpenDialog,
  hideLabelOnXs,
  hideLabel,
}: Props) => {
  const { colors } = useTheme();
  const iconElement = <HelpIcon nativeColor={iconPrimaryColor ? colors.action.primary : colors.base.surface} />;

  const labelStyle = { color: colors.base.surface, paddingRight: '8px' };
  const mobileStyle: any = {
    minWidth: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  };
  if (hideLabelOnXs || hideLabel) {
    mobileStyle.marginRight = '12px';
  }

  return (
    <div data-test="navbar-zendesk-button">
      <div className={className}>
        <FlatButton
          labelPosition="after"
          labelStyle={labelStyle}
          style={mobileStyle}
          label={hideLabelOnXs || hideLabel ? undefined : <LocalizedMessage id="navigationBarHelpButton" />}
          icon={iconElement}
          onClick={() => onOpenDialog(query, user)}
        />
      </div>
    </div>
  );
};

export const ZendeskNavbarHelpButton = withLocalization('zendesk')(ZendeskNavbarHelpButtonInner);
