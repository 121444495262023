import type { Messages, MessagesAndLocale } from '@client/internationalization/types';
import { getItemFromLocalStorage } from '@client/utils/local-storage';
import axios from 'axios';
import type { AppType, Market } from '../modules/app-context/constants';
import { Locale } from '../modules/app-context/constants';
import { popPreloadedFetchOrFallback } from '../modules/preloaded-resources';

// TODO: Maybe move it to developerTools namespace? Like in developer-tools.ts!
function maybeDebugPlatformLocale(): string {
  return getItemFromLocalStorage('debugPlatformLocale') || '';
}

export async function forceLoadMessagesAndLocale(
  locale: Locale,
  app: AppType,
  market?: Market
): Promise<MessagesAndLocale> {
  const url = `/api/v1/internationalization/messages/${locale}@${app}`;
  return axios.get(url, { params: { market: market } }).then((response) => response.data);
}

async function loadLocalMessagesEnglish(): Promise<MessagesAndLocale> {
  return import(/* webpackChunkName: "localized-messages-local" */ './development-localized-messages').then(
    (module) => {
      const messages: Messages = module.localizedMessagesEnglish();
      const locale = Locale.enGB;

      return { messages, locale };
    }
  );
}

export async function loadMessagesAndLocale(market?: Market): Promise<MessagesAndLocale> {
  // @ts-ignore
  if (window.Cypress) {
    const url = '/api/v1/internationalization/messages/';
    const localLocaleAndMessages = await loadLocalMessagesEnglish();
    try {
      // Messages endpoint was mocked
      const response = await axios.get(url);
      const localeAndMessages = response.data;
      if (Object.keys(localeAndMessages.messages).length === 0) {
        // Cypress mocking doesn't allow payloads >80kb, so we have to supply local messages manually here
        localeAndMessages.messages = localLocaleAndMessages.messages;
      }

      return localeAndMessages;
    } catch (e) {
      // If messages endpoint was not mocked, load local messages
      return localLocaleAndMessages;
    }
  } else if (process.env.NODE_ENV === 'development' && maybeDebugPlatformLocale() === '') {
    return loadLocalMessagesEnglish();
  } else {
    const url = `/api/v1/internationalization/messages/${encodeURIComponent(maybeDebugPlatformLocale())}`;

    return popPreloadedFetchOrFallback('messages', async () =>
      axios.get(url, { params: { market: market } }).then<MessagesAndLocale>((response) => response.data)
    );
  }
}

export async function loadMessagesAndLocaleCancellationPortal(
  locale: Locale,
  market: Market
): Promise<MessagesAndLocale> {
  if (process.env.NODE_ENV === 'development' && locale === Locale.enGB) {
    return loadLocalMessagesEnglish();
  } else {
    const url = `/api/v1/internationalization/messages/${locale}@${market}?market=${market}`;

    return axios.get(url).then((response) => response.data);
  }
}
