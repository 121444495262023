import { LocalizedMessage } from '@client/internationalization';
import { getSupportCode } from '@client/models/support';
import { dark, darker, primary } from '@client/styles/colors-standard';
import { getMarketSpecificSupportHours, getRawSupportNumber, getSupportNumber } from '@client/utils/support';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import Paper from 'material-ui/Paper';
import React from 'react';
import type { AppContext } from '../../../../app-context';
import { AppIntegration } from '../../../../app-integration';
import { ZendeskSubHeader } from '../../../ZendeskSubHeader';
import styles from './styles.scss';

const forceMarginTop = 'force-margin-top';

interface ZendeskCallUsProps {
  onBackClick(): void;
  getSupportCode(): void;
  appContext: AppContext;
}

interface ZendeskCallUsState {
  code: string;
}

export class ZendeskCallUs extends React.Component<ZendeskCallUsProps, ZendeskCallUsState> {
  public constructor(props: ZendeskCallUsProps) {
    super(props);
    this.state = { code: '' };
    this.openLink = this.openLink.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    const res = await getSupportCode();
    this.setState({ code: res.code });
  }

  public render() {
    const marketSpecificSupportHours = getMarketSpecificSupportHours(this.props.appContext.market);
    const supportHoursLocalizedMessage = (
      <LocalizedMessage
        id="contactUsSectionOpenHours"
        values={{
          mondayOpeningHour: marketSpecificSupportHours.mondayOpeningHour,
          lunchBreakStartingHour: marketSpecificSupportHours.lunchBreakStartingHour,
          lunchBreakEndingHour: marketSpecificSupportHours.lunchBreakEndingHour,
          openingHour: marketSpecificSupportHours.openingHour,
          closingHour: marketSpecificSupportHours.closingHour,
        }}
      />
    );

    return (
      <Paper className={styles.paper}>
        <div className={styles.container}>
          <ZendeskSubHeader title={<LocalizedMessage id="callUsTitle" />} />
          <Caption style={{ color: darker.main }} className={styles[forceMarginTop]}>
            <LocalizedMessage id="contactUsSectionCallUsOpenHoursSubtitleV2" />
          </Caption>
          <Body style={{ color: darker.main }}>{supportHoursLocalizedMessage}</Body>

          <Body className={styles[forceMarginTop]}>
            <LocalizedMessage id="callUsDescription" />
          </Body>

          <Caption className={styles[forceMarginTop]} style={{ color: dark.main }}>
            <LocalizedMessage id="callUsCodeDescription" />
          </Caption>
          <Body variant="bold" data-test="zendesk-phone-support-code">
            {this.state.code}
          </Body>

          <Caption className={styles[forceMarginTop]} style={{ color: dark.main }}>
            <LocalizedMessage id="callUsDescriptionV2" />
          </Caption>
          <Body
            variant="bold"
            data-test="zendesk-support-number"
            className={styles['support-number']}
            style={{ color: primary.main }}
            role="link"
            onClick={this.openLink}
          >
            <LocalizedMessage
              id="callUsSupportNumber"
              values={{ supportNumber: getSupportNumber(this.props.appContext.market) }}
            />
          </Body>
        </div>
      </Paper>
    );
  }

  private openLink(event: any) {
    const url = `tel://${getRawSupportNumber(this.props.appContext.market)}`;
    AppIntegration.openExternalWebPage(url, event);
  }
}
