import quickCancelDrawerImage from '@client/assets/images/quick-actions/quick-cancel-drawer.svg';
import quickCancelStar from '@client/assets/images/quick-actions/quick-cancel-star.svg';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_OVERVIEW } from '@client/tracking/mixpanel-constants';
import { useTheme } from '@material-ui/core/styles';
import { PositiveButton } from '@minna-technologies/minna-ui/components/Buttons/PositiveButton';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Drawer } from '@minna-technologies/minna-ui/components/Drawer';
import { CardLayout } from '@minna-technologies/minna-ui/components/Layouts/CardLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import React from 'react';
import styles from '../styles.scss';

interface QuickActionCancelDrawerProps {
  open: boolean;
  onClose(): void;
}

const QuickActionCancelDrawerInner: React.FunctionComponent<QuickActionCancelDrawerProps> = ({
  open,
  onClose,
}: QuickActionCancelDrawerProps) => {
  const { colors } = useTheme();

  function handleNext() {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_OVERVIEW,
      Page: 'Cancel quick action',
      Button: 'Next',
    });
    onClose();
  }
  // eslint-disable-next-line
  function handleClose() {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_OVERVIEW,
      Page: 'Cancel quick action',
      Button: 'Close',
    });
    onClose();
  }

  return (
    <Drawer open={open} onClose={handleClose}>
      <CardLayout>
        <div className={styles.imageContainer}>
          <img src={quickCancelDrawerImage} alt={'Cancel avatar'} />
        </div>
        <Headline2>
          <LocalizedMessage id={'cancelDrawerTitle'} />
        </Headline2>
        <Body>
          <LocalizedMessage id={'cancelBodyRow1'} />
        </Body>
        <Body>
          <LocalizedMessage id={'cancelBodyRow2'} />
        </Body>
        <Divider />
        <div className={styles.ratingContainer}>
          <Caption>
            <LocalizedMessage id={'rating'} />
          </Caption>
          <img className={styles.ratingStarSpacing} src={quickCancelStar} alt={'Rating star'} />
          <div className={styles.ratingValue}>
            <Headline2 color={colors.action.primary}>4.7</Headline2>
            <Body color={colors.action.primary}>/5</Body>
          </div>
        </div>
        <PositiveButton
          fullWidth={false}
          label={<LocalizedMessage id={'drawerContinueButton'} />}
          onClick={handleNext}
        />
      </CardLayout>
    </Drawer>
  );
};

export const QuickActionCancelDrawer = withLocalization('overview/QuickActionsV2')(QuickActionCancelDrawerInner);
