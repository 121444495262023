import { AppIntegration, AppPlatform } from '@client/modules/app-integration';
import {
  appOpenLink as appOpenLinkStandalone,
  isStandaloneMobileApp,
} from '@client/modules/app-integration/standalone/standalone-app-integration';
import {
  isSwedbankApp,
  openBankId as openBankIdFromSwedbankApp,
} from '@client/modules/app-integration/swedbank-app-integration';
import includes from 'lodash/includes';

/**
 * Opens BankID app.
 *
 * @param autoStartToken Optional token.
 */
export function openBankId(autoStartToken?: string): void {
  const autoStartTokenParameter = autoStartToken ? `autostarttoken=${autoStartToken}&` : '';

  if (isSwedbankApp()) {
    openBankIdFromSwedbankApp(autoStartToken);
  } else if (isStandaloneMobileApp()) {
    if (AppIntegration.getAppPlatform() === AppPlatform.ANDROID_WEBVIEW) {
      appOpenLinkStandalone(`bankid:///?${autoStartTokenParameter}redirect=null`);
    } else {
      appOpenLinkStandalone(`bankid:///?${autoStartTokenParameter}redirect=minatjanster://`);
    }
  } else if (document.location) {
    openBankIdFromWebBrowser(autoStartTokenParameter);
  }
}

function openBankIdFromWebBrowser(autoStartTokenParameter: string) {
  const userAgent = window.navigator.userAgent;
  const currentUrl = window.location.href;

  function redirectUrl() {
    if (includes(userAgent, 'Android')) {
      return 'null'; // Special case for Android, will close BankID app and return to last application.
    } else {
      const escapedUri = encodeURIComponent(currentUrl);
      // For Chrome on iOS make sure it opens again after BankId
      if (userAgent.match('CriOS')) {
        return escapedUri.replace(/^http/, 'googlechrome');
      } else {
        return escapedUri;
      }
    }
  }

  if (document.location) {
    document.location.replace(`bankid:///?${autoStartTokenParameter}redirect=${redirectUrl()}`);
  }
}
