import type { AppContext } from '@client/modules/app-context';
import { AppIntegration } from '@client/modules/app-integration';
import * as urls from '@client/routes';
import type { History } from 'history';

export const navigateToMainPage = (appContext: AppContext, history: History) => {
  if (!appContext.features.identify?.overview && appContext.embeddedView.webView) {
    AppIntegration.closeApplication();
  } else {
    history.push(urls.overviewPage);
  }
};
