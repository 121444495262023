import { selectorNotLoggedIn } from '@client/ducks/user';
import * as urls from '@client/routes';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectorAppContext } from '../../modules/app-context/duck';

/**
 * Use it to require a LoggedIn user in your component.
 * If the user is not logged in, it will be redirected to the loggedOut page or the loginPage if applicable.
 */
export function useLoggedInUser() {
  const history = useHistory();
  const appContext = useSelector(selectorAppContext);
  const notLoggedIn = useSelector(selectorNotLoggedIn);

  useEffect(() => {
    if (notLoggedIn) {
      history.replace(urls.loggedOutPage, { redirectOnLogin: history.location.pathname });
    }
  }, [appContext, history, notLoggedIn]);
}
