import { useLocalization } from '@client/internationalization';
import { MessageToUser } from '@client/modules/cancellation/CancellationOutcome/components/MessageToUser';
import { makeStyles } from '@material-ui/core';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles({
  sectionMargin: {
    margin: '0 16px',
  },
});

interface Props {
  serviceProviderName: string;
  messageFromProvider?: string;
  bindingTimeEndsAt?: string;
  lastPaymentAt?: string;
}

export const CancelledWithUpcomingPaymentsContent: FC<Props> = ({
  serviceProviderName,
  messageFromProvider,
  bindingTimeEndsAt,
  lastPaymentAt,
}) => {
  const classes = useStyles();
  const { localizeMessage } = useLocalization('cancel/CancellationOutcome');

  return (
    <div className={classes.sectionMargin}>
      <Body>
        {localizeMessage('cancelledWithUpcomingPaymentsDescription1', {
          providerName: serviceProviderName,
        })}
        <br />
        <br />
        {localizeMessage('cancelledWithUpcomingPaymentsDescription2', {
          cancellationDate: bindingTimeEndsAt || 'none',
          lastPaymentAt: lastPaymentAt || 'none',
        })}
        {messageFromProvider && (
          <MessageToUser messageToUser={messageFromProvider} serviceProviderName={serviceProviderName} />
        )}
      </Body>
    </div>
  );
};
