import type { AppContext } from '@client/modules/app-context';
import { Locale } from '@client/modules/app-context/constants';
import { withAppContext } from '@client/modules/app-context/react';
import { Sb1UserTermsContent } from '@client/modules/conditions-and-policies/UserTermsPage/Sb1UserTermsPage';
import PageNavigationBar from '@client/shared-components/PageNavigationBar';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import withStyles from '@material-ui/core/styles/withStyles';
import * as React from 'react';
import { compose } from 'recompose';

interface TermsInnerProps {
  appContext: AppContext;
}

export interface TermsProps {
  isOpen?: boolean;

  closeTermsDialog(): void;
  trackViewedEvent(): void;
}

const StyledDialogContent = withStyles({
  root: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0,
    },
  },
})(DialogContent);

// Currently only SB1 is using these cancellation terms
const TermsSectionInner: React.FC<TermsInnerProps & TermsProps> = ({
  isOpen = false,
  closeTermsDialog,
  trackViewedEvent,
  appContext,
}: TermsInnerProps & TermsProps) => {
  React.useEffect(() => {
    if (isOpen) {
      trackViewedEvent();
    }
  });

  let localizedPageTitle = null;
  if (appContext.locale === Locale.nbNO) {
    localizedPageTitle = 'Vilkår for avbestillinger';
  } else {
    localizedPageTitle = 'Terms for cancellations';
  }

  return (
    <Dialog open={isOpen} onClose={closeTermsDialog} fullScreen>
      <StyledDialogContent>
        <PageNavigationBar title={localizedPageTitle} onBackClick={closeTermsDialog} />
        <Sb1UserTermsContent locale={appContext.locale} />
      </StyledDialogContent>
    </Dialog>
  );
};

export const TermsSection: React.ComponentClass<TermsProps> = compose<TermsInnerProps & TermsProps, TermsProps>(
  withAppContext
)(TermsSectionInner);
