import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import React from 'react';

const ContactUsButtonInner: React.FunctionComponent<ContactUsButtonInnerProps> = ({
  onNavigateToContactUs,
  insightCardName,
  pageName,
  supplierName,
}) => {
  return (
    <SecondaryButton
      label={<LocalizedMessage id="contactUsBtnLabel" />}
      onClick={() => onNavigateToContactUs(pageName, insightCardName, supplierName)}
      fullWidth
    />
  );
};

export interface ContactUsButtonInnerProps {
  onNavigateToContactUs(pageName: string, insightCardName: string, supplierName: string): void;
  insightCardName: string;
  supplierName: string;
  pageName: string;
}

export const ContactUsButtonComponent = withLocalization('overview/InsightsPage/InsightCards/ContactUsButton')(
  ContactUsButtonInner
);
