import Sentry from '@client/assets/js/sentry';
import axios from 'axios';
import type { RatingRequest } from './model';

export const saveRating = (ratingRequest: RatingRequest) => {
  axios
    .post('api/user/rating', ratingRequest)
    .then(({ data }) => data)
    .catch((error) => {
      Sentry.captureExceptionWithMessage(error, 'Failed to save rating for request', { extra: { ratingRequest } });
    });
};
