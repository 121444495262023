import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { parseDate } from '@client/utils/date';
import { Stepper } from '@minna-technologies/minna-ui/components/Stepper';
import React from 'react';

interface OngoingOrderStepperProps {
  oldServiceProviderName?: string;
  newServiceProviderName: string;
  portingOrStartDate?: string;
  categoryName: string;
  waitingForCancellationInfo: boolean;
  actionRequestDate: string;
  orderIsAccepted: boolean;
  hasCancellation: boolean;
}

const OngoingOrderStepperInner: React.FunctionComponent<OngoingOrderStepperProps> = ({
  oldServiceProviderName,
  newServiceProviderName,
  actionRequestDate,
}) => {
  const isIndependentOrder = !oldServiceProviderName;

  return (
    <>
      {isIndependentOrder && (
        <IndependentOrderStepper orderRequestDate={actionRequestDate} serviceProviderName={newServiceProviderName} />
      )}
    </>
  );
};

export const OngoingOrderStepper = withLocalization('orderedSubscription/OrderStepper')(OngoingOrderStepperInner);

interface IndependentOrderStepperProps {
  serviceProviderName: string;
  orderRequestDate: string;
}

const IndependentOrderStepperInner: React.FunctionComponent<IndependentOrderStepperProps> = ({
  serviceProviderName,
  orderRequestDate,
}) => {
  return (
    <Stepper
      steps={[
        {
          label: (
            <LocalizedMessage
              id="requestSent"
              values={{ providerName: serviceProviderName, requestDate: parseDate(orderRequestDate) }}
            />
          ),
          active: true,
        },
        { label: <LocalizedMessage id="waitingForOrderConfirmation" values={{ supplierName: serviceProviderName }} /> },
      ]}
    />
  );
};

export const IndependentOrderStepper = withLocalization('orderedSubscription/OrderStepper')(
  IndependentOrderStepperInner
);
