import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { ContractDetailsFloatingButtons } from '@client/modules/subscription/ContractDetailsPage/components/ContractDetailsFloatingButtons';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import { MarkdownLink } from '@client/shared-components/MarkdownLink';
import { goBackOrDefault } from '@client/utils/hooks/history';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { ContractHeaderDeprecated } from '@minna-technologies/minna-ui/components/ContractHeaderDeprecated';
import { CardLayout } from '@minna-technologies/minna-ui/components/Layouts/CardLayout';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';

const styles = () =>
  createStyles({
    paper: {
      padding: 16,
      margin: '16px 8px 80px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 24,
    },
    instructions: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      wordBreak: 'break-word',
    },
  });

const CancellationInstructionsPageComponentInner: React.FunctionComponent<
  CancellationInstructionsPageComponentProps & WithStyles<typeof styles>
> = ({ contract, subscription, classes }) => {
  const history = useHistory();
  const goBack = () => {
    goBackOrDefault(history, urls.contractDetailsPage(subscription.id, contract.id));
  };

  return (
    <MultiDeviceLayout
      fullWidthContent={
        <ContractHeaderDeprecated
          providerLogo={subscription.supplier.logoUrl ?? ''}
          title={contract.service.category.text ?? ''}
          providerName={subscription.supplier.name}
          subHeading={subscription.supplier.name}
        />
      }
      footer={<ContractDetailsFloatingButtons backButton />}
    >
      <CardLayout>
        <Card>
          <ReactMarkdown className={classes.instructions} components={{ a: MarkdownLink }}>
            {contract.service.terminationInstructions}
          </ReactMarkdown>
          <div className={classes.buttonContainer}>
            <PrimaryButton onClick={goBack} label={<LocalizedMessage id="closeInstructionsButton" />} />
          </div>
        </Card>
      </CardLayout>
    </MultiDeviceLayout>
  );
};

export interface CancellationInstructionsPageComponentProps {
  subscription: Subscription;
  contract: Contract;
  goBack?(): void;
}

export const CancellationInstructionsPageComponent = withLocalization('termination/TerminationInstructionsPage')(
  withStyles(styles)(CancellationInstructionsPageComponentInner)
);
