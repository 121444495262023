import { selectorUser } from '@client/ducks/user';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { branch, compose, renderNothing } from 'recompose';
import { withAppContext } from '../../app-context/react';
import { open } from '../ducks';
import { ZendeskNavbarHelpButton } from './component';

const mapStateToProps = (state: any) => {
  return {
    user: selectorUser(state),
  };
};

const mapDispatchToProps = (dispatch: any, { appContext }: any) => {
  return {
    onOpenDialog: (query: any) =>
      dispatch(open(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, query)),
  };
};

export const ZendeskNavbarHelpButtonContainer = compose<any, any>(
  withAppContext,
  connect(mapStateToProps, mapDispatchToProps),
  branch(
    ({ appContext }: any) => appContext.features.helpCenter && !appContext.features.helpCenter.enabled,
    renderNothing
  )
)(ZendeskNavbarHelpButton);

ZendeskNavbarHelpButtonContainer.propTypes = {
  query: PropTypes.object.isRequired,
  className: PropTypes.string,
  iconPrimaryColor: PropTypes.bool,
  hideLabelOnXs: PropTypes.bool,
  hideLabel: PropTypes.bool,
};
