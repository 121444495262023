import { withLocalization } from '@client/internationalization';
import type React from 'react';
import { compose } from 'redux';
import { withAppContext } from '../../../../app-context/react';
import { ZendeskEmailUs } from './ZendeskEmailUsComponent';

//@ts-ignore
export const ZendeskEmailUsContainer: React.ComponentType<any> = compose<any, any>(
  withAppContext,
  //@ts-ignore
  withLocalization('zendesk')
  //@ts-ignore
)(ZendeskEmailUs);
