import Sentry, { isProductionEnvironment } from '@client/assets/js/sentry';
import { onEnter } from '@client/containers/container-helpers/onEnter';
import { onExit } from '@client/containers/container-helpers/onExit';
import type { Locale } from '@client/modules/app-context/constants';
import { selectorLocale } from '@client/modules/app-context/duck';
import { cancellationSignCompleteCallback } from '@client/modules/cancellation/signing/common';
import type { NemIdSigningPageProps } from '@client/modules/e-signing/nem-id/component';
import { NemIdSigningPageComponent } from '@client/modules/e-signing/nem-id/component';
import { NemIdErrorPage } from '@client/modules/e-signing/nem-id/components/NemIdErrorPage';
import { nemIdClientBaseUrl, nemIdClientUrl } from '@client/modules/e-signing/nem-id/constants';
import { conditionalRender } from '@client/modules/fetching/conditionalRender';
import { selectorContract, selectorSubscription } from '@client/modules/subscription';
import type { Contract, ContractId, Subscription, SubscriptionId } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import type { AxiosError } from 'axios';
import type { History } from 'history';
import type * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, withState } from 'recompose';
import type { Dispatch } from 'redux';
import { initiateNemIdSigning, requestCancellation } from './api';

interface ContainerOuterProps {
  subscriptionId: SubscriptionId;
  contractId: ContractId;
}

type ConnectOwnProps = {
  history: History;
  errorCode?: string;
  setErrorCode(errorCode: string): void;
} & ContainerOuterProps;

interface StateProps {
  nemIdUrl: string;
  contract?: Contract;
  subscription?: Subscription;
  locale: Locale;
}

interface DispatchProps {
  onBackClick(): void;
  onBackToContractDetail(): void;
  onCancellationSigningComplete(subscription: Subscription, contract?: Contract): void;
}

type MergeProps = ContainerOuterProps &
  ConnectOwnProps &
  StateProps &
  DispatchProps & {
    onCancellationSigningComplete(): void;
    onNemIDIFrameMessage(event: MessageEvent): void;
  };

function mapStateToProps(state: any, { contractId }: ConnectOwnProps): StateProps {
  return {
    nemIdUrl: nemIdClientUrl(),
    contract: selectorContract(state, contractId),
    subscription: selectorSubscription(state),
    locale: selectorLocale(state),
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  { history, subscriptionId, contractId }: ConnectOwnProps
): DispatchProps => ({
  onBackClick: () => {
    history.replace(urls.terminationFormPage(subscriptionId, contractId));
  },
  onBackToContractDetail: () => {
    history.replace(urls.contractDetailsPage(subscriptionId, contractId));
  },
  onCancellationSigningComplete: (subscription: Subscription, contract: Contract) => {
    cancellationSignCompleteCallback(dispatch, history)(subscription, contract);
  },
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: ConnectOwnProps): MergeProps => {
  const onCompleteFunction = () => {
    if (stateProps.subscription) {
      dispatchProps.onCancellationSigningComplete(stateProps.subscription, stateProps.contract);
    }
  };

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onCancellationSigningComplete: onCompleteFunction,
    onNemIDIFrameMessage: onNemIDMessage(
      stateProps.nemIdUrl,
      ownProps.contractId,
      onCompleteFunction,
      ownProps.setErrorCode
    ),
  };
};

interface PostMessageProps {
  command: string;
  content: string;
}

let signingStartedAt: Date | null = null;

const onNemIDMessage =
  (
    nemIdUrl: string,
    contractId: ContractId,
    onCancellationSigningComplete: () => void,
    setErrorCode: (errorCode: string) => void
  ) =>
  (event: MessageEvent) => {
    if (event.origin !== nemIdClientBaseUrl && isProductionEnvironment()) {
      throw new Error(`Received message from unexpected origin when communicating with NemId iframe: ${event.origin}`);
    }

    const nemIdIframe = document.getElementById('nemid_iframe');
    if (!nemIdIframe) {
      throw new Error('Could not find NemId iframe on the page.');
    }
    const iFrameContentWindow = (nemIdIframe as HTMLIFrameElement).contentWindow;
    if (!iFrameContentWindow) {
      throw new Error('Could not find content window on NemId iframe.');
    }

    const incomingMessage = JSON.parse(event.data as string);
    if (incomingMessage.command === 'SendParameters') {
      initiateNemIdSigning(contractId)
        .then((jsonParameters) => {
          const outgoingMessage: PostMessageProps = {
            command: 'parameters',
            content: JSON.stringify(jsonParameters),
          };
          signingStartedAt = new Date();
          iFrameContentWindow.postMessage(JSON.stringify(outgoingMessage), nemIdUrl);
        })
        .catch((error: AxiosError) => onError(error, 'Failed to generate NemId parameters.', setErrorCode));
    } else if (incomingMessage.command === 'changeResponseAndSubmit') {
      requestCancellation(contractId, signingStartedAt || new Date(), incomingMessage.content)
        .then(onCancellationSigningComplete)
        .catch((error: AxiosError) =>
          onError(error, 'Failed to create cancellation with NemId signing verification.', setErrorCode)
        );
    }
  };

const onError = (error: AxiosError, message: string, setErrorCode: (errorCode: string) => void) => {
  Sentry.captureExceptionWithMessage(error, message);
  const responseData = error.response && error.response.data;
  if (responseData) {
    setErrorCode(responseData as string);
  } else {
    setErrorCode('GeneralError');
  }
};

export const NemIdSigningPage: React.ComponentClass<ContainerOuterProps> = compose<
  NemIdSigningPageProps,
  ContainerOuterProps
>(
  withRouter,
  withState<{ history: History } & ContainerOuterProps, string | undefined, 'errorCode', 'setErrorCode'>(
    'errorCode',
    'setErrorCode',
    undefined
  ),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  onEnter((mergedProps: MergeProps) => {
    if (window.addEventListener) {
      window.addEventListener('message', mergedProps.onNemIDIFrameMessage);
    }
  }),
  onExit((mergedProps: MergeProps) => {
    if (window.removeEventListener) {
      window.removeEventListener('message', mergedProps.onNemIDIFrameMessage);
    }
  }),
  conditionalRender((props: MergeProps) => Boolean(props.errorCode), NemIdErrorPage)
)(NemIdSigningPageComponent);
