import { TermsAndConditionsPage } from '@client/modules/ordering-subscription/common/TermsAndConditionPage/component';
import type { OrderedSubscription } from '@client/modules/switch/ordered-subscription/models';
import React from 'react';
import { zendeskLabelFromCategory } from '../../common/utils';

interface OrderedSubscriptionTermsAndConditionsPageProps {
  orderedSubscription: OrderedSubscription;
  onBackClick(): void;
}

export const OrderedSubscriptionTermsAndConditionsPage: React.FunctionComponent<
  OrderedSubscriptionTermsAndConditionsPageProps
> = ({ orderedSubscription, onBackClick }) => {
  return (
    <TermsAndConditionsPage
      supplier={orderedSubscription.product.supplierInformation}
      productName={orderedSubscription.product.information.name}
      termsAndConditions={orderedSubscription.product?.termsAndConditions}
      zendeskLabel={zendeskLabelFromCategory(orderedSubscription.product.category[0].name)}
      onBackClick={onBackClick}
    />
  );
};
