import { Sentry } from '@client/assets/js/sentry';
import {
  saveUserViewedRateOnboardingCard,
  selectorViewedOnboardingCardDiscoverAt,
  selectorViewedOnboardingCardRateAt,
  setUser,
  setUserVisitedPage,
  VIEWED_RATE_ONBOARDING_CARD_AT,
} from '@client/ducks/user';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { LoadingState } from '@client/models/LoadingState';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { requestPushNotificationPermission } from '@client/modules/app-integration/standalone/standalone-app-integration';
import {
  InsightActions,
  selectorFetchableInsights,
  selectorOnboardingInsight,
} from '@client/modules/InsightsPage/duck';
import type {
  OnboardingInsightContent,
  OnboardingInsightUpdateIncoming,
} from '@client/modules/InsightsPage/insightCardModels';
import { OnboardingStepName, OnboardingStepStatus } from '@client/modules/InsightsPage/insightCardModels';
import type { Insight } from '@client/modules/InsightsPage/models';
import { resolveInsightCard, updateInsight } from '@client/modules/InsightsPage/models';
import { EnableNotificationsDialog } from '@client/modules/overview-onboarding-cards/components/EnableNotificationsDialog/EnableNotificationsDialog';
import { OverviewOnboardingCard } from '@client/modules/overview-onboarding-cards/components/OverviewOnboardingCard';
import { selectorUnresolvedSubscriptions, updateUnresolvedSubscriptions } from '@client/modules/overview/duck';
import { ResolveSubscriptionsDialog } from '@client/modules/subscription/unresolved-guide/components/ResolveSubscriptionsDialog';
import * as urls from '@client/routes';
import { getMixpanel } from '@client/tracking/mixpanel';
import {
  EventNames,
  FEATURE_OVERVIEW,
  FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
  InsightCardNames,
  TrackingPageName,
} from '@client/tracking/mixpanel-constants';
import { isOver12MonthsAgo } from '@client/utils/date';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@minna-technologies/minna-ui/components/CircularProgress';
import { isNil } from 'lodash/fp';
import moment from 'moment';
import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cancel from '../assets/svgs/cancel.svg';
import notifications from '../assets/svgs/notifications.svg';
import rate from '../assets/svgs/rate.svg';
import unresolvedSubscription from '../assets/svgs/unresolved-subscriptions.svg';

const cardsOrder: OnboardingStepName[] = [
  OnboardingStepName.ResolveSubscriptions,
  OnboardingStepName.SeeCancellableSubscriptions,
  OnboardingStepName.EnableNotifications,
  OnboardingStepName.Feedback,
];

const getCardsContent = () => {
  return {
    [OnboardingStepName.ResolveSubscriptions]: {
      cornerImage: unresolvedSubscription,
      title: <LocalizedMessage id={'resolveTitle'} />,
      body: <LocalizedMessage id={'resolveBody'} />,
      buttonLabel: <LocalizedMessage id={'resolveButton'} />,
      dataTest: 'resolve-subscriptions',
    },
    [OnboardingStepName.SeeCancellableSubscriptions]: {
      cornerImage: cancel,
      title: <LocalizedMessage id={'cancelTitle'} />,
      body: <LocalizedMessage id={'cancelBody'} />,
      buttonLabel: <LocalizedMessage id={'cancelButton'} />,
      dataTest: 'see-cancellable-subscriptions',
    },
    [OnboardingStepName.EnableNotifications]: {
      cornerImage: notifications,
      title: <LocalizedMessage id={'notificationsTitle'} />,
      body: <LocalizedMessage id={'notificationsBody'} />,
      buttonLabel: <LocalizedMessage id={'notificationsButton'} />,
      dataTest: 'enable-notifications',
    },
    [OnboardingStepName.Feedback]: {
      cornerImage: rate,
      title: <LocalizedMessage id={'rateTitle'} />,
      body: <LocalizedMessage id={'rateBody'} />,
      buttonLabel: <LocalizedMessage id={'rateButton'} />,
      dataTest: 'feedback',
    },
    // adding the rest to make typescript happy
    [OnboardingStepName.AddBank]: {
      cornerImage: unresolvedSubscription,
      title: <></>,
      body: <></>,
      buttonLabel: <></>,
      dataTest: 'add-bank',
    },
    [OnboardingStepName.SignUp]: {
      cornerImage: unresolvedSubscription,
      title: <></>,
      body: <></>,
      buttonLabel: <></>,
      dataTest: 'sign-up',
    },
  };
};

function clickedButtonMixpanelEvent(buttonName: string, location: string, extra: any) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_OVERVIEW,
    Page: TrackingPageName.INSIGHTS,
    Card: InsightCardNames.ONBOARDING_INSIGHT,
    Button: buttonName,
    Location: location,
    ...extra,
  });
}

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '248px',
    alignItems: 'center',
  },
  transparentDialog: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    width: '100%',
    maxWidth: 'none',
    margin: '32px 0',
  },
}));

const OnboardingOverviewComponentInner: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const features = useSelector(selectorFeatures);
  const discoverCardSeenAt = useSelector(selectorViewedOnboardingCardDiscoverAt);
  const rateCardSeenAt = useSelector(selectorViewedOnboardingCardRateAt);
  const unresolvedSubscriptions = useSelector(selectorUnresolvedSubscriptions);
  const insights = useSelector(selectorFetchableInsights);
  const maybeOnboardingInsight = useSelector(selectorOnboardingInsight);

  const [currentCard, setCurrentCard] = useState<ReactNode>(null);
  const [enableNotificationsDialogOpen, setEnableNotificationsDialogOpen] = useState(false);
  const [resolveSubscriptionsDialogOpen, setResolveSubscriptionsDialogOpen] = useState(false);

  // These states are only to prevent useEffect from triggering duplicate function calls
  const [currentStep, setCurrentStep] = useState<OnboardingStepName | null>(null);
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const [patchedDiscoverViewedAt, setPatchedDiscoverViewedAt] = useState(false);
  const [patchedFeedbackViewedAt, setPatchedFeedbackViewedAt] = useState(false);

  const isLoading = insights.type !== LoadingState.Success;

  const updateOnboardingInsightSteps = useCallback(
    (insight: Insight, cardType: OnboardingStepName) => {
      if (cardType !== OnboardingStepName.Feedback) {
        // set step as 'Done'
        updateOnboardingInsight(insight, cardType)
          .then(() => dispatch(InsightActions.fetchInsights()))
          .catch((err) => Sentry.captureMessage('Failed to update insight', err, 'info'));
      }
    },
    [dispatch]
  );

  const updateVisitedPagesForRate = useCallback(() => {
    dispatch(setUserVisitedPage(VIEWED_RATE_ONBOARDING_CARD_AT, moment().toString()));
    saveUserViewedRateOnboardingCard()
      .then((user) => dispatch(setUser(user)))
      .catch((error) => {
        Sentry.captureExceptionWithMessage(error, 'Failed to save rate onboarding card viewed to visited page');
      });
  }, [dispatch]);

  const onCloseCard = useCallback(
    (insight: Insight | undefined, cardType: OnboardingStepName) => {
      if (insight) {
        updateOnboardingInsightSteps(insight, cardType);
      }
      getMixpanel().track(EventNames.CLICKED_BUTTON, {
        Feature: FEATURE_OVERVIEW,
        Type: 'Main',
        'Onboarding card': cardType,
        Location: 'Top right',
        Button: 'Close',
      });
      switch (cardType) {
        case OnboardingStepName.Feedback:
          updateVisitedPagesForRate();
          setCurrentCard(null);
          break;
        default:
          break;
      }
    },
    [updateOnboardingInsightSteps, updateVisitedPagesForRate]
  );

  const openUnresolvedSubscriptionsGuide = useCallback(() => {
    if (features.useUnresolvedSubscriptionGuide) {
      dispatch(updateUnresolvedSubscriptions({ showSuccessAfterGuide: true, unresolvedSubscriptions }));
    }
    setResolveSubscriptionsDialogOpen(true);
  }, [dispatch, features.useUnresolvedSubscriptionGuide, unresolvedSubscriptions]);

  const onContinueCard = useCallback(
    (insight: Insight | undefined, cardType: OnboardingStepName) => {
      if (cardType !== OnboardingStepName.ResolveSubscriptions) {
        if (insight) {
          updateOnboardingInsightSteps(insight, cardType);
        }
      }
      getMixpanel().track(EventNames.CLICKED_BUTTON, {
        Feature: FEATURE_OVERVIEW,
        Type: 'Main',
        'Onboarding card': cardType,
        Location: 'Bottom right',
        Button: 'Continue',
      });
      switch (cardType) {
        case OnboardingStepName.ResolveSubscriptions:
          openUnresolvedSubscriptionsGuide();
          break;
        case OnboardingStepName.EnableNotifications:
          setEnableNotificationsDialogOpen(true);
          break;
        case OnboardingStepName.SeeCancellableSubscriptions:
          history.push(urls.quickActionsCancel, { previousUrl: history.location.pathname });
          break;
        case OnboardingStepName.Feedback:
          updateVisitedPagesForRate();
          history.push(urls.csatNps, { previousUrl: urls.overviewPage });
          getMixpanel().track(EventNames.VIEWED_GUIDE, {
            Feature: FEATURE_OVERVIEW,
            Type: 'CSAT rating',
            Page: 'Feedback card',
          });
          break;
        default:
      }
    },
    [history, openUnresolvedSubscriptionsGuide, updateOnboardingInsightSteps, updateVisitedPagesForRate]
  );

  function navigateToFirstUnresolvedSubscription() {
    if (unresolvedSubscriptions[0]) {
      history.push(urls.unresolvedSubscriptionGuidePath(unresolvedSubscriptions[0].id));
    } else {
      history.push(urls.overviewPage);
    }
  }

  const setNextCustomCard = useCallback(
    (cardType: OnboardingStepName, onboardingInsight: Insight | undefined) => {
      if (currentStep !== cardType) {
        const cardsContent = getCardsContent();

        getMixpanel().track('Viewed onboarding', {
          Feature: FEATURE_OVERVIEW,
          Type: 'Main',
          'Onboarding card': cardType,
        });

        const card = cardsContent[cardType];
        setCurrentStep(cardType);
        setCurrentCard(
          <OverviewOnboardingCard
            cornerImage={card.cornerImage}
            title={card.title}
            body={card.body}
            buttonLabel={card.buttonLabel}
            dataTest={card.dataTest}
            onContinue={() => onContinueCard(onboardingInsight, cardType)}
            onClose={() => onCloseCard(onboardingInsight, cardType)}
            progress={Math.ceil(((cardsOrder.indexOf(cardType) + 1) / cardsOrder.length) * 100)}
            key={cardType}
          />
        );
      }
    },
    [currentStep, onContinueCard, onCloseCard]
  );

  const resolveOnboardingInsight = useCallback(
    (onboardingInsight: Insight) => {
      resolveInsightCard(onboardingInsight.id).then(() => dispatch(InsightActions.fetchInsights()));
      setCurrentCard(null);
    },
    [dispatch]
  );

  useEffect(() => {
    // Edge case for existing users that haven't seen discover card, automatically set it as seen
    if (!isLoading && !patchedDiscoverViewedAt && isNil(maybeOnboardingInsight) && isNil(discoverCardSeenAt)) {
      setPatchedDiscoverViewedAt(true);
    }

    // Edge case for existing users that haven't seen feedback card, automatically set it as seen
    if (!isLoading && !patchedFeedbackViewedAt && isNil(maybeOnboardingInsight) && isNil(rateCardSeenAt)) {
      updateVisitedPagesForRate();
      setPatchedFeedbackViewedAt(true);
    }
  }, [
    isLoading,
    maybeOnboardingInsight,
    discoverCardSeenAt,
    rateCardSeenAt,
    updateVisitedPagesForRate,
    patchedDiscoverViewedAt,
    patchedFeedbackViewedAt,
  ]);

  useEffect(() => {
    if (!isOnboardingCardsVisible(maybeOnboardingInsight, discoverCardSeenAt, rateCardSeenAt)) return;
    if (isLoading) return;

    // OnboardingInsight exists and is not resolved
    if (!isNil(maybeOnboardingInsight)) {
      for (const cardType of cardsOrder) {
        // @ts-ignore
        if (maybeOnboardingInsight.content[cardType] === OnboardingStepStatus.NotDone) {
          setNextCustomCard(cardType, maybeOnboardingInsight);
          return;
        }
      }
    }

    // Feedback card not seen or was over 12 months ago
    if (
      (!isNil(maybeOnboardingInsight) && isNil(rateCardSeenAt)) ||
      (rateCardSeenAt && isOver12MonthsAgo(rateCardSeenAt))
    ) {
      setNextCustomCard(OnboardingStepName.Feedback, maybeOnboardingInsight);
    } else {
      // All cards should have been seen, resolve onboarding insight
      if (!isNil(maybeOnboardingInsight) && !onboardingComplete) {
        resolveOnboardingInsight(maybeOnboardingInsight);
        setOnboardingComplete(true);
      }
    }
  }, [
    maybeOnboardingInsight,
    isLoading,
    discoverCardSeenAt,
    rateCardSeenAt,
    setNextCustomCard,
    resolveOnboardingInsight,
    onboardingComplete,
  ]);

  return (
    <>
      {isLoading && !currentCard && (
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" size={24} thickness={6} />
        </div>
      )}
      {currentCard}
      {resolveSubscriptionsDialogOpen && (
        <ResolveSubscriptionsDialog
          open={resolveSubscriptionsDialogOpen}
          unresolvedSubscriptionsNumber={unresolvedSubscriptions.length}
          onClose={() => {
            clickedButtonMixpanelEvent('Close', 'Top right', {
              'Number of unanswered subscriptions in guide': unresolvedSubscriptions.length,
              Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
              Type: 'Contract guide',
              Page: 'Introduction',
            });
            setResolveSubscriptionsDialogOpen(false);
          }}
          onGotoResolveSubscriptionsGuide={() => {
            clickedButtonMixpanelEvent('Continue', 'Center', {
              'Number of unanswered subscriptions in guide': unresolvedSubscriptions.length,
              Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
              Type: 'Contract guide',
              Page: 'Introduction',
            });
            if (maybeOnboardingInsight) {
              updateOnboardingInsightSteps(maybeOnboardingInsight, OnboardingStepName.ResolveSubscriptions);
            }
            navigateToFirstUnresolvedSubscription();
          }}
        />
      )}
      {enableNotificationsDialogOpen && (
        <EnableNotificationsDialog
          onClose={(reason?: string) => {
            if (!reason || reason === 'backdropClick') {
              setEnableNotificationsDialogOpen(false);
            }
          }}
          open={enableNotificationsDialogOpen}
          onSkipClicked={() => {
            setEnableNotificationsDialogOpen(false);
          }}
          onEnableNotificationClicked={() => {
            requestPushNotificationPermission();
            setEnableNotificationsDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export const isOnboardingCardsVisible = (
  maybeOnboardingInsight: Insight | undefined,
  discoverCardSeenAt: string | undefined,
  rateCardSeenAt: string | undefined
): boolean => {
  return (
    !isNil(maybeOnboardingInsight) ||
    (!isNil(discoverCardSeenAt) && isNil(rateCardSeenAt)) ||
    (isNil(discoverCardSeenAt) && isNil(rateCardSeenAt)) ||
    (!isNil(rateCardSeenAt) && isOver12MonthsAgo(rateCardSeenAt))
  );
};

export async function updateOnboardingInsight(onboardingInsight: Insight, stepName: OnboardingStepName): Promise<void> {
  const updatedOnboardingSteps = {
    ...(onboardingInsight.content as unknown as OnboardingInsightContent),
    [stepName]: OnboardingStepStatus.Done,
  };

  // Call the api to save user input
  const payload: OnboardingInsightUpdateIncoming = {
    type: 'OnboardingInsightUpdateIncoming',
    content: updatedOnboardingSteps,
  };

  return updateInsight(onboardingInsight.id, payload);
}

export const OnboardingOverviewComponent = withLocalization('overview/OnboardingCards')(
  OnboardingOverviewComponentInner
);
