import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { paymentIntervalsPer } from '@client/models/payment-intervals';
import type { Currency } from '@client/models/subscription';
// @ts-ignore
import Form from '@client/modules/shared-components/form-validation/Form';
import { SelectField } from '@client/modules/shared-components/inputs/SelectField';
import { CurrencyTextField } from '@client/modules/shared-components/inputs/TextField/CurrencyTextField';
import { PerInterval } from '@client/modules/shared-components/interval-to-string';
import type { PaymentInterval } from '@client/modules/subscription/types';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import type { FunctionComponent } from 'react';
import React from 'react';

interface UpdatePriceFormProps {
  amount: number;
  currency: Currency;
  paymentInterval: PaymentInterval;
  onAmountChange(value: string): void;
  onPaymentIntervalChange(value: PaymentInterval): void;
}

const paymentIntervalMenuItems = paymentIntervalsPer.map((singleInterval: any) => {
  const interval = singleInterval.interval;

  return (
    <MenuItem value={interval} key={JSON.stringify(interval)}>
      <PerInterval interval={interval} showPrefix={false} />
    </MenuItem>
  );
});

const UpdatePriceFormComponent: FunctionComponent<UpdatePriceFormProps> = (props, { localizeMessage }) => {
  return (
    <Form>
      <div>
        <CurrencyTextField
          defaultValue={props.amount}
          name="pricing"
          requiredErrorText={localizeMessage('costRequiredError')}
          label={localizeMessage('costInputLabel')}
          fullWidth
          currency={props.currency}
          placeholder={props.currency}
          onChange={(e) => {
            props.onAmountChange(e.target.value);
          }}
        />
      </div>
      <div>
        <SelectField
          defaultValue={props.paymentInterval}
          name="interval"
          label={<LocalizedMessage id="paymentIntervalInputLabel" />}
          fullWidth
          requiredErrorText={<LocalizedMessage id="paymentIntervalRequiredError" />}
          onChange={(e) => props.onPaymentIntervalChange(e.target.value as unknown as PaymentInterval)}
          renderValue={(value) => <PerInterval interval={value as unknown as PaymentInterval} />}
        >
          {paymentIntervalMenuItems}
        </SelectField>
      </div>
    </Form>
  );
};

UpdatePriceFormComponent.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const UpdatePriceForm = withLocalization('subscription/WhatsWrongDialog')(UpdatePriceFormComponent);
