import { Sentry } from '@client/assets/js/sentry';
import requireLoggedInUser from '@client/containers/requireLoggedInUser';
import { clearState } from '@client/foundation/state/store';
import { withLocalization } from '@client/internationalization';
// @ts-ignore js import, remove this when the import is typed
import { deleteUser } from '@client/models/user';
import { selectorLocale } from '@client/modules/app-context/duck';
import * as urls from '@client/routes';
import type { AxiosError } from 'axios';
import type { History } from 'history';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import {
  selectorIsStandaloneAndSwedbankUser,
  selectorReasonText,
  selectorSelectedReason,
  setOtherReasonText,
  setReason,
} from '../delete-user';
import type { UserDeletePageProps } from './component';
import { UserDeletePage } from './component';

interface InnerProps {
  history: History;
}

const getDeleteAccountMessageId = (isStandaloneAndSwedbankUser: boolean) => {
  if (isStandaloneAndSwedbankUser) {
    return 'userDeletePageStandaloneAndSwedbankUserDeletionMessage';
  } else {
    return 'userDeletePageStandaloneUserDeletionMessage';
  }
};

interface StateProps {
  deleteAccountMessageId: string;
  selectedReason: string;
  reasonText: string;
  locale: string;
}

interface DispatchProps {
  onSelectReason(selectedReason: any): void;
  onChangeOtherReasonText(otherReasonText: string): void;
  onBackClick(): void;
  onConfirm(reasonText: string, locale: string): void;
}

const mapStateToProps = (state: any): StateProps => {
  const isStandaloneAndSwedbankUser = selectorIsStandaloneAndSwedbankUser(state);

  return {
    deleteAccountMessageId: getDeleteAccountMessageId(isStandaloneAndSwedbankUser),
    selectedReason: selectorSelectedReason(state),
    reasonText: selectorReasonText(state),
    locale: selectorLocale(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { history }: InnerProps): DispatchProps => ({
  onSelectReason: (selectedReason: any) => dispatch(setReason(selectedReason)),
  onChangeOtherReasonText: (otherReasonText: string) => dispatch(setOtherReasonText(otherReasonText)),
  onBackClick: history.goBack,
  onConfirm: (reasonText: string, locale: string) => {
    deleteUser(reasonText)
      .then(() => {
        dispatch(clearState());
        history.push(`${urls.userDeleteSuccessPage}?locale=${locale}`);
      })
      .catch((error: AxiosError) => Sentry.captureExceptionWithMessage(error, 'Failed to delete user'));
  },
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onConfirm: () => {
    dispatchProps.onConfirm(stateProps.reasonText, stateProps.locale);
  },
});

export const UserDeletePageContainer = compose<UserDeletePageProps, Record<string, unknown>>(
  requireLoggedInUser,
  withRouter,
  withLocalization('userSettings/UserDeletePage'),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(UserDeletePage);
