import type { User } from '@client/models/user-models';
import type { ZendeskLabel } from '@client/zendesk';
import useTheme from '@material-ui/core/styles/useTheme';
import { IconButton } from '@minna-technologies/minna-ui/components/Buttons/IconButton';
import { HelpIcon } from '@minna-technologies/minna-ui/icons/Help';
import React from 'react';

export interface ZendeskHeaderHelpButtonComponent {
  user: User;
  query: ZendeskLabel;
  className?: string;
  onOpenDialog: any;
  iconColor?: string;
}

export const ZendeskHeaderHelpButtonComponent: React.FunctionComponent<ZendeskHeaderHelpButtonComponent> = ({
  user,
  query,
  className,
  onOpenDialog,
  iconColor,
}) => {
  const defaultIconColor = useTheme().colors.action.primary;

  return (
    <IconButton onClick={() => onOpenDialog(query, user)} className={className} data-test="navbar-zendesk-button">
      <HelpIcon nativeColor={iconColor || defaultIconColor} />
    </IconButton>
  );
};
