import { LocalizedMessage } from '@client/internationalization';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import size from 'lodash/size';
import slice from 'lodash/slice';
import take from 'lodash/take';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ARTICLES_FILTERED_BY_CATEGORY,
  ARTICLES_FILTERED_BY_LABELS,
  ARTICLES_FILTERED_BY_SECTION,
  ARTICLES_FILTERED_BY_USER_SEARCH,
} from '../../../ducks/zendesk-help-center';
import { ArticleResultItem } from './articlesResultItem';
import styles from './styles.scss';

const useStyles = makeStyles((theme) => ({
  articlesResultCard: {
    padding: theme.spacing(2, 0, 1),
  },
  articlesResultSubheader: {
    padding: theme.spacing(0, 2),
  },
}));

const DEFAULT_NUMBER_VISIBLE_ARTICLES = 3;
const INCREMENT_ADDITIONAL_ARTICLES_AMOUNT = 3;

interface IProps {
  currentArticle: any;
  articles: any;
  numberOfAdditionalArticlesToDisplay: number;
  articlesFilteredBy: any;
  onNavigateToArticle: any;
  increaseNumberOfAdditionalArticlesToDisplay: any;
  embedded?: boolean;
}

export const ArticlesResult: React.FunctionComponent<IProps> = ({
  currentArticle,
  articles,
  numberOfAdditionalArticlesToDisplay,
  articlesFilteredBy,
  onNavigateToArticle,
  increaseNumberOfAdditionalArticlesToDisplay,
  embedded,
}) => {
  const classes = useStyles();

  const title = () => {
    switch (articlesFilteredBy) {
      case ARTICLES_FILTERED_BY_USER_SEARCH:
        return <LocalizedMessage id="articleResultTitleSearchResult" />;
      case ARTICLES_FILTERED_BY_LABELS:
      case ARTICLES_FILTERED_BY_SECTION:
        return <LocalizedMessage id="articleResultTitleRelatedArticles" />;
      case ARTICLES_FILTERED_BY_CATEGORY:
        return <LocalizedMessage id="articleResultTitltaPopularArticles" />;
      default:
        return undefined;
    }
  };

  const isViewingArticle = !isUndefined(currentArticle);
  const isArticleCurrentArticle = (articleId: any) => get(currentArticle, 'id') === articleId;
  const articlesWithoutCurrentArticle = isViewingArticle
    ? filter(articles, (article: any) => !isArticleCurrentArticle(article.id))
    : articles;

  // Show all results if we are showing search results or viewing the category results,
  // otherwise show default number of articles.
  const isSearchResults = articlesFilteredBy === ARTICLES_FILTERED_BY_USER_SEARCH;
  const isSectionResults = articlesFilteredBy === ARTICLES_FILTERED_BY_SECTION && !isViewingArticle;
  const numberOfVisibleArticles =
    isSearchResults || isSectionResults ? size(articlesWithoutCurrentArticle) : DEFAULT_NUMBER_VISIBLE_ARTICLES;

  const maximumNumberOfArticlesToBeDisplayed =
    articlesWithoutCurrentArticle.length < numberOfVisibleArticles
      ? articlesWithoutCurrentArticle.length
      : numberOfVisibleArticles;

  const articleListItems = map(take(articlesWithoutCurrentArticle, numberOfVisibleArticles), (article: any, index) => {
    return (
      <div key={article.id}>
        <ArticleResultItem article={article} onNavigateToArticle={onNavigateToArticle} />
        {index !== maximumNumberOfArticlesToBeDisplayed - 1 && <Divider slim />}
      </div>
    );
  });

  // If there are more articles to display and we're viewing an article, display the show more button.
  // Increases the amount of additional items by 3 when clicked.
  const moreArticlesToDisplay =
    numberOfVisibleArticles + numberOfAdditionalArticlesToDisplay < size(articlesWithoutCurrentArticle);
  const showMoreButton =
    moreArticlesToDisplay && isViewingArticle ? (
      <div className={styles['show-more-button-container']}>
        <Button onClick={() => increaseNumberOfAdditionalArticlesToDisplay(INCREMENT_ADDITIONAL_ARTICLES_AMOUNT)}>
          <div className={styles['show-more-button']}>
            <LocalizedMessage id="articleResultShowMoreButton" />
          </div>
        </Button>
      </div>
    ) : null;

  // If requested to see additional items
  const additionalArticlesIndex = numberOfVisibleArticles + numberOfAdditionalArticlesToDisplay;
  const additionalArticlesToDisplay = slice(
    articlesWithoutCurrentArticle,
    numberOfVisibleArticles,
    additionalArticlesIndex
  );

  const additionalArticlesToDisplayLength = additionalArticlesToDisplay.length;

  const additionalArticleToDisplayListItems =
    numberOfAdditionalArticlesToDisplay !== 0 && isViewingArticle
      ? map(additionalArticlesToDisplay, (article: any, index) => (
          <div>
            <ArticleResultItem key={article.id} article={article} onNavigateToArticle={onNavigateToArticle} />
            {index !== additionalArticlesToDisplayLength - 1 && <Divider slim />}
          </div>
        ))
      : null;

  const ResultsContainer = ({ children }: { children: React.ReactNode }) => {
    if (embedded) {
      return <div>{children}</div>;
    }

    return (
      <Card className={classes.articlesResultCard} variant="border">
        {children}
      </Card>
    );
  };

  return (
    <ResultsContainer>
      <div className={classes.articlesResultSubheader}>
        <Subheading2>{title()}</Subheading2>
      </div>
      {articleListItems}
      {additionalArticleToDisplayListItems}
      {showMoreButton}
    </ResultsContainer>
  );
};

ArticlesResult.propTypes = {
  currentArticle: PropTypes.object,
  articles: PropTypes.array.isRequired,
  numberOfAdditionalArticlesToDisplay: PropTypes.number.isRequired,
  increaseNumberOfAdditionalArticlesToDisplay: PropTypes.func.isRequired,
  articlesFilteredBy: PropTypes.string.isRequired,
  onNavigateToArticle: PropTypes.func.isRequired,
};
