import { useLocalization } from '@client/internationalization';
import type { AppFeatureToggles } from '@client/modules/app-context';
import { isCancellationEnabled, isChangePaymentEnabled } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import useTheme from '@material-ui/core/styles/useTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import { CancelActionButton } from '@minna-technologies/minna-ui/components/Buttons/ActionButton/Cancel';
import { ChangePaymentActionButton } from '@minna-technologies/minna-ui/components/Buttons/ActionButton/ChangePayment';
import { ButtonText } from '@minna-technologies/minna-ui/components/Typography/ButtonText';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.scss';

export interface QuickActionsComponentProps {
  onCancellationsClicked(): void;
  onChangePaymentsClicked(): void;
  onCancelAnySubscriptionClicked(): void;
}
withStyles({
  root: {
    display: 'contents',
  },
})(Fab);
const isEdge = navigator.userAgent.includes('Edg');
withStyles({
  root: {
    lineHeight: '16px',
    cursor: 'pointer',
    marginTop: isEdge ? '8px' : '-8px',
  },
})(ButtonText);
export const QuickActions: React.ComponentType<QuickActionsComponentProps> = ({
  onCancellationsClicked,
  onChangePaymentsClicked,
  onCancelAnySubscriptionClicked,
}: QuickActionsComponentProps) => {
  const { localizeMessage } = useLocalization('overview/QuickActionsV2');
  const featureToggles: AppFeatureToggles = useSelector(selectorFeatures);
  const { colors } = useTheme();
  const cancellationEnabled = isCancellationEnabled(featureToggles.actionFeatures);
  const changePaymentEnabled = isChangePaymentEnabled(featureToggles.actionFeatures);
  const searchEntryPointEnabled = featureToggles.identify?.identifyOptIn;
  const cancelButton =
    cancellationEnabled && !searchEntryPointEnabled ? (
      <CancelActionButton
        className={styles.actionButton}
        label={localizeMessage('cancelASubscription')}
        onClick={onCancellationsClicked}
        data-test="cancel-action-button"
      />
    ) : (
      <></>
    );

  const cancelAnySubscriptionButton =
    cancellationEnabled && searchEntryPointEnabled ? (
      <CancelActionButton
        className={styles.actionButton}
        label={localizeMessage('cancelAnySubscription')}
        onClick={onCancelAnySubscriptionClicked}
        data-test="cancel-any-subscription-action-button"
      />
    ) : (
      <></>
    );

  const changePaymentButton = changePaymentEnabled ? (
    <ChangePaymentActionButton
      className={styles.actionButton}
      label={localizeMessage('changePaymentOnASubscription')}
      onClick={onChangePaymentsClicked}
      data-test="change-payment-action-button"
    />
  ) : (
    <></>
  );

  const wideContainerForIcons = cancellationEnabled && changePaymentEnabled;

  function generateActionsBlock() {
    return (
      <div style={{ display: 'inline-block' }}>
        <div className={styles[wideContainerForIcons ? 'actions-inner-wide' : 'actions-inner']}>
          {cancelButton}
          {changePaymentButton}
          {cancelAnySubscriptionButton}
        </div>
      </div>
    );
  }

  return cancellationEnabled ? (
    <Paper
      elevation={0}
      square
      style={{
        padding: '0 8px 8px 8px',
        backgroundColor: colors.base.surface,
      }}
    >
      <Divider />
      <div className={styles['actions-container']} style={{ textAlign: 'center' }}>
        {generateActionsBlock()}
      </div>
    </Paper>
  ) : (
    <></>
  );
};
