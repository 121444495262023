import Slide from '@material-ui/core/Slide';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { WhiteSecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/WhiteSecondaryButton';
import { ProgressBar } from '@minna-technologies/minna-ui/components/ProgressBar';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { CloseIcon } from '@minna-technologies/minna-ui/icons/Close';
import type { ReactNode } from 'react';
import React from 'react';
import background from '../../assets/svgs/background.svg';

interface OverviewOnboardingCardProps {
  cornerImage: string;
  title: ReactNode;
  body: ReactNode;
  buttonLabel: ReactNode;
  progress: number;
  dataTest: string;

  onContinue(): void;

  onClose(): void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    margin: '16px 8px',
    padding: '16px 0',
  },
  background: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: theme.borderRadius,
    boxShadow: theme.cardShadow,
    overflow: 'hidden',
  },
  imageBox: {
    position: 'absolute',
    bottom: '-4px',
  },
  contentBox: {
    position: 'relative',
    padding: '0 16px',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  bodyBox: {
    marginBottom: '16px',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
}));

export const OverviewOnboardingCard: React.FunctionComponent<OverviewOnboardingCardProps> = (props) => {
  const classes = useStyles();
  const { colors } = useTheme();

  return (
    <Slide direction="right" in>
      <div className={classes.container} data-test={props.dataTest}>
        <div className={classes.background}>
          <img src={background} style={{ height: '100vh' }} alt={'Blue card background'} />
        </div>
        <div className={classes.imageBox}>
          <img src={props.cornerImage} alt={'Blue card illustration'} />
        </div>
        <div className={classes.contentBox}>
          <div className={classes.titleBox}>
            <Headline3 color={colors.base.surface}>{props.title}</Headline3>
            <CloseIcon
              nativeColor={colors.base.surface}
              onClick={props.onClose}
              data-test="onboarding-card-button-dismiss"
            />
          </div>
          <div className={classes.bodyBox}>
            <Body color={colors.base.surface}>{props.body}</Body>
          </div>
          <ProgressBar value={props.progress} variant={'white'} />
          <div className={classes.buttonBox}>
            <WhiteSecondaryButton
              label={props.buttonLabel}
              onClick={props.onContinue}
              data-test={'onboard-card-continue'}
            />
          </div>
        </div>
      </div>
    </Slide>
  );
};
