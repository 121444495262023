import { useLocalization } from '@client/internationalization';
import type { PastAction } from '@client/models/cancellation';
import { CancellationOutcome, CancellationState } from '@client/modules/cancellation/models';
import { parseDate } from '@client/utils/date';
import { makeStyles } from '@material-ui/styles';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';

interface PastActionCardTextProps {
  pastAction: PastAction;
}

const useStyles = makeStyles({
  cardTextBody: {
    flexGrow: 1,
  },
});

export const PastActionCardText = (props: PastActionCardTextProps) => {
  const classes = useStyles();
  const { localizeMessage, localizeDate } = useLocalization('subscriptionHub/subscriptionHub');

  const getText = (pastAction: PastAction): string[] => {
    if (pastAction.status !== CancellationState.Finished) {
      return [
        localizeMessage('cancellationOngoingWaitingForSupplier', {
          merchant: pastAction.merchantName,
          date: localizeDate(parseDate(pastAction.createdAt)),
        }),
      ];
    } else {
      switch (pastAction.outcome) {
        case CancellationOutcome.Terminated:
          if (pastAction.bindingPeriodEndsAt) {
            return [
              localizeMessage('cancellationSuccessWithDate', {
                merchant: pastAction.merchantName,
                date: localizeDate(parseDate(pastAction.bindingPeriodEndsAt)),
              }),
            ];
          } else {
            return [localizeMessage('cancellationSuccessNoDate', { merchant: pastAction.merchantName })];
          }
        case CancellationOutcome.CancelledWithUpcomingPayments:
          return [
            localizeMessage('cancellationSuccessWithUpcomingPayments', {
              merchant: pastAction.merchantName,
            }),
          ];
        case CancellationOutcome.AlreadyTerminated:
          return [localizeMessage('cancellationSuccessAlreadyCancelled')];
        case CancellationOutcome.PayingThroughThirdParty:
          return [localizeMessage('cancellationFailedPayingThroughThirdParty')];
        case CancellationOutcome.NoAnswerFromUser:
          return [localizeMessage('cancellationFailedNoAnswerFromUser')];
        case CancellationOutcome.NoAnswerFromSupplier:
          return [
            localizeMessage('cancellationFailedNoResponseFromServiceProvider1', {
              merchant: pastAction.merchantName,
            }),
            localizeMessage('cancellationFailedNoResponseFromServiceProvider2'),
          ];
        case CancellationOutcome.UserNotTheOwner:
          return [
            localizeMessage('cancellationFailedUserIsNotOwner1'),
            localizeMessage('cancellationFailedUserIsNotOwner2'),
          ];
        case CancellationOutcome.NoSuchAccount:
          return [
            localizeMessage('cancellationFailedNoSuchAccount1', { merchant: pastAction.merchantName }),
            localizeMessage('cancellationFailedNoSuchAccount2'),
          ];
        case CancellationOutcome.WrongUserInput:
          return [
            localizeMessage('cancellationFailedWrongUserInput1'),
            localizeMessage('cancellationFailedWrongUserInput2'),
          ];
        case CancellationOutcome.HasBindingPeriod:
          if (pastAction.bindingPeriodEndsAt) {
            return [
              localizeMessage('cancellationFailedHasBindingPeriodWithDate1', {
                bindingPeriodDate: localizeDate(parseDate(pastAction.bindingPeriodEndsAt)),
              }),
              localizeMessage('cancellationFailedHasBindingPeriodWithDate2'),
            ];
          } else {
            return [
              localizeMessage('cancellationFailedHasBindingPeriodNoDate1'),
              localizeMessage('cancellationFailedHasBindingPeriodNoDate2'),
            ];
          }
        case CancellationOutcome.ServiceProviderContactedUser:
          return [
            localizeMessage('cancellationStatePendingServiceProviderContactedUser', {
              merchant: pastAction.merchantName,
            }),
          ];
        case CancellationOutcome.ConfirmedByUser:
          if (pastAction.completedAt) {
            return [
              localizeMessage('cancellationConfirmedByUserWithDate', {
                date: localizeDate(parseDate(pastAction.completedAt)),
              }),
            ];
          } else {
            return [localizeMessage('cancellationConfirmedByUserWithoutDate')];
          }

        default:
          return [''];
      }
    }
  };

  const texts = getText(props.pastAction);

  return (
    <Body className={classes.cardTextBody}>
      {texts[0]}
      {texts[1] && (
        <>
          <br />
          <br />
          {texts[1]}
        </>
      )}
    </Body>
  );
};
