import { LocalizedMessage } from '@client/internationalization';
import useTheme from '@material-ui/core/styles/useTheme';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import React from 'react';
import { OverviewNavigationIconType } from './OverviewNavigationIcon';

interface OverviewNavigationActionLabelProps {
  actionType: OverviewNavigationIconType;
  isSelected: boolean;
  labelText?: string; // Can be used to override the label string set by this component
}

export const OverviewNavigationActionLabel = ({
  actionType,
  isSelected,
  labelText,
}: OverviewNavigationActionLabelProps) => {
  function getLabelText() {
    switch (actionType) {
      case OverviewNavigationIconType.OVERVIEW: {
        return <LocalizedMessage id={labelText ? labelText : 'overview'} />;
      }
      case OverviewNavigationIconType.INSIGHTS: {
        return <LocalizedMessage id={labelText ? labelText : 'insights'} />;
      }
      case OverviewNavigationIconType.DISCOVER: {
        return <LocalizedMessage id={labelText ? labelText : 'discover'} />;
      }
      case OverviewNavigationIconType.MORE: {
        return <LocalizedMessage id={labelText ? labelText : 'more'} />;
      }
      default:
        return null;
    }
  }
  const label = getLabelText();
  const { colors } = useTheme();

  return (
    <Caption
      variant={isSelected ? 'bold' : undefined}
      color={isSelected ? colors.action.primary : colors.textOn.surfaceSubdued}
    >
      {label}
    </Caption>
  );
};
