import { Sentry } from '@client/assets/js/sentry';
import type { TextInputType } from '@minna-technologies/minna-ui/components/Inputs/TextInput';
import { Market } from '../../modules/app-context/constants';

export const postalCodeInputType = (market: Market): TextInputType => {
  switch (market) {
    case Market.Belgium:
    case Market.Denmark:
    case Market.Norway:
    case Market.Spain:
    case Market.Sweden:
      return 'tel';

    case Market.Finland:
    case Market.UnitedKingdom:
      return 'text';

    default:
      Sentry.captureMessage(`No input type is defined for market: ${market}`);
      return 'text';
  }
};

export const postalCodeSupportedValidation = (
  market: Market,
  errorString: string,
  value: string | undefined
): boolean | string => {
  switch (market) {
    case Market.Spain:
      if (value) {
        const valueNoSpace = value.replace(/ /g, '');
        return /\b35[0-9]{3}\b/.test(valueNoSpace) || // Las Palmas Unsupported by Energy Nordic
          /\b38[0-9]{3}\b/.test(valueNoSpace) || // Santa Cruz de Tenerife Unsupported by Energy Nordic
          /\b51[0-9]{3}\b/.test(valueNoSpace) || // Ceuta Unsupported by Energy Nordic
          /\b52[0-9]{3}\b/.test(valueNoSpace) // Melilla Unsupported by Energy Nordic
          ? errorString
          : true;
      }
      return errorString;
    default:
      return true;
  }
};

export const postalCodeValidation = (
  market: Market,
  errorString: string,
  value: string | undefined
): boolean | string => {
  switch (market) {
    case Market.Belgium:
    case Market.Denmark:
      // a Belgian and danish postal code is always 4 digits. The first digit is never 0.
      return value && /\b[1-9][0-9]{3}\b/.test(value.replace(/ /g, '')) ? true : errorString;

    case Market.Finland:
      return value && /\b(FI-)[0-9]{5}\b|^[0-9]{5}/.test(value.replace(/ /g, '')) ? true : errorString;

    case Market.Norway:
      // a Norwegian postal code is always 4 digits
      // https://en.wikipedia.org/wiki/Postal_codes_in_Norway
      return value && /\b[0-9]{4}\b/.test(value.replace(/ /g, '')) ? true : errorString;

    case Market.Spain:
      if (value) {
        const valueNoSpace = value.replace(/ /g, '');
        return /\b03[0-9]{3}\b/.test(valueNoSpace) || // Alicante
          /\b05[0-9]{3}\b/.test(valueNoSpace) || // Ávila
          /\b08[0-9]{3}\b/.test(valueNoSpace) || // Barcelona
          /\b10[0-9]{3}\b/.test(valueNoSpace) || // Cáceres
          /\b13[0-9]{3}\b/.test(valueNoSpace) || // Ciudad Real
          /\b17[0-9]{3}\b/.test(valueNoSpace) || // Girona
          /\b18[0-9]{3}\b/.test(valueNoSpace) || // Granada
          /\b21[0-9]{3}\b/.test(valueNoSpace) || // Huelva
          /\b23[0-9]{3}\b/.test(valueNoSpace) || // Jaén
          /\b25[0-9]{3}\b/.test(valueNoSpace) || // Lleida
          /\b28[0-9]{3}\b/.test(valueNoSpace) || // Madrid
          /\b29[0-9]{3}\b/.test(valueNoSpace) || // Málaga
          /\b30[0-9]{3}\b/.test(valueNoSpace) || // Murcia
          /\b35[0-9]{3}\b/.test(valueNoSpace) || // Las Palmas
          /\b37[0-9]{3}\b/.test(valueNoSpace) || // Salamanca
          /\b38[0-9]{3}\b/.test(valueNoSpace) || // Santa Cruz de Tenerife
          /\b41[0-9]{3}\b/.test(valueNoSpace) || // Seville
          /\b46[0-9]{3}\b/.test(valueNoSpace) || // Valencia
          /\b48[0-9]{3}\b/.test(valueNoSpace) || // Biscay
          /\b51[0-9]{3}\b/.test(valueNoSpace) || // Ceuta
          /\b52[0-9]{3}\b/.test(valueNoSpace) // Melilla
          ? true
          : errorString;
      }
      return errorString;

    case Market.Sweden:
      // a Swedish postal code is always 5 digits. The first digit is never 0.
      return value && /\b[1-9][0-9]{4}\b/.test(value.replace(/ /g, '')) ? true : errorString;

    case Market.UnitedKingdom:
      // see https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Validation
      return value &&
        /^([A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/.test(
          value.replace(/ /g, '')
        )
        ? true
        : errorString;

    default:
      Sentry.captureMessage(`No validation is defined for market: ${market}`);

      return true;
  }
};
