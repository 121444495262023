import { LocalizedMessage } from '@client/internationalization';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';
import { ARTICLES_FILTERED_BY_USER_SEARCH } from '../../../ducks/zendesk-help-center';
import { ZendeskSubHeader } from '../../../ZendeskSubHeader';
import { Index } from '../ArticlesResult';
import styles from './styles.scss';

interface UserSearchResultProps {
  resultHasArticles?: boolean;
}

export const UserSearchResult: React.FC<UserSearchResultProps> = ({ resultHasArticles }) => {
  const searchResult = <Index articlesFilteredBy={ARTICLES_FILTERED_BY_USER_SEARCH} />;
  const noResult = (
    <Card className={styles['padding-top']}>
      <div className={styles['padding-left-right']}>
        <ZendeskSubHeader title={<LocalizedMessage id="userSearchResultTitle" />} />
      </div>
      <div className={styles.padding}>
        <Body>
          <LocalizedMessage id="userSearchNoResult" />
        </Body>
      </div>
    </Card>
  );

  return resultHasArticles ? searchResult : noResult;
};
