import { selectorUser } from '@client/ducks/user';
import { withLocalization } from '@client/internationalization';
import type { Market } from '@client/modules/app-context/constants';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { withAppContext } from '@client/modules/app-context/react';
import { sendRequestToZendesk } from '@client/modules/Zendesk/ducks/zendesk-requests-api-endpoint';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import type { Dispatch } from 'redux';
import { navigateToCallBackFailure, navigateToCallBackSuccess, saveCallBackRequestData } from '../../../ducks';
import { ZendeskCallBackComponent } from './ZendeskCallBackComponent';

const mapStateToProps = (state: any) => ({
  user: selectorUser(state),
  appContext: selectorAppContext(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onNavigateToCallBackSuccess: async (
    requestData: { timeSlotInput: string; phoneInput: string; emailInput: string },
    userData: { name: string; market: Market },
    zendeskBaseUrl: string
  ) => {
    const zendeskApiResponse = await sendRequestToZendesk(requestData, userData, zendeskBaseUrl);

    if (zendeskApiResponse.status === 201) {
      dispatch(saveCallBackRequestData(requestData));
      dispatch(navigateToCallBackSuccess());
    } else {
      dispatch(navigateToCallBackFailure());
    }
  },
});

export const ZendeskCallBackContainer = compose<any, any>(
  withAppContext,
  connect(mapStateToProps, mapDispatchToProps),
  withLocalization('zendesk')
)(ZendeskCallBackComponent);
