import { Sentry } from '@client/assets/js/sentry';
import type { AppContext } from '@client/modules/app-context';
import { isPauseEnabled } from '@client/modules/app-context';
import { clickedContractActionMixpanelEventDeprecated } from '@client/modules/subscription/mixpanel';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import type { History } from 'history';

export const onRequestResume = (
  appContext: AppContext,
  contract: Contract,
  subscription: Subscription,
  history: History
) => {
  if (!isPauseEnabled(appContext.features.actionFeatures)) {
    Sentry.captureMessage(
      'Invalid feature toggling: on resume click in container, assertion failed that pause feature is enabled',
      {
        extra: { features: appContext.features },
      }
    );

    return;
  }

  clickedContractActionMixpanelEventDeprecated(contract, subscription, 'Resume');

  history.push(urls.subscriptionResumePage(subscription.id, contract.id));
};
