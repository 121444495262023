import type { Question } from '@client/models/cancellation-questions';
import type { Locale } from '@client/modules/app-context/constants';
import { requiredQuestionLabel } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/logic';
import { detailsByLocale } from '@client/utils/locale';
import { RHTextInput } from '@minna-technologies/minna-ui/components/Form/RHTextInput';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useStyles } from './styles';

interface TelephoneQuestionProps {
  locale: Locale;
  question: Question;
}

export const TelephoneQuestion: React.FunctionComponent<TelephoneQuestionProps> = (
  { locale, question }: TelephoneQuestionProps,
  { localizeMessage }
) => {
  const { text, description } = detailsByLocale(question.details, locale);
  const required = question.required;
  const label = requiredQuestionLabel(text, required);
  const classes = useStyles();

  return (
    <>
      <Body className={classes.body}>{description}</Body>
      <RHTextInput
        label={label}
        name={question.id}
        type={'tel'}
        inputMode={'tel'}
        autoComplete={'tel'}
        validation={{
          required: required ? localizeMessage('requiredTelephoneNumberError') : false,
        }}
        fullWidth
      />
    </>
  );
};

TelephoneQuestion.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
