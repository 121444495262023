import type { CSSProperties } from 'react';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

interface SubscriptionSearchButtonIconProps {
  color: any;
  style?: CSSProperties;
}

export const SubscriptionSearchButtonIcon: React.FC<SubscriptionSearchButtonIconProps> = ({ color, style }) => {
  return (
    <SvgIcon style={style} viewBox="0 0 48 48">
      <rect width="48" height="48" rx="24" fill={color} />
      <path
        d="M36.0214 28.6112C33.3057 26.3133 30.2768 24.2766 27.3 22.1876C29.7023 19.2109 32.4179 15.973 33.5668 12.5785C33.6713 12.2651 33.2535 11.8473 32.9402 12.0562C29.859 14.1974 27.4044 17.5397 24.741 20.3598C21.5032 17.9053 18.1086 14.6674 14.2441 13.3096C13.4085 12.9963 12.6251 13.9885 13.0429 14.7719C14.7663 18.2709 18.2653 21.0909 20.9809 23.8066C18.3175 26.1044 15.4974 28.1934 12.9385 30.5957C10.1706 33.1546 14.1396 37.5936 16.9075 34.9824C19.4664 32.5279 21.6598 29.6556 23.801 26.7833C25.9422 29.1334 28.0834 31.5879 30.3812 33.6769C31.8957 35.0347 34.0891 35.4002 35.708 33.938C37.1181 32.7368 37.5881 29.9167 36.0214 28.6112Z"
        fill="white"
      />
    </SvgIcon>
  );
};
