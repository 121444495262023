import { useLocalization } from '@client/internationalization';
import { selectorSubscription } from '@client/modules/subscription';
import { buttonClickedSubscriptionPageMixpanelEvent } from '@client/modules/subscription/mixpanel';
import type { ContractId } from '@client/modules/subscription/types';
import { subscriptionPaymentHistoryPage } from '@client/routes';
import { makeStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardHeader } from '@minna-technologies/minna-ui/components/Card/CardHeader';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HistoryGraph } from '../../../ContractDetailsPage/components/HistoryGraph';

const useStyles = makeStyles({
  showMoreButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    cursor: 'pointer',
  },
});

interface Props {
  contractId?: ContractId;
  showViewTransactionsButton?: boolean;
}

export const PriceHistory: FC<Props> = (props) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const { localizeMessage } = useLocalization('contract/PriceHistoryLolipop');
  const history = useHistory();

  const subscription = useSelector(selectorSubscription);

  function showMoreClickHandler() {
    if (subscription) {
      buttonClickedSubscriptionPageMixpanelEvent(subscription, 'See price history', 'Contract details');
      history.push(
        subscriptionPaymentHistoryPage(subscription.id, props.contractId ?? subscription.contracts[0]?.id ?? '')
      );
    }
  }

  return subscription?.bankEvents?.length ? (
    <Card>
      <CardHeader title={localizeMessage('title')} />
      <HistoryGraph />
      {props.showViewTransactionsButton && (props.contractId || subscription.contracts.length >= 1) && (
        <>
          <Divider />
          <div className={classes.showMoreButton} role="button" onClick={showMoreClickHandler}>
            <Subheading2>{localizeMessage('viewTransactions')}</Subheading2>
            <ChevronRightIcon nativeColor={colors.action.primary} />
          </div>
        </>
      )}
    </Card>
  ) : null;
};
