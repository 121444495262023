import { hasOnlyMobilePhoneNumberCharacters } from '@client/assets/js/validators';
// @ts-ignore js import, remove this when the import is typed
import { LocalizedMessage } from '@client/internationalization';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { removeSpacesAndDashes } from '../../utils';

const UnitedKingdomMobilePhoneNumberValidators = {
  correctlyFormatted(value: string = ''): boolean | React.ReactNode {
    const trimmedValue = removeSpacesAndDashes(value);
    const valid =
      isEmpty(value) ||
      (hasOnlyMobilePhoneNumberCharacters(trimmedValue) && startsAsUnitedKingdomMobilePhoneNumber(trimmedValue));

    return valid ? false : <LocalizedMessage id="unitedKingdomMobileFormatError" />;
  },
  correctLength(value: string = ''): boolean | React.ReactNode {
    if (isEmpty(value)) {
      return false;
    }

    const trimmedValue = removeSpacesAndDashes(value);
    const validLength = /^07/.test(trimmedValue) ? 11 : 13;
    if (trimmedValue.length < validLength) {
      return <LocalizedMessage id="mobileNumberTooShortError" />;
    } else if (trimmedValue.length > validLength) {
      return <LocalizedMessage id="mobileNumberTooLongError" />;
    } else {
      return false;
    }
  },
};

export function startsAsUnitedKingdomMobilePhoneNumber(input: string) {
  return /^\+447|07/.test(input);
}

function unitedKingdomMobilePhoneNumberFormatter(value: string): string {
  const trimmedValue = removeSpacesAndDashes(value);
  if (/^\+447\d{9}/.test(trimmedValue)) {
    return `${trimmedValue.substring(0, 3)} ${trimmedValue.substring(3, 7)} ${trimmedValue.substring(
      7,
      13
    )} ${trimmedValue.substring(13)}`;
  }
  if (/^07\d{9}$/.test(trimmedValue)) {
    return `${trimmedValue.substring(0, 5)} ${trimmedValue.substring(5, 11)} ${trimmedValue.substring(11)}`;
  }

  return value;
}

export { UnitedKingdomMobilePhoneNumberValidators, unitedKingdomMobilePhoneNumberFormatter };
