import { hasOnlyMobilePhoneNumberCharacters } from '@client/assets/js/validators';
import { LocalizedMessage } from '@client/internationalization';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { removeSpacesAndDashes } from '../../utils';

const SwedishMobilePhoneNumberValidators = {
  correctlyFormatted(value: string = ''): boolean | React.ReactNode {
    const trimmedValue = removeSpacesAndDashes(value);
    const valid =
      isEmpty(value) ||
      (hasOnlyMobilePhoneNumberCharacters(trimmedValue) && startsAsSwedishMobilePhoneNumber(trimmedValue));

    return valid ? false : <LocalizedMessage id="swedishMobileFormatError" />;
  },
  correctLength(value: string = ''): boolean | React.ReactNode {
    if (isEmpty(value)) {
      return false;
    }
    const trimmedValue = removeSpacesAndDashes(value);
    const validLength = /^07/.test(trimmedValue) ? 10 : 12;
    if (trimmedValue.length < validLength) {
      return <LocalizedMessage id="mobileNumberTooShortError" />;
    } else if (trimmedValue.length > validLength) {
      return <LocalizedMessage id="mobileNumberTooLongError" />;
    } else {
      return false;
    }
  },
};

export function startsAsSwedishMobilePhoneNumber(input: string) {
  return /^(0|\+46)7/.test(input);
}

function swedishMobilePhoneNumberFormatter(value: string): string {
  const trimmedValue = removeSpacesAndDashes(value);
  if (/^\+467\d{8}/.test(trimmedValue)) {
    return `${trimmedValue.substring(0, 3)} ${trimmedValue.substring(3, 5)}-${trimmedValue.substring(
      5,
      8
    )} ${trimmedValue.substring(8, 10)} ${trimmedValue.substring(10)}`;
  }
  if (/^07\d{8}$/.test(trimmedValue)) {
    return `${trimmedValue.substring(0, 3)}-${trimmedValue.substring(3, 6)} ${trimmedValue.substring(
      6,
      8
    )} ${trimmedValue.substring(8)}`;
  }

  return value;
}

export { SwedishMobilePhoneNumberValidators, swedishMobilePhoneNumberFormatter };
