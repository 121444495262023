import type {
  AlternativeDetailsMap,
  QuestionAlternativeDetails,
  QuestionDetails,
  QuestionDetailsMap,
} from '@client/models/cancellation-questions';

export function detailsByLocale(details: QuestionDetailsMap, locale: string): QuestionDetails {
  //TODO: fetch market locale from server
  const marketLocale = 'en-GB';

  const detailsByLocaleWithFallback = details[locale] || details[marketLocale];
  if (!detailsByLocaleWithFallback) {
    throw new Error(`No details found for neither locale ${locale} or market locale ${marketLocale}`);
  }

  return detailsByLocaleWithFallback;
}

export function detailsByLocaleAlternative(details: AlternativeDetailsMap, locale: string): QuestionAlternativeDetails {
  //TODO: fetch market locale from server
  const marketLocale = 'en-GB';

  const detailsByLocaleWithFallback = details[locale] || details[marketLocale];
  if (!detailsByLocaleWithFallback) {
    throw new Error(`No details found for neither locale ${locale} or market locale ${marketLocale}`);
  }

  return detailsByLocaleWithFallback;
}
