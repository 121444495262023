import { Market } from '@client/modules/app-context/constants';
import { NemIdSigning } from '@client/modules/e-signing/nem-id';
import type { History, Location } from 'history';
import React from 'react';
import URI from 'urijs';
import { BankIdSigning } from './bank-id';

export interface ESigningComponentProps {
  navigateToOnSuccess: string;
  history: History;
  location: Location<{ nemIdParameters: any; signResourceRequest: any }>;
  contractId: string;
  signingId: string;
  resourceToSignId: string;
  market: Market;
}

export const ESigningComponent: React.FunctionComponent<ESigningComponentProps> = ({
  history,
  market,
  location,
  navigateToOnSuccess,
  contractId,
  signingId,
  resourceToSignId,
}) => {
  function onSigningComplete(): void {
    history.replace(URI.decode(navigateToOnSuccess), location.state);
  }

  if (market === Market.Denmark && (!location.state.nemIdParameters || !location.state.signResourceRequest)) {
    throw new Error('Missing NemId parameters.');
  }

  return (
    <>
      {market === Market.Sweden && (
        <BankIdSigning
          onSigningComplete={onSigningComplete}
          contractId={contractId}
          signingId={signingId}
          resourceToSignId={resourceToSignId}
          history={history}
        />
      )}
      {market === Market.Denmark && (
        <NemIdSigning
          onSigningComplete={onSigningComplete}
          contractId={contractId}
          signingId={signingId}
          resourceToSignId={resourceToSignId}
          history={history}
          nemIdSigningParameters={location.state.nemIdParameters}
          signResourceRequest={location.state.signResourceRequest}
        />
      )}
    </>
  );
};
