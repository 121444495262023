import type { Dispatch } from '@client/utils/redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { navigateToCallBack } from '../../../../ducks';
import { ZendeskCallBackFailure } from './ZendeskCallBackFailure';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onNavigateToCallBack: () => {
    dispatch(navigateToCallBack());
  },
});

export const ZendeskCallBackFailureContainer = compose<any, any>(connect(() => ({}), mapDispatchToProps))(
  ZendeskCallBackFailure
);
