import Sentry from '@client/assets/js/sentry';
// @ts-ignore js import, remove this when the import is typed
import { loadingPageWhileLoading, onEnter } from '@client/containers/container-helpers';
import { CancellationAction, selectorIsSendingCancellation } from '@client/modules/cancellation/duck';
import { cancellationSignCompleteCallback } from '@client/modules/cancellation/signing/common';
import { requestCancellation } from '@client/modules/cancellation/signing/NoSigningPage/api';
import { selectorContract, selectorSubscription } from '@client/modules/subscription';
import type { Contract, ContractId, Subscription, SubscriptionId } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import type { AxiosError } from 'axios';
import type { History } from 'history';
import type * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { NoSigningPageProps } from './component';
import { NoSigningPageComponent } from './component';

interface ContainerOuterProps {
  subscriptionId: SubscriptionId;
  contractId: ContractId;
}

type ConnectOwnProps = {
  history: History;
} & ContainerOuterProps;

interface StateProps {
  contract?: Contract;
  subscription?: Subscription;
  isSendingCancellation: boolean;
}

interface DispatchProps {
  onBackClick(): void;
  onCancellationSigningComplete(subscription: Subscription, contract?: Contract): void;
  setIsSendingCancellation(isSending: boolean): void;
}

type MergeProps = ContainerOuterProps &
  ConnectOwnProps &
  StateProps &
  DispatchProps & {
    onCancellationSigningComplete(): void;
  };

function mapStateToProps(state: any, { contractId }: ConnectOwnProps): StateProps {
  return {
    contract: selectorContract(state, contractId),
    subscription: selectorSubscription(state),
    isSendingCancellation: selectorIsSendingCancellation(state),
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  { history, subscriptionId, contractId }: ConnectOwnProps
): DispatchProps => ({
  onBackClick: () => {
    history.replace(urls.terminationFormPage(subscriptionId, contractId));
  },
  onCancellationSigningComplete: (subscription: Subscription, contract: Contract) => {
    cancellationSignCompleteCallback(dispatch, history)(subscription, contract);
  },
  setIsSendingCancellation: (isSending: boolean) => {
    dispatch(CancellationAction.setIsSendingCancellation(isSending));
  },
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: ConnectOwnProps): MergeProps => {
  const onCompleteFunction = () => {
    if (stateProps.subscription) {
      dispatchProps.onCancellationSigningComplete(stateProps.subscription, stateProps.contract);
    }
  };

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onCancellationSigningComplete: onCompleteFunction,
  };
};

type NoSigningPageContainerProps = StateProps & DispatchProps & MergeProps & ContainerOuterProps;

export const NoSigningPage: React.ComponentClass<ContainerOuterProps> = compose<
  NoSigningPageProps,
  ContainerOuterProps
>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  onEnter(({ contractId, onCancellationSigningComplete, setIsSendingCancellation }: NoSigningPageContainerProps) => {
    setIsSendingCancellation(true);
    requestCancellation(contractId)
      .then(() => {
        setIsSendingCancellation(false);
        onCancellationSigningComplete();
      })
      .catch((error: AxiosError) => {
        setIsSendingCancellation(false);
        Sentry.captureExceptionWithMessage(error, 'Failed to request cancellation');
      });
  }),
  loadingPageWhileLoading((props: NoSigningPageContainerProps) => props.isSendingCancellation)
)(NoSigningPageComponent);
