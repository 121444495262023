//TODO: Remove this file when OP SEP is ready and replace with RenewConsentPageV2Container. feat[opSEP]
// TODO 2: should we really remove it though? It sounds generic, other banks may have identifyOptIn = false
import type { AppFeatureToggles } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { RenewConsentPageV1Container } from '@client/modules/onboarding/OnboardingPsd2/consent/RenewConsentPage/RenewConsentPageV1';
import { RenewConsentPageV2Container } from '@client/modules/onboarding/OnboardingPsd2/consent/RenewConsentPage/RenewConsentPageV2';
import React from 'react';

interface RenewConsentChooserProps {
  features: AppFeatureToggles;
}

const mapStateToProps = (state: any) => ({
  features: selectorFeatures(state),
});

const RenewConsentChooser = ({ features }: RenewConsentChooserProps) => {
  if (features.identify?.identifyOptIn) {
    return <RenewConsentPageV2Container />;
  } else {
    return <RenewConsentPageV1Container />;
  }
};

export const RenewConsentPageContainer = compose(
  connect(mapStateToProps)
  // @ts-ignore
)(RenewConsentChooser);
