import { alpha } from '@material-ui/core/styles/colorManipulator';

// NOTE: These are legacy! Please don't use them if it can be avoided. Use useTheme() and fetch colors from the theme instead!
export const white = '#ffffff';
export const secondaryWhite = alpha(white, 0.7);
export const green = '#81C784';
export const darkGreen = '#43A047';
export const blueTheme = '#00b5b9';
export const black = '#000000';
export const grey = '#f4f4f4';
export const grey100 = '#f5f5f5';
export const grey200 = '#eee';
export const purple = '#9575CD';
export const orange = '#FFB74D';
export const black87 = alpha(black, 0.87);
export const black54 = alpha(black, 0.54);
export const black38 = alpha(black, 0.38);
export const darkGray = 'rgba(34, 34, 34, 0.8)';
export const yellow = '#FFF176';
export const red = '#E57373';
