import { searchSuppliers } from '@client/assets/js/search-suppliers';
import { useLocalization } from '@client/internationalization';
import type { SubscriptionActionType } from '@client/models/subscription';
import type { SupplierId, SupplierWithServices } from '@client/models/supplier';
import { getLocalisationScopeFromAction } from '@client/modules/subscription-search/SubscriptionSearchPage/logic';
import { trackSubscriptionSearchQuery } from '@client/modules/subscription-search/SubscriptionSearchPage/mixpanel';
import { subscriptionActionTypeToTrackingFeature as toTrackingFeature } from '@client/tracking/mixpanel-constants';
import { MenuItem, MenuList } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import { Badge } from '@minna-technologies/minna-ui/components/Badge';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { SearchBar } from '@minna-technologies/minna-ui/components/Inputs/SearchBar';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import { trim } from 'lodash/fp';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import take from 'lodash/fp/take';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
  menu: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
  },

  menuItem: {
    margin: '2px 0',
    minHeight: '36px',
    padding: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  menuItemAdd: {
    display: 'flex',
    flewDirection: 'column',
    minHeight: '30px',
  },

  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  logoAndBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  searchAlternativeLogoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '60px',
    margin: '8px 16px 8px 8px',
  },

  searchAlternativeLogo: {
    maxWidth: '60px',
    maxHeight: '40px',
    margin: 'auto',
  },

  searchAlternativeText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    maxWidth: '35vw',
  },

  missingLogo: {
    width: '60px',
  },

  addIcon: {
    marginRight: '8px',
  },
});

export interface MerchantSearchBarProps {
  merchants: SupplierWithServices[];
  onSelect(merchantId: SupplierId, searchTerm: string): void;
  defaultValue?: string;
  action: SubscriptionActionType;
}

export const MerchantSearchBar: React.FunctionComponent<MerchantSearchBarProps> = ({
  merchants,
  onSelect,
  defaultValue,
  action,
}) => {
  const scope = getLocalisationScopeFromAction(action);
  const { localizeMessage } = useLocalization(scope);
  const { colors } = useTheme();
  const classes = useStyles();

  const [hasFocus, setHasFocus] = useState(false);
  const [matchingMerchants, setMatchingMerchants] = useState<SupplierWithServices[]>([]);
  const [searchTerm, setSearchTerm] = useState(defaultValue || '');

  useEffect(() => {
    if (defaultValue) {
      setHasFocus(true);
      const matchingMerchant = searchSuppliers(defaultValue, merchants);
      setMatchingMerchants(matchingMerchant.length >= 3 ? take(3, matchingMerchant) : matchingMerchant);
    }
  }, [defaultValue, merchants]);

  const handleUpdateInput = (inputValue: string) => {
    const trimmedInputValue = trim(inputValue);

    if (!hasFocus) {
      setHasFocus(true);
    }

    setSearchTerm(trimmedInputValue);

    if (trimmedInputValue.length >= 1) {
      trackSubscriptionSearchQuery(trimmedInputValue, toTrackingFeature(action));
    }

    const matchingMerchant = searchSuppliers(trimmedInputValue, merchants);
    setMatchingMerchants(matchingMerchant.length >= 3 ? take(3, matchingMerchant) : matchingMerchant);
  };

  const handleSelected = (merchantId: SupplierId): void => {
    setHasFocus(false);
    onSelect(merchantId, searchTerm);
  };

  const handleFocus = (): void => {
    setHasFocus(true);
  };

  const hasLogo = (logo?: string): boolean => !isNil(logo) && !isEmpty(logo);

  const searchInput = (
    <SearchBar
      name="merchantName"
      data-test={'subscription-search-search-bar'}
      value={searchTerm}
      placeholder={localizeMessage('chooseSupplier')}
      fullWidth
      inputProps={{
        onFocus: handleFocus,
      }}
      onChange={(e) => handleUpdateInput(e.target.value)}
    />
  );

  const logo = (merchant: SupplierWithServices) => (
    <div className={classes.searchAlternativeLogoWrapper}>
      {hasLogo(merchant.logoUrl) ? (
        <img className={classes.searchAlternativeLogo} src={merchant.logoUrl} />
      ) : (
        <div className={classes.missingLogo} />
      )}
    </div>
  );

  const searchResults = (merchants: SupplierWithServices[]) => {
    if (merchants.length === 0) {
      return <Body color={colors.textOn.surfaceSubdued}>{localizeMessage('noResultsFound')}</Body>;
    }
    return merchants.map((merchant, i) => (
      <MenuItem
        key={i}
        className={classes.menuItem}
        disableRipple
        onClick={() => handleSelected(merchant.id)}
        data-test="subscription-search-search-menu-item"
      >
        <div className={classes.flexContainer}>
          <div className={classes.logoAndBodyContainer}>
            {logo(merchant)}
            <Body
              color={colors.textOn.surfaceSubdued}
              className={classes.searchAlternativeText}
              data-test="subscription-search-menu-item-text"
            >
              {merchant.name}
            </Body>
          </div>
          <div>
            <Badge label={localizeMessage('badgeLabel')} backgroundColor={colors.element.badgeSurface} />
          </div>
        </div>
      </MenuItem>
    ));
  };

  return (
    <div>
      {searchInput}
      {hasFocus && !isEmpty(searchTerm) && (
        <Card style={{ marginBottom: '32px' }}>
          <Subheading1>{localizeMessage('searchResultsTitle')}</Subheading1>
          <MenuList className={classes.menu}>{searchResults(matchingMerchants)}</MenuList>
        </Card>
      )}
    </div>
  );
};
