import Sentry from '@client/assets/js/sentry';
import type { ServiceId, SupplierId } from '@client/models/supplier';
import { isMobileDevice } from '@client/modules/app-integration/checks';
import { openBankId } from '@client/modules/bank-id';
import type { CancellationActions } from '@client/modules/cancellation/duck';
import { CancellationAction } from '@client/modules/cancellation/duck';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import omitBy from 'lodash/omitBy';
import type { Dispatch } from 'redux';
import URI from 'urijs';
import { fetchSubscription, selectorContract, selectorSubscription } from '../subscription';
import type { Contract, ContractId, Subscription } from '../subscription/types';
import { ContractState } from '../subscription/types';

let webSocket: WebSocket;

export function closeConnection() {
  if (webSocket) {
    webSocket.close();
  }
}

export function doReconnection(
  contractId: ContractId,
  onTerminationSignCompleted: (subscription: Subscription, contract?: Contract) => void,
  onTerminationSignFailed: (subscription: Subscription, contract?: Contract) => void,
  onNoSession: () => void
) {
  return signLoa(contractId, onTerminationSignCompleted, onTerminationSignFailed, true, onNoSession);
}

export function signLoa(
  contractId: ContractId,
  onTerminationSignCompleted: (subscription: Subscription, contract?: Contract) => void,
  onTerminationSignFailed: (subscription: Subscription, contract?: Contract) => void,
  reconnect = false,
  onNoSession = noop
) {
  return (dispatch: Dispatch<CancellationActions>, getState: () => any) => {
    function close() {
      dispatch(CancellationAction.connectionClosed());
      closeConnection();
    }

    const uri = new URI({
      protocol: window.location.protocol === 'http:' ? 'ws' : 'wss',
      hostname: window.location.host,
      path: `/api/user/contracts/${contractId}/request-termination`,
    });

    uri.setQuery(
      omitBy(
        {
          reconnect,
        },
        isNil
      )
    );

    closeConnection();
    webSocket = new WebSocket(uri.toString());

    dispatch(CancellationAction.connectionStarted());

    webSocket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.type === 'TerminationRequestStarted') {
        dispatch(CancellationAction.statusChanged(message));
        const isMobile = isMobileDevice();
        if (isMobile && !get(message, 'replay', false)) {
          openBankId(message.autoStartToken);
        }
      } else if (message.type === 'TerminationRegistered') {
        dispatch(CancellationAction.statusChanged(message));
        close();
        const subscription = selectorSubscription(getState());
        const contract = selectorContract(getState(), contractId);
        onTerminationSignCompleted(subscription as Subscription, contract);
      } else if (message.type === 'NoStream') {
        onNoSession();
        close();
      } else if (includes(['GeneralError'], message.type)) {
        dispatch(CancellationAction.statusChanged(message));
        close();
        const subscription = selectorSubscription(getState());
        const contract = selectorContract(getState(), contractId);
        onTerminationSignFailed(subscription as Subscription, contract);
      } else {
        dispatch(CancellationAction.statusChanged(message));
      }
    };

    webSocket.onclose = () => {
      close();
    };

    webSocket.onerror = (error) => {
      Sentry.captureMessage('WebSocket error in termination', { extra: { error } });
      const oldSubscription = selectorSubscription(getState());
      close();
      fetchNewSubscriptionAndMaybeGoToSuccess(
        oldSubscription?.id as string,
        contractId,
        onTerminationSignCompleted,
        onTerminationSignFailed,
        0
      );
    };
  };
}

export function fetchNewSubscriptionAndMaybeGoToSuccess(
  subscriptionId: string,
  contractId: string,
  onTerminationSignCompleted: (subscription: Subscription, contract?: Contract) => void,
  onTerminationSignFailed: (subscription: Subscription, contract?: Contract) => void,
  retries: number
) {
  fetchSubscription(subscriptionId).then((fetchedSubscription: Subscription) => {
    const newContract = fetchedSubscription.contracts.find((contract) => contract.id === contractId);
    if (newContract?.state === ContractState.Terminating) {
      onTerminationSignCompleted(fetchedSubscription, newContract);
    } else if (retries < 10) {
      setTimeout(
        () =>
          fetchNewSubscriptionAndMaybeGoToSuccess(
            subscriptionId,
            contractId,
            onTerminationSignCompleted,
            onTerminationSignFailed,
            retries + 1
          ),
        5000
      );
    } else {
      onTerminationSignFailed(fetchedSubscription, newContract);
    }
  });
}

export function signCancelServiceLoa(
  merchantId: SupplierId,
  serviceId: ServiceId,
  onTerminationSignCompleted: () => void,
  onTerminationSignFailed: () => void,
  reconnect = false,
  onNoSession = noop
) {
  return (dispatch: Dispatch<CancellationActions>) => {
    function close() {
      dispatch(CancellationAction.connectionClosed());
      closeConnection();
    }

    const uri = new URI({
      protocol: window.location.protocol === 'http:' ? 'ws' : 'wss',
      hostname: window.location.host,
      path: `/api/user/merchants/${merchantId}/services/${serviceId}/request-cancellation`,
    });

    uri.setQuery(
      omitBy(
        {
          reconnect,
        },
        isNil
      )
    );

    closeConnection();
    webSocket = new WebSocket(uri.toString());

    dispatch(CancellationAction.connectionStarted());

    webSocket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.type === 'TerminationRequestStarted') {
        dispatch(CancellationAction.statusChanged(message));
        const isMobile = isMobileDevice();
        if (isMobile && !get(message, 'replay', false)) {
          openBankId(message.autoStartToken);
        }
      } else if (message.type === 'TerminationRegistered') {
        dispatch(CancellationAction.statusChanged(message));
        close();
        onTerminationSignCompleted();
      } else if (message.type === 'NoStream') {
        onNoSession();
        close();
      } else if (includes(['GeneralError'], message.type)) {
        dispatch(CancellationAction.statusChanged(message));
        close();
        onTerminationSignFailed();
      } else {
        dispatch(CancellationAction.statusChanged(message));
      }
    };

    webSocket.onclose = () => {
      close();
    };

    webSocket.onerror = (error) => {
      Sentry.captureMessage('WebSocket error in termination', { extra: { error } });
      close();
    };
  };
}
