import type { User, ViewedSubscription } from '@client/models/user-models';
import { sendUserIdToApp } from '@client/modules/app-integration/standalone/standalone-app-integration';
import type { AxiosError } from 'axios';
import axios from 'axios';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import set from 'lodash/set';
import type { Dispatch } from 'redux';
import { handle } from 'redux-pack';
import Sentry from '../assets/js/sentry';
import { getUser } from '../models/user';
import { PERSONAL_INFORMATION_SAVE } from '../modules/settings/UserSettingsPage/actions';
import type { SubscriptionId } from '../modules/subscription/types';
import { getMixpanel } from '../tracking/mixpanel';

const SET = 'USER/SET';
const SET_VISITED_PAGE = 'USER/SET_VISITED_PAGE';
const SET_WITH_PROMISE = 'USER/SET_WITH_PROMISE';
const SET_EXISTING_USER_WITH_SAME_EMAIL = 'USER/SET_EXISTING_USER_WITH_SAME_EMAIL';
const CONNECT_TO_PERSONAL_NUMBER_ERROR = 'USER/CONNECT_TO_PERSONAL_NUMBER_ERROR';
const LOADING = 'USER/LOADING';
const LOADING_FAILURE = 'USER/LOADING_FAILURE';
const NOT_LOGGED_IN = 'USER/NOT_LOGGED_IN';
const LOGGED_IN = 'USER/LOGGED_IN';
const REQUESTS_DATA_EXPORT_TOKEN = 'USER/REQUESTS_DATA_EXPORT_TOKEN';
const DATA_EXPORT_TOKEN_GENERATION_SUCCEEDED = 'USER/DATA_EXPORT_TOKEN_GENERATION_SUCCEEDED';
const DATA_EXPORT_TOKEN_GENERATION_FAILED = 'USER/DATA_EXPORT_TOKEN_GENERATION_FAILED';
const SET_ACCEPTED_PRIVACY_POLICY_AND_TERMS_OF_USE = 'USER/SET_ACCEPTED_PRIVACY_POLICY_AND_TERMS_OF_USE';

const VIEWED_SUBSCRIPTION_IN_GUIDE = 'ViewedSubscriptions';
export const VIEWED_CANCEL_PRODUCT_ONBOARDING_AT = 'ViewedCancelProductOnboarding';
export const VIEWED_DISCOVER_ONBOARDING_CARD_AT = 'ViewedDiscoverOnboardingCard';
export const VIEWED_RATE_ONBOARDING_CARD_AT = 'ViewedRateOnboardingCard';

//The inner reducer exists to be able react on the changes to the state the user reducer has produced

const initialState = {
  contractImprovementsExplored: [],
};

export async function saveUserViewedSubscriptionInGuide(subscriptionId: SubscriptionId) {
  return axios
    .post('/api/user/visited-page', { type: VIEWED_SUBSCRIPTION_IN_GUIDE, subscriptionId })
    .then((response) => response.data);
}

export async function saveUserServiceIntroductionSeen(serviceIntroduction: string) {
  return axios.post('/api/user/visited-page', { type: serviceIntroduction }).then((response) => response.data);
}

export async function saveUserViewedDiscoverOnboardingCard() {
  return axios
    .post('/api/user/visited-page', { type: VIEWED_DISCOVER_ONBOARDING_CARD_AT })
    .then((response) => response.data);
}

export async function saveUserViewedRateOnboardingCard() {
  return axios
    .post('/api/user/visited-page', { type: VIEWED_RATE_ONBOARDING_CARD_AT })
    .then((response) => response.data);
}

/* eslint-disable import/no-default-export */
export default function reducer(state = initialState, action: any) {
  const updateState = innerReducer(state, action);
  handleUserChange(updateState);

  return updateState;
}

//TODO create user state type
/* eslint-disable  */
function innerReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET: {
      return { ...state, data: action.user };
    }
    case SET_VISITED_PAGE: {
      const newState = { ...state };
      set(newState, `data.visitedPages.${action.pageName}`, action.at);

      return newState;
    }
    case SET_WITH_PROMISE: {
      return handle(state, action, {
        start: (s) => ({ ...s, isLoading: true, loadingFailed: false }),
        finish: (s) => ({ ...s, isLoading: false }),
        failure: (s) => ({ ...s, loadingFailed: true }),
        success: (s) => ({ ...s, data: action.payload }),
      });
    }
    case PERSONAL_INFORMATION_SAVE: {
      return handle(state, action, {
        success: (s) => ({ ...s, data: action.payload }),
      });
    }
    case SET_EXISTING_USER_WITH_SAME_EMAIL: {
      return {
        ...state,
        existingUserWithSameEmail: action.existingUserWithSameEmail,
      };
    }

    case CONNECT_TO_PERSONAL_NUMBER_ERROR: {
      return {
        ...state,
        connectToPersonalNumberError: action.error,
      };
    }
    case LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LOADING_FAILURE: {
      return {
        ...state,
        isLoading: false,
        loadingFailed: true,
      };
    }
    case NOT_LOGGED_IN: {
      return {
        ...state,
        notLoggedIn: true,
        isLoading: false,
      };
    }
    case LOGGED_IN: {
      return {
        ...state,
        data: action.user,
        isLoading: false,
        notLoggedIn: false,
      };
    }
    case REQUESTS_DATA_EXPORT_TOKEN: {
      return {
        ...state,
        isGeneratingDataExportToken: true,
        dataExportTokenGenerationFailed: false,
        dataExportTokenValue: undefined,
      };
    }
    case DATA_EXPORT_TOKEN_GENERATION_SUCCEEDED: {
      return {
        ...state,
        isGeneratingDataExportToken: false,
        dataExportTokenValue: action.token,
      };
    }
    case DATA_EXPORT_TOKEN_GENERATION_FAILED: {
      return {
        ...state,
        isGeneratingDataExportToken: false,
        dataExportTokenGenerationFailed: true,
        dataExportTokenValue: undefined,
      };
    }
    case SET_ACCEPTED_PRIVACY_POLICY_AND_TERMS_OF_USE: {
      return {
        ...state,
        acceptedPrivacyPolicyAndTermsOfUse: action.acceptedPrivacyPolicyAndTermsOfUse,
      };
    }

    default:
      return state;
  }
}

export const selectorUser = (state: any) => state.user.data;
export const selectorUserId = (state: any) => get(state, 'user.data.id');
export const selectorUserEmailAddress = (state: any) => get(state, 'user.data.emailAddress');
export const selectorIsLoading = (state: any) => state.user.isLoading;
export const selectorLoadingFailed = (state: any) => state.user.loadingFailed;
export const selectorIsLoadingOrNotLoaded = (state: any) =>
  state.user.isLoading === undefined ? true : state.user.isLoading;
export const selectorNotLoggedIn = (state: any) => state.user.notLoggedIn;
//Also includes if fetch is currently ongoing
export const selectorFetchAttemptMade = (state: any) =>
  !state.user.isLoading && !(state.user.notLoggedIn || state.user.user || state.user.loadingFailed);
export const selectorLoggedIn = (state: any) => !state.user.notLoggedIn && Boolean(state.user.data);
export const selectorDataExportTokenGenerationFailed = (state: any) => state.user.dataExportTokenGenerationFailed;
export const selectorDataExportTokenValue = (state: any) => state.user.dataExportTokenValue;
export const selectorIsGeneratingDataExportToken = (state: any) => state.user.isGeneratingDataExportToken;
export const selectorIsLoadingValidAuth = (state: any) => state.user.isLoadingValidAuth;
export const selectorLoadingValidAuthFailed = (state: any) => state.user.loadingValidAuthFailed;
export const selectorViewedNewFeaturesPageAt = (state: any, platform: string) =>
  get(state.user.data, `visitedPages.viewedNewFeaturesPageAt.${platform}`);
export const selectorViewedOnboardDrawer = (state: any) => get(state, 'user.data.visitedPages.viewedOnboardDrawerAt');
export const selectorViewedCancelProductOnboarding = (state: any) =>
  get(state, `user.data.visitedPages.${VIEWED_CANCEL_PRODUCT_ONBOARDING_AT}`);
export const selectorViewedOnboardingCardDiscoverAt = (state: any): string | undefined =>
  get(state, `user.data.visitedPages.viewedDiscoverOnboardingCardAt`);
export const selectorViewedChangePaymentDrawer = (state: any): string | undefined =>
  get(state, `user.data.visitedPages.viewedChangePaymentDrawerAt`);
export const selectorViewedOnboardingCardRateAt = (state: any): string | undefined =>
  get(state, `user.data.visitedPages.viewedRateOnboardingCardAt`);
export const selectorViewedBankSyncWorkInProgressAt = (state: any) =>
  get(state, 'user.data.visitedPages.viewedBankSyncWorkInProgressAt');
export const selectorContractImprovementsExplored = (state: any) =>
  get(state.user.data, 'visitedPages.contractImprovementsExplored', initialState.contractImprovementsExplored);
export const selectorSubscriptionsViewedInGuide = (state: any) =>
  get(state.user.data, 'visitedPages.viewedSubscriptions', []).map(
    (viewedSubscription: ViewedSubscription) => viewedSubscription.subscriptionId
  );
export const selectorQuickCancelServiceExplored = (state: any) =>
  get(state.user.data, 'visitedPages.viewedQuickCancelServiceIntroductionAt');
export const selectorSearchPageExplored = (state: any) => get(state.user.data, `visitedPages.viewedSearchPageAt`);
export const selectorAcceptedPrivacyPolicyAndTermsOfUse = (state: any) =>
  state.user.acceptedPrivacyPolicyAndTermsOfUse || false;
export const userIsLoading = () => ({ type: LOADING });
export const userLoadingFailure = () => ({ type: LOADING_FAILURE });
export const userNotLoggedIn = () => ({ type: NOT_LOGGED_IN });

export const setUserWithPromise = (userPromise: Promise<User>) => {
  return {
    type: SET_WITH_PROMISE,
    promise: userPromise,
  };
};

export const setUser = (user: User) => {
  return {
    type: SET,
    user: user,
  };
};

export const setUserVisitedPage = (pageName: string, at: string) => {
  return {
    type: SET_VISITED_PAGE,
    pageName,
    at,
  };
};

export const loggedIn = (user: User) => {
  return {
    type: LOGGED_IN,
    user: user,
  };
};

export const logOut = () => {
  return (dispatch: any) => {
    dispatch(() => ({ type: 'CLEAR_STATE' }));
  };
};

export const acceptPrivacyPolicyAndTermsOfUse = (didAgree: boolean) => {
  return {
    type: SET_ACCEPTED_PRIVACY_POLICY_AND_TERMS_OF_USE,
    acceptedPrivacyPolicyAndTermsOfUse: didAgree,
  };
};

export const setExistingUserWithSameEmail = (user: User) => {
  return {
    type: SET_EXISTING_USER_WITH_SAME_EMAIL,
    existingUserWithSameEmail: user,
  };
};

export function connectToPersonalNumberError(error: Error) {
  return { type: CONNECT_TO_PERSONAL_NUMBER_ERROR, error };
}

export const userRequestsDataExportToken = () => ({ type: REQUESTS_DATA_EXPORT_TOKEN });
export const dataExportTokenGenerationSucceeded = (token: string) => ({
  type: DATA_EXPORT_TOKEN_GENERATION_SUCCEEDED,
  token,
});
export const dataExportTokenGenerationFailed = () => ({ type: DATA_EXPORT_TOKEN_GENERATION_FAILED });

/**
 * Fetches the user from the back-end.
 * In case of an error, if the error response object has redirectUrl, it'll automaticly redirect the user
 */
export const fetchUser = (onError?: (error: AxiosError) => void) => {
  return (dispatch: Dispatch) => {
    dispatch(userIsLoading());
    getUser()
      .then((user: User) => {
        dispatch(loggedIn(user));
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          dispatch(userNotLoggedIn());
        } else {
          dispatch(userLoadingFailure());
          Sentry.captureExceptionWithMessage(error, 'Failed to load user');
        }

        if (onError) {
          onError(error);
        }
      });
  };
};

// When the user is updated the changes are sent to mixpanel and sentry.
let currentUserState: any;
function handleUserChange(updatedUserState: any) {
  const previousUserState = currentUserState;
  currentUserState = updatedUserState;

  const emailAddressChanged =
    get(currentUserState, 'data.emailAddress') !== get(previousUserState, 'data.emailAddress');
  const nameChanged = !isEqual(get(currentUserState, 'data.name'), get(previousUserState, 'data.name'));
  const idChanged = get(currentUserState, 'data.id') !== get(previousUserState, 'data.id');

  if (!get(previousUserState, 'notLoggedIn') && isEmpty(currentUserState.data)) {
    // User changed from logged in to logged out.
    Sentry.configureScope((scope) => {
      scope.setUser({});
    });
    getMixpanel().reset();
  } else if (emailAddressChanged || nameChanged || idChanged) {
    // User was updated
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: currentUserState.data.id,
      });
    });
    identifyAndSetMixpanelUser(currentUserState.data);
    sendUserIdToApp(currentUserState.data.id); //For standalone-apps
  }
}

const identifyAndSetMixpanelUser = (user: User) => {
  const createdAtDate = new Date(get(user, 'createdAt'));
  const email = get(user, 'emailAddress');

  const properties = omitBy(
    {
      'User id': user.id,
      $created: createdAtDate,
      $last_login: new Date().toISOString(),
      $email: email,
      'Last Active': new Date().toISOString(),
    },
    isNil
  );

  getMixpanel().identify(user.id);
  getMixpanel().people.set(properties);
};
