import { useLocalization } from '@client/internationalization';
import { ReminderDialogView } from '@client/modules/subscription/ContractDetailsPage/components/ReminderDialog';
import { Select } from '@minna-technologies/minna-ui/components/Inputs/Select/';
import { SelectOption } from '@minna-technologies/minna-ui/components/Inputs/Select/components/SelectOption';
import type { FC } from 'react';
import React from 'react';

interface Props {
  defaultValue: ReminderDialogView;
  onChange(view: ReminderDialogView): void;
}

export const ReminderTypeSelect: FC<Props> = ({ defaultValue, onChange }) => {
  const { localizeMessage } = useLocalization('contract/subscriptionReminderDialog');

  return (
    <Select
      name="reminderType"
      defaultValue={defaultValue || ReminderDialogView.Default}
      label={localizeMessage('reminderTypeLabel')}
      onChange={(e) => onChange(ReminderDialogView[e.target.value as keyof typeof ReminderDialogView])}
      fullWidth
    >
      <SelectOption value={ReminderDialogView.Default} label={localizeMessage('reminderFieldNotSpecified')} />
      <SelectOption
        value={ReminderDialogView.ContractExpiring}
        label={localizeMessage('reminderFieldContractExpiring')}
      />
    </Select>
  );
};
