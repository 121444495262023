import type { RecommendationPreference } from '@client/models/recommendation-preference-request';
import type { InsightId, InsightId as NewInsightId } from '@client/modules/InsightsPage/models';
import type { Subscription } from '@client/modules/subscription/types';
import type { MobileProduct } from '@client/modules/switch/mobile/models';
import type { ActionsUnion } from '@client/utils/redux';
import { createAction } from '@client/utils/redux';

export interface RecommendationState {
  recommendedProduct?: MobileProduct;
  subscription?: Subscription;
  recommendationPreference?: RecommendationPreference;
  newInsightId?: NewInsightId;
  placingOrder: boolean;
}

const initialState: RecommendationState = {
  placingOrder: false,
};

enum ActionKeys {
  SET_PRODUCT_AND_SUBSCRIPTION_NEW = 'RECOMMENDATION/SET_PRODUCT_AND_SUBSCRIPTION_NEW',
  SET_PLACING_ORDER = 'MOBILE_GUIDE/SET_PLACING_ORDER',
}

export const RecommendationActions = {
  setProductAndSubscriptionNew: (
    recommendedProduct: MobileProduct,
    subscription: Subscription | undefined,
    recommendationPreference: RecommendationPreference,
    insightId: InsightId
  ) =>
    createAction(ActionKeys.SET_PRODUCT_AND_SUBSCRIPTION_NEW, {
      recommendedProduct,
      subscription,
      recommendationPreference,
      insightId,
    }),
  setPlacingOrder: (placingOrder: boolean) => createAction(ActionKeys.SET_PLACING_ORDER, placingOrder),
};

export function reducer(
  state: RecommendationState = initialState,
  action: ActionsUnion<typeof RecommendationActions>
): RecommendationState {
  switch (action.type) {
    case ActionKeys.SET_PRODUCT_AND_SUBSCRIPTION_NEW:
      return {
        ...state,
        recommendedProduct: action.payload.recommendedProduct,
        subscription: action.payload.subscription,
        recommendationPreference: action.payload.recommendationPreference,
        newInsightId: action.payload.insightId,
      };
    case ActionKeys.SET_PLACING_ORDER: {
      return {
        ...state,
        placingOrder: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

interface State {
  recommendation: RecommendationState;
}

export const selectorRecommendedProduct = (state: State) => state.recommendation.recommendedProduct;
export const selectorSubscriptionForRecommendation = (state: State) => state.recommendation.subscription;
export const selectorRecommendationPreference = (state: State) => state.recommendation.recommendationPreference;
export const selectorNewInsightId = (state: State) => state.recommendation.newInsightId;
export const selectorPlacingOrder = (state: State) => state.recommendation.placingOrder;
