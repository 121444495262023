import { useLocalization } from '@client/internationalization';
import { SubscriptionActionType } from '@client/models/subscription';
import { selectorAppContext } from '@client/modules/app-context/duck';
import * as urls from '@client/routes';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import { makeStyles } from '@material-ui/core';
import { PositiveButton } from '@minna-technologies/minna-ui/components/Buttons/PositiveButton';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Drawer } from '@minna-technologies/minna-ui/components/Drawer';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  trackChangePaymentDrawerCloseButtonClicked,
  trackChangePaymentDrawerContinueButtonClicked,
  trackChangePaymentDrawerViewed,
} from './mixpanel-events';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridRowGap: '16px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '16px auto',
  },
}));

interface ChangePaymentDrawerProps {
  onSeenDrawer(): void;
}

export const ChangePaymentDrawer: FC<ChangePaymentDrawerProps> = ({ onSeenDrawer }) => {
  const { localizeMessage } = useLocalization('change-payment/ChangePlanServiceIntroductionDrawer');
  const classes = useStyles();
  const appContext = useSelector(selectorAppContext);
  const [isChangePaymentDrawerOpen, setIsChangePaymentDrawerOpen] = useState(true);
  const history = useHistory();

  useMountEffect(() => {
    trackChangePaymentDrawerViewed(appContext.market, appContext.appType);
  });

  const handleDrawerCloseButtonClicked = () => {
    trackChangePaymentDrawerCloseButtonClicked(appContext.market, appContext.appType);
    setIsChangePaymentDrawerOpen(!isChangePaymentDrawerOpen);
    onSeenDrawer();
  };

  const handleOnGetStartedClick = () => {
    trackChangePaymentDrawerContinueButtonClicked(appContext.market, appContext.appType);
    history.push(urls.subscriptionSearchWithAction(SubscriptionActionType.ChangePayment));
    onSeenDrawer();
  };

  return (
    <MultiDeviceLayout>
      <Drawer open={isChangePaymentDrawerOpen} onClose={handleDrawerCloseButtonClicked}>
        <div data-test="change-payment-service-introduction-drawer" className={classes.container}>
          <Headline2>{localizeMessage('title')}</Headline2>
          <Body>{localizeMessage('descriptionText1', { platformName: appContext.appType })}</Body>
          <Divider />
          <div className={classes.buttonContainer}>
            <PositiveButton
              label={localizeMessage('buttonLabel')}
              onClick={handleOnGetStartedClick}
              data-test="change-payment-drawer-get-started-button"
            />
          </div>
        </div>
      </Drawer>
    </MultiDeviceLayout>
  );
};
