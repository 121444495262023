import { LocalizedMessage } from '@client/internationalization';
import { AppType } from '@client/modules/app-context/constants';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import type { FunctionComponent } from 'react';
import React from 'react';
import { HistoryCard } from '../components/cards/HistoryCard';
import { ConsentPageWrapper } from '../ConsentPageWrapper';
import { OpBankConsentPage } from '../OpBankConsentPage';
import styles from './styles.scss';
import { viewedOnboardingMixpanelEvent } from '@client/tracking/mixpanel-events';
import { TrackingPageName } from '@client/tracking/mixpanel-constants';

export interface ConsentPageProps {
  onContinueToConsentClick(): void;
  appType: AppType;
}

export const ConsentPage: FunctionComponent<ConsentPageProps> = ({ onContinueToConsentClick, appType }) => {
  const { colors } = useTheme();

  if (appType === AppType.OP) {
    return <OpBankConsentPage />;
  } else {
    return (
      <ConsentPageWrapper
        appType={appType}
        trackViewedPage={() => viewedOnboardingMixpanelEvent(TrackingPageName.CONSENT)}
      >
        <div>
          <Headline2 style={{ margin: '0 0 8px' }} data-test={'headline'}>
            <LocalizedMessage id="headlineV2" />
          </Headline2>
          <Body style={{ color: colors.textOn.surfaceSubdued }}>
            <LocalizedMessage id="description1" />
          </Body>
          <div className={styles.card}>
            <HistoryCard />
          </div>
          <Body style={{ color: colors.textOn.surfaceSubdued, margin: '24px 0 11px' }}>
            <LocalizedMessage id="description2" />
          </Body>
        </div>
        <div className={styles.button}>
          <PrimaryButton
            label={<LocalizedMessage id="buttonLabelV2" />}
            onClick={onContinueToConsentClick}
            data-test={'continue-button'}
          />
        </div>
      </ConsentPageWrapper>
    );
  }
};
