import type { ServiceId, SupplierId } from '@client/models/supplier';
import type { Address, PersonName } from '@client/models/user-models';
import type { CancellationOutcome, CancellationState } from '@client/modules/cancellation/models';
import axios from 'axios';
import type { Locale } from '../modules/app-context/constants';
import type { ContractId } from '../modules/subscription/types';
import type { ServiceCategory } from './service';

/**
 * A unique identifier for a Cancellation.
 */
export type CancellationId = string;

export async function getLoaPreviewForContract(contractId: ContractId) {
  return axios.get(`/api/v1/user/contracts/${contractId}/cancellation-loa`).then((response) => response.data);
}

export async function getRecentCancellationsForUser(): Promise<PastAction[]> {
  return axios.get('/api/v1/user/past-actions').then((response) => response.data);
}

export async function getLoaPreviewForService(merchantId?: SupplierId, serviceId?: ServiceId) {
  return axios
    .get(`/api/v1/user/merchants/${merchantId}/services/${serviceId}/cancellation-loa`)
    .then((response) => response.data);
}

export async function getCancellationId(merchantId: SupplierId, serviceId: ServiceId): Promise<CancellationId> {
  return axios
    .get(`/api/v1/user/merchants/${merchantId}/services/${serviceId}/cancellation-id`)
    .then((response) => response.data);
}

export interface CancellationUser {
  name: PersonName;
  address: Address;
  locale: Locale;
  personalNumber?: string;
  email: string;
}

export interface PastAction {
  id: CancellationId;
  merchantId: SupplierId;
  merchantName: string;
  serviceId: ServiceId;
  serviceName: string;
  status: CancellationState;
  outcome?: CancellationOutcome;
  bindingPeriodEndsAt?: string;
  requestedToBeCancelledAt?: string;
  createdAt: string;
  completedAt?: string;
  statusLastChangedAt?: string;
  category: ServiceCategory;
}
