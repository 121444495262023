import { LocalizedMessage } from '@client/internationalization';
import type { CancellationReason } from '@client/modules/saveDesk/api';
import { RadioButton } from '@client/modules/shared-components/inputs/RadioButton';
import type { WithStyles } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () =>
  createStyles({
    painsSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      width: '100%',
      margin: '8px 16px',
    },
    painWrapper: {
      margin: '4px 0 0 0',
    },
  });

export interface CancellationReasonDialogComponentProps {
  dialogOpen: boolean;
  onClose(): void;
  cancellationReasons: CancellationReason[];
  onSendFeedback(): void;
  onSelectCancellationReason(cancellationReasons: CancellationReason): void;
  selectedCancellationReason: CancellationReason;
  onGoToCancellationFlow(): void;
  loading: boolean;
  providerName: string;
}

const CancellationReasonDialogComponentInner: React.FunctionComponent<
  CancellationReasonDialogComponentProps & WithStyles<typeof styles>
> = (
  {
    classes,
    dialogOpen,
    onClose,
    cancellationReasons,
    onSendFeedback,
    onSelectCancellationReason,
    selectedCancellationReason,
    onGoToCancellationFlow,
    loading,
    providerName,
  },
  { localizeMessage }
) => {
  const painsSection = cancellationReasons.map((cancellationReason, i) => {
    return (
      <div key={i} className={classes.painWrapper}>
        <RadioButton
          onChange={() => onSelectCancellationReason(cancellationReason)}
          checked={selectedCancellationReason === cancellationReason}
          label={localizeMessage(cancellationReason)}
        />
      </div>
    );
  });
  const primaryButton = (
    <PrimaryButton
      label={<LocalizedMessage id="cancellationReasonDialogSendFeedbackButton" />}
      onClick={onSendFeedback}
      disabled={selectedCancellationReason === undefined}
      loading={loading}
    />
  );

  return (
    <Modal
      open={dialogOpen}
      onClose={onClose}
      title={<LocalizedMessage id="cancellationReasonDialogTitle" />}
      button1={primaryButton}
      button2={
        <SecondaryButton
          label={<LocalizedMessage id="cancellationReasonDialogNoAnswerButton" />}
          onClick={onGoToCancellationFlow}
        />
      }
    >
      <Body>
        <LocalizedMessage id="cancellationReasonDialogDescription2" values={{ providerName: providerName }} />
      </Body>
      <div className={classes.painsSection}>{painsSection}</div>
    </Modal>
  );
};

CancellationReasonDialogComponentInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const CancellationReasonDialogComponent = withStyles(styles)(CancellationReasonDialogComponentInner);
