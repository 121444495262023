import type { EndUserImages } from '@client/styles/themes/defaultTheme';
import { defaultThemeOptions } from '@client/styles/themes/defaultTheme';
import type { Theme } from '@material-ui/core';
import { createTheme } from '@minna-technologies/minna-ui/styles/themes/minna';

export const opBankTheme: Theme<EndUserImages> = createTheme<EndUserImages>({
  colors: {
    action: {
      primary: '#EE5A00',
      primaryHover: '#BE4800',
      primaryPressed: '#8F3600',
      primaryDisabled: '#DDDDDD',
      secondary: '#EE5A00',
      secondaryPressed: '#8F3600',
      secondaryHover: '#BE4800',
      secondaryDisabled: '#DDDDDD',
      info: '#7FA3FF',
      infoPressed: '#7FA3FF',
      infoHover: '#7FA3FF',
      positive: '#558C4D',
      positivePressed: '#3D7635',
      positiveHover: '#286120',
      danger: '#E43F5C',
      dangerPressed: '#C12641',
      dangerHover: '#9E1830',
    },
    textOn: {
      surface: '#222222',
      surfaceSubdued: '#4a4b4b',
      primary: '#ffffff',
      secondary: '#222222',
      disabled: '#818181',
      info: '#ffffff',
      positive: '#ffffff',
      danger: '#ffffff',
      badge: '#ffffff',
    },
    element: {
      rating: '#FFD467',
      badgeSurface: '#1D82A1',
    },
    base: {
      surface: '#ffffff',
      border: '#B0B0B0',
      background: '#f8f8f8',
      info: '#7FA3FF',
      positive: '#558C4D',
      danger: '#E43F5C',
    },
  },
  cardShadow: '0px 0px 0px 1px rgba(229,229,229,1)',
  modalShadow: '0px 0px 0px 1px rgba(229,229,229,1)',
  selectButtonShadowSelected: '00px 3px 3px rgba(34, 34, 34, 0.1), 0px 6px 12px rgba(34, 34, 34, 0.15)',
  selectButtonShadowDefault: '0px 2px 2px rgba(34, 34, 34, 0.2)',
  nextStepShadow:
    '0px 0px 3px rgba(34, 34, 34, 0.2), 0px 2px 5px rgba(34, 34, 34, 0.1), 0px 5px 10px rgba(34, 34, 34, 0.2)',
  floatingShadow:
    '0px 1px 1px rgba(34, 34, 34, 0.06), 0px 2px 3px rgba(34, 34, 34, 0.1), 0px 3px 10px rgba(34, 34, 34, 0.1)',
  borderRadius: '0px',
  borderRadiusPrimaryButton: '24px',
  borderRadiusSecondaryButton: '24px',
  transitionDuration: '0.2s',
  themeImages: defaultThemeOptions.themeImages, // No custom images
});
