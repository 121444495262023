import type { Contract, FixedContractBindingPeriod } from '@client/modules/subscription/types';
import { isWithinBindingPeriod } from '@client/modules/subscription/types';
import moment from 'moment';

export function getPercentBindingTimeOver(contractBindingPeriod: FixedContractBindingPeriod) {
  const totalBindingPeriod = moment(contractBindingPeriod.endsAt).diff(
    moment(contractBindingPeriod.setByUserAt),
    'days'
  );
  const bindingPeriodOver = moment().diff(moment(contractBindingPeriod.setByUserAt), 'days');

  return Math.max(1, (bindingPeriodOver / totalBindingPeriod) * 100);
}

export function isBindingPeriodEndingSoon(contract: Contract) {
  if (isWithinBindingPeriod(contract)) {
    const stopShowingBindingTimeLimitsBy = moment(contract.contractBindingPeriod.endsAt).subtract(40, 'days');

    return moment().isAfter(stopShowingBindingTimeLimitsBy);
  } else {
    return true;
  }
}
