import { costOverTimeConverter } from '@client/assets/js/interval-converter';
import { FITNESS, MOBILE } from '@client/constants/categories';
import type { AmountWithCurrency, Currency } from '@client/models/subscription';
import type { Subscription } from '@client/modules/subscription/types';
import { LABEL_OPTIMIZE_FITNESS, LABEL_OPTIMIZE_GENERAL, LABEL_OPTIMIZE_MOBILE } from '@client/zendesk';
import type { CampaignPeriod } from './models';

export function campaignLength(campaign?: CampaignPeriod): number {
  if (!campaign) {
    return 0;
  } else {
    return campaign.toMonth - campaign.fromMonth + 1;
  }
}

export function priceFirstYear(comparisonPrice: AmountWithCurrency): AmountWithCurrency {
  return { amount: comparisonPrice.amount * 12, currency: comparisonPrice.currency };
}

export function subscriptionCostFirstYear(subscription: Subscription): AmountWithCurrency {
  const subscriptionCostPerMonth = costOverTimeConverter(subscription.cost.amount, subscription.paymentInterval, {
    unit: 'month',
    amount: 1,
  });

  return {
    amount: subscriptionCostPerMonth * 12,
    currency: subscription.cost.currency,
  };
}

export function contractCostFirstYear(
  defaultCurrency: Currency,
  subscription?: Subscription,
  currentContractPricePerMonth?: number
): AmountWithCurrency | undefined {
  if (currentContractPricePerMonth) {
    return {
      amount: currentContractPricePerMonth * 12,
      currency: defaultCurrency,
    };
  } else if (subscription && subscription.singleContractId) {
    const subscriptionCostPerMonth = costOverTimeConverter(subscription.cost.amount, subscription.paymentInterval, {
      unit: 'month',
      amount: 1,
    });

    return {
      amount: subscriptionCostPerMonth * 12,
      currency: subscription.cost.currency,
    };
  } else {
    // In this case we don't actually know the contract cost
    return undefined;
  }
}

export function amountSavedFirstYear(
  comparisonPrice?: AmountWithCurrency,
  subscription?: Subscription,
  currentContractPricePerMonth?: number
): AmountWithCurrency | undefined {
  if (comparisonPrice === undefined) {
    return undefined;
  }

  const maybeContractCostFirstYear = contractCostFirstYear(
    comparisonPrice.currency,
    subscription,
    currentContractPricePerMonth
  );

  if (maybeContractCostFirstYear && maybeContractCostFirstYear.currency !== comparisonPrice.currency) {
    throw new Error(
      `Incompatible currencies ${maybeContractCostFirstYear?.currency} and ${comparisonPrice.currency} on subscription and product.`
    );
  }

  const amountSaved = maybeContractCostFirstYear && maybeContractCostFirstYear.amount - comparisonPrice.amount * 12;

  if (amountSaved !== undefined) {
    return {
      amount: amountSaved,
      currency: comparisonPrice.currency,
    };
  } else {
    return undefined;
  }
}

export const zendeskLabelFromCategory = (categoryName: string) => {
  switch (categoryName) {
    case MOBILE:
      return LABEL_OPTIMIZE_MOBILE;
    case FITNESS:
      return LABEL_OPTIMIZE_FITNESS;
    default:
      return LABEL_OPTIMIZE_GENERAL;
  }
};
