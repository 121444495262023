import Sentry from '@client/assets/js/sentry';
import { selectorUser } from '@client/ducks/user';
import {
  sendGetAppInfoToApp,
  sendUserIdToApp,
  setAppInfo,
} from '@client/modules/app-integration/standalone/standalone-app-integration';
import { getGlobalStore } from '@client/modules/global-store';
import forEach from 'lodash/forEach';
import get from 'lodash/get';

/* eslint-disable  */
declare global {
  interface Window {
    receivedMessageFromReactNativeWKWebView?(messageString: string): void;
  }
}

let appInfoSet = false;
const timeoutIds: number[] = [];

function receiveAppMessage(messageString: string) {
  try {
    const message = JSON.parse(messageString);

    const action = get(message, 'action');
    Sentry.addBreadcrumb({
      message: `Received app message: ${action}`,
      category: 'app-message',
      data: message,
    });

    if (action === 'AppInfo') {
      processAppInfo(message.appInfo);
    } else if (action === 'AppInfoUpdate') {
      setAppInfo(message.appInfo);
    }
  } catch (error) {
    Sentry.captureException(error, { extra: { appMessage: messageString } });
  }
}

function processAppInfo(appInfo: Record<string, unknown>) {
  if (!appInfoSet) {
    const user = selectorUser(getGlobalStore().getState());
    if (user) {
      sendUserIdToApp(user.id);
    }
  }
  setAppInfo(appInfo);
  appInfoSet = true;
  forEach(timeoutIds, (timeoutId) => {
    window.clearTimeout(timeoutId);
  });
}

function accessApplicationInfo() {
  try {
    if (window.minnaStandaloneApplication) {
      const appInfoString = window.minnaStandaloneApplication.getApplicationInfoString();
      const appInfo = JSON.parse(appInfoString);
      processAppInfo(appInfo);
    }
  } catch (error) {
    Sentry.captureExceptionWithMessage(error, 'Failed to get application info from standalone mobile application');
  }
}

export function initialize() {
  // Receive messages from Android app
  document.addEventListener(
    'message',
    (event) => {
      // @ts-ignore
      receiveAppMessage(event.data);
    },
    false
  );

  // Receive messages from IOS app
  window.receivedMessageFromReactNativeWKWebView = receiveAppMessage;

  sendGetAppInfoToApp();
  timeoutIds.push(window.setTimeout(sendGetAppInfoToApp, 1000));
  timeoutIds.push(window.setTimeout(sendGetAppInfoToApp, 2000));
  timeoutIds.push(window.setTimeout(sendGetAppInfoToApp, 3000));
  timeoutIds.push(window.setTimeout(sendGetAppInfoToApp, 5000));

  timeoutIds.push(window.setTimeout(accessApplicationInfo, 0));
  timeoutIds.push(window.setTimeout(accessApplicationInfo, 1000));
  timeoutIds.push(window.setTimeout(accessApplicationInfo, 5000));
}
