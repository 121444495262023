import { LocalizedMessage } from '@client/internationalization';
import type { Question } from '@client/models/cancellation-questions';
import type { Locale, Market } from '@client/modules/app-context/constants';
import { hasAlphanumericPostalCode } from '@client/modules/app-context/constants';
import { requiredQuestionLabel } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/logic';
import type { AddressInformation } from '@client/modules/cancellation/CancellationFormPage/models';
import { detailsByLocale } from '@client/utils/locale';
import { postalCodeValidation } from '@client/utils/postalCode/validation';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { RHTextInput } from '@minna-technologies/minna-ui/components/Form/RHTextInput';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useStyles } from './styles';

interface AddressQuestionProps {
  locale: Locale;
  question: Question;
  market: Market;
  addressOnUser: AddressInformation;
}

export const AddressQuestion: React.FunctionComponent<AddressQuestionProps> = (
  { locale, question, market, addressOnUser }: AddressQuestionProps,
  { localizeMessage }
) => {
  const { text, description } = detailsByLocale(question.details, locale);
  const required = question.required;

  const [defaultStreet, setDefaultStreet] = React.useState<undefined | string>(undefined);
  const [defaultCity, setDefaultCity] = React.useState<undefined | string>(undefined);
  const [defaultCareOf, setDefaultCareOf] = React.useState<undefined | string>(undefined);
  const [defaultPostalCode, setDefaultPostalCode] = React.useState<undefined | string>(undefined);

  const onClickCopyUserAddress = () => {
    setDefaultStreet(addressOnUser.street);
    setDefaultCity(addressOnUser.city);
    setDefaultCareOf(addressOnUser.careOf);
    setDefaultPostalCode(addressOnUser.postalCode);
  };
  const classes = useStyles();
  const streetLabel = requiredQuestionLabel(localizeMessage('streetInputLabel'), required);
  const postalCodeLabel = requiredQuestionLabel(localizeMessage('userInfoPostalCode'), required);
  const cityLabel = requiredQuestionLabel(localizeMessage('cityInputLabel'), required);

  return (
    <>
      <Body className={classes.body}>{text}</Body>
      {description ? <Body>{description}</Body> : null}
      <br />
      <SecondaryButton
        data-test="termination-question-copy-address-button"
        label={<LocalizedMessage id="useSameAddressAsAboveButton" />}
        onClick={onClickCopyUserAddress}
      />
      <br />
      <br />
      <RHTextInput
        key={defaultStreet}
        data-test="street-field"
        name={'street' + question.id}
        label={streetLabel}
        validation={{
          required: required ? localizeMessage('requiredStreetError') : false,
        }}
        defaultValue={defaultStreet}
        fullWidth
        autoComplete={'street-address'}
      />
      <div className={classes.medium}>
        <RHTextInput
          key={defaultPostalCode}
          name={'postCode' + question.id}
          label={postalCodeLabel}
          validation={{
            required: required ? localizeMessage('userInfoRequiredPostalCodeError') : false,
            validate: (value: string) =>
              postalCodeValidation(market, localizeMessage('userInfoPostalCodeError'), value),
          }}
          defaultValue={defaultPostalCode}
          fullWidth
          type={'text'}
          inputMode={hasAlphanumericPostalCode(market) ? 'text' : 'numeric'}
          autoComplete={'postal-code'}
        />
      </div>
      <RHTextInput
        key={defaultCareOf}
        name={'careOf' + question.id}
        label={<LocalizedMessage id="careOfInputLabel" />}
        defaultValue={defaultCareOf}
        fullWidth
      />
      <div className={classes.large}>
        <RHTextInput
          key={defaultCity}
          data-test="city-field"
          name={'city' + question.id}
          label={cityLabel}
          validation={{
            required: required ? localizeMessage('requiredCityError') : false,
          }}
          defaultValue={defaultCity}
          fullWidth
          autoComplete={'address-level2'}
        />
      </div>
    </>
  );
};

AddressQuestion.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
