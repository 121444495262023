import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import type { FC } from 'react';
import React from 'react';

interface Props {
  title: React.ReactNode;
  content: React.ReactNode | string;
}

export const InfoItem: FC<Props> = ({ title, content }) => {
  const { colors } = useTheme();

  return (
    <div>
      <Subheading2>{title}</Subheading2>
      <Body color={colors.textOn.surfaceSubdued}>{content}</Body>
    </div>
  );
};
