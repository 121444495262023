import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withAppContext } from '../../../../app-context/react';
import { selectorCurrentRoute, selectorDialogIsMinimized } from '../../../ducks';
import { open } from '../../../ducks/zendesk-actions';
import {
  selectorRecentMessageText,
  selectorShowRecentMessage,
  selectorUnreadMessagesCount,
} from '../../../ducks/zendesk-chat/zendesk-chat-selectors';
import type { ZendeskMinimizedComponentProps } from './component';
import { ZendeskMinimizedComponent } from './component';

const mapStateToProps = (state: any) => {
  return {
    isMinimized: selectorDialogIsMinimized(state),
    currentRoute: selectorCurrentRoute(state),
    unreadMessagesCount: selectorUnreadMessagesCount(state),
    recentMessageText: selectorRecentMessageText(state),
    showRecentMessage: selectorShowRecentMessage(state),
  };
};

const mapDispatchToProps = (dispatch: any, { appContext }: any) => {
  return {
    onOpenDialog() {
      dispatch(open(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale));
    },
  };
};

export const ZendeskMinimized = compose<ZendeskMinimizedComponentProps, any>(
  withAppContext,
  connect(mapStateToProps, mapDispatchToProps)
)(ZendeskMinimizedComponent);
