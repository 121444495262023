import { selectorViewedOnboardingCardDiscoverAt, selectorViewedOnboardingCardRateAt } from '@client/ducks/user';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { LoadingState } from '@client/models/LoadingState';
import type { User } from '@client/models/user-models';
import type { AppFeatureToggles } from '@client/modules/app-context';
import { isChangePaymentEnabled, OnboardingType } from '@client/modules/app-context';
import type { AppType } from '@client/modules/app-context/constants';
import {
  InsightActions,
  selectorFetchableInsights,
  selectorOnboardingInsight,
} from '@client/modules/InsightsPage/duck';
import { OnboardingOverviewDrawer } from '@client/modules/onboarding-overview-drawer/component';
import { OverviewBottomNavigation } from '@client/modules/overview';
import {
  isOnboardingCardsVisible,
  OnboardingOverviewComponent,
} from '@client/modules/overview-onboarding-cards/OnboardingOverview';
import { selectorIsLoading } from '@client/modules/overview/duck';
import { hasOneOrMoreContracts } from '@client/modules/overview/logic';
import { QuickActions } from '@client/modules/QuickActions/component';
import type { Subscription } from '@client/modules/subscription/types';
import type { OrderedSubscription } from '@client/modules/switch/ordered-subscription/models';
import { trackDifferentTypesOfContracts } from '@client/tracking/mixpanel-events';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import type { History } from 'history';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { ChangePaymentDrawer } from './components/ChangePaymentDrawer/component';
import { OverviewContentComponent } from './components/OverviewContentComponent';
import { OverviewTotalCost } from './components/OverviewTotalCost/component';

export interface OverviewPageProps {
  currentLocation: string;
  features: AppFeatureToggles;
  lastSeenFeaturesInCurrentPlatformAt?: string;
  lastSeenChangePaymentDrawerAt?: string;
  orderedSubscriptions: OrderedSubscription[];
  subscriptions: Subscription[];
  terminatedSubscriptions: Subscription[];
  user: User;
  visitedSubscriptionIds: string[];
  history: History;
  subscriptionsDrawerShown: boolean;
  appType: AppType;

  onAddManualSubscription(): void;
  onQuickActionCancelClicked(): void;
  onQuickActionChangePaymentClicked(): void;
  onSeenNewFeatures(): void;
  onSeenChangePaymentDrawer(): void;
  redirectWithPreviousPage(url: string): void;
  onCancelAnySubscriptionClicked(): void;
}

const OverviewPageComponent: FC<OverviewPageProps> = ({
  subscriptions,
  orderedSubscriptions,
  onAddManualSubscription,
  onQuickActionCancelClicked,
  onQuickActionChangePaymentClicked,
  currentLocation,
  user,
  onSeenNewFeatures,
  onSeenChangePaymentDrawer,
  features,
  lastSeenFeaturesInCurrentPlatformAt,
  lastSeenChangePaymentDrawerAt,
  onCancelAnySubscriptionClicked,
}) => {
  const dispatch = useDispatch();

  const isSubscriptionsLoading = useSelector(selectorIsLoading);
  const insights = useSelector(selectorFetchableInsights);
  const maybeOnboardingInsight = useSelector(selectorOnboardingInsight);
  const discoverCardSeenAt = useSelector(selectorViewedOnboardingCardDiscoverAt);
  const rateCardSeenAt = useSelector(selectorViewedOnboardingCardRateAt);
  const [showChangePaymentServiceIntroductionDrawer, setShowChangePaymentServiceIntroductionDrawer] = useState(false);

  const isHeaderVisible = !isOnboardingCardsVisible(maybeOnboardingInsight, discoverCardSeenAt, rateCardSeenAt);

  useMountEffect(() => {
    trackDifferentTypesOfContracts(user, subscriptions);
  });

  useEffect(() => {
    // Fetch Insights after subscriptions have been fetched
    // This is crucial since new users won't have onboarding Insight generated unless getSubscriptions has been called once
    // FP-1461 is about resolving the root issue
    if (!isSubscriptionsLoading && insights.type === LoadingState.NotStarted) {
      dispatch(InsightActions.fetchInsights());
    }
  }, [isSubscriptionsLoading, insights.type, dispatch]);

  const openQuickChangePaymentDrawer = () => {
    if (!lastSeenChangePaymentDrawerAt && isChangePaymentEnabled(features.actionFeatures)) {
      setShowChangePaymentServiceIntroductionDrawer(true);
    } else {
      onQuickActionChangePaymentClicked();
    }
  };

  const unresolvedSubscriptionGuidesEnabled = features.useUnresolvedSubscriptionGuide;

  const hasSubscriptions = unresolvedSubscriptionGuidesEnabled
    ? !!subscriptions.find(hasOneOrMoreContracts)
    : subscriptions.length > 0;

  // if user has not explored our features such identify, cancel, improve (ie lastSeenFeaturesInCurrentPlatformAt is undefined), we should onboard them to these features
  const userNeedsOnboarding = !lastSeenFeaturesInCurrentPlatformAt && features.onboarding === OnboardingType.ENABLED;
  // The Change payment drawer should be shown when a corresponding action button is clicked on the overview.
  // For now, this should not cause any issues as there are ChangePaymentFeature is disabled for all platforms until feature implementation is complete.
  // const showChangePaymentServiceIntroductionDrawer =
  //   !lastSeenChangePaymentDrawerAt && isChangePaymentEnabled(features.actionFeatures);

  const overviewContent = (
    <OverviewContentComponent
      subscriptions={subscriptions}
      orderedSubscriptions={orderedSubscriptions}
      onAddManualSubscription={onAddManualSubscription}
    />
  );

  const overviewFullWithContent = (
    <>
      <OverviewTotalCost />
      <QuickActions
        onCancellationsClicked={onQuickActionCancelClicked}
        onChangePaymentsClicked={openQuickChangePaymentDrawer}
        onCancelAnySubscriptionClicked={onCancelAnySubscriptionClicked}
      />
    </>
  );

  // ToDo: Remove this once the journey for users coming from quick actions is defined
  if (!features.identify?.overview) {
    return (
      <MultiDeviceLayout fullWidthContent>
        <Card
          style={{
            marginTop: '20px',
            height: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20px',
          }}
        >
          <LocalizedMessage id={'comingSoon'} />
        </Card>
      </MultiDeviceLayout>
    );
  }
  return (
    <MultiDeviceLayout
      fullWidthContent={
        isHeaderVisible && (hasSubscriptions || orderedSubscriptions.length > 0) ? overviewFullWithContent : null
      }
    >
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <OnboardingOverviewComponent />
      </div>
      {overviewContent}
      <OverviewBottomNavigation currentLocation={currentLocation} />
      {userNeedsOnboarding && <OnboardingOverviewDrawer onSeenNewFeatures={onSeenNewFeatures} />}
      {showChangePaymentServiceIntroductionDrawer && <ChangePaymentDrawer onSeenDrawer={onSeenChangePaymentDrawer} />}
    </MultiDeviceLayout>
  );
};

export const OverviewPage: React.ComponentClass<OverviewPageProps> = compose<OverviewPageProps, OverviewPageProps>(
  withLocalization('overview/OverviewPage')
)(OverviewPageComponent);
