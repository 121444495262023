import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { SupplierWithServices } from '@client/models/supplier';
import { selectorFeatures } from '@client/modules/app-context/duck';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { MerchantSearchBar } from './components/MerchantSearchBar';

const useStyles = makeStyles({
  container: {
    padding: '0 8px',
  },

  description: {
    marginBottom: '32px',
  },
});

interface Props {
  suppliers: SupplierWithServices[];
  onSupplierSubmit(): void;
  onBackClick(): void;
  onHelpClick(): void;
}

const ManualAddContractPageInner: FC<Props> = ({ suppliers, onSupplierSubmit, onBackClick, onHelpClick }) => {
  const features = useSelector(selectorFeatures);
  const { colors } = useTheme();
  const classes = useStyles();

  return (
    <MultiDeviceLayout
      title={<LocalizedMessage id="addSubscriptionGeneric" />}
      footer={
        <FooterNavigation
          onBackClick={onBackClick}
          onHelpClick={features.helpCenter && features.helpCenter.enabled ? onHelpClick : undefined}
        />
      }
      hasTopNavigation={false}
    >
      <div className={classes.container}>
        <Body color={colors.textOn.surfaceSubdued} className={classes.description}>
          <LocalizedMessage id="addSubscriptionGenericDescription" />
        </Body>
        <MerchantSearchBar merchants={suppliers} onSelect={onSupplierSubmit} />
      </div>
    </MultiDeviceLayout>
  );
};

ManualAddContractPageInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const ManualAddContractPage = withLocalization('ManualAddContractPage')(ManualAddContractPageInner);
