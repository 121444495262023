import Sentry from '@client/assets/js/sentry';
import { useLocalization } from '@client/internationalization';
import { paymentIntervalsPer } from '@client/models/payment-intervals';
import { selectorAppContext } from '@client/modules/app-context/duck';
// @ts-ignore
import Form from '@client/modules/shared-components/form-validation/Form';
import { selectorSubscription } from '@client/modules/subscription';
import { ContractDetailsFloatingButtons } from '@client/modules/subscription/ContractDetailsPage/components/ContractDetailsFloatingButtons';
import {
  clearSubscriptionUpdateOutcome,
  selectorSubscriptionUpdateFailed,
  selectorSubscriptionUpdateSucceeded,
  updateSubscription,
} from '@client/modules/subscription/duck';
import { updateSubscription as updateSubscriptionRequest } from '@client/modules/subscription/model';
import type { PaymentInterval, Subscription } from '@client/modules/subscription/types';
import { SubscriptionSource } from '@client/modules/subscription/types';
import { dispatchSubscriptionConvertedMixpanelEvent } from '@client/modules/subscription/unresolved-guide/utils';
import { subscriptionSettingsPage } from '@client/routes';
import grid from '@client/styles/grid.css';
import { FEATURE_OVERVIEW } from '@client/tracking/mixpanel-constants';
import { updatedSubscriptionMixpanelEvent } from '@client/tracking/mixpanel-events';
import { costWithDecimalsRegex, formatAmountWithDecimals } from '@client/utils/cost';
import { makeStyles } from '@material-ui/core/styles';
import { intervalToString } from '@minna-components/interval-to-string';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { ContractHeaderDeprecated } from '@minna-technologies/minna-ui/components/ContractHeaderDeprecated';
import { Select } from '@minna-technologies/minna-ui/components/Inputs/Select';
import { SelectOption } from '@minna-technologies/minna-ui/components/Inputs/Select/components/SelectOption';
import { TextInput } from '@minna-technologies/minna-ui/components/Inputs/TextInput';
import { CardLayout } from '@minna-technologies/minna-ui/components/Layouts/CardLayout';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Snackbar } from '@minna-technologies/minna-ui/components/Snackbar';
import { ButtonText } from '@minna-technologies/minna-ui/components/Typography/ButtonText';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const cx = classNames.bind(grid);

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '16px 0 8px 0',
  },
});

export const SubscriptionUpdateCostPage = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const { localizeMessage } = useLocalization('contract/UpdateCostLolipop');

  const appContext = useSelector(selectorAppContext);
  const currency = useSelector(selectorAppContext)?.currency.toString() ?? 'SEK';
  const subscription = useSelector(selectorSubscription);
  const updateFailed = useSelector(selectorSubscriptionUpdateFailed);
  const updateSucceeded = useSelector(selectorSubscriptionUpdateSucceeded);

  const initialCost = subscription?.cost;
  const initialInterval = subscription?.paymentInterval;

  let costAmount = `${initialCost?.amount}`;
  const logoUrl = subscription?.supplier.logoUrl;
  const serviceProvider = subscription?.supplier.name;

  const [cost, setCost] = useState(`${initialCost?.amount}`);
  const [invalidEntry, setInvalidEntry] = useState(false);
  const [paymentInterval, setPaymentInterval] = useState(initialInterval);
  const [indexValue, setIndexValue] = useState(0);

  function validate(input: string) {
    return costWithDecimalsRegex.test(input);
  }

  function submit(cost: string, interval: PaymentInterval) {
    if (subscription) {
      const updatedSubscription: Subscription = {
        ...subscription,
        cost: { amount: Number(cost), currency: appContext.currency },
        paymentInterval: interval,
      };
      dispatch(updateSubscription(updateSubscriptionRequest(updatedSubscription)))
        // @ts-ignore
        .then((resultSubscription: { payload: Subscription }) => {
          updatedSubscriptionMixpanelEvent(subscription, resultSubscription.payload, {
            Feature: FEATURE_OVERVIEW,
            Page: 'Edit cost',
            Button: 'Subscription changed',
          });
        })
        .catch((error: any) => {
          Sentry.captureExceptionWithMessage(error, 'Failed to update subscription');
        });
    }
  }

  function clearUpdateOutcome() {
    dispatch(clearSubscriptionUpdateOutcome());
  }

  const paymentIntervalMenuItems = paymentIntervalsPer.map(({ interval }, i) => (
    <SelectOption key={i} value={i} label={intervalToString(localizeMessage)(interval)} />
  ));

  function onAmountChange(event: React.ChangeEvent<HTMLInputElement>) {
    const formattedValue = formatAmountWithDecimals(event.target.value);
    setInvalidEntry(!validate(formattedValue));
    costAmount = formattedValue;
    setCost(costAmount);
  }

  function onFormSubmit() {
    if (subscription?.source === SubscriptionSource.BankDiscovery) {
      dispatchSubscriptionConvertedMixpanelEvent();
    }
    // @ts-ignore
    submit(cost, paymentInterval);
  }

  useEffect(() => {
    paymentIntervalsPer.forEach((singleInterval: any, index: number) => {
      const interval: PaymentInterval = singleInterval.interval;
      if (interval.amount === initialInterval?.amount && interval.unit === initialInterval.unit) {
        setIndexValue(index);
      }
    });
  }, [initialInterval?.amount, initialInterval?.unit]);

  return subscription && serviceProvider ? (
    <MultiDeviceLayout
      fullWidthContent={
        <ContractHeaderDeprecated
          providerLogo={logoUrl}
          providerName={serviceProvider}
          subHeading={serviceProvider}
          title={localizeMessage('updateCostPageHeader')}
        />
      }
      footer={
        <ContractDetailsFloatingButtons
          backButton
          customGoBack={() => {
            history.push(subscriptionSettingsPage(subscription.id));
          }}
        />
      }
      hasTopNavigation={false}
    >
      <CardLayout>
        <Card>
          <ButtonText style={{ marginBottom: '16px' }}>{localizeMessage('updateCostHeader1')}</ButtonText>
          <Form onValidSubmit={onFormSubmit}>
            <div className={cx('row')}>
              <div className={cx('col-xs-12', 'col-sm-6')}>
                <TextInput
                  name="pricing"
                  type="number"
                  onChange={onAmountChange}
                  defaultValue={costAmount}
                  label={localizeMessage('updateCostLabel')}
                  suffix={currency}
                  fullWidth
                  data-test="subscription-update-cost-input-amount"
                />
              </div>
              <div className={cx('col-xs-12', 'col-sm-6')}>
                <Select
                  name="interval"
                  value={indexValue}
                  onChange={(e) => {
                    setIndexValue(parseInt(e.target.value, 10));
                    setPaymentInterval(
                      paymentIntervalsPer[parseInt(e.target.value, 10)].interval as unknown as PaymentInterval
                    );
                  }}
                  label={localizeMessage('paymentIntervalInputLabel')}
                  fullWidth
                  data-test="subscription-update-cost-select-interval"
                >
                  {paymentIntervalMenuItems}
                </Select>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <PrimaryButton
                type={'submit'}
                label={localizeMessage('saveButton')}
                disabled={invalidEntry || (Number(cost) === initialCost?.amount && paymentInterval === initialInterval)}
                data-test="subscription-update-cost-button-submit"
              />
            </div>
          </Form>
        </Card>
      </CardLayout>
      <Snackbar
        open={!!updateSucceeded}
        message={localizeMessage('snackbarMessageUpdateSucceeded')}
        onClose={clearUpdateOutcome}
        autoClose
      />
      <Snackbar
        open={!!updateFailed}
        message={localizeMessage('snackbarMessageUpdateFailed')}
        onClose={clearUpdateOutcome}
        autoClose
      />
    </MultiDeviceLayout>
  ) : null;
};
