import { LocalizedMessage } from '@client/internationalization';
// @ts-ignore js import, remove this when the import is typed
import Form from '@client/modules/shared-components/form-validation/Form';
import { TextField } from '@client/modules/shared-components/inputs/TextField';
import { ThankYouCard20 } from '@client/modules/shared-components/ThankYouCard20';
import { makeStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { StarRating } from '@minna-technologies/minna-ui/components/StarRating';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import type { FC } from 'react';
import React, { useState } from 'react';

const useStyles = makeStyles({
  container: {
    marginTop: 8,
    position: 'relative',
  },
  content: {
    padding: '0 16px 16px',
  },
  starRating: {
    margin: '8px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  ratingSection: {
    display: 'grid',
    gridTemplateRows: 'repeat(auto)',
    gridRowGap: '8px',
    alignItems: 'center',
  },
});

interface Props {
  onRatedExperience(rating: number, message: string): void;
}

export const ContractActionOutcomeRatingCard: FC<Props> = ({ onRatedExperience }) => {
  const classes = useStyles();

  const [isCollapsed, setCollapsed] = useState(false);
  const [currentRating, onRatingChange] = useState(0);
  const [userMessage, setUserMessage] = useState('');
  const [hasRated, setHasRated] = useState(false);

  const rateCancellation = () => {
    onRatedExperience(currentRating, userMessage);
    setTimeout(() => setCollapsed(true), 2000);
    setHasRated(true);
  };

  const unRatedCard = (
    <Form>
      <div className={classes.ratingSection}>
        <Subheading2 align="center">
          <LocalizedMessage id="rateUsHeading" />
        </Subheading2>
        <div className={classes.starRating}>
          <StarRating currentRating={currentRating} onRatingChange={onRatingChange} />
        </div>
        <TextField
          label={<LocalizedMessage id="feedbackInputLabel" />}
          onChange={(event) => setUserMessage(event.target.value)}
          name="feedback"
          fullWidth
        />
        <PrimaryButton
          disabled={currentRating === 0}
          label={<LocalizedMessage id="sendButtonLabel" />}
          onClick={rateCancellation}
          fullWidth
        />
      </div>
    </Form>
  );

  return (
    <Collapse in={!isCollapsed}>
      <div className={classes.container}>
        <Divider />
        <div className={classes.content}>{unRatedCard}</div>
        {hasRated && <ThankYouCard20 />}
      </div>
    </Collapse>
  );
};
