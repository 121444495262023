import { useLocalization } from '@client/internationalization';
import type { AmountWithCurrency } from '@client/models/subscription';
import { PerInterval } from '@client/modules/shared-components/interval-to-string';
import type { PaymentInterval } from '@client/modules/subscription/types';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import type { FC } from 'react';
import React from 'react';

interface Props {
  cost: AmountWithCurrency;
  paymentInterval?: PaymentInterval;
}

export const AmountPerInterval: FC<Props> = ({ cost, paymentInterval }) => {
  const { localizeCostWithCurrency } = useLocalization('');

  return cost.amount > 0 ? (
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <Headline2>{localizeCostWithCurrency(cost)}</Headline2>
      <Body style={{ marginLeft: '4px' }}>
        <PerInterval interval={paymentInterval as PaymentInterval} key="perInterval" />
      </Body>
    </div>
  ) : null;
};
