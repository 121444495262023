export async function openTypeformPopup(typeformUrl: string): Promise<void> {
  // @ts-ignore js import, remove this when the import is typed
  return import(/* webpackChunkName: "vendor-typeform" */ '@typeform/embed').then(({ makePopup }) => {
    const typeformPopup = makePopup(typeformUrl, {
      mode: 'popup',
      autoClose: 5,
    });
    typeformPopup.open();
  });
}
