import React from 'react';
import Loadable from 'react-loadable';
import LoadingPage from '../../fetching/LoadingPage';

export const LazyLoadedChangePlanRoute = Loadable<any, any>({
  loader: async () => import(/* webpackChunkName: "change-plan-route" */ './ChangePlanRoute'),
  loading: LoadingPage,
  render(loaded, props) {
    const ChangePlanRoute = loaded.ChangePlanRoute;

    // @ts-ignore
    return <ChangePlanRoute {...props} />;
  },
});
