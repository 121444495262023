import Sentry from '@client/assets/js/sentry';
import { persistSearchTerm } from '@client/ducks/search-entrypoint';
import type { ServiceId, SupplierId } from '@client/models/supplier';
import { sentCancellationOfServiceFinishTrackingEvent } from '@client/modules/cancellation/mixpanel-events';
import * as urls from '@client/routes';
import { sentCancellationOfContractFinishTrackingEvent } from '@client/tracking/events';
import { sentTerminationOfContractFailedMixpanelEvent } from '@client/tracking/mixpanel-events';
import type { History } from 'history';
import type { Dispatch } from 'redux';
import {
  amountSavedPerYear,
  fetchSubscription,
  loadSubscription,
  subscriptionLoadingFailure,
} from '../../subscription';
import type { Contract, Subscription } from '../../subscription/types';

export const cancellationSignCompleteCallback =
  (dispatch: Dispatch, history: History) => (subscription: Subscription, contract?: Contract) => {
    const subscriptionId = subscription.id;
    const contractId = contract?.id;
    sentCancellationOfContractFinishTrackingEvent(subscription, amountSavedPerYear(subscription).amount, contract);
    const fetchSubscriptionPromise = fetchSubscription(subscriptionId).catch((error: Error) => {
      dispatch(subscriptionLoadingFailure());
      Sentry.captureExceptionWithMessage(error, 'Failed to load subscription in termination form', {
        extra: { subscriptionId },
      });
    });

    // @ts-ignore
    dispatch(loadSubscription(fetchSubscriptionPromise));
    history.replace(urls.terminationSentPage(subscriptionId, contractId ?? ''));
  };

export const cancelServiceSignCompleteCallback =
  (dispatch: Dispatch, history: History) => (merchantId: SupplierId, serviceId: ServiceId) => {
    sentCancellationOfServiceFinishTrackingEvent(merchantId, serviceId);
    dispatch(persistSearchTerm(undefined));
    history.replace(urls.sentCancelServiceRouteFun(merchantId, serviceId));
  };

export const onTerminationSignFailed = (subscription: Subscription, contract?: Contract) => {
  sentTerminationOfContractFailedMixpanelEvent(subscription, contract);
};
