import shrugMan from '@client/assets/images/shrug_man.svg';
import { BasicPaper } from '@client/modules/subscription/components/BasicPaper';
import useTheme from '@material-ui/core/styles/useTheme';
import { PositiveButton } from '@minna-technologies/minna-ui/components/Buttons/PositiveButton';
import { WhiteSecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/WhiteSecondaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import type { FC } from 'react';
import React from 'react';
import styles from './styles.scss';
import { FloatingButton } from '@minna-technologies/minna-ui/components/Buttons/FloatingButton';
import { ChevronLeftIcon } from '@minna-technologies/minna-ui/icons/ChevronLeft';

interface Props {
  headerText: string | React.ReactElement;
  bodyText: string | React.ReactElement;
  recoverActionText?: string | React.ReactElement;
  escapeActionText?: string | React.ReactElement;
  imageAltText?: string;
  shouldShowRecoverButton?: boolean;
  onRecoverClick?(): void;
  shouldShowEscapeButton?: boolean;
  onEscapeClick?(): void;
  shouldShowBackButton?: boolean;
  onBackClick?(): void;
}

/**
 * Page showing an error message to the user.
 *
 * This component does not use localization, instead takes all text as properties. This is to allow it to be rendered without having localization messages available.
 */
export const ErrorComponent: FC<Props> = ({
  headerText,
  bodyText,
  recoverActionText,
  escapeActionText,
  imageAltText,
  shouldShowRecoverButton,
  onEscapeClick,
  shouldShowEscapeButton,
  onRecoverClick,
  shouldShowBackButton,
  onBackClick,
}) => {
  const { colors } = useTheme();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles['paper-container']} style={{ backgroundColor: colors.base.background }}>
          <BasicPaper>
            <div className={styles['paper-content']}>
              <img className={styles.image} src={shrugMan} alt={imageAltText} />

              <div className={styles['header-row']}>
                <Title data-test="error-component-title">{headerText}</Title>
              </div>

              <div className={styles.row}>
                <Body>{bodyText}</Body>
              </div>

              {shouldShowRecoverButton && onRecoverClick && (
                <div className={styles.row}>
                  <PositiveButton label={recoverActionText} onClick={onRecoverClick} />
                </div>
              )}
            </div>
          </BasicPaper>
        </div>

        {shouldShowEscapeButton && onEscapeClick && (
          <WhiteSecondaryButton label={escapeActionText} onClick={onEscapeClick} />
        )}
      </div>
      {shouldShowBackButton && onBackClick && (
        <div className={styles['back-button']}>
          <FloatingButton onClick={() => onBackClick()} variant="primary">
            <ChevronLeftIcon nativeColor={colors.base.surface} />
          </FloatingButton>
        </div>
      )}
    </div>
  );
};
