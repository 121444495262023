import { LocalizedMessage, useLocalization, withLocalization } from '@client/internationalization';
import type { Market } from '@client/modules/app-context/constants';
import { createCancellationOutcomeInfo } from '@client/modules/cancellation/CancellationOutcome';
import { trackRatedCompletedCancellation } from '@client/modules/cancellation/mixpanel-events';
import { CancellationOutcome, successfulCancellationOutcomes } from '@client/modules/cancellation/models';
import { ratedCancellationRequestEvent } from '@client/modules/cancellation/tracking-events';
import { toInsightCancellationOutcome } from '@client/modules/InsightsPage/models';
import { FeedbackType } from '@client/modules/rating/model';
import { ContractActionOutcomeRatingCard } from '@client/modules/subscription/ContractDetailsPage/components/ContractActionOutcomeRatingCard';
import type { CancellationInfo, ContractId, SubscriptionId } from '@client/modules/subscription/types';
import { ServiceNameWithIcon } from '@client/shared-components/ServiceNameWithIcon';
import { TrackingPageName } from '@client/tracking/mixpanel-constants';
import { parseDate } from '@client/utils/date';
import { makeStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { Badge } from '@minna-technologies/minna-ui/components/Badge';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { CheckCircleIcon } from '@minna-technologies/minna-ui/icons/CheckCircle';
import { DeleteIcon } from '@minna-technologies/minna-ui/icons/Delete';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles({
  marginTop: {
    marginTop: 8,
  },
  paper: {
    margin: '8px 0 16px',
  },
  headerContainer: {
    margin: '0 16px',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  header: {
    marginTop: '8px',
  },
  container: {
    padding: '16px 0 8px',
  },
  content: {
    marginTop: 16,
  },
});

interface Props {
  contractId?: ContractId;
  subscriptionId?: SubscriptionId;
  cancellationInfo: CancellationInfo;
  outcome: CancellationOutcome;
  serviceProviderName: string;
  serviceProviderId: string;
  categoryName: string;
  market: Market;
  serviceName?: string;
}

const ContractCancellationOutcomeInner: FC<Props> = ({
  contractId,
  outcome,
  cancellationInfo,
  serviceProviderName,
  serviceProviderId,
  categoryName,
  subscriptionId,
  market,
  serviceName,
}) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const { localizeMessage } = useLocalization('contract/ContractCancellationOutcome');

  const insightCancellationOutcome = toInsightCancellationOutcome(outcome);

  const onRateCancellationExperience = (rating: number, message: string) => {
    trackRatedCompletedCancellation(
      TrackingPageName.CONTRACT_DETAILS,
      serviceProviderName,
      categoryName,
      rating,
      message,
      outcome
    );
    ratedCancellationRequestEvent(
      rating,
      FeedbackType.Completed,
      categoryName,
      market,
      cancellationInfo.cancellationId,
      message
    );
  };

  const outcomeInfo = createCancellationOutcomeInfo(
    colors,
    insightCancellationOutcome,
    serviceProviderName,
    serviceProviderId,
    TrackingPageName.CONTRACT_DETAILS,
    cancellationInfo,
    contractId,
    subscriptionId
  );

  const getStatusBadge = () => {
    if (outcome && successfulCancellationOutcomes.includes(outcome)) {
      return (
        <Badge
          color={colors.textOn.positive}
          label={localizeMessage('cancellationConfirmed')}
          icon={<CheckCircleIcon bold />}
          backgroundColor={colors.base.positive}
        />
      );
    } else if (outcome && outcome == CancellationOutcome.ServiceProviderContactedUser) {
      return (
        <Badge
          color={colors.textOn.danger}
          label={localizeMessage('cancellationClosed')}
          icon={<DeleteIcon bold />}
          backgroundColor={colors.base.danger}
        />
      );
    } else {
      return (
        <Badge
          color={colors.textOn.danger}
          label={localizeMessage('cancellationFailed')}
          icon={<DeleteIcon bold />}
          backgroundColor={colors.base.danger}
        />
      );
    }
  };

  const content = (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.topRow}>
          {getStatusBadge()}
          <ServiceNameWithIcon
            categoryName={categoryName}
            serviceName={serviceName}
            localizedCategoryName={categoryName}
          />
        </div>
        <Headline2 className={classes.header} color={colors.textOn.surface}>
          {outcomeInfo?.headline}
        </Headline2>
      </div>
      {outcome === CancellationOutcome.Terminated && cancellationInfo?.cancelledAt && (
        <Body color={colors.textOn.surface} style={{ marginTop: 8, marginLeft: '1rem' }}>
          <LocalizedMessage
            id="cancellationDate"
            values={{ cancellationDate: parseDate(cancellationInfo?.cancelledAt) }}
          />
        </Body>
      )}
      {outcomeInfo?.content && <div className={classes.content}>{outcomeInfo.content}</div>}
    </>
  );

  return (
    <Card className={classes.paper} noPadding variant="border">
      <div className={classes.container}>{content}</div>
      {!cancellationInfo.userRating && outcomeInfo?.askForRating && (
        <ContractActionOutcomeRatingCard onRatedExperience={onRateCancellationExperience} />
      )}
    </Card>
  );
};

export const ContractCancellationOutcome: React.ComponentType<Props> = withLocalization('cancel/CancellationOutcome')(
  ContractCancellationOutcomeInner
);
