import { useLocalization } from '@client/internationalization';
import { isBindingPeriodBound } from '@client/modules/subscription/ContractDetailsPage/components/SubscriptionReminder/component';
import type { ContractBindingPeriod } from '@client/modules/subscription/types';
import { BindingPeriodType } from '@client/modules/subscription/types';
import { useTheme } from '@material-ui/core/styles';
import { DatePicker } from '@minna-technologies/minna-ui/components/Inputs/DatePicker';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import isEmpty from 'lodash/fp/isEmpty';
import moment from 'moment';
import type { FC } from 'react';
import React from 'react';

interface Props {
  defaultValue: ContractBindingPeriod;
  onChange(contractBindingPeriod: ContractBindingPeriod): void;
}

export const ContractExpiringSelect: FC<Props> = ({ defaultValue, onChange }) => {
  const { colors } = useTheme();
  const { localizeMessage } = useLocalization('contract/subscriptionReminderDialog');

  const onDateChange = (endsAt: string): void => {
    const formattedDate = isEmpty(endsAt) ? endsAt : moment(endsAt).format('YYYY-MM-DD');
    // TODO: Can't get the type checker to comply here without breaking the functionality
    // @ts-ignore
    onChange({
      ...defaultValue,
      type: BindingPeriodType.FixedContractBindingPeriod,
      endsAt: formattedDate,
    });
  };

  return (
    <>
      <DatePicker
        name="contractEndingDate"
        label={localizeMessage('reminderContractEndingLabel')}
        value={defaultValue.endsAt}
        onChange={(event) => onDateChange(event.target.value ?? defaultValue.endsAt ?? '')}
        fullWidth
      />
      {isBindingPeriodBound(defaultValue) && (
        <Body color={colors.textOn.surfaceSubdued}>{localizeMessage('reminderNotifyContractExpiring')}</Body>
      )}
    </>
  );
};
