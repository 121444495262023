import type { PortingDateType } from '@client/models/order';
import type { AmountWithCurrency } from '@client/models/subscription';
import type { AddonOption } from '@client/modules/addon/utils';
import type {
  BinaryAnswer,
  CampaignPeriod,
  PaymentOption,
  PriceExpectation,
  Product,
  ServiceProvider,
  SupportChannel,
  TermsAndConditions,
} from '@client/modules/switch/common/models';

export enum AirTimePricePlan {
  FREE_CALLS = 'Free calls',
  PAY_PER_HOUR = 'Pay for hours',
}

export interface MobileImproveFlowInformation {
  providerLogos: string[];
}

export enum SubscriptionCalls {
  CURRENT_COUNTRY = 'Current country',
  EU = 'EU',
}

export enum MinimumAirTimeUnit {
  HOURS = 'hours',
  MINUTES = 'minutes',
}

export interface MobileSubscriptionFilter {
  requestedDataCapacity: number;
  currentDataCapacity?: number;
  saveUnusedData?: BinaryAnswer;
  noBindingTime?: BinaryAnswer;
  subscriptionCalls?: SubscriptionCalls;
  airTimePricePlan?: AirTimePricePlan;
  minimumAirTime?: number;
  minimumAirTimeUnit?: MinimumAirTimeUnit;
  networkOperators?: NetworkOperator[];
  customerSupportChannels?: SupportChannel[];
  priceExpectation?: PriceExpectation;
  addonOptions?: AddonOption[];
}

export interface MobileCampaign {
  discountedPricing: {
    monthlyCost: number;
  };
  period: number;
}

export enum CallsLimitation {
  LIMITED_TIME_IN_HOURS = 'LimitedTimeInHours',
  LIMITED_TIME_IN_MINUTES = 'LimitedTimeInMinutes',
  LIMITED_AMOUNT_OF_CALLS = 'LimitedAmountOfCalls',
  UNLIMITED = 'UnlimitedCalls',
}

export enum TextLimitation {
  LIMITED_MESSAGES = 'LimitedMessages',
  UNLIMITED = 'UnlimitedMessages',
}

export enum DataLimitation {
  LIMITED_DATA = 'LimitedDataCapacity',
  UNLIMITED_DATA = 'UnlimitedDataCapacity',
}

export interface PhoneCallsLimitation {
  name: CallsLimitation;
  hours?: number;
  minutes?: number;
}

export interface MessagesLimitation {
  name: TextLimitation;
  amount?: number;
}

export interface DataCapacityInGb {
  name: DataLimitation;
  amount?: number;
}

export interface ExtraDataMonthly {
  amount: number;
  fromMonth: number;
  toMonth: number;
}

export interface ExtraDataCampaign {
  oneTimeAmount?: number;
  monthly?: ExtraDataMonthly;
}

export enum MobileProductTag {
  EUCallsIncluded = 'EUCallsIncluded',
  SavedUnusedCellularData = 'SavedUnusedCellularData',
}

export interface MobileProduct extends Product {
  id: string;
  serviceProvider: ServiceProvider;
  supplier?: any;
  name: string;
  description: string;
  bindingPeriod: number;
  cancellationNoticePeriod: number;
  comparisonPrice: AmountWithCurrency;
  termsAndConditions?: TermsAndConditions[];
  networkOperatorName: NetworkOperator;
  monthlyCost: AmountWithCurrency;
  dataCapacityInGb: DataCapacityInGb;
  euDataCapacityInGb?: DataCapacityInGb;
  messagesLimitation: MessagesLimitation;
  phoneCallsLimitation: PhoneCallsLimitation;
  tags: { name: MobileProductTag }[];
  fees: { startUp?: AmountWithCurrency };
  campaign?: CampaignPeriod;
  extraData?: ExtraDataCampaign;
  welcomeDiscount?: AmountWithCurrency;
}

export interface SwedenMobileRecommendationDetailsIncoming {
  type: 'IncomingSwedenMobileRecommendationDetails';
  comparisonPrice: AmountWithCurrency;
  dataCapacity: DataCapacityInGb;
  networkOperators: NetworkOperator[];
  saveSurf: boolean;
  supportChannels: SupportChannel[];
}

export interface SwedenMobileRecommendationDetails {
  type: 'SwedenMobileRecommendationDetails';
  comparisonPrice: AmountWithCurrency;
  dataCapacity: DataCapacityInGb;
  networkOperators: NetworkOperator[];
  saveSurf: boolean;
  supportChannels: SupportChannel[];
}

export interface DenmarkMobileRecommendationDetailsIncoming {
  type: 'IncomingDenmarkMobileRecommendationDetails';
  comparisonPrice: AmountWithCurrency;
  dataCapacity: DataCapacityInGb;
  networkOperators: NetworkOperator[];
  euCalls: boolean;
  unlimitedCalls: boolean;
  minimumAirTime?: number;
  supportChannels: SupportChannel[];
}

export interface DenmarkMobileRecommendationDetails {
  type: 'DenmarkMobileRecommendationDetails';
  comparisonPrice: AmountWithCurrency;
  dataCapacity: DataCapacityInGb;
  networkOperators: NetworkOperator[];
  euCalls: boolean;
  unlimitedCalls: boolean;
  minimumAirTime?: number;
  supportChannels: SupportChannel[];
}

export type MobileRecommendationDetails = SwedenMobileRecommendationDetails | DenmarkMobileRecommendationDetails;

export type MobileRecommendationDetailsIncoming =
  | SwedenMobileRecommendationDetailsIncoming
  | DenmarkMobileRecommendationDetailsIncoming;

export enum NetworkOperator {
  TRE = 'Tre',
  TELENOR = 'Telenor',
  TELIA = 'Telia',
  TELE2 = 'Tele2',
  TELIA_TELENOR = 'Telia/Telenor',
  TDC = 'TDC',
}

export interface MobileOrderFormData {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  deliveryAddressSelected?: string;
  deliveryAddressCareOf?: string;
  deliveryAddressCity?: string;
  deliveryAddressStreet?: string;
  deliveryAddressStreetNumber?: string;
  deliveryAddressPostalCode?: string;
  deliveryAddressBox?: string;
  deliveryAddressFloor?: string;
  customDeliveryAddressChosen?: string;
  invoiceAddressChosen?: string;
  invoiceAddressCareOf?: string;
  invoiceAddressCity?: string;
  invoiceAddressStreet?: string;
  invoiceAddressStreetNumber?: string;
  invoiceAddressPostalCode?: string;
  invoiceAddressBox?: string;
  invoiceAddressFloor?: string;
  invoiceAddressSameAsDelivery?: boolean;
  email?: string;
  phoneNumber?: string;
  personalNumber?: string;
  birthDate?: string;
  placeOfBirth?: string;
  confirmTermsAndConditions?: string;
  paymentOption: PaymentOption;
  switchDateType: PortingDateType;
  switchDate?: string;
  iban?: string;
  simCardNumber?: string;
  phoneNumberToPort?: string;
  contactPermission?: string;
}
