import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { Currency } from '@client/models/subscription';
import type { TextFieldProps } from '@client/modules/shared-components/inputs/TextField';
import { TextField } from '@client/modules/shared-components/inputs/TextField';
import { costWithDecimalsRegex, parseAmountWithDecimals, removeSpaces } from '@client/utils/cost';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, defaultProps } from 'recompose';

interface ExtendedProps {
  currency: Currency;
  decimals?: number;
  validators?: any;
}

const CurrencyTextFieldInner: React.FunctionComponent<CurrencyTextFieldInnerProps> = (
  props,
  { localizeCostWithCurrency }
) => {
  const innerDefaultProps = {
    transformBlurValue: (value: any, valid: boolean) => {
      if (value && valid) {
        return localizeCostWithCurrency({ amount: parseAmountWithDecimals(value), currency: props.currency });
      } else {
        return value;
      }
    },
  };

  return <TextField {...innerDefaultProps} {...props} />;
};

const inputValidationProps = defaultProps({
  showErrorOnBlur: true,
  decimals: 2,
  inputProps: { inputMode: 'decimal' },
  type: 'text',
  validators: {
    onlyNumbers(value: string) {
      return value && costWithDecimalsRegex.test(removeSpaces(value.toString())) ? (
        false
      ) : (
        <LocalizedMessage id="amountFormatError" />
      );
    },
  },
});

type CurrencyTextFieldInnerProps = CurrencyTextFieldProps;
type CurrencyTextFieldProps = ExtendedProps & TextFieldProps;

CurrencyTextFieldInner.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
};

export const CurrencyTextField = compose<CurrencyTextFieldInnerProps, CurrencyTextFieldProps>(
  inputValidationProps,
  withLocalization('standard-components/CurrencyTextField')
)(CurrencyTextFieldInner);
CurrencyTextField.displayName = 'CurrencyTextField';
