import type { AppIntegrationImplementation } from '@client/modules/app-integration';
import { AppIntegrationType, AppPlatform, setAppIntegrationImplementation } from '@client/modules/app-integration';
import { initialize as initializeMessageReceiver } from '@client/modules/app-integration/standalone/message-receiver';
import type { History } from 'history';
import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import isFunction from 'lodash/isFunction';

let thisHistory: History;

declare global {
  interface Window {
    webkit?: { messageHandlers: any };
    minnaStandaloneApplication?: MinnaStandaloneApplication;
  }
}

function closeApplication(): boolean {
  return false;
}

function closeApplicationOrGoTo(url: string) {
  thisHistory.push(url);
}

function openExternalWebPage(url: string, event?: Event): boolean {
  if (!url) return false;

  if (event) {
    event.stopPropagation();
    event.preventDefault();
  }

  if (isStandaloneMobileApp()) {
    appOpenLink(url);

    return true;
  } else {
    window.open(url, '_blank');

    return true;
  }
}

function openInternalPage(url: string, event?: Event): boolean {
  if (!url) return false;

  if (event) {
    event.stopPropagation();
    event.preventDefault();
  }

  if (isStandaloneMobileApp()) {
    appOpenLink(url);

    return true;
  } else {
    window.open(url);

    return true;
  }
}

function getAppIntegrationType(): AppIntegrationType {
  if (isStandaloneMobileApp()) {
    return AppIntegrationType.MOBILE_APPLICATION;
  } else {
    return AppIntegrationType.WEB_BROWSER;
  }
}

function getAppPlatform(): AppPlatform {
  if (isStandaloneMobileApp()) {
    if (isIosApp()) {
      return AppPlatform.IOS_WEBVIEW;
    } else {
      return AppPlatform.ANDROID_WEBVIEW;
    }
  } else {
    return AppPlatform.WEB_BROWSER_NATIVE;
  }
}

export const standaloneAppIntegration: AppIntegrationImplementation = {
  sendAnalyticsEvent() {
    // No special handling of events in Standalone
    return false;
  },
  getAppIntegrationType,
  getAppPlatform,
  closeApplication,
  closeApplicationOrGoTo,
  openExternalWebPage,
  openInternalPage,
};

/**
 * Native interface since Android version 2.0.0
 */
export interface MinnaStandaloneApplication {
  getPlatform(): string;
  getVersion(): string;
  getApplicationInfoString(): string;
  setUserId(userId: string): void;
  openUrl(url: string): void;

  /**
   * @deprecated Use specific methods instead, such as openUrl(url).
   * @param action JSON object serialized as a string.
   */
  sendAction(action: string): void;
}

export function isStandaloneMobileApp() {
  return (
    // @ts-ignore
    typeof WebViewBridge === 'object' || // Both 1.0.0
    has(window, 'webkit.messageHandlers.reactNative.postMessage') || // iOS 1.2.0
    // @ts-ignore
    isFunction(window.originalPostMessage) || // Android 1.2.0 && iOS 1.4.0
    has(window, 'minnaStandaloneApplication')
  );
}

function postMessage(message: Record<string, unknown>) {
  const string = JSON.stringify(message);

  // @ts-ignore
  if (typeof WebViewBridge === 'object') {
    // Both 1.0.0
    // @ts-ignore
    WebViewBridge.send(string);
  } else if (window.webkit && has(window.webkit, 'messageHandlers.reactNative.postMessage')) {
    // iOS 1.2.0
    window.webkit.messageHandlers.reactNative.postMessage(string);
    // @ts-ignore
  } else if (isFunction(window.originalPostMessage)) {
    // Android 1.2.0 && iOS 1.4.0
    // @ts-ignore
    window.postMessage(string);
  } else if (window.minnaStandaloneApplication) {
    // Android 2.0.0
    window.minnaStandaloneApplication.sendAction(string);
  }
}

export function getPlatformAndVersionEstimate(): string {
  // @ts-ignore
  if (typeof WebViewBridge === 'object') {
    return 'Android 1.0.0 && iOS 1.0.0';
  } else if (window.webkit && has(window.webkit, 'messageHandlers.reactNative.postMessage')) {
    return 'iOS 1.2.0';
    // @ts-ignore
  } else if (isFunction(window.originalPostMessage)) {
    return 'Android 1.2.0 && iOS 1.4.0';
  } else if (window.minnaStandaloneApplication) {
    return `${window.minnaStandaloneApplication.getPlatform()} ${window.minnaStandaloneApplication.getVersion()}`;
  }

  return 'unknown';
}

export function isIosApp(): boolean {
  const userAgent = get(window, 'navigator.userAgent');
  const isIos = includes(userAgent, 'iPhone') || includes(userAgent, 'iPad');

  return isStandaloneMobileApp() && isIos;
}

/**
 * Returns either false to indicate that the event have been handled, or undefined to mean that no link was opened.
 */
export function appOpenLink(url: string, event?: Event): boolean | undefined {
  if (isStandaloneMobileApp() && url) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (window.minnaStandaloneApplication) {
      window.minnaStandaloneApplication.openUrl(url);
    } else {
      postMessage({ action: 'OpenUrl', url: url });
    }

    return false;
  }

  return undefined;
}

export function sendUserIdToApp(userId: string): void {
  if (isStandaloneMobileApp()) {
    if (window.minnaStandaloneApplication) {
      window.minnaStandaloneApplication.setUserId(userId);
    } else {
      postMessage({ action: 'SetUserId', userId: userId });
    }
  }
}

export function requestPushNotificationPermission() {
  if (isStandaloneMobileApp()) {
    postMessage({ action: 'RequestPushNotificationPermissions' });
  }
}

let appInfo = {};

export function setAppInfo(appInfoData: Record<string, unknown>) {
  appInfo = appInfoData;
}

export function getAppInfo(): Record<string, unknown> | undefined {
  return appInfo;
}

export function isPushNotificationPermissionGranted() {
  //Android allows push by default, iOS doesn't.
  return get(appInfo, 'pushNotificationPermissionGranted', !isIosApp());
}

export function isPushNotificationsPermissionsRequested() {
  if (isIosApp()) {
    return get(appInfo, 'requestedPushNotificationPermissions');
  }

  return true;
}

// eslint-disable-next-line
export function sendGetAppInfoToApp() {
  if (isStandaloneMobileApp()) {
    postMessage({ action: 'GetAppInfo' });
  }
}

export function initializeStandaloneMobileAppIntegration(history: History): void {
  thisHistory = history;
  setAppIntegrationImplementation(standaloneAppIntegration);
  initializeMessageReceiver();
}

export function getAndSetupStandaloneMobileAppIntegration(history: History): AppIntegrationImplementation {
  thisHistory = history;
  initializeMessageReceiver();

  return standaloneAppIntegration;
}
