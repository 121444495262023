import { useLocalization } from '@client/internationalization';
import { SubscriptionActionType } from '@client/models/subscription';
import { sendClickedSubscriptionSearchWidget } from '@client/modules/subscription-hub/mixpanel';
import { useTheme } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { ButtonText } from '@minna-technologies/minna-ui/components/Typography/ButtonText';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as urls from '../../../routes';
import { SubscriptionSearchButtonIcon } from '@client/modules/subscription-hub/SubscriptionSearchButton/SubscriptionSearchButtonIcon';

const useStyles = makeStyles(() => ({
  actionButtonContainer: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignContent: 'center',
    alignItems: 'center',
  },
  actionButtonTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const StyledFloatingButton = withStyles({
  root: {
    display: 'contents',
  },
})(Fab);

const StyledButtonText = withStyles({
  root: {
    lineHeight: '1.25rem',
    cursor: 'pointer',
  },
})(ButtonText);

export const SubscriptionSearchButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const { colors } = useTheme();
  const { localizeMessage } = useLocalization('subscriptionHub/subscriptionHub');

  const onSubscriptionSearchClicked = () => {
    sendClickedSubscriptionSearchWidget();
    history.push(urls.subscriptionSearchWithAction(SubscriptionActionType.Cancel));
  };

  return (
    <Card
      className={classes.actionButtonContainer}
      onClick={onSubscriptionSearchClicked}
      data-test="subscription-search-button"
      variant="shadow"
    >
      <div className={classes.actionButton}>
        <StyledFloatingButton disableRipple>
          <SubscriptionSearchButtonIcon
            color={colors.action.primary}
            style={{ marginLeft: '8px', marginRight: '16px', width: '48px', height: '48px' }}
          />
        </StyledFloatingButton>
        <StyledButtonText
          className={classes.actionButtonTextContainer}
          style={{ lineHeight: '16px', cursor: 'pointer' }}
        >
          <Body variant={'bold'}>{localizeMessage('subscriptionSearchButtonTitle')}</Body>
          <Caption color={colors.textOn.surfaceSubdued}>{localizeMessage('subscriptionSearchButtonText')}</Caption>
        </StyledButtonText>
      </div>
      <ChevronRightIcon />
    </Card>
  );
};
