import minnaLogo from '@client/assets/images/minna-1-icon.svg';
import type { AppContext } from '@client/modules/app-context';
import { withAppContext } from '@client/modules/app-context/react';
import { getLogoAltText } from '@client/utils/logo';
import React from 'react';
import styles from './styles.scss';

export const WelcomePageHeader = withAppContext(({ appContext }: { appContext: AppContext }) => {
  return (
    <div className={styles.header} data-test={'welcome-page-header'}>
      <img src={minnaLogo} alt={getLogoAltText({ ...appContext })} data-test={'minna-logo'} />
    </div>
  );
});
