import { useLocalization } from '@client/internationalization';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import type { FC } from 'react';
import * as React from 'react';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { makeStyles } from '@material-ui/core/styles';

export interface UpdateBankAppProps {
  dialogOpen: boolean;
  closeDialog(): void;
}

const useStyles = makeStyles((theme) => ({
  content: {
    textAlign: 'left',
    color: theme.colors.textOn.surface,
  },
}));

export const UpdateBankAppModal: FC<UpdateBankAppProps> = ({ dialogOpen, closeDialog }) => {
  const { localizeMessage } = useLocalization('shared-components/UpdateBankApp');
  const classes = useStyles();

  return (
    <Modal
      open={dialogOpen}
      title={localizeMessage('title')}
      button1={
        <PrimaryButton
          label={localizeMessage('close')}
          onClick={closeDialog}
          data-test={'update-bank-app-modal-close-button'}
        />
      }
    >
      <div className={classes.content}>
        <Body>{localizeMessage('body1V2')}</Body>
        <br />
        <Body>{localizeMessage('body2')}</Body>
        <br />
        <Body>{localizeMessage('body3V2')}</Body>
      </div>
    </Modal>
  );
};
