import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { localDateFormatString } from '@client/models/LocalDate';
import { offsetDateTimeFormat } from '@client/models/OffsetDateTime';
import type { Contract } from '@client/modules/subscription/types';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import moment from 'moment';
import * as React from 'react';
import { compose } from 'recompose';

const styles = () =>
  createStyles({
    title: {
      marginBottom: '1rem',
    },
    body: {
      marginBottom: '0.5rem',
    },
  });

interface ContractCancellationHistoryProps {
  contracts: Contract[];
}

export const ContractCancellationHistoryInnerComponent: React.FC<
  ContractCancellationHistoryProps & WithStyles<typeof styles>
> = ({ contracts, classes }: ContractCancellationHistoryProps & WithStyles<typeof styles>) => (
  <Card>
    <Subheading1 className={classes.title}>
      <LocalizedMessage id="title" />
    </Subheading1>
    {contracts.map((contract) => {
      const date = contract.terminatedAt
        ? moment(contract.terminatedAt, offsetDateTimeFormat).format(localDateFormatString)
        : '';

      return (
        <Body key={contract.id} className={classes.body}>
          <LocalizedMessage id="cancelledOnMessage" values={{ service: contract.service.name, date: date }} />
        </Body>
      );
    })}
  </Card>
);

export const ContractCancellationHistory = compose<
  ContractCancellationHistoryProps & WithStyles<typeof styles>,
  ContractCancellationHistoryProps
>(
  withStyles(styles),
  withLocalization('contract/CancellationHistory')
)(ContractCancellationHistoryInnerComponent);
