import { Sentry } from '@client/assets/js/sentry';
import type { CancellationId } from '@client/models/cancellation';
import { getCancellationId } from '@client/models/cancellation';
import type { ServiceId, SupplierId, SupplierService, SupplierWithServices } from '@client/models/supplier';
import { fetchSupplier } from '@client/models/supplier';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { CancellationSentPage } from '@client/modules/cancellation/CancellationSentPage/components/CancellationSentPage';
import { onClickRating } from '@client/modules/cancellation/CancellationSentPage/utils';
import LoadingPage from '@client/modules/fetching/LoadingPage';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as urls from '@client/routes';

interface Props {
  serviceId: ServiceId;
  supplierId: SupplierId;
}

export const CancelServiceSentPage = ({ supplierId, serviceId }: Props): JSX.Element => {
  const appContext = useSelector(selectorAppContext);
  const history = useHistory();
  const [merchant, setMerchant] = useState<SupplierWithServices | undefined>(undefined);
  const [service, setService] = useState<SupplierService | undefined>(undefined);
  const [cancellationId, setCancellationId] = useState<CancellationId | undefined>(undefined);

  useEffect(() => {
    getCancellationId(supplierId, serviceId).then((id) => setCancellationId(id));
    fetchSupplier(supplierId).then((supplier) => {
      setMerchant(supplier);
      const newService = supplier.services?.find((service) => service.id === serviceId);
      if (newService === undefined) {
        Sentry.captureMessage('Failed to find service to be cancelled', { extra: { supplierId, serviceId } });
      }
      setService(newService);
    });
  }, [serviceId, supplierId]);

  const overviewEnabled = appContext.features.identify?.identify || false;
  const onRedirectUser = () => history.push(overviewEnabled ? urls.overviewPage : urls.subscriptionHub);

  return (
    (merchant && service && (
      <CancellationSentPage
        merchant={merchant}
        service={service}
        categoryName={service.category.name}
        subscriptionMethod={service.cancellationMethod.type}
        saveDeskEnabled={service.saveDeskEnabled}
        onRate={onClickRating(merchant, service, appContext.market, service.category.name, cancellationId)}
        onRedirectUser={onRedirectUser}
      />
    )) || <LoadingPage />
  );
};
