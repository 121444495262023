import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { selectorNotLoggedIn } from '../ducks/user';
import type { AppType } from '../modules/app-context/constants';
import { selectorAppType } from '../modules/app-context/duck';
import * as urls from '../routes';
// @ts-ignore js import, remove this when the import is typed
import { redirect } from './container-helpers';

const mapStateToProps = (state: any) => ({
  notLoggedIn: selectorNotLoggedIn(state),
  appType: selectorAppType(state),
});

interface RedirectProps {
  notLoggedIn: boolean;
  appType: AppType;
  location: Location;
}

/**
 * HOC for requiring that a user is logged in.
 * A user that is not logged in will be redirected to an appropriate page.
 */
export const requireLoggedInUser = compose(
  withRouter,
  connect(mapStateToProps),
  redirect(
    (p: RedirectProps) => p.notLoggedIn,
    (props: RedirectProps) => {
      return { pathname: urls.loggedOutPage, state: { redirectOnLogin: props.location.pathname } };
    }
  )
);

// We allow this since we were using it from so many places when introducing the lint rule.
// eslint-disable-next-line import/no-default-export
export default requireLoggedInUser;
