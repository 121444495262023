import classNames from 'classnames';
import { Checkbox } from 'material-ui';
import RadioChecked from 'material-ui/svg-icons/toggle/radio-button-checked';
import RadioUnchecked from 'material-ui/svg-icons/toggle/radio-button-unchecked';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.scss';

export const RadioItem = ({ text, checked, onCheck, className, children }: any) => {
  const labelStyle = {
    fontSize: '14px',
  };

  return (
    <div className={classNames(styles['radio-item'], className)}>
      <Checkbox
        label={text}
        checked={checked}
        onCheck={onCheck}
        labelStyle={labelStyle}
        checkedIcon={<RadioChecked />}
        uncheckedIcon={<RadioUnchecked />}
      />
      {children}
    </div>
  );
};
RadioItem.propTypes = {
  text: PropTypes.node.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};
