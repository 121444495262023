import { MessageToUser } from '@client/modules/cancellation/CancellationOutcome/components/MessageToUser';
import type { FC } from 'react';
import React from 'react';

interface Props {
  messageFromSupport?: string;
}

export const UserRegrettedCancellationContent: FC<Props> = ({ messageFromSupport }) => {
  return messageFromSupport ? <MessageToUser messageToUser={messageFromSupport} /> : null;
};
