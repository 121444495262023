/* eslint-disable import/no-unassigned-import */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Sentry } from '@client/assets/js/sentry';
import { loggedIn, userIsLoading, userLoadingFailure, userNotLoggedIn } from '@client/ducks/user';
import { fetchAppContext, initializeMixpanel } from '@client/foundation/initUtils';
import { ActionKeys } from '@client/internationalization/duck';
import { loadMessagesAndLocale } from '@client/internationalization/load';
import type { PlatformName } from '@client/models/platform';
import { getUser } from '@client/models/user';
import { startAppIntegration } from '@client/modules/app-integration/app-integrations';
import LoadingPage from '@client/modules/fetching/LoadingPage';
import { setGlobalStore } from '@client/modules/global-store';
import type { AxiosError } from 'axios';
import { createBrowserHistory } from 'history';
import 'intl';
import 'intl/locale-data/jsonp/en-GB.js';
import 'intl/locale-data/jsonp/sv-SE.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorLoadingEndUserApp } from './foundation/components/ErrorLoadingEndUserApp';
import { EndUserApp } from './foundation/EndUserApp';
import { store } from './foundation/state/store';
import './locale';
import './styles.scss';

const history = createBrowserHistory();

Sentry.setGlobalTag('application', 'end-user-unknown'); // Set a base value in case we crash before overriding it to e.g. "end-user-Swedbank"

setGlobalStore(store);

const messagesAndLocalePromise = async () =>
  loadMessagesAndLocale().then((messagesAndLocale) =>
    store.dispatch({
      type: ActionKeys.FETCH_MESSAGES_AND_LOCALE,
      promise: Promise.resolve(messagesAndLocale),
    })
  );

// Show loading screen while app-context gets loaded from the server.
ReactDOM.render(<LoadingPage />, document.getElementById('root'));

void (async () => {
  // these are considered critical for the app to function. Bail out if they don't exist.
  try {
    await Promise.resolve(store.dispatch(userIsLoading()))
      .then(async () => {
        return getUser();
      })
      .then((user) => store.dispatch(loggedIn(user)))
      .catch((axiosError: AxiosError) => {
        if (axiosError.response?.status === 401) {
          store.dispatch(userNotLoggedIn());
        } else {
          store.dispatch(userLoadingFailure());
          Sentry.captureExceptionWithMessage(axiosError, 'Failed to load user');
        }
      });
    const appContext = await fetchAppContext(store);
    await messagesAndLocalePromise();

    Sentry.setGlobalTag('application', 'end-user-' + appContext.appType.toString());

    // TODO appContext should contain PlatformName rather than AppType
    const platformName = appContext.appType.toString() as PlatformName;

    startAppIntegration(platformName, history);

    // Non-critical resources, render even if they fail
    try {
      initializeMixpanel();
    } finally {
      ReactDOM.render(<EndUserApp history={history} appContext={appContext} />, document.getElementById('root'));
    }
  } catch (error) {
    Sentry.captureExceptionWithMessage(error, 'Failed to load critical resource on startup');
    ReactDOM.render(<ErrorLoadingEndUserApp />, document.getElementById('root'));
  }
})();
