import type { AppContext } from '@client/modules/app-context';
import { zendeskOnlyEmailEnabled } from '@client/modules/app-context';
import { withAppContext } from '@client/modules/app-context/react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { AppType } from '../../../../app-context/constants';
import { conditionalRender } from '../../../../fetching/conditionalRender';
import { navigateToCallBack, navigateToCallUs, navigateToChat, navigateToEmailUs } from '../../../ducks';
import { selectorIsAgentAvailable } from '../../../ducks/zendesk-chat';
import { ZendeskEmailUsContainer } from '../EmailUs';
import { StartScreenContainer } from '../StartScreen';
import { ZendeskContactUs } from './component';

const mapStateToProps = (state: any, { appContext }: any) => {
  return {
    isAgentAvailable: selectorIsAgentAvailable(state),
    market: appContext.market,
    helpCenter: appContext.features.helpCenter,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onNavigateToCallUs: () => {
      dispatch(navigateToCallUs());
    },
    onNavigateToCallBack: () => {
      dispatch(navigateToCallBack());
    },
    onNavigateToEmailUs: () => {
      dispatch(navigateToEmailUs());
    },
    onNavigateToChat: () => {
      dispatch(navigateToChat());
    },
  };
};

export const ZendeskContactUsContainer = compose<any, any>(
  withAppContext,
  conditionalRender(
    ({ appContext }: { appContext: AppContext }) => zendeskOnlyEmailEnabled(appContext.features),
    ZendeskEmailUsContainer
  ),
  // TODO: use feature toggle when adding full support for 'contact your bank' in Zendesk
  conditionalRender(
    ({ appContext }: { appContext: AppContext }) => appContext.appType === AppType.ING,
    StartScreenContainer
  ),
  connect(mapStateToProps, mapDispatchToProps)
)(ZendeskContactUs);

ZendeskContactUsContainer.propTypes = {
  onBackClick: PropTypes.func.isRequired,
};
