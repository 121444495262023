import { Sentry } from '@client/assets/js/sentry';
import { ELECTRICITY_GRID } from '@client/constants/categories';
import { selectorUser } from '@client/ducks/user';
import { useLocalization } from '@client/internationalization';
import type { QuestionId } from '@client/models/cancellation-questions';
import type { SupplierService, SupplierWithServices } from '@client/models/supplier';
import { fetchSupplier } from '@client/models/supplier';
import type { User } from '@client/models/user-models';
import { isCancellationWithTermsEnabled, SigningFeature } from '@client/modules/app-context';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { onSendCancelServiceRequest } from '@client/modules/cancellation/CancellationFormPage/CancelServiceForm/logic';
import { CancellationDataSection } from '@client/modules/cancellation/CancellationFormPage/components/CancellationDataSection';
import { CancellationQuestionsSection } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection';
import { ConfirmSectionComponent } from '@client/modules/cancellation/CancellationFormPage/components/ConfirmSection';
import { SigningSection } from '@client/modules/cancellation/CancellationFormPage/components/SigningSection';
import { UserInfoForm } from '@client/modules/cancellation/CancellationFormPage/components/UserInfoForm';
import type { AddressInformation } from '@client/modules/cancellation/CancellationFormPage/models';
import { updateCancellationAnswers } from '@client/modules/cancellation/CancellationFormPage/models';
import {
  CancellationAction,
  selectorDesiredTerminationAt,
  selectorFormSubmitted,
  selectorIsCancelButtonClicked,
  selectorTerminationAnswers,
  selectorTerminationQuestions,
} from '@client/modules/cancellation/duck';
import {
  trackClickedCancelServiceButton,
  trackViewedCancelServiceTermsDialog,
} from '@client/modules/cancellation/mixpanel-events';
import type { UserInformationForm } from '@client/modules/cancellation/models';
import LoadingPage from '@client/modules/fetching/LoadingPage';
import { ContractDetailsFloatingButtons } from '@client/modules/subscription/ContractDetailsPage/components/ContractDetailsFloatingButtons';
import {
  selectorUserInformationForm,
  updateUserInformationForm,
  updateUserInformationFormField,
} from '@client/modules/subscription/duck';
import { TrackingButtonLocation, TrackingButtonName, TrackingPageName } from '@client/tracking/mixpanel-constants';
import { trackViewedCancelServiceForm } from '@client/tracking/mixpanel-events';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import { makeStyles } from '@material-ui/core/styles';
import { ContractHeaderDeprecated } from '@minna-technologies/minna-ui/components/ContractHeaderDeprecated';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import isFunction from 'lodash/isFunction';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPrefilledUserInformationForm } from '../../utils';

const footer = (isCancelButtonClicked: boolean, onSubmitCancellationForm: (values: any) => void) => {
  return (
    <ContractDetailsFloatingButtons
      backButton
      isCancelButtonClicked={isCancelButtonClicked}
      signButton
      onSubmitCancellationForm={onSubmitCancellationForm}
    />
  );
};

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: 'grid',
    gridRowGap: '16px',
  },
}));

interface Props {
  serviceId: string;
  merchantId: string;
}

export const CancelServiceFormPage = ({ serviceId, merchantId }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const appContext = useSelector(selectorAppContext);
  const { localizeMessage } = useLocalization('termination/TerminationFormPage');

  const isCancelButtonClicked = useSelector(selectorIsCancelButtonClicked);
  const form: UserInformationForm = useSelector(selectorUserInformationForm);
  const user: User = useSelector(selectorUser);
  const desiredTerminationAt = useSelector(selectorDesiredTerminationAt);
  const onSendCancellation = () => onSendCancelServiceRequest(dispatch, appContext, history, merchantId, serviceId);
  const onFormPropertyUpdate = (propertyName: keyof UserInformationForm, newValue: any) => {
    dispatch(updateUserInformationFormField(propertyName, newValue));
  };
  const onSetAnswer = (questionId: string, value: any) => {
    dispatch(CancellationAction.setTerminationAnswer(questionId, value));
  };

  const classes = useStyles();
  const methods = useMinnaForm<any>();
  const { watch, getValues, trigger, errors } = methods;
  const customDeliveryDateChosen: string | undefined = watch('customDeliveryDateChosen');
  const watchRecipientQuestion = (questionId: QuestionId) => watch('recipientQuestion' + questionId);
  const watchNestedQuestion = (questionId: QuestionId) => watch('nestedQuestion' + questionId);
  const [checkboxSigningDialogOpen, setCheckboxSigningDialogOpen] = useState<boolean>(false);
  const checkboxOrCheckboxWithProof =
    appContext.features.signing === SigningFeature.CHECKBOX ||
    appContext.features.signing === SigningFeature.CHECKBOX_WITH_PROOF;

  const cancellationQuestions = useSelector(selectorTerminationQuestions);
  const terminationAnswers = useSelector(selectorTerminationAnswers);
  const formSubmitted = useSelector(selectorFormSubmitted);
  const [merchant, setMerchant] = useState<SupplierWithServices | undefined>(undefined);
  const [service, setService] = useState<SupplierService | undefined>(undefined);

  useEffect(() => {
    fetchSupplier(merchantId).then((supplier) => {
      setMerchant(supplier);
      const newService = supplier.services?.find((service) => service.id === serviceId);
      if (newService === undefined) {
        Sentry.captureMessage('Failed to find service to be cancelled', { extra: { merchantId, serviceId } });
      }
      setService(newService);
      trackViewedCancelServiceForm(
        supplier.id,
        supplier.name,
        serviceId,
        newService?.cancellationMethod.type,
        newService?.name,
        newService?.category.name
      );
    });
  }, [serviceId, merchantId]);

  useEffect(() => {
    if (service !== undefined) {
      dispatch(CancellationAction.updateTerminationInformation(service?.terminationQuestions));
    }
  }, [dispatch, service, user]);

  useMountEffect(() => {
    dispatch(updateUserInformationForm(getPrefilledUserInformationForm(user)));
  });

  const maybeAddressInfo: AddressInformation = {
    street: form.street,
    city: form.city,
    careOf: form.careOf,
    postalCode: form.postalCode,
  };
  const categoryName = service?.category?.name ?? '';
  const categoryText = service?.category?.text;

  const storeFormValues = () => {
    const values = getValues();
    updateCancellationAnswers(cancellationQuestions, values, dispatch, appContext.locale);
    if (values.customDeliveryDateChosen === 'CustomDate' || categoryName === ELECTRICITY_GRID) {
      dispatch(CancellationAction.setDesiredTerminationAt(values.desiredTerminationAt));
    } else {
      dispatch(CancellationAction.setDesiredTerminationAt());
    }
  };

  const onSubmitCancellationForm = () => {
    storeFormValues();
    onSendCancellation();
  };

  const trackClickedSubmitButton = () => {
    trackClickedCancelServiceButton(
      TrackingPageName.CANCEL_SERVICE_FORM,
      merchantId,
      merchant?.name ?? '',
      serviceId,
      service?.name ?? '',
      service?.category?.name ?? '',
      TrackingButtonName.CANCEL_NOW,
      TrackingButtonLocation.BOTTOM_CENTER,
      service?.cancellationMethod.type
    );
  };

  const onSubmitButtonClick = () => {
    trigger().then((isValidForm: boolean) => {
      if (isValidForm) {
        trackClickedSubmitButton();
        onSubmitCancellationForm();
      }
    });
  };

  const onSubmitButtonClickWithCheckbox = () => {
    trigger().then((isValidForm: boolean) => {
      if (isValidForm) {
        trackClickedSubmitButton();
        setCheckboxSigningDialogOpen(true);
      }
    });
  };

  const callbackOrScrollToErrors = (callBack: () => void) => {
    trigger().then((isValidForm: boolean) => {
      if (isValidForm) {
        storeFormValues();
        callBack();
      } else {
        const errorQuestion = Object.keys(errors)[0];
        if (isFunction(errors[errorQuestion]?.ref?.scrollIntoView)) {
          errors[errorQuestion]?.ref?.scrollIntoView({ block: 'center' });
        }
      }
    });
  };

  const finishedLoading = !!merchant && !!service;

  const clickedButtonMixpanelEvent = (buttonName: TrackingButtonName, buttonLocation: TrackingButtonLocation) => {
    trackClickedCancelServiceButton(
      TrackingPageName.DETAILS,
      merchantId,
      merchant?.name ?? '',
      serviceId,
      service?.name ?? '',
      categoryName,
      buttonName,
      buttonLocation
    );
  };

  const viewCancellationTermsDialogMixpanelEvent = () => {
    trackViewedCancelServiceTermsDialog(
      TrackingPageName.DETAILS,
      merchantId,
      merchant?.name ?? '',
      serviceId,
      service?.name ?? '',
      categoryName,
      TrackingButtonName.CANCEL_TERMS
    );
  };

  const content = finishedLoading && (
    <MultiDeviceLayout
      fullWidthContent={
        <ContractHeaderDeprecated
          providerLogo={merchant?.logoUrl ?? ''}
          providerName={merchant?.name}
          subHeading={merchant?.name}
          title={categoryText ?? ''}
        />
      }
      footer={footer(
        isCancelButtonClicked,
        checkboxOrCheckboxWithProof ? onSubmitButtonClickWithCheckbox : onSubmitButtonClick
      )}
    >
      <Form onSubmit={onSubmitCancellationForm} formMethods={methods}>
        <div className={classes.cardContainer}>
          {form.prefilled && (
            <UserInfoForm
              title={localizeMessage('userFormTitle')}
              formData={form}
              onFormPropertyUpdate={onFormPropertyUpdate}
              appContext={appContext}
            />
          )}
          {cancellationQuestions?.length > 0 ? (
            <CancellationQuestionsSection
              cancellationQuestions={cancellationQuestions}
              terminationAnswers={terminationAnswers}
              onSetAnswer={onSetAnswer}
              formSubmitted={formSubmitted}
              userLocale={appContext.locale}
              market={appContext.market}
              addressOnUser={maybeAddressInfo}
              watchRecipientQuestion={watchRecipientQuestion}
              watchNestedQuestion={watchNestedQuestion}
            />
          ) : null}
          <CancellationDataSection
            desiredTerminationAt={desiredTerminationAt}
            customDeliveryDateChosen={customDeliveryDateChosen}
            contractCategoryName={categoryName}
            supplierName={merchant?.name}
          />
          {appContext.features.signing === SigningFeature.CHECKBOX ||
          appContext.features.signing === SigningFeature.CHECKBOX_WITH_PROOF ? (
            <ConfirmSectionComponent
              supplierName={merchant?.name}
              onPreviewLoa={callbackOrScrollToErrors}
              onSendCancellation={onSendCancellation}
              useCancellationTerms={isCancellationWithTermsEnabled(appContext.features.subscriptionFeatures)}
              onValid={callbackOrScrollToErrors}
              checkboxSigningDialogOpen={checkboxSigningDialogOpen}
              setCheckboxSigningDialogOpen={setCheckboxSigningDialogOpen}
              clickedButtonMixpanelEvent={clickedButtonMixpanelEvent}
              viewCancellationTermsDialog={viewCancellationTermsDialogMixpanelEvent}
              merchantId={merchantId}
              serviceId={serviceId}
            />
          ) : (
            <SigningSection
              supplierName={merchant?.name}
              onPreviewLoa={callbackOrScrollToErrors}
              merchantId={merchantId}
              serviceId={serviceId}
            />
          )}
        </div>
      </Form>
    </MultiDeviceLayout>
  );
  return (finishedLoading && content) || <LoadingPage />;
};
