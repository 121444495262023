import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { getMixpanel } from '@client/tracking/mixpanel';
import {
  TrackingButtonLocation,
  EventNames,
  FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
} from '@client/tracking/mixpanel-constants';
import { clickedComplexContractGuideMixpanelEvent } from '@client/tracking/mixpanel-events';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useTheme from '@material-ui/core/styles/useTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { CloseIcon } from '@minna-technologies/minna-ui/icons/Close';
import React from 'react';
import { useSelector } from 'react-redux';
import highFiveIconUrl from '../icons/highFive.svg';

const ResolveSubscriptionsFinishDialogComponent: React.FunctionComponent<ResolveSubscriptionsDialogProps> = ({
  onClose,
  onSaveAndContinueToInsightsPage,
  subscriptionsInGuide,
  open,
}) => {
  const features = useSelector(selectorFeatures);
  const { colors } = useTheme();

  const useUnresolvedSubscriptionsGuide = features.useUnresolvedSubscriptionGuide;
  function onCloseDialog() {
    if (useUnresolvedSubscriptionsGuide) {
      getMixpanel().track(EventNames.CLICKED_BUTTON, {
        Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
        Page: pageName,
        Type: 'Contract guide',
        Location: 'Top right',
        Button: 'Close',
      });
    } else {
      clickedComplexContractGuideMixpanelEvent(
        pageName,
        subscriptionsInGuide,
        TrackingButtonLocation.TOP_RIGHT,
        'Close'
      );
    }
    onClose();
  }
  function onFinish() {
    if (useUnresolvedSubscriptionsGuide) {
      getMixpanel().track(EventNames.CLICKED_BUTTON, {
        Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
        Page: pageName,
        Type: 'Contract guide',
        Location: TrackingButtonLocation.BOTTOM_CENTER,
        Button: 'Finish',
      });
    } else {
      clickedComplexContractGuideMixpanelEvent(
        pageName,
        subscriptionsInGuide,
        TrackingButtonLocation.BOTTOM_CENTER,
        'Finish'
      );
    }
    onSaveAndContinueToInsightsPage();
  }

  return (
    <StyledDialog open={open} maxWidth="sm" onClose={onCloseDialog}>
      <StyledDialogTitle disableTypography>
        <Title color={colors.action.primary} style={{ flexGrow: 1, textAlign: 'center' }}>
          <LocalizedMessage id="thatsAllFolks" />
        </Title>
        <CloseIcon onClick={onCloseDialog} />
      </StyledDialogTitle>
      <DialogContent style={{ padding: '0' }}>
        <img src={highFiveIconUrl} role="presentation" alt="" />
        <Body style={{ margin: '20px 16px 32px 32px' }}>
          <LocalizedMessage id="thanksForLettingUsKnowYou" />
        </Body>
      </DialogContent>
      <DialogActions>
        <StyledPrimaryButton
          data-test={'unresolved-guide-done'}
          label={<LocalizedMessage id="close" />}
          onClick={onFinish}
        />
      </DialogActions>
    </StyledDialog>
  );
};

export interface ResolveSubscriptionsDialogProps {
  onClose(): void;
  onSaveAndContinueToInsightsPage(): void;
  subscriptionsInGuide: number;
  open: boolean;
}

export const ResolveSubscriptionsFinishDialog = withLocalization('overview/InsightsPage/OnboardingInsightCard')(
  ResolveSubscriptionsFinishDialogComponent
);

const StyledDialog = withStyles({
  paperWidthSm: {
    width: '343px',
    flexShrink: 0,
  },
})(Dialog);

const StyledDialogTitle = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
})(DialogTitle);

const StyledPrimaryButton = withStyles({
  root: {
    margin: '0 16px 4px 16px',
    width: '100%',
  },
})(PrimaryButton);

const pageName = 'Outro';
