import { sessionKeepAlive } from '@client/models/user';
import type { AxiosError } from 'axios';
import { throttle } from 'lodash';

/**
 * Session logic:
 * - Only users that are active should have their session kept alive.
 * - "Active users" means users clicking, scrolling, using the app in some way.
 *
 * Implementation details:
 * - If the history.location is being updated, then the user is definitely active.
 * - It would be nice to not unnecessarily spam Core. So we throttle the calls.
 * - If the user goes inactive for a long time and then comes back, their session will have expired. Then we have the possibility to e.g. dispatch an event or redirect.
 */
async function doSessionKeepAlive(onNotLoggedIn?: () => void): Promise<void> {
  sessionKeepAlive().catch((error: AxiosError) => {
    if (onNotLoggedIn && error.response?.status === 401) {
      onNotLoggedIn();
    }
  });
}

/** Note: If there are several callers to markUserActive it's not sure which onNotLoggedIn will be called. */
export const markUserActive = throttle(doSessionKeepAlive, 30000);
