//@ts-ignore
import { loadingPageWhileLoading, onEnter } from '@client/containers/container-helpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withAppContext } from '../../../../app-context/react';
import { navigateToSection } from '../../../ducks';
import { filterArticlesByCategory, selectorLoadingArticles } from '../../../ducks/zendesk-help-center';
import { DisplayCategory } from './component';

const mapStateToProps = (state: any) => {
  return {
    loadingArticles: selectorLoadingArticles(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onNavigateToSection: (categoryId: any, sectionId: any) => {
      dispatch(navigateToSection(categoryId, sectionId));
    },
  };
};

export const DisplayCategoryContainer = compose<any, any>(
  withAppContext,
  connect(mapStateToProps, mapDispatchToProps),
  onEnter(({ appContext, category }: any, { store }: any) => {
    // TODO do not use dispatch in onEnter, create a fn in dispatchToProps instead
    store.dispatch(
      filterArticlesByCategory(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, category.id)
    );
  }),
  loadingPageWhileLoading((p: any) => p.loadingArticles)
)(DisplayCategory);

DisplayCategoryContainer.propTypes = {
  category: PropTypes.object.isRequired,
};
