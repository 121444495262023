import { Locale, Market } from '../modules/app-context/constants';

export function getSupportNumber(market: Market): string {
  switch (market) {
    case Market.Sweden:
    case Market.Denmark:
    case Market.UnitedKingdom:
    case Market.Norway:
    case Market.Finland:
    case Market.Belgium:
      return '+46 (0)31 - 320 45 34';
    default:
      throw new Error(`${market} has no support number!`);
  }
}

export function getLocalSupportNumber(market: Market): string {
  switch (market) {
    case Market.Sweden:
      return '031 - 320 45 34';
    case Market.Denmark:
    case Market.Belgium:
    case Market.UnitedKingdom:
    case Market.Norway:
    case Market.Finland:
      return 'Missing phone number';
    default:
      throw new Error(`${market} has no local support number!`);
  }
}

export function getRawSupportNumber(market: Market): string {
  switch (market) {
    case Market.Denmark:
    case Market.Sweden:
    case Market.Belgium:
    case Market.UnitedKingdom:
    case Market.Finland:
    case Market.Norway:
      return '+46313204534'; // TODO Update for Belgium, UnitedKingdom and Norway
    default:
      throw new Error(`${market} has no support number!`);
  }
}

export function getMarketSpecificSupportHours(market: Market) {
  switch (market) {
    case Market.Finland:
      return {
        mondayOpeningHour: '11:30',
        lunchBreakStartingHour: '13:00',
        lunchBreakEndingHour: '14:00',
        openingHour: '10:00',
        closingHour: '17:00',
      };
    default:
      return {
        mondayOpeningHour: '10:30',
        lunchBreakStartingHour: '12:00',
        lunchBreakEndingHour: '13:00',
        openingHour: '09:00',
        closingHour: '16:00',
      };
  }
}

export function getIngBankContactUrl(locale: Locale) {
  switch (locale) {
    case Locale.frBE:
      return 'https://www.ing.be/fr/retail/contact';
    case Locale.nlBE:
      return 'https://www.ing.be/nl/retail/contact';
    default:
      return 'https://www.ing.be/en/retail/contact';
  }
}
