import type { CategoryName } from '@client/constants/categories';
import type { Question } from '@client/models/cancellation-questions';
import type { CancellationMethod, ServiceToDisplay } from '@client/models/service';
import type { SubscriptionActionType } from '@client/models/subscription';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import filter from 'lodash/fp/filter';
import reject from 'lodash/fp/reject';

export interface SupplierWithServicesCategory {
  name: CategoryName;
  text: string;
}

export type ServiceId = string;

export interface SupplierService {
  id: ServiceId;
  name: string;
  terminationQuestions: Question[];
  category: SupplierWithServicesCategory;
  valueAddedService: boolean;
  terminable: boolean;
  saveDeskEnabled: boolean;
  terminationInstructions?: string;
  cancellationMethod: CancellationMethod;
}

export type SupplierId = string;

export interface SupplierWithServices {
  id: SupplierId;
  name: string;
  logoUrl?: string;
  services: SupplierService[];
}

export type ResourceName = string;
export type Language = string;

export async function fetchSupplier(supplierId: SupplierId): Promise<SupplierWithServices> {
  return axios
    .get(`/api/suppliers-with-services/${supplierId}`)
    .then(({ data }: AxiosResponse<SupplierWithServices>) => data);
}

export async function fetchSuppliers(): Promise<SupplierWithServices[]> {
  return axios.get('/api/suppliers-with-services').then(({ data }: AxiosResponse<SupplierWithServices[]>) => data);
}

export async function fetchSubscriptionSearchSuppliers(
  action: SubscriptionActionType
): Promise<SupplierWithServices[]> {
  return axios
    .get('/api/v1/subscription-search/merchants', { params: { subscriptionAction: action } })
    .then(({ data }: AxiosResponse<SupplierWithServices[]>) => data);
}

export async function fetchSubscriptionSearchTopSuppliers(
  action: SubscriptionActionType
): Promise<SupplierWithServices[]> {
  return axios
    .get('/api/v1/subscription-search/top-merchants', { params: { subscriptionAction: action } })
    .then(({ data }: AxiosResponse<SupplierWithServices[]>) => data);
}

export interface Supplier {
  id: SupplierId;
  name: string;
  verified: boolean;
  logo?: ResourceName;
  language: Language;
  services?: ServiceToDisplay[];
}

export const isValueAddedService = (service: SupplierService | ServiceToDisplay): boolean => service.valueAddedService;

export const filterRegularSupplierServices = (services: SupplierService[]): SupplierService[] =>
  reject(isValueAddedService, services);

export const filterRegularServicesToDisplay = (services: ServiceToDisplay[]): ServiceToDisplay[] =>
  reject(isValueAddedService, services);

export const filterValueAddedSupplierServices = (services: SupplierService[]): SupplierService[] =>
  filter(isValueAddedService, services);

export const filterValueAddedServicesToDisplay = (services: ServiceToDisplay[]): ServiceToDisplay[] =>
  filter(isValueAddedService, services);
