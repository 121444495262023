import { promisify } from '@client/utils/promise';
//@ts-ignore
import zChat from '../../../../../../../../vendor/web_sdk';

export const onClose = async () => removeTag();

export const onMinimize = async () => removeTag();

const removeTag = async () => {
  const remove = zChat.removeTag.bind(zChat, 'Help center open');

  return promisify(remove).catch(); // ignore failures, could be zchat not initialized
};

export const onOpen = async () => {
  const addTag = zChat.addTag.bind(zChat, 'Help center open');

  return promisify(addTag).catch(); // ignore failures, could be zchat not initialized
};
