import { useLocalization } from '@client/internationalization';
import { paymentIntervalsPer } from '@client/models/payment-intervals';
import type { PaymentInterval } from '@client/modules/subscription/types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { intervalToString } from '@minna-components/interval-to-string';
import { Select } from '@minna-technologies/minna-ui/components/Inputs/Select';
import { SelectOption } from '@minna-technologies/minna-ui/components/Inputs/Select/components/SelectOption';
import { indexOf, isNil } from 'lodash/fp';
import type { ChangeEvent, FC, ReactNode } from 'react';
import React, { useState } from 'react';

const useStyles = makeStyles({
  overrideSelectWidth: {
    '& select': { minWidth: 'auto' },
  },
});

interface Props {
  defaultValue?: PaymentInterval;
  disabled?: boolean;
  fullWidth: boolean;
  label: ReactNode;
  name: string;
  onChange(paymentInterval: PaymentInterval): void;
}

export const PaymentIntervalSelect: FC<Props> = ({ defaultValue, disabled, fullWidth, label, name, onChange }) => {
  const classes = useStyles();
  const { localizeMessage } = useLocalization('interval-to-string');

  const initialPaymentInterval = isNil(defaultValue) ? 3 : indexOf({ interval: defaultValue }, paymentIntervalsPer);

  const [selectedPaymentInterval, setSelectedPaymentInterval] = useState(
    initialPaymentInterval >= 0 ? initialPaymentInterval : 3
  );

  const paymentIntervalSelectOptions = paymentIntervalsPer.map(({ interval }, i) => (
    <SelectOption key={i} value={i} label={intervalToString(localizeMessage)(interval)} />
  ));

  const paymentIntervalByIndex = (i: number): PaymentInterval => {
    return paymentIntervalsPer[i].interval;
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    setSelectedPaymentInterval(0);
    onChange(paymentIntervalByIndex(Number(e.target.value)));
  };

  return (
    <Select
      className={classes.overrideSelectWidth}
      defaultValue={selectedPaymentInterval}
      name={name}
      label={label}
      disabled={disabled}
      fullWidth={fullWidth}
      onChange={handleChange}
    >
      {paymentIntervalSelectOptions}
    </Select>
  );
};
