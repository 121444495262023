import { ErrorComponent } from '@client/components/error/ErrorComponent';
import { GenericErrorPageContainer } from '@client/components/error/GenericErrorPage';
import { selectorIsMessagesFetched } from '@client/internationalization/duck';
import { LocalizationProvider } from '@client/internationalization/LocalizationProvider';
import { selectorIsAppContextAvailable } from '@client/modules/app-context/duck';
import { useAppTheme } from '@client/utils/hooks/use-app-theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { minnaTheme } from '@minna-technologies/minna-ui/styles/themes/minna';
import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../state/store';

export const ErrorLoadingEndUserApp: React.FunctionComponent = () => {
  try {
    const theme = useAppTheme();
    const state = store.getState();
    const isMessagesFetched = selectorIsMessagesFetched(state);
    const isAppContextAvailable = selectorIsAppContextAvailable(state);
    if (isMessagesFetched && isAppContextAvailable) {
      return (
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <LocalizationProvider>
              <GenericErrorPageContainer />
            </LocalizationProvider>
          </MuiThemeProvider>
        </Provider>
      );
    }
  } catch (ex) {
    // Ignore, since the outer block will return.
  }

  return (
    // Typography needs a theme for text colors, so insert a default here even
    // if all loading has failed
    <MuiThemeProvider theme={minnaTheme}>
      <ErrorComponent
        headerText="Something went wrong"
        imageAltText="Something went wrong"
        bodyText="We’re working on it. Please try again."
      />
    </MuiThemeProvider>
  );
};
