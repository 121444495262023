import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { EditUserInfo } from '@client/modules/cancellation/CancellationFormPage/components/UserInfoForm/EditUserInfo';
import type { UserInformationForm } from '@client/modules/cancellation/models';
import grid from '@client/styles/grid.css';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import useTheme from '@material-ui/core/styles/useTheme';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardHeader } from '@minna-technologies/minna-ui/components/Card/CardHeader';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { InfoIcon } from '@minna-technologies/minna-ui/icons/Info';
import classNames from 'classnames/bind';
import type { ChangeEvent, FC } from 'react';
import React, { useState } from 'react';
import type { AppContext } from '../../../../app-context';
import { SigningFeature } from '../../../../app-context';
import { hasPersonalIdentifier } from '../../../../app-context/constants';

const cx = classNames.bind(grid);

const useStyles = makeStyles({
  paper: {
    padding: '16px',
    marginBottom: '16px',
  },
  headerContainer: {
    display: 'grid',
    gridTemplateColumns: '36px 1fr',
    alignItems: 'left',
    marginBottom: '16px',
  },
});

interface UserInfoFormProps {
  formData: UserInformationForm;
  onFormPropertyUpdate?: (property: keyof UserInformationForm, value: string) => void;
  title: React.ReactNode;
  appContext: AppContext;
  titleIcon?: boolean;
}

const UserInfoFormInner: FC<UserInfoFormProps> = ({ onFormPropertyUpdate, formData, appContext, title, titleIcon }) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const [touched, setTouched] = useState(false);
  const disableUsernameInputs =
    appContext.features?.signing === SigningFeature.CHECKBOX_WITH_PROOF &&
    !appContext.features?.settings?.personalInformation.enabledNameChange;

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    property: keyof UserInformationForm
  ) => {
    setTouched(true);
    if (onFormPropertyUpdate) {
      onFormPropertyUpdate(property, event.target.value.trim());
    }
  };

  const allInfoExists =
    formData.firstName &&
    formData.lastName &&
    formData.email &&
    formData.street &&
    formData.postalCode &&
    formData.city;
  const marketHasPersonalIdentifier = hasPersonalIdentifier(appContext.market);
  const validPersonalIdentifier = marketHasPersonalIdentifier ? Boolean(formData.personalIdentityNumber) : true;
  const readonlyState = !touched && allInfoExists && validPersonalIdentifier;
  const headerWithIcon = (
    <div className={classes.headerContainer}>
      <InfoIcon nativeColor={colors.action.secondary} />
      <CardHeader title={title} />
    </div>
  );

  const readonlyComponent = (
    <Card>
      {titleIcon ? headerWithIcon : <CardHeader title={title} />}
      <div className={cx('row')}>
        <InfoComponent label={<LocalizedMessage id="userInfoNameLabel" />} className={cx('col-xs-6', 'col-sm-3')}>
          {`${formData.firstName} ${formData.lastName}`}
        </InfoComponent>
        {marketHasPersonalIdentifier && (
          <InfoComponent
            label={<LocalizedMessage id="userInfoPersonalNumberLabel" />}
            className={cx('col-xs-6', 'col-sm-3')}
          >
            {formData.personalIdentityNumber}
          </InfoComponent>
        )}
        <InfoComponent label={<LocalizedMessage id="userInfoEmailLabel" />} className={cx('col-xs-12', 'col-sm-6')}>
          {formData.email}
        </InfoComponent>
        <InfoComponent label={<LocalizedMessage id="userInfoAddressLabel" />} className={cx('col-xs-12', 'col-sm-6')}>
          {formData.careOf ? (
            <span>
              C/O {formData.careOf}
              <br />
            </span>
          ) : null}
          {formData.street}
          <br />
          {`${formData.postalCode} ${formData.city}`}
        </InfoComponent>
      </div>
      <Divider fullWidth />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <SecondaryButton label={<LocalizedMessage id="userInfoEditButton" />} onClick={() => setTouched(true)} />
      </div>
    </Card>
  );

  const editingComponent = (
    <Paper className={classes.paper}>
      {titleIcon ? headerWithIcon : <CardHeader title={title} />}
      <EditUserInfo
        handleChange={handleChange}
        formData={formData}
        marketHasPersonalIdentifier={marketHasPersonalIdentifier}
        disableUsernameInputs={disableUsernameInputs}
        market={appContext.market}
      />
    </Paper>
  );

  return readonlyState ? readonlyComponent : editingComponent;
};

export const UserInfoForm = withLocalization('termination/TerminationFormPage')(UserInfoFormInner);

interface InfoComponentProps {
  label: React.ReactNode | string;
  className?: string;
  children: React.ReactNode;
}

export const InfoComponent: FC<InfoComponentProps> = ({ label, className, children }) => {
  const { colors } = useTheme();

  return (
    <div className={className} style={{ marginBottom: '8px' }}>
      <Body>{label}</Body>
      <Body color={colors.textOn.surfaceSubdued}>{children}</Body>
    </div>
  );
};
