import { LocalizedMessage } from '@client/internationalization';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { sendClickedButtonMixpanelEvent } from '@client/modules/cancellation/mixpanel-events';
import { ContactUsButton } from '@client/shared-components/ContactUsButton';
import { makeStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import type { FC } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import type { TrackingPageName } from '@client/tracking/mixpanel-constants';
import { TrackingButtonName } from '@client/tracking/mixpanel-constants';

const useStyles = makeStyles({
  sectionMargin: {
    margin: '0 0 24px',
  },
  content: {
    margin: '0 16px 8px',
  },
});

export interface Props {
  serviceProviderName: string;
  pageName: TrackingPageName;
}

export const NoAnswerFromSupplierContent: FC<Props> = ({ serviceProviderName, pageName }) => {
  const classes = useStyles();
  const features = useSelector(selectorFeatures);
  const [expanded, setExpanded] = useState(false);
  const onClickExpandButton = () => {
    setExpanded(true);
    sendClickedButtonMixpanelEvent(
      pageName,
      serviceProviderName,
      TrackingButtonName.READ_MORE,
      'CancellationOutcomeNoAnswerFromSupplier'
    );
  };

  return (
    <div className={classes.content}>
      {expanded ? (
        <Collapse in={expanded} timeout={500}>
          <div className={classes.sectionMargin}>
            <Body>
              <LocalizedMessage id="noAnswerFromSupplierDescription1" values={{ supplierName: serviceProviderName }} />
            </Body>
            <br />
            <Body>
              <LocalizedMessage id="noAnswerFromSupplierDescription2" />
            </Body>
            {features.helpCenter.enabled && (
              <Body>
                <LocalizedMessage id="noAnswerFromSupplierDescription3" />
              </Body>
            )}
          </div>
          {features.helpCenter.enabled && (
            <ContactUsButton
              pageName={pageName}
              insightCardName="CancellationOutcomeNoAnswerFromSupplier"
              supplierName={serviceProviderName}
            />
          )}
        </Collapse>
      ) : (
        <SecondaryButton label={<LocalizedMessage id="readMore" />} onClick={onClickExpandButton} fullWidth />
      )}
    </div>
  );
};
