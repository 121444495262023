import { AppType } from '@client/modules/app-context/constants';
import { keepalivePing } from '@client/modules/app-integration/swedbank-app-integration';
import type { Reducer, Store } from 'redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import { persistReducer, persistStore } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import { startupReducers } from './startup-reducers';

const composeEnhancers = composeWithDevTools({});

const CLEAR_STATE = 'CLEAR_STATE';

const appReducer = combineReducers(startupReducers);

const rootReducer = (state: any, action: any) => {
  if (state && state.appContext && state.appContext.appType === AppType.Swedbank) {
    setTimeout(keepalivePing);
  }

  if (action.type === CLEAR_STATE) {
    // @ts-ignore
    return appReducer({ appContext: state.appContext, internationalization: state.internationalization }, action);
  }

  // @ts-ignore
  return appReducer(state, action);
};

export function clearState() {
  return { type: CLEAR_STATE };
}

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage: localStorage,
    whitelist: ['consolidateLoans', 'electricityGuide', 'energyGuide', 'mobileGuide'],
  },
  rootReducer
);

const configureStore = (preloadedState?: any) => {
  return createStore(
    persistedReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunkMiddleware, reduxPackMiddleware, promiseMiddleware()))
  );
};

export const store: Store = configureStore();
export const persistor = persistStore(store);

export function addReducers(newReducers: { [key: string]: Reducer<any, any> }) {
  const newRootReducer = combineReducers({
    ...startupReducers,
    ...newReducers,
  });

  // @ts-ignore
  store.replaceReducer(newRootReducer);
}
