import minnaManFace from '@client/assets/images/minna-man-face.svg';
import { Sentry } from '@client/assets/js/sentry';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { openTypeformPopup } from '@client/modules/typeform';
import type { Theme, WithStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

const styles = (theme: Theme) =>
  createStyles({
    message: {
      width: '129px',
      height: '40px',
      background: `${theme.colors.textOn.surfaceSubdued}`,
      borderRadius: '4px',
      padding: '4px 4px 4px 16px',
      marginLeft: '-10px',
      color: '#fff',
    },
    floating: {
      zIndex: 1,
      position: 'absolute',
      bottom: '70px',
    },
    additionalMargin: {
      marginLeft: '16px',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      width: 'fit-content',
      marginBottom: '30px',
      zIndex: 1,
    },
    icon: {
      boxShadow:
        '0px 3px 10px rgba(34, 34, 34, 0.1), 0px 2px 3px rgba(34, 34, 34, 0.1), 0px 1px 1px rgba(34, 34, 34, 0.06)',
      borderRadius: '50%',
    },
  });

interface TypeformButtonProps {
  additionalMargin?: boolean;
  floating?: boolean;
  location: string;
}

const TypeformButtonInner: React.FC<TypeformButtonProps & WithStyles<typeof styles>> = ({
  additionalMargin,
  floating,
  classes,
  location,
}) => {
  const [loading, setLoading] = useState(false);
  const { colors } = useTheme();

  const handleClick = useCallback(() => {
    if (loading) return;
    setLoading(true);

    // TODO this is the URL for Denmark
    // 'https://minnatech.typeform.com/c/NvcSHr?typeform-cui-avatar=https://images.typeform.com/images/RFKVKweAsSH7';

    void openTypeformPopup(location)
      .catch((error) => {
        Sentry.captureExceptionWithMessage(error, 'Failed to open Typeform popup');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [loading, location]);

  const getButtonClasses = () => {
    if (additionalMargin && floating) {
      return classNames(classes.button, classes.additionalMargin, classes.floating);
    } else if (!additionalMargin && floating) {
      return classNames(classes.button, classes.floating);
    } else if (additionalMargin && !floating) {
      return classNames(classes.button, classes.additionalMargin);
    } else {
      return classNames(classes.button);
    }
  };

  return (
    <div role="button" onClick={handleClick} className={getButtonClasses()}>
      <div className={classes.message}>
        <Caption color={colors.base.surface}>{<LocalizedMessage id="improveFeedbackMessage" />}</Caption>
      </div>
      <img className={classes.icon} src={minnaManFace} alt="typeform button" />
    </div>
  );
};

export const TypeformButton = withLocalization('TypeformButton')(withStyles(styles)(TypeformButtonInner));
