import { hasOnlyMobilePhoneNumberCharacters } from '@client/assets/js/validators';
// @ts-ignore js import, remove this when the import is typed
import { LocalizedMessage } from '@client/internationalization';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { removeSpacesAndDashes } from '../../utils';

const DanishMobilePhoneNumberValidators = {
  correctlyFormatted(value: string = ''): boolean | React.ReactNode {
    const trimmedValue = removeSpacesAndDashes(value);
    const valid = isEmpty(value) || hasOnlyMobilePhoneNumberCharacters(trimmedValue);

    return valid ? false : <LocalizedMessage id="danishMobileFormatError" />;
  },
  correctLength(value: string = ''): boolean | React.ReactNode {
    if (isEmpty(value)) {
      return false;
    }
    const trimmedValue = removeSpacesAndDashes(value);
    const validLength = /^\+45/.test(trimmedValue) ? 11 : 8;
    if (trimmedValue.length < validLength) {
      return <LocalizedMessage id="mobileNumberTooShortError" />;
    } else if (trimmedValue.length > validLength) {
      return <LocalizedMessage id="mobileNumberTooLongError" />;
    } else {
      return false;
    }
  },
};

function danishMobilePhoneNumberFormatter(value: string): string {
  const trimmedValue = removeSpacesAndDashes(value);
  if (/^\+45\d{8}/.test(trimmedValue)) {
    return `${trimmedValue.substring(0, 3)} ${trimmedValue.substring(3, 5)} ${trimmedValue.substring(
      5,
      7
    )} ${trimmedValue.substring(7, 9)} ${trimmedValue.substring(9)}`;
  }
  if (/^\d{8}$/.test(trimmedValue)) {
    return `${trimmedValue.substring(0, 2)} ${trimmedValue.substring(2, 4)} ${trimmedValue.substring(
      4,
      6
    )} ${trimmedValue.substring(6)}`;
  }

  return value;
}

export { DanishMobilePhoneNumberValidators, danishMobilePhoneNumberFormatter };
