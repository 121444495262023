import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_OVERVIEW, TrackingPageName } from '@client/tracking/mixpanel-constants';

export const sendViewedSubscriptionHub = () => {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: FEATURE_OVERVIEW,
    Page: TrackingPageName.SUBSCRIPTION_HUB,
  });
};

export const sendClickedSubscriptionSearchWidget = () => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_OVERVIEW,
    Page: TrackingPageName.SUBSCRIPTION_HUB,
    Button: 'Cancel any subscription',
  });
};
export const sendClickedSubscriptionOverviewWidget = () => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_OVERVIEW,
    Page: TrackingPageName.SUBSCRIPTION_HUB,
    Button: 'Subscription overview',
  });
};
export const sendClickedSettingsPageButton = () => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_OVERVIEW,
    Page: TrackingPageName.SUBSCRIPTION_HUB,
    Button: 'Settings',
  });
};
export const sendClickedZendeskButton = () => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_OVERVIEW,
    Page: TrackingPageName.SUBSCRIPTION_HUB,
    Button: 'Zendesk',
  });
};

export const sendClickedPastAction = (
  merchantId: string,
  merchantName: string,
  category: string,
  cancellationId: string
) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_OVERVIEW,
    Page: TrackingPageName.SUBSCRIPTION_HUB,
    Button: 'Past actions cancellation',
    MerchantId: merchantId,
    MerchantName: merchantName,
    Category: category,
    CancellationId: cancellationId,
  });
};
