import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { AmountWithCurrency } from '@client/models/subscription';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { selectorSubscription } from '@client/modules/subscription';
import { AmountPerInterval } from '@client/modules/subscription/components/AmountPerInterval';
import { SubscriptionSettingsButton } from '@client/modules/subscription/components/SubscriptionSettingsButton';
import { ContractDetailsFloatingButtons } from '@client/modules/subscription/ContractDetailsPage/components/ContractDetailsFloatingButtons';
import { PriceHistory } from '@client/modules/subscription/PriceHistoryPage/components/PriceHistory/component';
import type { Contract, PaymentInterval, Subscription } from '@client/modules/subscription/types';
import { SubscriptionSource } from '@client/modules/subscription/types';
import { overviewPage } from '@client/routes';
import useTheme from '@material-ui/core/styles/useTheme';
import { ContractHeaderDeprecated } from '@minna-technologies/minna-ui/components/ContractHeaderDeprecated';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ContractCancellationHistory } from './components/ContractCancellationHistory';
import style from './styles.scss';

export interface SubscriptionDetailsPageInnerProps {
  logoUrl?: string;
  serviceProvider?: string;
  contracts: Contract[];
  cancelledContracts: any[];
  cost?: AmountWithCurrency;
  paymentInterval?: PaymentInterval;
  contractCancellationHistory: Contract[];
}
export const SubscriptionDetailsPageInner: React.FunctionComponent<SubscriptionDetailsPageInnerProps> = ({
  logoUrl,
  contracts,
  cancelledContracts,
  cost,
  paymentInterval,
  serviceProvider,
  contractCancellationHistory,
}: SubscriptionDetailsPageInnerProps) => {
  const { colors } = useTheme();
  const history = useHistory();
  const features = useSelector(selectorFeatures);
  const subscription: Subscription | undefined = useSelector(selectorSubscription);
  function showPriceHistory() {
    if (subscription?.source === SubscriptionSource.Converted) {
      return false;
    }
    if (!subscription?.bankEvents?.length) {
      return false;
    }

    return true;
  }

  return (
    <MultiDeviceLayout
      hasTopNavigation={false}
      fullWidthContent={
        <ContractHeaderDeprecated
          providerLogo={logoUrl}
          title=""
          subHeading={serviceProvider ?? ''}
          providerName={serviceProvider ?? ''}
          content={cost && cost.amount > 0 && <AmountPerInterval cost={cost} paymentInterval={paymentInterval} />}
        />
      }
      footer={
        <ContractDetailsFloatingButtons
          backButton={!!features.identify?.overview}
          customGoBack={() => {
            history.push(overviewPage);
          }}
        />
      }
    >
      <div className={style.contractCardsHolder}>
        <Body style={{ margin: '32px 0 16px 0' }} color={colors.textOn.surfaceSubdued}>
          <LocalizedMessage id="servicesLabel" />
        </Body>
        {contracts}
        {!!cancelledContracts.length && (
          <>
            <div className={style.divider}>
              <Divider />
            </div>
          </>
        )}
        {cancelledContracts}
        {!!contractCancellationHistory.length && (
          <ContractCancellationHistory contracts={contractCancellationHistory} />
        )}
      </div>
      {subscription && <SubscriptionSettingsButton subscription={subscription} />}
      {showPriceHistory() && (
        <>
          <Body style={{ margin: '32px 0 16px 16px' }} color={colors.textOn.surfaceSubdued}>
            <LocalizedMessage id="contractInformation" />
          </Body>
          <PriceHistory showViewTransactionsButton />
        </>
      )}
    </MultiDeviceLayout>
  );
};

export const SubscriptionDetailsPageComponent = withLocalization('contract/SubscriptionDetailsLolipop')(
  SubscriptionDetailsPageInner
);
