//@ts-ignore
import { loadingPageWhileLoading } from '@client/containers/container-helpers';
import clone from 'lodash/clone';
import isArray from 'lodash/isArray';
import last from 'lodash/last';
import reduce from 'lodash/reduce';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { minimize, navigateBack, selectorIsChatOnly } from '../ducks';
import {
  addLocalMessageToHistory,
  firstMessageFormChange,
  messageFormChange,
  scrollToBottom,
  selectorAccountStatus,
  selectorAdditionalUserInfoWhenSetVisitorInfo,
  selectorAgentData,
  selectorAgentIsTyping,
  selectorChatEvents,
  selectorFirstMessageForm,
  selectorIsAgentAvailable,
  selectorIsChatConnected,
  selectorIsChatting,
  selectorMessageForm,
  selectorZChatIsDoneInitializing,
  startChat,
  ZendeskChatVisitorIsTypingEvent,
} from '../ducks/zendesk-chat';
import type { ZendeskChatProps } from './component';
import { ZendeskChat } from './component';

/* eslint-disable  */
window.onresize = () => scrollToBottom();

const mapStateToProps = (state: any) => {
  return {
    isChatting: selectorIsChatting(state),
    isLoading: !selectorIsChatConnected(state) || !selectorZChatIsDoneInitializing(state),
    isAgentAvailable: selectorIsAgentAvailable(state),
    accountStatus: selectorAccountStatus(state),
    partitionedEvents: partitionEventsBySender(selectorChatEvents(state)),
    firstMessageForm: selectorFirstMessageForm(state),
    messageForm: selectorMessageForm(state),
    agentData: selectorAgentData(state),
    agentIsTyping: selectorAgentIsTyping(state),
    isChatOnly: selectorIsChatOnly(state),
    additionalUserInfo: selectorAdditionalUserInfoWhenSetVisitorInfo(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFirstMessageFormChange: (property: any, value: any) => {
      dispatch(firstMessageFormChange(property, value));
    },
    onMessageFormChange: (property: any, value: any) => {
      dispatch(messageFormChange(property, value));
      if (value && value.trim()) {
        dispatch(ZendeskChatVisitorIsTypingEvent());
      }
    },
    onNavigateBack: () => {
      dispatch(navigateBack());
    },
    onMinimizeChat: () => {
      dispatch(minimize());
    },
    onStartChat: () => {
      dispatch(startChat());
    },
    addMessageLocally(message: any) {
      dispatch(addLocalMessageToHistory(message));
    },
  };
};

export const ZendeskChatContainer = compose<ZendeskChatProps, any>(
  connect(mapStateToProps, mapDispatchToProps),
  loadingPageWhileLoading((p: any) => p.isLoading)
)(ZendeskChat);

/**
 * Group adjacent chat messages from the same sender into arrays. Events that aren't messages aren't grouped.
 * e.g. [ "Agent joined", "Niklas:a", "Niklas:b", "Agent: "c"]
 * ->   [ "Agent joined", ["Niklas:a", "Niklas:b"], ["Agent:c"] ]
 */
/* eslint-disable @typescript-eslint/no-param-reassign */
export const partitionEventsBySender = (events: any) => {
  function nicksAreEqual(nick1: any, nick2: any) {
    nick1 = nick1.split(':')[0];
    nick2 = nick2.split(':')[0];

    return nick1 === nick2;
  }

  return reduce(
    events,
    (partitionedEvents: any, event: any) => {
      if (event.type !== 'chat.msg') {
        // don't create a group
        return partitionedEvents.concat(event);
      }
      const maybeMessageGroup: any = last(partitionedEvents);
      const messageBelongsToGroup = isArray(maybeMessageGroup) && nicksAreEqual(maybeMessageGroup[0].nick, event.nick);
      if (messageBelongsToGroup) {
        // add to latest group
        const newPartitionedEvents = clone(partitionedEvents);
        newPartitionedEvents[partitionedEvents.length - 1] = maybeMessageGroup.concat(event);

        return newPartitionedEvents;
      } else {
        // create a new group
        return partitionedEvents.concat([[event]]);
      }
    },
    []
  );
};
