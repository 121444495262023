import { LocalizedMessage } from '@client/internationalization';
import { makeStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles({
  content: {
    margin: '0 16px 8px',
  },
});

export const PayingThroughThirdPartyContent: FC = () => {
  const classes = useStyles();
  const { colors } = useTheme();

  return (
    <div className={classes.content}>
      <Body>
        <LocalizedMessage id="payingThroughThirdPartyDescription" />
      </Body>
      <br />
      <Title color={colors.textOn.surfaceSubdued} style={{ marginBottom: '4px' }}>
        <LocalizedMessage id="whatCanYouDo" />
      </Title>
      <Body>
        <LocalizedMessage id="payingThroughThirdPartyCancellationInstructions" />
      </Body>
    </div>
  );
};
