// See https://minnatechnologies.atlassian.net/wiki/spaces/DEV/pages/158007323/Best+practices+for+event+naming+in+Mixpanel
// for best practices of event naming in Mixpanel

import { SubscriptionActionType } from '@client/models/subscription';

export const STAGE_STARTED = 'Started';
export const STAGE_FINISHED = 'Finished';

export const OUTCOME_SUCCESS = 'Success';
export const OUTCOME_FAILED = 'Failed';

//Also known as vertical, i.e. what type of service did we try to improve/switch/cancel
export const CATEGORY_MOBILE = 'Mobile';
export const CATEGORY_ENERGY = 'Energy';
export const CATEGORY_ELECTRICITY = 'Electricity';
export const CATEGORY_BROADBAND = 'Broadband';
export const CATEGORY_CHARITY = 'Charity';
export const CATEGORY_FITNESS = 'Fitness';

export const FEATURE_OVERVIEW = 'Overview';
export const FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE = 'Unresolved subscription guide';
export const FEATURE_INDEPENDENT_ORDER = 'Independent';
export const FEATURE_DISCOVER = 'Discover';
export const FEATURE_SETTINGS = 'Settings';
export const FEATURE_ONBOARDING = 'Onboarding';
export const FEATURE_ONBOARDING_ACTION_DRAWER = 'Onboarding Action Drawer';
export const SUBSCRIPTION_PROTECTION = 'Subscription protection';
export const FEATURE_SIGN_OUT = 'Sign out';

export enum TrackingFeature {
  Cancellation = 'Cancellation',
  CancelIG = 'Cancel guide',
  ChangePayment = 'Change Payment',
  ChangePlan = 'Change Plan',
  Pause = 'Pause',
  Resume = 'Resume',
  SaveDesk = 'Savedesk',
  UnknownFeature = 'Unknown Feature',
}

export const subscriptionActionTypeToTrackingFeature = (actionType: SubscriptionActionType) => {
  switch (actionType) {
    case SubscriptionActionType.Cancel:
      return TrackingFeature.Cancellation;
    case SubscriptionActionType.ChangePayment:
      return TrackingFeature.ChangePayment;
    default:
      return TrackingFeature.UnknownFeature;
  }
};

export enum TrackingButtonName {
  BACK = 'Back',
  BUTTON = 'Button',
  CANCEL_NOW = 'Cancel now',
  CANCEL_TERMS = 'Cancel terms',
  CHANGE_ANOTHER = 'Change another',
  CHANGE_PLAN = 'Change Plan',
  CLOSE = 'Close',
  CONTACT_US = 'Contact us',
  CONTINUE = 'Continue',
  LINK = 'Link',
  MERCHANT_DEEPLINK = 'Merchant deeplink',
  START_SAVING = 'Start saving',
  NO = 'No',
  READ_MORE = 'Read more',
  SEND_FEEDBACK = 'Send feedback',
  SUBMIT_CANCELLATION = 'Submit cancellation',
  TRY_AGAIN = 'Try again',
  YES = 'Yes',
  PREVIEW_OF_LOA = 'Preview of LOA',
}

export enum EventNames {
  CLICKED_BUTTON = 'Clicked button',
  CLICKED_CARD = 'Clicked card',
  ENTERED_GUIDE = 'Entered guide',
  ORDER_SENT = 'Order sent',
  RATED_EXPERIENCE = 'Rated experience',
  SELECTED_PRODUCT = 'Selected product',
  SUBMITTED_FORM = 'Submitted form',
  VIEWED_CARD = 'Viewed card',
  VIEWED_DIALOG = 'Viewed dialog',
  VIEWED_FORM = 'Viewed form',
  VIEWED_GUIDE = 'Viewed guide', // VIEWED_PAGE should be used instead (see https://minnatechnologies.atlassian.net/browse/DAT-183?focusedCommentId=44832)
  VIEWED_OVERVIEW = 'Viewed overview',
  VIEWED_PAGE = 'Viewed page',
  SEARCHED = 'Searched',
}

export enum TrackingPageName {
  ACTION_DRAWER = 'Action drawer',
  CANCEL_INTELLIGENT_GUIDE = 'Cancel guide',
  CANCEL_INTELLIGENT_GUIDE_DIALOG_REASON = 'Cancel reason',
  CANCEL_QUICK_ACTION = 'Cancel quick action',
  CANCEL_SERVICE_FORM = 'CancelServiceForm',
  CANCELLATION_DETAILS = 'Cancellation details',
  CATEGORY = 'Category page',
  CONSENT = 'Consent page',
  CONTRACT_DETAILS = 'Contract details',
  DETAILS = 'Details',
  DISCOVER = 'Discover',
  INSIGHTS = 'Insights',
  INTELLIGENT_GUIDE = 'Intelligent guide',
  INTELLIGENT_GUIDE_DIALOG_REASON = 'Reason',
  MANAGE_BINDING_PERIOD = 'Manage binding period',
  MENU = 'Menu',
  MONTHLY_CHECKLIST = 'Monthly checklist',
  NEW_CONSENT = 'New consent page',
  ONBOARDING = 'Onboarding',
  ONBOARDING_CANCEL = 'Cancel',
  ONBOARDING_IDENTIFY = 'Identify',
  ONBOARDING_INTRODUCTION = 'Introduction',
  ONBOARDING_LOADING = 'Loading Screen',
  ONBOARDING_SUBCRIPTIONS_RESULT = 'Subscriptions result',
  ORDER_DETAILS = 'Order details',
  ORDER_SENT = 'Order sent',
  ORDERED_SUBSCRIPTION = 'Ordered subscription',
  OVERVIEW = 'Overview',
  PRODUCT_TERMS = 'Product terms',
  RATE_CHECKLIST = 'Rate checklist',
  RECOMMENDATION = 'Recommendation page',
  REMOVE_SUBSCRIPTION = 'Remove subscription',
  SELECT_SERVICE = 'Select service',
  SERVICE_PROVIDER_PAGE = 'Service provider page',
  SUBSCRIPTION_DETAILS = 'Subscription details',
  SUBSCRIPTION_HUB = 'Subscription hub',
  SUBSCRIPTION_SEARCH = 'Subscription search',
  SUCCESS_SCREEN = 'Success screen',
  RESULT_PAGE = 'Result page',
  PREVIEW_OF_LOA = 'Preview of LOA',
  CANCELLATION_SENT = 'Cancellation sent',
  UPDATE_BANK_APP_MODAL = 'Update bank app modal',
  CHANGE_PLAN_TEST = 'Change plan test',
}

export enum TrackingButtonLocation {
  BOTTOM = 'Bottom',
  BOTTOM_CENTER = 'Bottom center',
  BOTTOM_LEFT = 'Bottom left',
  BOTTOM_RIGHT = 'Bottom right',
  CENTER = 'Center',
  LEFT = 'Left',
  RIGHT = 'Right',
  TOP = 'Top',
  TOP_LEFT = 'Top left',
  TOP_RIGHT = 'Top right',
}

export enum InsightCardNames {
  ADDON_RECOMMENDATION = 'AddonRecommendation',
  BINDING_TIME_ENDING = 'BindingTimeEnding',
  CANCELLATION_OUTCOME = 'CancellationOutcome',
  CANCELLATION_STATUS = 'CancellationStatus',
  DISCOVER_OUTCOME = 'DiscoverOutcome',
  INDEPENDENT_ORDER_STATUS = 'IndependentOrderStatus',
  NEW_SUBSCRIPTION = 'NewSubscription',
  ONBOARDING_INSIGHT = 'Onboarding',
  OTHER_INSIGHT = 'OtherInsight',
  PRICE_DECREASE = 'PriceDecrease',
  PRICE_INCREASE = 'PriceIncrease',
  RecommendationFromMonitoringPreferenceCard = 'Monitor recommendation',
  SAVEDESK_ONGOING = 'SaveDeskOngoing',
  SAVEDESK_OUTCOME = 'SaveDeskOutcome',
  UNRESOLVED_SUBSCRIPTION = 'Unresolved subscription',
  NEW_PROVIDER_OFFERS = 'New provider offers',
}

export enum TrackingSubscriptionMethod {
  MerchantApi = 'API',
  MerchantOutreach = 'MO',
  IntelligentGuide = 'IG',
}

export enum TrackingFeedbackType {
  Guide = 'Guide',
  Completion = 'Completion',
}
