import { SigningFeature } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import useTheme from '@material-ui/core/styles/useTheme';
import type { PositiveFloatingButtonProps } from '@minna-technologies/minna-ui/components/Buttons/PositiveFloatingButton';
import { PositiveFloatingButton } from '@minna-technologies/minna-ui/components/Buttons/PositiveFloatingButton';
import { BankIdIcon } from '@minna-technologies/minna-ui/icons/BankId';
import React from 'react';
import { useSelector } from 'react-redux';

export const SigningButton: React.FunctionComponent<PositiveFloatingButtonProps> = ({ ...props }) => {
  const { colors } = useTheme();
  const features = useSelector(selectorFeatures);

  return (
    <PositiveFloatingButton
      {...props}
      data-test="sign-button"
      icon={features.signing === SigningFeature.BANKID ? <BankIdIcon nativeColor={colors.base.surface} /> : undefined}
    />
  );
};
