import type { AppFeatureToggles } from '@client/modules/app-context';
import { SubscriptionSearchButton } from '@client/modules/subscription-hub/SubscriptionSearchButton/SubscriptionSearchButton';
import React from 'react';
import { PastActions } from '../PastActions';
import { SubscriptionOverviewButton } from '@client/modules/subscription-hub/SubscriptionOverviewButton';
import { Sentry } from '@client/assets/js/sentry';
import { makeStyles } from '@material-ui/core/styles';

export interface WidgetProviderProps {
  features: AppFeatureToggles;
}

const useStyles = makeStyles(() => ({
  widgetButton: {
    padding: '8px 0 8px 0',
  },
}));

// Or controller, or layout, or whatever..
export const WidgetProvider = (props: WidgetProviderProps) => {
  const classes = useStyles();
  const widgetsConfig = props.features.subscriptionHub;
  const identifyEnabled = props.features.identify?.identify || false;
  const identifyOptInEnabled = props.features.identify?.identifyOptIn || false;

  if (widgetsConfig.overview && !(identifyEnabled || identifyOptInEnabled)) {
    Sentry.captureMessage(
      'SubscriptionHub: Overview widget is enabled but identify / identifyOptIn is not. Widget will not do anything.'
    );
  }

  const widgetsInOrder: { component: JSX.Element; sortOrder: number }[] = [
    widgetsConfig.search && { sortOrder: widgetsConfig.search.sortOrder, component: <SubscriptionSearchButton /> },
    widgetsConfig.overview && {
      sortOrder: widgetsConfig.overview.sortOrder,
      component: (
        <SubscriptionOverviewButton identifyEnabled={identifyEnabled} identifyOptInEnabled={identifyOptInEnabled} />
      ),
    },
    widgetsConfig.pastActions && { sortOrder: widgetsConfig.pastActions.sortOrder, component: <PastActions /> },
  ]
    .flatMap((widgets) => (widgets ? [widgets] : []))
    .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));

  return (
    <div>
      {widgetsInOrder.map((s) => (
        <div className={classes.widgetButton} key={s.sortOrder}>
          {s.component}
        </div>
      ))}
    </div>
  );
};
