import type { PhoneNumber } from '@client/models/user-models';

export enum IntelligentGuideStepType {
  IntelligentGuideTextStep = 'IntelligentGuideTextStep',
  IntelligentGuideLinkButtonStep = 'IntelligentGuideLinkButtonStep',
  IntelligentGuideEmailButtonStep = 'IntelligentGuideEmailButtonStep',
  IntelligentGuidePhoneButtonStep = 'IntelligentGuidePhoneButtonStep',
}

export type IntelligentGuideStep =
  | IntelligentGuideTextStep
  | IntelligentGuideLinkButtonStep
  | IntelligentGuideEmailButtonStep
  | IntelligentGuidePhoneButtonStep;

export interface IntelligentGuideTextStep {
  type: IntelligentGuideStepType.IntelligentGuideTextStep;
  id: IntelligentGuideStepId;
  description: string;
}

export interface IntelligentGuideLinkButtonStep {
  type: IntelligentGuideStepType.IntelligentGuideLinkButtonStep;
  id: IntelligentGuideStepId;
  description: string;
  buttonLabel: string;
  buttonLink: string;
}

export interface IntelligentGuideEmailButtonStep {
  type: IntelligentGuideStepType.IntelligentGuideEmailButtonStep;
  id: IntelligentGuideStepId;
  description: string;
  buttonLabel: string;
  buttonLink: string;
}

// @deprecated use IntelligentGuidePhoneButtonStep instead
export interface IntelligentGuidePhoneButtonStep {
  type: IntelligentGuideStepType.IntelligentGuidePhoneButtonStep;
  id: IntelligentGuideStepId;
  description: string;
  buttonLabel: string;
  buttonLink: string;
}

export interface IntelligentGuide {
  settings: IntelligentGuideSettings;
  steps: IntelligentGuideStep[];
  alternateSteps: IntelligentGuideStep[];
  disclaimer?: IntelligentGuideDisclaimer;
}

export interface IntelligentGuideSettings {
  guideId: IntelligentGuideId;
  linkToAction?: string;
  phoneNumber?: PhoneNumber;
  email?: IntelligentGuideEmailSettings;
}

export enum IntelligentGuideDisclaimerType {
  Text = 'Text',
}
export interface IntelligentGuideDisclaimer {
  type: IntelligentGuideDisclaimerType.Text;
  id: IntelligentGuideDisclaimerId;
  description: string;
}

export interface IntelligentGuideEmailSettings {
  emailAddress?: EmailAddress;
  subject?: string;
  body?: string;
}

export type EmailAddress = string;

export type IntelligentGuideDisclaimerId = string;

export type IntelligentGuideId = string;

export type IntelligentGuideStepId = string;
