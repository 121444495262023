import { ELECTRICITY_GRID } from '@client/constants/categories';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardHeader } from '@minna-technologies/minna-ui/components/Card/CardHeader';
import { RHDatePicker } from '@minna-technologies/minna-ui/components/Form/RHDatePicker';
import { RHFormGroup } from '@minna-technologies/minna-ui/components/Form/RHFormGroup';
import { RHRadio } from '@minna-technologies/minna-ui/components/Form/RHRadio';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import moment from 'moment-business-days';
import PropTypes from 'prop-types';
import * as React from 'react';

const CancellationDataSectionInner: React.FunctionComponent<CancellationDataSectionProps> = (
  { desiredTerminationAt, customDeliveryDateChosen, contractCategoryName, supplierName },
  { localizeMessage }
) => {
  const { colors } = useTheme();
  const contractCategoryIsElectricity = contractCategoryName === ELECTRICITY_GRID;

  const minDesiredTerminationDate = moment().businessAdd(5).toDate();

  const desiredTerminationAtDefined = desiredTerminationAt !== undefined;

  const desiredTerminationAtDate = desiredTerminationAtDefined
    ? moment(desiredTerminationAt).format('YYYY-MM-DD')
    : undefined;

  const desiredTerminationAtDateField =
    customDeliveryDateChosen === 'CustomDate' ? (
      <RHDatePicker
        name="desiredTerminationAt"
        label={localizeMessage('desiredTerminationDateLabel')}
        defaultValue={desiredTerminationAtDate}
        fullWidth
        // @ts-ignore
        min={minDesiredTerminationDate}
      />
    ) : null;

  const movingDateField = (
    <RHDatePicker
      name="desiredTerminationAt"
      label={localizeMessage('movingDateLabel')}
      defaultValue={desiredTerminationAtDate}
      fullWidth
      validation={{ required: localizeMessage('moveDateRequiredError') }}
    />
  );
  const euroSportPlayerExtraInformation =
    supplierName === 'Eurosport Player' ? <LocalizedMessage id="euroSportPlayerExtraInformation" /> : '';
  const dateField = contractCategoryIsElectricity ? movingDateField : desiredTerminationAtDateField;

  return (
    <Card>
      <CardHeader
        title={
          contractCategoryIsElectricity ? (
            <Title>
              <LocalizedMessage id="moveDateTitle" />
            </Title>
          ) : (
            <Title>
              <LocalizedMessage id="desiredTerminationTitle" />
            </Title>
          )
        }
      />

      {contractCategoryIsElectricity ? null : (
        <div>
          {euroSportPlayerExtraInformation ? (
            <Body color={colors.textOn.surfaceSubdued}>{euroSportPlayerExtraInformation}</Body>
          ) : null}
          <Body>
            <LocalizedMessage id="terminationDateText" />
          </Body>
          <RHFormGroup name="customDeliveryDateChosen" defaultValue="AsSoonAsPossible">
            <RHRadio label={<LocalizedMessage id="optionAsSoonAsPossbile" />} value="AsSoonAsPossible" />
            <RHRadio label={<LocalizedMessage id="optionSelectDesiredDate" />} value="CustomDate" />
          </RHFormGroup>
        </div>
      )}
      <div>{dateField}</div>
    </Card>
  );
};

interface CancellationDataSectionProps {
  desiredTerminationAt: string;
  customDeliveryDateChosen?: string;
  contractCategoryName: string;
  supplierName: string;
}

CancellationDataSectionInner.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const CancellationDataSection = withLocalization('termination/TerminationFormPage')(
  CancellationDataSectionInner
);
