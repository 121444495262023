import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { ServiceProvider, TermsAndConditions } from '@client/modules/switch/common/models';
import { ExternalLink } from '@client/shared-components/ExternalLink';
import { useZendesk } from '@client/utils/hooks/use-zendesk';
import type { ZendeskLabel } from '@client/zendesk';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import { DocumentIcon } from '@minna-technologies/minna-ui/icons/Document';
import type { ReactElement } from 'react';
import React from 'react';

export interface FrontendDiscoverProvider {
  name: string;
  logoUrl: string;
  id: string;
  description: string;
}

const useStyles = makeStyles({
  card: {
    margin: '0 0 16px 0',
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, 120px) auto',
    gridColumnGap: '16px',
    alignItems: 'center',
  },
  logo: {
    maxHeight: 32,
    maxWidth: '128px',
  },
  files: {
    margin: '8px',
  },
  termsRow: {
    margin: '24px 8px',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridColumnGap: '16px',
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: 16,
  },
});

const TermsAndConditionsPageInner: React.FunctionComponent<TermsAndConditionsPageProps> = ({
  supplier,
  productName,
  termsAndConditions,
  onBackClick,
  zendeskLabel,
}) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const onHelpClick = useZendesk(zendeskLabel);
  const termsAndConditionFiles = termsAndConditions?.map((termAndCondition: TermsAndConditions) => {
    return (
      <ExternalLink
        href={`/api/terms-and-conditions/${supplier.id}/${termAndCondition.storageName}`}
        key={termAndCondition.id}
      >
        <div className={classes.termsRow}>
          <DocumentIcon nativeColor={colors.textOn.surfaceSubdued} />
          <Body style={{ marginLeft: '16px', wordBreak: 'break-all' }}>{termAndCondition.name}</Body>
          <ChevronRightIcon nativeColor={colors.textOn.surfaceSubdued} />
        </div>
        <Divider fullWidth />
      </ExternalLink>
    );
  });

  return (
    <MultiDeviceLayout
      title={<LocalizedMessage id="title" />}
      hasTopNavigation={false}
      footer={<FooterNavigation onBackClick={onBackClick} onHelpClick={onHelpClick} />}
    >
      <Card variant="border" className={classes.card}>
        <img src={supplier.logoUrl} alt={supplier.name} className={classes.logo} />
        <div>
          <Subheading2>{supplier.name}</Subheading2>
          <Caption color={colors.base.border}>{productName}</Caption>
        </div>
      </Card>
      <div className={classes.files}>
        <Subheading2 className={classes.marginBottom}>
          <LocalizedMessage id="subtitle" />
        </Subheading2>
        {termsAndConditionFiles}
      </div>
    </MultiDeviceLayout>
  );
};

export const TermsAndConditionsPage = withLocalization('ordering-subscription/common/TermsAndConditionsPage')(
  TermsAndConditionsPageInner
);

export interface TermsAndConditionsPageProps {
  supplier: ServiceProvider | FrontendDiscoverProvider;
  productName: string | ReactElement;
  zendeskLabel: ZendeskLabel;
  termsAndConditions?: TermsAndConditions[];
  onBackClick(): void;
}
