import type { Contract, SubscriptionSupplier } from '@client/modules/subscription/types';
import { CategoryIcon } from '@client/shared-components/CategoryIcon/CategoryIcon';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import React from 'react';

const useStyles = makeStyles({
  serviceHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0',
  },

  serviceHeaderCategory: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  logotype: {
    maxHeight: '48px',
    maxWidth: '96px',
  },

  categoryIconLabel: {
    marginLeft: '8px',
  },
});

interface Props {
  contract: Contract;
  supplier: SubscriptionSupplier;
}

export const ContractActionHeader: React.FunctionComponent<Props> = ({ contract, supplier }: Props) => {
  const classes = useStyles();
  const { colors } = useTheme();

  return (
    <>
      <div className={classes.serviceHeader}>
        <div className={classes.serviceHeaderCategory}>
          <CategoryIcon categoryName={contract.service.category.name} nativeColor={colors.textOn.surfaceSubdued} />
          <div className={classes.categoryIconLabel}>
            <Subheading2 color={colors.textOn.surfaceSubdued}>{contract.service.category.text}</Subheading2>
          </div>
        </div>
        <div>
          <img className={classes.logotype} alt={supplier.name} src={supplier.logoUrl} />
        </div>
      </div>
      <Divider fullWidth />
    </>
  );
};
