import type { CategoryName } from '@client/constants/categories';
import type { CancellationId } from '@client/models/cancellation';
import type { SupplierService, SupplierWithServices } from '@client/models/supplier';
import type { Market } from '@client/modules/app-context/constants';
import { trackRatedCancelServiceExperience } from '@client/modules/cancellation/mixpanel-events';
import { ratedCancellationRequestEvent } from '@client/modules/cancellation/tracking-events';
import { FeedbackType } from '@client/modules/rating/model';
import { TrackingPageName } from '@client/tracking/mixpanel-constants';

export function onClickRating(
  merchant: SupplierWithServices,
  service: SupplierService,
  market: Market,
  categoryName: CategoryName,
  cancellationId?: CancellationId
) {
  return (newRating: number) => {
    if (newRating > 0) {
      // 0 == not rated
      trackRatedCancelServiceExperience(TrackingPageName.SUCCESS_SCREEN, newRating, merchant, service);
      ratedCancellationRequestEvent(newRating, FeedbackType.Flow, categoryName, market, cancellationId);
    }
  };
}
