import { useLocalization } from '@client/internationalization';
import type { LocalDate } from '@client/models/LocalDate';
import { parseDate } from '@client/utils/date';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import type { FC } from 'react';
import React from 'react';

interface Props {
  bindingPeriodEndDate: LocalDate;
  contractHasActions: boolean;
}

const useStyles = makeStyles({
  card: {
    margin: '16px 0px 24px',
  },
});

export const BindingTimeNotice: FC<Props> = ({ bindingPeriodEndDate, contractHasActions }) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const { localizeDate, localizeMessage } = useLocalization('overview/BindingTimeNotice');

  return (
    <Card className={classes.card}>
      <Body color={colors.textOn.surface}>{localizeMessage('youHaveBindingTime')}</Body>
      <Title style={{ margin: '16px 0 12px', color: colors.action.primary }}>
        {localizeDate(parseDate(bindingPeriodEndDate))}
      </Title>
      <Body color={colors.base.border}>
        {localizeMessage('weWillRemindYou', { contractHasActions: String(contractHasActions) })}
      </Body>
    </Card>
  );
};
