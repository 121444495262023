import type { ServiceId, SupplierId } from '@client/models/supplier';
import type { ContractId } from '@client/modules/subscription/types';
import axios from 'axios';

export async function viewedCancellationGuide(contractId: ContractId) {
  return axios.post(`/api/v1/user/contracts/${contractId}/viewed-cancellation-guide`);
}

export async function removeViewedCancellationGuide(contractId: ContractId) {
  return axios.delete(`/api/v1/user/contracts/${contractId}/viewed-cancellation-guide`);
}

export async function cancelUsingIntelligentGuide(contractId: ContractId) {
  return axios.post(`/api/v1/user/contracts/${contractId}/cancel-using-intelligent-guide`);
}

export async function cancelServiceUsingIntelligentGuide(merchantId: SupplierId, serviceId: ServiceId) {
  return axios.post(`/api/v1/user/merchants/${merchantId}/services/${serviceId}/cancel-using-intelligent-guide`);
}

export async function saveCancellationAnswers(
  merchantId: SupplierId,
  serviceId: ServiceId,
  answers: any[],
  desiredCancellationDate: string
) {
  return axios.post(`/api/v1/user/merchants/${merchantId}/services/${serviceId}/save-answers`, {
    answers,
    desiredCancellationDate,
  });
}
