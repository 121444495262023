import type { AppType, Market } from '@client/modules/app-context/constants';
import { appPlatform, getMixpanel } from '@client/tracking/mixpanel';
import { TrackingButtonLocation, EventNames, FEATURE_OVERVIEW } from '@client/tracking/mixpanel-constants';

export const trackCancelQuickActionButtonClicked = (market: Market, appType: AppType) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_OVERVIEW,
    Type: 'Main',
    Location: TrackingButtonLocation.TOP,
    Button: 'Cancel quick action',
    Market: market,
    Platform: appType,
    'App platform': appPlatform(),
  });
};

export const trackChangePaymentQuickActionButtonClicked = (market: Market, appType: AppType) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_OVERVIEW,
    Type: 'Main',
    Location: TrackingButtonLocation.TOP,
    Button: 'Change payment quick action',
    Market: market,
    Platform: appType,
    'App platform': appPlatform(),
  });
};

export const trackCancelAnySubscriptionButtonClicked = (market: Market, appType: AppType) => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_OVERVIEW,
    Type: 'Main',
    Location: TrackingButtonLocation.TOP,
    Button: 'Cancel any subscription',
    Market: market,
    Platform: appType,
    'App platform': appPlatform(),
  });
};
