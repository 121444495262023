import type { AppContext } from '@client/modules/app-context';
import { CancellationFormContainer } from '@client/modules/cancellation/CancellationFormPage/CancelContractForm';
import { CancellationInstructionsPageContainer } from '@client/modules/cancellation/CancellationInstructionsPage';
import { CancellationSentContainer } from '@client/modules/cancellation/CancellationSentPage/CancelContractSentPage';
import { RouteNotFoundContainer } from '@client/modules/error-handling/RouteNotFound';
import * as urls from '@client/routes';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import type { ContractId, SubscriptionId } from '../subscription/types';
import { IntelligentGuidePage } from './intelligentGuide/IntelligentGuideContract';
import { IntelligentGuideDonePageContractWrapper } from './intelligentGuide/IntelligentGuideDonePageContractWrapper';
import { SigningPage } from './signing';

export const CancelContractRoute: React.FunctionComponent<CancellationRouteProps> = ({
  subscriptionId,
  contractId,
  appContext,
}) => {
  return (
    <Switch>
      <Route
        exact
        path={urls.terminationFormPage(subscriptionId, contractId)}
        render={() => <CancellationFormContainer subscriptionId={subscriptionId} contractId={contractId} />}
      />
      <Route
        path={urls.terminationSignPage(subscriptionId, contractId)}
        render={() => <SigningPage subscriptionId={subscriptionId} contractId={contractId} appContext={appContext} />}
      />
      <Route
        path={urls.terminationSentPage(subscriptionId, contractId)}
        render={() => <CancellationSentContainer subscriptionId={subscriptionId} contractId={contractId} />}
      />
      <Route
        path={urls.terminationInstructionsPage(subscriptionId, contractId)}
        render={() => <CancellationInstructionsPageContainer subscriptionId={subscriptionId} contractId={contractId} />}
      />
      <Route
        path={urls.cancellationGuidePage(subscriptionId, contractId)}
        render={() => <IntelligentGuidePage subscriptionId={subscriptionId} contractId={contractId} />}
      />
      <Route
        path={urls.cancellationGuideDonePage(subscriptionId, contractId)}
        render={() => <IntelligentGuideDonePageContractWrapper contractId={contractId} />}
      />
      <Route render={() => RouteNotFoundContainer} />
    </Switch>
  );
};

export interface CancellationRouteProps {
  subscriptionId: SubscriptionId;
  contractId: ContractId;
  appContext: AppContext;
}
