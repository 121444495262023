import { Sentry } from '@client/assets/js/sentry';
import type {
  MultipleChoiceQuestion,
  Question,
  QuestionId,
  RecipientQuestion,
  SimpleQuestion,
} from '@client/models/cancellation-questions';
import { CancellationQuestionType, SimpleQuestionType } from '@client/models/cancellation-questions';
import type { Locale, Market } from '@client/modules/app-context/constants';
import { AddressQuestion } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/CancellationQuestion/AddressQuestion';
import { DateQuestion } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/CancellationQuestion/DateQuestion';
import { EmailQuestion } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/CancellationQuestion/EmailQuestion';
import { MultipleChoiceQuestion as MultipleChoiceQuestionComponent } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/CancellationQuestion/MultipleChoiceQuestion';
import { RecipientQuestionComponent } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/CancellationQuestion/RecipientQuestion';
import { TelephoneQuestion } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/CancellationQuestion/TelephoneQuestion';
import { TextQuestion } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/CancellationQuestion/TextQuestion';
import type { AddressInformation } from '@client/modules/cancellation/CancellationFormPage/models';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface CancellationQuestionProps {
  locale: Locale;
  type: CancellationQuestionType;
  onSetAnswer(questionId: string, answerValue: any): void;
  formSubmitted: boolean;
  answers: any; // TODO: improve
  question: Question;
  market: Market;
  addressOnUser: AddressInformation;
  isNested?: boolean;
  watchRecipientQuestion(questionId: QuestionId): any;
  watchNestedQuestion(questionId: QuestionId): any;
}

const useStyles = makeStyles(() => ({
  cancellationQuestion: {},
  nestedCancellationQuestion: { padding: '0 0 0 48px' },
}));

export const CancellationQuestionComponent: React.FunctionComponent<CancellationQuestionProps> = ({
  locale,
  type,
  onSetAnswer,
  formSubmitted,
  answers,
  isNested,
  question,
  market,
  addressOnUser,
  watchRecipientQuestion,
  watchNestedQuestion,
}) => {
  const renderSimpleQuestion = () => {
    const simpleQuestion: SimpleQuestion = question as SimpleQuestion;
    switch (simpleQuestion.questionType) {
      case SimpleQuestionType.EmailQuestion:
        return <EmailQuestion question={question} locale={locale} />;
      case SimpleQuestionType.AddressQuestion:
        return <AddressQuestion locale={locale} question={question} market={market} addressOnUser={addressOnUser} />;
      case SimpleQuestionType.TelephoneQuestion:
        return <TelephoneQuestion locale={locale} question={question} />;
      case SimpleQuestionType.TextQuestion:
        return <TextQuestion locale={locale} question={question} />;
      case SimpleQuestionType.DateQuestion:
        return <DateQuestion locale={locale} question={question} />;
      default:
        Sentry.captureMessage(`Unknown simple cancellation question type ${simpleQuestion.questionType}`);

        return undefined;
    }
  };

  const renderQuestion = () => {
    switch (type) {
      case CancellationQuestionType.SimpleQuestion:
        return renderSimpleQuestion();
      case CancellationQuestionType.MultipleChoiceQuestion: {
        const multipleChoiceQuestion = question as MultipleChoiceQuestion;

        return (
          <MultipleChoiceQuestionComponent
            locale={locale}
            {...question}
            alternatives={multipleChoiceQuestion.alternatives}
            answers={answers}
            onSetAnswer={onSetAnswer}
            formSubmitted={formSubmitted}
            market={market}
            addressOnUser={addressOnUser}
            watchRecipientQuestion={watchRecipientQuestion}
            watchNestedQuestion={watchNestedQuestion}
          />
        );
      }
      case CancellationQuestionType.RecipientQuestion:
        return (
          <RecipientQuestionComponent
            locale={locale}
            question={question as RecipientQuestion}
            watchRecipientQuestion={watchRecipientQuestion}
          />
        );
      default:
        Sentry.captureMessage(`Unknown cancellation question type ${question.type}`);

        return undefined;
    }
  };
  const classes = useStyles();

  return (
    <div
      data-test="termination-question"
      className={isNested ? classes.nestedCancellationQuestion : classes.cancellationQuestion}
    >
      {renderQuestion()}
    </div>
  );
};
