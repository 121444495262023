import { AppIntegration } from '@client/modules/app-integration';
import type {
  IntelligentGuideDisclaimer,
  IntelligentGuideSettings,
  IntelligentGuideStep,
} from '@client/modules/intelligent-guides/models';
import { IntelligentGuideStepType } from '@client/modules/intelligent-guides/models';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import withStyles from '@material-ui/core/styles/withStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import debounce from 'lodash/debounce';
import type { ReactNode } from 'react';
import React from 'react';
import { SubscriptionActionType } from '@client/models/subscription';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';

interface IntelligentGuidePreviewProps {
  steps: IntelligentGuideStep[];
  alternateSteps: IntelligentGuideStep[];
  disclaimer?: IntelligentGuideDisclaimer;
  settings: IntelligentGuideSettings;
  phoneLinkEnabled: boolean;
  actionType: SubscriptionActionType;
  hasClickedOnButton: boolean;
  onClickLink(): void;
  onClickButton(): void;
  classes: any;
}

const styles: any = (theme: any) => ({
  mainDiv: {
    backgroundColor: theme.colors.base.surface,
    padding: '1rem 0',
    borderRadius: '0.5rem',
  },
  stepLabel: {
    marginLeft: 8,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '2rem',
  },
  stepIcon: {
    color: `${theme.colors.element.badgeSurface} !important`,
  },
  stepIconText: {
    fontWeight: 700,
    fill: `${theme.colors.textOn.badge} !important`,
  },
  noPadding: {
    padding: '0',
  },
  connectorLine: {
    minHeight: 24,
  },
  linkStyling: { '& a': { fontWeight: 700, textDecoration: 'underline' } },
  disclaimer: {
    marginTop: '24px',
  },
  disclaimerText: {
    color: `${theme.colors.textOn.surfaceSubdued} !important`,
  },
});

class IntelligentGuideComponentInner extends React.Component<IntelligentGuidePreviewProps> {
  private rootContainer: HTMLElement | null = null;

  public componentDidMount() {
    const rootElement = this.rootContainer;
    rootElement?.querySelectorAll('a').forEach((a) => a.setAttribute('target', '_blank'));
  }

  public render(): ReactNode {
    const {
      steps,
      alternateSteps,
      onClickLink,
      onClickButton,
      classes,
      disclaimer,
      settings,
      phoneLinkEnabled,
      hasClickedOnButton,
      actionType,
    } = this.props;

    const [stepsHead, ...stepsTail] = steps;
    const [alternateStepsHead, ...alternateStepsTail] = alternateSteps;

    const onClick = (event: any) => {
      if (event.target.nodeName == 'A') {
        event.preventDefault();
        AppIntegration.openExternalWebPage(event.target.href);
        onClickLink();
      }
    };

    const onClickButtonDebounced = debounce(onClickButton, 700);

    const onClickMerchantButton = (linkToCancel: string) => {
      return (event: any) => {
        event.preventDefault();
        AppIntegration.openExternalWebPage(linkToCancel);
        onClickButtonDebounced();
      };
    };

    const MerchantButton = hasClickedOnButton ? SecondaryButton : PrimaryButton;

    return (
      <>
        {actionType === SubscriptionActionType.Cancel && stepsHead && (
          <>
            {stepsHead.type === IntelligentGuideStepType.IntelligentGuideTextStep && (
              <div className={classes.headlineDiv}>
                <Title>{stepsHead.description}</Title>
              </div>
            )}
          </>
        )}
        <div className={classes.mainDiv} onClick={onClick} ref={(ctx) => (this.rootContainer = ctx)}>
          <Stepper
            connector={
              <StepConnector
                classes={{
                  root: classes.noPadding,
                  line: classes.connectorLine,
                }}
              />
            }
            className={classes.noPadding}
            orientation="vertical"
            nonLinear
          >
            {actionType === SubscriptionActionType.Cancel &&
              stepsHead &&
              stepsHead.type !== IntelligentGuideStepType.IntelligentGuideTextStep && (
                <Step active key={0}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        active: classes.stepIcon,
                        text: classes.stepIconText,
                      },
                    }}
                    classes={{ labelContainer: classes.stepLabel }}
                  >
                    <Body>
                      <span
                        className={classes.linkStyling}
                        /* eslint-disable-next-line react/no-danger*/
                        dangerouslySetInnerHTML={{ __html: stepsHead.description }}
                      />
                    </Body>
                  </StepLabel>
                  {((stepsHead.type === IntelligentGuideStepType.IntelligentGuidePhoneButtonStep && phoneLinkEnabled) ||
                    // @ts-ignore
                    (stepsHead.type !== IntelligentGuideStepType.IntelligentGuideTextStep &&
                      [
                        IntelligentGuideStepType.IntelligentGuideEmailButtonStep,
                        IntelligentGuideStepType.IntelligentGuideLinkButtonStep,
                      ].includes(stepsHead.type))) && (
                    <StepContent>
                      {stepsHead.buttonLink && stepsHead.buttonLabel && (
                        <MerchantButton
                          onClick={onClickMerchantButton(stepsHead.buttonLink)}
                          label={stepsHead.buttonLabel}
                          data-test="intelligent-guide-link-button"
                        />
                      )}
                    </StepContent>
                  )}
                </Step>
              )}
            {(actionType === SubscriptionActionType.Cancel ? stepsTail : steps).map((step, index) => {
              return (
                <Step active key={index}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        active: classes.stepIcon,
                        text: classes.stepIconText,
                      },
                    }}
                    classes={{ labelContainer: classes.stepLabel }}
                  >
                    <Body>
                      {/* eslint-disable-next-line react/no-danger*/}
                      <span className={classes.linkStyling} dangerouslySetInnerHTML={{ __html: step.description }} />
                    </Body>
                  </StepLabel>
                  {((step.type === IntelligentGuideStepType.IntelligentGuidePhoneButtonStep && phoneLinkEnabled) ||
                    (step.type !== IntelligentGuideStepType.IntelligentGuideTextStep &&
                      [
                        IntelligentGuideStepType.IntelligentGuideEmailButtonStep,
                        IntelligentGuideStepType.IntelligentGuideLinkButtonStep,
                      ].includes(step.type))) && (
                    <StepContent>
                      {step.buttonLink && step.buttonLabel && (
                        <MerchantButton
                          onClick={onClickMerchantButton(step.buttonLink)}
                          label={step.buttonLabel}
                          data-test="intelligent-guide-link-button"
                        />
                      )}
                    </StepContent>
                  )}
                </Step>
              );
            })}
          </Stepper>
          {actionType === SubscriptionActionType.Cancel && alternateStepsHead && (
            <div className={classes.headlineDiv} style={{ padding: '16px 0' }}>
              <Title>{alternateStepsHead.description}</Title>
            </div>
          )}
          <Stepper
            connector={
              <StepConnector
                classes={{
                  root: classes.noPadding,
                  line: classes.connectorLine,
                }}
              />
            }
            className={classes.noPadding}
            orientation="vertical"
            nonLinear
          >
            {(actionType === SubscriptionActionType.Cancel ? alternateStepsTail : alternateSteps).map((step, index) => {
              return (
                <Step active key={index}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        active: classes.stepIcon,
                        text: classes.stepIconText,
                      },
                    }}
                    classes={{ labelContainer: classes.stepLabel }}
                  >
                    <Body>
                      {/* eslint-disable-next-line react/no-danger*/}
                      <span className={classes.linkStyling} dangerouslySetInnerHTML={{ __html: step.description }} />
                      {step.type === IntelligentGuideStepType.IntelligentGuidePhoneButtonStep && !phoneLinkEnabled && (
                        <div>{`${step.buttonLabel}: ${settings.phoneNumber}`}</div>
                      )}
                    </Body>
                  </StepLabel>
                  {((step.type === IntelligentGuideStepType.IntelligentGuidePhoneButtonStep && phoneLinkEnabled) ||
                    (step.type !== IntelligentGuideStepType.IntelligentGuideTextStep &&
                      [
                        IntelligentGuideStepType.IntelligentGuideEmailButtonStep,
                        IntelligentGuideStepType.IntelligentGuideLinkButtonStep,
                      ].includes(step.type))) && (
                    <StepContent>
                      {step.buttonLink && step.buttonLabel && (
                        <MerchantButton
                          onClick={onClickMerchantButton(step.buttonLink)}
                          label={step.buttonLabel}
                          data-test="intelligent-guide-alternate-link-button"
                        />
                      )}
                    </StepContent>
                  )}
                </Step>
              );
            })}
          </Stepper>
          {disclaimer && (
            <div className={classes.disclaimer}>
              <Caption className={classes.disclaimerText}>{disclaimer.description}</Caption>
            </div>
          )}
        </div>
      </>
    );
  }
}

export const IntelligentGuideComponent = withStyles(styles, { withTheme: true })(IntelligentGuideComponentInner);
