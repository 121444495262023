import { useLocalization } from '@client/internationalization';
import { IntelligentGuideComponent } from '@client/modules/intelligent-guides/components/IntelligentGuideComponent';
import type { IntelligentGuide } from '@client/modules/intelligent-guides/models';
import { ContractDetailsFloatingButtons } from '@client/modules/subscription/ContractDetailsPage/components/ContractDetailsFloatingButtons';
import { phoneMaxWidth } from '@client/styles/responsive-helpers';
import type { TrackingPageName } from '@client/tracking/mixpanel-constants';
import { TrackingButtonLocation, TrackingButtonName } from '@client/tracking/mixpanel-constants';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { RHFormGroup } from '@minna-technologies/minna-ui/components/Form/RHFormGroup';
import { RHRadio } from '@minna-technologies/minna-ui/components/Form/RHRadio';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { CardLayout } from '@minna-technologies/minna-ui/components/Layouts/CardLayout';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import * as queryString from 'querystring';
import type { FC } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { SubscriptionActionType } from '@client/models/subscription';

export interface DialogOption {
  mixpanelData: string;
  label: string;
}

interface DialogOptionPrecursor {
  mixpanelData: string;
  lokaliseKey: string;
}

const defaultDialogOptionsPrecursor: DialogOptionPrecursor[] = [
  {
    mixpanelData: 'I don’t know my password',
    lokaliseKey: 'dialogDontKnowLoginDetails',
  },
  {
    mixpanelData: 'It doesn’t feel safe with the link',
    lokaliseKey: 'dialogDoesntFeelSafe',
  },
  {
    mixpanelData: 'I was just looking around',
    lokaliseKey: 'dialogJustLookingAround',
  },
  {
    mixpanelData: 'I don’t have time',
    lokaliseKey: 'dialogDontHaveTime',
  },
  {
    mixpanelData: 'The guide was hard to understand',
    lokaliseKey: 'dialogGuideHardToUnderstand',
  },
  {
    mixpanelData: 'Other',
    lokaliseKey: 'dialogOther',
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    [theme.breakpoints.up(phoneMaxWidth)]: {
      minHeight: '100vh',
      overflow: 'auto',
    },
    '@supports (padding-bottom: calc(env(safe-area-inset-bottom)))': {
      paddingBottom: 'calc(env(safe-area-inset-bottom))',
      minHeight: 'calc(100vh - 64px - env(safe-area-inset-bottom))',
    },
  },
  stepLabel: {
    marginLeft: 8,
  },
  stepIcon: {
    color: `${theme.colors.action.info} !important`,
  },
  noPadding: {
    padding: '0',
  },
  connectorLine: {
    minHeight: 24,
  },
  radioButton: {
    paddingBottom: '1rem',
  },
  bottomDiv: {
    textAlign: 'center',
    marginTop: '1.5rem',
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  buttonLeft: {
    marginRight: '1rem',
    width: '100%',
  },
  buttonRight: {
    width: '100%',
  },
  title: {
    marginBottom: '1rem',
  },
  headlineDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    marginBottom: '0.5rem',
  },
}));

interface FormValues {
  dialogOption: number;
}

interface Props {
  header: React.ReactNode;
  intelligentGuide: IntelligentGuide;
  dialogPageName: TrackingPageName;
  linkName: TrackingButtonName;
  title: React.ReactNode;
  feedbackSectionTitle: React.ReactNode;
  modalTitle: React.ReactNode;

  onGoBack(): void;

  onConfirmAction(): void;

  onSubmitDeclineDialog(userComment?: string): void;

  onClickAnyButton(location: TrackingButtonLocation, button: string, page?: string, reason?: string): void;

  onViewGuideNotFromUserMessage?(): void;

  trackViewedGuide(): void;

  trackViewedReasonPage?(): void;

  trackClickedIntelligentGuideButton(): void;

  customDialogOptions?: DialogOption[];
  showFeedbackSection?: boolean;
  actionType: SubscriptionActionType;
}

export const IntelligentGuidePageComponent: FC<Props> = ({
  header,
  intelligentGuide,
  dialogPageName,
  linkName,
  title,
  feedbackSectionTitle,
  modalTitle,
  onGoBack,
  onConfirmAction,
  onSubmitDeclineDialog,
  onViewGuideNotFromUserMessage,
  onClickAnyButton,
  trackViewedGuide,
  trackViewedReasonPage,
  trackClickedIntelligentGuideButton,
  customDialogOptions,
  showFeedbackSection,
  actionType,
}) => {
  const { localizeMessage } = useLocalization('general/IntelligentGuide');
  const features = useSelector(selectorFeatures);
  const query = queryString.parse(window.location.search.slice(1));
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [hasClickedOnGuideButton, setHasClickedOnGuideButton] = React.useState(showFeedbackSection || false);
  const methods = useMinnaForm<FormValues>();
  const reason = methods.watch('dialogOption');
  const dialogOptions: DialogOption[] = customDialogOptions
    ? customDialogOptions
    : defaultDialogOptionsPrecursor.map((dialogOptionPrecursor) => ({
        mixpanelData: dialogOptionPrecursor.mixpanelData,
        label: localizeMessage(dialogOptionPrecursor.lokaliseKey),
      }));

  useMountEffect(() => {
    trackViewedGuide();
    if (!query.userMessage && onViewGuideNotFromUserMessage !== undefined) {
      onViewGuideNotFromUserMessage();
    }
  });

  const submitDialog = () => {
    setOpenModal(false);
    if (reason) {
      onClickAnyButton(
        TrackingButtonLocation.CENTER,
        TrackingButtonName.SEND_FEEDBACK,
        dialogPageName,
        dialogOptions[reason].mixpanelData
      );
    }
    onSubmitDeclineDialog(dialogOptions[reason].mixpanelData);
  };
  const onClickNo = () => {
    setOpenModal(true);
    onClickAnyButton(TrackingButtonLocation.LEFT, TrackingButtonName.NO);
    if (trackViewedReasonPage) trackViewedReasonPage();
  };
  const onClickYes = () => {
    onClickAnyButton(TrackingButtonLocation.RIGHT, TrackingButtonName.YES);
    onConfirmAction();
  };
  const onClickLink = () => {
    onClickAnyButton(TrackingButtonLocation.CENTER, linkName);
  };
  const onClickBack = () => {
    onClickAnyButton(TrackingButtonLocation.BOTTOM_LEFT, TrackingButtonName.BACK);
    onGoBack();
  };
  const onClickCloseModal = () => {
    setOpenModal(false);
    onClickAnyButton(TrackingButtonLocation.TOP_RIGHT, TrackingButtonName.CLOSE, dialogPageName);
    onSubmitDeclineDialog();
  };
  const onClickGuideButton = () => {
    setHasClickedOnGuideButton(true);
    trackClickedIntelligentGuideButton();
  };

  return (
    <MultiDeviceLayout
      fullWidthContent={header}
      footer={<ContractDetailsFloatingButtons backButton customGoBack={onClickBack} hideHelp />}
    >
      <div className={classes.container}>
        <CardLayout>
          <Card>
            {actionType !== SubscriptionActionType.Cancel && (
              <div className={classes.headlineDiv}>
                <Title>{title}</Title>
              </div>
            )}
            {intelligentGuide && (
              <IntelligentGuideComponent
                steps={intelligentGuide.steps}
                alternateSteps={intelligentGuide.alternateSteps}
                hasClickedOnButton={hasClickedOnGuideButton}
                disclaimer={intelligentGuide.disclaimer}
                settings={intelligentGuide.settings}
                phoneLinkEnabled={features.subscriptionFeatures.merchantGuide.phoneLinkEnabled}
                actionType={actionType}
                onClickLink={onClickLink}
                onClickButton={onClickGuideButton}
              />
            )}
            {hasClickedOnGuideButton && (
              <>
                <Divider fullWidth />
                <div className={classes.bottomDiv}>
                  <Title className={classes.title}>{feedbackSectionTitle}</Title>
                  <div className={classes.buttonDiv}>
                    <div className={classes.buttonLeft}>
                      <PrimaryButton
                        label={localizeMessage('no')}
                        onClick={onClickNo}
                        data-test="intelligent-guide-feedback-button-no"
                      />
                    </div>
                    <div className={classes.buttonRight}>
                      <PrimaryButton
                        label={localizeMessage('yes')}
                        onClick={onClickYes}
                        data-test="intelligent-guide-feedback-button-yes"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </Card>
        </CardLayout>
      </div>
      <Modal
        open={openModal}
        title={modalTitle}
        button1={
          <PrimaryButton
            label={localizeMessage('sendFeedback')}
            type="submit"
            fullWidth
            disabled={!reason}
            onClick={submitDialog}
            data-test="intelligent-guide-send-feedback-button"
          />
        }
        button2={
          <SecondaryButton
            label={localizeMessage('skipButtonLabel')}
            type="button"
            fullWidth
            onClick={onClickCloseModal}
            data-test="intelligent-guide-skip-feedback-button"
          />
        }
      >
        <Form onSubmit={submitDialog} formMethods={methods}>
          <RHFormGroup data-test="intelligent-guide-feedback-options" name="dialogOption" defaultValue="post" fullWidth>
            {dialogOptions.map((option, index) => {
              return (
                <RHRadio
                  value={index}
                  key={index}
                  data-test={`intelligent-guide-feedback-option-${index + 1}`}
                  label={option.label}
                  className={classes.radioButton}
                />
              );
            })}
          </RHFormGroup>
        </Form>
      </Modal>
    </MultiDeviceLayout>
  );
};
