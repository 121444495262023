import { withLocalization } from '@client/internationalization';
import type { ServiceToDisplay } from '@client/models/service';
import { ContractCardInfoChip } from '@client/modules/subscription/components/ContractCardInfoChip/component';
import type { Contract } from '@client/modules/subscription/types';
import { ContractState } from '@client/modules/subscription/types';
import { CategoryIcon } from '@client/shared-components/CategoryIcon/CategoryIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Checkbox } from '@minna-technologies/minna-ui/components/Inputs/Checkbox';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';

const useStyles = makeStyles({
  checkbox: {
    marginRight: '-6px',
  },

  card: {
    marginBottom: '16px',
  },

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '-webkit-tap-highlight-color': 'white',
  },

  serviceName: {
    width: '100%',
    margin: '0 16px',
  },

  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },

  columnContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '16px',
  },
});

export interface ServiceCardProps {
  service: ServiceToDisplay;
  contracts: Contract[];
  onServiceCheck(service: any, isChecked: boolean): void;
}

const ServiceCardComponent: React.FunctionComponent<ServiceCardProps> = ({
  service,
  contracts,
  onServiceCheck,
}: ServiceCardProps) => {
  const { colors } = useTheme();
  const classes = useStyles();

  const currentContract = contracts.find((contract: Contract) => contract.id === service.contractId);
  const contractState = currentContract?.state ?? '';
  // providing a default value here because checking for contract will cause
  // a different set of errors.
  const ongoingProcess = !(contractState === ContractState.Optimized || contractState === ContractState.Active);

  return (
    <Card className={classes.card}>
      <div
        className={classes.content}
        role="button"
        onClick={() => {
          if (currentContract && ongoingProcess) {
            return;
          }
          onServiceCheck(service, !service.selected);
        }}
      >
        <div>
          <div className={classes.rowContent}>
            <CategoryIcon categoryName={service.category.name} nativeColor={colors.textOn.surfaceSubdued} />
            <div className={classes.columnContent}>
              <div className={classes.serviceName}>
                <Body color={colors.textOn.surfaceSubdued}>{service.name}</Body>
              </div>
              {ongoingProcess && currentContract && <ContractCardInfoChip contract={currentContract} />}
            </div>
          </div>
        </div>
        <Checkbox
          checked={service.selected}
          disabled={currentContract && ongoingProcess}
          value={service.name}
          className={classes.checkbox}
          data-test="select-service-card-checkbox"
          onChange={(e) => onServiceCheck(service, e.target.checked)}
        />
      </div>
    </Card>
  );
};

export const ServiceCard = withLocalization('contract/ContractDetails')(ServiceCardComponent);
