import Sentry from '@client/assets/js/sentry';
import type {
  MultipleChoiceQuestion,
  Question,
  RecipientQuestion,
  SimpleQuestion,
} from '@client/models/cancellation-questions';
import type { User } from '@client/models/user-models';
import type { UserInformationForm } from '@client/modules/cancellation/models';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import find from 'lodash/find';
import flatMapDeep from 'lodash/flatMapDeep';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import map from 'lodash/map';
import set from 'lodash/set';

export function collectAllQuestions(questions: Question[], formAnswers: any): Question[] {
  return flatMapDeep(questions, (question) => {
    const type = question.type;

    if (type === 'MultipleChoiceQuestion') {
      // Only include answered nested questions
      const multipleChoiceQuestion = question as MultipleChoiceQuestion;
      const { nestedQuestionAlternativeId } = get(formAnswers, question.id, {
        nestedQuestionAlternativeId: get(formAnswers, 'nestedQuestion' + question.id, ''),
      });
      const selectedAlternative = find(multipleChoiceQuestion.alternatives, { id: nestedQuestionAlternativeId });

      if (selectedAlternative) {
        const nestedQuestions = collectAllQuestions(selectedAlternative.nestedQuestions, formAnswers);

        return concat([question], nestedQuestions);
      } else {
        // None selected, needs to be handled later.
        return question;
      }
    } else {
      return question;
    }
  });
}

export function getPrefilledUserInformationForm(user: User) {
  return {
    personalIdentityNumber: user.personalNumber,
    firstName: get(user, 'name.first'),
    lastName: get(user, 'name.last'),
    nameVerified: get(user, 'name.verified'),
    street: get(user, 'address.street'),
    careOf: get(user, 'address.careOf'),
    postalCode: get(user, 'address.postalCode'),
    city: get(user, 'address.city'),
    email: get(user, 'emailAddress'),
    emailVerified: get(user, 'email.verified', false),
    prefilled: true,
  };
}

export function userInfoFromUserInformationForm(form: UserInformationForm) {
  const userInfo = {};
  forEach(formInfoPathLookupTable, (userInfoPath, informationKey) => {
    const userInformation = get(form, informationKey);
    if (userInformation) {
      set(userInfo, userInfoPath, userInformation);
    }
  });

  return userInfo;
}

const formInfoPathLookupTable = {
  firstName: 'name.first',
  lastName: 'name.last',
  nameVerified: 'name.verified',
  personalIdentityNumber: 'personalIdentityNumber.number',
  email: 'emailAddress',
  emailVerified: 'email.verified',
  street: 'address.street',
  careOf: 'address.careOf',
  postalCode: 'address.postalCode',
  city: 'address.city',
};

export const questionInfoMap = (question: Question) => {
  switch (question.type) {
    case 'SimpleQuestion': {
      const simpleQuestion = question as SimpleQuestion;

      return simpleQuestionInfoMap(simpleQuestion);
    }
    case 'MultipleChoiceQuestion':
      return { answerType: 'NestedAnswer' };
    case 'RecipientQuestion':
      return { answerType: 'RecipientAnswer' };
    default:
      Sentry.captureMessage('Was not able to create a Cancellation answer');
      return { answerType: 'TextAnswer' };
  }
};

const simpleQuestionInfoMap = (simpleQuestion: SimpleQuestion) => {
  switch (simpleQuestion.questionType) {
    case 'TextQuestion':
      return { answerType: 'TextAnswer' };
    case 'DateQuestion':
      return { answerType: 'DateAnswer' };
    case 'AddressQuestion':
      return { answerType: 'AddressAnswer' };
    case 'EmailQuestion':
      return { answerType: 'EmailAnswer' };
    case 'TelephoneQuestion':
      return { answerType: 'TelephoneAnswer' };
    default:
      Sentry.captureMessage('Was not able to create a Cancellation answer');
      return { answerType: 'TextAnswer' };
  }
};

export const contractCancellationAnswers = (terminationAnswers: any, terminationQuestions: Question[]) => {
  const questions = collectAllQuestions(terminationQuestions, terminationAnswers);

  return compact(
    map(questions, (question: Question) => {
      const questionInfo = questionInfoMap(question);

      const answerObject = get(terminationAnswers, question.id);

      if (!answerObject) {
        return null;
      }

      let modificationsDueToUserInputMatchesAlternative = {};
      //If question is type recipient and has additional info and additional info matches existing alternative we should use that alternative instead
      if (answerObject.userInput && question.type === 'RecipientQuestion') {
        const recipientQuestion = question as RecipientQuestion;
        const maybeMatchingAlternative = find(
          recipientQuestion.alternatives,
          // @ts-ignore
          (alternative) => (alternative.label ?? '').toLowerCase() === answerObject.userInput.toLowerCase()
        );

        if (maybeMatchingAlternative) {
          modificationsDueToUserInputMatchesAlternative = {
            alternativeId: maybeMatchingAlternative.id,
            userInput: undefined,
          };
        }
      }

      if (questionInfo.answerType === 'DateAnswer' && answerObject.date == null) {
        return null;
      }

      return {
        type: questionInfo.answerType,
        questionId: question.id,
        ...answerObject,
        ...modificationsDueToUserInputMatchesAlternative,
      };
    })
  );
};
