import type { CancellationId } from '@client/models/cancellation';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { sendViewedCancellationDetailsPage } from '@client/modules/cancellation/CancellationDetailsPage/mixpanel';
import type { CancellationDetailsResponse } from '@client/modules/cancellation/CancellationDetailsPage/models';
import { getCancellationDetails } from '@client/modules/cancellation/CancellationDetailsPage/models';
import { ContractCancellationOutcome } from '@client/modules/subscription/ContractDetailsPage/components/ContractCancellationOutcome';
import { OngoingCancellation } from '@client/modules/subscription/ContractDetailsPage/components/OngoingCancellation';
import { ContractHeaderDeprecated } from '@minna-technologies/minna-ui/components/ContractHeaderDeprecated';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

interface Params {
  cancellationId: CancellationId;
}

export const CancellationDetailsPage = () => {
  const [cancellationDetails, setCancellationDetails] = useState<CancellationDetailsResponse | undefined>();
  const { cancellationId } = useParams<Params>();
  const appContext = useSelector(selectorAppContext);
  const history = useHistory();

  useEffect(() => {
    getCancellationDetails(cancellationId).then((details) => {
      setCancellationDetails(details);
      sendViewedCancellationDetailsPage();
    });
  }, [cancellationId]);

  return (
    <div>
      {!!cancellationDetails && (
        <MultiDeviceLayout
          footer={<FooterNavigation onBackClick={() => history.goBack()} />}
          hasTopNavigation={false}
          fullWidthContent={
            <ContractHeaderDeprecated
              providerLogo={cancellationDetails.logo}
              title={cancellationDetails.localizedCategoryName}
              providerName={cancellationDetails.merchantName}
              subHeading={cancellationDetails.merchantName}
            />
          }
        >
          {cancellationDetails.cancellationInformation.actionOutcome ? (
            <ContractCancellationOutcome
              cancellationInfo={cancellationDetails.cancellationInformation}
              outcome={cancellationDetails.cancellationInformation.actionOutcome}
              market={appContext.market}
              categoryName={cancellationDetails.categoryName.name}
              serviceProviderName={cancellationDetails.merchantName}
              serviceProviderId={cancellationDetails.merchantId}
              serviceName={cancellationDetails.serviceName}
            />
          ) : (
            <OngoingCancellation
              categoryName={cancellationDetails.categoryName.name}
              cancellationInfo={cancellationDetails.cancellationInformation}
              serviceProviderName={cancellationDetails.merchantName}
              localizedCategoryName={cancellationDetails.localizedCategoryName}
              serviceName={cancellationDetails.serviceName}
            />
          )}
        </MultiDeviceLayout>
      )}
    </div>
  );
};
