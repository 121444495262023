import {connect} from 'react-redux';
import {compose} from 'recompose';
import SwedbankNotificationSettingsPage from './component';
import {errorPageOnFailure, loadingPageWhileLoading, onEnter} from '@client/containers/container-helpers';
import {
  fetchNotificationSettingsAndEmail,
  updateEmailAndNotificationSettings,
  selectorSettings,
  selectorIsLoading,
  selectorLoadingFailed,
  selectorUpdateFailed,
  selectorUpdateSucceeded,
  selectorUserEmailAddress
} from './swedbank-notification-settings';
import {viewSwedbankNotificationSettingsPageMixpanelEvent} from '../../../tracking/mixpanel-events';

const mapStateToProps = (state) => {
  return {
    notificationSettings: selectorSettings(state),
    isLoading: selectorIsLoading(state),
    loadingFailed: selectorLoadingFailed(state),
    updateFailed: selectorUpdateFailed(state),
    updateSucceeded: selectorUpdateSucceeded(state),
    userEmailAddress: selectorUserEmailAddress(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitUpdateSettings: (userEmailAddress, contractChangeEmailEnabled) => {
      dispatch(updateEmailAndNotificationSettings(userEmailAddress, contractChangeEmailEnabled));
    }
  };
};

const SwedbankNotificationSettingsContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  onEnter((props, {store}) => {
    store.dispatch(fetchNotificationSettingsAndEmail());
    viewSwedbankNotificationSettingsPageMixpanelEvent();
  }),
  errorPageOnFailure(p => p.loadingFailed),
  loadingPageWhileLoading(p => p.isLoading)
)(SwedbankNotificationSettingsPage);

export default SwedbankNotificationSettingsContainer;
