import type { SubscriptionActionType } from '@client/models/subscription';
import { isCancellationEnabled, isChangePlanEnabled, isPauseEnabled } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { BindingTimeNotice } from '@client/modules/overview/components/BindingTimeNotice';
import { PossibleActionsOnContract } from '@client/modules/overview/ContractAction/components/ContractActionContent/PossibleActionsOnContract';
import { isBindingPeriodEndingSoon } from '@client/modules/overview/OverviewPage/components/SubscriptionList/SubscriptionListItemWithActions/utils';
import type { Contract, Subscription, SubscriptionSupplier } from '@client/modules/subscription/types';
import { hasTerminationInstructions } from '@client/modules/subscription/types';
import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import type { BindingTimeContract } from '../../../../subscription/types';
import { ContractActionHeader } from '../ContractActionHeader';
import { NoActionText } from '../NoActionText';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.colors.base.surface,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    width: '100%',
  },

  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 8px',
  },
}));

interface Props {
  contract: Contract;
  subscription: Subscription;
  supplier: SubscriptionSupplier;
  onButtonClick(action: SubscriptionActionType): void;
  onReadMoreClicked(): void;
}

export const ContractActionContent = ({
  contract,
  subscription,
  supplier,
  onButtonClick,
  onReadMoreClicked,
}: Props): JSX.Element => {
  const classes = useStyles();

  const features = useSelector(selectorFeatures);

  const cancelActiveAndAvailable = isCancellationEnabled(features.actionFeatures) && contract.terminable;

  const pauseAvailable = isPauseEnabled(features.actionFeatures);

  const changePlanAvailable = isChangePlanEnabled(features.actionFeatures) && contract.changePlanEnabled;

  const pauseActiveAndAvailable = pauseAvailable && contract.pausable;

  const contractHasActions = cancelActiveAndAvailable || pauseActiveAndAvailable || changePlanAvailable;

  function getInnerContent(): React.ReactNode {
    if (!isBindingPeriodEndingSoon(contract)) {
      const bindingPeriodEndDate = (contract as BindingTimeContract).contractBindingPeriod.endsAt;
      return <BindingTimeNotice bindingPeriodEndDate={bindingPeriodEndDate} contractHasActions={contractHasActions} />;
    } else if (contractHasActions) {
      return (
        <PossibleActionsOnContract
          contract={contract}
          subscription={subscription}
          onButtonClick={onButtonClick}
          supplierName={supplier.name}
        />
      );
    } else {
      return (
        <NoActionText
          categoryName={contract.service.category.text}
          hasTerminationInstructions={hasTerminationInstructions(contract)}
          supplierName={supplier.name}
          onClick={onReadMoreClicked}
        />
      );
    }
  }

  return (
    <div className={classes.container}>
      <ContractActionHeader contract={contract} supplier={supplier} />
      <div className={classes.actionContainer}>{getInnerContent()}</div>
    </div>
  );
};
