import { QuickActionsCancelPage } from '@client/modules/QuickActions/Cancel';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import {
  contractDetailsPage,
  manualAddSubscriptionPage,
  overviewPage,
  quickActionsCancel,
  subscriptionPage,
} from '@client/routes';
import { TrackingPageName } from '@client/tracking/mixpanel-constants';
import get from 'lodash/get';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import type { AppContext } from '../app-context';
import { withAppContext } from '../app-context/react';
import { onRequestCancellationOfContract } from '../cancellation/onCancelContract';

interface IProps {
  appContext: AppContext;
}

export const QuickActionRouteInner: React.FunctionComponent<IProps> = ({ appContext }) => {
  const dispatch = useDispatch();

  return (
    <Switch>
      <Route
        path={quickActionsCancel}
        render={({ history }) => (
          <QuickActionsCancelPage
            onBackClick={() => {
              const backToUrl = get(history.location, 'state.previousUrl', overviewPage);
              history.push(backToUrl);
            }}
            onAddManualSubscription={() => history.push(manualAddSubscriptionPage)}
            onSubscriptionClick={(subscription: Subscription) => {
              if (subscription.contracts.length > 1) {
                history.push(subscriptionPage(subscription.id), {
                  previousUrl: quickActionsCancel,
                });
              } else {
                history.push(contractDetailsPage(subscription.id, subscription.contracts[0].id), {
                  previousUrl: quickActionsCancel,
                });
              }
            }}
            onContractCancelClick={(subscription: Subscription, contract: Contract) =>
              onRequestCancellationOfContract(
                appContext,
                contract,
                subscription,
                history,
                dispatch,
                true,
                TrackingPageName.CANCEL_QUICK_ACTION
              )
            }
          />
        )}
      />
    </Switch>
  );
};

export const QuickActionRoute = withAppContext(QuickActionRouteInner);
