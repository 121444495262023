import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { UserIcon } from '@minna-technologies/minna-ui/icons/User';
import React from 'react';

interface UserMenuWithIconHeaderButtonProps {
  iconColor?: string;
  classes: any;
  onClick?(event: any): void;
}

const styles = createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    marginLeft: '0',
  },
});

const UserMenuWithIconHeaderButtonInner: React.FunctionComponent<UserMenuWithIconHeaderButtonProps> = ({
  iconColor,
  classes,
  onClick,
}) => (
  <div role="button" className={classes.container} onClick={onClick}>
    <UserIcon nativeColor={iconColor} className={classes.icon} />
  </div>
);

export const UserMenuWithIconHeaderButton = withStyles(styles)(UserMenuWithIconHeaderButtonInner);
