import { useLocalization } from '@client/internationalization';
import type { AmountWithCurrency } from '@client/models/subscription';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import { useTheme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { StarRating } from '@minna-technologies/minna-ui/components/StarRating';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { TadaIllustration } from '@minna-technologies/minna-ui/illustrations/Tada';
import React, { useCallback, useEffect, useState } from 'react';
import { selectorAppContext, selectorFeatures } from '@client/modules/app-context/duck';
import { useSelector } from 'react-redux';
import { AppIntegration } from '@client/modules/app-integration';
import {
  DELAY_MS_BEFORE_OPEN_UPDATE_BANK_APP_MODAL,
  shouldOpenUpdateBankAppModal,
} from '@minna-components/UpdateBankAppModal/utils';
import { UpdateBankAppModal } from '@minna-components/UpdateBankAppModal';
import { YouSavedComponent } from '@minna-components/YouSavedComponent';
import { tabletMinWidth } from '@client/styles/responsive-helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.colors.base.surface,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '100vh',
    [theme.breakpoints.up(tabletMinWidth)]: {
      minHeight: 'calc(100vh - 56px)',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: '16px',
    marginTop: '48px',
    width: '100%',
  },
  buttonsContainer: {
    borderTop: `2px solid ${theme.colors.base.background}`,
    padding: '24px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    heigh: '142px',
    width: '142px',
  },
  rating: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '8px',
    justifyContent: 'flex-end',
  },
}));

interface Props {
  description?: React.ReactNode;
  amountWithCurrency?: AmountWithCurrency;
  primaryButtonLabel?: React.ReactNode;

  showCancelAndSaveEntryPoint?: boolean;

  trackCloseButtonClicked(): void;
  onRedirectUser(rating?: number): void;
  onAnotherAction?(rating?: number): void;
  trackRatedIntelligentGuideExperience(rating: number): void;
  trackIntelligentGuideSuccessPageViewed?(): void;
  trackStartNewSavingsButtonClicked?(): void;
  trackViewedUpdateBankAppModal?(): void;
  trackClosedUpdateBankAppModal?(): void;
}

export const IntelligentGuideDoneComponent = ({
  description,
  amountWithCurrency,
  primaryButtonLabel,
  showCancelAndSaveEntryPoint,
  trackCloseButtonClicked,
  onRedirectUser,
  onAnotherAction,
  trackRatedIntelligentGuideExperience,
  trackIntelligentGuideSuccessPageViewed,
  trackStartNewSavingsButtonClicked,
  trackViewedUpdateBankAppModal,
  trackClosedUpdateBankAppModal,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const features = useSelector(selectorFeatures);
  const { localizeMessage } = useLocalization('general/IntelligentGuide');
  const [currentRating, onRatingChange] = useState(0);
  const classes = useStyles();
  const { colors } = useTheme();
  const appContext = useSelector(selectorAppContext);
  const [timer, setTimer] = useState<any>(null);

  useEffect(() => {
    // By returning a function from useEffect, react runs this on un-mount: https://legacy.reactjs.org/docs/hooks-reference.html#cleaning-up-an-effect
    return () => {
      if (timer !== null) {
        return clearTimeout(timer);
      }
    };
  }, [timer]);

  useMountEffect(() => {
    if (trackIntelligentGuideSuccessPageViewed) trackIntelligentGuideSuccessPageViewed();
  });

  const onClickDoneButton = () => {
    if (currentRating > 0) {
      trackRatedIntelligentGuideExperience(currentRating);
      trackCloseButtonClicked();
      onRedirectUser(currentRating);
    } else {
      trackCloseButtonClicked();
      onRedirectUser();
    }
  };

  const onClickAnotherActionButton = () => {
    if (currentRating > 0) {
      trackRatedIntelligentGuideExperience(currentRating);
      // @ts-ignore we display the button only if onAnotherAction is available
      onAnotherAction(currentRating);
    } else {
      // @ts-ignore we display the button only if onAnotherAction is available
      onAnotherAction();
    }
  };
  const [openUpdateBankApp, setOpenUpdateBankApp] = React.useState<boolean>(false);

  const maybeOpenUpdateBankApp = useCallback(() => {
    if (shouldOpenUpdateBankAppModal(appContext.appType)) {
      if (trackViewedUpdateBankAppModal) trackViewedUpdateBankAppModal();
      setOpenUpdateBankApp(true);
    }
    setIsLoading(false);
  }, [appContext.appType, trackViewedUpdateBankAppModal]);
  const closeUpdateBankApp = () => {
    if (trackClosedUpdateBankAppModal) trackClosedUpdateBankAppModal();
    setOpenUpdateBankApp(false);
    onRedirectUser();
  };
  const onClickCancelAndSaveButton = (event?: any) => {
    if (trackStartNewSavingsButtonClicked) trackStartNewSavingsButtonClicked();
    if (currentRating > 0) trackRatedIntelligentGuideExperience(currentRating);
    const internalUrl: string | undefined = features.subscriptionFeatures.cancelAndSave.urlString;
    if (internalUrl) {
      setIsLoading(true);
      AppIntegration.openInternalPage(internalUrl, event);
      const timeOut = setTimeout(maybeOpenUpdateBankApp, DELAY_MS_BEFORE_OPEN_UPDATE_BANK_APP_MODAL);
      setTimer(timeOut);
    }
  };

  return (
    <MultiDeviceLayout backgroundColor={colors.base.surface}>
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.imageContainer}>
            <TadaIllustration className={classes.image} />
          </div>
          <Headline1 color={colors.textOn.surface}>{localizeMessage('doneThanksTellingUs')}</Headline1>
          {description && <Body align="center">{description}</Body>}
          {amountWithCurrency && amountWithCurrency.amount > 0 && (
            <YouSavedComponent amountSaved={amountWithCurrency} />
          )}
          <div className={classes.rating}>
            <Body align="center" style={{ marginBottom: '8px' }}>
              {localizeMessage('ratingTitle')}
            </Body>
            <StarRating currentRating={currentRating} onRatingChange={onRatingChange} />
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          {onAnotherAction && primaryButtonLabel && (
            <PrimaryButton
              onClick={onClickAnotherActionButton}
              label={primaryButtonLabel}
              fullWidth
              data-test="intelligent-guide-done-button-another-action"
            />
          )}
          {features.subscriptionFeatures.cancelAndSave.enabled && showCancelAndSaveEntryPoint && (
            <PrimaryButton
              label={localizeMessage('startNewSavingsButtonForIG')}
              fullWidth
              loading={isLoading}
              onClick={onClickCancelAndSaveButton}
              data-test="intelligent-guide-cancel-and-save-button"
            />
          )}
          <SecondaryButton
            disabled={isLoading}
            onClick={onClickDoneButton}
            label={localizeMessage('close')}
            fullWidth
            data-test="intelligent-guide-done-button-close"
          />
        </div>
      </div>
      <UpdateBankAppModal dialogOpen={openUpdateBankApp} closeDialog={closeUpdateBankApp} />
    </MultiDeviceLayout>
  );
};
