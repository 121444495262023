import minnaLogo from '@client/assets/images/minna-technologies-horizontal-rgb.svg';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { Header } from '@client/shared-components/Header';
import { useLoggedInUser } from '@client/utils/hooks/use-loggedin-user';
import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useLocalization } from '../../internationalization';
import * as urls from '../../routes';
import PageNavigationBar from '../../shared-components/PageNavigationBar';
import { LABEL_FAQ_GENERAL } from '../../zendesk';
import { NavigationType } from '../app-context';
import PageNotFoundContainer from '../fetching/ErrorPage';
import LoadingPage from '../fetching/LoadingPage';
import { FeedbackPage } from '../nps-csat-module/component';
import { ZendeskNavbarHelpButtonContainer } from '../Zendesk/ZendeskNavbarHelpButton';
import { OverviewPage } from './OverviewPage';

const LoadableInsightsPage = Loadable({
  loader: async () => import(/* webpackChunkName: "insights-page" */ '@client/modules/InsightsPage/container'),
  loading: LoadingPage,
  render(loaded) {
    const InsightsPage = loaded.InsightsPage;

    return <InsightsPage />;
  },
});

export const OverviewRoute = () => {
  useLoggedInUser();

  const { localizeMessage } = useLocalization('overview');

  const features = useSelector(selectorFeatures);

  const helpIcon = <ZendeskNavbarHelpButtonContainer query={LABEL_FAQ_GENERAL} hideLabelOnXs iconPrimaryColor />;

  const header = (titleId: string) => {
    const title = features?.navigation?.pageNavigationLogo ? undefined : localizeMessage(titleId);
    const logo = features?.navigation?.pageNavigationLogo ? minnaLogo : undefined;
    return features.navigation && features.navigation.navigationType === NavigationType.MOBILE_ONLY ? (
      <PageNavigationBar title={title} logo={logo} iconRight={helpIcon} beta={features?.beta} />
    ) : (
      <Header />
    );
  };

  return (
    <Switch>
      <Route
        exact
        path={urls.overviewPage}
        render={() => (
          <div>
            {header('overview')}
            <OverviewPage />
          </div>
        )}
      />
      <Route exact path={urls.csatNps} render={() => <FeedbackPage />} />
      {features.insights && (
        <Route
          exact
          path={urls.insightsPage}
          render={() => (
            <div>
              {header('insights')}
              <LoadableInsightsPage />
            </div>
          )}
        />
      )}
      <Route component={PageNotFoundContainer} />
    </Switch>
  );
};
