import identity from 'lodash/identity';
import type { ComponentType, ReactNode } from 'react';
import { branch, renderComponent } from 'recompose';

export function conditionalRender<P>(
  shouldRender: (props: P) => boolean,
  component: ReactNode
): (wrappedComponent: ComponentType<P>) => ComponentType<P> {
  return branch(
    shouldRender,
    // @ts-ignore
    renderComponent(component),
    identity
  );
}
