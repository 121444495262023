import get from 'lodash/get';
import type { Contract, Subscription } from '../modules/subscription/types';
import { methodToTrackingSubscriptionMethod } from '@client/tracking/mixpanel-events';

export function subscriptionProperties(subscription: Subscription) {
  return {
    'Subscription source': get(subscription, 'source'),
    'Supplier id': get(subscription, 'supplier.id'),
    'Supplier name': get(subscription, 'supplier.name'),
  };
}

export function contractProperties(contract: Contract) {
  return {
    Method: methodToTrackingSubscriptionMethod(contract?.cancellationMethod.type),
    Category: get(contract, 'service.category.name'),
    'Contract id': get(contract, 'id'),
    'Service id': get(contract, 'service.id'),
    'Service name': get(contract, 'service.name'),
  };
}

export function subscriptionAndContractProperties(subscription?: Subscription, contract?: Contract) {
  const subProperties = subscription ? subscriptionProperties(subscription) : {};
  const conProperties = contract ? contractProperties(contract) : {};

  return {
    ...subProperties,
    ...conProperties,
  };
}
