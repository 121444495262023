import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import useTheme from '@material-ui/core/styles/useTheme';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { LogOutIcon } from '@minna-technologies/minna-ui/icons/LogOut';
import compact from 'lodash/compact';
import type { FunctionComponent } from 'react';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LocalizedMessage } from '../../internationalization';
import * as urls from '../../routes';
import type { AppFeatureToggles } from '../app-context';
import { OverviewBottomNavigation } from '../overview';
import styles from './styles.scss';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';

interface MenuPageProps {
  showFeedback?: boolean;
  onFAQClick?: () => void;
  onContactUsClick?: () => void;
  onLogout?: () => void;
  dialogOpen: boolean;
  showDialog: (show: boolean) => void;
  currentLocation: string;
  features: AppFeatureToggles;
}

export const MenuPage: FunctionComponent<MenuPageProps> = ({
  showFeedback,
  onFAQClick,
  onContactUsClick,
  onLogout,
  dialogOpen,
  showDialog,
  currentLocation,
  features,
}) => {
  const { colors } = useTheme();
  const atLeastOnePolicyEnabled =
    features.conditionsAndPolicies?.userTermsPage ||
    features.conditionsAndPolicies?.privacyPolicyPage ||
    features.conditionsAndPolicies?.securityPage ||
    features.conditionsAndPolicies?.cookiesPage;
  const history = useHistory();
  const overviewEnabled: boolean = Boolean(features.identify?.identify);
  const onBackClick = () => {
    const backToUrl = overviewEnabled ? urls.overviewPage : urls.subscriptionHub;
    history.push(backToUrl);
  };
  const showBottomNavigation = overviewEnabled;
  const showBackButton = !showBottomNavigation;

  const conditionsAndPoliciesDisabled = !features.conditionsAndPolicies || !atLeastOnePolicyEnabled;

  const listItems = compact([
    {
      localizeId: 'settingsText',
      listItemsProps: { component: Link, to: { pathname: urls.settingsPage, state: { backTo: urls.menuPage } } },
    },
    onFAQClick && {
      localizeId: 'frequentQuestionsText',
      listItemsProps: { onClick: onFAQClick },
    },
    !conditionsAndPoliciesDisabled && {
      localizeId: 'conditionsAndPoliciesText',
      listItemsProps: {
        component: Link,
        to: { pathname: urls.conditionsAndPoliciesMenu, state: { backTo: urls.menuPage } },
      },
    },
    showFeedback && {
      localizeId: 'leaveFeedbackText',
      listItemsProps: { component: Link, to: urls.feedbackPage },
    },
    onContactUsClick && {
      localizeId: 'contactUsText',
      listItemsProps: { onClick: onContactUsClick },
    },
  ]);

  return (
    <MultiDeviceLayout
      title={<LocalizedMessage id="profileAndSettingsPageTitle" />}
      footer={showBackButton && <FooterNavigation onBackClick={onBackClick} />}
      hasTopNavigation={false}
    >
      <div className={styles.container}>
        <Paper>
          <List style={{ paddingBottom: '0' }}>
            {listItems.map(({ localizeId, listItemsProps }, index) => (
              <ListItem
                data-test={localizeId}
                button
                key={localizeId}
                divider={index !== listItems.length - 1}
                {...listItemsProps}
              >
                <ListItemText primary={<LocalizedMessage id={localizeId} />} />
                <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                  <ChevronRight style={{ marginRight: '0' }} />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
          {onLogout && (
            <Modal
              open={dialogOpen}
              onClose={() => showDialog(false)}
              title={<LocalizedMessage id="logoutText" />}
              button1={
                <SecondaryButton
                  label={<LocalizedMessage id="cancelText" />}
                  onClick={() => showDialog(false)}
                  data-test="dialog-secondary-button"
                />
              }
              button2={
                <PrimaryButton
                  label={<LocalizedMessage id="logoutTextV2" />}
                  onClick={onLogout}
                  data-test="dialog-primary-button"
                />
              }
              buttonLayout={'horizontal'}
            >
              <div className={styles['text-centered']}>
                <Body>
                  <LocalizedMessage id="logoutConfirmText" />
                </Body>
              </div>
            </Modal>
          )}
        </Paper>
        {onLogout && (
          <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'center' }}>
            <SecondaryButton
              data-test="logout-button"
              label={<LocalizedMessage id="logoutText" />}
              icon={<LogOutIcon nativeColor={colors.textOn.surfaceSubdued} />}
              onClick={() => showDialog(true)}
            />
          </div>
        )}
      </div>
      {showBottomNavigation && <OverviewBottomNavigation currentLocation={currentLocation} />}
    </MultiDeviceLayout>
  );
};
