import Sentry from '@client/assets/js/sentry';
import type { AppContext, AppContextIncoming } from '@client/modules/app-context';
import { getCachedOrFetchAppContext } from '@client/modules/app-context/app-context';
import { AppContextActions } from '@client/modules/app-context/duck';
import { prepareAppContextWithDisplayName } from '@client/modules/app-context/utils';
import { getMixpanelKey } from '@client/tracking/mixpanel';
// @ts-ignore js import, remove this when the import is typed
import mixpanel from 'mixpanel-browser';
import type { Store } from 'redux';

/**
 * Returns the correct mixpanel domain. When we are in US we should send requests to "normal" mixpanel, which have servers in US
 * When we are in EU we should send request to eu mixpanel.
 * We default to EU to be fully backward compatible, since we only had EU before.
 * Merchant portal is a special case, we use EU project for it.
 */
export const getMixpanelDomain = (hostname: string): string => {
  const isUsRegion = hostname.includes('.us.') || hostname.includes('-us-');
  const isMerchantPortal = hostname.includes('supplier.') || hostname.includes('portal.');
  if (isUsRegion && !isMerchantPortal) {
    return 'api.mixpanel.com';
  } else return 'api-eu.mixpanel.com';
};

export const fetchAppContext = async (store: Store): Promise<AppContext> => {
  // TODO: remove this when a proper solution for fetching feature toggles for logged out user is implemented
  try {
    const tenSeconds = 1000 * 10;
    const error = new Promise<AppContextIncoming>((resolve) => {
      // eslint-disable-next-line
      setTimeout(resolve, tenSeconds, null);
    });
    const appContextResponse = await Promise.race([getCachedOrFetchAppContext(), error]);
    const appContext = prepareAppContextWithDisplayName(appContextResponse);
    if (appContext) {
      store.dispatch(AppContextActions.initialize(appContext));

      return appContext;
    } else {
      throw new Error('Failed to fetch appContext due to timeout');
    }
  } catch (error) {
    Sentry.captureExceptionWithMessage(error, 'Failed to fetch appContext');
    throw error;
  }
};

export const initializeMixpanel = () => {
  const mixPanelKey = getMixpanelKey(window.location.hostname);
  const mixpanelDomain = getMixpanelDomain(window.location.hostname);
  if (mixPanelKey) {
    mixpanel.init(mixPanelKey, {
      api_host: `https://${mixpanelDomain}`,
    });
    //@ts-ignore
    window.mixpanel = mixpanel;
  }
};
