import { applicationInformation } from '@client/ApplicationInformation';
import ErrorIconImageUrl from '@client/assets/images/error-page-icon.svg';
import { Locale } from '@client/modules/app-context/constants';
import { selectorLocale } from '@client/modules/app-context/duck';
import useTheme from '@material-ui/core/styles/useTheme';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { RaisedButton } from 'material-ui';
import type { FunctionComponent } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import styles from './styles.scss';

interface ErrorLoadingProps {
  locale?: Locale;
}

const ErrorLoadingComponent: React.FunctionComponent<ErrorLoadingProps> = ({ locale = Locale.enGB }) => {
  const { colors } = useTheme();

  //ErrorLoading is shown if we couldn't load messages - so we need to bundle the messages
  function bundledMessages(userLocale?: Locale) {
    switch (userLocale) {
      case Locale.svSE:
        return {
          somethingWentWrongTitle: 'Hmm, något gick fel',
          itIsNotYouItIsUsText: 'Var inte orolig, det är inte du, det är vi.',
          pleaseTryAgainText: 'Var god och prova igen. Funkar inte det, försök igen senare.',
          tryAgainButtonLabel: 'Prova igen',
        };
      case Locale.daDK:
        return {
          somethingWentWrongTitle: 'Hmm, noget gik galt',
          itIsNotYouItIsUsText: 'Bare rolig. Det er ikke dig, det er os.',
          pleaseTryAgainText: 'Prøv igen. Hvis det ikke virker, så prøv igen senere.',
          tryAgainButtonLabel: 'Prøv igen',
        };
      case Locale.frBE:
      case Locale.frFR:
        return {
          somethingWentWrongTitle: 'Nous avons rencontré un problème',
          itIsNotYouItIsUsText: "Pas d'inquiétude, ce n'est pas vous, c'est nous",
          pleaseTryAgainText: 'Veuillez réessayer. Si ça ne marche pas, réessayez plus tard',
          tryAgainButtonLabel: 'Veuillez réessayer plus tard',
        };
      case Locale.nlBE:
      case Locale.nlNL:
        return {
          somethingWentWrongTitle: 'Hmm, er is iets fout gegaan',
          itIsNotYouItIsUsText: 'Geen zorgen. Het ligt niet aan u, maar aan ons.',
          pleaseTryAgainText: 'Probeer het nog eens. Als het niet lukt, probeer het dan later.',
          tryAgainButtonLabel: 'Probeer het nog eens',
        };
      // TODO: add case for Locale.fiFI
      case Locale.enGB:
      default:
        return {
          somethingWentWrongTitle: 'Hmm, something went wrong',
          itIsNotYouItIsUsText: "Don't worry, it's not you, it's us.",
          pleaseTryAgainText: "Please try again. If that doesn't work, try again later.",
          tryAgainButtonLabel: 'Try again',
        };
    }
  }

  const messages = bundledMessages(locale);

  return (
    <Card className={styles.container}>
      <div>
        <img src={ErrorIconImageUrl} className={styles['error-loading-image']} alt="error" />
        <h3 className={styles.header} data-test="error-component-title">
          {messages.somethingWentWrongTitle}
        </h3>
        <Body color={colors.textOn.surfaceSubdued} style={{ marginBottom: 16 }}>
          {messages.itIsNotYouItIsUsText}
          <br />
          {messages.pleaseTryAgainText}
        </Body>
      </div>
      <RaisedButton
        primary
        label={messages.tryAgainButtonLabel}
        onClick={() => window.location.reload()}
        className={styles['reload-button']}
      />
    </Card>
  );
};

const mapStateToProps = (state: any, props: ErrorLoadingProps) => {
  const adminLocale = applicationInformation.name === 'admin' ? 'en-GB' : undefined;

  return {
    locale: adminLocale || props.locale || selectorLocale(state),
  };
};

// @ts-ignore
export const ErrorLoading: FunctionComponent<ErrorLoadingProps> = connect(mapStateToProps)(ErrorLoadingComponent);
