import { hasOnlyMobilePhoneNumberCharacters } from '@client/assets/js/validators';
// @ts-ignore js import, remove this when the import is typed
import { LocalizedMessage } from '@client/internationalization';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { removeSpacesAndDashes } from '../../utils';

// https://en.wikipedia.org/wiki/Telephone_numbers_in_Finland
const FinlandMobilePhoneNumberValidators = {
  correctlyFormatted(value: string = ''): boolean | React.ReactNode {
    const trimmedValue = removeSpacesAndDashes(value);
    const valid =
      isEmpty(value) ||
      (hasOnlyMobilePhoneNumberCharacters(trimmedValue) && startsAsFinlandMobilePhoneNumber(trimmedValue));

    return valid ? false : <LocalizedMessage id="belgiumMobileFormatError" />;
  },
  correctLength(value: string = ''): boolean | React.ReactNode {
    if (isEmpty(value)) {
      return false;
    }

    const trimmedValue = removeSpacesAndDashes(value);
    const validLength = validNumberLength(trimmedValue);
    if (trimmedValue.length < validLength) {
      return <LocalizedMessage id="mobileNumberTooShortError" />;
    } else if (trimmedValue.length > validLength) {
      return <LocalizedMessage id="mobileNumberTooLongError" />;
    } else {
      return false;
    }
  },
};

function validNumberLength(trimmedNumber: string) {
  if (/^04(?!5)|050/.test(trimmedNumber)) {
    return 10;
  } else if (/^0457/.test(trimmedNumber)) {
    return 11;
  } else {
    return 13;
  }
}

export function startsAsFinlandMobilePhoneNumber(input: string) {
  return /^(\+358|04|050)/.test(input);
}

function finlandMobilePhoneNumberFormatter(value: string): string {
  const trimmedValue = removeSpacesAndDashes(value);
  if (/^\+358\d{9}/.test(trimmedValue)) {
    return `${trimmedValue.substring(0, 4)} ${trimmedValue.substring(4, 6)} ${trimmedValue.substring(
      6,
      9
    )} ${trimmedValue.substring(9, 11)} ${trimmedValue.substring(11)}`;
  }

  if (/^(0457)\d{7}$/.test(trimmedValue)) {
    return `${trimmedValue.substring(0, 4)} ${trimmedValue.substring(4, 7)} ${trimmedValue.substring(
      7,
      9
    )} ${trimmedValue.substring(9)}`;
  }

  if (/^(050|04(?!5))\d{7}$/.test(trimmedValue)) {
    return `${trimmedValue.substring(0, 3)} ${trimmedValue.substring(3, 6)} ${trimmedValue.substring(
      6,
      8
    )} ${trimmedValue.substring(8)}`;
  }

  return value;
}

export { FinlandMobilePhoneNumberValidators, finlandMobilePhoneNumberFormatter };
