// organize-imports-ignore
import Sentry from '@client/assets/js/sentry';
import {
  errorPageOnFailure,
  loadingPageWhileLoading,
  // @ts-ignore
} from '@client/containers/container-helpers';
import { onEnter } from '@client/containers/container-helpers/onEnter';
import requireLoggedInUser from '@client/containers/requireLoggedInUser';
import {
  selectorSubscriptionsViewedInGuide,
  selectorUser,
  selectorViewedChangePaymentDrawer,
  selectorViewedNewFeaturesPageAt,
  setUser,
} from '@client/ducks/user';
import { withFeatures } from '@client/feature-toggling';
import { saveUserViewedChangePaymentDrawer, saveUserViewedNewFeatures } from '@client/models/user';
import type { User } from '@client/models/user-models';
import type { AppContext } from '@client/modules/app-context';
import { selectorAppType, selectorMarket } from '@client/modules/app-context/duck';
import { withAppContext } from '@client/modules/app-context/react';
import { HistoryGraph } from '@client/modules/subscription/ContractDetailsPage/components/HistoryGraph';
import type { Subscription, SubscriptionId } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import type { History } from 'history';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import type { Dispatch } from 'redux';
import {
  getAndRefreshOverview,
  pageLoading,
  pageLoadingFailure,
  pageLoadingSuccess,
  selectorIsLoading,
  selectorLastBankDiscoveryDate,
  selectorLoadingFailed,
  selectorOrderedSubscriptions,
  selectorSubscriptions,
  selectorTerminatedSubscriptions,
} from '../duck';
import type { OverviewPageProps } from './component';
import { OverviewPage } from './component';
import {
  trackCancelAnySubscriptionButtonClicked,
  trackCancelQuickActionButtonClicked,
  trackChangePaymentQuickActionButtonClicked,
} from './mixpanel-events';
import type { AppType, Market } from '@client/modules/app-context/constants';
import { SubscriptionActionType } from '@client/models/subscription';

interface InnerProps {
  appContext: AppContext;
  history: History;
  market: Market;
  appType: AppType;
}

const mapStateToProps = (state: any, { appContext }: InnerProps) => {
  return {
    subscriptions: selectorSubscriptions(state),
    terminatedSubscriptions: selectorTerminatedSubscriptions(state),
    orderedSubscriptions: selectorOrderedSubscriptions(state),
    lastBankDiscoverySyncAt: selectorLastBankDiscoveryDate(state),
    isLoading: selectorIsLoading(state),
    loadingFailed: selectorLoadingFailed(state),
    user: selectorUser(state),
    visitedSubscriptionIds: selectorSubscriptionsViewedInGuide(state),
    lastSeenFeaturesInCurrentPlatformAt: selectorViewedNewFeaturesPageAt(state, appContext.appType),
    lastSeenChangePaymentDrawerAt: selectorViewedChangePaymentDrawer(state),
    appType: selectorAppType(state),
    market: selectorMarket(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { history, market, appType }: InnerProps) => ({
  redirectWithPreviousPage: (redirectUrl: string, previousPage: string = '') => {
    const previousPageObj = previousPage ? { previousUrl: previousPage } : {};
    history.push(redirectUrl, previousPageObj);
  },
  onAddManualSubscription: () => {
    history.push(urls.manualAddSubscriptionPage);
  },
  onQuickActionCancelClicked: () => {
    trackCancelQuickActionButtonClicked(market, appType);
    history.push(urls.quickActionsCancel);
  },
  onQuickActionChangePaymentClicked: () => {
    trackChangePaymentQuickActionButtonClicked(market, appType);
    history.push(urls.subscriptionSearchWithAction(SubscriptionActionType.ChangePayment));
  },
  onCancelAnySubscriptionClicked: () => {
    trackCancelAnySubscriptionButtonClicked(market, appType);
    history.push(urls.subscriptionSearch);
  },
  onSeenNewFeatures: (user: User) => {
    saveUserViewedNewFeatures()
      .then((updatedUser: User) => dispatch(setUser(updatedUser)))
      .catch((error: Error) => {
        const visitedPagesModified = cloneDeep(user?.visitedPages) || {};
        set(visitedPagesModified, 'viewedNewFeaturesPageAt.Standalone', moment());
        dispatch(setUser({ ...user, visitedPages: visitedPagesModified }));
        Sentry.captureExceptionWithMessage(error, 'Failed to update visited pages on user model');
      });
  },
  onSeenChangePaymentDrawer: (user: User) => {
    saveUserViewedChangePaymentDrawer()
      .then((updatedUser: User) => dispatch(setUser(updatedUser)))
      .catch((error: Error) => {
        const visitedPagesModified = cloneDeep(user?.visitedPages) || {};
        set(visitedPagesModified, 'viewedChangePlanDrawerAt', moment());
        dispatch(setUser({ ...user, visitedPages: visitedPagesModified }));
        Sentry.captureExceptionWithMessage(error, 'Failed to update visited pages on user model');
      });
  },
  onReadMore: () => {
    history.push(urls.privacyPolicyPage);
  },
});

const mergeProps = (stateProps: ReturnType<typeof mapStateToProps>, dispatchProps: any, ownProps: any) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onSeenNewFeatures: () => {
      dispatchProps.onSeenNewFeatures(stateProps.user);
    },
    onSeenChangePaymentServiceIntroductionDrawer: () =>
      dispatchProps.onSeenChangePaymentServiceIntroductionDrawer(stateProps.user),
  };
};

interface OnEnterProps {
  subscriptions: Subscription[];
  activeTab: string;
  visitedSubscriptionIds: SubscriptionId[];
  history: History;
}

export const OverviewPageContainer = compose<OverviewPageProps, Record<string, unknown>>(
  requireLoggedInUser,
  withRouter,
  withProps<{ currentLocation: string }, { location: any }>(({ location }) => ({ currentLocation: location.pathname })),
  withAppContext,
  withFeatures,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  onEnter(({ subscriptions }: OnEnterProps, { store }) => {
    if (!subscriptions) {
      store.dispatch(pageLoading());
    }
    getAndRefreshOverview()
      .then((overview: any) => {
        store.dispatch(pageLoadingSuccess(overview));

        // Preload subscription history graph in case the user goes into the contract details page.
        // Slight delay to prioritize loading of logotypes and other resources on the overview.
        setTimeout(() => {
          HistoryGraph.preload();
        }, 250);
      })
      .catch((error: Error) => {
        store.dispatch(pageLoadingFailure());
        Sentry.captureExceptionWithMessage(error, 'Failed to load overview page');
      });
  }),
  loadingPageWhileLoading((p: ReturnType<typeof mapStateToProps>) => p.isLoading),
  errorPageOnFailure((p: ReturnType<typeof mapStateToProps>) => p.loadingFailed)
)(OverviewPage);
