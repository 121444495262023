import React from 'react';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { useLocalization } from '@client/internationalization';
import { menuPage } from '@client/routes';
import { useHistory } from 'react-router-dom';
import { sendClickedSettingsPageButton } from '@client/modules/subscription-hub/mixpanel';

export const SettingsButton: React.FC = () => {
  const { localizeMessage } = useLocalization('subscriptionHub/subscriptionHub');
  const history = useHistory();
  const onClick = () => {
    sendClickedSettingsPageButton();
    history.push(menuPage);
  };

  return <SecondaryButton onClick={onClick} label={localizeMessage('settingsButtonLabel')} />;
};
