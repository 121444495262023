import { CancelServiceFormPage } from '@client/modules/cancellation/CancellationFormPage/CancelServiceForm';
import { CancelServiceSentPage } from '@client/modules/cancellation/CancellationSentPage/CancelServiceSentPage';
import { SigningServicePage } from '@client/modules/cancellation/signing/SignCancelServicePage';
import * as urls from '@client/routes';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IntelligentGuideDonePageServiceWrapper } from './intelligentGuide/IntelligentGuideDonePageServiceWrapper';
import { IntelligentGuideServicePage } from './intelligentGuide/IntelligentGuideService';

//TODO make lazy loaded
export const CancelServiceRoute: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route
        exact
        path={urls.cancelServiceRoute}
        render={({
          match: {
            params: { merchantId, serviceId },
          },
        }) => {
          return <CancelServiceFormPage merchantId={merchantId} serviceId={serviceId} />;
        }}
      />
      <Route
        exact
        path={urls.signCancelServiceRoute}
        render={({
          match: {
            params: { merchantId, serviceId },
          },
        }) => {
          return <SigningServicePage merchantId={merchantId} serviceId={serviceId} />;
        }}
      />
      <Route
        exact
        path={urls.sentCancelServiceRoute}
        render={({
          match: {
            params: { merchantId, serviceId },
          },
        }) => {
          return <CancelServiceSentPage supplierId={merchantId} serviceId={serviceId} />;
        }}
      />
      <Route
        exact
        path={urls.cancelServiceIntelligentGuidePage}
        render={({
          match: {
            params: { merchantId, serviceId },
          },
        }) => <IntelligentGuideServicePage merchantId={merchantId} serviceId={serviceId} />}
      />
      <Route
        exact
        path={urls.cancelServiceIntelligentGuideDonePage}
        render={({
          match: {
            params: { merchantId, serviceId },
          },
        }) => <IntelligentGuideDonePageServiceWrapper merchantId={merchantId} serviceId={serviceId} />}
      />
    </Switch>
  );
};
