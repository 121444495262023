import { LoadingState } from '@client/models/LoadingState';
import { InsightActions, selectorFetchableInsights } from '@client/modules/InsightsPage/duck';
import { OnboardingStepName } from '@client/modules/InsightsPage/insightCardModels';
import { isOnboardingInsight } from '@client/modules/InsightsPage/utils';
import { updateOnboardingInsight } from '@client/modules/overview-onboarding-cards/OnboardingOverview';
import {
  selectorShowSuccessModalAfterGuide,
  selectorUnresolvedSubscriptions,
  selectorUnresolvedSubscriptionsForGuide,
  updateUnresolvedSubscriptions,
} from '@client/modules/overview/duck';
import type { Subscription, SubscriptionId } from '@client/modules/subscription/types';
import { ResolveSubscriptionsFinishDialog } from '@client/modules/subscription/unresolved-guide/components/ResolveSubscriptionsFinishDialog';
import { getMixpanel } from '@client/tracking/mixpanel';
import {
  EventNames,
  FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
  TrackingPageName,
} from '@client/tracking/mixpanel-constants';
import { useLoggedInUser } from '@client/utils/hooks/use-loggedin-user';
import { useZendesk } from '@client/utils/hooks/use-zendesk';
import { LABEL_FAQ_GENERAL } from '@client/zendesk';
import { FlowPage } from '@minna-technologies/minna-ui/components/flow/FlowPage';
import { FlowTopBar } from '@minna-technologies/minna-ui/components/flow/FlowTopBar';
import clone from 'lodash/clone';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { FlowPageUnresolvedSubscription } from './SubscriptionGuidePage';

export const UnresolvedSubscriptionRoute: React.FunctionComponent = () => {
  useLoggedInUser();
  const openZendesk = useZendesk(LABEL_FAQ_GENERAL);
  const totalUnresolvedSubscriptions = useSelector(selectorUnresolvedSubscriptions);
  const unresolvedSubscriptions = useSelector(selectorUnresolvedSubscriptionsForGuide);
  const insights = useSelector(selectorFetchableInsights);
  const showSuccessAfterGuide = useSelector(selectorShowSuccessModalAfterGuide);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const showSuccessModalAfterGuide = useSelector(selectorShowSuccessModalAfterGuide);
  const history = useHistory();
  const dispatch = useDispatch();
  const path = useRouteMatch()?.path;
  const [deletedSubscriptions, setDeletedSubscriptions] = useState<SubscriptionId[]>([]);
  const [showGuideFinished, setShowGuideFinished] = useState(false);
  const unresolvedSubscriptionRoutes = unresolvedSubscriptions.map((s, index) => {
    const isLastPage = index === unresolvedSubscriptions.length - 1;

    return (
      // For each unresolved subscription, render guide page content
      <Route
        exact
        path={`${path}/${s.id}`}
        key={s.id}
        render={() => (
          <FlowPageUnresolvedSubscription
            unresolvedSubscription={s}
            goBack={goBack}
            updateSubscriptionWithServices={updateSubscriptionWithServices}
            deleteSubscription={setSubscriptionAsDeleted}
            goForward={isLastPage ? closeGuide : goForward} // Close guide if on last page
          />
        )}
      />
    );
  });
  const updateSubscriptionWithServices = (updatedSubscription: Subscription) => {
    const unresolvedSubscriptionsClone = clone(unresolvedSubscriptions) || [];
    const subIndex = unresolvedSubscriptionsClone.findIndex(
      (subscription) => subscription.id === updatedSubscription.id
    );
    const updatedSubscriptions = [
      ...unresolvedSubscriptionsClone.slice(0, subIndex),
      updatedSubscription,
      ...unresolvedSubscriptionsClone.slice(subIndex + 1),
    ];
    dispatch(
      updateUnresolvedSubscriptions({
        showSuccessAfterGuide: showSuccessModalAfterGuide,
        unresolvedSubscriptions: updatedSubscriptions,
      })
    );
  };
  const goBack = () => {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
      Page: TrackingPageName.SELECT_SERVICE,
      Type: 'Contract guide',
      Location: 'Bottom left',
      Button: 'Back',
    });
    let previousValidIndex = currentPageIndex - 1;
    /* eslint-disable  */
    while (previousValidIndex > -1 && deletedSubscriptions.includes(unresolvedSubscriptions[previousValidIndex].id)) {
      previousValidIndex--;
    }
    const goBackPages = previousValidIndex - currentPageIndex;
    setCurrentPageIndex(previousValidIndex);
    history.go(goBackPages);
  };
  const goForward = () => {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
      Page: TrackingPageName.SELECT_SERVICE,
      Type: 'Contract guide',
      Location: 'Bottom right',
      Button: 'Continue',
    });
    let nextValidIndex = currentPageIndex + 1;
    const skippedPages = [];
    while (
      nextValidIndex < unresolvedSubscriptions.length &&
      deletedSubscriptions.includes(unresolvedSubscriptions[nextValidIndex].id)
    ) {
      // create a list of the URLs that ned to be skipped
      skippedPages.push(`${path}/${unresolvedSubscriptions[nextValidIndex].id}`);
      nextValidIndex++;
    }
    if (nextValidIndex < unresolvedSubscriptions.length) {
      const nextPage = `${path}/${unresolvedSubscriptions[nextValidIndex].id}`;
      setCurrentPageIndex(nextValidIndex);
      if (skippedPages.length) {
        // push the urls that need to be skipped into history, so that they will be counted
        // correctly for the Back action
        skippedPages.forEach((url) => history.push(url));
      }
      history.push(nextPage);
    } else {
      closeGuide();
    }
  };
  function closeGuide() {
    if (showSuccessAfterGuide) {
      getMixpanel().track(EventNames.VIEWED_GUIDE, {
        Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
        Page: 'Outro',
        Type: 'Contract guide',
      });
      setShowGuideFinished(true);
    } else {
      history.go(-unresolvedSubscriptions.length);
    }
    updateOnboardingUnresolvedStep();
  }
  function updateOnboardingUnresolvedStep() {
    if (insights.type !== LoadingState.Success) {
      return;
    }
    const maybeOnboardingInsight = insights.data.find(isOnboardingInsight);
    if (!maybeOnboardingInsight || totalUnresolvedSubscriptions.length > 1) {
      return;
    }
    updateOnboardingInsight(maybeOnboardingInsight, OnboardingStepName.ResolveSubscriptions).then(() =>
      dispatch(InsightActions.fetchInsights())
    );
  }
  function setSubscriptionAsDeleted(id: SubscriptionId) {
    setDeletedSubscriptions([...deletedSubscriptions, id]);
  }
  function applyChanges() {
    setShowGuideFinished(false);
    history.go(-unresolvedSubscriptions.length);
  }

  return (
    <Switch>
      <Route path={`${path}`}>
        <FlowPage scrollable>
          <FlowTopBar
            progress={
              (currentPageIndex / (unresolvedSubscriptions.length - deletedSubscriptions.length - 1)) * 100 || 0
            }
            onHelpClick={openZendesk}
          />
          <ResolveSubscriptionsFinishDialog
            open={showGuideFinished}
            subscriptionsInGuide={unresolvedSubscriptions.length}
            onClose={() => {
              applyChanges();
            }}
            onSaveAndContinueToInsightsPage={applyChanges}
          />
          <Switch>{unresolvedSubscriptionRoutes}</Switch>
        </FlowPage>
      </Route>
    </Switch>
  );
};
