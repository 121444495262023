import MissingPageImageUrl from '@client/assets/images/404.svg';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { AppContext } from '@client/modules/app-context';
import { OnboardingType } from '@client/modules/app-context';
import { AppType } from '@client/modules/app-context/constants';
import { withAppContext } from '@client/modules/app-context/react';
import * as urls from '@client/routes';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import React from 'react';
import { Link } from 'react-router-dom';
import { ErrorLoading } from './ErrorLoading';
import styles from './styles.scss';

interface ErrorLoadingPageProps {
  onBackClick(): void;
}

export const ErrorLoadingPage = withLocalization('errorPage/ErrorPage')(({ onBackClick }: ErrorLoadingPageProps) => {
  return (
    <MultiDeviceLayout
      title={<LocalizedMessage id="pageNotLoadable" />}
      footer={<FooterNavigation onBackClick={onBackClick} />}
      hasTopNavigation={false}
    >
      <ErrorLoading />
    </MultiDeviceLayout>
  );
});

interface PageNotFoundPageProps {
  onBackClick(): void;
  isSwedbank?: boolean;
  isLoggedIn: boolean;
}

export const PageNotFoundPage = withLocalization('errorPage/ErrorPage')(
  ({ onBackClick, isSwedbank, isLoggedIn }: PageNotFoundPageProps) => {
    return (
      <MultiDeviceLayout
        title={<LocalizedMessage id="pageNotFound" />}
        footer={<FooterNavigation onBackClick={onBackClick} />}
        hasTopNavigation={false}
      >
        {isSwedbank ? <SwedbankPageNotFound /> : <PageNotFound isLoggedIn={isLoggedIn} />}
      </MultiDeviceLayout>
    );
  }
);

const SwedbankPageNotFound = () => {
  const { colors } = useTheme();
  return (
    <Card className={styles['swb-container']}>
      <Subheading1 color={colors.action.primary} className={styles['swb-subheading']}>
        <LocalizedMessage id="pageNotFound" />
      </Subheading1>
    </Card>
  );
};

interface PageNotFoundProps {
  appContext: AppContext;
  isLoggedIn?: boolean;
}

const useStyles = makeStyles({
  headline: {
    margin: '24px 0 16px',
  },
  card: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  linkTextMargin: {
    margin: '8px 0 16px',
  },
});

const PageNotFound = withAppContext(({ appContext: { appType, locale, features }, isLoggedIn }: PageNotFoundProps) => {
  const { colors } = useTheme();
  const classes = useStyles();

  const linkStyle = {
    color: colors.action.primary,
  };

  return (
    <Card className={classes.card}>
      <img src={MissingPageImageUrl} className={styles['missing-page-image']} />
      <Headline2 align="center" className={classes.headline} data-test="error-component-title">
        <LocalizedMessage id="title" />
      </Headline2>
      <Body align="center">
        <LocalizedMessage id="pageNotFoundMessage" />
      </Body>
      <Body align="center" className={classes.linkTextMargin}>
        <LocalizedMessage id="helpfulLinksText" />
      </Body>
      <div className={styles['link-section']}>
        {features.onboarding !== OnboardingType.CANCELPRODUCT && isLoggedIn && (
          <Body style={{ marginBottom: 8 }}>
            <Link style={linkStyle} to={urls.landingPage}>
              <LocalizedMessage id="startPageText" />
            </Link>
          </Body>
        )}
        {features.helpCenter.enabled && (
          <Body style={{ marginBottom: 8 }}>
            <Link style={linkStyle} to={urls.faqPage(features.helpCenter.zendeskBaseUrl, locale)}>
              <LocalizedMessage id="commonQuestionsText" />
            </Link>
          </Body>
        )}
        {appType !== AppType.SB1 && (
          <>
            <Body style={{ marginBottom: 8 }}>
              <Link style={linkStyle} to={urls.userTermsPage}>
                <LocalizedMessage id="userTermsText" />
              </Link>
            </Body>
            <Body style={{ marginBottom: 8 }}>
              <Link style={linkStyle} to={urls.securityPage}>
                <LocalizedMessage id="securityText" />
              </Link>
            </Body>
          </>
        )}
      </div>
    </Card>
  );
});
