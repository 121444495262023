export type ApplicationName = 'admin' | 'end-user';

/**
 * Build information about the currently running application.
 * No dynamic properties that could change during runtime.
 */
export interface ApplicationInformation {
  /**
   * The current frontend application running.
   * Example values: "admin", "end-user".
   */
  name?: ApplicationName;
}

export const applicationInformation: ApplicationInformation = {};
