// Labels on FAQ articles in Zendesk.
export const LABEL_OPTIMIZE_GENERAL = { labels: ['Förbättra'] };
export const LABEL_OPTIMIZE_MOBILE = { labels: ['Mobil'] };
export const LABEL_OPTIMIZE_FITNESS = { labels: ['Fitness'] };
export const LABEL_CANCELLATIONS = { labels: ['säg-upp'] };
export const LABEL_FAQ_GENERAL = { labels: ['vanliga-frågor'] };
export const LABEL_NEW_MOBILE_SUBSCRIPTION = { labels: ['nyteckning'] };

export interface ZendeskLabel {
  labels: string[];
}
