import { withLocalization } from '@client/internationalization';
//@ts-ignore
import BankIdStatus from '@client/modules/e-signing/bank-id/components/BankIdStatus';
import MultiDeviceLayout from '@client/shared-components/layout/MultiDeviceLayout';
import { darker } from '@client/styles/colors-standard';
import { useBackOrDefault } from '@client/utils/hooks/history';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import type { History } from 'history';
import { IconButton } from 'material-ui';
import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import React, { useCallback, useEffect, useState } from 'react';
import type { BankIdSigningStatus } from './server-api';
import { closeWebSocketConnection, sign } from './server-api';
import styles from './styles.scss';

export interface BankIdSigningProps {
  contractId: string;
  signingId: string;
  resourceToSignId: string;
  onSigningComplete(): void;
  history: History<any>;
}

const BankIdSigningComponent: React.FunctionComponent<BankIdSigningProps> = ({
  contractId,
  signingId,
  resourceToSignId,
  onSigningComplete,
}) => {
  const startSignOrReconnectToSigning = useCallback(() => {
    sign(contractId, signingId, resourceToSignId, setStatus, onSigningComplete);
  }, [contractId, onSigningComplete, resourceToSignId, signingId]);

  const goBack = useBackOrDefault();
  const [status, setStatus] = useState<BankIdSigningStatus>({
    type: 'BankIdSignResourceRequestStarted',
    finished: false,
  });

  useEffect(() => {
    startSignOrReconnectToSigning();

    return closeWebSocketConnection;
  }, [startSignOrReconnectToSigning]);

  return (
    <MultiDeviceLayout>
      <Card data-test="auth-status-page-div">
        <div>
          <IconButton onClick={goBack} iconStyle={{ fill: darker.main }}>
            <ArrowBack />
          </IconButton>
          <div className={styles['bankid-mobile']} />
          <div className={styles['status-message']}>
            <BankIdStatus status={status} onRetryClick={startSignOrReconnectToSigning} />
          </div>
        </div>
      </Card>
    </MultiDeviceLayout>
  );
};

export const BankIdSigning: React.ComponentType<BankIdSigningProps> =
  withLocalization('auth/AuthStatusPage')(BankIdSigningComponent);
