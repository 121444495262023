import Sentry from '@client/assets/js/sentry';
import type { ServiceId, SupplierId, SupplierService } from '@client/models/supplier';
import type { User } from '@client/models/user-models';
import type { AppType, Market } from '@client/modules/app-context/constants';
import { improvableContracts, onlySubscriptionsWithActiveContracts } from '@client/modules/subscription';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import { isWithinBindingPeriod } from '@client/modules/subscription/types';
import { viewDashboardTrackingEvent } from '@client/tracking/events';
import {
  TrackingButtonLocation,
  SUBSCRIPTION_PROTECTION,
  TrackingSubscriptionMethod,
  TrackingButtonName,
  TrackingFeedbackType,
} from '@client/tracking/mixpanel-constants';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import has from 'lodash/has';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import join from 'lodash/join';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import type { CategoryName } from '../constants/categories';
import { LOAN_CATEGORIES_CODES } from '../constants/categories';
import { formatToIsoDate } from '../utils/date';
import { getMixpanel } from './mixpanel';
import {
  CATEGORY_ELECTRICITY,
  EventNames,
  TrackingFeature,
  FEATURE_INDEPENDENT_ORDER,
  FEATURE_ONBOARDING,
  FEATURE_OVERVIEW,
  FEATURE_SETTINGS,
  OUTCOME_FAILED,
  TrackingPageName,
} from './mixpanel-constants';
import { subscriptionAndContractProperties } from './mixpanel-event-properties';
import { sendButtonClickedEvent, sendViewedPageEvent } from './mixpanel-tracking-events';
import { CancellationMethodType } from '@client/models/service';

export function finishedOnboardingMixpanelEvent(page: TrackingPageName, market: Market, appType: AppType) {
  sendButtonClickedEvent(FEATURE_ONBOARDING, page, 'Finish onboarding', {
    Market: market,
    Platform: appType,
    Location: TrackingButtonLocation.BOTTOM_CENTER,
  });
}

export function closedOnboardingMixpanelEvent(page: TrackingPageName, market: Market, appType: AppType) {
  sendButtonClickedEvent(FEATURE_ONBOARDING, page, 'Close onboarding', {
    Market: market,
    Platform: appType,
    Location: TrackingButtonLocation.BOTTOM_CENTER,
  });
}

export function viewedOnboardingMixpanelEvent(page: TrackingPageName) {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: FEATURE_ONBOARDING,
    Page: page,
  });
}

export function clickedDisplayPriceInfoDialogMixpanelEvent(category: string, button: string) {
  sendButtonClickedEvent(FEATURE_INDEPENDENT_ORDER, 'Price info', button, {
    Category: category,
  });
}

export function clickedShowSupplierInfoMixpanelEvent(category: string, merchantName: string, page: string) {
  sendButtonClickedEvent(FEATURE_INDEPENDENT_ORDER, page, 'Expand supplier info', {
    Category: category,
    'New supplier': merchantName,
  });
}

export function clickedCancellationConfirmationInsuranceDialogButtonMixpanelEvent(buttonName: string) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Button: buttonName,
    Dialog: 'Cancel insurance confirmation',
  });
}

export function pressedCancelContractButtonMixpanelEvent(
  subscription: Subscription,
  contract: Contract,
  button: string
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Button: button,
    ...subscriptionAndContractProperties(subscription, contract),
  });
}

export function clickedSubscriptionOnDashboardMixpanelEvent(subscription: Subscription) {
  getMixpanel().track('Clicked on subscription', {
    Feature: FEATURE_OVERVIEW,
    'Supplier id': get(subscription, 'supplier.id'),
    'Supplier name': get(subscription, 'supplier.name'),
    'Subscription source': get(subscription, 'source'),
    ...subscriptionUserMessage(subscription),
  });
}

export function ratedOrderExperienceMixpanelEvent(
  rating: number,
  subscription?: Subscription,
  contract?: Contract,
  productRank?: number | string,
  personalRecommendation: boolean = false
) {
  getMixpanel().track(EventNames.RATED_EXPERIENCE, {
    ...subscriptionAndContractProperties(subscription, contract),
    Feature: FEATURE_INDEPENDENT_ORDER,
    'Feedback type': TrackingFeedbackType.Guide,
    'mp_.rating': rating,
    'Personal recommendations': personalRecommendation,
    'Product rank': productRank,
  });
}

export function trackRatedCancelExperience(
  pageName: TrackingPageName,
  rating: number,
  subscription: Subscription,
  amountSavedPerYear?: number,
  contract?: Contract
) {
  getMixpanel().track(EventNames.RATED_EXPERIENCE, {
    ...subscriptionAndContractProperties(subscription, contract),
    Feature: TrackingFeature.Cancellation,
    Method: methodToTrackingSubscriptionMethod(contract?.cancellationMethod?.type),
    Page: pageName,
    Category: contract?.service.category.name ?? 'category not found',
    'mp_.rating': rating,
    'Feedback type': TrackingFeedbackType.Guide,
    'Amount saved per year': amountSavedPerYear,
  });
}

export function ratedPauseExperienceMixpanelEvent(rating: number, subscription: Subscription, contract: Contract) {
  getMixpanel().track(EventNames.RATED_EXPERIENCE, {
    ...subscriptionAndContractProperties(subscription, contract),
    Feature: TrackingFeature.Pause,
    'mp_.rating': rating,
    'Feedback type': TrackingFeedbackType.Completion,
  });
}

export function ratedResumeExperienceMixpanelEvent(rating: number, subscription: Subscription, contract: Contract) {
  getMixpanel().track(EventNames.RATED_EXPERIENCE, {
    ...subscriptionAndContractProperties(subscription, contract),
    Feature: TrackingFeature.Resume,
    'mp_.rating': rating,
    'Feedback type': TrackingFeedbackType.Completion,
  });
}

export function viewProductMixpanelEvent(
  subscription: Subscription | undefined,
  contract: Contract | undefined,
  productName: string,
  merchantName: string,
  amountSavedFirstYear?: number,
  productRank?: number | string,
  isRecommendation = false
) {
  getMixpanel().track('Selected product', {
    Page: TrackingPageName.RESULT_PAGE,
    ...handleOrder(subscription, contract),
    'New supplier': merchantName,
    'Product name': productName,
    'Product rank': productRank,
    'Amount saved per year': amountSavedFirstYear,
    'Recommended product': isRecommendation,
  });
}

export function viewContractDetailsPageMixpanelEvent(
  subscription: Subscription,
  contract: Contract,
  monthlyCost: number | undefined
) {
  sendViewedPageEvent(FEATURE_OVERVIEW, 'Contract details', {
    Category: getCategoryCodeForSending(get(contract, 'service.category.name')),
    'Supplier name': get(subscription, 'supplier.name'),
    'Binding time set': isWithinBindingPeriod(contract),
    ...(monthlyCost && { 'Monthly Cost': monthlyCost }),
  });
}

export function viewOptimizationOtherAlternativesPageEvent() {
  getMixpanel().track(EventNames.VIEWED_GUIDE, {
    Feature: FEATURE_INDEPENDENT_ORDER,
    Page: 'Other alternatives',
  });
}

export function enterOrderFormPageMixpanelEvent(
  productName: string,
  subscription?: Subscription,
  contract?: Contract,
  inFlow = true,
  productRank?: string | number
) {
  getMixpanel().track(EventNames.VIEWED_GUIDE, {
    Page: 'Order details',
    'Product rank': productRank,
    'Recommended product': productRank === 'Recommendation',
    'In flow': inFlow,
    'Product name': productName,
    ...handleOrder(subscription, contract),
  });
}

export const handleOrder = (subscription?: Subscription, contract?: Contract) => {
  const independentOrder = !subscription;
  const copySubscriptionAndContractProperties = JSON.parse(
    JSON.stringify(subscriptionAndContractProperties(subscription, contract))
  );
  delete {
    ...copySubscriptionAndContractProperties,
    ['Old supplier']: copySubscriptionAndContractProperties['Supplier name'],
  }['Supplier name'];
  if (independentOrder) {
    return { Feature: FEATURE_INDEPENDENT_ORDER };
  } else {
    const category = contract ? { Category: normalizeCategoryName(contract.service.category.name) } : {};

    return {
      Feature: FEATURE_INDEPENDENT_ORDER,
      ...copySubscriptionAndContractProperties,
      ...category,
    };
  }
};

export function enterRecommendationPageMixpanelEvent(subscription?: Subscription, contract?: Contract) {
  getMixpanel().track(EventNames.VIEWED_GUIDE, {
    Page: TrackingPageName.RECOMMENDATION,
    ...handleOrder(subscription, contract),
  });
}

export function onClickNextButtonElectricityGuide(page: string, specProperty: any) {
  sendButtonClickedEvent(FEATURE_INDEPENDENT_ORDER, page, 'Next', {
    Category: CATEGORY_ELECTRICITY,
    ...specProperty,
  });
}

export function viewedCancellationInstructionsPageMixpanelEvent(subscription: Subscription, contract: Contract) {
  getMixpanel().track(EventNames.VIEWED_GUIDE, {
    Feature: TrackingFeature.Cancellation,
    Page: 'Cancellation instructions',
    ...subscriptionAndContractProperties(subscription, contract),
  });
}

export function requestedOptimizationOfContractMixpanelEvent(subscription: Subscription, contract: Contract) {
  const copySubscriptionAndContractProperties = JSON.parse(
    JSON.stringify(subscriptionAndContractProperties(subscription, contract))
  );
  delete {
    ...copySubscriptionAndContractProperties,
    ['Old supplier']: copySubscriptionAndContractProperties['Supplier name'],
  }['Supplier name'];
  getMixpanel().track(EventNames.ENTERED_GUIDE, {
    Feature: FEATURE_INDEPENDENT_ORDER,
    ...copySubscriptionAndContractProperties,
    Category: normalizeCategoryName(contract.service.category.name),
  });
}

export function requestedLoaPreviewMixpanelEvent(subscription: Subscription, contract: Contract) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    ...subscriptionAndContractProperties(subscription, contract),
    Feature: TrackingFeature.Cancellation,
    Page: TrackingPageName.PREVIEW_OF_LOA,
    Button: TrackingButtonName.PREVIEW_OF_LOA,
  });
}

export function requestedLoaPreviewForServiceMixpanelEvent(merchantId: SupplierId, serviceId: ServiceId) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    'Supplier Id': merchantId,
    'Service id': serviceId,
    Feature: TrackingFeature.Cancellation,
    Page: TrackingPageName.PREVIEW_OF_LOA,
    Button: TrackingButtonName.PREVIEW_OF_LOA,
  });
}

export function viewSwedbankNotificationSettingsPageMixpanelEvent() {
  getMixpanel().track('Viewed settings', {
    Feature: FEATURE_SETTINGS,
    Page: 'Notifications',
  });
}

export function viewPolicyMixpanelEvent(page: string, updatedDate: string) {
  getMixpanel().track('Viewed policy', {
    Feature: FEATURE_SETTINGS,
    Page: page,
    Version: updatedDate,
  });
}

export function viewDashboardMixpanelEvent(noOfActiveSubscriptions: number, noOfUnexploredImprovableContracts: number) {
  getMixpanel().track('Viewed overview', {
    Feature: FEATURE_OVERVIEW,
    Type: 'Main',
    'Active subscriptions': noOfActiveSubscriptions,
    'Unreviewed improvable contracts': noOfUnexploredImprovableContracts,
  });
}

export function trackViewedCancellationFormPage(subscription: Subscription, contract: Contract) {
  getMixpanel().track(EventNames.VIEWED_FORM, {
    Feature: TrackingFeature.Cancellation,
    Page: TrackingPageName.DETAILS,
    ...subscriptionAndContractProperties(subscription, contract),
  });
}

export const methodToTrackingSubscriptionMethod = (
  method: CancellationMethodType | undefined
): TrackingSubscriptionMethod | string => {
  switch (method) {
    case CancellationMethodType.IntelligentGuide:
      return TrackingSubscriptionMethod.IntelligentGuide;
    case CancellationMethodType.MerchantAPI:
      return TrackingSubscriptionMethod.MerchantApi;
    case CancellationMethodType.PowerOfAttorney:
      return TrackingSubscriptionMethod.MerchantOutreach;
    default:
      return 'method not found';
  }
};

export function trackViewedCancelServiceForm(
  merchantId: string,
  merchantName: string,
  serviceId: string,
  method?: CancellationMethodType,
  serviceName?: string,
  category?: string
) {
  getMixpanel().track(EventNames.VIEWED_FORM, {
    Feature: TrackingFeature.Cancellation,
    Method: method ? methodToTrackingSubscriptionMethod(method) : 'method not found',
    Page: 'CancelServiceForm',
    'Supplier Id': merchantId,
    'Supplier name': merchantName,
    Category: category,
    'Service id': serviceId,
    'Service name': serviceName,
  });
}

export function trackViewedSuccessScreen(
  merchantName: string,
  merchantId: string,
  method: CancellationMethodType,
  serviceName?: string,
  serviceId?: string,
  category?: string,
  contractId?: string,
  subscriptionSource?: string
) {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: TrackingFeature.Cancellation,
    Method: methodToTrackingSubscriptionMethod(method),
    Page: TrackingPageName.SUCCESS_SCREEN,
    Category: category,
    'Supplier name': merchantName,
    'Supplier id': merchantId,
    'Service name': serviceName,
    'Service id': serviceId,
    'Contract id': contractId,
    'Subscription source': subscriptionSource,
  });
}

export function trackClickedCancelSuccessCloseButton(
  merchantName: string,
  merchantId: string,
  method: CancellationMethodType,
  serviceName?: string,
  serviceId?: string,
  category?: string,
  amountSavedPerYear?: number
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Method: methodToTrackingSubscriptionMethod(method),
    Page: TrackingPageName.SUCCESS_SCREEN,
    Button: TrackingButtonName.CLOSE,
    Category: category,
    'Supplier name': merchantName,
    'Supplier id': merchantId,
    'Service name': serviceName,
    'Service id': serviceId,
    'Amount saved per year': amountSavedPerYear,
  });
}

export function sentCancellationOfContractFinishMixpanelEvent(
  subscription: Subscription,
  amountSavedPerYear: number,
  contract?: Contract
) {
  getMixpanel().track(EventNames.SUBMITTED_FORM, {
    Feature: TrackingFeature.Cancellation,
    ...subscriptionAndContractProperties(subscription, contract),
    'Amount saved per year': amountSavedPerYear,
  });
}

export function sentTerminationOfContractFailedMixpanelEvent(subscription: Subscription, contract?: Contract) {
  getMixpanel().track(EventNames.SUBMITTED_FORM, {
    ...subscriptionAndContractProperties(subscription, contract),
    Feature: TrackingFeature.Cancellation,
    Page: 'Cancellation form',
    Outcome: OUTCOME_FAILED,
  });
}

export function showingPushNotificationPrePermissionMixpanelEvent() {
  getMixpanel().track('Viewed permissions', {
    Feature: TrackingFeature.Cancellation,
    Medium: 'Push',
    Type: 'Pre',
    Page: TrackingPageName.CANCELLATION_SENT,
  });
}

export function clickedAllowOnPushNotificationPrePermissionMixpanelEvent() {
  getMixpanel().track('Notification permissions', {
    Feature: TrackingFeature.Cancellation,
    Medium: 'Push',
    Type: 'Pre',
    Page: TrackingPageName.CANCELLATION_SENT,
    Outcome: 'Allowed',
  });
}

export function clickedNotNowOnPushNotificationPrePermissionMixpanelEvent() {
  getMixpanel().track('Notification permissions', {
    Feature: TrackingFeature.Cancellation,
    Medium: 'Push',
    Type: 'Pre',
    Page: TrackingPageName.CANCELLATION_SENT,
    Outcome: 'Not now',
  });
}

export function abortedElectricityTerminationFromDialogMixpanelEvent(contract?: Contract, service?: SupplierService) {
  getMixpanel().track('Dialog dismissed', {
    Feature: TrackingFeature.Cancellation,
    Page: 'Contract info',
    Category: get(contract, 'service.category.name'),
    'Contract id': contract?.id,
    'Service id': get(contract, 'service.id', service?.id),
    'Service name': get(contract, 'service.name', service?.name),
  });
}

export function createdSubscriptionManuallyMixpanelEvent(subscription: Subscription, maybeCategory?: string) {
  getMixpanel().track('Subscription created', {
    Feature: FEATURE_OVERVIEW,
    Type: 'Manual',
    Page: 'Add subscription details',
    'Supplier id': get(subscription, 'supplier.id'),
    'Supplier name': get(subscription, 'supplier.name'),
    Cost: get(subscription, 'cost.amount'),
    Interval: `${get(subscription, 'paymentInterval.amount')} ${get(subscription, 'paymentInterval.unit')}`,
    Category: maybeCategory,
  });
}

export function updatedSubscriptionMixpanelEvent(
  oldSubscription: Subscription,
  updatedSubscription: Subscription,
  extra: any = {}
) {
  getMixpanel().track('Subscription changed', {
    'Subscription id': updatedSubscription.id,
    'Supplier id': get(updatedSubscription, 'supplier.id'),
    'Supplier name': get(updatedSubscription, 'supplier.name'),
    'Old cost': get(oldSubscription, 'cost.amount'),
    'New cost': get(updatedSubscription, 'cost.amount'),
    'Old payment interval': `${get(oldSubscription, 'paymentInterval.amount')} ${get(
      oldSubscription,
      'paymentInterval.unit'
    )}`,
    'New payment interval': `${get(updatedSubscription, 'paymentInterval.amount')} ${get(
      updatedSubscription,
      'paymentInterval.unit'
    )}`,
    ...extra,
  });
}

export function deletedSubscriptionMixpanelEvent(subscription: Subscription, reason: string) {
  getMixpanel().track('Subscription deleted', {
    Feature: FEATURE_OVERVIEW,
    'Supplier id': get(subscription, 'supplier.id'),
    'Supplier name': get(subscription, 'supplier.name'),
    Reason: reason,
    'Service Provider': subscription.supplier.name,
  });
}

export function unsubscribeEmailMixpanelEvent(page: string) {
  getMixpanel().track('Notification changed ', {
    Outcome: 'Unsubscribed',
    Medium: 'Email',
    Page: page,
  });
}

export function swedbankUserChangedContractChangeEmailSettingsMixpanelEvent(enabled: boolean) {
  getMixpanel().track('Notification changed', {
    Feature: FEATURE_SETTINGS,
    Medium: 'Email',
    'Contract change': enabled,
  });
}

export function viewedExperimentMixpanelEvent(variantNumber: string) {
  getMixpanel().track('Viewed experiment', {
    Variant: variantNumber,
  });
}

export function viewedAddManualSubscriptionPageMixpanelEvent(maybeCategory?: CategoryName) {
  getMixpanel().track(EventNames.VIEWED_GUIDE, {
    Feature: FEATURE_OVERVIEW,
    Page: 'Add manual subscription',
    Category: maybeCategory,
  });
}

export function viewedManualSubscriptionPricingPageMixpanelEvent(maybeCategory?: CategoryName) {
  getMixpanel().track(EventNames.VIEWED_GUIDE, {
    Feature: FEATURE_OVERVIEW,
    Page: 'Add subscription details',
    Category: maybeCategory,
  });
}

function subscriptionUserMessage(subscription: Subscription) {
  const userMessageProps = get(subscription, 'userMessage.message');
  const mixPanelUserMessage: any = {};
  const userMessageKeyPrefix = 'User Message ';
  forOwn(userMessageProps, (value, key) => {
    if (isObject(value)) {
      // Currently only message that is an object is currency, produces output: 123 SEK
      mixPanelUserMessage[userMessageKeyPrefix + key] = join(flatMap(value), ' ');
    } else if (key === 'expiresAt' || key === 'endsAt') {
      mixPanelUserMessage[userMessageKeyPrefix + key] = formatToIsoDate(value);
    } else {
      mixPanelUserMessage[userMessageKeyPrefix + key] = value;
    }
  });
  mixPanelUserMessage[`${userMessageKeyPrefix} isTemporaryMessage`] = has(userMessageProps, 'expiresAt');

  return mixPanelUserMessage;
}

export function getCategoryCodeForSending(categoryName: string) {
  return LOAN_CATEGORIES_CODES[categoryName]
    ? LOAN_CATEGORIES_CODES[categoryName]
    : normalizeCategoryName(categoryName);
}

export function normalizeCategoryName(categoryName: string) {
  if (categoryName === undefined) {
    Sentry.captureMessage('Missing category name in normalizeCategoryName when sending mixpanel event');

    return 'Unknown';
  }

  return categoryName.charAt(0).toUpperCase() + categoryName.slice(1);
}

export function clickedComplexContractGuideMixpanelEvent(
  page: string,
  totalSubscriptions: number,
  location: TrackingButtonLocation,
  button: string,
  merchantName?: string,
  merchantId?: string,
  currentSubscription?: number,
  value?: any
) {
  const props = omitBy(
    {
      Feature: FEATURE_ONBOARDING,
      Type: 'Contract guide',
      Page: page,
      'Number of unanswered subscriptions in guide': totalSubscriptions,
      'Current unanswered subscription': currentSubscription,
      Supplier: merchantName,
      'Supplier id': merchantId,
      Value: value,
      Location: location,
      Button: button,
    },
    isNil
  );
  getMixpanel().track(EventNames.CLICKED_BUTTON, props);
}

export function viewSubscriptionProtectionPageEvent() {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: SUBSCRIPTION_PROTECTION,
    Page: TrackingPageName.INSIGHTS,
  });
}

export function clickedSubscriptionProtectionPageEvent(buttonName: string, location: TrackingButtonLocation) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: SUBSCRIPTION_PROTECTION,
    Page: TrackingPageName.INSIGHTS,
    Button: buttonName,
    Location: location,
  });
}

export function trackDifferentTypesOfContracts(user: User, subscriptions: Subscription[]) {
  const contractsExplored = map(get(user, 'visitedPages.contractImprovementsExplored', []), 'contractId');
  const isContractUnexplored = (contract: Contract) => !contractsExplored.includes(contract.id);
  const unexploredImprovableContracts = filter(improvableContracts(subscriptions), isContractUnexplored);
  viewDashboardTrackingEvent(
    onlySubscriptionsWithActiveContracts(subscriptions).length,
    unexploredImprovableContracts.length
  );
}

export function trackOnboardingGuide(pageName: TrackingPageName) {
  getMixpanel().track(EventNames.VIEWED_GUIDE, {
    Feature: FEATURE_ONBOARDING,
    Page: pageName,
  });
}

export function trackClicksOnOnboardingGuide(pageName: TrackingPageName, buttonName: string) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: FEATURE_ONBOARDING,
    Page: pageName,
    Button: buttonName,
    Location: TrackingButtonLocation.BOTTOM,
  });
}

export function trackViewedPage(Feature: string, Page: string) {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature,
    Page,
  });
}

export const trackClickedButton = (
  Feature: string,
  Page: string,
  Button: string,
  Location?: TrackingButtonLocation
): void => {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature,
    Page,
    Button,
    Location,
  });
};

export const trackClickGoToOverview = (Feature: string, Page: string, Location: TrackingButtonLocation): void => {
  trackClickedButton(Feature, Page, 'Go to overview', Location);
};
