import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { FeedbackCarouselCardProps } from '@client/modules/nps-csat-module/component';
import { cardStyles } from '@client/modules/nps-csat-module/component';
import {
  FeedbackButtons,
  feedbackCardButtonClicked,
  FeedbackCards,
  feedbackCardViewed,
  trackNpsScore,
} from '@client/modules/nps-csat-module/mixpanel-events';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { CarouselCard } from '@minna-technologies/minna-ui/components/CarouselCard';
import { NetPromoterScoreWidget } from '@minna-technologies/minna-ui/components/NetPromoterScoreWidget';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { CloseIcon } from '@minna-technologies/minna-ui/icons/Close';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
  npsSpacing: {
    marginTop: '40px',
  },
  nextButton: {
    position: 'absolute',
    width: '100%',
    padding: '16px',
    bottom: 0,
    left: 0,
  },
}));

export const Component: React.FunctionComponent<FeedbackCarouselCardProps> = ({
  onNext,
  onClose,
  opacity,
  fullHeight,
}) => {
  const styles = useStyles();
  const globalClasses = cardStyles();
  const { colors } = useTheme();
  const [rating, setRating] = useState<number | undefined>(undefined);

  const onRatingChange = (newRating: number | undefined) => {
    if (newRating != undefined) {
      setRating(newRating);
    }
  };
  const closeFromNps = () => {
    feedbackCardButtonClicked(FeedbackButtons.CLOSEX, 'NPS rating');
    onClose();
  };
  const continueToThankYou = () => {
    feedbackCardViewed(FeedbackCards.FEEDBACK);
    // rating can be 0, so we need to check for null specifically
    if (rating !== undefined) trackNpsScore(rating);
    feedbackCardButtonClicked(FeedbackButtons.CONTINUE, 'NPS rating');
    onNext();
  };

  return (
    <CarouselCard fullHeight={fullHeight} opacity={opacity}>
      <div className={globalClasses.centeredContainer}>
        <Title color={colors.action.primary}>
          <LocalizedMessage id={'title'} />
        </Title>
      </div>
      <div className={globalClasses.closeButton}>
        <CloseIcon onClick={closeFromNps} />
      </div>
      <div className={styles.npsSpacing}>
        <NetPromoterScoreWidget
          description={<LocalizedMessage id={'npsScore'} />}
          minRatingLabel={<LocalizedMessage id={'npsScoreNotLikely'} />}
          maxRatingLabel={<LocalizedMessage id={'npsScoreMostLikely'} />}
          currentRating={rating}
          onRatingChange={onRatingChange}
        />
      </div>
      <div className={styles.nextButton}>
        <PrimaryButton
          disabled={rating === undefined}
          onClick={continueToThankYou}
          fullWidth
          label={<LocalizedMessage id={'nextButtonLabel'} />}
        />
      </div>
    </CarouselCard>
  );
};

export const FeedbackNpsRatingCard = withLocalization('feedback-modal')(Component);
