import type { AppType } from '@client/modules/app-context/constants';
import { ConsentPageWrapper } from '@client/modules/onboarding/OnboardingPsd2/consent/ConsentPageWrapper';
import { LocalizedMessage } from '@client/internationalization';
import { subscriptionHub } from '@client/routes';
import useTheme from '@material-ui/core/styles/useTheme';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { HistoryIcon } from '@minna-technologies/minna-ui/icons/History';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { cancelOnboardingConsentPrimer, continueOnboardingConsentPrimer, sendViewedConsentPrimer } from './mixpanel';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { AppContextActions } from '@client/modules/app-context/duck';
import { updateFeatureOverrides } from '@client/models/user';
import { Sentry } from '@client/assets/js/sentry';
import type { Market } from '@client/modules/app-context/constants';
import { fetchAppContext } from '@client/modules/app-context/app-context';
import { prepareAppContextWithDisplayName } from '@client/modules/app-context/utils';

interface ConsentPageOPProps {
  onContinueToConsentClick(): void;

  appType: AppType;
  market: Market;
}

export const ConsentPageOP = ({ onContinueToConsentClick, appType, market }: ConsentPageOPProps) => {
  const { colors } = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const useStyles = makeStyles(() => ({
    card: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '16px',
      marginTop: '16px',
      flexShrink: 0,
      width: '100%',
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    iconWrapper: {
      flex: 0,
      marginRight: '16px',
      alignSelf: 'flex-start',
      boxSizing: 'content-box',
      height: '24px',
      width: '24px',
    },
    contentWrapper: {
      textAlign: 'left',
      paddingLeft: '40px',
    },
    primaryButton: {
      marginBottom: '24px',
    },
    buttons: {
      marginTop: 'auto',
      marginBottom: '24px',
    },
  }));

  const onContinueClick = () => {
    continueOnboardingConsentPrimer();
    onContinueToConsentClick();
  };

  const onCancelClick = async () => {
    await disableIdentify();
    cancelOnboardingConsentPrimer();
    // Updating feature toggles since they change when we downgrade user.
    const updatedAppContext = await fetchAppContext();
    const appContext = prepareAppContextWithDisplayName(updatedAppContext);
    dispatch(AppContextActions.initialize(appContext));
    history.push(subscriptionHub);
  };

  const disableIdentify = async () => {
    try {
      return await updateFeatureOverrides({ identify: false });
    } catch (error) {
      Sentry.captureExceptionWithMessage(
        error,
        `Failed to update user feature overrides for market: ${market} and  appType: ${appType} `
      );
      throw error;
    }
  };

  const styles = useStyles();

  return (
    <ConsentPageWrapper appType={appType} trackViewedPage={() => sendViewedConsentPrimer()}>
      <div>
        <Headline2 style={{ margin: '0 0 8px' }} data-test={'headline-consent-expired'}>
          <LocalizedMessage id="headline" />
        </Headline2>
        <Body color={colors.textOn.surfaceSubdued} style={{ margin: '16px 0' }}>
          <LocalizedMessage id="descriptionOne" />
        </Body>
        <Body color={colors.textOn.surfaceSubdued} style={{ margin: '16px 0' }}>
          <LocalizedMessage id="descriptionTwo" />
        </Body>
      </div>
      <div>
        <Card className={styles.card} data-test={'card-two'}>
          <div className={styles.cardHeader}>
            <div className={styles.iconWrapper}>
              <HistoryIcon nativeColor={colors.action.primary} />
            </div>
            <Body variant="bold">
              <LocalizedMessage id="transactionHistoryHeadline" />
            </Body>
          </div>
          <div className={styles.contentWrapper}>
            <Caption style={{ color: colors.textOn.surfaceSubdued }}>
              <LocalizedMessage id="transactionHistoryDescription1" />
            </Caption>
          </div>
        </Card>
      </div>
      <div className={styles.buttons}>
        <div className={styles.primaryButton}>
          <PrimaryButton
            fullWidth
            label={<LocalizedMessage id="renewConsentLabel" />}
            onClick={onContinueClick}
            data-test={'continue-button'}
          />
        </div>
        <div>
          <SecondaryButton
            fullWidth
            label={<LocalizedMessage id="cancelLabel" />}
            onClick={onCancelClick}
            data-test={'cancel-button'}
          />
        </div>
      </div>
    </ConsentPageWrapper>
  );
};
