import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { Question, QuestionId } from '@client/models/cancellation-questions';
import type { Locale, Market } from '@client/modules/app-context/constants';
import { CancellationQuestionComponent } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/CancellationQuestion';
import type { AddressInformation } from '@client/modules/cancellation/CancellationFormPage/models';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardHeader } from '@minna-technologies/minna-ui/components/Card/CardHeader';
import map from 'lodash/map';
import React from 'react';

const CancellationQuestionsSectionInner: React.FunctionComponent<CancellationQuestionsSectionProps> = ({
  cancellationQuestions,
  terminationAnswers,
  onSetAnswer,
  formSubmitted,
  userLocale,
  market,
  addressOnUser,
  watchRecipientQuestion,
  watchNestedQuestion,
}) => {
  const cancellationQuestionComponents = map(cancellationQuestions, (cancellationQuestion) => {
    return (
      <CancellationQuestionComponent
        key={cancellationQuestion.id}
        type={cancellationQuestion.type}
        onSetAnswer={onSetAnswer}
        question={cancellationQuestion}
        answers={terminationAnswers}
        formSubmitted={formSubmitted}
        locale={userLocale}
        market={market}
        addressOnUser={addressOnUser}
        watchRecipientQuestion={watchRecipientQuestion}
        watchNestedQuestion={watchNestedQuestion}
      />
    );
  });

  return (
    <Card>
      <CardHeader title={<LocalizedMessage id="terminationQuestionsTitle" />} />
      {cancellationQuestionComponents}
    </Card>
  );
};

interface CancellationQuestionsSectionProps {
  cancellationQuestions: Question[];
  terminationAnswers: any;
  onSetAnswer(questionId: string, value: any): void;
  formSubmitted: boolean;
  userLocale: Locale;
  market: Market;
  addressOnUser: AddressInformation;
  watchRecipientQuestion(questionId: QuestionId): any;
  watchNestedQuestion(questionId: QuestionId): any;
}

export const CancellationQuestionsSection = withLocalization('termination/TerminationFormPage')(
  CancellationQuestionsSectionInner
);
