import Sentry from '@client/assets/js/sentry';
import {
  selectorViewedCancelProductOnboarding,
  setUserVisitedPage,
  VIEWED_CANCEL_PRODUCT_ONBOARDING_AT,
} from '@client/ducks/user';
import { saveUserViewedOnboardCancelProduct } from '@client/models/user';
import { selectorAppType, selectorMarket } from '@client/modules/app-context/duck';
import { OnboardingCancelProductComponent } from '@client/modules/auth/OnboardingCancelProduct/component';
import moment from 'moment';
import type React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { compose } from 'redux';

const mapStateToProps = (state: any) => ({
  onboardingCancelProductShown: selectorViewedCancelProductOnboarding(state),
  market: selectorMarket(state),
  appType: selectorAppType(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onOnboardingCancelProductShownAction: () => {
    dispatch(setUserVisitedPage(VIEWED_CANCEL_PRODUCT_ONBOARDING_AT, moment().toString()));
    saveUserViewedOnboardCancelProduct().catch((error: Error) => {
      Sentry.captureExceptionWithMessage(error, 'Failed to update visited onboarding cancel product on user model');
    });
  },
});

export const OnboardingCancelProduct = compose<React.FunctionComponent>(connect(mapStateToProps, mapDispatchToProps))(
  OnboardingCancelProductComponent
);
