// @ts-ignore js import, remove this when the import is typed
import { loadingPageWhileLoading, onEnter } from '@client/containers/container-helpers/index';
import { withAppContext } from '@client/modules/app-context/react';
import * as urls from '@client/routes';
import type * as React from 'react';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import type { Dispatch } from 'redux';
// @ts-ignore js import, remove this when the import is typed
import AuthStatusPage from '../../../auth/AuthStatusPage';
import type { ContractId, SubscriptionId } from '../../../subscription/types';
import { CancellationAction } from '../../duck';
import { doReconnection } from '../../websocketHelpers';
import { cancellationSignCompleteCallback, onTerminationSignFailed } from '../common';

interface ContainerProps {
  subscriptionId: SubscriptionId;
  contractId: ContractId;
}

interface StateProps {
  status: any;
}

const mapStateToProps = (state: any): StateProps => ({
  status: state.cancellation.status,
});

type ConnectOwnProps = ContainerProps & RouteComponentProps;

interface DispatchProps {
  onReconnect(): void;
  onBackClick(): void;
  onRetryClick(): void;
  onBackToContractDetail(): void;
}

type BankIdSigningPageContainerProps = StateProps & DispatchProps & ConnectOwnProps;

const mapDispatchToProps = (
  dispatch: Dispatch,
  { history, subscriptionId, contractId }: ConnectOwnProps
): DispatchProps => ({
  onReconnect: () => {
    const onTerminationSignCompleted = cancellationSignCompleteCallback(dispatch, history);
    const onNoSession = () => {
      history.replace(urls.terminationFormPage(subscriptionId, contractId));
    };

    dispatch<any>(doReconnection(contractId, onTerminationSignCompleted, onTerminationSignFailed, onNoSession));
  },
  onBackClick: () => {
    dispatch(CancellationAction.cancel());
    history.replace(urls.terminationFormPage(subscriptionId, contractId));
  },
  onRetryClick: () => {
    dispatch(CancellationAction.cancel());
    history.push(urls.terminationFormPage(subscriptionId, contractId));
  },
  onBackToContractDetail: () => {
    dispatch(CancellationAction.cancel());
    history.replace(urls.contractDetailsPage(subscriptionId, contractId));
  },
});

export const BankIdSigningPageContainer: React.ComponentClass<ContainerProps> = compose<any, ContainerProps>(
  withRouter,
  withAppContext,
  withProps({ mobileHeight: 'full-with-no-header' }),
  connect(mapStateToProps, mapDispatchToProps),
  onEnter(({ status, onReconnect }: BankIdSigningPageContainerProps) => {
    if (!status) {
      onReconnect();
    }
  }),
  loadingPageWhileLoading((props: BankIdSigningPageContainerProps) => !props.status)
)(AuthStatusPage);
