import get from 'lodash/get';
import type { Contract, Subscription } from '../modules/subscription/types';
import { trackSwedbankAnalyticsEvent } from './swedbank-analytics';

export const viewDashboardAnalyticsEvent = () => {
  const event = { action: 'viewed-overview' };
  trackSwedbankAnalyticsEvent(event);
};

export const viewDiscoverAnalyticsEvent = () => {
  const event = { action: 'viewed-discover-page' };
  trackSwedbankAnalyticsEvent(event);
};

export const userRequestedIndependentOrderAnalyticsEvent = (vertical: string, newSupplierName: string) => {
  // TODO we don't have amountSavedPerYear
  const feature = 'independent';
  const event = { action: 'order-sent', vertical, feature, supplierName: newSupplierName };
  trackSwedbankAnalyticsEvent(event);
};

export const userRequestedCancellationAnalyticsEvent = (
  subscription: Subscription,
  amountSavedPerYear: number,
  contract?: Contract
) => {
  const event = {
    action: 'cancellation-sent',
    ...subscriptionProperties(subscription),
    ...contractProperties(contract),
    amountSavedPerYear,
  };
  trackSwedbankAnalyticsEvent(event);
};

export function subscriptionProperties(subscription: Subscription) {
  return {
    supplierName: get(subscription, 'supplier.name'),
  };
}

export function contractProperties(contract?: Contract) {
  return {
    category: contract?.service.category.name,
  };
}
