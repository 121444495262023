import { Sentry } from '@client/assets/js/sentry';
import { withLocalization } from '@client/internationalization';
import { SetSentFeedback } from '@client/modules/saveDesk/actions';
import {
  clickedSaveDeskOfferPageButton,
  viewedSaveDeskOfferDialogEvent,
} from '@client/modules/saveDesk/mixpanel-events';
import { SaveDeskOfferDialogComponent } from '@client/modules/saveDesk/SaveDeskOfferDialog/component';
import { selectorCancellationReason, selectorOffer } from '@client/modules/saveDesk/state';
import { fireMixpanelEvent } from '@client/modules/saveDesk/utils';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import { saveDeskDetailsPageVariant } from '@client/routes';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

interface SaveDeskOfferDialogInnerProps {
  subscription: Subscription;
  open: boolean;
  goToCancelForm(): void;
  contract: Contract;
  onClose(): void;
}

const SaveDeskOfferDialogInner: React.FunctionComponent<SaveDeskOfferDialogInnerProps> = ({
  subscription,
  open,
  goToCancelForm,
  contract,
  onClose,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const offer = useSelector(selectorOffer);
  const cancellationReason = useSelector(selectorCancellationReason);
  const onGoToCancellationFlow = () => {
    fireMixpanelEvent(subscription, contract, clickedSaveDeskOfferPageButton(cancellationReason, 'Cancel'));
    dispatch(SetSentFeedback(false));
    onClose();
    goToCancelForm();
  };
  const onGoToDetailsPage = async () => {
    fireMixpanelEvent(subscription, contract, clickedSaveDeskOfferPageButton(cancellationReason, 'Accept offer'));
    dispatch(SetSentFeedback(false));
    onClose();
    history.push(saveDeskDetailsPageVariant(subscription.id, contract.id));
  };

  useMountEffect(() => {
    fireMixpanelEvent(subscription, contract, viewedSaveDeskOfferDialogEvent(cancellationReason));
  });

  const closeDialog = () => {
    dispatch(SetSentFeedback(false));
    onClose();
  };
  if (!offer) {
    Sentry.captureMessage('No offer is available for offer dialog');
    closeDialog();
  }

  return (
    <SaveDeskOfferDialogComponent
      dialogOpen={open}
      offer={offer}
      onGoToDetailsPage={onGoToDetailsPage}
      onGoToCancellationFlow={onGoToCancellationFlow}
      provider={subscription.supplier}
      closeDialog={closeDialog}
    />
  );
};

export const SaveDeskOfferDialog = withLocalization('save-desk')(SaveDeskOfferDialogInner);
