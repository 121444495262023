import classNames from 'classnames/bind';
import React from 'react';
import styles from './style.scss';

const cx = classNames.bind(styles);

interface MultiDeviceLayoutProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  desktop?: boolean;
}

/**
 * @deprecated - use @minna-technologies/minna-ui/components/MultiDeviceLayout instead
 */
export const MultiDeviceLayout: React.FunctionComponent<MultiDeviceLayoutProps> = ({ header, children, desktop }) => {
  return (
    // @ts-ignore
    <div className={cx('page', { desktop: desktop })}>
      {header}
      <div
        // @ts-ignore
        className={cx('content', { desktop: desktop })}
      >
        {children}
      </div>
    </div>
  );
};

// We allow this since we were using it from so many places when introducing the lint rule.
// eslint-disable-next-line import/no-default-export
export default MultiDeviceLayout;
