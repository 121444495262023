import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_OVERVIEW } from '@client/tracking/mixpanel-constants';
import { sendButtonClickedEvent } from '@client/tracking/mixpanel-tracking-events';

export const sendViewedConsentPrimer = () => {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: FEATURE_OVERVIEW,
    Page: 'Consent primer',
  });
};

export const continueOnboardingConsentPrimer = () => {
  sendButtonClickedEvent(FEATURE_OVERVIEW, 'Consent primer', 'Continue');
};

export const cancelOnboardingConsentPrimer = () => {
  sendButtonClickedEvent(FEATURE_OVERVIEW, 'Consent primer', 'Back');
};
