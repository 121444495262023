import { selectorAppContext } from '@client/modules/app-context/duck';
import { ErrorLoading } from '@client/modules/fetching/ErrorPage/ErrorLoading';
import { LoadingPage } from '@client/modules/fetching/LoadingPage';
// @ts-ignore
import {
  selectorLoadingFailed,
  selectorOrderedSubscription,
  selectorSentRating,
} from '@client/modules/switch/ordered-subscription/duck';
import type { OrderedSubscription } from '@client/modules/switch/ordered-subscription/models';
import { OrderedSubscriptionPage } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionPage/component';
import * as urls from '@client/routes';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { viewPageOrderedSubscriptionMixpanelEvent } from '../../mixpanel-events';

interface OrderedSubscriptionPageProps {
  orderId: string;
}

export const OrderedSubscriptionContainer: React.FunctionComponent<OrderedSubscriptionPageProps> = ({ orderId }) => {
  const history = useHistory();

  const appContext = useSelector(selectorAppContext);
  const orderedSubscription: undefined | OrderedSubscription = useSelector(selectorOrderedSubscription);
  const loadingFailed = useSelector(selectorLoadingFailed);
  const sentRating = useSelector(selectorSentRating);

  useMountEffect(() => {
    viewPageOrderedSubscriptionMixpanelEvent(
      orderedSubscription?.product.supplierInformation.name ?? '',
      orderedSubscription?.product.category ?? [],
      orderedSubscription?.oldSupplierName
    );
  });

  const onBackClick = () => history.goBack();

  if (loadingFailed) {
    return <ErrorLoading />;
  } else if (orderedSubscription) {
    return (
      <OrderedSubscriptionPage
        orderedSubscription={orderedSubscription}
        currency={appContext.currency}
        onBackClick={onBackClick}
        goToTermsPage={() => history.push(urls.orderedSubscriptionTermsPage(orderId))}
        features={appContext.features}
        market={appContext.market}
        history={history}
        sentRating={sentRating}
      />
    );
  } else {
    return <LoadingPage />;
  }
};
