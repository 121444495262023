import Sentry from '@client/assets/js/sentry';
import { isAndroidApp, isIosApp } from '@client/modules/app-integration/webview-integration/device-helper';
import get from 'lodash/get';

export function postMessage(action: string, properties?: any) {
  try {
    Sentry.addBreadcrumb({ message: `App integration message sent: ${action}` });

    if (isIosApp()) {
      const message = { action, ...properties };

      // @ts-ignore
      window.webkit.messageHandlers.nativeHost.postMessage(message);

      return true;
    } else if (isAndroidApp()) {
      if (properties) {
        window.nativeHost.call(action, JSON.stringify(properties));
      } else {
        window.nativeHost.call(action);
      }

      return true;
    } else {
      const windowInfo = {
        androidNativeHost: get(window, 'nativeHost'),
        iosWebkit: get(window, 'webkit'),
        iosWebkitMessageHandlers: get(window, 'webkit.messageHandlers'),
        iosWebkitMessageHandlersNativeHost: get(window, 'webkit.messageHandlers.nativeHost'),
      };
      Sentry.captureMessage('Failed to identify Swedbank app integration type. Neither Android or iOS', {
        extra: { messageAction: action, messageProperties: properties, windowInfo },
      });

      return false;
    }
  } catch (error) {
    Sentry.captureExceptionWithMessage(error, 'Failed to send message to Swedbank app', {
      extra: { messageAction: action, messageProperties: properties },
    });

    return false;
  }
}
