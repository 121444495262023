import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { secondaryWhite } from '../colors';
import { ink, primary, white } from '../colors-standard';

export const blueBaseTheme = getMuiTheme({
  palette: {
    textColor: ink.main,
    primary1Color: primary.main,
    // @ts-ignore I don't think this is a supported palette option. But we read it in a few places.
    lightTextColor: white.main,
    lightSecondaryTextColor: secondaryWhite,
  },
  slider: {
    trackSize: 3,
    handleSize: 16,
    handleSizeActive: 22,
    handleColorZero: primary.main,
    handleFillColor: primary.main,
  },
  raisedButton: {
    secondaryColor: white.main,
    secondaryTextColor: primary.main,
  },
  borderRadius: '8px',
});
