import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { manualAddSubscriptionPage } from '@client/routes';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_ONBOARDING_ACTION_DRAWER } from '@client/tracking/mixpanel-constants';
import { PositiveFloatingButton } from '@minna-technologies/minna-ui/components/Buttons/PositiveFloatingButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './styles.scss';

export const SyncDoneEmptySlideComponent = ({ onClose }: { onClose(): void }) => {
  const history = useHistory();
  const pageTitle = 'No subscription';
  function goToAddManual() {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_ONBOARDING_ACTION_DRAWER,
      Page: pageTitle,
      Button: 'Add Manual',
    });
    onClose();
    history.push(manualAddSubscriptionPage);
  }
  useEffect(() => {
    getMixpanel().track(EventNames.VIEWED_GUIDE, {
      Feature: FEATURE_ONBOARDING_ACTION_DRAWER,
      Page: pageTitle,
    });
  }, []);

  return (
    <div className={styles.minHeightContainer}>
      <div className={[styles.centeredContainer, styles.titleSpacing].join(' ')}>
        <Headline2>
          <LocalizedMessage id={'emptyWelcome'} />
        </Headline2>
      </div>
      <div className={[styles.centeredContainer, styles.monthlyCostTitleSpacing].join(' ')}>
        <Body>
          <LocalizedMessage id={'emptyFirstRow'} />
        </Body>
      </div>
      <div className={[styles.centeredContainer, styles.monthlyCostTitleSpacing].join(' ')}>
        <Body>
          <LocalizedMessage id={'emptySecondRow'} />
        </Body>
      </div>
      <div className={styles.addManualButton}>
        <PositiveFloatingButton
          label={<LocalizedMessage id="addManual" />}
          onClick={goToAddManual}
          data-test="onboarding-drawer-add-manual-button"
        />
      </div>
    </div>
  );
};

export const SyncDoneEmptySlide = withLocalization('overview/OnboardingDrawer')(SyncDoneEmptySlideComponent);
