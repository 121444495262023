import type { ServiceId, SupplierId } from '@client/models/supplier';
import type { CategoryName } from '@client/constants/categories';
import type { AppType, Market } from '@client/modules/app-context/constants';
import type { CancellationMethodType } from '@client/models/service';
import { appPlatform, getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, TrackingFeature, TrackingPageName } from '@client/tracking/mixpanel-constants';
import { methodToTrackingSubscriptionMethod } from '@client/tracking/mixpanel-events';

export function trackViewedUpdateBankAppModal(
  merchantId: SupplierId,
  merchantName: string,
  serviceId: ServiceId,
  serviceName: string,
  categoryName: CategoryName | string,
  market: Market,
  appType: AppType,
  method: CancellationMethodType | undefined,
  subscriptionSource: string
) {
  getMixpanel().track(EventNames.VIEWED_PAGE, {
    Feature: TrackingFeature.Cancellation,
    Page: TrackingPageName.UPDATE_BANK_APP_MODAL,
    Method: methodToTrackingSubscriptionMethod(method),
    'App platform': appPlatform(),
    'Supplier id': merchantId,
    'Supplier name': merchantName,
    'Service id': serviceId,
    'Service name': serviceName,
    Category: categoryName,
    Market: market,
    Platform: appType,
    'Subscription source': subscriptionSource,
  });
}
export function trackClosedUpdateBankAppModal(
  merchantId: SupplierId,
  merchantName: string,
  serviceId: ServiceId,
  serviceName: string,
  categoryName: CategoryName | string,
  market: Market,
  appType: AppType,
  method: CancellationMethodType | undefined,
  subscriptionSource: string
) {
  getMixpanel().track(EventNames.CLICKED_BUTTON, {
    Feature: TrackingFeature.Cancellation,
    Page: TrackingPageName.UPDATE_BANK_APP_MODAL,
    Method: methodToTrackingSubscriptionMethod(method),
    'App platform': appPlatform(),
    'Supplier id': merchantId,
    'Supplier name': merchantName,
    'Service id': serviceId,
    'Service name': serviceName,
    Category: categoryName,
    Market: market,
    Platform: appType,
    'Subscription source': subscriptionSource,
  });
}
