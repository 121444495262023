// @ts-ignore js import, remove this when the import is typed
import { loadingPageWhileLoading, redirect } from '@client/containers/container-helpers';
import type { AppContext } from '@client/modules/app-context';
import { withAppContext } from '@client/modules/app-context/react';
import { CancellationSentPage } from '@client/modules/cancellation/CancellationSentPage/components/CancellationSentPage';
import { selectorCurrentRating, selectorSigningStatus } from '@client/modules/cancellation/duck';
import { ratedCancellationRequestEvent } from '@client/modules/cancellation/tracking-events';
import { FeedbackType } from '@client/modules/rating/model';
import {
  amountSavedPerYear,
  selectorContract,
  selectorSubscription,
  selectorSubscriptionIsLoading,
} from '@client/modules/subscription';
import type { Contract, ContractId, SubscriptionId } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import { trackRatedCancelExperience } from '@client/tracking/mixpanel-events';
import type { History } from 'history';
import get from 'lodash/get';
import type * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { TrackingPageName } from '@client/tracking/mixpanel-constants';
import debounce from 'lodash/debounce';

interface CancellationSentContainerProps {
  subscriptionId: SubscriptionId;
  contractId: ContractId;
}

const mapStateToProps = (
  state: any,
  {
    contractId,
    subscriptionId,
    appContext,
    history,
  }: { contractId: string; subscriptionId: string; appContext: AppContext; history: History }
) => {
  const subscription = selectorSubscription(state);
  const merchant = subscription?.supplier;
  const contract = selectorContract(state, contractId);
  const service = contract?.service;
  const serviceName = service?.name ?? 'service name not found';
  const categoryName = service?.category.name ?? 'category not found';
  const subscriptionMethod = contract?.cancellationMethod.type;
  const amountSaved = subscription ? amountSavedPerYear(subscription) : undefined;

  const features = appContext.features;

  const supplierName = merchant?.name ?? '';

  // There is a weird bug where we send 5 - 30 mixpanel events at the time. Can not find the reason.
  // Since it is very isolated, we work around it by debouncing (max 1 event per time unit).
  // Example of behaviour can be found in the story: https://minnatechnologies.atlassian.net/browse/OMI-886
  const onRate = debounce((newRating: number) => {
    if (newRating > 0 && subscription) {
      // 0 == not rated
      trackRatedCancelExperience(
        TrackingPageName.SUCCESS_SCREEN,
        newRating,
        subscription,
        amountSaved?.amount,
        contract
      );
      ratedCancellationRequestEvent(
        newRating,
        FeedbackType.Flow,
        categoryName,
        appContext.market,
        contract?.cancellationInfo?.cancellationId
      );
    }
  }, 3000);

  return {
    subscription,
    contract,
    merchant,
    service,
    serviceName,
    subscriptionMethod,
    categoryName: contract?.service?.category?.name,
    saveDeskEnabled: contract?.saveDeskEnabled,
    features,
    supplierName,
    amountSavedPerYear: amountSaved,
    status: selectorSigningStatus(state),
    currentRating: selectorCurrentRating(state),
    isLoading: selectorSubscriptionIsLoading(state, subscriptionId),
    onRate,
    onRedirectUser: () => history.go(-2),
  };
};

export const CancellationSentContainer = compose<React.ComponentType<CancellationSentContainerProps>>(
  withAppContext,
  withRouter,
  connect(mapStateToProps),
  loadingPageWhileLoading((props: any) => props.isLoading),
  redirect(
    ({ contract }: { contract: Contract }) => get(contract, 'state') === 'Active',
    ({ subscriptionId, contractId }: { subscriptionId: SubscriptionId; contractId: ContractId }) =>
      urls.terminationFormPage(subscriptionId, contractId)
  )
)(CancellationSentPage);
