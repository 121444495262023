import { Sentry } from '@client/assets/js/sentry';
import { useLocalization } from '@client/internationalization';
import type { PastAction } from '@client/models/cancellation';
import { getRecentCancellationsForUser } from '@client/models/cancellation';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { cancellationDetailsPage } from '@client/routes';
import { makeStyles } from '@material-ui/styles';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendClickedPastAction } from '../mixpanel';
import { PastActionCard } from './PastActionCard';

const useStyles = makeStyles({
  pastActionsWidget: {
    marginTop: '40px',
  },
});

export const PastActions = () => {
  const [pastActions, setPastActions] = useState<PastAction[]>([]);
  const { localizeMessage } = useLocalization('subscriptionHub/subscriptionHub');
  const appContext = useSelector(selectorAppContext);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    getRecentCancellationsForUser()
      .then((cancellations: PastAction[]) => {
        setPastActions(cancellations);
      })
      .catch((error: Error) => {
        Sentry.captureMessage('Could not fetch past actions for user', { extra: { error } });
      });
  }, []);

  const onPastActionClicked = (merchantId: string, merchantName: string, category: string, cancellationId: string) => {
    sendClickedPastAction(merchantId, merchantName, category, cancellationId);
    history.push(cancellationDetailsPage(cancellationId));
  };

  const maybeDivider =
    pastActions.length > 0 ? (
      <span data-test="past-actions-divider">
        <Divider title={localizeMessage('divider')} />
      </span>
    ) : null;

  return (
    <div className={classes.pastActionsWidget}>
      {maybeDivider}
      {pastActions.map((c: PastAction) => (
        <PastActionCard key={c.id} cancellation={c} onPastActionClicked={onPastActionClicked} appContext={appContext} />
      ))}
    </div>
  );
};
