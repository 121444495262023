import { LocalizedMessage } from '@client/internationalization';
// @ts-ignore js import, remove this when the import is typed
import StickyContainerView from '@client/shared-components/layout/StickyContainerView';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { ZendeskHeader } from '../../../ZendeskHeader/component';
import { ZendeskMinimized } from '../ZendeskMinimized/container';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    overflow: 'scroll',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.base.background,
  },
  headerContainer: {
    width: '100%',
  },
}));

interface ZendeskDesktopDialogProps {
  isOpen: boolean;
  onNavigateBack(): void;
  onCloseDialog(): void;
  visibleComponent: React.ReactNode;
}

export const ZendeskDesktopDialog: React.FunctionComponent<ZendeskDesktopDialogProps> = ({
  isOpen,
  onNavigateBack,
  onCloseDialog,
  visibleComponent,
}: ZendeskDesktopDialogProps) => {
  const classes = useStyles();

  return (
    <div>
      <StickyContainerView isOpen={isOpen} name="zendesk-desktop-dialog">
        <div className={classes.headerContainer}>
          <ZendeskHeader
            title={<LocalizedMessage id="desktopDialogHeaderTitle" />}
            onNavigateBack={onNavigateBack}
            onClose={onCloseDialog}
          />
        </div>
        <div className={classes.contentContainer}>{visibleComponent}</div>
      </StickyContainerView>
      <ZendeskMinimized />
    </div>
  );
};
