import { isSwedbankAndroidApp } from '@client/modules/app-integration/swedbank-app-integration';
import responsiveHelpers from '@client/styles/responsive-helpers.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import StableFullDeviceHeight from '../StableFullDeviceHeight';
import styles from './styles.scss';

const FullscreenContainerView = ({ isOpen, children, name }: any) => {
  // HACK: swedbank webview on android does not fire a 'resize' event when hiding keyboard.
  // Need to inject some really tall content for this to happen (MT-47)
  const androidSwedbankDiv = isOpen && isSwedbankAndroidApp() && (
    <div
      style={{
        height: '9000px',
        width: '0px',
        visibility: 'hidden',
      }}
    />
  );

  return (
    <div data-test={name}>
      {androidSwedbankDiv}
      <StableFullDeviceHeight
        className={classNames(
          responsiveHelpers['visible-mobile-chat'],
          styles['fullscreen-container'],
          !isOpen && styles.hidden
        )}
        heightProperty="height"
      >
        {children}
      </StableFullDeviceHeight>
    </div>
  );
};

FullscreenContainerView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string,
  children: PropTypes.node,
};

// eslint-disable-next-line import/no-default-export
export default FullscreenContainerView;
