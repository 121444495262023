import { Locale } from '@client/modules/app-context/constants';
import { useBackOrUndefined } from '@client/utils/hooks/history';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import React from 'react';
import { LazyLoadedUserTermsPageEn } from './Sb1UserTermsPageEn';
import { LazyLoadedUserTermsPageNo } from './Sb1UserTermsPageNo';

interface Sb1UserTermsPageProps {
  locale: Locale;
}

export const Sb1UserTermsPage: React.FunctionComponent<Sb1UserTermsPageProps> = ({ locale }) => {
  const maybeBackClick = useBackOrUndefined();

  // TODO: use 'termsOfServiceTitle' localization key for page title
  let localizedPageTitle = null;
  let localizedUserTermsPage = null;
  if (locale === Locale.nbNO) {
    localizedPageTitle = 'Vilkår for avbestillinger';
    localizedUserTermsPage = <LazyLoadedUserTermsPageNo />;
  } else {
    localizedPageTitle = 'Terms for cancellations';
    localizedUserTermsPage = <LazyLoadedUserTermsPageEn />;
  }

  return (
    <MultiDeviceLayout title={localizedPageTitle} footer={<FooterNavigation onBackClick={maybeBackClick} />}>
      {localizedUserTermsPage}
    </MultiDeviceLayout>
  );
};

export const Sb1UserTermsContent: React.FunctionComponent<Sb1UserTermsPageProps> = ({ locale }) => {
  if (locale === Locale.nbNO) {
    return <LazyLoadedUserTermsPageNo />;
  } else {
    return <LazyLoadedUserTermsPageEn />;
  }
};
