import Sentry from '@client/assets/js/sentry';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { selectorSubscription, setInitialState, subscriptionLoadingFailure } from '@client/modules/subscription/duck';
import {
  buttonClickedSubscriptionPageMixpanelEvent,
  viewSubscriptionPageMixpanelEvent,
} from '@client/modules/subscription/mixpanel';
import { SubscriptionSettingsPageComponent } from '@client/modules/subscription/SubscriptionSettingsPage/component';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import { ContractState } from '@client/modules/subscription/types';
import { overviewPage, selectServicesPage, subscriptionUpdateCostPage } from '@client/routes';
import { deletedSubscriptionMixpanelEvent } from '@client/tracking/mixpanel-events';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteSubscription as deleteSubscriptionCall } from '../model';

export const SubscriptionSettingsPage: React.FunctionComponent = () => {
  const subscription: Subscription | undefined = useSelector(selectorSubscription);
  const features = useSelector(selectorFeatures);
  const dispatch = useDispatch();
  const history = useHistory();
  const hasActionOngoingContracts = (subscription?.contracts ?? []).some((contract: Contract) => {
    return contract.state !== ContractState.Active;
  });
  const editServicesEnabled =
    (subscription?.contracts.length ?? 0) > 1 ||
    ((!(subscription?.supplier.singleServiceSupplier ?? true) && subscription?.supplier.verified) ?? false);
  const changeCostEnabled = subscription?.source === 'manual' || features.subscriptionFeatures.editBdSubscriptions;
  const removeFromOverviewEnabled = !hasActionOngoingContracts && features.subscriptionFeatures.deletingSubscriptions;
  const pageName = 'Settings';

  function handleRemoveFromOverviewClicked(reason: string) {
    if (subscription) {
      buttonClickedSubscriptionPageMixpanelEvent(subscription, 'Delete subscription', pageName);
      deleteSubscriptionCall(subscription.id, reason)
        .then(() => {
          deletedSubscriptionMixpanelEvent(subscription, reason);
          dispatch(setInitialState());
          history.push(overviewPage);
        })
        .catch((error: any) => {
          dispatch(subscriptionLoadingFailure());
          Sentry.captureExceptionWithMessage(error, 'Failed to delete subscription');
        });
    }
  }
  function handleEditCostClicked() {
    if (subscription) {
      buttonClickedSubscriptionPageMixpanelEvent(subscription, 'Edit Cost', pageName);
      history.push(subscriptionUpdateCostPage(subscription.id));
    }
  }
  function handleChangeServicesClicked() {
    if (subscription) {
      buttonClickedSubscriptionPageMixpanelEvent(subscription, 'Select services', pageName);
      history.push(selectServicesPage(subscription.id));
    }
  }
  useMountEffect(() => {
    if (subscription) {
      viewSubscriptionPageMixpanelEvent(subscription, pageName);
    }
  });
  function removeSubscriptionClicked() {
    if (subscription) {
      const deleteDialogPageTitle = 'Delete subscription';
      buttonClickedSubscriptionPageMixpanelEvent(subscription, deleteDialogPageTitle, pageName);
      setDialogOpen(true);
      viewSubscriptionPageMixpanelEvent(subscription, deleteDialogPageTitle);
    }
  }
  const [dialogOpen, setDialogOpen] = useState(false);

  return subscription ? (
    <SubscriptionSettingsPageComponent
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      subscription={subscription}
      editServicesEnabled={editServicesEnabled}
      removeFromOverviewEnabled={removeFromOverviewEnabled}
      changeCostEnabled={changeCostEnabled}
      history={history}
      removeSubscriptionClicked={removeSubscriptionClicked}
      handleEditCostClicked={handleEditCostClicked}
      handleChangeServicesClicked={handleChangeServicesClicked}
      handleRemoveFromOverviewClicked={handleRemoveFromOverviewClicked}
    />
  ) : null;
};
