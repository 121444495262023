import type { CategoryName } from '@client/constants/categories';
import type { QuestionId } from '@client/models/cancellation-questions';
import type { ServiceId } from '../modules/subscription/types';

export interface ServiceCategory {
  name: { name: string };
  localizedNames: { [localeName: string]: string };
}

export interface Category {
  name: CategoryName;
  text: string;
}

export interface ServiceToDisplay {
  category: Category;
  contractId?: string;
  displayId?: number;
  id: string;
  name: string;
  selected: boolean;
  serviceId?: ServiceId;
  terminable?: boolean;
  valueAddedService: boolean;
  terminationQuestions?: TerminationQuestions;
  cancellationSettings: ServiceCancellationSettings;
  trusted: boolean;
}

export interface CancellationCategoryQuestionsFilter {
  ignoreCategoryQuestions?: QuestionId[];
}

export interface SelectableService {
  serviceId: string;
  contractId?: string;
  selected: boolean;
  displayId?: number;
}

export interface TerminationQuestions {
  id: string;
  label: string;
  required: boolean;
  description?: string;
}

export interface ServiceCancellationSettings {
  methods: CancellationMethodSettings[];
  questionSettings?: CancellationQuestionsSettings;
  serviceProviderCommunicationSettings?: CancellationServiceProviderCommunicationSettings;
  cruncherSettings?: CruncherServiceSettings;
  nonCancellableInstructionsSettings?: NonCancellableInstructionsSettings;
  intelligentGuideSettings?: CancelIntelligentGuideSettings;
}
export interface CancellationMethodSettings {
  method: CancellationMethod;
  allowedInMarkets?: string[];
}

export interface CancellationMethod {
  type: CancellationMethodType;
}
export enum CancellationMethodType {
  MerchantAPI = 'MerchantAPI',
  PowerOfAttorney = 'PowerOfAttorney',
  BlockPayment = 'BlockPayment',
  IntelligentGuide = 'IntelligentGuide',
  NonCancellableInstructions = 'NonCancellableInstructions',
}
export interface NonCancellableInstructionsSettings {
  body: { [key: string]: string };
  lastUpdatedAt?: string;
}
export interface CancellationQuestionsSettings {
  cancellationQuestions?: any[];
  cancellationCategoryQuestionsFilter?: CancellationCategoryQuestionsFilter;
}
export interface CancellationServiceProviderCommunicationSettings {
  emailAddresses?: string[];
  emailTemplateName?: string;
  numberOfDaysBeforeReminder?: number;
  usePartnerApiIfAvailable: boolean;
}

export interface CruncherServiceSettings {
  automaticResolveFlags: {
    enabledForUselessEmails?: boolean;
    enabledToFinishCancellation?: boolean;
  };
}
export interface CancelIntelligentGuideSettings {
  guideId: CancelIntelligentGuideId;
  linkToCancel?: string;
}

export type CancelIntelligentGuideId = string;
