// @ts-ignore js import, remove this when the import is typed
import { formatPersonalNumber } from '@client/assets/js/formatters';
import { LocalizedMessage } from '@client/internationalization';
import type { CancellationUser } from '@client/models/cancellation';
import type { AppContext } from '@client/modules/app-context';
import { hasPersonalIdentifier } from '@client/modules/app-context/constants';
import { InfoComponent } from '@client/modules/cancellation/CancellationFormPage/components/UserInfoForm';
import grid from '@client/styles/grid.css';
import classNames from 'classnames/bind';
import React from 'react';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';

const cx = classNames.bind(grid);

interface UserTerminationInfoProps {
  user: CancellationUser;
  appContext: AppContext;
}

export const UserTerminationInfo: React.FunctionComponent<UserTerminationInfoProps> = ({ user, appContext }) => {
  const addressInfo = user.address && (
    <InfoComponent label={<LocalizedMessage id="address" />} className={cx('col-xs-12', 'col-sm-6')}>
      {user.address.careOf ? (
        <span>
          C/O {user.address.careOf}
          <br />
        </span>
      ) : null}
      {user.address.street}
      <br />
      {`${user.address.postalCode} ${user.address.city}`}
    </InfoComponent>
  );
  const emailInfo = user.email && (
    <InfoComponent label={<LocalizedMessage id="emailAddress" />} className={cx('col-xs-12', 'col-sm-6')}>
      {user.email}
    </InfoComponent>
  );
  const marketHasPersonalIdentifier = hasPersonalIdentifier(appContext.market);

  return (
    <div data-test="loa-user-info">
      <Title>
        <LocalizedMessage id="userInformationTitle" />
      </Title>
      <div className={cx('row')}>
        <InfoComponent label={<LocalizedMessage id="name" />} className={cx('col-xs-12', 'col-sm-6')}>
          {`${user.name.first} ${user.name.last}`}
        </InfoComponent>
        {marketHasPersonalIdentifier && (
          <InfoComponent label={<LocalizedMessage id="personalNumber" />} className={cx('col-xs-12', 'col-sm-6')}>
            {formatPersonalNumber(user.personalNumber)}
          </InfoComponent>
        )}
        {addressInfo}
        {emailInfo}
      </div>
    </div>
  );
};
