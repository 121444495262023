import type { FC } from 'react';
import React from 'react';
import errorImage from '@client/assets/images/circle-with-red-x.svg';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { makeStyles } from '@material-ui/core/styles';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  cardContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    marginBottom: '16px',
  },
  cardBody: {
    marginBottom: '76px',
    marginTop: '12px',
  },
}));

interface Props {
  headerText: string | React.ReactElement;
  bodyText: string | React.ReactElement;
  altText: string;
}

export const MerchantGuideErrorComponent: FC<Props> = ({ headerText, bodyText, altText }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={errorImage} alt={altText} />
      </div>
      <div className={classes.cardContainer}>
        <Card data-test="merchant-guide-error-component-card">
          <Headline2>{headerText}</Headline2>
          <Body className={classes.cardBody}>{bodyText}</Body>
        </Card>
      </div>
    </div>
  );
};
