import type { SubscriptionActionType } from '@client/models/subscription';
import type { ServiceId, SupplierId } from '@client/models/supplier';
import type { UserId } from '@client/models/user-models';
import type { IntelligentGuide, IntelligentGuideId } from '@client/modules/intelligent-guides/models';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

export async function getContractIntelligentGuide(
  contractId: SupplierId,
  subscriptionAction: SubscriptionActionType
): Promise<IntelligentGuide> {
  return axios
    .get(`/api/v1/user/contracts/${contractId}/intelligent-guide?subscriptionAction=${subscriptionAction}`)
    .then((response: AxiosResponse<IntelligentGuide>) => response.data);
}

export async function getServiceIntelligentGuide(
  merchantId: SupplierId,
  serviceId: ServiceId,
  subscriptionAction: SubscriptionActionType
): Promise<IntelligentGuide> {
  return axios
    .get(
      `/api/v1/user/merchants/${merchantId}/services/${serviceId}/intelligent-guide?subscriptionAction=${subscriptionAction}`
    )
    .then((response: AxiosResponse<IntelligentGuide>) => response.data);
}

export async function saveSubscriptionActionIntelligentGuideConfirmation(
  userId: UserId,
  actionType: SubscriptionActionType,
  guideId: IntelligentGuideId,
  serviceId: ServiceId,
  successfulAction: boolean,
  userComment?: string,
  rating?: number
) {
  return axios.post(`/api/v1/user/${userId}/subscription-actions/intelligent-guide`, {
    actionType,
    guideId,
    serviceId,
    successfulAction,
    userComment,
    rating,
  });
}

export async function saveViewedIntelligentGuideEvent(
  subscriptionActionType: SubscriptionActionType,
  merchantId: SupplierId,
  merchantName: string,
  serviceId: ServiceId,
  serviceName: string,
  categoryName: string,
  contractId?: string,
  subscriptionSource?: string
) {
  return axios.post(`/api/v1/user/viewed-intelligent-guide`, {
    subscriptionActionType,
    serviceId,
    merchantId,
    merchantName,
    categoryName,
    serviceName,
    contractId,
    subscriptionSource,
  });
}
