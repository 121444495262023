import { useLocalization } from '@client/internationalization';
import { TrackingFeature } from '@client/tracking/mixpanel-constants';
import { parseDate } from '@client/utils/date';
import { makeStyles } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { CheckCircleIcon } from '@minna-technologies/minna-ui/icons/CheckCircle';
import { QuestionIcon } from '@minna-technologies/minna-ui/icons/Question';
import { TimeClockIcon } from '@minna-technologies/minna-ui/icons/TimeClock';
import type { FC } from 'react';
import React from 'react';

interface CancellationProgressProps {
  supplierName: string;
  cancellationRequestDate: string;
  cancellationReminderSentDate?: string;
}

export const CancellationProgress: FC<CancellationProgressProps> = ({
  supplierName,
  cancellationRequestDate,
  cancellationReminderSentDate,
}) => {
  return cancellationReminderSentDate ? (
    <CancellationReminderSentStepper
      supplierName={supplierName}
      cancellationRequestDate={parseDate(cancellationRequestDate)}
      cancellationReminderSentDate={parseDate(cancellationReminderSentDate)}
    />
  ) : (
    <RequestSentAndWaitingStepper
      supplierName={supplierName}
      requestDate={parseDate(cancellationRequestDate)}
      feature={TrackingFeature.Cancellation}
    />
  );
};

const useStyles = makeStyles({
  stepLabel: {
    marginLeft: 8,
  },
  connector: {
    padding: '0',
  },
  connectorLine: {
    minHeight: 24,
  },
});

interface RequestSentAndWaitingStepperProps {
  supplierName: string;
  requestDate: Date;
  feature: string;
}

export const RequestSentAndWaitingStepper: FC<RequestSentAndWaitingStepperProps> = ({
  supplierName,
  requestDate,
  feature,
}) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const { localizeMessage } = useLocalization('cancellation/CancellationStepper');

  return (
    <Stepper
      connector={
        <StepConnector
          classes={{
            root: classes.connector,
            line: classes.connectorLine,
          }}
        />
      }
      style={{ padding: '0' }}
      activeStep={0}
      orientation="vertical"
    >
      <Step>
        <StepLabel
          icon={<CheckCircleIcon nativeColor={colors.action.positive} bold />}
          classes={{ labelContainer: classes.stepLabel }}
        >
          <Body color={colors.textOn.surfaceSubdued}>
            {localizeMessage('requestSentText', {
              supplierName: supplierName,
              // @ts-ignore
              requestDate: requestDate,
            })}
          </Body>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel
          icon={<TimeClockIcon nativeColor={colors.action.info} bold />}
          classes={{ labelContainer: classes.stepLabel }}
        >
          <Body>
            {localizeMessage('waitForConfirmationAfterRequestText', {
              supplierName: supplierName,
              feature: feature,
            })}
          </Body>
        </StepLabel>
      </Step>
    </Stepper>
  );
};

interface CancellationReminderSentStepperProps {
  supplierName: string;
  cancellationRequestDate: Date;
  cancellationReminderSentDate: Date;
}

export const CancellationReminderSentStepper: FC<CancellationReminderSentStepperProps> = ({
  supplierName,
  cancellationRequestDate,
  cancellationReminderSentDate,
}) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const { localizeMessage } = useLocalization('cancellation/CancellationStepper');

  return (
    <Stepper
      connector={
        <StepConnector
          classes={{
            root: classes.connector,
            line: classes.connectorLine,
          }}
        />
      }
      style={{ padding: '0' }}
      activeStep={1}
      orientation="vertical"
    >
      <Step>
        <StepLabel
          icon={<CheckCircleIcon nativeColor={colors.action.positive} bold />}
          classes={{ labelContainer: classes.stepLabel }}
        >
          <Body color={colors.textOn.surfaceSubdued}>
            {localizeMessage('requestSentText', {
              supplierName: supplierName,
              // @ts-ignore
              requestDate: cancellationRequestDate,
            })}
          </Body>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel
          icon={<QuestionIcon nativeColor={colors.textOn.surface} />}
          classes={{ labelContainer: classes.stepLabel }}
        >
          <Body color={colors.textOn.surfaceSubdued}>
            {localizeMessage('reminderSentText', {
              supplierName,
              // @ts-ignore
              cancellationReminderSentDate,
            })}
          </Body>
        </StepLabel>
      </Step>
      <Step>
        <StepLabel
          icon={<TimeClockIcon nativeColor={colors.action.info} bold />}
          classes={{ labelContainer: classes.stepLabel }}
        >
          <Body>{localizeMessage('waitForConfirmationAfterReminderText', { supplierName })}</Body>
        </StepLabel>
      </Step>
    </Stepper>
  );
};
