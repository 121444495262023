import { LocalizedMessage } from '@client/internationalization';
import { RadioItem } from '@client/modules/shared-components/fields/radioFields/RadioItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { DangerButton } from '@minna-technologies/minna-ui/components/Buttons/DangerButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { FooterNavigation } from '@minna-technologies/minna-ui/components/FooterNavigation';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './styles.scss';

export interface UserDeletePageProps {
  selectedReason: any;
  onSelectReason(selectedReason: any): void;
  onChangeOtherReasonText(otherReasonText: any): void;
  onConfirm(): void;
  onBackClick(): void;
  reasons: any[];
  deleteAccountMessageId: string;
}

export const UserDeletePage: React.FunctionComponent<UserDeletePageProps> = (
  { selectedReason, onSelectReason, onChangeOtherReasonText, onConfirm, onBackClick, deleteAccountMessageId },
  { localizeMessage }
) => {
  const showOtherReasonTextField = get(selectedReason, 'key') === 'otherReason';

  const [modalOpen, setModalOpen] = useState(false);

  const onToggleConfirmationModal = () => {
    setModalOpen(!modalOpen);
  };

  const reasons = [
    {
      key: 'doNotNeedTheService',
      text: <LocalizedMessage id="deletionReasonIDoNotNeedTheServiceV2" />,
      value: localizeMessage('deletionReasonIDoNotNeedTheServiceV2'),
      userInput: false,
    },
    {
      key: 'notHappyWithProduct',
      text: <LocalizedMessage id="deletionReasonNotHappyWithProductV2" />,
      value: localizeMessage('deletionReasonNotHappyWithProductV2'),
      userInput: false,
    },
    {
      key: 'feelsNotSecure',
      text: <LocalizedMessage id="deletionReasonFeelsNotSecureV2" />,
      value: localizeMessage('deletionReasonFeelsNotSecureV2'),
      userInput: false,
    },
    {
      key: 'otherReason',
      text: <LocalizedMessage id="deletionReasonOtherReason" />,
      value: localizeMessage('deletionReasonOtherReason'),
      userInput: true,
    },
  ];

  const radioItems = map(reasons, (reason) => {
    const checked = isEqual(reason.key, get(selectedReason, 'key'));

    return (
      <div className={styles['radio-buttons-div']} key={reason.key}>
        <RadioItem checked={checked} onCheck={() => onSelectReason(reason)} key={reason.key} text={reason.text} />
      </div>
    );
  });

  return (
    <MultiDeviceLayout
      footer={<FooterNavigation onBackClick={onBackClick} />}
      title={<LocalizedMessage id="userDeletePageTitle" />}
      hasTopNavigation={false}
    >
      <Paper className={styles['delete-user']}>
        {
          <Body style={{ marginBottom: '16px' }}>
            <LocalizedMessage id="userDeletePageFormTitle" />
            <form className={styles['delete-user-form']} onSubmit={(event) => event.preventDefault()}>
              {radioItems}
            </form>
          </Body>
        }
        {showOtherReasonTextField ? (
          <TextField
            label={<LocalizedMessage id="deletionReasonOtherReason" />}
            placeholder={localizeMessage('deletionReasonOtherReasonHintText')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChangeOtherReasonText(event.currentTarget.value)
            }
            multiline
          />
        ) : null}

        <div className={styles['confirm-panel']}>
          <DangerButton
            data-test="confirm-user-account-deletion"
            label={<LocalizedMessage id="userDeletePageSubmitButton" />}
            onClick={onToggleConfirmationModal}
            fullWidth
          />
        </div>
        <Modal
          title={<LocalizedMessage id="userDeletePageModalTitle" />}
          open={modalOpen}
          button1={
            <DangerButton
              onClick={onConfirm}
              label={<LocalizedMessage id="userDeletePageModalConfirm" />}
              data-test="dialog-primary-button"
            />
          }
          button2={
            <SecondaryButton
              onClick={onBackClick}
              label={<LocalizedMessage id="userDeletePageModalCancel" />}
              data-test="dialog-secondary-button"
            />
          }
          onClose={onToggleConfirmationModal}
          buttonLayout={'horizontal'}
        >
          <Body>
            <LocalizedMessage id={deleteAccountMessageId} />
          </Body>
        </Modal>
      </Paper>
    </MultiDeviceLayout>
  );
};

UserDeletePage.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
