import React, { useCallback, useEffect } from 'react';
import { MerchantGuideErrorComponent } from '@client/modules/intelligent-guides/components/MerchantGuideMissingLinkComponent';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import type { Locale } from '@client/modules/app-context/constants';
import { AppType } from '@client/modules/app-context/constants';
import { forceLoadMessagesAndLocale } from '@client/internationalization/load';
import { useLocalization } from '@client/internationalization';
import { Sentry } from '@client/assets/js/sentry';

type ErrorReason = 'ActionNotFound' | 'LinkMissing' | 'LinkExpired';
export const MerchantGuideErrorPage: React.FunctionComponent = () => {
  const { localizeMessage } = useLocalization('general/IntelligentGuide');
  const searchParams = new URLSearchParams(window.location.search);
  const failureReason = searchParams.get('reason') as ErrorReason;
  const hasMessages = useCallback(() => localizeMessage('errorTitle') !== 'MISSING', [localizeMessage]);

  useEffect(() => {
    Sentry.captureMessage(
      `A user was sent to Merchant Guide error page from the Link tracker with reason: ${failureReason}`
    );
  }, [failureReason]);

  useEffect(() => {
    if (!hasMessages()) {
      try {
        const browserLocale = (navigator.language as Locale) ?? 'en-GB';
        forceLoadMessagesAndLocale(browserLocale, AppType.Undefined);
      } catch (error) {
        return;
      }
    }
  }, [hasMessages]);

  return (
    <MultiDeviceLayout hasTopNavigation={false}>
      <MerchantGuideErrorComponent
        headerText={localizeMessage('errorTitle')}
        bodyText={localizeMessage('errorBody')}
        altText={localizeMessage('errorAltText')}
      />
    </MultiDeviceLayout>
  );
};
