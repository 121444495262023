import { LocalizedMessage, withLocalization } from '@client/internationalization';
import MultiDeviceLayout from '@client/shared-components/layout/MultiDeviceLayout';
import PageNavigationBar from '@client/shared-components/PageNavigationBar';
import Paper from '@material-ui/core/Paper/Paper';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';
import { compose } from 'recompose';

const paperStyles = createStyles({
  paperRoot: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    minHeight: '200px',
  },
});

export interface NoSigningPageProps {
  onBackClick(): void;
}

type NoSigningPagePropsInner = NoSigningPageProps & WithStyles<typeof paperStyles>;

export const NoSigningPageComponentInner: React.FunctionComponent<NoSigningPagePropsInner> = ({
  onBackClick,
  classes,
}) => {
  const header = (
    <PageNavigationBar backgroundColor="white" onBackClick={onBackClick} title={<LocalizedMessage id="pageTitle" />} />
  );

  return (
    <MultiDeviceLayout header={header}>
      <Paper classes={{ root: classes.paperRoot }}>
        <Body>
          <LocalizedMessage id="cancellationError" />
        </Body>
        <PrimaryButton onClick={onBackClick} label={<LocalizedMessage id="tryAgainButton" />} />
      </Paper>
    </MultiDeviceLayout>
  );
};

export const NoSigningPageComponent = compose<NoSigningPagePropsInner, NoSigningPageProps>(
  withStyles(paperStyles),
  withLocalization('cancellation/signing/NoSigningPage')
)(NoSigningPageComponentInner);
