import makeStyles from '@material-ui/core/styles/makeStyles';
import { ChatIcon } from '@minna-technologies/minna-ui/icons/Chat';
import classNames from 'classnames';
import truncate from 'lodash/truncate';
import Badge from 'material-ui/Badge';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  minimizedContainer: {
    right: '16px',
    position: 'fixed',
    bottom: '86px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 2, // has to be above RaisedButton (which has z-index=1)
    '&.hidden': {
      display: 'none',
    },
    '& .hidden': {
      display: 'none',
    },
  },
  recentMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5px',
    marginRight: '4px',
    animation: '$fade-in-recent-message 0.2s ease-in-out',
  },
  '@keyframes fade-in-recent-message': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  recentMessage: {
    backgroundColor: '#2a2a2a',
    color: theme.colors.base.surface,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '12px',
    boxShadow: '0 2px 1px rgba(0, 0, 0, 0.5)',
    padding: '10px',
    borderRadius: '5px',
    maxWidth: '250px', // based on iphone 5 screen width
  },
  minimizedButton: {
    backgroundColor: theme.colors.action.primary,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  rightArrow: {
    width: 0,
    height: 0,
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderLeft: '6px solid #2b2b2b',
  },
}));

export interface ZendeskMinimizedComponentProps {
  isMinimized: boolean;
  onOpenDialog: () => void;
  unreadMessagesCount: number;
  recentMessageText?: string;
  showRecentMessage: boolean;
}

export const ZendeskMinimizedComponent = ({
  isMinimized,
  onOpenDialog,
  unreadMessagesCount,
  recentMessageText,
  showRecentMessage,
}: ZendeskMinimizedComponentProps) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.minimizedContainer, !isMinimized && 'hidden')}>
      <RecentMessage messageText={recentMessageText} show={showRecentMessage} />
      <div role="button" className={classes.minimizedButton} onClick={onOpenDialog}>
        <Badge
          badgeContent={unreadMessagesCount}
          secondary
          style={{
            padding: '14px 14px 7px',
          }}
          badgeStyle={{
            top: '-2px',
            right: 'auto',
            left: '-2px',
            visibility: unreadMessagesCount === 0 ? 'hidden' : 'visible',
          }}
        >
          <ChatIcon style={{ color: 'white' }} />
        </Badge>
      </div>
    </div>
  );
};

interface RecentMessageProps {
  messageText?: string;
  show: boolean;
}

const RecentMessage = ({ messageText, show }: RecentMessageProps) => {
  const classes = useStyles();

  const truncatedMessage = truncate(messageText, { length: 70 });

  return (
    <div className={classNames(classes.recentMessageContainer, !show && 'hidden')}>
      <div className={classes.recentMessage}>
        <span>{truncatedMessage}</span>
      </div>
      <div className={classes.rightArrow} />
    </div>
  );
};
