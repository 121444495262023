import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { sendClickedButtonMixpanelEvent } from '@client/modules/cancellation/mixpanel-events';
import type { ContractId, SubscriptionId } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import type { FC } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import type { TrackingPageName } from '@client/tracking/mixpanel-constants';
import { TrackingButtonName } from '@client/tracking/mixpanel-constants';

export interface Props {
  serviceProviderName: string;
  pageName: TrackingPageName;
  subscriptionId: SubscriptionId;
  contractId: ContractId;
  action?(): void;
}

const TryCancellationAgainButtonInner: FC<Props> = ({
  serviceProviderName,
  action,
  pageName,
  subscriptionId,
  contractId,
}) => {
  const history = useHistory();
  const onTryAgainClick = () => {
    if (action !== undefined) {
      action();
    }
    sendClickedButtonMixpanelEvent(
      pageName,
      serviceProviderName,
      TrackingButtonName.TRY_AGAIN,
      'CancellationOutcomeNoSuchAccount'
    );
    history.push(urls.terminationFormPage(subscriptionId, contractId));
  };

  return (
    <PrimaryButton label={<LocalizedMessage id="tryCancellationAgainBtnLabel" />} onClick={onTryAgainClick} fullWidth />
  );
};

export const TryCancellationAgainButton = withLocalization(
  'overview/InsightsPage/InsightCards/TryCancellationAgainButton'
)(TryCancellationAgainButtonInner);
