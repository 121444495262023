import { useLocalization } from '@client/internationalization';
import type { PaymentInterval } from '@client/modules/subscription/types';
import type { FC } from 'react';
import React from 'react';

interface IntervalToTextProps {
  interval: PaymentInterval;
}

export const IntervalToText: FC<IntervalToTextProps> = ({ interval }) => {
  const { localizeMessage } = useLocalization('interval-to-string');
  const { unit, amount: count } = interval;

  switch (unit) {
    case 'day':
      return <>{localizeMessage('day')}</>;
    case 'week':
      return <>{localizeMessage('week')}</>;
    case 'month':
      return <>{localizeMessage('month', { count })}</>;
    case 'year':
      return <>{localizeMessage('year')}</>;
    default:
      throw Error('This could never happen, if PaymentInterval is typed correctly');
  }
};

interface PerIntervalProps {
  interval: PaymentInterval;
  showPrefix?: boolean;
}

export const PerInterval: FC<PerIntervalProps> = ({ interval, showPrefix = true }) => {
  const { localizeMessage } = useLocalization('interval-to-string');

  return <>{intervalToString(localizeMessage)(interval, showPrefix)}</>;
};

/**
 * Converts an interval to a string
 *
 * @example
 * {unit: 'week', amount: 1} -> "per week"
 *
 * @param localizeMessageForNamespace the localize message for namespace function
 * @param [showPrefix=true] -
 * if true, returns e.g. "per week"
 * if false, returns e.g. "week"
 * @returns A localized string
 */
export const intervalToString =
  (localizeMessage: any) =>
  (interval: PaymentInterval, showPrefix = true): string => {
    const { unit, amount: count } = interval;

    switch (unit) {
      case 'day':
        return localizeMessage(showPrefix ? 'perDayWithPrefix' : 'perDay', { count });
      case 'week':
        return localizeMessage(showPrefix ? 'perWeekWithPrefix' : 'perWeek', { count });
      case 'month':
        return localizeMessage(showPrefix ? 'perMonthWithPrefix' : 'perMonth', { count });
      case 'year':
        return localizeMessage(showPrefix ? 'perYearWithPrefix' : 'perYear', { count });
      default:
        throw Error('This could never happen, if PaymentInterval is typed correctly');
    }
  };
