import { LocalizedMessage } from '@client/internationalization';
import type { Market } from '@client/modules/app-context/constants';
import { hasAlphanumericPostalCode } from '@client/modules/app-context/constants';
import type { UserInformationForm } from '@client/modules/cancellation/models';
import grid from '@client/styles/grid.css';
import { emailValidation } from '@client/utils/email';
import { postalCodeValidation } from '@client/utils/postalCode/validation';
import { makeStyles } from '@material-ui/core';
import { RHTextInput } from '@minna-technologies/minna-ui/components/Form/RHTextInput';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import type { ChangeEvent, FC } from 'react';
import React from 'react';

const cx = classNames.bind(grid);

const useStyles = makeStyles({
  emailContainer: {
    position: 'relative',
  },
});

interface Props {
  handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    property: keyof UserInformationForm
  ): void;
  formData: UserInformationForm;
  marketHasPersonalIdentifier: boolean;
  disableUsernameInputs?: boolean;
  market: Market;
}

export const EditUserInfo: FC<Props> = (
  { handleChange, formData, marketHasPersonalIdentifier, market, disableUsernameInputs = false },
  { localizeMessage }
) => {
  const classes = useStyles();

  return (
    <>
      <div className={cx('row')}>
        <div className={cx('col-xs-6', 'col-sm-3')}>
          <RHTextInput
            name="firstName"
            validation={{ required: localizeMessage('userInfoRequiredFirstNameError') }}
            label={<LocalizedMessage id="userInfoFirstNameInputLabel" />}
            defaultValue={formData.firstName}
            disabled={disableUsernameInputs}
            onChange={(event) => handleChange(event, 'firstName')}
            fullWidth
            type="text"
            inputMode="text"
            autoComplete="given-name"
          />
        </div>
        <div className={cx('col-xs-6', 'col-sm-3')}>
          <RHTextInput
            name="lastName"
            validation={{ required: localizeMessage('userInfoRequiredLastNameError') }}
            label={<LocalizedMessage id="userInfoLastNameInputLabel" />}
            defaultValue={formData.lastName}
            disabled={disableUsernameInputs}
            onChange={(event) => handleChange(event, 'lastName')}
            fullWidth
            type="text"
            inputMode="text"
            autoComplete="family-name"
          />
        </div>
        {marketHasPersonalIdentifier && (
          <div className={cx('col-xs-6', 'col-sm-3')}>
            <RHTextInput
              label={<LocalizedMessage id="userInfoPersonalNumberLabel" />}
              name="personalIdentityNumber"
              disabled
              defaultValue={formData.personalIdentityNumber}
              fullWidth
            />
          </div>
        )}
      </div>
      <div className={cx('row')}>
        <div className={cx('col-xs-12', 'col-sm-6', classes.emailContainer)}>
          <RHTextInput
            label={<LocalizedMessage id="userInfoEmailLabel" />}
            name="email"
            type="email"
            inputMode="email"
            autoComplete="email"
            defaultValue={formData.email}
            validation={{
              required: localizeMessage('userInfoRequiredEmailError'),
              validate: (value: string) => emailValidation(value, localizeMessage('userInfoEmailError')),
            }}
            onChange={(event) => handleChange(event, 'email')}
            fullWidth
          />
        </div>
        <div className={cx('col-xs-12', 'col-sm-6')}>
          <RHTextInput
            name="careOf"
            label={<LocalizedMessage id="userInfoCareOfInputLabel" />}
            defaultValue={formData.careOf}
            onChange={(event) => handleChange(event, 'careOf')}
            fullWidth
          />
        </div>
      </div>
      <div className={cx('row')}>
        <div className={cx('col-xs-12', 'col-sm-6')}>
          <RHTextInput
            name="street"
            data-test="street-field"
            type="text"
            inputMode="text"
            autoComplete="street-address"
            validation={{ required: localizeMessage('userInfoRequiredStreetError') }}
            label={<LocalizedMessage id="userInfoStreetInputLabel" />}
            placeholder={localizeMessage('userInfoStreetInputHintText')}
            defaultValue={formData.street}
            onChange={(event) => handleChange(event, 'street')}
            fullWidth
          />
        </div>
        <div className={cx('col-xs-6', 'col-sm-3')}>
          <RHTextInput
            name="postalCode"
            type="text"
            inputMode={hasAlphanumericPostalCode(market) ? 'text' : 'numeric'}
            autoComplete="postal-code"
            label={<LocalizedMessage id="userInfoPostalCode" />}
            defaultValue={formData.postalCode}
            validation={{
              required: localizeMessage('userInfoRequiredPostalCodeError'),
              validate: (value: string) =>
                postalCodeValidation(market, localizeMessage('userInfoPostalCodeError'), value),
            }}
            onChange={(event) => handleChange(event, 'postalCode')}
            fullWidth
          />
        </div>
        <div className={cx('col-xs-6', 'col-sm-3')}>
          <RHTextInput
            name="city"
            data-test="city-field"
            type="text"
            inputMode="text"
            autoComplete="address-level2"
            validation={{ required: localizeMessage('userInfoRequiredCityError') }}
            label={<LocalizedMessage id="userInfoCityInputLabel" />}
            defaultValue={formData.city}
            onChange={(event) => handleChange(event, 'city')}
            fullWidth
          />
        </div>
      </div>
    </>
  );
};

EditUserInfo.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
