export interface UserInformationForm {
  personalIdentityNumber?: string;
  firstName?: string;
  lastName?: string;
  street?: string;
  careOf?: string;
  postalCode?: string;
  city?: string;
  email?: string;
  emailVerified?: boolean;
  prefilled?: boolean;
}

export enum CancellationState {
  New = 'New',
  SentToSupplier = 'SentToSupplier',
  SupplierDeclined = 'SupplierDeclined',
  ReceivedSupplierEmail = 'ReceivedSupplierEmail',
  EmailToSupplierBounced = 'EmailToSupplierBounced',
  WaitingForUserInput = 'WaitingForUserInput',
  Finished = 'Finished',
}

export enum CancellationOutcome {
  Terminated = 'Terminated',
  AlreadyTerminated = 'AlreadyTerminated',
  ConfirmedByUser = 'ConfirmedByUser',
  CancelledWithUpcomingPayments = 'CancelledWithUpcomingPayments',
  NoSuchAccount = 'NoSuchAccount',
  HasBindingPeriod = 'HasBindingPeriod',
  NoAnswerFromSupplier = 'NoAnswerFromSupplier',
  NoAnswerFromUser = 'NoAnswerFromUser',
  NotACancellableSubscription = 'NotACancellableSubscription',
  PayingThroughThirdParty = 'PayingThroughThirdParty',
  ManuallyClosed = 'ManuallyClosed',
  Aborted = 'Aborted',
  CancellationSent = 'CancellationSent',
  Cancelled = 'Cancelled',
  UserNotTheOwner = 'UserNotTheOwner',
  WrongUserInput = 'WrongUserInput',
  ServiceProviderContactedUser = 'ServiceProviderContactedUser',
}

export const successfulCancellationOutcomes = [
  CancellationOutcome.Terminated,
  CancellationOutcome.AlreadyTerminated,
  CancellationOutcome.ConfirmedByUser,
  CancellationOutcome.CancelledWithUpcomingPayments,
];

export const failedCancellationOutcomes = [
  CancellationOutcome.NoSuchAccount,
  CancellationOutcome.HasBindingPeriod,
  CancellationOutcome.NoAnswerFromUser,
  CancellationOutcome.NotACancellableSubscription,
  CancellationOutcome.NoAnswerFromSupplier,
  CancellationOutcome.PayingThroughThirdParty,
  CancellationOutcome.UserNotTheOwner,
  CancellationOutcome.WrongUserInput,
  CancellationOutcome.ServiceProviderContactedUser,
];

export enum CancellationConfirmationDialog {
  NotCancellable = 'NotCancellable',
  ElectricityGrid = 'ElectricityGrid',
  Insurance = 'Insurance',
  SaveDesk = 'SaveDesk',
}
