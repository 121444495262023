import { LocalizedMessage, LocalizedMessageWithElementsInjected } from '@client/internationalization';
import type { HelpCenter } from '@client/modules/app-context';
import type { Market } from '@client/modules/app-context/constants';
import { OnlineOfflineIcon } from '@client/modules/shared-components/icons/OnlineOfflineIcon/OnlineOfflineIcon';
import { getMarketSpecificSupportHours } from '@client/utils/support';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardRowLink } from '@minna-technologies/minna-ui/components/CardRowLink';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import { ChatIcon } from '@minna-technologies/minna-ui/icons/Chat';
import { EmailIcon } from '@minna-technologies/minna-ui/icons/Email';
import classNames from 'classnames';
import { ListItem } from 'material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import phoneIcon from '../../../icons/phone-call.svg';
import { ZendeskSubHeader } from '../../../ZendeskSubHeader';

const useStyles = makeStyles((theme) => ({
  contactUsCard: {
    padding: theme.spacing(2, 0, 1),
  },
  contactUsCardLinkBody: {
    paddingLeft: theme.spacing(1),
  },
  openHoursSubtitle: {
    margin: theme.spacing(0.5, 0),
  },
  forceNoBottomPadding: {
    '& div': {
      paddingBottom: '0 !important',
      '& div': {
        paddingBottom: '0 !important',
      },
    },
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr',
    alignItems: 'center',
    marginLeft: 0,
  },
  paper: {
    padding: theme.spacing(2, 2, 1, 0),
    flex: 1,
  },
  disabled: {
    color: 'gray',
    backgroundColor: 'transparent !important',
    cursor: 'default !important',
  },
  paddingLeft: {
    paddingLeft: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  leftContainer: {
    alignSelf: 'flex-start',
  },
  rightContainer: {
    alignSelf: 'center',
    justifySelf: 'center',
    marginRight: '0 !important',
  },
  statusLabel: {
    fontSize: '12px',
    marginLeft: '4px',
  },
}));

interface ZendeskContactUsProps {
  helpCenter: HelpCenter;
  onNavigateToCallUs: () => void;
  onNavigateToCallBack: () => void;
  onNavigateToEmailUs: () => void;
  onNavigateToChat: () => void;
  isAgentAvailable: boolean;
  market: Market;
}

/* eslint-disable  */
export const ZendeskContactUs: React.FC<ZendeskContactUsProps> = (
  {
    helpCenter,
    isAgentAvailable,
    onNavigateToChat,
    onNavigateToCallUs,
    onNavigateToCallBack,
    onNavigateToEmailUs,
    market,
  }: any,
  { localizeMessage }: any
) => {
  const classes = useStyles();
  const { colors } = useTheme();
  const iconPhoneStyle = { width: '56px', height: '56px', marginTop: '20px' };
  const chatOfflineClassName = !isAgentAvailable && classes.disabled;

  const isCallBackAvailable = (() => {
    const dateNow = new Date();
    const dayNumber = dateNow.getDay();
    const hourNow = dateNow.getHours();
    const minutesNow = dateNow.getMinutes();
    if (dayNumber === 0 || dayNumber === 6) {
      return false;
    } else if (hourNow > 15) {
      return false;
    } else if (hourNow === 15 && minutesNow >= 30) {
      return false;
    } else {
      return true;
    }
  })();

  const callBackNotAvialableClassName = !isCallBackAvailable && classes.disabled;

  const marketSpecificSupportHours = getMarketSpecificSupportHours(market);
  const supportHoursLocalizedMessage = (
    <LocalizedMessage
      id="contactUsSectionOpenHours"
      values={{
        mondayOpeningHour: marketSpecificSupportHours.mondayOpeningHour,
        lunchBreakStartingHour: marketSpecificSupportHours.lunchBreakStartingHour,
        lunchBreakEndingHour: marketSpecificSupportHours.lunchBreakEndingHour,
        openingHour: marketSpecificSupportHours.openingHour,
        closingHour: marketSpecificSupportHours.closingHour,
      }}
    />
  );

  const onlineOfflineMessage = (isFutureAvailable: any) => {
    return (
      <span data-test={isFutureAvailable ? 'online' : 'offline'}>
        <OnlineOfflineIcon online={isFutureAvailable} style={{ marginLeft: '2px', marginBottom: '1px' }} />
        <span className={classes.statusLabel}>
          {isFutureAvailable ? (
            <LocalizedMessage id="contactUsSectionAgentOnline" />
          ) : (
            <LocalizedMessage id="contactUsSectionAgentOffline" />
          )}
        </span>
      </span>
    );
  };

  return (
    <Card className={classes.contactUsCard} variant="border">
      <div data-test="zendesk-contact-us-header" className={classes.paddingLeft}>
        <ZendeskSubHeader title={<LocalizedMessage id="contactUsSectionTitleV2" />} />
      </div>
      {helpCenter.chatEnabled && (
        <>
          <CardRowLink
            body={
              <div data-test="zendesk-support-chat" className={classes.contactUsCardLinkBody}>
                <Subheading2>
                  <LocalizedMessageWithElementsInjected
                    id="contactUsSectionChatTitle"
                    elements={{ chatOnlineOffline: onlineOfflineMessage(isAgentAvailable) }}
                  />
                </Subheading2>
                <Caption className={classes.marginBottom}>
                  {/* Value of the key should be a space character in the code. For more information: https://minnatechnologies.atlassian.net/wiki/spaces/GOB/pages/1505722506/Zendesk+widget*/}
                  <LocalizedMessage id="chatTemporaryMessagePlaceholder" />
                </Caption>
                <Body color={colors.textOn.surfaceSubdued} className={classes.openHoursSubtitle}>
                  <LocalizedMessage id="contactUsSectionCallUsOpenHoursSubtitleV2" />
                </Body>
                <Caption color={colors.textOn.surfaceSubdued}>{supportHoursLocalizedMessage}</Caption>
              </div>
            }
            onClick={isAgentAvailable ? onNavigateToChat : undefined}
            icon={<ChatIcon />}
            className={classNames(classes.forceNoBottomPadding, chatOfflineClassName)}
          ></CardRowLink>
          <Divider slim />
        </>
      )}

      {helpCenter.callUsEnabled && (
        <ListItem
          key="callUs"
          data-test="zendesk-call-us-list-item"
          leftIcon={
            <img
              src={phoneIcon}
              alt={localizeMessage('contactUsSectionPhoneIconAltDescription')}
              style={iconPhoneStyle}
            />
          }
          className={classes.forceNoBottomPadding}
          onClick={onNavigateToCallUs}
        >
          <div className={classes.paddingLeft}>
            <div className={classes.listItem}>
              <div className={classes.leftContainer}>
                <Body>
                  <LocalizedMessage id="contactUsSectionCallUsTitleV2" />
                </Body>
                <Caption className={classes.marginBottom}>
                  {/* Value of the key should be a space character in the code. For more information: https://minnatechnologies.atlassian.net/wiki/spaces/GOB/pages/1505722506/Zendesk+widget*/}
                  <LocalizedMessage id="callUsTemporaryMessagePlaceholder" />
                </Caption>
                <Body color={colors.textOn.surfaceSubdued} className={classes.openHoursSubtitle}>
                  <LocalizedMessage id="contactUsSectionCallUsOpenHoursSubtitleV2" />
                </Body>
                <Caption color={colors.textOn.surfaceSubdued}>{supportHoursLocalizedMessage}</Caption>
              </div>
              <ListItemIcon className={classes.rightContainer}>
                <ChevronRight />
              </ListItemIcon>
            </div>
            <Divider />
          </div>
        </ListItem>
      )}
      {helpCenter.callBackEnabled && (
        <ListItem
          key="callBack"
          data-test="zendesk-call-back-list-item"
          leftIcon={
            <img
              src={phoneIcon}
              alt={localizeMessage('contactUsSectionPhoneIconAltDescription')}
              style={iconPhoneStyle}
            />
          }
          className={classNames(classes.forceNoBottomPadding, callBackNotAvialableClassName)}
          onClick={isCallBackAvailable ? onNavigateToCallBack : null}
          disableTouchRipple={!isCallBackAvailable}
        >
          <div className={classes.paddingLeft}>
            <div className={classes.listItem}>
              <div className={classes.leftContainer}>
                <Body color={colors.textOn.surfaceSubdued}>
                  <LocalizedMessageWithElementsInjected
                    id="contactUsSectionCallBackTitle"
                    elements={{ callBackOnlineOffline: onlineOfflineMessage(isCallBackAvailable) }}
                  />
                </Body>
                <Caption className={classes.marginBottom}>
                  {/* Value of the key should be a space character in the code. For more information: https://minnatechnologies.atlassian.net/wiki/spaces/GOB/pages/1505722506/Zendesk+widget*/}
                  <LocalizedMessage id="callbackTemporaryMessagePlaceholder" />
                </Caption>
                <Body className={classes.openHoursSubtitle}>
                  <LocalizedMessage id="contactUsSectionCallBackOpenHoursSubtitle" />
                </Body>
                <Caption color={colors.textOn.surfaceSubdued}>{supportHoursLocalizedMessage}</Caption>
              </div>
              {isCallBackAvailable && (
                <ListItemIcon className={classes.rightContainer} data-test="chevron-right-icon">
                  <ChevronRight />
                </ListItemIcon>
              )}
            </div>
            <Divider />
          </div>
        </ListItem>
      )}
      {helpCenter.emailUsEnabled && (
        <CardRowLink
          key="emailUs"
          data-test="zendesk-email-us-list-item"
          icon={<EmailIcon />}
          className={classes.forceNoBottomPadding}
          onClick={onNavigateToEmailUs}
          body={
            <div className={classes.contactUsCardLinkBody}>
              <Subheading2>
                <LocalizedMessage id="contactUsSectionEmailUsTitle" />
              </Subheading2>
              <Caption>
                {/* Value of the key should be a space character in the code. For more information: https://minnatechnologies.atlassian.net/wiki/spaces/GOB/pages/1505722506/Zendesk+widget*/}
                <LocalizedMessage id="emailTemporaryMessagePlaceholder" />
              </Caption>
              <Body color={colors.textOn.surfaceSubdued} className={classes.openHoursSubtitle}>
                <LocalizedMessage id="contactUsSectionEmailUsDescriptionSubtitleV2" />
              </Body>
              <Caption color={colors.textOn.surfaceSubdued}>
                <LocalizedMessage id="contactUsSectionEmailUsDescriptionV2" />
              </Caption>
            </div>
          }
        ></CardRowLink>
      )}
    </Card>
  );
};

ZendeskContactUs.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
