import { LocalizedMessage } from '@client/internationalization';
import { clickedCancellationConfirmationInsuranceDialogButtonMixpanelEvent } from '@client/tracking/mixpanel-events';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';

export const InsuranceCancellationInformationDialog: React.FunctionComponent<
  InsuranceCancellationInformationDialogProps
> = ({ open, onClose, onContinue }) => {
  const handleTerminateClick = () => {
    onContinue();
    clickedCancellationConfirmationInsuranceDialogButtonMixpanelEvent('Continue');
  };

  const handleClose = () => {
    onClose();
    clickedCancellationConfirmationInsuranceDialogButtonMixpanelEvent('Go back');
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={<LocalizedMessage id="insuranceCancellationDialogTitle" />}
      button1={
        <PrimaryButton
          label={<LocalizedMessage id="insuranceCancellationDialogCancelButtonContinue" />}
          onClick={handleTerminateClick}
        />
      }
      button2={
        <SecondaryButton
          label={<LocalizedMessage id="insuranceCancellationDialogCancelButton" />}
          onClick={handleClose}
        />
      }
    >
      <Body>
        <LocalizedMessage id="insuranceCancellationDialogDescription" />
      </Body>
    </Modal>
  );
};

interface InsuranceCancellationInformationDialogProps {
  open: boolean;
  onClose(): void;
  onContinue(): void;
}
