import { LocalizedMessage } from '@client/internationalization';
import { makeStyles } from '@material-ui/core';
import { WhitePrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/WhitePrimaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';

const useStyles = makeStyles({
  noActionTextContainer: {
    padding: '8px 0 8px 0',
  },
});

interface Props {
  categoryName: string;
  supplierName: string;
  hasTerminationInstructions: boolean;
  onClick(): void;
}

export const NoActionText: React.FunctionComponent<Props> = ({
  categoryName,
  supplierName,
  hasTerminationInstructions,
  onClick,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.noActionTextContainer}>
      <div style={{ padding: '10% 0 10% 0', margin: '0 10% 0 10%' }}>
        <Body>
          {hasTerminationInstructions ? (
            <LocalizedMessage id="cannotCancelSupplierOnMinnaReadMore" values={{ supplierName: supplierName }} />
          ) : (
            <LocalizedMessage
              id="cannotCancelSupplierOnMinna2"
              values={{ categoryName: categoryName, supplierName: supplierName }}
            />
          )}
        </Body>
      </div>
      {hasTerminationInstructions && (
        <div style={{ padding: '23px' }}>
          <WhitePrimaryButton onClick={onClick} label={<LocalizedMessage id="readMore" />} />
        </div>
      )}
    </div>
  );
};
