import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { SigningFeature } from '@client/modules/app-context';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardHeader } from '@minna-technologies/minna-ui/components/Card/CardHeader';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';
import { useSelector } from 'react-redux';
import { LoaDocumentContainer } from '../LoaDocument';

const useStyles = makeStyles(() => ({
  previewButton: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
  },
  aBtestMessage: {
    margin: '16px',
  },
}));

interface SigningSectionProps {
  contractId?: string;
  merchantId?: string;
  serviceId?: string;
  supplierName: string;

  onPreviewLoa(requestPreviewLoa: () => void): void;
}

const SigningSectionInner: React.FunctionComponent<SigningSectionProps> = ({
  supplierName,
  onPreviewLoa,
  contractId,
  merchantId,
  serviceId,
}) => {
  const classes = useStyles();
  const features = useSelector(selectorFeatures);

  return (
    <Card>
      <CardHeader
        title={
          features.signing === SigningFeature.NEMID ? (
            <LocalizedMessage id="signLoaTitleNemId" />
          ) : (
            <LocalizedMessage id="signLoaTitle" />
          )
        }
      />

      <Body>
        <LocalizedMessage id="signLoaDescriptionText" values={{ supplierName }} />
      </Body>

      <div className={classes.previewButton}>
        <LoaDocumentContainer
          onPreviewLoa={onPreviewLoa}
          contractId={contractId}
          merchantId={merchantId}
          serviceId={serviceId}
        />
      </div>
    </Card>
  );
};

export const SigningSection = withLocalization('termination/TerminationFormPage')(SigningSectionInner);
