import type { AppType, Market } from '@client/modules/app-context/constants';
import { OnboardingCancelProductPages } from '@client/modules/auth/OnboardingCancelProduct/OnboardingCancelProductPages';
import { subscriptionContractRoutePrefix } from '@client/routes';
import MultiDeviceLayout from '@client/shared-components/layout/MultiDeviceLayout';
import { TrackingPageName } from '@client/tracking/mixpanel-constants';
import { closedOnboardingMixpanelEvent, finishedOnboardingMixpanelEvent } from '@client/tracking/mixpanel-events';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import { Drawer } from '@minna-technologies/minna-ui/components/Drawer';
import React, { useState } from 'react';

export const OnboardingCancelProductComponent = (props: {
  onboardingCancelProductShown?: string;
  onOnboardingCancelProductShownAction: () => void;
  market: Market;
  appType: AppType;
}) => {
  const { onboardingCancelProductShown, onOnboardingCancelProductShownAction, market, appType } = props;
  const [isOnboardingCancelProductOpen, setIsOnboardingCancelProductOpen] = useState(true);
  const pageName = RegExp(subscriptionContractRoutePrefix).test(window.location.pathname)
    ? TrackingPageName.CONTRACT_DETAILS
    : TrackingPageName.SUBSCRIPTION_DETAILS;

  const onClose = () => {
    setIsOnboardingCancelProductOpen(false);
    onOnboardingCancelProductShownAction();
    closedOnboardingMixpanelEvent(pageName, market, appType);
  };

  const onFinishOnboarding = () => {
    setIsOnboardingCancelProductOpen(false);
    onOnboardingCancelProductShownAction();
    finishedOnboardingMixpanelEvent(pageName, market, appType);
  };

  useMountEffect(() => {
    return () => onOnboardingCancelProductShownAction();
  });

  return onboardingCancelProductShown === undefined ? (
    <MultiDeviceLayout>
      <Drawer open={isOnboardingCancelProductOpen} onClose={onClose}>
        <OnboardingCancelProductPages onClose={onClose} onFinishOnboarding={onFinishOnboarding} />
      </Drawer>
    </MultiDeviceLayout>
  ) : null;
};
