import { Sentry } from '@client/assets/js/sentry';
import { onEnter } from '@client/containers/container-helpers/onEnter';
import { onExit } from '@client/containers/container-helpers/onExit';
import type { Locale } from '@client/modules/app-context/constants';
import { selectorLocale } from '@client/modules/app-context/duck';
import type { NemIdSigningPageProps } from '@client/modules/e-signing/nem-id/component';
import { NemIdSigningPageComponent } from '@client/modules/e-signing/nem-id/component';
import { NemIdErrorPage } from '@client/modules/e-signing/nem-id/components/NemIdErrorPage';
import { nemIdClientBaseUrl, nemIdClientUrl } from '@client/modules/e-signing/nem-id/constants';
import type { NemIdResponseWithResourceRequest, SignResourceRequest } from '@client/modules/e-signing/server-api';
import { createResource } from '@client/modules/e-signing/server-api';
import { conditionalRender } from '@client/modules/fetching/conditionalRender';
import { selectorContract, selectorSubscription } from '@client/modules/subscription';
import type { Contract, ContractId, Subscription } from '@client/modules/subscription/types';
import type { AxiosError } from 'axios';
import type { History } from 'history';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, withState } from 'recompose';
import type { Dispatch } from 'redux';

export interface NemIdSigningProps {
  contractId: string;
  signingId: string;
  resourceToSignId: string;
  onSigningComplete(): void;
  history: History<any>;
  nemIdSigningParameters: Record<string, unknown>;
  signResourceRequest: SignResourceRequest;
}

type ConnectOwnProps = {
  history: History;
  errorCode?: string;
  setErrorCode(errorCode: string): void;
} & NemIdSigningProps;

interface StateProps {
  nemIdUrl: string;
  contract?: Contract;
  subscription?: Subscription;
  locale: Locale;
}

interface DispatchProps {
  onBackClick(): void;
}

type MergeProps = NemIdSigningProps &
  ConnectOwnProps &
  StateProps &
  DispatchProps & {
    onNemIDIFrameMessage(event: MessageEvent): void;
  };

function mapStateToProps(state: any, { contractId }: ConnectOwnProps): StateProps {
  return {
    nemIdUrl: nemIdClientUrl(),
    contract: selectorContract(state, contractId),
    subscription: selectorSubscription(state),
    locale: selectorLocale(state),
  };
}

const mapDispatchToProps = (dispatch: Dispatch, { history }: ConnectOwnProps): DispatchProps => ({
  onBackClick: () => {
    history.goBack();
  },
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: ConnectOwnProps): MergeProps => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onNemIDIFrameMessage: onNemIDMessage(
    stateProps.nemIdUrl,
    ownProps.contractId,
    ownProps.onSigningComplete,
    ownProps.setErrorCode,
    ownProps.nemIdSigningParameters,
    ownProps.signResourceRequest
  ),
});

interface PostMessageProps {
  command: string;
  content: string;
}

const onNemIDMessage =
  (
    nemIdUrl: string,
    contractId: ContractId,
    onSigningComplete: () => void,
    setErrorCode: (errorCode: string) => void,
    nemIdSigningParameters: Record<string, unknown>,
    signResourceRequest: SignResourceRequest
  ) =>
  (event: MessageEvent) => {
    if (event.origin !== nemIdClientBaseUrl) {
      throw new Error(`Received message from unexpected origin when communicating with NemId iframe: ${event.origin}`);
    }

    const nemIdIframe = document.getElementById('nemid_iframe');
    if (!nemIdIframe) {
      throw new Error('Could not find NemId iframe on the page.');
    }
    const iFrameContentWindow = (nemIdIframe as HTMLIFrameElement).contentWindow;
    if (!iFrameContentWindow) {
      throw new Error('Could not find content window on NemId iframe.');
    }

    const incomingMessage = JSON.parse(event.data as string);
    if (incomingMessage.command === 'SendParameters') {
      const outgoingMessage: PostMessageProps = {
        command: 'parameters',
        content: JSON.stringify(nemIdSigningParameters),
      };
      iFrameContentWindow.postMessage(JSON.stringify(outgoingMessage), nemIdUrl);
    } else if (incomingMessage.command === 'changeResponseAndSubmit') {
      const nemIdResponseWithResourceRequest: NemIdResponseWithResourceRequest = {
        nemIdResponse: {
          encodedResponse: incomingMessage.content,
          signingStartedAt: new Date(),
        },
        ...signResourceRequest,
      };
      createResource(contractId, nemIdResponseWithResourceRequest)
        .then(onSigningComplete)
        .catch((error: AxiosError) =>
          onError(error, 'Failed to create resource with NemId signing verification.', setErrorCode)
        );
    }
  };

const onError = (error: AxiosError, message: string, setErrorCode: (errorCode: string) => void) => {
  Sentry.captureExceptionWithMessage(error, message);
  const responseData = error.response && error.response.data;
  if (responseData) {
    setErrorCode(responseData as string);
  } else {
    setErrorCode('GeneralError');
  }
};

export const NemIdSigning = compose<NemIdSigningPageProps, NemIdSigningProps>(
  withRouter,
  withState<{ history: History } & NemIdSigningProps, string | undefined, 'errorCode', 'setErrorCode'>(
    'errorCode',
    'setErrorCode',
    undefined
  ),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  onEnter((mergedProps: MergeProps) => {
    if (window.addEventListener) {
      window.addEventListener('message', mergedProps.onNemIDIFrameMessage);
    }
  }),
  onExit((mergedProps: MergeProps) => {
    if (window.removeEventListener) {
      window.removeEventListener('message', mergedProps.onNemIDIFrameMessage);
    }
  }),
  conditionalRender((props: MergeProps) => Boolean(props.errorCode), NemIdErrorPage)
)(NemIdSigningPageComponent);
