import { LocalizedMessage } from '@client/internationalization';
import type { SaveDeskOfferForUser } from '@client/modules/saveDesk/api';
import type { SubscriptionSupplier } from '@client/modules/subscription/types';
import type { WithStyles } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { PositiveButton } from '@minna-technologies/minna-ui/components/Buttons/PositiveButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import React from 'react';

const styles = () =>
  createStyles({
    card: {
      marginTop: '24px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    offerTitle: {
      margin: '16px 0',
      textAlign: 'center',
    },
    caption: {
      marginTop: '12px',
    },
  });

export interface SaveDeskOfferDialogComponentProps {
  dialogOpen: boolean;
  onGoToDetailsPage(): void;
  offer: SaveDeskOfferForUser;
  onGoToCancellationFlow(): void;
  provider: SubscriptionSupplier;
  closeDialog(): void;
}

const SaveDeskOfferDialogComponentInner: React.FunctionComponent<
  SaveDeskOfferDialogComponentProps & WithStyles<typeof styles>
> = ({ classes, dialogOpen, onGoToDetailsPage, onGoToCancellationFlow, provider, closeDialog, offer }) => {
  const { colors } = useTheme();

  return (
    <Modal
      open={dialogOpen}
      title={<LocalizedMessage id="saveDeskOfferDialogTitle" values={{ providerName: provider.name }} />}
      button1={
        <PositiveButton
          label={<LocalizedMessage id="saveDeskOfferDialogGoToDetailButton2" />}
          onClick={onGoToDetailsPage}
        />
      }
      button2={
        <SecondaryButton
          label={<LocalizedMessage id="saveDeskOfferDialogGoToCancelButton" />}
          onClick={onGoToCancellationFlow}
        />
      }
      onClose={closeDialog}
    >
      <Body>
        <LocalizedMessage id="saveDeskOfferDialogDescription2" />
      </Body>
      <Card className={classes.card} variant="border" borderColor={colors.action.positive}>
        <img src={provider.logoUrl} alt={provider.name} height="48px" />
        <Headline2 className={classes.offerTitle}>{offer.name}</Headline2>
        <Body color={colors.textOn.surfaceSubdued}>{offer.description}</Body>
        {offer.disclaimer && (
          <Caption color={colors.base.border} className={classes.caption}>
            {offer.disclaimer}
          </Caption>
        )}
      </Card>
    </Modal>
  );
};

export const SaveDeskOfferDialogComponent = withStyles(styles)(SaveDeskOfferDialogComponentInner);
