import { LazyLoadingBox } from '@client/modules/LazyLoadingBox';

/**
 * The HistoryGraph contains the recharts and D3 library which is a heavy load. It is beneficial for the initial load time for the user to postpone loading this component.
 */
export const LazyLoadedHistoryGraph = LazyLoadingBox({
  loader: async () =>
    import(/* webpackChunkName: "subscription-history-graph" */ './HistoryGraphContainer').then(
      (module) => module.HistoryGraphContainer
    ),
  height: '126px',
});
