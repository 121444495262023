// @ts-ignore js import, remove this when the import is typed
import InputValidationHOC from '@client/modules/shared-components/form-validation/InputValidationHOC';
import type { InputValidationProps } from '@client/modules/shared-components/form-validation/InputValidationHOC/types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { default as MUIRadioButton } from '@material-ui/core/Radio';
import createStyles from '@material-ui/core/styles/createStyles';
import type { WithStyles } from '@material-ui/core/styles/withStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import omit from 'lodash/omit';
import values from 'lodash/values';
import * as React from 'react';
import { compose, defaultProps, mapProps } from 'recompose';

interface ExtendedProps {
  transformBlurValue?(value: any, valid?: boolean, errors?: Record<string, unknown> | any[]): string;

  requiredErrorText?: React.ReactNode | string;
  validators?: any;

  showErrorOnBlur?(): void | boolean;

  showErrorOnChange?(): void | boolean;

  clear?: boolean;
  errorText?: React.ReactNode | string;
}

interface RadioButtonProps {
  onChange(event: any, value: string | number | boolean): void;
  checked: boolean | string;
  value?: string | number | boolean;
  label?: React.ReactNode;
  name?: string;
}

type RadioGroupInnerProps = RadioGroupProps & InputValidationProps & WithStyles<typeof styles>;

const inputValidationProps = defaultProps({
  showErrorOnBlur: true,
  layout: 'column',
});

type Layout = 'row' | 'column';

type RadioGroupProps = {
  children: React.ReactNode[];
  name: string;
  layout?: Layout;
  value?: string;
} & ExtendedProps;

const requiredToValidatorsProps = mapProps(({ requiredErrorText, validators, ...props }: RadioGroupInnerProps) => {
  if (requiredErrorText) {
    const updatedValidators = {
      required(value: boolean) {
        return value ? false : requiredErrorText;
      },
      ...validators,
    };

    return { validators: updatedValidators, ...props };
  } else {
    return { validators, ...props };
  }
});

export const RadioButton: React.FunctionComponent<RadioButtonProps> = ({
  checked,
  label,
  onChange,
  name,
  ...props
}) => {
  return (
    <FormControl>
      <FormControlLabel
        control={<MUIRadioButton checked={Boolean(checked)} onChange={onChange} {...props} />}
        name={name}
        label={label}
      />
    </FormControl>
  );
};

const styles = () =>
  createStyles({
    row: {
      flexDirection: 'row',
    },
  });

const RadioGroupInner: React.FunctionComponent<RadioGroupInnerProps> = ({
  children,
  name,
  errors,
  errorText,
  classes,
  layout,
  ...props
}) => {
  const error = errors ? values(errors)[0] : errorText;
  const childrenWithProps = React.Children.map(children, (child: React.ReactNode) => {
    return React.cloneElement<RadioButtonProps>(child as React.ReactElement<RadioButtonProps>, {
      name: name,
    });
  });

  const cleanedProps = omit(props, [
    'clear',
    'showErrorOnBlur',
    'showErrorOnChange',
    'canShowError',
    'formSubmitted',
    'focused',
    'valid',
  ]);

  return (
    <FormControl
      error={Boolean(error)}
      {...cleanedProps}
      style={{ width: '100%' }}
      classes={{ root: layout === 'row' ? classes.row : undefined }}
    >
      {childrenWithProps}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export const RadioGroup = compose<RadioGroupInnerProps, RadioGroupProps>(
  withStyles(styles),
  requiredToValidatorsProps,
  inputValidationProps,
  InputValidationHOC
)(RadioGroupInner);
