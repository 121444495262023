import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  message: {
    padding: '12px 8px',
    marginBottom: '4px',
    borderRadius: '4px',
    fontSize: '14px',
    maxWidth: '43ch',
    wordWrap: 'break-word',
    '&.userMessage': {
      textAlign: 'right',
      backgroundColor: theme.colors.action.primary,
      color: theme.colors.base.surface,
    },
    '&.agentMessage': {
      textAlign: 'right',
      backgroundColor: theme.colors.base.background,
      color: 'rgb(113, 113, 113)',
    },
  },
}));

interface MessageProps {
  message: any;
  fromUser: boolean;
}

export const Message = ({ message, fromUser }: MessageProps) => {
  const classes = useStyles();

  const className = fromUser ? 'userMessage' : 'agentMessage';

  return <div className={classNames(classes.message, className)}>{message.msg}</div>;
};
