//@ts-ignore
import { onExit } from '@client/containers/container-helpers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { navigateToArticle } from '../../../ducks';
import { selectorArticles } from '../../../ducks/zendesk-help-center';
import {
  increaseNumberOfAdditionalArticlesToDisplay,
  resetNumberOfAdditionalArticlesToDisplay,
} from '../../../ducks/zendesk-help-center/zendesk-help-center-actions';
import {
  selectorArticle,
  selectorNumberOfAdditionalArticlesToDisplay,
} from '../../../ducks/zendesk-help-center/zendesk-help-center-selector';
import { ArticlesResult } from './component';

const mapStateToProps = (state: any) => {
  return {
    articles: selectorArticles(state),
    currentArticle: selectorArticle(state),
    numberOfAdditionalArticlesToDisplay: selectorNumberOfAdditionalArticlesToDisplay(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onNavigateToArticle: (sectionId: any, articleId: any) => {
      dispatch(navigateToArticle(sectionId, articleId));
    },
    increaseNumberOfAdditionalArticlesToDisplay: (amount: any) => {
      dispatch(increaseNumberOfAdditionalArticlesToDisplay(amount));
    },
  };
};

export const Index = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  onExit((props: any, { store: { dispatch } }: any) => {
    dispatch(resetNumberOfAdditionalArticlesToDisplay());
  })
)(ArticlesResult);

Index.propTypes = {
  articlesFilteredBy: PropTypes.string.isRequired,
};
