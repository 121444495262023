import checklist from '@client/assets/images/checklist.svg';
import cog from '@client/assets/images/cog.svg';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { OverviewBottomNavigation } from '@client/modules/overview/components/OverviewBottomNavigation';
import { menuPage, monthlyChecklist } from '@client/routes';
import { Header } from '@client/shared-components/Header';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { getMixpanel } from '@client/tracking/mixpanel';
import { FEATURE_OVERVIEW, TrackingPageName } from '@client/tracking/mixpanel-constants';
import { sendButtonClickedEvent } from '@client/tracking/mixpanel-tracking-events';
import Card from '@material-ui/core/Card/Card';
import CardActionArea from '@material-ui/core/CardActionArea/CardActionArea';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import React, { useEffect } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import styles from './styles.scss';

const Page = 'More';
const Feature = FEATURE_OVERVIEW;

const MorePageInner = ({ history }: RouteComponentProps) => {
  useEffect(() => {
    getMixpanel().track('Viewed overview', {
      Feature,
      Page,
    });
  }, []);

  return (
    <>
      <Header />
      <MultiDeviceLayout>
        <div className={styles.container}>
          <MorePageCard
            titleId="monthlyChecklistTitle"
            bodyId="monthlyChecklistBody"
            icon={checklist}
            onClick={() => {
              sendButtonClickedEvent(Feature, Page, TrackingPageName.MONTHLY_CHECKLIST);
              history.push(monthlyChecklist);
            }}
          />
          <MorePageCard
            titleId="settingsTitle"
            bodyId="settingsBody"
            icon={cog}
            onClick={() => {
              sendButtonClickedEvent(Feature, Page, 'Settings');
              history.push(menuPage);
            }}
          />
        </div>
        <OverviewBottomNavigation currentLocation={menuPage} />
      </MultiDeviceLayout>
    </>
  );
};

export const MorePage = withLocalization('subscription-alarm/MorePage')(MorePageInner);

const MorePageCard = ({
  titleId,
  bodyId,
  icon,
  onClick,
}: {
  titleId: string;
  bodyId: string;
  icon: string;
  onClick(): void;
}) => (
  <Card>
    <CardActionArea onClick={onClick}>
      <div className={styles.cardContainer}>
        <div className={styles.cardText}>
          <div className={styles.cardTitle}>
            <img className={styles.cardIcon} src={icon} alt="" role="presentation" />
            <Title>
              <LocalizedMessage id={titleId} />
            </Title>
          </div>
          <Body>
            <LocalizedMessage id={bodyId} />
          </Body>
        </div>
        <div className={styles.cardArrow}>
          <ChevronRight />
        </div>
      </div>
    </CardActionArea>
  </Card>
);
