import { LocalizedMessage } from '@client/internationalization';
import { appOpenLink } from '@client/modules/app-integration/standalone/standalone-app-integration';
import type { OrderedBroadbandProduct } from '@client/modules/switch/ordered-subscription/models';
import { InfoItem } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionDetails/components/InfoItem';
import { InfoItemGrid } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionDetails/components/InfoItemGrid';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import PropTypes from 'prop-types';
import React from 'react';

interface OrderedBroadbandProductProps {
  broadbandProduct: OrderedBroadbandProduct;
  priceWithCurrency(amount: number): string;
}

export const OrderedBroadbandProductInfo: React.FunctionComponent<OrderedBroadbandProductProps> = (
  { broadbandProduct, priceWithCurrency },
  { localizeMessage }
) => {
  const { colors } = useTheme();
  const termsLink = broadbandProduct.supplierInformation.termsUrl && (
    <a
      // nosemgrep
      href={broadbandProduct.supplierInformation.termsUrl}
      target="_blank"
      onClick={() => appOpenLink(broadbandProduct.supplierInformation.termsUrl ?? '')}
      rel="noopener noreferrer"
    >
      <Body color={colors.action.primary} variant="link">
        <LocalizedMessage id="termsLinkText" />
      </Body>
    </a>
  );

  const priceItems =
    broadbandProduct.price.discountMonths &&
    broadbandProduct.price.discountMonths > 0 &&
    broadbandProduct.price.campaignPrice ? (
      [
        <InfoItem
          key={0}
          title={
            <LocalizedMessage
              id="broadbandInitialPrice"
              values={{ discountMonths: broadbandProduct.price.discountMonths }}
            />
          }
          content={
            <LocalizedMessage
              id="costPerMonth"
              values={{ cost: priceWithCurrency(broadbandProduct.price.campaignPrice) }}
            />
          }
        />,
        <InfoItem
          key={1}
          title={<LocalizedMessage id="broadbandRegularPrice" />}
          content={
            <LocalizedMessage
              id="costPerMonth"
              values={{ cost: priceWithCurrency(broadbandProduct.price.fullMonthlyCost) }}
            />
          }
        />,
      ]
    ) : (
      <InfoItem
        title={<LocalizedMessage id="broadbandPrice" />}
        content={`${priceWithCurrency(broadbandProduct.price.fullMonthlyCost)} ${localizeMessage('costPerMonth')}`}
      />
    );

  return (
    <InfoItemGrid>
      <InfoItem title={<LocalizedMessage id="broadbandTermsText" />} content={termsLink} />
      <InfoItem
        title={<LocalizedMessage id="broadbandUploadSpeedText" />}
        content={<LocalizedMessage id="broadbandUploadSpeed" values={{ speed: broadbandProduct.speed.up }} />}
      />
      <InfoItem
        title={<LocalizedMessage id="broadbandDownloadSpeedText" />}
        content={<LocalizedMessage id="broadbandDownloadSpeed" values={{ speed: broadbandProduct.speed.down }} />}
      />
      {priceItems}
      <InfoItem
        title={<LocalizedMessage id="broadbandBindingTime" />}
        content={
          <LocalizedMessage
            id="bindingPeriodInMonths"
            values={{ months: broadbandProduct.information.bindingPeriod }}
          />
        }
      />
      <InfoItem
        title={<LocalizedMessage id="broadbandRouter" />}
        content={broadbandProduct.fees?.router ? <LocalizedMessage id="yes" /> : <LocalizedMessage id="no" />}
      />
      <InfoItem
        title={<LocalizedMessage id="broadbandStartingFeeText" />}
        content={
          <LocalizedMessage
            id="broadbandStartingFee"
            values={{ price: priceWithCurrency(broadbandProduct.fees?.startup || 0) }}
          />
        }
      />
      <InfoItem
        title={<LocalizedMessage id="broadbandType" />}
        content={broadbandProduct.networkType === 'fiber' ? <LocalizedMessage id="fiber" /> : ''}
      />
    </InfoItemGrid>
  );
};

OrderedBroadbandProductInfo.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
