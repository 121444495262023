import { ELECTRICITY_GRID } from '@client/constants/categories';
import { useLocalization } from '@client/internationalization';
import type { Question, QuestionId } from '@client/models/cancellation-questions';
import type { AppContext } from '@client/modules/app-context';
import { isCancellationWithTermsEnabled, SigningFeature } from '@client/modules/app-context';
import { CancellationDataSection } from '@client/modules/cancellation/CancellationFormPage/components/CancellationDataSection';
import { CancellationQuestionsSection } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection';
import { ConfirmSectionComponent } from '@client/modules/cancellation/CancellationFormPage/components/ConfirmSection';
import { SigningSection } from '@client/modules/cancellation/CancellationFormPage/components/SigningSection';
import { UserInfoForm } from '@client/modules/cancellation/CancellationFormPage/components/UserInfoForm';
import type { AddressInformation } from '@client/modules/cancellation/CancellationFormPage/models';
import { updateCancellationAnswers } from '@client/modules/cancellation/CancellationFormPage/models';
import { CancellationAction } from '@client/modules/cancellation/duck';
import type { UserInformationForm } from '@client/modules/cancellation/models';
import { ContractDetailsFloatingButtons } from '@client/modules/subscription/ContractDetailsPage/components/ContractDetailsFloatingButtons';
import type { Contract, Subscription } from '@client/modules/subscription/types';
import { TrackingButtonLocation, TrackingButtonName, TrackingPageName } from '@client/tracking/mixpanel-constants';
import { makeStyles } from '@material-ui/core';
import { ContractHeaderDeprecated } from '@minna-technologies/minna-ui/components/ContractHeaderDeprecated';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import isFunction from 'lodash/isFunction';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackClickedCancelContractButton, trackViewCancellationTermsDialog } from '../../mixpanel-events';
import { useState } from 'react';
import { selectorAppContext } from '@client/modules/app-context/duck';

export interface CancellationFormPageProps {
  categoryName: string;
  categoryText?: string;
  contractId: string;
  contract: Contract;
  isCancelButtonClicked: boolean;
  desiredTerminationAt: string;
  form: UserInformationForm;
  formSubmitted: boolean;
  logoUrl?: string;
  subscription: Subscription;
  merchantName: string;
  terminationAnswers: Record<string, unknown>;
  terminationQuestions: Question[];

  onBackClick?(): void;
  onFormPropertyUpdate(): void;
  onSetAnswer(questionId: string, value: any): void;
  onSendCancellation(): void;
}

const footer = (isCancelButtonClicked: boolean, onSubmitCancellationForm: (values: any) => void): JSX.Element => {
  return (
    <ContractDetailsFloatingButtons
      backButton
      isCancelButtonClicked={isCancelButtonClicked}
      signButton
      onSubmitCancellationForm={onSubmitCancellationForm}
    />
  );
};

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: 'grid',
    gridRowGap: '16px',
  },
}));

export const CancellationFormPage = ({
  isCancelButtonClicked,
  contractId,
  contract,
  subscription,
  logoUrl,
  form,
  merchantName,
  categoryName,
  categoryText,
  onFormPropertyUpdate,
  onSendCancellation,
  terminationQuestions,
  terminationAnswers,
  onSetAnswer,
  formSubmitted,
  desiredTerminationAt,
}: CancellationFormPageProps): JSX.Element => {
  const classes = useStyles();
  const { localizeMessage } = useLocalization('termination/TerminationFormPage');
  const appContext: AppContext = useSelector(selectorAppContext);

  const methods = useMinnaForm<any>();
  const { watch, getValues, trigger, errors } = methods;
  const customDeliveryDateChosen: string | undefined = watch('customDeliveryDateChosen');
  const watchRecipientQuestion = (questionId: QuestionId) => watch('recipientQuestion' + questionId);
  const watchNestedQuestion = (questionId: QuestionId) => watch('nestedQuestion' + questionId);
  const [checkboxSigningDialogOpen, setCheckboxSigningDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const merchantId = subscription?.supplier.id.toString() ?? 'supplierId not found';
  const category = contract?.service.category.name ?? 'category not found';
  const serviceId = contract?.service.id.toString() ?? 'serviceId not found';
  const method = contract?.cancellationMethod.type ?? 'method not found';
  const checkboxOrCheckboxWithProof =
    appContext.features.signing === SigningFeature.CHECKBOX ||
    appContext.features.signing === SigningFeature.CHECKBOX_WITH_PROOF;

  const maybeAddressInfo: AddressInformation = {
    street: form.street,
    city: form.city,
    careOf: form.careOf,
    postalCode: form.postalCode,
  };

  const storeFormValues = () => {
    const values = getValues();
    updateCancellationAnswers(terminationQuestions, values, dispatch, appContext.locale);
    if (values.customDeliveryDateChosen === 'CustomDate' || categoryName === ELECTRICITY_GRID) {
      dispatch(CancellationAction.setDesiredTerminationAt(values.desiredTerminationAt));
    } else {
      dispatch(CancellationAction.setDesiredTerminationAt());
    }
  };

  const onSubmitCancellationForm = () => {
    storeFormValues();
    onSendCancellation();
  };

  const onSubmitButtonClick = () => {
    trigger().then((isValidForm: boolean) => {
      if (isValidForm) {
        trackClickedCancelContractButton(
          TrackingPageName.DETAILS,
          merchantId,
          merchantName,
          category,
          contractId,
          contract.service.name,
          subscription.source,
          TrackingButtonName.SUBMIT_CANCELLATION,
          TrackingButtonLocation.BOTTOM_CENTER,
          serviceId,
          method
        );
        onSubmitCancellationForm();
      }
    });
  };

  const onSubmitButtonClickWithCheckbox = () => {
    trigger().then((isValidForm: boolean) => {
      if (isValidForm) {
        trackClickedCancelContractButton(
          TrackingPageName.DETAILS,
          merchantId,
          merchantName,
          category,
          contractId,
          contract.service.name,
          subscription.source,
          TrackingButtonName.CANCEL_NOW,
          TrackingButtonLocation.BOTTOM_CENTER,
          serviceId,
          method
        );
        setCheckboxSigningDialogOpen(true);
      }
    });
  };

  const callbackOrScrollToErrors = (callBack: () => void) => {
    trigger().then((isValidForm: boolean) => {
      if (isValidForm) {
        storeFormValues();
        callBack();
      } else {
        const errorQuestion = Object.keys(errors)[0];
        if (isFunction(errors[errorQuestion]?.ref?.scrollIntoView)) {
          errors[errorQuestion]?.ref?.scrollIntoView({ block: 'center' });
        }
      }
    });
  };

  const clickedButtonMixpanelEvent = (buttonName: TrackingButtonName, buttonLocation: TrackingButtonLocation) => {
    trackClickedCancelContractButton(
      TrackingPageName.DETAILS,
      merchantId,
      merchantName,
      category,
      contractId,
      contract?.service?.name,
      subscription?.source,
      buttonName,
      buttonLocation,
      contract.service.id,
      method
    );
  };

  const viewCancellationTermsDialogMixpanelEvent = () => {
    trackViewCancellationTermsDialog(
      TrackingPageName.DETAILS,
      merchantId,
      merchantName,
      category,
      contractId,
      contract?.service?.name,
      subscription?.source,
      TrackingButtonName.CANCEL_TERMS,
      serviceId
    );
  };

  return (
    <MultiDeviceLayout
      fullWidthContent={
        <ContractHeaderDeprecated
          providerLogo={logoUrl ?? ''}
          providerName={merchantName}
          subHeading={merchantName}
          title={categoryText ?? ''}
        />
      }
      footer={footer(
        isCancelButtonClicked,
        checkboxOrCheckboxWithProof ? onSubmitButtonClickWithCheckbox : onSubmitButtonClick
      )}
    >
      <Form onSubmit={onSubmitCancellationForm} formMethods={methods}>
        <div className={classes.cardContainer}>
          {form.prefilled && (
            <UserInfoForm
              title={localizeMessage('userFormTitle')}
              formData={form}
              onFormPropertyUpdate={onFormPropertyUpdate}
              appContext={appContext}
            />
          )}
          {terminationQuestions?.length > 0 ? (
            <CancellationQuestionsSection
              cancellationQuestions={terminationQuestions}
              terminationAnswers={terminationAnswers}
              onSetAnswer={onSetAnswer}
              formSubmitted={formSubmitted}
              userLocale={appContext.locale}
              market={appContext.market}
              addressOnUser={maybeAddressInfo}
              watchRecipientQuestion={watchRecipientQuestion}
              watchNestedQuestion={watchNestedQuestion}
            />
          ) : null}

          <CancellationDataSection
            desiredTerminationAt={desiredTerminationAt}
            customDeliveryDateChosen={customDeliveryDateChosen}
            contractCategoryName={categoryName}
            supplierName={merchantName}
          />

          {checkboxOrCheckboxWithProof ? (
            <ConfirmSectionComponent
              supplierName={merchantName}
              onPreviewLoa={callbackOrScrollToErrors}
              contractId={contract.id}
              onSendCancellation={onSendCancellation}
              useCancellationTerms={isCancellationWithTermsEnabled(appContext.features.subscriptionFeatures)}
              onValid={callbackOrScrollToErrors}
              checkboxSigningDialogOpen={checkboxSigningDialogOpen}
              setCheckboxSigningDialogOpen={setCheckboxSigningDialogOpen}
              clickedButtonMixpanelEvent={clickedButtonMixpanelEvent}
              viewCancellationTermsDialog={viewCancellationTermsDialogMixpanelEvent}
            />
          ) : (
            <SigningSection
              supplierName={merchantName}
              onPreviewLoa={callbackOrScrollToErrors}
              contractId={contractId}
            />
          )}
        </div>
      </Form>
    </MultiDeviceLayout>
  );
};
