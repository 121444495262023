import {handle} from 'redux-pack';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import {patchUserNotificationSettings, changeUserEmail, getUser} from '../../../models/user';
import {swedbankUserChangedContractChangeEmailSettingsMixpanelEvent} from '../../../tracking/mixpanel-events';
import {AppIntegration} from '../../app-integration';

export const FETCH = 'SWEDBANK_NOTIFICATION_SETTINGS/FETCH';
export const UPDATE = 'SWEDBANK_NOTIFICATION_SETTINGS/UPDATE';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case FETCH:
      return handle(state, action, {
        start: () => ({isLoading: true}),
        finish: s => ({...s, isLoading: false}),
        failure: s => ({...s, loadingFailed: true}),
        success: (s) => {
          const user = get(action, 'payload');
          return {
            ...s,
            userEmailAddress: get(user, 'emailAddress'),
            settings: get(user, 'notificationSettings')
          };
        }
      });

    case UPDATE:
      return handle(state, action, {
        start: s => ({...s, isUpdating: true, updateFailed: false, updateSucceeded: false}),
        finish: s => ({...s, isUpdating: false}),
        failure: s => ({...s, updateFailed: true}),
        success: (s) => {
          const [settings, user] = get(action, 'payload');
          return {
            ...s,
            userEmailAddress: get(user, 'emailAddress'),
            settings,
            updateSucceeded: true
          };
        }
      });

    default:
      return state;
  }
}

export const fetchNotificationSettingsAndEmail = () => {
  return {type: FETCH, promise: getUser()};
};

export const updateEmailAndNotificationSettings = (userEmailAddress, contractChangeEmailEnabled) => {
  return (dispatch) => {
    const updateNotificationPromise = () => {
      if (isNil(contractChangeEmailEnabled)) {
        return Promise.resolve();
      } else {
        return patchUserNotificationSettings({['contractChangeEmail']: contractChangeEmailEnabled}).then((notificationSettings) => {
          swedbankUserChangedContractChangeEmailSettingsMixpanelEvent(notificationSettings.contractChangeEmail);
          return notificationSettings;
        });
      }
    };

    const updateUserPromise = changeUserEmail(userEmailAddress);
    const updatePromise = Promise.all([updateNotificationPromise(), updateUserPromise]);

    dispatch({type: UPDATE, promise: updatePromise});
    updatePromise
      .then(() => {
        AppIntegration.closeApplication();
      });
  };
};

export const selectorSettings = (state) => get(state, 'swedbankNotificationSettings.settings', {});
export const selectorIsLoading = (state) => get(state, 'swedbankNotificationSettings.isLoading', false);
export const selectorLoadingFailed = (state) => get(state, 'swedbankNotificationSettings.loadingFailed', false);
export const selectorUpdateFailed = (state) => get(state, 'swedbankNotificationSettings.updateFailed', false);
export const selectorUpdateSucceeded = (state) => get(state, 'swedbankNotificationSettings.updateSucceeded', false);
export const selectorUserEmailAddress = (state) => state.swedbankNotificationSettings?.userEmailAddress;
