import { Sentry } from '@client/assets/js/sentry';
import { InsightCardNames } from '@client/tracking/mixpanel-constants';
import { getCategoryCodeForSending } from '@client/tracking/mixpanel-events';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import type { Contract, Subscription } from '../subscription/types';
import type { SubscriptionCostChanged } from './insightCardModels';
import type { Insight } from './models';
import { InsightContentType } from './models';

export function getAllCardsOnInsightsFeed(insights: Insight[]) {
  const cardNames: InsightCardNames[] = insights
    .map(getInsightCardName)
    .filter((cardName) => cardName !== InsightCardNames.OTHER_INSIGHT);

  return [...cardNames];
}

export function getTotalNumberOfInsights(insights: Insight[]) {
  return getAllCardsOnInsightsFeed(insights).length;
}

export function getNamesOfCardsInInsightsFeed(insights: Insight[]) {
  const allCards = getAllCardsOnInsightsFeed(insights);
  const uniqueCardNames = uniq(allCards);

  return [...uniqueCardNames];
}

/* eslint-disable complexity */
export function getInsightCardName(insight: Insight) {
  switch (insight.content.type) {
    case InsightContentType.BindingPeriodEnding:
      return InsightCardNames.BINDING_TIME_ENDING;
    case InsightContentType.NewSubscriptionFound:
      return InsightCardNames.NEW_SUBSCRIPTION;
    case InsightContentType.SubscriptionCostChanged: {
      const insightContent = insight.content as SubscriptionCostChanged;
      if (insightContent.newCost < insightContent.oldCost) {
        return InsightCardNames.PRICE_DECREASE;
      } else {
        return InsightCardNames.PRICE_INCREASE;
      }
    }
    case InsightContentType.CancellationOutcome:
      return InsightCardNames.CANCELLATION_OUTCOME;
    case InsightContentType.CancellationOngoing:
      return InsightCardNames.CANCELLATION_STATUS;
    case InsightContentType.SaveDeskOngoing:
      return InsightCardNames.SAVEDESK_ONGOING;
    case InsightContentType.SaveDeskOutcome:
      return InsightCardNames.SAVEDESK_OUTCOME;
    case InsightContentType.DiscoverOutcome:
      return InsightCardNames.DISCOVER_OUTCOME;
    case InsightContentType.DiscoverOngoing:
      return InsightCardNames.INDEPENDENT_ORDER_STATUS;
    case InsightContentType.PersonalMobileRecommendation:
      return InsightCardNames.RecommendationFromMonitoringPreferenceCard;
    case InsightContentType.AddonRecommendation:
      return InsightCardNames.ADDON_RECOMMENDATION;
    case InsightContentType.OnboardingInsight:
      return InsightCardNames.ONBOARDING_INSIGHT;
    case InsightContentType.UnresolvedSubscription:
      return InsightCardNames.UNRESOLVED_SUBSCRIPTION;
    default:
      // Expected that control will fall to default case if it is monthly checklist insight or action rating
      checkUnknownInsight(insight.content.type);

      return InsightCardNames.OTHER_INSIGHT;
  }
}

function checkUnknownInsight(insightType: InsightContentType) {
  Sentry.captureMessage('Received unknown insight', {
    extra: { insightType: insightType },
  });
}

export const isOnboardingInsight = (insight: Insight): boolean =>
  insight.content.type === InsightContentType.OnboardingInsight;

export function getOptimizableCategories(subscriptions: Subscription[]) {
  const optimizableContracts = flatMap(subscriptions, (subscription) =>
    filter(subscription.contracts, { optimizable: true })
  );

  return values(
    uniq(
      optimizableContracts.map((contract: Contract) =>
        getCategoryCodeForSending(get(contract, 'service.category.name'))
      )
    )
  );
}
