import type { ServiceId, SupplierId } from '@client/models/supplier';
import { SigningFeature } from '@client/modules/app-context';
import { selectorAppContext } from '@client/modules/app-context/duck';
import { BankIdSigningServicePage } from '@client/modules/cancellation/signing/BankIdSigningServicePage';
import { NoSigningServicePage } from '@client/modules/cancellation/signing/NoSigningServicePage';
import React from 'react';
import { useSelector } from 'react-redux';

export interface SigningPageProps {
  serviceId: ServiceId;
  merchantId: SupplierId;
}

export const SigningServicePage: React.FunctionComponent<SigningPageProps> = ({ merchantId, serviceId }) => {
  const appContext = useSelector(selectorAppContext);
  switch (appContext.features.signing) {
    case SigningFeature.BANKID:
      return <BankIdSigningServicePage merchantId={merchantId} serviceId={serviceId} />;
    //TODO create support for nemid
    case SigningFeature.CHECKBOX:
    case SigningFeature.CHECKBOX_WITH_PROOF:
      return <NoSigningServicePage merchantId={merchantId} serviceId={serviceId} />;
    default:
      throw new Error(`No signing page for signing feature ${appContext.features.signing} when cancelling service`);
  }
};
