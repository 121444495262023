import { LocalizedMessage } from '@client/internationalization';
import { appOpenLink } from '@client/modules/app-integration/standalone/standalone-app-integration';
import type { OrderedMobileProductLegacy } from '@client/modules/switch/ordered-subscription/models';
import { MobileDataCapacityLimitationType } from '@client/modules/switch/ordered-subscription/models';
import { InfoItem } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionDetails/components/InfoItem';
import { InfoItemGrid } from '@client/modules/switch/ordered-subscription/OrderedSubscriptionDetails/components/InfoItemGrid';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import PropTypes from 'prop-types';
import React from 'react';

interface OrderedMobileProductProps {
  mobileProduct: OrderedMobileProductLegacy;
  priceWithCurrency(amount: number): string;
}

export const OrderedMobileProductInfoLegacy: React.FunctionComponent<OrderedMobileProductProps> = (
  { mobileProduct, priceWithCurrency },
  { localizeCostWithCurrency }
) => {
  const openExternalSupplierWebsite = (event: any) =>
    appOpenLink(mobileProduct.supplierInformation.webSite ?? '', event);
  const { colors } = useTheme();

  const websiteLink = (
    <a
      // nosemgrep
      href={mobileProduct.supplierInformation.webSite}
      target="_blank"
      onClick={openExternalSupplierWebsite}
      rel="noopener noreferrer"
    >
      <Body color={colors.action.primary} variant="link">
        {mobileProduct.supplierInformation.name}
      </Body>
    </a>
  );

  const termsLink = mobileProduct.supplierInformation.termsUrl && (
    <a
      // nosemgrep
      href={mobileProduct.supplierInformation.termsUrl}
      target="_blank"
      onClick={() => appOpenLink(mobileProduct.supplierInformation.termsUrl ?? '')}
      rel="noopener noreferrer"
    >
      <Body color={colors.action.primary} variant="link">
        <LocalizedMessage id="termsLinkText" />
      </Body>
    </a>
  );

  const priceItems = mobileProduct.campaign ? (
    [
      <InfoItem
        key={0}
        title={<LocalizedMessage id="campaignPeriodTitle" values={{ period: mobileProduct.campaign.period }} />}
        content={
          <LocalizedMessage
            id="campaignPeriodText"
            values={{ monthlyCost: priceWithCurrency(mobileProduct.campaign.discountedPricing.monthlyCost) }}
          />
        }
      />,
      <InfoItem
        key={1}
        title={<LocalizedMessage id="afterCampaignTitle" />}
        content={
          <LocalizedMessage
            id="afterCampaignText"
            values={{ monthlyCost: priceWithCurrency(mobileProduct.pricing.monthlyCost) }}
          />
        }
      />,
    ]
  ) : (
    <InfoItem
      title={<LocalizedMessage id="priceTitle" />}
      content={
        <LocalizedMessage
          id="priceText"
          values={{ monthlyCost: priceWithCurrency(mobileProduct.pricing.monthlyCost) }}
        />
      }
    />
  );

  const dataCapacityInGb =
    mobileProduct.information.dataCapacityInGb.name === MobileDataCapacityLimitationType.UnlimitedDataCapacity ? (
      <LocalizedMessage id="unlimitedDataCapacity" />
    ) : (
      `${mobileProduct.information.dataCapacityInGb.amount} GB`
    );

  return (
    <InfoItemGrid>
      <InfoItem title={<LocalizedMessage id="homepageTitle" />} content={websiteLink} />
      {termsLink && <InfoItem title={<LocalizedMessage id="termsLinkTitle" />} content={termsLink} />}
      <InfoItem title={<LocalizedMessage id="callsTitle" />} content={<LocalizedMessage id="callsText" />} />
      <InfoItem title={<LocalizedMessage id="bandwidthCapacityTitle" />} content={dataCapacityInGb} />
      {priceItems}
      <InfoItem
        title={<LocalizedMessage id="bindingTimeTitle" />}
        content={
          <LocalizedMessage id="bindingTimeText" values={{ bindingPeriod: mobileProduct.information.bindingPeriod }} />
        }
      />
      <InfoItem
        title={<LocalizedMessage id="terminationTimeTitle" />}
        content={
          <LocalizedMessage
            id="terminationTimeText"
            values={{
              terminationTime: mobileProduct.information.terminationTime
                ? mobileProduct.information.terminationTime
                : 0,
            }}
          />
        }
      />
      <InfoItem title={<LocalizedMessage id="startFeeTitle" />} content={priceWithCurrency(0)} />
      {mobileProduct.campaign?.welcomeDiscount && (
        <InfoItem
          title={<LocalizedMessage id="welcomeDiscountTitle" />}
          content={localizeCostWithCurrency(mobileProduct.campaign.welcomeDiscount)}
        />
      )}
    </InfoItemGrid>
  );
};

OrderedMobileProductInfoLegacy.contextTypes = {
  localizeCostWithCurrency: PropTypes.func.isRequired,
};
