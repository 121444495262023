import Sentry from '@client/assets/js/sentry';
import { LocalizedMessage, withLocalization } from '@client/internationalization';
import { PerInterval } from '@client/modules/shared-components/interval-to-string';
import { updateSubscription } from '@client/modules/subscription/duck';
import {
  deleteSubscription as deleteSubscriptionCall,
  updateSubscription as updateSubscriptionRequest,
} from '@client/modules/subscription/model';
import type { Subscription } from '@client/modules/subscription/types';
import { SubscriptionSource } from '@client/modules/subscription/types';
import { dispatchSubscriptionConvertedMixpanelEvent } from '@client/modules/subscription/unresolved-guide/utils';
import { getMixpanel } from '@client/tracking/mixpanel';
import { EventNames, FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE } from '@client/tracking/mixpanel-constants';
import { costWithDecimalsRegex, formatAmountWithDecimals } from '@client/utils/cost';
import { useDispatch } from '@client/utils/redux';
import useTheme from '@material-ui/core/styles/useTheme';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { RemoveSubscriptionForm } from './removeSubscriptionForm';
import { UpdatePriceForm } from './updatePriceForm';
import { INVALID_COST, NOT_A_SUBSCRIPTION, WhatsWrongForm } from './whatsWrongForm';

const WHATS_WRONG = 'whats_wrong';

export interface WhatsWrongDialogProps {
  subscription: Subscription;
  open: boolean;
  onClose(): void;
  onSubscriptionRemoved?(subscription: Subscription, reason: string): void;
  onPriceUpdated?(updated: Subscription): void;
  onError?(e: Error): void;
}

const proceedTitles: { [key: string]: string } = {
  [WHATS_WRONG]: 'nextButtonLabel',
  [NOT_A_SUBSCRIPTION]: 'deleteSubscriptionConfirm',
  [INVALID_COST]: 'saveButtonLabel',
};

const backTitles: { [key: string]: string } = {
  [WHATS_WRONG]: 'cancelButtonLabel',
  [NOT_A_SUBSCRIPTION]: 'goBackButton',
  [INVALID_COST]: 'goBackButton',
};

const pageNames: { [key: string]: string } = {
  [WHATS_WRONG]: 'Edit subscription',
  [NOT_A_SUBSCRIPTION]: 'Delete subscription',
  [INVALID_COST]: 'Edit cost',
};

const WhatsWrongDialogComponent: React.FunctionComponent<WhatsWrongDialogProps> = (
  props,
  { localizeMessage, localizeCurrency }
) => {
  const dispatch = useDispatch();
  const subscription: Subscription = props.subscription;
  const [selectedStep, setSelectedStep] = useState(WHATS_WRONG);
  const [displayStep, setDisplayStep] = useState(WHATS_WRONG);
  const [selectedRemoveReason, setSelectedRemoveReason] = useState(localizeMessage('deleteReasonNotSelected'));
  const [updateCostValid, setUpdateCostValid] = useState(true);
  const [newCost, setNewCost] = useState(subscription.cost.amount);
  const [newPaymentInterval, setNewPaymentInterval] = useState(subscription.paymentInterval);
  const { colors } = useTheme();

  const onAmountChange = (value: string) => {
    const formattedValue = formatAmountWithDecimals(value);
    const valid = costWithDecimalsRegex.test(formattedValue);
    setUpdateCostValid(valid);
    if (props.subscription.source === SubscriptionSource.BankDiscovery) {
      dispatchSubscriptionConvertedMixpanelEvent();
    }
    if (valid) {
      setNewCost(Number(formattedValue));
    }
  };

  const close = () => {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
      Page: pageNames[selectedStep],
      Type: 'Contract guide',
      Location: 'Top right',
      Button: 'Close',
    });
    props.onClose();
    setSelectedStep(WHATS_WRONG);
    setDisplayStep(WHATS_WRONG);
  };

  const values = {
    supplier: subscription.supplier.name,
    amount: subscription.cost.amount,
    currency: localizeCurrency(subscription.cost.currency),
  };

  const reportError = (e: Error, message: string) => {
    Sentry.captureExceptionWithMessage(e, message);
    if (props.onError) {
      props.onError(e);
    }
  };

  const removeSubscription = () => {
    getMixpanel().track('Subscription deleted', {
      Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
      Page: pageNames[selectedStep],
      Type: 'Contract guide',
      Reason: selectedRemoveReason,
      'Service Provider': subscription.supplier.name,
    });
    deleteSubscriptionCall(subscription.id, selectedRemoveReason)
      .then(() => {
        close();
        if (props.onSubscriptionRemoved) {
          props.onSubscriptionRemoved(subscription, selectedRemoveReason);
        }
      })
      .catch((err: Error) => {
        reportError(err, 'Failed to delete subscription');
      });
  };

  const updatePrice = () => {
    const updatedSubscription = {
      ...subscription,
      cost: { amount: newCost, currency: subscription.cost.currency },
      paymentInterval: newPaymentInterval,
    };
    const isManualSub = updatedSubscription.source === SubscriptionSource.Manual;
    // @ts-ignore
    const promise = dispatch(updateSubscription(updateSubscriptionRequest(updatedSubscription)));
    promise
      // @ts-ignore
      .then(() => {
        getMixpanel().track(isManualSub ? 'Subscription changed' : 'Subscription converted', {
          Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
          Page: pageNames[selectedStep],
          Type: 'Contract guide',
          'Old cost': subscription.cost.amount,
          'New cost': newCost,
          'Old payment interval': `${subscription.paymentInterval.amount} ${subscription.paymentInterval.unit}`,
          'New payment interval': `${newPaymentInterval.amount} ${newPaymentInterval.unit}`,
        });
        close();
        if (props.onPriceUpdated) {
          props.onPriceUpdated(updatedSubscription);
        }
      })
      .catch((err: Error) => {
        reportError(err, 'Failed to update subscription');
      });
  };

  const proceed = () => {
    if (displayStep === WHATS_WRONG) {
      getMixpanel().track(EventNames.CLICKED_BUTTON, {
        Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
        Page: pageNames[selectedStep],
        Type: 'Contract guide',
        Location: 'Bottom right',
        Button: 'Continue',
      });
      setDisplayStep(selectedStep);
      getMixpanel().track(EventNames.VIEWED_GUIDE, {
        Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
        Page: pageNames[selectedStep],
        Type: 'Contract guide',
      });
    } else if (displayStep === NOT_A_SUBSCRIPTION) {
      removeSubscription();
    } else if (displayStep === INVALID_COST) {
      updatePrice();
    }
  };

  const canProceed: boolean =
    (displayStep === WHATS_WRONG && selectedStep !== WHATS_WRONG) ||
    (displayStep === INVALID_COST && updateCostValid) ||
    displayStep === NOT_A_SUBSCRIPTION;

  const cancel = () => {
    getMixpanel().track(EventNames.CLICKED_BUTTON, {
      Feature: FEATURE_UNRESOLVED_SUBSCRIPTION_GUIDE,
      Page: pageNames[selectedStep],
      Type: 'Contract guide',
      Location: 'Bottom left',
      Button: 'Back',
    });
    if (displayStep !== WHATS_WRONG) {
      setDisplayStep(WHATS_WRONG);
    } else {
      close();
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={close}
      title={<LocalizedMessage id="whatsWrongPopupTitle" />}
      buttonLayout="horizontal"
      button1={<SecondaryButton label={localizeMessage(backTitles[displayStep])} onClick={cancel} />}
      button2={
        <PrimaryButton disabled={!canProceed} label={localizeMessage(proceedTitles[displayStep])} onClick={proceed} />
      }
    >
      {displayStep === WHATS_WRONG && (
        <div>
          <Body color={colors.textOn.surfaceSubdued} style={{ marginBottom: '16px', fontStyle: 'italic' }}>
            <LocalizedMessage id="whatsWrongPopupBody" values={values} />
            &nbsp;
            <PerInterval showPrefix interval={subscription.paymentInterval} />
          </Body>
          <WhatsWrongForm step={selectedStep} onChange={setSelectedStep} />
        </div>
      )}
      {displayStep === NOT_A_SUBSCRIPTION && (
        <div>
          <Body color={colors.textOn.surfaceSubdued}>
            <LocalizedMessage id={'whyDoYouWantToDeleteSubscription'} values={values} />
          </Body>
          <Caption style={{ margin: '8px 0 16px 0' }}>
            <LocalizedMessage id={'deleteSubscriptionCardDescription'} values={values} />
          </Caption>
          <RemoveSubscriptionForm value={selectedRemoveReason} onChange={setSelectedRemoveReason} />
        </div>
      )}
      {displayStep === INVALID_COST && (
        <div>
          <Body color={colors.textOn.surfaceSubdued} style={{ marginBottom: '24px' }}>
            <LocalizedMessage id={'whatsWrongPopupBody'} values={values} />
            &nbsp;
            <PerInterval showPrefix interval={subscription.paymentInterval} />
          </Body>
          <Caption color={'#000'} style={{ fontWeight: 'bold', marginBottom: '8px' }}>
            <LocalizedMessage id={'addCorrectCost'} />
          </Caption>
          <UpdatePriceForm
            paymentInterval={newPaymentInterval}
            amount={newCost}
            currency={subscription.cost.currency}
            onAmountChange={onAmountChange}
            onPaymentIntervalChange={setNewPaymentInterval}
          />
        </div>
      )}
    </Modal>
  );
};

WhatsWrongDialogComponent.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
  localizeCurrency: PropTypes.func.isRequired,
};

export const WhatsWrongDialog = withLocalization('subscription/WhatsWrongDialog')(WhatsWrongDialogComponent);
