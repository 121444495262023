import { selectorUser } from '@client/ducks/user';
import { selectorAppType, selectorMarket } from '@client/modules/app-context/duck';
import { withAppContext } from '@client/modules/app-context/react';
import type { ZendeskLabel } from '@client/zendesk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import type { Dispatch } from 'redux';
import type { AppContext } from '../../../modules/app-context';
import { open } from '../../../modules/Zendesk/ducks';
import { ZendeskHeaderHelpButtonComponent } from './component';
import type { AppType, Market } from '@client/modules/app-context/constants';

interface ZendeskHeaderHelpButtonProps {
  query: ZendeskLabel;
  className?: string;
  iconColor?: string;
  sideEffect?: () => void;
}

interface StateProps {
  user: any;
  market: Market;
  appType: AppType;
}

const mapStateToProps = (state: any) => ({
  user: selectorUser(state),
  market: selectorMarket(state),
  appType: selectorAppType(state),
});

interface DispatchProps {
  onOpenDialog: (query: Record<string, unknown>) => void;
}

const mapDispatchToProps = (dispatch: Dispatch, { appContext }: { appContext: AppContext }) => ({
  onOpenDialog: (query: Record<string, unknown>) => {
    dispatch(open(appContext.features.helpCenter.zendeskBaseUrl, appContext.locale, query));
  },
});

const mergeProps: (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: ZendeskHeaderHelpButtonProps & { appContext: AppContext }
) => any = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onOpenDialog: (query: Record<string, unknown>) => {
      if (ownProps?.sideEffect) {
        ownProps?.sideEffect();
      }
      dispatchProps.onOpenDialog(query);
    },
  };
};

export const ZendeskHeaderHelpButton = compose<ZendeskHeaderHelpButtonComponent, ZendeskHeaderHelpButtonProps>(
  withAppContext,
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(ZendeskHeaderHelpButtonComponent);
