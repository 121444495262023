import { withLocalization } from '@client/internationalization';
import { FeedbackCardThankyou } from '@client/modules/nps-csat-module/feedbackCards/FeedbackCardThankyou';
import { FeedbackCsatRatingCard } from '@client/modules/nps-csat-module/feedbackCards/FeedbackCsatRatingCard';
import { FeedbackNpsRatingCard } from '@client/modules/nps-csat-module/feedbackCards/FeedbackNpsRatingCard';
import { overviewPage } from '@client/routes';
import { makeStyles } from '@material-ui/core/styles';
import { Carousel } from '@minna-technologies/minna-ui/components/Carousel';
import { FlowContent } from '@minna-technologies/minna-ui/components/flow/FlowContent';
import { FlowPage } from '@minna-technologies/minna-ui/components/flow/FlowPage';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface FeedbackComponentProps {
  onFeedbackComponentClose: () => void;
  showInput?: boolean;
}

const Component: React.FunctionComponent<FeedbackComponentProps> = ({ onFeedbackComponentClose, showInput }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const cards = [
    <FeedbackCsatRatingCard
      key={'rating'}
      onNext={() => {
        setCurrentSlide(1);
      }}
      onClose={onFeedbackComponentClose}
    />,
    <FeedbackNpsRatingCard
      key={'nps'}
      onNext={() => {
        setCurrentSlide(2);
      }}
      onClose={onFeedbackComponentClose}
    />,
    <FeedbackCardThankyou key={'thank you'} showInput={showInput} onClose={onFeedbackComponentClose} />,
  ];
  useEffect(() => {
    if (!showInput) {
      if (currentSlide === 2) {
        // make it go away after 4 seconds
        setTimeout(() => {
          onFeedbackComponentClose();
        }, 4000);
      }
    }
  }, [currentSlide, showInput, onFeedbackComponentClose]);

  return (
    <Carousel disabled index={currentSlide} fullHeight>
      {cards}
    </Carousel>
  );
};

export interface FeedbackCarouselCardProps {
  onNext: () => void;
  onClose: () => void;
  opacity?: number;
  fullHeight?: boolean;
}

export const cardStyles = makeStyles(() => ({
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  nextButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
  mainTextContent: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    textAlign: 'center',
  },
}));

export const FeedbackComponent = withLocalization('feedback-modal')(Component);

export const FeedbackPage: React.FunctionComponent = () => {
  const history = useHistory();
  const closeFeedback = () => {
    history.push(overviewPage);
  };

  return (
    <FlowPage>
      <FlowContent>
        <FeedbackComponent onFeedbackComponentClose={closeFeedback} showInput />
      </FlowContent>
    </FlowPage>
  );
};
