import PropTypes from 'prop-types';
import React from 'react';
import {Switch} from '@minna-components/inputs/Switch';
import styles from './styles.scss';

const NotificationToggle = ({title, description, active, onUpdate}) => {
  return (
    <div className={ styles.base } onClick={ () => onUpdate(!active) } data-test="notification-setting-toggle">
      <div className={ styles.texts }>
        <div>{ title }</div>
        <div className={ styles.description }>{description}</div>
      </div>
      <div className={ styles.toggle }>
        <Switch data-test="toggle-notifications" checked={ active } onChange={ onUpdate } />
      </div>
    </div>
  );
};

NotificationToggle.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  active: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired
};

NotificationToggle.defaultProps = {
  active: false
};

export default NotificationToggle;
