import type { EmailSupportId } from '@client/models/support';
import { getEmailSupportCode } from '@client/models/support';
import type { Market } from '@client/modules/app-context/constants';
import axios from 'axios';
import get from 'lodash/get';

export async function sendRequestToZendesk(
  requestData: { timeSlotInput: string; phoneInput: string; emailInput: string },
  userData: { name: string; market: Market },
  zendeskBaseUrl: string
): Promise<any> {
  const supportId: EmailSupportId = await getEmailSupportCode();

  const callBackRequest = {
    request: {
      requester: { name: get(userData, 'name', 'Anonymous user'), email: requestData.emailInput },
      subject: 'Callback request',
      comment: {
        body: `Requested callback time: ${requestData.timeSlotInput}, phone number: ${requestData.phoneInput}, email: ${requestData.emailInput}, supportId: ${supportId.code}`,
      },
    },
  };

  const zendeskRequestsApiUrl = `${zendeskBaseUrl}/api/v2/requests.json`;

  return axios
    .post(zendeskRequestsApiUrl, callBackRequest)
    .then((response) => response)
    .catch((error) => error);
}
