import { LocalizedMessage, withLocalization } from '@client/internationalization';
import PropTypes from 'prop-types';
import React from 'react';
import { ErrorComponent } from '../ErrorComponent';

const GenericSyncErrorPage: React.FunctionComponent = (_, { localizeMessage }) => {
  return (
    <ErrorComponent
      headerText={<LocalizedMessage id="genericSyncHeaderText" />}
      imageAltText={localizeMessage('imageAltText')}
      bodyText={<LocalizedMessage id="genericSyncBodyText" />}
    />
  );
};

GenericSyncErrorPage.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};

export const GenericSyncErrorPageContainer = withLocalization('ErrorScreen')(GenericSyncErrorPage);
