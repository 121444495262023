import { CardRowLink } from '@minna-technologies/minna-ui/components/CardRowLink';
import PropTypes from 'prop-types';
import * as React from 'react';

export const ArticleResultItem = ({ article, onNavigateToArticle }: any) => {
  const onArticleClick = () => {
    onNavigateToArticle(article.sectionId, article.id);
  };

  return <CardRowLink onClick={onArticleClick} body={article.title} />;
};

ArticleResultItem.propTypes = {
  article: PropTypes.object.isRequired,
  onNavigateToArticle: PropTypes.func.isRequired,
};
