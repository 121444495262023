import axios from 'axios';

export interface SignResourceRequest {
  signingId: string;
  resourceToSignId: string;
  nemIdParameters?: NemIdParameters;
}

export interface NemIdParameters {
  timestamp: string;
  sp_cert: string;
  clientFlow: { name: string };
  digest_signature: string;
  params_digest: string;
  signtext_format: { format: string };
  signtext?: string;
  signtext_uri?: string;
  origin?: string;
  language?: { name: string };
  transaction_context?: string;
}

export interface NemIdResponseWithResourceRequest extends SignResourceRequest {
  nemIdResponse: NemIdResponse;
  signingId: string;
  resourceToSignId: string;
}

interface NemIdResponse {
  signingStartedAt: Date;
  encodedResponse: string;
}

export async function createResource(contractId: string, nemIdResponse: NemIdResponseWithResourceRequest) {
  return axios.post(`/api/user/contracts/${contractId}/nem-id/create-resource`, nemIdResponse);
}
