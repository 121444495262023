// Weight interval against a month
import type { PaymentInterval } from '@client/modules/subscription/types';

const intervalConversion = {
  day: 365 / 12,
  week: 52 / 12,
  month: 1,
  year: 1 / 12,
};

export function costOverTimeConverter(
  cost: number,
  fromInterval: PaymentInterval,
  toInterval: PaymentInterval
): number {
  const normalizedFromUnit = intervalConversion[fromInterval.unit] / fromInterval.amount;
  const monthlyCost = cost * normalizedFromUnit;

  const normalizedToUnit = 1 / (intervalConversion[toInterval.unit] / toInterval.amount);

  return monthlyCost * normalizedToUnit;
}
