import { selectorSubscription } from '@client/modules/subscription';
import { ContractDetailsFloatingButtons } from '@client/modules/subscription/ContractDetailsPage/components/ContractDetailsFloatingButtons';
import { viewPageMixpanelEvent } from '@client/modules/subscription/mixpanel';
import { PriceHistory } from '@client/modules/subscription/PriceHistoryPage/components/PriceHistory/component';
import { TransactionHistory } from '@client/modules/subscription/PriceHistoryPage/components/TransactionHistory/component';
import type { Contract, ContractId } from '@client/modules/subscription/types';
import { BindingPeriodType } from '@client/modules/subscription/types';
import { useMountEffect } from '@client/utils/hooks/use-mount-effect';
import { ContractHeaderDeprecated } from '@minna-technologies/minna-ui/components/ContractHeaderDeprecated';
import { CardLayout } from '@minna-technologies/minna-ui/components/Layouts/CardLayout';
import { MultiDeviceLayout } from '@minna-technologies/minna-ui/components/MultiDeviceLayout';
import React from 'react';
import { useSelector } from 'react-redux';

export interface PriceHistoryPageComponentProps {
  contractId: ContractId;
}

export const PriceHistoryPage = (props: PriceHistoryPageComponentProps) => {
  const subscription = useSelector(selectorSubscription);
  const contract: Contract | undefined = subscription?.contracts?.find(
    (possibleContract: Contract) => possibleContract.id === props.contractId
  );
  useMountEffect(() => {
    if (subscription && contract) {
      viewPageMixpanelEvent(subscription, 'Price history', {
        Category: contract.service.category.name,
        'Binding time set': contract.contractBindingPeriod.type === BindingPeriodType.FixedContractBindingPeriod,
        'Monthly cost': subscription.cost.amount,
      });
    }
  });

  return (
    <MultiDeviceLayout
      footer={<ContractDetailsFloatingButtons backButton />}
      fullWidthContent={
        <ContractHeaderDeprecated
          providerLogo={subscription?.supplier.logoUrl ?? ''}
          title={contract?.service.category?.text ?? ''}
          subHeading={subscription?.supplier.name ?? ''}
          providerName={subscription?.supplier.name ?? ''}
        />
      }
    >
      <CardLayout>
        <PriceHistory />
        <TransactionHistory />
      </CardLayout>
    </MultiDeviceLayout>
  );
};
