import { getUserProfilingSettings } from '@client/models/user';
import type { ProfilingSetting } from '@client/models/user-models';
import { UpdatingStates } from '@client/models/user-models';
import { createAction, createPromiseAction } from '@client/utils/redux';

export enum ActionKeys {
  FETCH_PROFILING_SETTINGS = 'PROFILING_SETTINGS/FETCH_PROFILING_SETTINGS',
  UPDATE_PROFILING_SETTINGS = 'PROFILING_SETTINGS/UPDATE_PROFILING_SETTINGS',
  UPDATE_PROFILING_SETTINGS_COMPLETED = 'PROFILING_SETTINGS/UPDATE_PROFILING_SETTINGS_COMPLETED',
  UPDATE_PROFILING_SETTINGS_FAILED = 'PROFILING_SETTINGS/UPDATE_PROFILING_SETTINGS_FAILED',
  CLEAR_PROFILING_SETTINGS_FAILED_STATUS = 'PROFILING_SETTINGS/CLEAR_PROFILING_SETTINGS_FAILED_STATUS',
}

export const fetchProfilingSettings = () =>
  createPromiseAction(ActionKeys.FETCH_PROFILING_SETTINGS, getUserProfilingSettings());
export const updateProfilingSetting = (setting: ProfilingSetting) =>
  createAction(ActionKeys.UPDATE_PROFILING_SETTINGS, {
    profilingSetting: setting,
    updatingStatus: UpdatingStates.UPDATING,
  });
export const updateProfilingSettingCompleted = () =>
  createAction(ActionKeys.UPDATE_PROFILING_SETTINGS_COMPLETED, UpdatingStates.UPDATED);
export const updateProfilingSettingFailed = (setting: ProfilingSetting) =>
  createAction(ActionKeys.UPDATE_PROFILING_SETTINGS_FAILED, {
    profilingSetting: {
      ...setting,
      allowed: !setting.enabled,
    },
    updatingStatus: UpdatingStates.UPDATE_FAILED,
  });
export const clearProfilingSettingUpdateFailed = () => createAction(ActionKeys.CLEAR_PROFILING_SETTINGS_FAILED_STATUS);

export type Actions =
  | ReturnType<typeof fetchProfilingSettings>
  | ReturnType<typeof updateProfilingSetting>
  | ReturnType<typeof updateProfilingSettingCompleted>
  | ReturnType<typeof updateProfilingSettingFailed>
  | ReturnType<typeof clearProfilingSettingUpdateFailed>;
