import { ExternalLink } from '@client/shared-components/ExternalLink';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FlowContent } from '@minna-technologies/minna-ui/components/flow/FlowContent';
import { FlowPage } from '@minna-technologies/minna-ui/components/flow/FlowPage';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles(() => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    background: 'radial-gradient(177.89% 100% at 100% 100%, #822849 0%, #F35846 47.67%, #FF9167 100%)',
    height: '500px',
    padding: '1rem',
  },
  text: {
    textAlign: 'center',
  },
}));

export const IsShutdown: FC = () => {
  const classes = useStyles();
  const { colors } = useTheme();

  return (
    <FlowPage scrollable={false}>
      <FlowContent justifyTop>
        <div className={classes.layout}>
          <Headline1 color={colors.base.surface} className={classes.text}>
            Mina Tjänster stänger ned
          </Headline1>
          <br />
          <Body color={colors.base.surface} className={classes.text} variant={'bold'}>
            Tjänsten har stängts ned och det är tyvärr inte längre möjligt att logga in. I samband med att Mina Tjänster
            stänger ned så flyttar våra smarta funktioner istället in i ekonomiappen{' '}
            <ExternalLink
              href={'https://anyfin.onelink.me/qbaL?pid=crm&c=minna'}
              style={{ textDecoration: 'underline', color: colors.base.surface }}
            >
              Anyfin
            </ExternalLink>
            .
          </Body>
        </div>
      </FlowContent>
    </FlowPage>
  );
};
