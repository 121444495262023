import * as urls from '@client/routes';
import Button from '@material-ui/core/Button';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = createStyles({
  mobileTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
});

interface LoginButtonProps {
  // eslint-disable-next-line
  variant?: 'text' | 'flat' | 'outlined' | 'contained' | 'raised' | 'fab' | 'extendedFab';
  classes: any;
  label: string | React.ReactElement;
}

const LoginButtonInner: React.FunctionComponent<LoginButtonProps> = ({ variant, label, classes }) => {
  const button = (
    // @ts-ignore
    <Button variant={variant} color="primary" component={Link} to={urls.loginPage}>
      {label}
    </Button>
  );

  return <div className={classes.mobileTitle}>{button}</div>;
};

export const LoginButton = withStyles(styles)(LoginButtonInner);
