import type { DisplayName, DisplayNameIncoming, Locale } from '@client/modules/app-context/constants';
import type { AppContext, AppContextIncoming } from './';

export const getAppContextFromAppContextIncoming = (
  appContextIncoming: AppContextIncoming,
  localeSpecificDisplayName: DisplayName
): AppContext => ({ ...appContextIncoming, displayName: localeSpecificDisplayName });

export const getLocaleSpecificDisplayName = (displayName: DisplayNameIncoming, locale: Locale): string =>
  displayName[locale] ? displayName[locale] : displayName.default;

export const prepareAppContextWithDisplayName = (appContextResponse: AppContextIncoming) => {
  const localeSpecificDisplayName = getLocaleSpecificDisplayName(
    appContextResponse.displayName,
    appContextResponse.locale
  );

  return getAppContextFromAppContextIncoming(appContextResponse, localeSpecificDisplayName);
};
