import { Sentry } from '@client/assets/js/sentry';
import type { WithLocalizationContextType } from '@client/internationalization';
import { LocalizedMessage } from '@client/internationalization';
import { ZendeskContactUsContainer } from '@client/modules/Zendesk/ZendeskHelpCenter/components/ContactUs';
import { ZendeskEmailUsContainer } from '@client/modules/Zendesk/ZendeskHelpCenter/components/EmailUs';
import { getSupportNumber } from '@client/utils/support';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SearchBar } from '@minna-technologies/minna-ui/components/Inputs/SearchBar';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import find from 'lodash/find';
import get from 'lodash/get';
import { Paper } from 'material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ARTICLE_DETAILS_ROUTE,
  CATEGORY_ROUTE,
  CONTACT_US_ROUTE,
  EMAIL_US_ROUTE,
  HELP_CENTER_ROUTE,
  SECTION_ROUTE,
  USER_SEARCH_RESULT_ROUTE,
} from '../ducks';
import { Index } from './components/DisplayArticle';
import { DisplayCategoryContainer } from './components/DisplayCategory';
import { DisplaySectionContainer } from './components/DisplaySection';
import { StartScreenContainer } from './components/StartScreen';
import { UserSearchResultContainer } from './components/UserSearchResult';

const useStyles = makeStyles((theme) => ({
  visibleComponentContainer: {
    padding: theme.spacing(2, 1),
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

export const ZendeskHelpCenter = (
  {
    categories,
    userSearchQuery,
    onUpdateUserSearchQuery,
    onSearchArticles,
    currentRoute,
    onNavigateBack,
    communicationWithApiFailed,
    appContext,
  }: any,
  { localizeMessage }: WithLocalizationContextType
) => {
  const classes = useStyles();

  const visibleComponent = () => {
    const getSectionFromRoute = (route: any) => {
      const category = find(categories, ['id', route.parameters.categoryId]);

      return find(get(category, 'sections'), ['id', route.parameters.sectionId]);
    };

    switch (currentRoute.route) {
      case HELP_CENTER_ROUTE:
        return <StartScreenContainer />;
      case CATEGORY_ROUTE: {
        const category = find(categories, ['id', currentRoute.parameters.categoryId]);
        if (!category) {
          Sentry.captureMessage(
            `Could not find category from Zendesk matching id ${currentRoute.parameters.categoryId}`
          );
        }
        return <DisplayCategoryContainer category={category} />;
      }
      case SECTION_ROUTE:
        return <DisplaySectionContainer section={getSectionFromRoute(currentRoute)} onBackClick={onNavigateBack} />;
      case USER_SEARCH_RESULT_ROUTE:
        return <UserSearchResultContainer onBackClick={onNavigateBack} />;
      case ARTICLE_DETAILS_ROUTE:
        return (
          <Index
            articleId={currentRoute.parameters.articleId}
            sectionId={currentRoute.parameters.sectionId}
            onBackClick={onNavigateBack}
          />
        );
      case CONTACT_US_ROUTE:
        return <ZendeskContactUsContainer />;
      case EMAIL_US_ROUTE:
        return <ZendeskEmailUsContainer />;
      default:
        return null;
    }
  };

  const handleSearchBarInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    onUpdateUserSearchQuery(event.target.value);

  return (
    <div>
      {communicationWithApiFailed ? (
        <Paper className={classes.padding}>
          <Body data-test="zendesk-error-screen">
            <LocalizedMessage
              id="unableToConnectToHelpCenterErrorV2"
              values={{ supportNumber: getSupportNumber(appContext.market) }}
            />
          </Body>
        </Paper>
      ) : (
        <div className={classes.visibleComponentContainer}>
          <SearchBar
            fullWidth
            data-test="zendesk-search"
            onClick={onSearchArticles}
            value={userSearchQuery}
            placeholder={localizeMessage('searchBarGreeting')}
            onChange={handleSearchBarInput}
          />
          {visibleComponent()}
        </div>
      )}
    </div>
  );
};

ZendeskHelpCenter.propTypes = {
  user: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  userSearchQuery: PropTypes.string,
  appContext: PropTypes.object.isRequired,
  onUpdateUserSearchQuery: PropTypes.func.isRequired,
  onSearchArticles: PropTypes.func.isRequired,
  currentRoute: PropTypes.object.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
  communicationWithApiFailed: PropTypes.bool,
};

ZendeskHelpCenter.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
