import { LocalizedMessage, LocalizedMessageWithElementsInjected, withLocalization } from '@client/internationalization';
import { Locale } from '@client/modules/app-context/constants';
import { ExternalLink } from '@client/shared-components/ExternalLink';
import MultiDeviceLayout from '@client/shared-components/layout/MultiDeviceLayout';
import PageNavigationBar from '@client/shared-components/PageNavigationBar';
import Paper from '@material-ui/core/Paper';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import React from 'react';
import styles from './styles.scss';

export interface NemIdErrorPageProps {
  onBackClick(): void;
  errorCode?: string;
  locale: Locale;
}

/**
 * Translates a nemId error code into a message displayed to the user. Look at this documentation to get more details about an error code:
 * https://www.nets.eu/dk-da/kundeservice/nemid-tjenesteudbyder/NemID-tjenesteudbyderpakken/Documents/NemID%20Error%20Codes.pdf
 *
 * @param locale The current locale used in the application, will determine the links to NemID support in different languages
 * @param errorCode Could be both NemID error code and internal error code, for NemID error codes look into the docs for more details.
 */

// eslint-disable-next-line complexity
function translateErrorCodeToMessage(locale: Locale, errorCode?: string) {
  const localizedLinkToContactSupport =
    locale === Locale.daDK
      ? 'https://www.nemid.nu/dk-da/faa_hjaelp_til_dit_nemid/kontakt/'
      : 'https://www.nemid.nu/dk-en/support/contact/';
  const linkToNemIdContactSupport = {
    contactSupportLink: (
      <ExternalLink href={localizedLinkToContactSupport}>
        <LocalizedMessage id="nemIdContactSupportLinkText" />
      </ExternalLink>
    ),
  };

  const lostCardUrl =
    locale === Locale.daDK
      ? 'https://service.nemid.nu/dk-da/nemid/noeglekort/mistet_noeglekort/'
      : 'https://service.nemid.nu/dk-en/nemid/code_cards/lost_code_card/';
  const lostCardPageLink = {
    lostCardSupportLink: (
      <ExternalLink href={lostCardUrl}>
        <LocalizedMessage id="lostCardPageLinkText" />
      </ExternalLink>
    ),
  };

  const linkToRequestNemId = {
    requestNemIdLink: (
      <ExternalLink href={'https://service.nemid.nu/dk-da/bestil_nemid/'}>
        <LocalizedMessage id="requestNemIdLinktext" />
      </ExternalLink>
    ),
  };

  const linkToAttachPublicSignatureToNemId = {
    attachPublicSignatureLink: (
      <ExternalLink
        href={'https://service.nemid.nu/dk-da/bestil_nemid/bestil_offentlig_digital_signatur_til_dit_nemid/'}
      >
        <LocalizedMessage id="attachPublicSignatureLinkText" />
      </ExternalLink>
    ),
  };

  // eslint-disable-next-line
  switch (errorCode) {
    //NemID errors
    case 'AUTH001':
    case 'AUTH005':
    case 'AUTH007':
      return <LocalizedMessageWithElementsInjected id="nemIdIsBlocked" elements={linkToNemIdContactSupport} />;
    case 'AUTH003':
      return <LocalizedMessage id="noBankAgreement" />;
    case 'AUTH004':
      return <LocalizedMessage id="nemIdIsLocked" />;
    case 'AUTH006':
      return <LocalizedMessageWithElementsInjected id="noAvailableCodes" elements={lostCardPageLink} />;
    case 'AUTH008':
      return <LocalizedMessageWithElementsInjected id="nemIdIsNotActivated" elements={linkToNemIdContactSupport} />;
    case 'AUTH010':
      return <LocalizedMessage id="nemIdIsNotLatestIssued" />;
    case 'AUTH011':
      return (
        <LocalizedMessageWithElementsInjected id="temporaryPasswordNotSupported" elements={linkToNemIdContactSupport} />
      );
    case 'AUTH017':
      return <LocalizedMessage id="environmentError" />;
    case 'AUTH018':
      return <LocalizedMessage id="nemIdBlockedReactivate" />;
    case 'AUTH019':
      return <LocalizedMessage id="codeCardNotSupported" />;
    case 'AUTH020':
      return <LocalizedMessage id="oneFactorNotSupported" />;
    case 'CAN001':
      return (
        <LocalizedMessageWithElementsInjected
          id="userCancelledAfterProvidingPassword"
          elements={linkToNemIdContactSupport}
        />
      );
    case 'CAN002':
      return <LocalizedMessage id="userCancelled" />;
    case 'CAN003':
      return <LocalizedMessage id="userSessionTimedOut" />;
    case 'CAN004':
      return <LocalizedMessage id="bankAppLoggedOut" />;
    case 'CAN005':
      return <LocalizedMessage id="noResponseFromCodeApp" />;
    case 'CAN006':
      return <LocalizedMessageWithElementsInjected id="maxSessionsReached" elements={linkToNemIdContactSupport} />;
    case 'CAN007':
      return <LocalizedMessage id="userRejectedCodeAppRequest" />;
    case 'CAN008':
      return <LocalizedMessage id="userStartedNewRequest" />;
    case 'CAPP004':
      return <LocalizedMessage id="appIsSuspendedOrInactive" />;
    case 'LIB002':
      return <LocalizedMessage id="networkError" />;
    case 'LOCK001':
      return (
        <LocalizedMessageWithElementsInjected
          id="exceededAllowedPasswordRetriesQuarantine"
          elements={linkToNemIdContactSupport}
        />
      );
    case 'LOCK002':
    case 'LOCK003':
      return (
        <LocalizedMessageWithElementsInjected
          id="exceededAllowedPasswordRetriesLocked"
          elements={linkToNemIdContactSupport}
        />
      );
    case 'APP003':
    case 'SRV004':
      return <LocalizedMessageWithElementsInjected id="nemIdUnrecoverableError" elements={linkToNemIdContactSupport} />;
    case 'OCES001':
      return (
        <LocalizedMessageWithElementsInjected id="userOptedOutOfOces" elements={linkToAttachPublicSignatureToNemId} />
      );
    case 'OCES002':
      return <LocalizedMessageWithElementsInjected id="userNotOcesQualified" elements={linkToRequestNemId} />;
    case 'OCES003':
      return <LocalizedMessage id="userUsingWrongOcesDevice" />;
    case 'OCES004':
      return <LocalizedMessage id="userNotOcesQualifedNoCprNumber" />;
    case 'OCES005':
      return <LocalizedMessageWithElementsInjected id="signatureIssuingFailed" elements={linkToNemIdContactSupport} />;
    case 'OCES006':
      return <LocalizedMessageWithElementsInjected id="userOcesInaccessible" elements={linkToRequestNemId} />;
    //Internal errors
    case 'AlreadyTerminationState':
      return <LocalizedMessage id="cancellationAlreadyTerminationState" />;
    default:
      return <LocalizedMessage id="generalError" />;
  }
}

const NemIdErrorPageInnerComponent: React.FunctionComponent<NemIdErrorPageProps> = ({
  onBackClick,
  errorCode,
  locale,
}) => {
  const header = (
    <PageNavigationBar backgroundColor="white" onBackClick={onBackClick} title={<LocalizedMessage id="pageTitle" />} />
  );

  return (
    <MultiDeviceLayout header={header}>
      <Paper className={styles.container}>
        <Body>{translateErrorCodeToMessage(locale, errorCode)}</Body>
      </Paper>
    </MultiDeviceLayout>
  );
};

export const NemIdErrorPage = withLocalization('cancellation/signing/NemIdErrorPage')(NemIdErrorPageInnerComponent);
