import type { Supplier, SupplierWithServices } from '@client/models/supplier';
import type { InsightServiceProvider } from '@client/modules/InsightsPage/models';
import type { SubscriptionSupplier } from '@client/modules/subscription/types';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import type { FC } from 'react';
import React from 'react';
import styles from './styles.scss';

interface Props {
  supplier: SupplierWithServices | Supplier | SubscriptionSupplier | InsightServiceProvider;
}

export const SupplierLogo: FC<Props> = ({ supplier }) => {
  // @ts-ignore
  const logoUrl = supplier.logoUrl || supplier.logotypeUrl || supplier.logo;
  const name = supplier.name;
  if (logoUrl) {
    return <img src={logoUrl} className={styles.image} alt={name} />;
  } else {
    return <Subheading2 className={styles.name}>{name}</Subheading2>;
  }
};
