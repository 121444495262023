import { white } from '@client/styles/colors';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { ArrowBackIcon } from '@minna-technologies/minna-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import { BetaTag } from '../BetaTag';

interface Props {
  title?: React.ReactNode;
  logo?: string;
  beta?: boolean;
  fullWidth?: boolean;
  titleTooltip?: string;
  backTo?: string | Record<string, unknown>;
  iconRight?: React.ReactElement;
  primary?: boolean;
  backgroundColor?: string;
  transparent?: boolean;
  position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
  noShadow?: boolean;
  onBackClick?(): void;
}

export const PageNavigationBar: FC<Props> = (
  {
    onBackClick,
    backTo,
    title,
    titleTooltip = '',
    primary,
    iconRight,
    backgroundColor,
    transparent,
    position = 'sticky',
    noShadow,
    fullWidth,
    beta = false,
    logo,
  },
  { muiTheme }
) => {
  const titleTextColor = primary ? white : muiTheme.palette.textColor;
  const textAlign = logo ? 'center' : 'left';
  const titleStyle = {
    flex: 1,
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '20px',
    color: titleTextColor,
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: textAlign,
  };

  const betaTagWrapperStyle = {
    display: 'inline-block',
    marginTop: '-2px',
    verticalAlign: 'top',
  };

  const iconColor = primary ? white : muiTheme.palette.primary1Color;
  const backButtonStyle = { marginRight: '-48px' };

  let backButton;
  if (onBackClick) {
    backButton = (
      <IconButton onClick={onBackClick} style={backButtonStyle} data-test="page-navigation-bar-back">
        <ArrowBackIcon nativeColor={iconColor} />
      </IconButton>
    );
  } else if (backTo) {
    backButton = (
      <IconButton component={Link} to={backTo} style={backButtonStyle} data-test="page-navigation-bar-back">
        <ArrowBackIcon nativeColor={iconColor} />
      </IconButton>
    );
  }

  const appBarStyle = {
    backgroundColor:
      (transparent && 'transparent') || backgroundColor || (primary ? muiTheme.palette.primary1Color : white),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const resetShadowStyle = noShadow ? { boxShadow: 'none' } : null;
  const toolBarStyle = fullWidth
    ? { width: '100%' }
    : {
        maxWidth: '640px',
        width: '100%',
      };

  const logoStyle = { width: '100px' };
  return (
    <AppBar style={{ ...appBarStyle, ...resetShadowStyle }} position={position} elevation={transparent ? 0 : undefined}>
      <Toolbar style={{ ...toolBarStyle }}>
        {backButton}
        <div
          // @ts-ignore
          style={titleStyle}
          title={titleTooltip}
        >
          {title} {logo && <img src={logo} style={logoStyle} />}
          {beta && (
            <span style={betaTagWrapperStyle}>
              <BetaTag />
            </span>
          )}
        </div>
        {iconRight && <div style={{ marginLeft: '-32px', fill: iconColor, width: '32px' }}>{iconRight}</div>}
      </Toolbar>
    </AppBar>
  );
};

PageNavigationBar.contextTypes = {
  muiTheme: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default PageNavigationBar;
