// @ts-ignore js import, remove this when the import is typed
import { errorPageOnFailure, onEnter } from '@client/containers/container-helpers';
import { selectorLocaleFetchable, selectorMessagesFetchable } from '@client/internationalization/duck';
import { LoadingState } from '@client/models/LoadingState';
import moment from 'moment';
import type * as React from 'react';
import { IntlProvider } from 'react-intl';
// @ts-ignore
import type { OptionalIntlConfig } from 'react-intl/src/components/provider';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import type { Store } from 'redux';
import { extractSuccess, fetchableIsLoading } from '../models/Fetchable';
import { getCachedOrFetchAppContext } from '../modules/app-context/app-context';
import type { Locale } from '../modules/app-context/constants';
import { AppContextActions, selectorAppContext } from '../modules/app-context/duck';
import { getLocaleSpecificDisplayName } from '../modules/app-context/utils';
import { loadingPageWhileLoading } from '../modules/fetching/loadingPageWhileLoading';

const mapStateToProps = (state: any) => ({
  fetchableLocale: selectorLocaleFetchable(state),
  fetchableMessages: selectorMessagesFetchable(state),
  locale: selectorAppContext(state).locale,
  formats: {
    date: {
      dayAndMonth: { day: 'numeric', month: 'long' },
      dayAndShortMonth: { day: 'numeric', month: 'short' },
      long: { day: 'numeric', month: 'long', year: 'numeric' },
      monthAndYear: { month: 'short', year: 'numeric' },
      shortMonth: { month: 'short' },
      shortMonthAndShortYear: { month: 'short', year: '2-digit' },
      fullYear: { year: 'numeric' },
    },
  },
  textComponent: 'span',
});

interface LocalizationProviderProps {
  skipAppContext?: boolean;
}

export const LocalizationProvider = compose<React.PropsWithChildren<OptionalIntlConfig>, LocalizationProviderProps>(
  connect(mapStateToProps),
  loadingPageWhileLoading(
    ({ fetchableLocale, fetchableMessages }: ReturnType<typeof mapStateToProps>) =>
      fetchableIsLoading(fetchableLocale) || fetchableIsLoading(fetchableMessages)
  ),
  errorPageOnFailure(
    ({ fetchableLocale, fetchableMessages }: ReturnType<typeof mapStateToProps>) =>
      fetchableLocale.type === LoadingState.Failure || fetchableMessages.type === LoadingState.Failure
  ),
  withPropsOnChange(
    ['fetchableLocale', 'fetchableMessages'],
    ({ fetchableLocale, fetchableMessages }: ReturnType<typeof mapStateToProps>) => ({
      locale: extractSuccess(fetchableLocale),
      messages: extractSuccess(fetchableMessages),
      // eslint-disable-next-line
      key: Math.random(), //A key needs to be passed to IntlProvider so the context (for messages) refreshes. https://github.com/yahoo/react-intl/issues/1097#issuecomment-363114792
    })
  ),
  onEnter(
    async ({ locale, skipAppContext }: { locale: Locale; skipAppContext: boolean }, { store }: { store: Store }) => {
      store.dispatch(AppContextActions.setLocale(locale));
      moment.locale(locale);

      if (!skipAppContext) {
        const appContextResponse = await getCachedOrFetchAppContext();
        const localeSpecificDisplayName = getLocaleSpecificDisplayName(appContextResponse.displayName, locale);
        store.dispatch(AppContextActions.setDisplayName(localeSpecificDisplayName));
      }
    }
  )
)(IntlProvider);
