import { LocalizedMessage, withLocalization } from '@client/internationalization';
import type { SaveDeskInfo } from '@client/modules/subscription/types';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { CardHeader } from '@minna-technologies/minna-ui/components/Card/CardHeader';
import { Stepper } from '@minna-technologies/minna-ui/components/Stepper';
import PropTypes from 'prop-types';
import React from 'react';

interface OngoingSaveDeskCardProps {
  saveDeskInfo: SaveDeskInfo;
}

const OngoingSaveDeskCardInner: React.FunctionComponent<OngoingSaveDeskCardProps> = (
  { saveDeskInfo },
  { localizeDate }
) => {
  return (
    <Card>
      <CardHeader title={<LocalizedMessage id="stepperTitle2" />} />
      <Stepper
        steps={[
          {
            label: (
              <LocalizedMessage
                id="acceptedOfferStep"
                values={{
                  providerName: saveDeskInfo.providerName,
                  requestedDate: localizeDate(saveDeskInfo.requestedAt),
                }}
              />
            ),
            active: true,
            completed: false,
          },
          {
            label: (
              <LocalizedMessage id="waitingForConfirmationStep" values={{ providerName: saveDeskInfo.providerName }} />
            ),
            active: false,
            completed: false,
          },
        ]}
      />
    </Card>
  );
};

OngoingSaveDeskCardInner.contextTypes = {
  localizeDate: PropTypes.func.isRequired,
};

export const OngoingSaveDeskCard = withLocalization('save-desk/OngoingSaveDeskCard')(OngoingSaveDeskCardInner);
