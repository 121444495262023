import { LocalizedMessage, withLocalization } from '@client/internationalization';
import useTheme from '@material-ui/core/styles/useTheme';
import { PositiveButton } from '@minna-technologies/minna-ui/components/Buttons/PositiveButton';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { CheckCircleIcon } from '@minna-technologies/minna-ui/icons/CheckCircle';
import { MinnaIcon } from '@minna-technologies/minna-ui/icons/Minna';
import React, { useState } from 'react';
import cancelPic from './cancel.svg';
import styles from './styles.scss';

const OnboardingCancelProductPagesComponent = (props: { onClose(): void; onFinishOnboarding(): void }) => {
  const [pageId, setPageId] = useState(1);
  const onNextDrawerPage = () => setPageId(2);
  const onFinishOnboarding = () => props.onFinishOnboarding();
  const { colors } = useTheme();

  const cancelProductOnboardPage1 = (
    <div className={styles.container}>
      <MinnaIcon nativeColor={colors.action.primary} className={styles.logo} />
      <div className={styles.margin}>
        <Headline2>
          <LocalizedMessage id="Page1Headline" />
        </Headline2>
      </div>
      <div className={styles.margin}>
        <Body className={styles.margin}>
          <LocalizedMessage id="bankPartnership1" />
          <br />
          <br />
          <LocalizedMessage id="bankPartnership2" />
        </Body>
      </div>
      <div className={styles['drawer-page-bullet-container']}>
        <div className={styles['drawer-page-bullet-green']} />
        <div className={styles['drawer-page-bullet-gray']} />
      </div>
      <div className={styles['margin-button']}>
        <PrimaryButton
          onClick={onNextDrawerPage}
          label={<LocalizedMessage id="nextButton" />}
          data-test="onboarding-continue-button"
        />
      </div>
    </div>
  );
  const cancelProductOnboardPage2 = (
    <div className={styles.container}>
      <img className={styles['cancel-image']} src={cancelPic} role="presentation" alt="" />
      <div className={styles.margin}>
        <Headline2>
          <LocalizedMessage id="Page2Headline" />
        </Headline2>
      </div>
      <div className={styles['info-box']}>
        <div className={styles['info-icon']}>
          <CheckCircleIcon bold nativeColor={colors.action.positive} />
        </div>
        <Body>
          <LocalizedMessage id="info1" />
        </Body>
      </div>
      <div className={styles['info-box']}>
        <div className={styles['info-icon']}>
          <CheckCircleIcon bold nativeColor={colors.action.positive} />
        </div>
        <Body>
          <LocalizedMessage id="info2" />
        </Body>
      </div>
      <div className={styles['info-box']}>
        <div className={styles['info-icon']}>
          <CheckCircleIcon bold nativeColor={colors.action.positive} />
        </div>
        <Body>
          <LocalizedMessage id="info3" />
        </Body>
      </div>
      <div className={styles['drawer-page-bullet-container']}>
        <div className={styles['drawer-page-bullet-gray']} />
        <div className={styles['drawer-page-bullet-green']} />
      </div>
      <div className={styles['margin-button']}>
        <PositiveButton
          onClick={onFinishOnboarding}
          label={<LocalizedMessage id="closeButton" />}
          data-test="onboarding-finish-button"
        />
      </div>
    </div>
  );

  return <>{pageId === 1 ? cancelProductOnboardPage1 : cancelProductOnboardPage2}</>;
};

export const OnboardingCancelProductPages = withLocalization('onboarding-cancel-product/OnboardingCancelProductPages')(
  OnboardingCancelProductPagesComponent
);
