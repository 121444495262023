import { hasOnlyMobilePhoneNumberCharacters } from '@client/assets/js/validators';
import { LocalizedMessage } from '@client/internationalization';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { removeSpacesAndDashes } from '../../utils';

const SpainMobilePhoneNumberValidators = {
  correctlyFormatted(value: string = ''): boolean | React.ReactNode {
    const trimmedValue = removeSpacesAndDashes(value);
    const valid =
      isEmpty(value) ||
      (hasOnlyMobilePhoneNumberCharacters(trimmedValue) && startsAsSpainMobilePhoneNumber(trimmedValue));

    return valid ? false : <LocalizedMessage id="spainMobileFormatError" />;
  },
  correctLength(value: string = ''): boolean | React.ReactNode {
    if (isEmpty(value)) {
      return false;
    }
    const trimmedValue = removeSpacesAndDashes(value);
    const validLength = /^([9867])/.test(trimmedValue) ? 9 : 12;
    if (trimmedValue.length < validLength) {
      return <LocalizedMessage id="mobileNumberTooShortError" />;
    } else if (trimmedValue.length > validLength) {
      return <LocalizedMessage id="mobileNumberTooLongError" />;
    } else {
      return false;
    }
  },
};

export function startsAsSpainMobilePhoneNumber(input: string) {
  return /^(\+34)?([9867])\d.+/.test(input);
}

function spainMobilePhoneNumberFormatter(value: string): string {
  const trimmedValue = removeSpacesAndDashes(value);
  if (/^\+34\d{9}/.test(trimmedValue)) {
    return `${trimmedValue.substring(0, 3)} ${trimmedValue.substring(3, 6)} ${trimmedValue.substring(
      6,
      9
    )} ${trimmedValue.substring(9)}`;
  }
  if (/^([9867])\d{8}$/.test(trimmedValue)) {
    return `${trimmedValue.substring(0, 3)} ${trimmedValue.substring(3, 6)} ${trimmedValue.substring(6)}`;
  }

  return value;
}

export { SpainMobilePhoneNumberValidators, spainMobilePhoneNumberFormatter };
