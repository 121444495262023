import Sentry from '@client/assets/js/sentry';
import { AppType } from '@client/modules/app-context/constants';
import { selectorAppType } from '@client/modules/app-context/duck';
import type { AppIntegrationImplementation } from '@client/modules/app-integration';
import { AppIntegrationType, AppPlatform, setAppIntegrationImplementation } from '@client/modules/app-integration';
import { getGlobalStore } from '@client/modules/global-store';
import { swedbankRoutes } from '@client/routes';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';

const publicRoutes = [
  swedbankRoutes.userTermsPage,
  swedbankRoutes.frequentlyAskedQuestionsPage,
  swedbankRoutes.privacyPolicyPage,
];
export const isPublicRoute = (route: any) => publicRoutes.some((publicRoute) => route.includes(publicRoute));
export const isOnPublicRoute = () => isPublicRoute(window.location.toString());

/*
Documentation for Swedbank app integration can be found here:
https://docs.google.com/document/d/1y8ErT1q6Esb4mXgt_MNw97DnYrTzH2OFhTAgxA3juAs/edit?ts=59c4bd8e#heading=h.t37zubuwfj0k
*/

declare global {
  interface Window {
    webkit?: { messageHandlers: any };
    nativeHost?: any;
    onWebViewHostMessage?(message: any): void;
  }
}

export function isSwedbankAndroidApp() {
  return isSwedbankApp() && isAndroidApp();
}

export function isIosApp() {
  return Boolean(get(window, 'webkit.messageHandlers.nativeHost'));
}

function isAndroidApp() {
  return has(window, 'nativeHost.call');
}

export const appIntegration: AppIntegrationImplementation = {
  getAppIntegrationType() {
    return AppIntegrationType.MOBILE_APPLICATION;
  },
  getAppPlatform() {
    if (isAndroidApp()) {
      return AppPlatform.ANDROID_WEBVIEW;
    } else {
      return AppPlatform.IOS_WEBVIEW;
    }
  },
  closeApplication: closeWebView,
  closeApplicationOrGoTo: closeWebView,
  sendAnalyticsEvent,
  openExternalWebPage,
  openInternalPage,
};

let PING_THRESHOLD = 60 * 1000;

// @ts-ignore
if (window.Cypress) {
  PING_THRESHOLD = 0;
}

function closeWebView(): boolean {
  return postMessage(SwedbankAppIntegrationMessages.CLOSE_WEB_VIEW);
}

function postMessage(action: SwedbankAppIntegrationMessages, properties?: any) {
  try {
    Sentry.addBreadcrumb({ message: `App integration message sent: ${action}` });

    if (isIosApp()) {
      const message = { action, ...properties };

      // @ts-ignore
      window.webkit.messageHandlers.nativeHost.postMessage(message);

      return true;
    } else if (isAndroidApp()) {
      if (properties) {
        window.nativeHost.call(action, JSON.stringify(properties));
      } else {
        window.nativeHost.call(action);
      }

      return true;
    } else {
      const windowInfo = {
        androidNativeHost: get(window, 'nativeHost'),
        iosWebkit: get(window, 'webkit'),
        iosWebkitMessageHandlers: get(window, 'webkit.messageHandlers'),
        iosWebkitMessageHandlersNativeHost: get(window, 'webkit.messageHandlers.nativeHost'),
      };
      //This error happens a few thousand times per day, but it's not a problem for the user. Don't send sentry alert for
      // this error to decrease load on sentry.
      const pingOrAnalytics =
        action === SwedbankAppIntegrationMessages.PING || action === SwedbankAppIntegrationMessages.ANALYTICS;
      if (!pingOrAnalytics) {
        Sentry.captureMessage(
          `Failed to identify Swedbank app integration type for ${action}. Neither Android or iOS`,
          {
            extra: { messageAction: action, messageProperties: properties, windowInfo },
          }
        );
      }
      return false;
    }
  } catch (error) {
    if (action === SwedbankAppIntegrationMessages.PING || action === SwedbankAppIntegrationMessages.ANALYTICS) {
      Sentry.captureMessage(
        `Failed to send ${action} to Swedbank app`,
        {
          extra: { messageAction: action, messageProperties: properties },
        },
        'info'
      );
    } else {
      Sentry.captureExceptionWithMessage(error, 'Failed to send message to Swedbank app', {
        extra: { messageAction: action, messageProperties: properties },
      });
    }

    return false;
  }
}

function sendAnalyticsEvent(event: Record<string, unknown>) {
  return postMessage(SwedbankAppIntegrationMessages.ANALYTICS, { event });
}

function ping(): void {
  if (isOnPublicRoute()) {
    return;
  }
  postMessage(SwedbankAppIntegrationMessages.PING);
}

export const keepalivePing = throttle(ping, PING_THRESHOLD, {
  leading: false,
  trailing: true,
});

export function openBankId(autoStartToken?: string) {
  if (typeof autoStartToken === 'string' && autoStartToken.length > 0) {
    return postMessage(SwedbankAppIntegrationMessages.START_BANK_ID, { autoStartToken });
  }

  return postMessage(SwedbankAppIntegrationMessages.START_BANK_ID);
}

function openExternalWebPage(url: string, event?: Event): boolean {
  if (isEmpty(url)) {
    return true;
  } else {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    return postMessage(SwedbankAppIntegrationMessages.OPEN_LINK, { url });
  }
}

function openInternalPage(url: string, event?: Event): boolean {
  if (isEmpty(url)) {
    return true;
  } else {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    return postMessage(SwedbankAppIntegrationMessages.OPEN_INTERNAL_LINK, { url: url });
  }
}

export function isSwedbankApp() {
  return selectorAppType(getGlobalStore().getState()) === AppType.Swedbank;
}

const SavingsAfterCancellationSupported = 'savingsAfterCancellationSupported';
export function activateSwedbankIntegration(appIntegrationImpl: AppIntegrationImplementation) {
  setAppIntegrationImplementation(appIntegrationImpl);

  window.onWebViewHostMessage = (message: any) => {
    if (message === SavingsAfterCancellationSupported) {
      Cookies.set(SavingsAfterCancellationSupported, 'true');
      Sentry.captureMessage(`Swedbank sent SavingsAfterCancellationSupported message: ${message}`, undefined, 'info');
    } else {
      Sentry.captureMessage(`Swedbank sent error message: ${message}`);
    }
  };
}

export function initialize() {
  activateSwedbankIntegration(appIntegration);
}

export function getAndSetupSwedbankAppIntegration(): AppIntegrationImplementation {
  initialize();

  return appIntegration;
}

enum SwedbankAppIntegrationMessages {
  CLOSE_WEB_VIEW = 'CLOSE_WEB_VIEW',
  ANALYTICS = 'ANALYTICS',
  PING = 'PING',
  START_BANK_ID = 'START_BANK_ID',
  OPEN_LINK = 'OPEN_LINK',
  OPEN_INTERNAL_LINK = 'OPEN_INTERNAL_LINK',
}
