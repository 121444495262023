import type { Question } from '@client/models/cancellation-questions';
import type { Locale } from '@client/modules/app-context/constants';
import { requiredQuestionLabel } from '@client/modules/cancellation/CancellationFormPage/components/CancellationQuestionsSection/logic';
import { emailValidation } from '@client/utils/email';
import { detailsByLocale } from '@client/utils/locale';
import { RHTextInput } from '@minna-technologies/minna-ui/components/Form/RHTextInput';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useStyles } from './styles';

interface EmailQuestionProps {
  locale: Locale;
  question: Question;
}

export const EmailQuestion: React.FunctionComponent<EmailQuestionProps> = (
  { locale, question }: EmailQuestionProps,
  { localizeMessage }
) => {
  const { text, description } = detailsByLocale(question.details, locale);
  const required = question.required;
  const label = requiredQuestionLabel(text, required);
  const classes = useStyles();

  return (
    <>
      <Body className={classes.body}>{description}</Body>
      <RHTextInput
        label={label}
        name={question.id}
        type={'email'}
        inputMode={'email'}
        autoComplete={'email'}
        validation={{
          required: required ? localizeMessage('requiredEmailError') : false,
          validate: (value: string) => emailValidation(value, localizeMessage('userInfoEmailError')),
        }}
        fullWidth
      />
    </>
  );
};

EmailQuestion.contextTypes = {
  localizeMessage: PropTypes.func.isRequired,
};
