import { useLocalization } from '@client/internationalization';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Drawer } from '@minna-technologies/minna-ui/components/Drawer';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { PaperPlaneIllustration } from '@minna-technologies/minna-ui/illustrations/PaperPlane';
import React from 'react';

const useStyles = makeStyles({
  cancelIcon: {
    width: '88px',
    color: 'blue',
  },
  headline: {
    marginTop: '15px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  drawerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '90%',
    margin: 'auto',
  },
  bodyText: {
    textAlign: 'center',
  },
  closeButton: {
    marginTop: '35px',
  },
});

interface IntroDrawerProps {
  open: boolean;
  onClose(): void;
}

export const IntroDrawer = (props: IntroDrawerProps) => {
  const { localizeMessage } = useLocalization('genericActions/SubscriptionSearchPage/Cancel');
  const classes = useStyles();
  const { colors } = useTheme();

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      <div className={classes.drawerContent}>
        <PaperPlaneIllustration className={classes.cancelIcon} />
        <Headline2 className={classes.headline}>{localizeMessage('introDrawerHeader')}</Headline2>
        <Body color={colors.textOn.surfaceSubdued} className={classes.bodyText}>
          {localizeMessage('introDrawerBodyText1')}
        </Body>
        <div className={classes.closeButton}>
          <PrimaryButton
            onClick={props.onClose}
            label={localizeMessage('introDrawerCloseButton')}
            data-test="search-intro-close"
          />
        </div>
      </div>
    </Drawer>
  );
};
