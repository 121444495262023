import { withLocalization } from '@client/internationalization';
import { selectorFeatures } from '@client/modules/app-context/duck';
import { selectorComputedInsightsCount } from '@client/modules/InsightsPage/duck';
import * as urls from '@client/routes';
import Badge from '@material-ui/core/Badge';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NavigationType } from '../../../app-context';
import { OverviewNavigationActionLabel } from './OverviewNavigationActionLabel';
import { OverviewNavigationIcon, OverviewNavigationIconType } from './OverviewNavigationIcon';
import styles from './styles.scss';

const badgeRadius = 10;

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.colors.action.danger,
    color: theme.colors.base.surface,
    width: badgeRadius * 2,
    height: badgeRadius * 2,
    top: 6,
    right: 0,
  },
}))(Badge);

interface OverviewBottomNavigationProps {
  currentLocation: string;
}

const urlToIndex: { [key: string]: number } = {
  '/overview': 0,
  '/insights': 1,
  '/discover': 2,
  '/menu': 3,
};

const useStyles = makeStyles((theme) => ({
  navigation: {
    backgroundColor: theme.colors.base.surface,
    boxShadow: theme.modalShadow,
  },
}));

const OverviewBottomNavigationInner = ({ currentLocation }: OverviewBottomNavigationProps) => {
  const classes = useStyles();
  const features = useSelector(selectorFeatures);
  const insightsCount = useSelector(selectorComputedInsightsCount);
  //const insightCountRef = useRef(insightsCount);
  const [badgeCount, setBadgeCount] = useState(insightsCount);

  useEffect(() => {
    setBadgeCount(insightsCount);
  }, [insightsCount]);
  // TODO check if the count works as expected
  const [selectedIndex, setSelectedIndex] = useState(urlToIndex[currentLocation]);

  const shouldShowBottomNavigation = !(
    features.navigation && features.navigation.navigationType === NavigationType.PROFILE
  );

  function handleChange(_event: ChangeEvent<Record<string, unknown>>, value: number) {
    setSelectedIndex(value);
  }

  const overviewIcon = (
    <OverviewNavigationIcon isSelected={selectedIndex === 0} iconType={OverviewNavigationIconType.OVERVIEW} />
  );

  const improveIcon = (
    <OverviewNavigationIcon isSelected={selectedIndex === 1} iconType={OverviewNavigationIconType.INSIGHTS} />
  );

  const moreIcon = (
    <OverviewNavigationIcon isSelected={selectedIndex === 3} iconType={OverviewNavigationIconType.MORE} />
  );
  // In theory, the conditional check should not be needed, however, if removed,
  // the badge shows a '0' for a split second (possibly because insightsCount changes its value after the initial render)
  // of the badge. To fix this, we should look into the root case (the selector for insights count)
  const badge =
    badgeCount > 0 ? (
      <StyledBadge badgeContent={badgeCount} showZero={false}>
        {improveIcon}
      </StyledBadge>
    ) : (
      improveIcon
    );

  return shouldShowBottomNavigation ? (
    <div className={classNames(styles.navigation, classes.navigation)} data-test="bottom-navigation">
      <BottomNavigation classes={{ root: classes.navigation }} value={selectedIndex} onChange={handleChange} showLabels>
        <BottomNavigationAction
          data-test="bottom-navigation-overview"
          label={
            <OverviewNavigationActionLabel
              actionType={OverviewNavigationIconType.OVERVIEW}
              isSelected={selectedIndex === 0}
            />
          }
          icon={overviewIcon}
          component={Link}
          to={urls.overviewPage}
        />
        {features.insights && (
          <BottomNavigationAction
            data-test="bottom-navigation-insights"
            label={
              <OverviewNavigationActionLabel
                actionType={OverviewNavigationIconType.INSIGHTS}
                isSelected={selectedIndex === 1}
                labelText="insights"
              />
            }
            icon={badge}
            component={Link}
            to={urls.insightsPage}
          />
        )}
        <BottomNavigationAction
          data-test="bottom-navigation-more"
          label={
            <OverviewNavigationActionLabel
              actionType={OverviewNavigationIconType.MORE}
              isSelected={selectedIndex === 3}
            />
          }
          icon={moreIcon}
          component={Link}
          to={urls.menuPage}
        />
      </BottomNavigation>
    </div>
  ) : null;
};

export const OverviewBottomNavigation = withLocalization('overviewBottomNavigation')(OverviewBottomNavigationInner);
