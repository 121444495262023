import { makeStyles } from '@material-ui/styles';
import { ButtonText } from '@minna-technologies/minna-ui/components/Typography/ButtonText';
import React from 'react';
import { useAppTheme } from '@client/utils/hooks/use-app-theme';
import type { Theme } from '@material-ui/core';

interface ActionButtonProps {
  icon: string;
  iconAltLabel?: string;
  onClick: () => void;
  dataTest?: string;
  label: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '-webkit-tap-highlight-color': 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    width: '124px',
    marginTop: theme.spacing(2),
  },
  circle: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '64px',
    width: '64px',
    borderRadius: '50%',
    marginBottom: '8px',
    outline: 'none',
  },
  icon: {
    width: '64px',
  },
}));

export const ActionButton = ({ icon, iconAltLabel, onClick, dataTest, label }: ActionButtonProps) => {
  const classes = useStyles();

  return (
    <div role="button" className={classes.button} onClick={onClick} data-test={dataTest}>
      <span className={classes.circle}>
        <img className={classes.icon} src={icon} alt={iconAltLabel} />
      </span>
      <ButtonText align="center">{label}</ButtonText>
    </div>
  );
};

const useStylesActionButtonWithIcon = makeStyles((theme: Theme) => ({
  circle: {
    display: 'flex',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.action.primary,
  },
  actionButton: {
    marginTop: theme.spacing(2),
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

// ToDo: Move me to Minna-UI and make me prettier
export const ActionButtonWithIcon = ({
  Icon,
  label,
  onClick,
  'data-test': dataTest,
}: {
  Icon: React.ElementType;
  onClick: () => void;
  label: string | JSX.Element;
  'data-test': string;
}) => {
  const classes = useStylesActionButtonWithIcon();
  const theme = useAppTheme();

  return (
    <button onClick={onClick} className={classes.actionButton} data-test={dataTest}>
      <div className={classes.circle}>
        <Icon nativeColor={theme.colors.textOn.badge} />
      </div>
      <ButtonText>{label}</ButtonText>
    </button>
  );
};
