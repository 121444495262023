import type { IntelligentGuide } from '@client/modules/intelligent-guides/models';

export interface IntelligentGuideState {
  selectedIntelligentGuide?: IntelligentGuide;
}

const initialState: IntelligentGuideState = {
  selectedIntelligentGuide: undefined,
};

const NAMESPACE = 'INTELLIGENT_GUIDE';
const INTELLIGENT_GUIDE_SELECTED = `${NAMESPACE}/INTELLIGENT_GUIDE_SELECTED`;

export function reducer(state: IntelligentGuideState = initialState, action: any) {
  switch (action.type) {
    case INTELLIGENT_GUIDE_SELECTED: {
      return {
        ...state,
        selectedIntelligentGuide: action.selectedIntelligentGuide,
      };
    }
    default: {
      return state;
    }
  }
}

export const setSelectedIntelligentGuide = (selectedIntelligentGuide: IntelligentGuide | undefined) => ({
  type: INTELLIGENT_GUIDE_SELECTED,
  selectedIntelligentGuide,
});

export const selectorSelectedIntelligentGuide = (state: any): IntelligentGuide =>
  state.intelligentGuide.selectedIntelligentGuide;
