import { withFeatures } from '@client/feature-toggling';
import type { CancellationRouteProps } from '@client/modules/cancellation/cancelContractRoute';
import type { PauseRouteProps } from '@client/modules/pause/pauseRoute';
import type { ResumeRouteProps } from '@client/modules/pause/resumeRoute';
import type { ISaveDeskRoutes } from '@client/modules/saveDesk/route';
import type { ContractId, SubscriptionId } from '@client/modules/subscription/types';
import * as urls from '@client/routes';
import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch, withRouter } from 'react-router-dom';
import type { AppContext, AppFeatureToggles } from '../../app-context';
import { isCancellationEnabled, isChangePlanEnabled, isPauseEnabled } from '../../app-context';
import PageNotFoundContainer from '../../fetching/ErrorPage';
import LoadingPage from '../../fetching/LoadingPage';
import { ContractDetailsPage } from '../ContractDetailsPage';
import { PriceHistoryPage } from '../PriceHistoryPage';
import { LazyLoadedChangePlanRoute } from '@client/modules/change-plan/LazyLoadedChangePlanRoute';

interface ContractRouteProps {
  subscriptionId: SubscriptionId;
  contractId: ContractId;
  features: AppFeatureToggles;
  appContext: AppContext;
}

const LoadableCancellationRoute = Loadable({
  loader: async () => import(/* webpackChunkName: "cancellation-route" */ '../../cancellation/cancelContractRoute'),
  loading: LoadingPage,
  render(loaded, props: CancellationRouteProps) {
    const CancellationRoute = loaded.CancelContractRoute;

    return <CancellationRoute {...props} />;
  },
});

const LoadableSaveDeskRoute = Loadable({
  loader: async () => import(/* webpackChunkName: "save-desk-route" */ '@client/modules/saveDesk/route'),
  loading: LoadingPage,
  render(loaded, props: ISaveDeskRoutes) {
    const SaveDeskRoute = loaded.SaveDeskRoute;

    return <SaveDeskRoute {...props} />;
  },
});

const LoadablePauseRoute = Loadable({
  loader: async () => import(/* webpackChunkName: "pause-route" */ '@client/modules/pause/pauseRoute'),
  loading: LoadingPage,
  render(loaded, props: PauseRouteProps) {
    const PauseRoute = loaded.PauseRoute;

    return <PauseRoute {...props} />;
  },
});

const LoadableResumeRoute = Loadable({
  loader: async () => import(/* webpackChunkName: "resume-route" */ '@client/modules/pause/resumeRoute'),
  loading: LoadingPage,
  render(loaded, props: ResumeRouteProps) {
    const ResumeRoute = loaded.ResumeRoute;

    return <ResumeRoute {...props} />;
  },
});

// routes
const ContractRouteInner = ({ subscriptionId, contractId, features, appContext }: ContractRouteProps) => {
  return (
    <Switch>
      <Route
        path={urls.contractDetailsPage(subscriptionId, contractId)}
        render={() => <ContractDetailsPage subscriptionId={subscriptionId} contractId={contractId} />}
      />
      <Route
        path={urls.subscriptionPaymentHistoryPage(subscriptionId, contractId)}
        render={() => <PriceHistoryPage contractId={contractId} />}
      />
      {isCancellationEnabled(features.actionFeatures) && (
        <Route
          path={urls.subscriptionCancelBasePath(subscriptionId, contractId)}
          render={() => (
            <LoadableCancellationRoute
              subscriptionId={subscriptionId}
              contractId={contractId}
              appContext={appContext}
            />
          )}
        />
      )}
      {isCancellationEnabled(features.actionFeatures) && (
        <Route
          path={urls.saveDesk(subscriptionId, contractId)}
          render={() => <LoadableSaveDeskRoute subscriptionId={subscriptionId} contractId={contractId} />}
        />
      )}
      {isChangePlanEnabled(features.actionFeatures) && (
        <Route path={urls.subscriptionContractChangePlanBasePath(subscriptionId, contractId)}>
          <LazyLoadedChangePlanRoute />
        </Route>
      )}
      {isPauseEnabled(features.actionFeatures) && (
        <>
          <Route
            path={urls.subscriptionPauseBasePath(subscriptionId, contractId)}
            render={() => <LoadablePauseRoute subscriptionId={subscriptionId} contractId={contractId} />}
          />
          <Route
            path={urls.subscriptionResumeBasePath(subscriptionId, contractId)}
            render={() => <LoadableResumeRoute subscriptionId={subscriptionId} contractId={contractId} />}
          />
        </>
      )}
      <Route component={PageNotFoundContainer} />
    </Switch>
  );
};

// @ts-ignore
export const ContractRoute: React.ComponentType<ContractRouteProps> = withRouter(withFeatures(ContractRouteInner));
