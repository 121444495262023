import { Sentry } from '@client/assets/js/sentry';
import { FITNESS } from '@client/constants/categories';
import { LocalizedMessage } from '@client/internationalization';
import type { RejectedReason } from '@client/models/order';
import { OrderState } from '@client/models/order';
import type { ActionType } from '@client/modules/InsightsPage/models';
import { FailedOrderContent } from '@client/modules/switch/ordered-subscription/OrderOutcome/components/FailedOrderContent';
import useTheme from '@material-ui/core/styles/useTheme';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { ChangeDataPlanIcon } from '@minna-technologies/minna-ui/icons/ChangeDataPlan';
import { IndependentOrderIcon } from '@minna-technologies/minna-ui/icons/IndependentOrder';
import { SwitchIcon } from '@minna-technologies/minna-ui/icons/Switch';
import React from 'react';

export interface OrderOutcomeCardContent {
  color: string;
  headerId: string;
  headline: React.ReactNode;
  content?: React.ReactNode;
  icon: React.ReactElement;
  askForRating?: boolean;
}

export const useCreateOrderOutcomeInfo = (
  state: OrderState,
  feature: ActionType,
  category: string,
  newServiceProviderName: string,
  serviceProviderName?: string,
  rejectedReason?: RejectedReason,
  bindingTimeEndsAt?: string,
  message?: string
): OrderOutcomeCardContent | undefined => {
  const { colors } = useTheme();

  switch (state) {
    case OrderState.ChangeDataPlanAccepted:
      return {
        color: colors.action.positive as string,
        icon: <ChangeDataPlanIcon />,
        headline: <LocalizedMessage id="successfulChangeDataPlanSubtitle" />,
        headerId: 'changeDataPlanCardTitle',
        content: (
          <Body>
            <LocalizedMessage id="dataPlanWillChangeInOneHour" />
          </Body>
        ),
        askForRating: true,
      };
    case OrderState.ChangeDataPlanRejected:
      return {
        color: colors.action.info as string,
        icon: <ChangeDataPlanIcon />,
        headline: <LocalizedMessage id="rejectedChangeDataPlanSubtitle" />,
        headerId: 'changeDataPlanCardTitle',
        content: (
          <Body>
            <LocalizedMessage id="noChangesToYourSubscription" />
          </Body>
        ),
        askForRating: true,
      };
    case OrderState.AcceptedBySupplier:
    case OrderState.InvoiceSentToSupplier:
    case OrderState.PaidBySupplier:
      return {
        color: colors.action.positive as string,
        icon: <IndependentOrderIcon />,
        headline: getSuccessfulOrderHeadlineFromCategoryIndependent(category, newServiceProviderName),
        headerId: 'independentOrderCardTitle',
        content: getSuccessfulOrderDescriptionFromCategoryIndependent(
          category,
          newServiceProviderName,
          colors.textOn.surfaceSubdued as string
        ),
        askForRating: true,
      };
    case OrderState.CancelledByUser:
      return {
        color: colors.action.info as string,
        icon: <IndependentOrderIcon />,
        headline: <LocalizedMessage id="cancelledIndependentOrderSubtitle" />,
        headerId: 'independentOrderCardTitle',
        content: null,
        askForRating: true,
      };
    case OrderState.RejectedBySupplier:
      return {
        color: colors.action.danger as string,
        icon: <IndependentOrderIcon />,
        headline: <LocalizedMessage id="failedOrderSubtitle" values={{ supplierName: newServiceProviderName }} />,
        headerId: 'independentOrderCardTitle',
        content: (
          <FailedOrderContent
            message={message}
            serviceProviderName={serviceProviderName}
            newServiceProviderName={newServiceProviderName}
            bindingTimeEndsAt={bindingTimeEndsAt}
            rejectedReason={rejectedReason}
          />
        ),
        askForRating: true,
      };
    case OrderState.TerminationFailed:
      return {
        color: colors.action.danger as string,
        icon: <SwitchIcon />,
        headline: getTerminationFailedHeadlineFromCategory(category, serviceProviderName),
        headerId: 'switchCardTitle',
        content: (
          <Body>
            {getTerminationFailedDescriptionFromCategory(category, newServiceProviderName, serviceProviderName)}
          </Body>
        ),
        askForRating: true,
      };
    default:
      Sentry.captureMessage(`Don´t know how to handle state ${state} in OrderOutcome`);

      return undefined;
  }
};

const getTerminationFailedHeadlineFromCategory = (category: string, serviceProviderName?: string) => {
  switch (category) {
    case FITNESS:
      return <LocalizedMessage id="failedSwitchDueToFailedCancellationSubtitleFitness" />;
    default:
      return (
        <LocalizedMessage
          id="failedSwitchDueToFailedCancellationSubtitle"
          values={{ oldSupplier: serviceProviderName }}
        />
      );
  }
};

const getTerminationFailedDescriptionFromCategory = (
  category: string,
  newServiceProviderName: string,
  serviceProviderName?: string
) => {
  switch (category) {
    case FITNESS:
      return (
        <LocalizedMessage
          id="failedSwitchDueToFailedCancellationDescriptionFitness"
          values={{ oldSupplier: serviceProviderName, newSupplier: newServiceProviderName }}
        />
      );
    default:
      return (
        <LocalizedMessage
          id="failedSwitchDueToFailedCancellationDescription"
          values={{ oldSupplier: serviceProviderName, newSupplier: newServiceProviderName }}
        />
      );
  }
};

const getSuccessfulOrderHeadlineFromCategoryIndependent = (category: string, newServiceProviderName?: string) => {
  switch (category) {
    case FITNESS:
      return (
        <LocalizedMessage id="successfulSwitchSubtitleFitness" values={{ supplierName: newServiceProviderName }} />
      );
    default:
      return <LocalizedMessage id="successfulIndependentOrderSubtitle" />;
  }
};

const getSuccessfulOrderDescriptionFromCategoryIndependent = (
  category: string,
  newServiceProviderName: string,
  color: string
) => {
  switch (category) {
    case FITNESS:
      return (
        <Body color={color}>
          <LocalizedMessage id="successfulOrderDescriptionFitness" values={{ supplierName: newServiceProviderName }} />
        </Body>
      );
    default:
      return (
        <Body color={color}>
          <LocalizedMessage id="successfulOrderDescription" values={{ supplierName: newServiceProviderName }} />
        </Body>
      );
  }
};
