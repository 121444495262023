import { LocalizedMessage } from '@client/internationalization';
import type { CancellationUser } from '@client/models/cancellation';
import type { AppContext } from '@client/modules/app-context';
import { InfoComponent } from '@client/modules/cancellation/CancellationFormPage/components/UserInfoForm';
import { parseDate } from '@client/utils/date';
import { getLogo, getLogoAltText } from '@client/utils/logo';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Modal } from '@minna-technologies/minna-ui/components/Modal';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Subheading1 } from '@minna-technologies/minna-ui/components/Typography/Subheading1';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { UserTerminationInfo } from './UserTerminationInfo';

interface LoaDocumentPreviewProps {
  previewDialogLoaOpen: boolean;
  onPreviewLoa(requestPreviewLoa: () => void): void;
  requestPreviewLoa(): void;
  closePreviewDialogOpen(): void;
  appContext: AppContext;
  text: string;
  user: CancellationUser;
  desiredTerminationAt: string;
  terminationQuestionResults: any[];
  terminatingServiceName?: string;
  supplierName: string;
}

export const LoaDocumentPreview: React.FunctionComponent<LoaDocumentPreviewProps> = ({
  onPreviewLoa,
  previewDialogLoaOpen,
  requestPreviewLoa,
  closePreviewDialogOpen,
  ...props
}) => {
  const onToggleLoa = () => {
    if (!previewDialogLoaOpen) {
      onPreviewLoa(requestPreviewLoa);
    } else {
      closePreviewDialogOpen();
    }
  };

  return (
    <>
      <Modal
        open={previewDialogLoaOpen}
        onClose={onToggleLoa}
        button1={<SecondaryButton label={<LocalizedMessage id="closeLoaDialogButton" />} onClick={onToggleLoa} />}
      >
        <LoaDocument {...props} />
      </Modal>
      <SecondaryButton label={<LocalizedMessage id="displayLoaButton" />} onClick={onToggleLoa} />
    </>
  );
};

export interface LoaDocumentProps {
  appContext: AppContext;
  text: string;
  user: CancellationUser;
  desiredTerminationAt?: string;
  terminationQuestionResults: any[];
  terminatingServiceName?: string;
  supplierName: string;
  titleId?: string;
}

export const LoaDocument: React.FunctionComponent<LoaDocumentProps> = (
  {
    titleId,
    text,
    user,
    desiredTerminationAt,
    terminationQuestionResults,
    terminatingServiceName,
    supplierName,
    appContext,
  },
  { localizeDate }
) => {
  const desiredTerminationAtText = desiredTerminationAt ? (
    localizeDate(parseDate(desiredTerminationAt))
  ) : (
    <LocalizedMessage id="asSoonAsPossible" />
  );
  const { colors } = useTheme();

  return (
    <div>
      <LoaHeader supplierName={supplierName} appContext={appContext} titleId={titleId} />

      <Body color={colors.textOn.surfaceSubdued}>{text}</Body>

      <Divider />

      <UserTerminationInfo user={user} appContext={appContext} />

      <Divider />

      <Subheading1>
        <LocalizedMessage id="otherInformation" />
      </Subheading1>

      <ServiceName terminatingServiceName={terminatingServiceName} />
      <TerminationAnswers terminationQuestionResults={terminationQuestionResults} />
      <InfoComponent label={<LocalizedMessage id="desiredTerminationDate" />}>{desiredTerminationAtText}</InfoComponent>
    </div>
  );
};

LoaDocument.contextTypes = {
  localizeDate: PropTypes.func.isRequired,
};

interface LoaHeaderProps {
  appContext: AppContext;
  supplierName: string;
  titleId?: string;
}

const useStyles = makeStyles(() => ({
  headerContainer: {
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    height: '32px',
    marginBottom: '16px',
  },
}));

const LoaHeader: React.FunctionComponent<LoaHeaderProps> = ({ titleId, supplierName, appContext }) => {
  const classes = useStyles();
  const logo = getLogo(appContext);
  const logoAltText = getLogoAltText(appContext);
  const titleIdValue = titleId || 'loaTitle';

  return (
    <div className={classes.headerContainer}>
      <img className={classes.logo} src={logo} alt={logoAltText} />
      <Subheading2>
        <LocalizedMessage id={titleIdValue} values={{ supplierName: supplierName }} />
      </Subheading2>
    </div>
  );
};

interface TerminationAnswersProps {
  terminationQuestionResults: any[];
}

const TerminationAnswers: React.FunctionComponent<TerminationAnswersProps> = ({ terminationQuestionResults }) => {
  const { colors } = useTheme();
  const fields = map(terminationQuestionResults, (questionResult, index) => {
    const value = questionResult.value;

    return (
      <div key={index} style={{ marginBottom: '8px' }}>
        <Body>{questionResult.label}</Body>
        {value ? <Body color={colors.textOn.surfaceSubdued}>{value}</Body> : null}
      </div>
    );
  });

  return <div>{fields}</div>;
};

interface ServiceNameProps {
  terminatingServiceName?: string;
}

const ServiceName: React.FunctionComponent<ServiceNameProps> = ({ terminatingServiceName }) => {
  const { colors } = useTheme();
  if (terminatingServiceName) {
    return (
      <div>
        <Body>
          <LocalizedMessage id="service" />
        </Body>
        <Body color={colors.textOn.surfaceSubdued} style={{ marginTop: '0' }}>
          {terminatingServiceName}
        </Body>
      </div>
    );
  } else {
    return null;
  }
};
